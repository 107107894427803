// @ts-nocheck
import React, { useState, useEffect } from 'react';
import TooltipView from 'react-components/components/Tooltip';
import ReactSlider from 'react-slider';
import * as Utils from './utils';
import { ProbeSingleSliderProps } from './interface';
import { Grid } from '@material-ui/core';
import {
  renderCustomThumb,
  LeftSideComponent,
  RightSideComponent,
} from './hooks';
import './styles.scss';

const UTILS = { ...Utils.default };

const ProbeSingleSlider: React.FC<ProbeSingleSliderProps> = ({
  id,
  values,
  onChange,
  max,
  min,
  step,
  col,
  showLabel,
  symbol,
  probeStatus,
  ...props
}) => {
  const [compData, setCompData]: any = useState(null);
  const [compValues, setCompValues]: any = useState([]);

  const convertProbeStatus = (status: any) => {
    let classStatus: string = '';

    switch (status) {
      case 2:
        classStatus = 'prob-calibrate';
        break;
      case 3:
        classStatus = 'prob-running';
        break;
      case 0:
        classStatus = 'prob-start';
        break;
      case 1:
      default:
        classStatus = 'prob-standby';
        break;
    }

    return classStatus;
  };

  useEffect(() => {
    if (id && id !== '') {
      const result: any = UTILS.selectData(id);
      if (result) {
        setCompData({ ...result });
      }
    }

    return () => {
      setCompData(null);
    };
  }, [id]);

  useEffect(() => {
    if (values) {
      const tempValues: any = UTILS.mapNewValues(values, { max, min });
      setCompValues([...tempValues]);
    }
  }, [values]);

  return (
    <Grid className="widget-slider">
      <div id={id} className="widget-slider--container">
        <Grid container>
          <Grid item className={`slider--left-container--${col ? col : 1}`}>
            {compData && <LeftSideComponent data={compData} />}
          </Grid>
          <Grid item className={`slider--center-container--${col ? col : 1}`}>
            <ReactSlider
              className={`prob-horizontal--slider ${convertProbeStatus(
                probeStatus,
              )}`}
              thumbClassName={`prob--slider-thumb`}
              trackClassName="prob--slider-track"
              value={compValues}
              step={step}
              max={max}
              min={min}
              ariaLabel={[
                's-Left s-thumb',
                's-Middle s-thumb',
                's-Right s-thumb',
              ]}
              renderThumb={renderCustomThumb.bind(null, symbol)}
              minDistance={-max}
              snapDragDisabled={true}
              onSliderClick={(value: number, tIndex: number) => {}}
              onBeforeChange={(value: number, tIndex: number) => {}}
              onChange={onChange}
            />
            <div className="default-value--wrapper">
              <TooltipView title={'Default Value: 50'}>
                <svg
                  width="10"
                  height="8"
                  style={{
                    position: 'absolute',
                    top: 20,
                    left: 120,
                    zIndex: 99,
                  }}
                >
                  <rect
                    width="10"
                    height="8"
                    style={{ fill: '#90c095', strokeWidth: 0 }}
                  />
                  Sorry, your browser does not support inline SVG.
                </svg>
              </TooltipView>
            </div>
          </Grid>
          <Grid item className={`slider--right-container--${col ? col : 1}`}>
            {compData && <RightSideComponent data={compData} />}
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default ProbeSingleSlider;
