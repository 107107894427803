import React from 'react';
import { PhoneProps } from './interface';
import PhoneInput from 'react-phone-input-2';
import './styles.scss';

const Phone: React.FC<PhoneProps> = ({
  value,
  disabled,
  onChange,
  className,
}) => {
  return (
    <div className={className}>
      <PhoneInput
        disabled={disabled}
        country={'us'}
        value={value || ''}
        onChange={onChange}
        onlyCountries={['us']}
      />
    </div>
  );
};

export default Phone;
