import React from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';
import config from './config';
import './styles.scss';


const HEADER_TITLES: any = { ...config.colunms };


interface ThresholdTableHeaderProps {
  values: any;
}

const ThresholdTableHeader: React.FC<ThresholdTableHeaderProps> = ({ values, ...props }) => {

  return (
    <div className="th-header--main">
      <div className="th-header--td outside align--center">{HEADER_TITLES.top[0].title}</div>
      <div className="th-header--td indoor align--center">{HEADER_TITLES.top[1].title}</div>
      <div className="th-header--td mode align--center">{HEADER_TITLES.top[2].title}</div>
      <div className="th-header--td td-temp">
        <div className="td--temp td--temp--title">{'Temperature'}</div>
        <div className="td--temp td--temp--max">{HEADER_TITLES.bottom[0].title}</div>
        <div className="td--temp td--temp--min">{HEADER_TITLES.bottom[1].title}</div>
      </div>
      <div className="th-header--td td-hum s-row align--center">{HEADER_TITLES.bottom[2].title}</div>
      <div className="th-header--td td-aqi s-row align--center">{HEADER_TITLES.bottom[3].title}</div>

      <div className="th-header--td td-pm25 s-row align--center">{HEADER_TITLES.bottom[4].title}</div>
      <div className="th-header--td td-co2 s-row align--center">{HEADER_TITLES.bottom[5].title}</div>
      <div className="th-header--td td-voc s-row align--center">{HEADER_TITLES.bottom[6].title}</div>

      <div className="td-body--row m-row">
        <div className="td--temp-max h-row">{values?.out_temp_max || 'N/A'}</div>
        <div className="td--temp-min h-row">{values?.out_temp_min || 'N/A'}</div>
      </div>
      <div className="td-body--row s-row">{values?.out_hum_value || 'N/A'}</div>
      <div className="td-body--row s-row">{values?.out_aq_value || 'N/A'}</div>

      <div className="td-body--row s-row">{values?.in_pm25_value || 'N/A'}</div>
      <div className="td-body--row s-row">{values?.in_co2_value || 'N/A'}</div>
      <div className="td-body--row s-row">{values?.in_voc_value || 'N/A'}</div>
      <div className="td-body--row s-row row-mode">{values?.curr_mode || 'N/A'}</div>
    </div>
  );
}

export default ThresholdTableHeader;
