import React from 'react';
import { LocationElementsProps } from './interface';
import { Select, Input } from '../';

const FileLocationElements: React.FC<LocationElementsProps> = ({
  ...props
}) => {
  const FileLocationSwitch = ({
    mode,
    allLocations,
    value,
    field,
    parentField,
    onChange,
    returnLabel,
    ...other
  }: any) => {
    switch (mode) {
      case 'edit':
        return (
          <Select
            value={value}
            onChange={onChange}
            locations={allLocations}
            field={parentField}
          />
        );
      case 'normal':
      default:
        return (
          <Input
            label={returnLabel(field)}
            placeholder={returnLabel(field)}
            name={field}
            value={value}
            error={null}
            type="text"
            handleChange={() => {}}
            handleChangeError={() => {}}
            disabled={true}
          />
        );
    }
  };

  return <FileLocationSwitch {...props} />;
};

export default FileLocationElements;
