import React from 'react';
import { VentilationSectionProps } from './interface';
import { CalculatedFlow, CalculatedTime, BalancedMode } from '..';
import './styles.scss';

const VentilationSection: React.FC<VentilationSectionProps> = ({
  fieldsState,
  configs,
  errors,
  onChangeState,
}) => {
  return (
    <div className="col-xs-12 col-sm-12 col-md-6 fields-edit-container">
      {fieldsState?.ventilation_setup === 'Calculated Flow' && (
        <CalculatedFlow
          config={{ ...configs }}
          errors={errors}
          fieldsState={fieldsState}
          onChangeState={onChangeState}
        />
      )}
      {fieldsState?.ventilation_setup === 'Calculated Time' && (
        <CalculatedTime
          config={configs}
          errors={errors}
          fieldsState={fieldsState}
          onChangeState={onChangeState}
        />
      )}
      {fieldsState?.ventilation_setup === 'Balanced Mode' && (
        <BalancedMode
          config={configs}
          errors={errors}
          fieldsState={fieldsState}
          onChangeState={onChangeState}
        />
      )}
    </div>
  );
};

export default VentilationSection;
