import config from './config';

const returnPlaceholder = (key: any) => {
  if (key[0] === 'device_city') return key[1].name;
  if (key[0] === 'device_state') return key[1].name;
  if (key[0] === 'device_zipcode') return key[1].zip;
};

const returnLabel = (str: string) => {
  if (config.labels.hasOwnProperty(str)) {
    return config.labels[str as keyof typeof config.labels];
  }
};

const validateInputFields = (str: string) => {
  if (
    str === 'device_city' ||
    str === 'device_state' ||
    str === 'device_zipcode'
  ) {
    return true;
  } else {
    return false;
  }
};

const handleOpts = (action: string, section: string) => {
  if (section === `title`) {
    if (config.titles.hasOwnProperty(action)) {
      return config.titles[action as keyof typeof config.titles];
    }
  }
  if (section === `icon`) {
    if (config.icons.hasOwnProperty(action)) {
      return config.icons[action as keyof typeof config.icons];
    }
  }
  if (section === `bodies`) {
    if (config.bodies.hasOwnProperty(action)) {
      return config.bodies[action as keyof typeof config.bodies];
    }
  }

  if (section === `bodyClass`) {
    if (config.bodyClass.hasOwnProperty(action)) {
      return config.bodyClass[action as keyof typeof config.bodyClass];
    }
  }
};

export const functions = {
  returnPlaceholder: returnPlaceholder,
  returnLabel: returnLabel,
  validateInputFields: validateInputFields,
  handleOpts: handleOpts,
};
