import React from 'react';

interface TopProps {
  config: any;
}

const Top: React.FC<TopProps> = ({ config }) => {
  return (
    <>
      <h5 className=""> {config?.strings[0].title}</h5>
      <p className="lead">{config?.strings[0].section}</p>
      <hr className="my-4" />
    </>
  );
};

export default Top;
