import React from 'react';
import { UploadProps } from './interface';
import { RiImageEditLine } from 'react-icons/ri';
import './styles.scss';

const Upload: React.FC<UploadProps> = ({
  type,
  id,
  name,
  accept,
  onClick,
  imgElement,
  rowUpl,
  idUpl,
  cClass,
}) => {
  return (
    <div className="upload-box">
      <div className="row d-flex justify-content-center mt-4">
        <div className={rowUpl} id={idUpl}>
          <input type={type} id={id} name={name} accept={accept} />

          <div className={cClass}> </div>
          <div className="col-2 btn-cont-up">
            <button
              className="btn btn-sm  upload-btn btn-up-small-button btn-up-ok"
              onClick={onClick}
              value="Choose file"
            >
              <RiImageEditLine className="edit-img" />
            </button>
          </div>
        </div>
        {imgElement}
      </div>{' '}
    </div>
  );
};

export default Upload;
