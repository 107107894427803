import React, { useState, useEffect } from 'react';
import { Dropdown, Col, Form } from 'react-bootstrap';
import cn from 'classnames';
import styles from './index.module.scss';
import './styles.scss';

interface DataType {
  value: string;
  label?: string;
}
interface DropdownProps {
  label?: string;
  title?: string;
  handleChange: Function;
  value: boolean;
  type?: string;
  name?: string;
  data?: DataType[];
  cols?: {
    col?: number;
    sm?: number;
    md?: number;
    lg?: number;
    lx?: number;
  };
  error?: string;
  disabled?: boolean;
  classname?: string;
}

const DropdownComponent: React.FC<DropdownProps> = ({
  label,
  handleChange,
  value,
  cols,
  error,
  data,
  title,
  disabled,
  classname,
}) => {

  useEffect(() => {
    setFilteredData([...data]);
    return () => {};
  }, []);

  useEffect(() => {
    var filteredData = data?.filter(function (item: any) {
      return item.value === value;
    });
    if (filteredData) {
      setValueDropdown(filteredData[0]?.label);
    }
  }, [data, value]);

  const [valueDropdown, setValueDropdown]: any = useState(false);
  const [filteredData, setFilteredData]: any = useState([]);
  const [valueFilteredData, setValueFilteredData]: any = useState('');

  const handleChangeDropdown = (id: string, label: string) => {
    handleChange(id);
    setValueDropdown(label);
    setFilteredData([...data]);
    setValueFilteredData('');
  };

  const handleChangeFilter = (e: any) => {
    var val = e.target.value;
    setValueFilteredData(val);
    var filteredData = data?.filter(function (item: any) {
      return item.label.toLowerCase().includes(val.toLowerCase());
    });
    setFilteredData(filteredData);
  };

  return (
    <Col
      sm={cols?.sm || cols?.col}
      md={cols?.md || cols?.col}
      lg={cols?.lg || cols?.col}
      className={cn('form-group', classname)}
    >
      {label && <label className="inputLabel">{label}</label>}
      <div id="air-custom--dp">
        <Dropdown id="dropdown-">
          <Dropdown.Toggle
            disabled={!disabled}
            variant={''}
            className={cn('mr-3  text-left btn-outline-secondary text-dark', {
              [styles[`input--isError`]]: error,
            })}
            style={{ width: '100%' }}
          >
            {value ? `${valueDropdown}` : title}
          </Dropdown.Toggle>
          {data && data.length > 0 && (
            <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'scroll' }}>
              <div className="p-2 pr-3 pl-3 dp-search">
                <Form.Control
                  onChange={handleChangeFilter}
                  type="text"
                  placeholder="Filter"
                  value={valueFilteredData}
                />
              </div>
              {filteredData.map((item: any, index: any) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => handleChangeDropdown(item.value, item.label)}
                >
                  {item.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          )}
        </Dropdown>
        {error && (
          <span
            className={cn(styles.input, {
              [styles[`input__span--isError`]]: error,
            })}
          >
            {error}
          </span>
        )}
      </div>
    </Col>
  );
};

export default DropdownComponent;
