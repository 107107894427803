const config: any = {
  strings: {
    title: 'Admin',
    lostPassword: 'Lost your password?',
    createAccount: 'Create Account',
    confirm: 'Login',
    cancel: 'Cancel',
  },
  fieldsState: {
    email: '',
    password: '',
  },
  fieldsFocus: {
    email: false,
    password: false,
  },
  fields: {
    email: {
      label: 'E-mail',
      type: 'email',
      autofocus: true,
      placeholder: 'E-mail',
      cols: {
        col: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      },
    },
    password: {
      label: 'Password',
      type: 'password',
      placeholder: 'Password',
      cols: {
        col: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      },
    },
  },
  constraints: {
    email: {
      email: true,
      presence: true,
    },
    password: {
      length: {
        minimum: 6,
      },
      presence: true,
    },
  },
};

export { config };
