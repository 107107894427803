import { ConnectionBroker } from '../../core';
import config from '../config/config';
import _ from 'lodash';

export const adminDataLoggingState = {
  state: {
    devToGetLogs: {},
    dataLogExportCanceled: false,
    dataLoggingDetails: {},
    dataLoggingLoaded: false,
    startDate: undefined,
    endDate: undefined,
    footerMessage: ``,
    modalMustClose: false,
  },
  reducers: {
    handleModalState(state: any, payload: any) {
      return {
        ...state,
        modalMustClose: payload,
      };
    },
    defineDevToGetLogs(state: any, payload: any) {
      return { ...state, devToGetLogs: payload };
    },
    cancelDataLog(state: any, payload: boolean) {
      return {
        ...state,
        dataLogExportCanceled: payload,
      };
    },
    setDataLoggingList(state: any, payload: any) {
      return {
        ...state,
        dataLoggingDetails: payload,
        dataLoggingLoaded: true,
      };
    },
    setStartDate(state: any, payload: any) {
      return { ...state, startDate: payload, footerMessage: '' };
    },
    setEndDate(state: any, payload: any) {
      return { ...state, endDate: payload, footerMessage: '' };
    },
    setMessage(state: any, payload: string) {
      return { ...state, footerMessage: payload };
    },
  },
  effects: (dispatch: any) => ({
    //wip
    async getDataLoggingList(data: any) {
      const that: any = this;
      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        `admin.dataLoggingList`,
        null,
        data,
      );
      if (response?.statusCode === 200) {
        that.setDataLoggingList(response?.body?.data);
      } else {
        //handle error here
      }
      return response;
    },
    async getLogsByDates(data: any) {
      const that: any = this;
      const { systemId, account_id, end, start }: any = data;

      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        'admin.dataLoggingListByDates',
        null,
        { system_id: systemId, account_id, start, end },
      );
      if (response && response?.statusCode === 200) {
        const { Items }: any = response?.body?.data?.data || {};

        if (Items && Items?.length === 0) {
          that.setMessage(config?.adminDataLoggingState?.emptyList);
        } else {
          that.setMessage(config?.adminDataLoggingState?.readyData);
        }
        return Items && Array.isArray(Items) && Items.length > 0
          ? _.cloneDeep(Items)
          : [];
      } else {
        //handle error
      }
      return [];
    },
  }),
};
