import React from 'react';


interface NotificationComponentProps {
  account_id: string;
  alerts_id: string;
  content: any;
  message_id: string;
  system_id: string;
  status: any;
  createAt: any;
}

const NotificationComponent: React.FC<NotificationComponentProps> = ({ content, ...props}) => {
  const { title, msm } = content || {};

  return (
    <>
      <div className="notfy-item--title">{title || ''}</div>
      <div className="notfy-item--body">{msm || ''}</div>
    </>
  );
};

export default NotificationComponent;
