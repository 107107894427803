import React from 'react';
import { TabsProps } from './interface';
import './styles.scss';

const Tabs: React.FC<TabsProps> = ({
  tabs,
  currentlyActive,
  changeTab,
  spaced,
}) => {
  return (
    <div className={`col-12 ${spaced}`}>
      <div className="row tabs-pl-0">
        <div className="col-12 spec-cols">
          <ul className="nav nav-pills nav-fill" id="delete-req-tabs">
            {tabs.map((tab: any, index: number) => (
              <li className="nav-item" key={index}>
                <button
                  className={`nav-link tabs-fs ${
                    currentlyActive === tab.id ? `active` : ``
                  }`}
                  onClick={() => changeTab(tab.id)}
                >
                  {tab.label}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
