import React, { Fragment } from 'react';
import { G3CardProps } from './interface';
import { RiSettings4Line } from 'react-icons/ri';
import './styles.scss';

const G3Card: React.FC<G3CardProps> = ({
  config,
  device,
  returnLabel,
  returnValue,
}) => {
  const splitValue = (val: any) => {
    let cut;
    if (typeof val === 'string') {
      cut = val.slice(0, 16).concat('...');
      return cut;
    } else {
      return val;
    }
  };

  return (
    <Fragment>
      <h6 className="card-subtitle mb-2 text-muted mt-4 g3-section">
        <RiSettings4Line className="g3-icon" />{' '}
        {config.accordionDetails.card4.subTitle}
      </h6>
      <div className="card-text g3--data">
        {config.settingsDevLabels
          .slice(0, config.settingsDevLabels.length / 2)
          .filter((label: any) => label.id === `fmwv` || label.id === `hrss`)
          .map((label: any, index: number) => {
            return (
              <div className="container col-p-0" key={index}>
                <div className="row item" title={returnLabel(label.label)}>
                  <div className="col col-p-0">
                    <span className="lead stronger tw-px">
                      {returnLabel(label.label)}
                    </span>
                    <span className={`pill-style`}>[{label.label}]</span>
                  </div>

                  <div
                    className={`${
                      returnLabel(label.label) === 'Last Conn. Access Point'
                        ? `col-auto p-l-0 pr0`
                        : `col-3 p-l-0 pr0`
                    }`}
                  >
                    <span className="alert-device lead to-the-right pt2">
                      {label.id !== 'conf' && <> {returnValue(label.label)}</>}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}

        {config.settingsDevLabels
          .slice(config.settingsDevLabels.length / 2)
          .filter((label: any) => label.id === `uptm`)
          .map((label: any, index: number) => {
            return (
              <div className="container col-p-0" key={index}>
                <div className="row item" title={returnLabel(label.label)}>
                  <div className="col col-p-0">
                    <span className="lead stronger tw-px">
                      {returnLabel(label.label)}
                    </span>
                    <span className={`pill-style`}>[{label.label}]</span>
                  </div>

                  <div
                    className={`${
                      returnLabel(label.label) === 'Last Conn. Access Point'
                        ? `col-auto p-l-0 pr0`
                        : `col-3 p-l-0 pr0`
                    }`}
                  >
                    <span className="lead to-the-right pt2 alert-device">
                      {' '}
                      {returnValue(label.label)}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}

        {Object.entries(device?.details?.settings?.conf)
          .slice(Object.entries(device?.details?.settings?.conf).length / 2)
          .map(([key, value]: [any, any], i) => {
            return key === 'cdvs' ? (
              <Fragment key={i}>
                {value.map((element: any, i: number) => {
                  Object.entries(element).map(([key, value]: [any, any], i) => {
                    if (key === `dmac`) {
                      return (
                        <div className="container col-p-0" key={i}>
                          <div className="row item">
                            <div className="col-6 col-p-0">
                              {config.cdvsSettings
                                .slice(0, config.cdvsSettings.length / 2)
                                .map((elem: any, index: number) => {
                                  return (
                                    <div
                                      className="container col-p-0"
                                      key={index}
                                    >
                                      <div
                                        className="row item"
                                        title={returnLabel(elem)}
                                      >
                                        <div className="col col-p-0">
                                          <span className="lead stronger tw-px">
                                            {returnLabel}
                                          </span>
                                          <span className={`pill-style`}>
                                            [{elem}]
                                          </span>
                                        </div>

                                        <div
                                          className={`${
                                            returnLabel(elem) ===
                                            'Last Conn. Access Point'
                                              ? `col-auto conn-pt pr0`
                                              : `col-3 col-p-0 pr0`
                                          }`}
                                        >
                                          <span className="lead to-the-right pt2 alert-device">
                                            {splitValue(
                                              returnLabel(element[elem]),
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>

                            <div className="col-6 col-p-0">
                              {config.cdvsSettings
                                .slice(config.cdvsSettings.length / 2)
                                .map((elem: any, index: number) => {
                                  return (
                                    <div
                                      className="container col-p-0"
                                      key={index}
                                    >
                                      <div
                                        className="row item"
                                        title={returnLabel(elem)}
                                      >
                                        <div className="col col-p-0">
                                          <span className="lead stronger tw-px">
                                            {returnLabel(elem)}
                                          </span>
                                          <span className={`pill-style`}>
                                            [{elem}]
                                          </span>
                                        </div>

                                        <div
                                          className={`${
                                            returnLabel(elem) ===
                                            'Last Conn. Access Point'
                                              ? `col-auto p-l-0 pr0`
                                              : `col-3 p-l-0 pr0`
                                          }`}
                                        >
                                          <span className="lead to-the-right pt2 alert-device">
                                            {splitValue(
                                              returnLabel(element[elem]),
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  });
                })}
              </Fragment>
            ) : null;
          })}
      </div>
    </Fragment>
  );
};

export default G3Card;
