export default {
    strings: {
      title: 'Edit Peripheral',
      confirm: 'Update Peripheral'
    },
    alerts: {
      update: {
        question: 'Are you sure you want to update peripheral?',
        btn: {
          confirm: 'Yes, update it!',
        },
      },
    },
    notifications: {
      update: {
        success: 'Peripheral updated successfully!',
        error: ''
      },
    },
    fieldsState: {
      cail: ''
    },
    fields: {
      cail: {
        label: 'Peripheral Name',
        type: 'text',
        placeholder: 'Peripheral Name',
      },
    },
    constraints: {
      cail: {
        presence: true,
        length: {
          minimum: 6,
        },
      },
    },
}
