// @ts-nocheck
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  createRef,
} from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import _ from 'lodash';
import { TiWaves } from 'react-icons/ti';
import { GoSettings } from 'react-icons/go';
import { FaExclamation } from 'react-icons/fa';
import TooltipView from 'react-components/components/Tooltip';
import {
  DevicesList,
  ZonesList,
  EmptyList,
  ChartItem,
  VentilationState,
  FreshConnectState,
  CentralFanState,
  ChartLegendMain,
  ComplementaryLegend,
} from '../../components';
import {
  OutsideAir,
  IndoorAir,
  FreshConnect,
  PressureConnect,
  NoDevices,
  ManualControl,
} from './components';
import { UserTemplate } from 'business/modules/common';
import {
  StringsUtils,
  VentUtils,
  DevicesUtils,
  ChartUtils,
  ChartUtilsV2,
} from './utils';
import './style.scss';
import configs from '../../../../config/config';
import config from './config';

interface LoadingProps {
  start: any;
  stop: any;
}

interface DashboardProps {
  devices?: any;
  weather: any;
  getDevices: any;
  getDeviceMethod2: any;
  ventStack: any;
  loading: LoadingProps;
  thirdParty: any;
  thirdPartyFuncs: any;
  updateVentStack: any;
  requestWeather: any;
  updateWeather: any;
  updateG3Reads: any;
  getSysSetup: Function;
  setCurrentDevice?: any;
  systemChartData: Function;
}

const SOCKET_URL: any = configs?.apis?.g3iot?.baseSocketURLs[0];
const PROPS_TO_CHECK: object = { ...config.checkConnect };
const TIME_PROPS: object = { ...config.timeStack };
const WEATHER_TIMER_VAL: number = config.timersVals.weather;
const IS_SIGNING_IN: string = '@aircyler:is-signing-in';
const STORAGE_STACK_LABEL: string = '@air-stack-charts:';
const STORAGE_HAS_STACK: string = '@air-has-stack:';
const STORAGE_PREFIX: string = '@air:brex-slice->';
let GLOBAL_SCOPE: object = {
  currentHour: new Date().getHours(),
  _devices: [],
  devices: {
    new: [],
    old: [],
    oldInit: false,
  },
  charts: [],
  devicesUpdated: [],
};
let WEBSOCKETS: object = {};
let STACKS: any = [];
let TIME_INTERVALS: any = {};
let TIME_VALUES: any = {};

const Dashboard: React.FC<DashboardProps> = ({
  devices,
  thirdParty,
  ...props
}) => {
  const history: any = useHistory();
  const mounted: any = useRef(false);
  const titlesElements: any = useRef([]);
  const [hasFoobotConn, setHasFoobotConn]: any = useState(false);
  const [isStackInit, setIsStackInit]: any = useState(false);
  const [devicesToBuild, setDevicesToBuild]: any = useState([]);
  const [chartsData, setChartsData]: any = useState([]);
  const [probeValuesCtrl, setProbeValuesCtrl]: any = useState({});
  const [sensors, setSensors]: any = useState([]);
  const [sysSetups, setSysSetups]: any = useState({});
  const [timeLogs, setTimeLogs]: any = useState({});
  const [weatherReqFlag, setWeatherReqFlag]: any = useState(true);
  const [systemsStatus, setSystemsStatus]: any = useState({});

  const handleStoredStack = async (_system: any) => {
    let resultStack: any = await props.systemChartData(_system);

    let result: any = {
      chartData: {
        vent: [],
        exhaust: [],
      },
      percentage: {},
      deviceId: _system.device_id,
      detailsId: _system.details.id,
      minutes: {},
      stoped: true,
    };

    if (resultStack) {
      const { inside, outside, minutes, percentage, stoped }: any = resultStack;

      result = {
        chartData: {
          vent: outside && Array.isArray(outside) ? [...outside] : [],
          exhaust: inside && Array.isArray(inside) ? [...inside] : [],
        },
        percentage: { ...percentage },
        deviceId: _system.device_id,
        detailsId: _system.details.id,
        minutes: { ...minutes },
        stoped: stoped,
      };
    }

    return result;
  };

  const handleInitSliceStacks = async (_devices: any) => {
    let tempChartsData: any = [];
    props.loading.start('Loading...');

    if (_devices && _devices?.length > 0) {
      for (const _device of _devices) {
        const newStack: any = await handleStoredStack(_device);
        tempChartsData.push(newStack);
      }
    }

    if (tempChartsData?.length > 0) {
      setChartsData(_.cloneDeep(tempChartsData));
      STACKS = _.cloneDeep(tempChartsData);
    }

    props.loading.stop();

    return tempChartsData;
  };

  const handleWeatherRequestsGate = () => {
    setWeatherReqFlag(false);

    setTimeout(() => {
      setWeatherReqFlag(true);
    }, WEATHER_TIMER_VAL);
  };

  const startDevicesWeather = async (aircycler: any) => {
    for (let index = 0; index < aircycler.length; index++) {
      const { device_id, device_name, device_zipcode, device_city } = aircycler[
        index
      ];
      const stackDevice: any =
        STACKS.length > 0
          ? STACKS.find((stackData: any) => stackData.deviceId === device_id)
          : null;
      const isProbeConn: any = DevicesUtils.isProbeConnected(aircycler[index]);

      if ((!!device_zipcode || !!device_city) && weatherReqFlag) {
        if (!stackDevice?.stoped) {
          let devWeather: any = await props.requestWeather({
            deviceId: device_id,
          });

          if (devWeather) props.updateWeather({ ...devWeather }, device_id);
        }
      }
      if (isProbeConn?.connected) {
        let probeValues: any = DevicesUtils.selectProbeValues(aircycler[index]);

        props.updateG3Reads({ ...probeValues }, aircycler[index].device_id);
      }
    }

    if (weatherReqFlag) handleWeatherRequestsGate();
  };

  const goDeviceSettings = (id: any) => {
    const settingsDevice = devices.find(
      (device: any) => device.device_id === id,
    );
    props.setCurrentDevice({ ...settingsDevice });
    history.push(`dashboard/system-settings/${id}`);
  };

  const parseConnectedDeviceIndex = (devData: any) => {
    const { cidx }: any = devData || {};
    let parsedValue: number = 0;

    if (cidx !== null && cidx !== undefined) {
      if (typeof cidx === 'string') parsedValue = parseInt(cidx);
      else parsedValue = cidx;
    }

    return parsedValue;
  };

  const destroyIntervals = (intervals: any) => {
    if (intervals && Object.values(intervals).length > 0) {
      for (const interval in intervals) {
        clearInterval(intervals[interval]);
      }
    }
  };

  const systemNameHandler = useCallback((_device: object) => {
    const { details, device_name } = _device || {};

    if (device_name && device_name !== '') return device_name;
    else if (details?.settings?.g3id && details?.settings?.g3id !== '')
      return details.settings.g3id;

    return 'Aircycler G3';
  }, []);

  const handleTitleMargin = (
    index: number,
    name: string,
    option: string,
    stopped: any,
  ) => {
    const REF_NUM: number = 6;
    const REF_GAIN: number = 2.0313;
    let left: number = -1;
    const currTitleElem: any =
      titlesElements?.current?.length > 0
        ? titlesElements.current[index]
        : null;

    if (currTitleElem) {
      switch (option) {
        case 'stop':
          left = currTitleElem.offsetLeft + currTitleElem.offsetWidth + 5;
          break;
        case 'time':
          left =
            currTitleElem.offsetLeft +
            currTitleElem.offsetWidth +
            (stopped ? 50 : 40);
        default:
          break;
      }
      return left !== -1 ? { left: `${left}px` } : {};
    } else {
      if (name.length > 0) {
        switch (option) {
          case 'stop':
            left =
              name.length <= REF_NUM
                ? (27.3 * name.length) / 6
                : name.length * REF_GAIN - 2.2;
            break;
          case 'time':
          default:
            left =
              name.length <= REF_NUM
                ? (27.3 * name.length) / 6 + 2.2
                : name.length * REF_GAIN;
        }
      }
      return left !== -1 ? { left: `${left}vw` } : {};
    }
  };

  const checkIsFCPHVC = (_cdvs: any, prefix: string) => {
    let exist = false;
    if (_cdvs && _cdvs.length > 0)
      _cdvs.forEach(cdv => {
        if (cdv.cdid.startsWith(prefix)) {
          exist = cdv.cdid.startsWith(prefix);
        }
      });
    // return exist;
    //  IMPORTANT  ->  just for demo purpose, then we can remove the "return true"
    return true;
  };

  const handleTimeMessage = (time: any) => {
    let newMsm: any;

    if (time < 60) {
      newMsm = `${time} ${time > 1 ? 'seconds' : 'second'}`;
    } else if (time >= 60) {
      let minCalcs: number = time / 60;
      newMsm = `${parseInt(minCalcs)} ${
        parseInt(minCalcs) > 1 ? 'minutes' : 'minute'
      }`;
    } else if (time >= 60 * 60) {
      let hourCalcs: number = time / 60 / 60;
      newMsm = `${parseInt(minCalcs)} ${
        parseInt(hourCalcs) > 1 ? 'hours' : 'hour'
      }`;
    } else if (time >= 60 * 60 * 24) {
      let daysCalcs: number = time / 60 / 60 / 24;
      newMsm = `${parseInt(daysCalcs)} ${
        parseInt(daysCalcs) > 1 ? 'days' : 'day'
      }`;
    }

    return newMsm;
  };

  const handleTimeUpdates = (message: any) => {
    const { device_id } = message;
    let currentValue: number = 0;
    TIME_VALUES[device_id] = 0;

    if (TIME_INTERVALS[device_id]) {
      clearInterval(TIME_INTERVALS[device_id]);
      TIME_INTERVALS[device_id] = null;
    }

    let interval: any = setInterval(() => {
      TIME_VALUES[device_id] = TIME_VALUES[device_id] + 1;
      let msm: any = handleTimeMessage(TIME_VALUES[device_id]);

      setTimeLogs((prevTimes: any) => {
        let msmClone: any = _.cloneDeep(prevTimes);
        if (msm) {
          msmClone[device_id] = msm;
        }
        return msmClone;
      });
    }, 1000);

    TIME_INTERVALS[device_id] = interval;
  };


  const requestSystemsSetup = async (_devices: any) => {
    let tempSetups: object = _.cloneDeep(sysSetups);

    if (_devices && _devices.length > 0) {
      for (let index = 0; index < _devices.length; index++) {
        const { device_id } = _devices[index] || {};
        const setupResp: any = await props.getSysSetup({ id: device_id });

        if (!tempSetups[device_id]) tempSetups[device_id] = {};
        tempSetups[device_id] = { ...setupResp };
      }
    }

    setSysSetups({ ...tempSetups });
  };



  const requestSystemsStatus = async (_devices: any) => {
    let tempSysStates: object = _.cloneDeep(systemsStatus);

    if (_devices && _devices.length > 0) {
      for (let index = 0; index < _devices.length; index++) {
        const { device_id }: any = _devices[index] || {};
        const systemStatus: any = await props.getSystemStatus({
          system_id: device_id,
        });

        if (!tempSysStates[device_id]) tempSysStates[device_id] = {};

        tempSysStates[device_id] = { ...systemStatus };
      }
    }

    setSystemsStatus(
      _.cloneDeep(tempSysStates)
    );
  };



  const destroySockets = (sockets: object) => {
    if (Object.keys(sockets).length > 0) {
      Object.keys(sockets).forEach((socketKey: any) => {
        sockets[socketKey].close();
        delete sockets[socketKey];
      });
    }
  };

  const updateDevicesToBuild = (receivedData: any) => {
    setDevicesToBuild((prevDevices: any) => {
      let prevClone: any = _.cloneDeep(prevDevices);
      const devIndex: any = prevClone.findIndex((dev: any) => {
        return dev.details.id === receivedData.device_id;
      });

      if (devIndex > -1) {
        const { details } = receivedData;
        prevClone[devIndex].details.settings = _.cloneDeep(details);
      }

      return _.cloneDeep(prevClone);
    });
  };

  const mapSensorType = (devType: string) => {
    switch (devType) {
      case 'aircycler-local':
        return 'aircycler-sensor';
      default:
        return devType;
    }
  };

  const filterDevicesByType = (_devices: any) => {
    let filterdDevices: any = {};
    if (_devices && Array.isArray(_devices)) {
      _devices.forEach((_device: any) => {
        const tempType: string = mapSensorType(_device?.device_type);

        if (!filterdDevices[tempType]) {
          filterdDevices[tempType] = [];
        }
        filterdDevices[tempType].push({ ..._device });
      });
    }
    return filterdDevices;
  };

  const updateGlobalStateDevices = async (state: any) => {
    const updatedDevices: any = await props.getDeviceMethod2();
    let tempDevices: any = filterDevicesByType(updatedDevices);
    const { aircycler } = tempDevices || {};

    const devClone: any = _.cloneDeep({ ...GLOBAL_SCOPE.devices });
    GLOBAL_SCOPE.devices.old = state
      ? _.cloneDeep([...aircycler] || [])
      : _.cloneDeep([...devClone.new]);

    if (aircycler) GLOBAL_SCOPE.devices.new = _.cloneDeep([...aircycler]);
  };

  const handleStackUpdate = async (system: any, ping: any) => {
    const { details, device_id }: any = ping;
    let resultStack: any = await props.systemChartData({ ...system });
    let upStack: any;

    if (resultStack) {
      const { inside, outside, minutes, percentage, stoped }: any = resultStack;

      upStack = {
        chartData: {
          vent: [...outside],
          exhaust: [...inside],
        },
        percentage: { ...percentage },
        deviceId: system.device_id,
        detailsId: system.details.id,
        minutes: { ...minutes },
        stoped,
      };

      // to control probe air flow values -> updates
      setProbeValuesCtrl((prevValues: any) => {
        return {
          ...prevValues,
          [system.device_id]: true,
        };
      });
    }

    if (upStack) {
      const storedSliceIndex: any = STACKS.findIndex(
        (slice: any) => slice.detailsId === ping.device_id,
      );

      if (storedSliceIndex > -1) {
        let stackClone: any = _.cloneDeep([...STACKS]);
        stackClone[storedSliceIndex] = { ...upStack };

        setChartsData([...stackClone]);
        STACKS = _.cloneDeep([...stackClone]);
      }
    }
  };

  const receiveWsMessage = (_device: object, event: any) => {
    if (event?.data) {
      let deviceClone = { ..._device };
      const receivedData: any = JSON.parse(event.data);

      // for development use, then delete
      const { device_name, device_id } = deviceClone;
      const { time } = receivedData;

      // if(device_id === TEMP_DEVICE_ID){
      // console.log(
      //   '1 : MESSAGE RECEIVED:::  ',
      //   device_name, '     ->      ',
      //   device_id,
      //   receivedData,
      //   ` \n - ->  ->  HAS TIME FLAG:  `, time,
      // );
      // }

      if (time) {
        handleTimeUpdates(receivedData);
        handleStackUpdate(deviceClone, receivedData);
      }
      updateDevicesToBuild({ ...receivedData });
    }
  };

  const handleWsConnectionClosed = (_device: object, event: any) => {
    console.log('SOCKET DISCONNECTED...     ', event?.reason);
    if (event?.reason === 'Going away') {
      const { device_id } = _device;
      const newSocket: any = setWsDevice(_device);
      WEBSOCKETS[device_id] = newSocket;
    }
  };

  // setup websockets to watch devices updates
  const setWsDevice = (device: object) => {
    const { api_key } = device;
    const skUrl = `${SOCKET_URL}?x-iot-key=${api_key}`;
    let socket: any = new WebSocket(skUrl);
    socket.addEventListener('message', receiveWsMessage.bind(null, device));
    socket.addEventListener(
      'close',
      handleWsConnectionClosed.bind(null, device),
    );
    return socket;
  };

  const stoppedDeviceTooltip = () => {
    return <p style={{ marginTop: 0, marginBottom: 0 }}>Disconnected.</p>;
  };









  // to setup websockets to watch new data comming
  useEffect(() => {
    if (
      devices &&
      devices.length > 0
    ) {
      // props.loading.start('Loading...');
      let tempDevices: any = filterDevicesByType(devices);
      const { aircycler, foobot, airthings, awair, aircyclerSensor }: any =
        tempDevices || {};
      let allSensors: any = [];
      let tempProbCtrl: any = {};

      if (aircycler && aircycler.length > 0) {
        handleInitSliceStacks([...aircycler]);
        requestSystemsSetup(aircycler);
        requestSystemsStatus(aircycler);

        aircycler.forEach(async (_device: any) => {
          const { device_name, device_id } = _device;

          if (!WEBSOCKETS[device_id]) {
            let tSocket: any = setWsDevice(_device);
            WEBSOCKETS[device_id] = tSocket;
          }

          if (!tempProbCtrl.hasOwnProperty(device_id)) {
            tempProbCtrl[device_id] = false;
          }
        });

        setDevicesToBuild([...aircycler]);
        setProbeValuesCtrl(tempProbCtrl);
        // handleInitSliceStacks([...aircycler]);

        // if (isRestarting) {
        //   setIsRestarting(false);
        // }
      }

      if (foobot && foobot.length > 0) {
        allSensors = allSensors.concat(foobot);
      }

      if (airthings && airthings.length > 0) {
        allSensors = allSensors.concat(airthings);
      }

      if (awair && awair.length > 0) {
        allSensors = allSensors.concat(awair);
      }

      if (
        tempDevices['awair-element'] &&
        tempDevices['awair-element'].length > 0
      ) {
        allSensors = allSensors.concat(tempDevices['awair-element']);
      }

      if (tempDevices['awair-omni'] && tempDevices['awair-omni'].length > 0) {
        allSensors = allSensors.concat(tempDevices['awair-omni']);
      }

      if (
        tempDevices['aircycler-sensor'] &&
        tempDevices['aircycler-sensor'].length > 0
      ) {
        allSensors = allSensors.concat(tempDevices['aircycler-sensor']);
      }

      if (allSensors && allSensors.length > 0) {
        setSensors(allSensors);
        setHasFoobotConn(true);
      }

      // props.loading.stop();
    }

    return () => {};
  }, [devices]);


  useEffect(() => {
    if (devicesToBuild.length > 0) {
      startDevicesWeather(devicesToBuild);

      if (!isStackInit) {
        for (const dev of devicesToBuild) {
          const { details } = dev;

          if (details) {
            const { id, settings } = details;
            handleTimeUpdates({
              device_id: id,
              details: settings,
            });
          }
        }

        setIsStackInit(true);
      }
    }

    return () => {};
  }, [devicesToBuild]);


  useEffect(() => {
    mounted.current = true;

    (async () => {
      props.loading.start('Loading...');
      await props.getDevices();
      props.loading.stop();
    })();

    return () => {
      setDevicesToBuild([]);
      setProbeValuesCtrl({});
      destroySockets(WEBSOCKETS);
      setIsStackInit(false);
      destroyIntervals(TIME_INTERVALS);
      mounted.current = false;
    };
  }, []);



  return (
    <>
      <UserTemplate id="air-dashboard--top" title="Dashboard">
        <div className="chart-container">
          {devicesToBuild && devicesToBuild.length > 0 ? (
            devicesToBuild.map((device: any, index: number) => {
              const { device_id, details }: any = device || {};

              return (
                <div key={`wrapper-${index + 1}`} className="chart-wrapper">
                  <div className="chart-wrapper--title-container">
                    <div className="col--left" />


                    <div className="col-center">
                      <div
                        className={`wrapper--title ${
                          timeLogs && details?.id && timeLogs[details.id]
                            ? 'extra-mg'
                            : ''
                        }`}
                      >
                        <h3 ref={el => (titlesElements.current[index] = el)}>
                          {systemNameHandler(device)}
                        </h3>
                        {chartsData[index] && chartsData[index]?.stoped && (
                          <div
                            className="stop-icon-wrapper"
                            style={handleTitleMargin(
                              index,
                              systemNameHandler(device),
                              'stop',
                            )}
                          >
                            <TooltipView title={stoppedDeviceTooltip()}>
                              <div className="c-stop-icon">
                                <FaExclamation size="1rem" color="red" />
                              </div>
                            </TooltipView>
                          </div>
                        )}
                        {timeLogs && details?.id && timeLogs[details.id] && (
                          <div
                            className="title-time-logs"
                            style={handleTitleMargin(
                              index,
                              systemNameHandler(device),
                              'time',
                              chartsData[index]?.stoped,
                            )}
                          >
                            Last updated {timeLogs[details.id]} ago
                          </div>
                        )}
                      </div>
                    </div>


                    <div className="col--right">
                      <div className="action--menu">
                        <GoSettings
                          size="1.5rem"
                          onClick={() => goDeviceSettings(device?.device_id)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="chart-wrapper--container-cf">
                    <div className="wrapper-col-lateral col--left">
                      <div className="wrapper-col-inside">
                        <CentralFanState
                          key={`state--cfan`}
                          title={'Central Fan'}
                          stoped={chartsData[index]?.stoped}
                          {...VentUtils.centralFanStates(
                            device.details.settings,
                          )}
                        />
                      </div>
                    </div>
                    <div className="wrapper-col-center">
                    </div>
                    <div className="wrapper-col-lateral col--right">
                    </div>
                  </div>

                  <div className="chart-wrapper--container">
                    <div className="wrapper-col-lateral col--left">
                      {device?.details?.settings?.conf?.cdvs.length > 0 &&
                        device?.details?.settings?.conf?.cdvs.map(
                          (connDevice: any, i: number) => {
                            const connDevIndex: number = parseConnectedDeviceIndex(
                              connDevice,
                            );
                            const connDevType: any = VentUtils.checkTyoe(
                              connDevice,
                            );
                            const {
                              statesFunc,
                              titleFunc,
                              devType,
                              version,
                            } = connDevType;
                            const connDevProps: any = statesFunc
                              ? VentUtils[statesFunc](connDevice)
                              : VentUtils.ventConnectLightStates(connDevice);
                            const title: any = titleFunc
                              ? StringsUtils[titleFunc](
                                  connDevice,
                                  connDevIndex,
                                  version,
                                )
                              : StringsUtils.wrappVentTitle(
                                  connDevice,
                                  connDevIndex,
                                );
                            const { cdid }: any = connDevice || {};
                            const subTitle: any =
                              cdid && cdid !== ''
                                ? cdid
                                : StringsUtils.subTitleByDeviceType(devType);

                            switch (devType) {
                              case 3:
                                return <div key={`state--${i}`}></div>;
                              case 2:
                                return (
                                  <FreshConnectState
                                    key={`state--${i}`}
                                    index={i}
                                    stoped={chartsData[index]?.stoped}
                                    title={title}
                                    subTitle={subTitle}
                                    devData={connDevice || {}}
                                    {...connDevProps}
                                  />
                                );
                              case 1:
                                return (
                                  <VentilationState
                                    key={`state--${i}`}
                                    index={i}
                                    stoped={chartsData[index]?.stoped}
                                    title={title}
                                    subTitle={subTitle}
                                    {...connDevProps}
                                  />
                                );
                              default:
                                return null;
                            }
                          },
                        )}
                      {(device?.details?.settings?.conf?.fcsp === 1 ||
                        device?.details?.settings?.conf?.fcsp === '1') && (
                        <VentilationState
                          key={`state--fan`}
                          index={0}
                          stoped={chartsData[index]?.stoped}
                          title={'FanConnect'}
                          {...VentUtils.fanLightStates(
                            device.details.settings.conf,
                          )}
                        />
                      )}

                    </div>

                    <div className="wrapper-col-center">
                      {chartsData[index] && (
                        <ChartItem
                          key={'chart--' + index}
                          position={index}
                          title={''}
                          ventPercent={chartsData[index]?.percentage?.done || 0}
                          minutes={chartsData[index]?.minutes || { _left: 0 }}
                          data={chartsData[index]?.chartData || []}
                          stoped={chartsData[index]?.stoped}
                          device={device}
                          setup={
                            sysSetups[device_id] ? sysSetups[device_id] : {}
                          }
                        />
                      )}
                    </div>
                    <div className="wrapper-col-lateral col--right">
                      <div className="widgets--wrapper">
                        <div className="legend-main">
                          <ChartLegendMain
                            data={chartsData[index]?.chartData || []}
                          />
                        </div>
                        <div className="legend-complement">
                          <ComplementaryLegend
                            data={chartsData[index]}
                            system={device}
                            id={chartsData[index]?.deviceId}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="chart-wrapper--widgets">
                    <div className="widget-row">
                      <div className="widget-col w--left">
                        <OutsideAir
                          device={device}
                          stackData={
                            STACKS && STACKS.length > 0
                              ? STACKS.find(
                                  (stackData: any) =>
                                    stackData?.deviceId === device_id,
                                )
                              : null
                          }
                          deviceSettings={details?.settings}
                          setup={
                            sysSetups[device_id] ? sysSetups[device_id] : {}
                          }
                          ctrlValue={
                            probeValuesCtrl && probeValuesCtrl[device_id]
                              ? probeValuesCtrl[device_id]
                              : false
                          }
                          setCtrl={setProbeValuesCtrl}
                          status={systemsStatus[device_id]}
                        />
                      </div>
                      <div className="widget-col w--right">
                        <IndoorAir
                          device={device}
                          connection={hasFoobotConn}
                          sensors={sensors}
                          setup={
                            sysSetups[device_id] ? sysSetups[device_id] : {}
                          }
                          isMounted={mounted?.current}
                          status={systemsStatus[device_id]}
                        />
                      </div>
                    </div>

                    <div className="widget-row">
                      {checkIsFCPHVC(
                        device?.details?.settings?.conf?.cdvs,
                        'AirCyclerFH',
                      ) && (
                        <div className="widget-col w--left">
                          <ManualControl
                            device={device}
                            setup={
                              sysSetups[device_id] ? sysSetups[device_id] : {}
                            }
                            status={systemsStatus[device_id]}
                          />
                        </div>
                      )}
                      {checkIsFCPHVC(
                        device?.details?.settings?.conf?.cdvs,
                        'AirCyclerPC',
                      ) && (
                        <div className="widget-col w--right">
                          <PressureConnect
                            device={device}
                            setup={
                              sysSetups[device_id] ? sysSetups[device_id] : {}
                            }
                            status={systemsStatus[device_id]}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <NoDevices />
          )}
        </div>
      </UserTemplate>
    </>
  );
};



// <div className="wrapper-col-inside">
//   <CentralFanState
//     key={`state--cfan`}
//     title={'Central Fan'}
//     stoped={chartsData[index]?.stoped}
//     {...VentUtils.centralFanStates(
//       device.details.settings,
//     )}
//   />
// </div>



function mapStateToProps(state: any) {
  const { devices, foobot, env } = state;
  return {
    devices: devices.devices,
    thirdParty: {
      foobot: {
        connected: foobot.isConnected,
        devices: foobot.fooDevices,
      },
    },
    weather: env.weather,
    ventStack: env.ventStack,
  };
}

function mapDispatchToProps(dispatch: any) {
  const {
    env,
    devices,
    loader,
    systemSettings,
  }: any = dispatch || {};

  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    setCurrentDevice: dispatch.devices.setCurrentDevice,
    getDevices: devices.getAllDevices,
    getSystemStatus: devices.getSystemStatus,
    getDeviceMethod2: devices.getAllDevicesWithoutUpdateState,
    thirdPartyFuncs: {},
    updateVentStack: env.updateVentStack,
    requestWeather: env.requestWeather,
    updateWeather: env.updateWeather,
    updateG3Reads: env.updateG3Reads,
    getSysSetup: systemSettings.getSystemSetupBySysId,
    systemChartData: env.requestSystemChartData,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
