const returnLabel = (sectionName: string) => {
  switch (sectionName) {
    case 'dev':
    case 'sens':
    case 'sys':
      return 'No data yet!';
    case 'oa':
      return 'Outside Air';
    case 'ia':
      return 'Indoor Air';
    case 'fc':
      return 'Fresh Connect';
    case 'pc':
      return 'Pressure Connect';
    case 'mc':
      return 'Manual Control';
    default:
      return '';
  }
};

export const helpers = {
  returnLabel: returnLabel,
};
