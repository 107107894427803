// @ts-nocheck
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { InstallerOutsideAirProps } from './interface';
import { RiSave3Fill } from 'react-icons/ri';
import { DoubleSlider, SingleSlider, GradSlider } from '../common';
import { Notification } from 'react-components';
import { SwitchButton } from './components';
import { preparePayload } from '../../helpers';
import config from './config';
import './styles.scss';


const COMPONENT_STRINGS: any = { ...config?.strings };
const DEFAULT_EXTREMS: any = { ...config.sliderValues };
const DEFAULT_VALUES: any = { ...config.sliderValues.defaults };

const InstallerOutsideAir: React.FC<InstallerOutsideAirProps> = ({
  loading,
  showBtnCtrl,
  configShowData,
  oaConfig,
  prepareToSaveSetupStore,
  handleConfigValidation,
  ...props
}) => {
  const [
    outsideAirSectionIsEnabled,
    setOutsideAirSectionIsEnabled,
  ]: boolean = useState(false);

  const [showAlert, setShowAlert]: boolean = useState(false);
  const [showSaveBtn, setShowSaveBtn]: any = useState(false);
  const [widgetStates, setWidgetStates]: any = useState(oaConfig);

  const toggleSection = (status: any) => {
    setOutsideAirSectionIsEnabled(!status);
    setShowSaveBtn(true);
  };

  const onChangeTemps = (newValues: any) => {
    setWidgetStates((prevValues: any) => {
      return {
        ...prevValues,
        temp: {
          ...prevValues.temp,
          min: newValues[0],
          max: newValues[2],
        },
      };
    });

    setShowSaveBtn(true);
    showBtnCtrl(true);
  };

  const onChangeHumidity = (newValues: any) => {
    setWidgetStates((prevValues: any) => {
      return {
        ...prevValues,
        hum: {
          ...prevValues.hum,
          min: newValues[0],
          max: newValues[1],
        },
      };
    });

    setShowSaveBtn(true);
    showBtnCtrl(true);
  };

  const onChangeAqi = (newValues: any) => {
    setWidgetStates((prevValues: any) => {
      return {
        ...prevValues,
        aqi: {
          ...prevValues.aqi,
          min: newValues[0],
          max: newValues[1],
        },
      };
    });

    setShowSaveBtn(true);
    showBtnCtrl(true);
  };

  const transformSingleSlider = useCallback((values: any) => {
    const { max, min, current } = values;
    return [current, max];
  });

  const transformDoubleSlider = useCallback((values: any) => {
    const { max, min, current } = values;
    return [min, current, max];
  });

  const prepareToSaveSetup = (values: any, enabled: boolean) => {
    const { temp, aqi, hum, cfm } = values;

    let tempSetup: object = {
      temp: {
        max: temp.max,
        min: temp.min,
      },
      hum: hum.max,
      aqi: aqi.max,
      cfm: cfm.max,
      turnedOn: enabled,
    };

    return {
      setup: {
        outsideAir: { ...tempSetup },
      },
    };
  };

  const handleSaveOutsideData = async (event: any) => {
    let errValidation = await handleConfigValidation(true);

    if (errValidation) {
      setShowAlert(false);
    }else{
      setShowSaveBtn(false);
    }
  };

  useEffect(() => {
    prepareToSaveSetupStore(
      prepareToSaveSetup(widgetStates, outsideAirSectionIsEnabled)
    );
  }, [widgetStates, outsideAirSectionIsEnabled]);


  return (
    <div className="" id="installer--outside-air">
      <div className="switch-box">
        <div className="row">
          <div className="installer--outside-air--title">
            <SwitchButton
              stringInfo={COMPONENT_STRINGS.title}
              id="outside-section"
              onChange={() => {
                toggleSection(outsideAirSectionIsEnabled);
              }}
              value={outsideAirSectionIsEnabled}
            />
          </div>
        </div>
      </div>

      <div className="install-add-outside-sliders-wrapper">
        <div className="row">
          <div className="inst-slider-wrapper">
            <DoubleSlider
              id="w-temp-inst"
              values={transformDoubleSlider(widgetStates.temp)}
              max={DEFAULT_EXTREMS.temp.max}
              min={DEFAULT_EXTREMS.temp.min}
              step={1}
              col={2}
              showLabel={true}
              symbol="ºF"
              onChange={onChangeTemps}
              defaultMarks={true}
              defaultMarksValues={{
                left: {
                  top: 39,
                  left: 21.5,
                  value: DEFAULT_VALUES.temp.min,
                },
                right: {
                  top: 40,
                  right: 32,
                  value: DEFAULT_VALUES.temp.max,
                },
              }}
            />
          </div>

          <div className="inst-slider-wrapper">
            <SingleSlider
              id="w-rhum-inst"
              values={transformSingleSlider(widgetStates.hum)}
              max={DEFAULT_EXTREMS.hum.max}
              min={DEFAULT_EXTREMS.hum.min}
              step={1}
              col={2}
              showLabel={true}
              symbol="%"
              onChange={onChangeHumidity}
              defaultMark={true}
              defaultMarkValues={{
                top: 40,
                left: 79.7,
                value: DEFAULT_VALUES.hum.max,
              }}
            />
          </div>
          <div className="">
            <GradSlider
              id="w-aqi-inst"
              values={transformSingleSlider(widgetStates.aqi)}
              max={DEFAULT_EXTREMS.aqi.max}
              min={DEFAULT_EXTREMS.aqi.min}
              step={1}
              col={2}
              showLabel={true}
              symbol=""
              onChange={onChangeAqi}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-1 offset-md-11 col-save-install-indoor-values">
          {showSaveBtn && (
            <button
              className="btn save-configs-btn-purple"
              onClick={handleSaveOutsideData}
            >
              <RiSave3Fill />
              <span className="save-span">
                SAVE SETUP
              </span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  const { installerConfigs } = state;
  return {
    configShowData: installerConfigs.configShowData,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { installerConfigs, loader } = dispatch;
  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    prepareToSaveSetupStore: installerConfigs.prepareToSaveSetupStore,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InstallerOutsideAir);
