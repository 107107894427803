import { ConnectionBroker } from '../../core';
import config from '../config/config';

export const adminInstallersState = {
  state: {
    installerIdToUpdate: '',
    userStartedToType: false,
    installerItem: {},
    userCleared: true,
    userClearedEd: true,
    newInstaller: {
      name: '',
      email: '',
      phone: '',
      code: '',
      city: '',
      state: '',
      zip: '',
    },
    mandatoryFieldsMissing: true,
    errors: { mandatoryFieldsMissing: 'Please fill all the required data.' },
    mandatoryEditionFieldsMissing: false,
    editionErrors: {
      mandatoryEditionFieldsMissing: 'Please fill all the required data.',
    },
    message: ``,
    installersList: [],
    installersListIsLoaded: false,
    installersCounter: 0,
    installersIntendedCounter: { name: 0, location: 0, devs: 0 },
    orderedInstallersList: false,
    orders: {
      orderInstallersListByLocation: false,
      orderInstallersListByDevs: false,
      orderInstallersListByName: false,
    },
    updatedInstaller: {
      name: '',
      email: '',
      phone: '',
      code: '',
      city: '',
      state: '',
      zipcode: '',
    },
    currInstallerToEdit: {},
  },
  reducers: {
    setToEdit(state: any, payload: any) {
      localStorage.setItem('currUpdatingInstaller', JSON.stringify(payload));
      let inst: any = localStorage.getItem('currUpdatingInstaller');
      let parsed;
      if (inst) {
        parsed = JSON.parse(inst);
      }
      return {
        ...state,
        currInstallerToEdit: parsed,
      };
    },
    saveUpdatingInstaller(state: any, payload: any) {
      return { ...state, updatedInstaller: payload, userClearedEd: false };
    },
    defInstallerToEdit(state: any, payload: string) {
      return { ...state, installerIdToUpdate: payload };
    },
    setError(state: any, payload: any) {
      if (payload === '') {
        return {
          ...state,
          mandatoryFieldsMissing: false,
        };
      }
      if (payload === 'mandatoryFieldsMissing') {
        return {
          ...state,
          mandatoryFieldsMissing: true,
          errors: {
            mandatoryFieldsMissing:
              config.adminInstallersState.mandatoryFieldsMissing,
          },
        };
      }
      return { ...state };
    },
    setUserIsTyping(state: any, payload: any) {
      if (payload) {
        return {
          ...state,
          userStartedToType: true,
        };
      } else {
        return {
          ...state,
        };
      }
    },
    clearNewInstaller(state: any) {
      return {
        ...state,
        userCleared: true,
        newInstaller: {
          name: '',
          email: '',
          phone: '',
          code: '',
          city: '',
          state: '',
          zip: '',
        },
      };
    },
    saveNewInstaller(state: any, payload: any) {
      return { ...state, newInstaller: payload, userCleared: false };
    },
    clearUpdatedInstaller(state: any) {
      return {
        ...state,
        userClearedEd: true,
        updatedInstaller: {
          name: '',
          email: '',
          phone: '',
          code: '',
          city: '',
          state: '',
          zipcode: '',
        },
      };
    },
    saveInstallerItemGlobally(state: any, payload: any) {
      localStorage.setItem('@installerItem', JSON.stringify(payload));
      const persistentInstaller = localStorage.getItem('@installerItem');
      let parsed;

      if (persistentInstaller) {
        parsed = JSON.parse(persistentInstaller);
      }

      return {
        ...state,
        installerItem: parsed,
      };
    },
    handleInstallersListCounterAndOrder(
      state: any,
      payload: any,
      counter: number,
    ) {
      return {
        ...state,
        installersIntendedCounter: payload,
        installersCounter: counter,
      };
    },
    updateOrderState(state: any, payload: string) {
      switch (payload) {
        case 'Name':
          return {
            ...state,
            orderedInstallersList: false,
            orders: {
              orderInstallersListByLocation: false,
              orderInstallersListByDevs: false,
              orderInstallersListByName: true,
            },
          };
        case 'Location':
          return {
            ...state,
            orderedInstallersList: false,
            orders: {
              orderInstallersListByLocation: true,
              orderInstallersListByDevs: false,
              orderInstallersListByName: false,
            },
          };
        case 'Devices':
          return {
            ...state,
            orderedInstallersList: false,
            orders: {
              orderInstallersListByLocation: false,
              orderInstallersListByDevs: true,
              orderInstallersListByName: false,
            },
          };
        case 'Default':
          return {
            ...state,
            orderedInstallersList: true,
            orders: {
              orderInstallersListByLocation: false,
              orderInstallersListByDevs: false,
              orderInstallersListByName: false,
            },
          };
        default:
          return {
            ...state,
            orderedInstallersList: true,
            orders: {
              orderInstallersListByLocation: false,
              orderInstallersListByDevs: false,
              orderInstallersListByName: false,
            },
          };
      }
    },
    storeInstallersList(state: any, payload: any) {
      return {
        ...state,
        installersList: payload,
        installersListIsLoaded: true,
      };
    },
  },
  effects: (dispatch: any) => ({
    async updateCurrInstaller(data: any) {
      //TODO update installer fetch
    },
    async createANewInstaller(createdInstallerData: any) {
      const that: any = this;
      //TODO create installer fetch
    },
    async getTheInstallersList() {
      const that: any = this;
      //TODO get installers list fetch
    },
  }),
};
