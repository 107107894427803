import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import {
  TableRow,
  TableCell,
} from '@material-ui/core';
import _ from 'lodash';
import {
  ThresholdTableHeader,
  TableHeader,
} from './components';
import config from './config';
import './styles.scss';


const TABLE_COLUNMS: any = [...config.colunms];
const THRESH_TAGS: any = [...config.threshTags];


interface ThresholdRowSecProps {
  data: any;
  columns: any;
  show?: any;
  top?: any;
}

const ThresholdRowSec: React.FC<ThresholdRowSecProps> = ({
  data,
  columns,
  show,
  top,
  ...props
}) => {
  const [dataValues, setDataValues]: any = useState({});


  useEffect(() => {
    if(data && Object.keys(data).length > 0){
      setDataValues(data);
    }
  }, [data]);


  return (
    <div
      id="thresh-row-sec--container"
      style={{
        display: show ? 'block' : 'none',
      }}
    >
      <div className="thresh-row-sec--container">
        <ThresholdTableHeader values={dataValues} />
        <TableHeader columns={columns} />
      </div>
    </div>
  );
}

export default ThresholdRowSec;
