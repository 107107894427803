// import { ConnectionBroker } from '../../core';

export const searchUsersState = {
  state: {
    searchTermInput: '',
    everyPeoplesList: [],
    everyPplListLoaded: false,
    resultsFromUserSearch: [],
    resultsAreLoaded: false,
  },
  reducers: {
    storeResultsFromSearch(state: any, payload: any) {
      return {
        ...state,
        resultsFromUserSearch: payload,
        resultsAreLoaded: true,
      };
    },

    //fill a list with every user /account
    fillEveryPeopleList(state: any, payload: any) {
      return {
        ...state,
        everyPeoplesList: payload,
        everyPplListLoaded: true,
      };
    },

    defSearchTerm(state: any, payload: any) {
      return {
        ...state,
        searchTermInput: payload,
      };
    },
  },
  effects: (dispatch: any) => ({}),
};
