import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';


interface CrumbItemProps {
  to: any;
  glyph?: any;
}

const CrumbItem: React.FC<CrumbItemProps> = ({ to, glyph, ...props }) => (
  <div className="air-breadcrumb" to={to}>
    <Breadcrumb.Item {...props}></Breadcrumb.Item>
  </div>
);

export default CrumbItem;
