import React, { useState } from 'react';
import config from './config';
import { ActionsMenuProps } from './interface';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import './styles.scss';

const ActionsMenu: React.FC<ActionsMenuProps> = ({
  data,
  onDelete,
  onEdit,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const onclickActions = (zone: any, event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div id="menu--config--container">
      <IconButton
        id="more-button--001"
        aria-controls={`actions-menu-${data.device_id}`}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={onclickActions.bind(null, data)}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        id={`actions-menu-${data.device_id}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'more-button001',
        }}
      >
        <MenuItem onClick={onEdit.bind(null, data, handleClose)}>
          {config?.menuItems?.edit}
        </MenuItem>
        <MenuItem onClick={onDelete.bind(null, data, handleClose)}>
          {config?.menuItems?.delete}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ActionsMenu;
