import {
  TempIcon,
  HumIcon,
  FlowIcon,
  FanIcon,
} from '../../../../../../../../../assets/sensors-icons';
import { WavesIcon } from '../../../../../../../../../assets/icons';
import numeral from 'numeral';

const utils = {
  defaultCFM: {min: 30, max: 200},
  selectData: (id: string) => {
    switch (id) {
      case 'w-temp':
        return {
          _class: 'i-temp',
          left: {
            isIcon: true,
            item: TempIcon,
          },
          right: 'ºF',
        };
      case 'w-rhum':
        return {
          _class: 'i-hum',
          left: {
            isIcon: true,
            item: HumIcon,
          },
          right: 'RH',
        };
      case 'w-cfm':
        return {
          _class: 'i-cfm',
          left: {
            isIcon: true,
            item: WavesIcon,
          },
          right: 'CFM',
        };
      case 'w-pm25':
        return {
          _class: 'l-tvoc',
          left: {
            isIcon: false,
            item: 'PM 2.5',
          },
          right: 'ug/m3',
        };
      case 'w-co2':
        return {
          _class: 'l-co2',
          left: {
            isIcon: false,
            item: 'CO2',
          },
          right: 'ppm',
        };
      case 'w-tvoc':
        return {
          _class: 'l-tvoc',
          left: {
            isIcon: false,
            item: 'tVOC',
          },
          right: 'ppb',
        };
      case 'w-p-conn':
        return {
          _class: 'w-paConn',
          left: {
            isIcon: false,
            item: 'Pa',
          },
          right: '',
        };
      default:
        return;
    }
  },
  mapNewValues: (value: any, defaults: any) => {
    const { min, max } = defaults;
    return [utils.defaultCFM.min, value, utils.defaultCFM.max];
  },
};

export default utils;
