export default {
  strings: {
    title: 'Devices List',
    devices: {
        noDevices: 'You do not have any device on this zone.'
    }
  },
  alerts: {
    delete: {
        question: 'Are you sure you want to delete device: ',
        msm: 'You wont be able to revert the operation!',
        btn: {
            confirm: 'Yes, delete it!'
        }
    }
  },
  notifications: {
    delete: {
        msm: 'Device deleted successfully!'
    }
  },
  fieldsState: {},
  fieldsFocus: {},
  fields: {},
  constraints: {}
};
