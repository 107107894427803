import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { DataLoggs } from './pages';


const logging_path = '/data-logging';

const DataLoggingRoutes: React.FC = ({ ...props }) => {

  return (
    <>
      <BreadcrumbsItem to={`/dashboard${logging_path}`} >
        Data Logging
      </BreadcrumbsItem>
      <Switch>
        <Route exact path={`/dashboard${logging_path}`} component={DataLoggs} />
      </Switch>
    </>
  );
};

export default DataLoggingRoutes;
