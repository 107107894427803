import React from 'react';
import { FooterProps } from './interface';
import { Button } from 'business/modules/admin/common';
import { HiOutlineXCircle } from 'react-icons/hi';
import './styles.scss';

const Footer: React.FC<FooterProps> = ({
  icon,
  onClick,
  label,
  className,
  hasConfirmBtn,
  onClickCancel,
}) => {
  return (
    <div className="modal-footer" id="modal--footer--1">
      <Button
        type="button"
        className="small-button dismiss-btn"
        dataDismiss={`modal`}
        onClick={onClickCancel}
        spanClass={`btn-child-ft`}
      >
        <HiOutlineXCircle className="cancel" /> {`EXIT `}
      </Button>
      {hasConfirmBtn && (
        <Button
          className={className}
          type="button"
          spanClass={`btn-child-ft`}
          onClick={onClick}
        >
          {icon}
          {label}
        </Button>
      )}
    </div>
  );
};

export default Footer;
