import React, { useState } from 'react';
import { Grid, Slider } from '@material-ui/core';
import './styles.scss';


interface CustomSliderProps {
  id?: string;
  label?: any;
  value?: any;
  onChange?: any;
  max?: any;
  min?: any;
  step?: any;
}

const CustomSlider: React.FC<CustomSliderProps> = ({
  id,
  label,
  value,
  onChange,
  max,
  min,
  step,
  ...props
}) => {
  return (
    <Grid item>
      <div id={id} className="periph-csl">
        <div className="periph-csl--values-wrapper">
          <label className="periph-csl--values-label">{label}</label>
          <label className="periph-csl--values-display">
            {value ? value : 0}
          </label>
        </div>
        <Slider
          value={value}
          max={max}
          min={min}
          step={step}
          onChange={onChange}
          aria-labelledby="continuous-slider"
        />
      </div>
    </Grid>
  );
};

export default CustomSlider;
