export default {
  strings: {
    title: 'Api Request'
  },
  default: {
    tab: {
      id: '',
      title: '',
      data: {
        link: {
          value: { id: 1, link: 'requests/logs-list', title: 'List Logs' },
          func: {
            onChange: () => {},
          },
        },
        params: {
          rows: [{id: 0, key: '', value: ''}],
          func: {
            onAddRow: () => {},
            onRemoveRow: () => {},
            onValueChange: () => {},
            onKeyChange: () => {},
          },
        },
        headers: {
          rows: [{id: 0, key: '', value: ''}],
          func: {
            onAddRow: () => {},
            onRemoveRow: () => {},
            onValueChange: () => {},
            onKeyChange: () => {},
          },
        },
        body: {
          value: '{\n\n}',
          func: {
            onChange: () => {}
          },
        },
        response: '{}',
        // search: {
        //   value: '',
        //   func: {
        //     onOpenSearch: () => {},
        //     onCloseSearch: () => {},
        //     onSearch: () => {},
        //   },
        // },
      },
    },
    dataTemplate: {
      link: {
        value: { id: 1, link: 'requests/logs-list', title: 'List Logs' },
        func: {
          onChange: () => {},
        },
      },
      params: {
        rows: [{id: 0, key: '', value: ''}],
        func: {
          onAddRow: () => {},
          onRemoveRow: () => {},
          onValueChange: () => {},
          onKeyChange: () => {},
        },
      },
      headers: {
        rows: [{id: 0, key: '', value: ''}],
        func: {
          onAddRow: () => {},
          onRemoveRow: () => {},
          onValueChange: () => {},
          onKeyChange: () => {},
        },
      },
      body: {
        value: '{\n\n}',
        func: {
          onChange: () => {}
        },
      },
      response: '{}',
      // search: {
      //   value: '',
      //   func: {
      //     onOpenSearch: () => {},
      //     onCloseSearch: () => {},
      //     onSearch: () => {},
      //   },
      // },
    },
    requests: {
      links: [
        { id: 1, link: 'requests/logs-list', title: 'List Logs' },
        { id: 2, link: 'requests/systems-list', title: 'List Systems' },
        { id: 3, link: 'requests/sensors-list', title: 'List Sensors' },
      ],
    },
    templateRow: { id: 0, key: '', value: '' },
  },
}
