import React from 'react';
// import Logo from '../../../../assets/logo-color.svg';
import { AirCyclerLogoSVG, AirCyclerLogoPNG } from '../../../../assets/logos';
import { useHistory } from 'react-router-dom';

const Header: React.FC = () => {
  const history = useHistory();
  const path = window.location.pathname;

  return (
    <div className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4  bg-white border-bottom box-shadow pt-4 pb-4 justify-content-between">
      <img
        src={AirCyclerLogoPNG}
        alt="logo"
        height={'88px'}
        className={'cursor-pointer'}
        onClick={() => history.push('/')}
      />
      <div>
        {path !== '/admin/login' && (
          <div>
            <button
              className="btn btn-primary mr-3 text-white"
              onClick={() => history.push('/login')}
            >
              Login
            </button>
            <button
              className="btn btn-outline-primary"
              onClick={() => history.push('/signup')}
            >
              Register
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
