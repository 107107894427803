/* tslint:disable */
// @ts-nocheck
import React, { useState, useEffect, useCallback, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Sector,
  Legend,
  Customized,
} from 'recharts';
import {
  ActiveShape,
  ActiveShape1,
  CustomClock,
  DamperComponent,
} from './components';
import config from './config';
import './styles.scss';

let CONFIG: any = { ...config };
let CHARTS_COLORS: any = { ...config.chartsColors };
let ACTIVE_DATA: any = {};

interface ChartItemProps {
  title?: any;
  ventPercent?: any;
  minutes?: any;
  position?: any;
  data?: any;
  device?: any;
  setHoverCfItem?: Function;
  setHoverExItem?: Function;
  setHoveringState: Function;
  chartHoveringCfState: any;
  chartHoveringExState: any;
  menuOpen: any;
  stoped: any;
}

const ChartItem: React.FC<ChartItemProps> = ({
  title,
  ventPercent,
  position,
  data,
  device,
  minutes,
  chartHoveringCfState,
  chartHoveringExState,
  menuOpen,
  stoped,
  ...props
}) => {
  const { charts }: any = { ...CONFIG };
  const [layoutSize, setLayoutSize]: any = useState([0, 0]);
  const [deviceId, setDeviceId]: any = useState(device?.device_id);
  const [hasVentConnect, setHasVentConnect]: boolean = useState(true);
  const [ventData, setVentData]: any = useState([]);
  const [exhaustData, setExhaustData]: any = useState([]);
  const [activeIndex, setActiveIndex]: any = useState(0);
  const [activeIndexExhaust, setActiveIndexExhaust]: any = useState(0);
  const [activeClockIndex, setActiveClockIndex]: any = useState(-1);
  const [vtPercentage, setVtPercentage] = useState(0);
  const [damper, setDamper]: any = useState(false);
  const [isLocked, setIsLocked]: boolean = useState(false);
  // const [menuState, setMenuState]: any = useState(menuOpen);
  const [activeData, setActiveData]: any = useState({});
  const [currentInterval, setCurrentInterval]: any = useState([]);


  const colorPicker: any = (entry: any, isRunning: any) => {
    const { colors }: any = entry || {};
    const { cat, type }: any = colors || {};
    let currentColor: any = cat && type && CHARTS_COLORS[cat][type]
      ? CHARTS_COLORS[cat][type]
      : CHARTS_COLORS.freeTime;

    if(!isRunning){
      if(currentColor.length > 7){
        let colorChars: string = currentColor.slice(0, 7);
        currentColor = `${colorChars}70`;
      }else{
        currentColor = `${currentColor}70`;
      }
    }

    return currentColor
  };

  const colorClockPicker: any = (entry: any) => {
    const { type } = entry || {};
    return '#727478';
  };

  const checkMode = (cdvs: any, hiredFan: any) => {
    if(
      (cdvs && cdvs.length > 0)
      || (hiredFan && hiredFan === 1 || hiredFan === '1')
    ){
      return true;
    }
    return false;
  };


  const handleMouseHover = useCallback((event: any, index: number) => {
    const { device_id }: any = device || {};

    if(!stoped){
      setActiveIndex(index);
      props.setHoveringState({ id: device_id, value: true }, 'cf');
    }
  }, [device, stoped]);

  const onMouseLeaveCfHover = useCallback((event: any, index: number) => {
    const { device_id }: any = device || {};

    if(!stoped){
      props.storeHoveringState(false);
      props.setHoveringState({ id: device_id, value: false }, 'cf');
    }
  }, [device, stoped]);


  const handleMouseHoverExhaust = useCallback((event: any, index: number) => {
    const { device_id }: any = device || {};

    if(!stoped){
      setActiveIndexExhaust(index);
      props.setHoveringState({ id: device_id, value: true }, 'ex');
    }
  }, [device, stoped]);

  const onMouseLeaveExHover = useCallback((event: any, index: number) => {
    const { device_id }: any = device || {};

    if(!stoped){
      props.storeHoveringState(false);
      props.setHoveringState({ id: device_id, value: false }, 'ex');
    }
  }, [device, stoped]);


  const initHandleActiveData = (_device: any) => {
    const { device_name, device_id }: any = _device;
    let intervalClone: any = _.cloneDeep(currentInterval);

    if(intervalClone && intervalClone.length > 0){
      for (const _interval of intervalClone) {
        clearInterval(_interval);
      }
    }

    // to update custom-clock data, every 1sec
    let interval: any = setInterval(() => {
      if(ACTIVE_DATA){
        setActiveData(
          _.cloneDeep(ACTIVE_DATA)
        );
      }
    }, 2000);

    intervalClone.push(interval);
    setCurrentInterval(intervalClone);

    setTimeout(() => {
      if(intervalClone && intervalClone.length > 0){
        for (const _interval of intervalClone) {
          clearInterval(_interval);
        }

        setCurrentInterval([]);
      }
    }, (1000 * 60));
  };

  useLayoutEffect(() => {
    const updateSize = () => {
      setLayoutSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);




  useEffect(() => {
    if(activeIndexExhaust !== null && activeIndexExhaust !== undefined){
      const { device_id }: any = device || {};

      if(device_id){
        if(ventData && ventData[activeIndexExhaust]){
          props.setHoverExItem({ id: device_id, data: ventData[activeIndexExhaust] });
        }
      }
    }
  }, [activeIndexExhaust]);

  useEffect(() => {
    if(activeIndex !== null && activeIndex !== undefined){
      const { device_id }: any = device || {};

      if(device_id){
        if(exhaustData && exhaustData[activeIndex]){
          props.setHoverCfItem({ id: device_id, data: exhaustData[activeIndex] });
        }
      }
    }
  }, [activeIndex]);

  useEffect(() => {
    if (device) {
      const { dmst, cdvs, fcsp } = device?.details?.settings?.conf || {};
      setDamper(!!dmst);
      setHasVentConnect(checkMode(cdvs, fcsp));
      setDeviceId(device.device_id);

      if(!isLocked){
        setActiveIndex(1);
        setActiveIndexExhaust(1);
        setIsLocked(true);
      }
    }
  }, [device]);

  useEffect(() => {
    setVtPercentage(parseInt(ventPercent));
  }, [ventPercent]);

  useEffect(() => {
    const { vent, exhaust } = data || {};
    if (vent && vent.length > 0) {
      setVentData([...vent]);
    }

    if (exhaust && exhaust.length > 0) {
      setExhaustData([...exhaust]);
    }
  }, [data]);

  useEffect(() => {
    initHandleActiveData(device);
  }, [menuOpen, layoutSize]);

  useEffect(() => {
    initHandleActiveData(device);

    return () => {
      // clear all intervals that are updating custom-clock data
      if(currentInterval?.length > 0){
        for (const interval of currentInterval) {
          clearInterval(interval);
        }
      }
    };
  }, []);


  return (
    <div className="chart-container--item">
      <h4 className="chart-container--title">
        {title}
      </h4>
      <DamperComponent
        status={damper}
        stoped={stoped}
      />
      <ResponsiveContainer
        id={`container-${position}`}
        width="100%"
        height="100%"
      >
        {hasVentConnect ? (
          <PieChart width="100%" height="100%">
            <Pie
              data={ventData || []}
              cx="42%"
              cy="50%"
              innerRadius="74%"
              outerRadius="80%"
              dataKey="y"
              nameKey="x"
              startAngle={90}
              endAngle={-270}
              onMouseEnter={handleMouseHoverExhaust}
              onMouseLeave={onMouseLeaveExHover}
              activeIndex={activeIndexExhaust}
              activeShape={_props => (
                <ActiveShape
                  vtPercentage={vtPercentage}
                  minutes={minutes}
                  stoped={stoped}
                  {..._props}
                />
              )}
            >
              {ventData.length > 0 &&
                ventData.map((entry: any, index: number) => {
                  return (
                    <Cell key={`cell_3-${index}`} fill={colorPicker(entry, !stoped)} />
                  );
                })}
            </Pie>
            <Pie
              data={exhaustData || []}
              cx="42%"
              cy="50%"
              innerRadius="68%"
              outerRadius="74%"
              dataKey="y"
              nameKey="x"
              startAngle={90}
              endAngle={-270}
              onMouseEnter={handleMouseHover}
              onMouseLeave={onMouseLeaveCfHover}
              activeIndex={activeIndex}
              activeShape={_props => {
                const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle } = _props;
                const { device_id } = device;

                if(cx !== null && cx !== undefined && !isNaN(cx)){
                  ACTIVE_DATA[device_id] = { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle };
                }

                return (
                  <ActiveShape1
                    vtPercentage={vtPercentage}
                    minutes={minutes}
                    stoped={stoped}
                    {..._props}
                  />
                );
              }}
            >
              {exhaustData.length > 0 &&
                exhaustData.map((entry: any, index: number) => {
                  return (
                    <Cell key={`cell_3-${index}`} fill={colorPicker(entry, !stoped)} />
                  );
                })}
            </Pie>
            <Customized component={
              <>
                <CustomClock
                  activeData={activeData ? activeData[device?.device_id] : null}
                  menuOpen={menuOpen}
                  deviceId={deviceId}
                  stoped={stoped}
                />
              </>
            } />
          </PieChart>
        ) : (
          <PieChart width="100%" height="100%">
            <Pie
              data={exhaustData || []}
              cx="42%"
              cy="50%"
              innerRadius="72%"
              outerRadius="80%"
              dataKey="y"
              nameKey="x"
              startAngle={90}
              endAngle={-270}
              onMouseEnter={handleMouseHover}
              onMouseLeave={onMouseLeaveCfHover}
              activeIndex={activeIndex}
              activeShape={_props => {
                const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle } = _props;
                const { device_id } = device;

                if(cx !== null && cx !== undefined && !isNaN(cx)){
                  ACTIVE_DATA[device_id] = { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle };
                }

                return (
                  <ActiveShape
                    vtPercentage={vtPercentage}
                    minutes={minutes}
                    stoped={stoped}
                    {..._props}
                  />
                );
              }}
            >
              {exhaustData.length > 0 &&
                exhaustData.map((entry: any, index: number) => {
                  return (
                    <Cell key={`cell_3-${index}`} fill={colorPicker(entry, !stoped)} />
                  );
                })}
            </Pie>
            <Customized component={
              <>
                <CustomClock
                  activeData={activeData ? activeData[device?.device_id] : null}
                  menuOpen={menuOpen}
                  deviceId={deviceId}
                  stoped={stoped}
                />
              </>
            } />
          </PieChart>
        )}
      </ResponsiveContainer>
    </div>
  );
};


function mapStateToProps(state: any) {
  const { navigationalState, aside } = state;

  return {
    chartHoveringCfState: navigationalState.chartHoveringCfState,
    chartHoveringExState: navigationalState.chartHoveringExState,
    menuOpen: aside.menuOpen,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { navigationalState } = dispatch;

  return {
    storeHoveringState: navigationalState.storeHoveringState,
    setHoveringState: navigationalState.setHoveringState,
    setHoverCfItem: navigationalState.setHoverCfItem,
    setHoverExItem: navigationalState.setHoverExItem,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartItem);
