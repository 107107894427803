import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';

interface ActionsMenuProps {
  data?: any;
  parent?: any;
  handleEdit: () => void;
}

const ActionsMenu: React.FC<ActionsMenuProps> = ({
  data,
  parent,
  handleEdit,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const onclickActions = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickEdit = () => {
    handleEdit();
    handleClose();
  };

  return (
    <div>
      <IconButton
        id="more-button"
        aria-controls={`actions-menu`}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={onclickActions}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id={`actions-menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'more-button',
        }}
      >
        <MenuItem onClick={onClickEdit}>Edit</MenuItem>
      </Menu>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {};
}

function mapDispatchToProps(dispatch: any) {
  return {
    setCurrentDevice: dispatch.devices.setCurrentDevice,
    setPeripheral: dispatch.devices.setPeripheral,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionsMenu);
