export default {
  strings: {
    main: `Company overview`,
    lead: `Check some information about `,
    cardTitle: `Main data`,
    cardSubtitle: `If you wish to update the current data, please select the Edit option (Companies List menu).`,
  },
  keys: {
    status: `Status`,
    createdAt: `Created at`,
    updatedAt: `Updated at`,
    _id: `id`,
    name: `Name`,
    code: `Code`,
    contact: `Contact`,
    state: `State`,
    city: `City`,
    zipcode: `Zip code`,
    company_id: `Company id`,
    __v: `__v`,
  },
  tooltip: {
    title: `Grab the code`,
    txt: `Copy the code to clipboard`,
  },
};
