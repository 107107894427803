import React, { useState, useEffect } from 'react';
import config from './config';
import { connect } from 'react-redux';
import { functions } from './helpers';
import { AddInstallerProps } from './interface';
import { useHistory } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { HiOutlineXCircle } from 'react-icons/hi';
import { RiSave3Fill, RiEdit2Line } from 'react-icons/ri';
import { isValidPhoneNumber } from 'react-phone-number-input';
import {
  Template,
  Button,
  Input,
  Phone,
  Label,
  Modal,
} from 'business/modules/admin/common';
import './styles.scss';

const AddInstaller: React.FC<AddInstallerProps> = ({
  clearNewInstaller,
  compItem,
  setUserIsTyping,
  saveCompItemGlobally,
  newInstaller,
  saveNewInstaller,
}) => {
  const history = useHistory();
  const [userCleared, setUserCleared] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [errors, setErrors]: any = useState({
    name: '',
    email: '',
    phone: '',
    state: '',
    zip: '',
    city: '',
  });

  const handlePhoneChange = (value: any, country: any, formattedValue: any) => {
    setErrors({ ...errors, phone: '' });
    saveNewInstaller({ ...newInstaller, phone: '+' + value });

    if (!isValidPhoneNumber('+' + value)) {
      setErrors({ ...errors, phone: 'Invalid phone number.' });
    } else {
      setErrors({ ...errors, phone: '' });
    }
  };

  const getUserInput = (event: any) => {
    setUserIsTyping(true);
    let name: string = event.target.name;
    let value: any = event.target.value;

    let validate = functions.handleErrors(name, value);

    setErrors({ ...errors, [name]: validate });

    saveNewInstaller({
      ...newInstaller,
      [name]: value,
    });
  };

  const returnVal = (elem: any) => {
    return newInstaller[elem.name as keyof typeof newInstaller];
  };

  const capitalizeFirstChar = (str: string) => {
    let first = str[0].toUpperCase();
    let rest = str.slice(1);
    return str === 'zip' ? 'Zip Code' : first.concat(rest);
  };

  useEffect(() => {
    saveNewInstaller({ ...newInstaller, code: compItem.code });
  }, [compItem.code]);

  useEffect(() => {
    const currentCompany: any = localStorage.getItem('@companyItem');
    let parsed: any;
    if (currentCompany) {
      parsed = JSON.parse(currentCompany);

      saveCompItemGlobally(parsed);
    }
  }, []);

  //clear data when user quits adding installer
  useEffect(() => {
    if (userCleared) {
      saveNewInstaller({
        name: '',
        email: '',
        phone: '',
        state: '',
        zip: '',
        city: '',
        code: compItem?.code,
      });

      setErrors({
        name: '',
        email: '',
        phone: '',
        state: '',
        zip: '',
        city: '',
      });
    }
  }, [userCleared]);

  useEffect(() => {
    let errs: any = Object.values(errors).filter(
      (item: any) => item.length > 0,
    );

    let fields: any = Object.values(newInstaller).filter(
      (item: any) => item.length > 0,
    );

    if (errs.length === 0 && fields.length === 7) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [errors]);

  return (
    <Template
      id="installers-add"
      title={config.strings.add.title}
      goBack={() => {
        history.push('/admin/dashboard/companies-list/company/installers');
      }}
    >
      <BreadcrumbsItem to={`/admin/dashboard/company/installers/add-edit`}>
        {config.strings.add.breadcrumbTitle}
      </BreadcrumbsItem>

      <div id="add-installer--page">
        <div className="jumbotron container-add min--h">
          <h4 className="centered">{config.strings.add.mainTitle} </h4>
          <p className="lead centered card-top-ft">
            {config.strings.add.pText}
          </p>
          <hr className="my-4" />
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 offset-md-3">
                {config.fields.map((elem: any, index: number) => {
                  if (elem.id === `telephone-input-spec1`) {
                    return (
                      <div
                        className="form-group form-group-edit-ins1"
                        key={index}
                      >
                        <Label>{capitalizeFirstChar(elem.name)}</Label>
                        <Phone
                          className="form-group form-group-edit-ins1  lighter btm-space-mb1 plpr01"
                          label={elem}
                          disabled={false}
                          key={index}
                          value={newInstaller?.phone}
                          onChange={(
                            value: any,
                            country: any,
                            e: any,
                            formattedValue: any,
                          ) =>
                            handlePhoneChange(value, country, formattedValue)
                          }
                          name={elem.name}
                        />
                        <div className="render-error">
                          {errors && errors[elem.name] ? errors[elem.name] : ''}
                        </div>
                      </div>
                    );
                  }

                  return (
                    <div
                      className="form-group form-group-edit-ins1"
                      key={index}
                    >
                      <Label>{capitalizeFirstChar(elem.name)}</Label>
                      <div className="input-group mbottom-01">
                        <Input
                          type={elem.type}
                          className={elem.className}
                          id={elem.id}
                          name={elem.name}
                          aria-describedby={elem.ariaDescribedBy}
                          placeholder={elem.placeholder}
                          children={elem.label}
                          value={returnVal(elem)}
                          onChange={getUserInput}
                        />
                      </div>
                      <div className="render-error">
                        {errors && errors[elem.name] ? errors[elem.name] : ''}
                      </div>
                    </div>
                  );
                })}

                <div className="form-group form-group-edit-ins1">
                  <Label>Company Code</Label>
                  <div className="input-group mbottom-01">
                    <Input
                      type={'text'}
                      className={'form-control mb-2'}
                      id={'installer-id2'}
                      name={'installer-id'}
                      aria-describedby={''}
                      placeholder={'Generated ID'}
                      value={compItem?.code || ''}
                      disabled={true}
                    />
                    <div className="input-group-append"></div>
                  </div>
                </div>
                <div className="container" id="create--installer">
                  <div className="row d-flex justify-content-center">
                    <div className="col-12 pdg1">
                      <Button
                        className="small-button dismiss-btn float-right-btn mr2"
                        onClick={() => {
                          saveNewInstaller({
                            name: '',
                            email: '',
                            phone: '',
                            state: '',
                            zip: '',
                            city: '',
                            code: compItem?.code,
                          });

                          setUserCleared(true);
                        }}
                      >
                        <span className="span--icon btn-child-ft">
                          <HiOutlineXCircle className="cancel" /> CANCEL{' '}
                        </span>
                      </Button>

                      <Button
                        className="small-button ok float-right-btn"
                        dataToggle={'modal'}
                        dataTarget={config.strings.add.dataTarget}
                        disabled={disabled}
                        type="button"
                        onClick={() => {}}
                      >
                        <span className="span--icon btn-child-ft">
                          <RiSave3Fill className="edit" /> SAVE{' '}
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        config={config}
        id={`save-changes-to-new-installer`}
        title={config.modalStrings.edit}
        icon={<RiEdit2Line className="edit" />}
        body={config?.modalStrings?.aboutToUpdate}
        label={config?.modalStrings?.labelOK}
        className={`small-button ok`}
        bodyClass={`alert alert-info`}
        sureToProceed={config?.modalStrings?.sureToProceed}
        hasConfirmBtn={true}
        warning={config?.modalStrings?.noUndo}
        onClickCancel={clearNewInstaller}
        onClick={() => {
          /*Todo: this will confirm adding the installer */
        }}
        //handle modal automatic closing when
        //there is a real update going on:
        /* modalMustClose={modalMustClose}
        handleModalState={handleModalState}*/
        modalMustClose={false}
        handleModalState={() => {}}
      />
    </Template>
  );
};

function mapStateToProps(state: any) {
  const { adminInstallersState, adminCompaniesState } = state;
  return {
    installerItem: adminInstallersState.installerItem,
    compItem: adminCompaniesState.compItem,
    newInstaller: adminInstallersState.newInstaller,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { adminInstallersState, adminCompaniesState } = dispatch;
  return {
    clearNewInstaller: adminInstallersState.clearNewInstaller,
    saveCompItemGlobally: adminCompaniesState.saveCompItemGlobally,
    setUserIsTyping: adminInstallersState.setUserIsTyping,
    saveNewInstaller: adminInstallersState.saveNewInstaller,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddInstaller);
