import React, { Fragment } from 'react';
import { Container, Top, Footer, Info } from './components';
import config from './config';
import './styles.scss';

interface DeleteAccountProps {}

const DeleteAccount: React.FC<DeleteAccountProps> = () => {
  
  return (
    <div className="container main-wrapper" id="delete-instructions">
      <h2 className="mt-3 mb-3 text-center">{`Delete account`}</h2>
      <div className="row">
        <div className="instructions">
          <Top config={config} />
          <Container cClass={`container`} rClass={`row r--1`} cCls={`col-12`}>
            <ul className="list-group">
              {config?.strings?.slice(1).map((section: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <li className="list-group-item lead lead--1">
                      {section.title}
                    </li>
                    <div className="container details--del--acc">
                      <div className="row ml-1">
                        <div className="col-12">
                          {section.id === `how-to-delete` ? (
                            <Info
                              section={section}
                              additionalInfo={
                                <div className="col-12 ml-2">
                                  <li>{section.li1}</li>
                                  <li>{section.li2}</li>
                                </div>
                              }
                            />
                          ) : (
                            <Info section={section} />
                          )}
                        </div>
                      </div>
                    </div>
                  </Fragment>
                );
              })}
            </ul>
          </Container>
        </div>

        <Footer title={config?.questionsTitle} body={config?.questionsBody} />
      </div>
    </div>
  );
};

export default DeleteAccount;
