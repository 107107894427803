import React from 'react';
import {
  Select,
} from '@material-ui/core';
import './styles.scss';


interface PageSizeProps {
  options: any;
  pageSize: any;
  onPageSizeChange: any;
}

const TablePageSize: React.FC<PageSizeProps> = ({
  options,
  pageSize,
  onPageSizeChange,
  ...props
}) => {

  return (
    <td className="dt-ps-select-container">
      <Select
        native
        value={pageSize}
        onChange={onPageSizeChange}
      >
        {options?.length > 0 && options.map((item: any, index: number) => {
          return (
            <option key={index} value={item.value}>{item?.label || ''}</option>
          );
        })}
      </Select>
    </td>
  );
};

export default TablePageSize;
