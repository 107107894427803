import React from 'react';
import { IndoorAndOutsideSectionProps } from './interface';
import { InstallerIndoorAir, InstallerOutsideAir } from '..';
import './styles.scss';

const IndoorAndOutsideSection: React.FC<IndoorAndOutsideSectionProps> = ({
  loading,
  oaConfig,
  showBtnCtrl,
  handleConfigValidation,
  showBtn,
  isMounted,
}) => {
  return (
    <div className="m-container">
      <div className="row">
        <div className="col-xs-12 col-sm-12">
          <InstallerOutsideAir
            loading={loading}
            oaConfig={oaConfig}
            handleConfigValidation={handleConfigValidation}
            showBtnCtrl={showBtnCtrl}
            showBtn={showBtn}
          />
        </div>

        <div className="col-xs-12 col-sm-12 installer-indoor-air-container">
          <InstallerIndoorAir
            isMounted={isMounted}
            loading={loading}
            handleConfigValidation={handleConfigValidation}
            showBtnCtrl={showBtnCtrl}
            showBtn={showBtn}
          />
        </div>
      </div>
    </div>
  );
};

export default IndoorAndOutsideSection;
