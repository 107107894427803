export default {
  strings: {
    title: 'Peripheral Setup',
    labels: {},
    buttons: {},
  },
  timersVals: {
    weather: (1000 * 60 * 30),  // 30min  
  },
  charts: {
    colors: {
        default: ['#9adbd7', '#eeeeee', '#2e3191', '#eeeeee', '#FF6800', '#eeeeee', '#10a9a0', '#eeeeee', '#00a3de', '#eeeeee', '#fedc1d', '#eeeeee', '#e77d33', '#eeeeee', '#a52a2a', '#eeeeee', '#6495ed', '#eeeeee'],
        damper: '#9adbd7',
        ventilator: '#2e3191',
        exhautor: '#10a9a0',
        thermostat: '#00a3de',
        termination: '#eeeeee'
    },
  },
  propsToCompare: {
    connected: 'cdst',
    ventilationAqi: 'exwm',
    timeToRun: 'mner',
    thermostat: 'thsa'
  },
  checkConnect: {
    thermostat: 'thsa',
    sensor: 'stmp',
    connected: 'cdst',
    ventilationAqi: 'exwm'
  },
  knownCalls: ['thermostatEllapsed', 'thermostatRemain'],
  timeStack: {
    default: ['timeEllaped', 'timeRemain', 'aircyclerRemain']
  },
  alerts: {},
  notifications: {},
  fieldsState: {},
  fieldsFocus: {},
  fields: {},
  constraints: {},
};
