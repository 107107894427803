import React from 'react';
import { TopProps } from './interface';

const Top: React.FC<TopProps> = ({ children, id }) => {
  return (
    <div
      className="modal fade"
      id={id}
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle2"
      aria-hidden="true"
    >
      {children}
    </div>
  );
};

export default Top;
