import React from 'react';
import { ButtonProps } from './interface';
import './styles.scss';

const Button: React.FC<ButtonProps> = ({
  children,
  dataToggle,
  dataTarget,
  dataDismiss,
  id,
  className,
  type,
  onClick,
  disabled,
  spanClass,
}) => {
  return (
    <button
      id={id}
      data-dismiss={dataDismiss}
      className={className}
      data-toggle={dataToggle}
      data-target={dataTarget}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      <span className={spanClass}>{children}</span>
    </button>
  );
};

export default Button;
