import React from 'react';
import { IoCheckmark } from 'react-icons/io5';

export default {
  outsideAir: {
    setup: {
      weatherInterval: 1000 * 60 * 30,
    },
    strings: {
      title: 'Outside Air',
      stLabel: '@outside-air::',
      addLocation: `ADD LOCATION `,
      noConfig: `This user has not configured Outside Air settings for
      this device.`,
      noRequests:
        'Add device location (city/zip code) to get local outdoor air quality data and manage incoming air.',
      labels: {
        temp: 'ºF',
        rhum: '% RH',
        cfm: 'CFM',
        airq: 'AQI',
      },
      confirmation: `You are about to update the Outside Air configurations.`,
      sureToProceed: `Are you sure you wish to proceed?`,
    },
    titles: {
      oa: `Update configuration`,
    },
    icons: {
      oa: <IoCheckmark className="edit" />,
    },
    labels: {
      oa: ` OK`,
    },
    bodies: {
      oa: ``,
    },
    bodyClass: {
      oa: `alert alert-danger`,
    },
    alerts: {
      save: {
        question:
          'Are you sure you want to save the new Outside Air configuration?',
        btn: {
          confirm: 'Yes, save it!',
        },
      },
    },
    formats: {
      temp: '0,0',
      hum: '0',
      cfm: '0,0',
      airq: '0,0',
    },
    model: {
      max: -101,
      min: -101,
      current: 0,
      init: false,
    },
    sliderValues: {
      temp: {
        max: 120,
        min: -20,
      },
      hum: {
        max: 100,
        min: 0,
      },
      aqi: {
        max: 500,
        min: 0,
      },
      cfm: {
        max: 200,
        min: 0,
      },
      defaults: {
        temp: { max: 75, min: 10 },
        hum: { max: 80 },
        aqi: { max: 100 },
        cfm: { max: 50 },
      },
    },
    notifications: {
      update: {
        success: 'Outside Air setup was saved.',
      },
    },
    weatherValInitState: {
      ctmp: null,
      crhr: null,
    },
    tooltip: {
      title: `Outside Air (OA)`,
      p: `Temperature (°F) and humidity (RH) data sourced from your local weather
        station or FlowProbe™ (if installed). Select high and low outdoor air
        temperature limits for your AirCycler® Connect System.`,
      span: `Air Quality Index (AQI)`,
      p1: `sourced by airnow.gov/ using location data provided.`,
      span2: `Cubic Feet per Minute (CFM)`,
      p2: `airflow data sourced from FlowProbe™ (if installed).`,
    },
    onOffTooltip: {
      title: `Enable / disable section`,
      str: `These changes will affect the user's configurations. Are you sure you want to proceed?`,
    },
  },
  modalStrings: {
    warning: 'Information',
  },
};
