import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Notification } from '../../../../../react-components';
import { IconsHandler } from '../../../../../core/utils';
import './styles.scss';
import {
  ConnectedIcon,
  DisconnectedIcon,
} from '../../../../assets';
import { ActionsMenu, EmptyList } from '../';
import config from './config';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));


interface LoadingProps {
  start: any;
  stop: any;
}

interface DevicesListProps {
  devices?: any;
  setCurrentDevice: any;
  deleteDevice: any;
  loading: LoadingProps;
}

const DevicesList: React.FC<DevicesListProps> = ({ devices, ...props }) => {
  const history = useHistory();
  const [showDeleteAlert, setShowDeleteAlert]: any = useState(false);
  const [deviceToDelete, setDeviceToDelete]: any = useState(null);

  const onClickItem = (device: any, event: any) => {
    props.setCurrentDevice(device);
    history.push(`/dashboard/devices/details?id=${device?.device_id}`);
  };

  const onDeleteDevice = (device: object) => {
    setDeviceToDelete(device);
    setShowDeleteAlert(true);
  };

  const deviceIcon = (type: string) => {
    const Icon: any = IconsHandler.deviceIconByType(type);
    return <Icon/>;
  };

  const deleteDevice = async () => {
    setShowDeleteAlert(false);
    props.loading.start('Deleting Device');
    const { device_id } = deviceToDelete;
    props.deleteDevice({ device_id }).then((resp: any) => {
      props.loading.stop();
      if (resp?.statusCode === 200) {
        Notification({
          title: 'Successfully',
          message: config?.notifications?.delete?.msm,
          type: 'success',
        });
      }
    });
  };

  useEffect(() => {
    if (!showDeleteAlert) setDeviceToDelete(null);
  }, [showDeleteAlert]);

  return (
    <>
      {devices && devices.length > 0 ? (
        <List id="devices-list">
          {devices.map((device: any, index: number) => {
            return (
              <ListItem
                key={`${device.device_id}--${index}`}
                alignItems="flex-start"
                onClick={onClickItem.bind(null, device)}
              >
                <ListItemAvatar>
                  {deviceIcon(device?.device_type)}
                </ListItemAvatar>
                <ListItemText>{device.device_name}</ListItemText>
                <ListItemText className="mac-address">
                  {device.details.id}
                </ListItemText>
                <ListItemAvatar>
                  {device?.status === 'active' ? (
                    <ConnectedIcon />
                  ) : (
                    <DisconnectedIcon />
                  )}
                </ListItemAvatar>
                <ListItemSecondaryAction>
                  <ActionsMenu data={device} onDelete={onDeleteDevice} />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      ) : (
        <EmptyList title="devices">
          <p>{config?.strings?.devices?.noDevices}</p>
        </EmptyList>
      )}
      <SweetAlert
        danger
        show={showDeleteAlert}
        showCancel
        confirmBtnText={config?.alerts?.delete?.btn?.confirm}
        confirmBtnBsStyle="danger"
        title={`${config?.alerts?.delete?.question} "${deviceToDelete?.device_name}"?`}
        onConfirm={deleteDevice}
        onCancel={() => setShowDeleteAlert(false)}
        focusCancelBtn
      >
        {config.alerts.delete.msm}
      </SweetAlert>
    </>
  );
};

function mapStateToProps(state: any) {
  return {};
}

function mapDispatchToProps(dispatch: any) {
  return {
    loading: {
      start: dispatch.loader.startLoader,
      stop: dispatch.loader.stopLoader,
    },
    setCurrentDevice: dispatch.devices.setCurrentDevice,
    deleteDevice: dispatch.devices.deleteDevice,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DevicesList);
