export default {
  strings: {
    title: 'System Details',
    labels: {
      firmware: 'Firmware Version: ',
      macAddrr: 'Mac Address: ',
      upTime: 'Up Time: ',
      conDevices: 'Connected Devices: ',
      fanCon: 'Fan Connected/Switch Present',
      damper: 'Damper Status',
      currentTemp: 'Current Temperature',
      currentFlow: 'Current Flow',
      relativeHumid: 'Current Relative Humidity',
    },
    titles: {
      peripherals: 'Peripherals',
      connDevices: 'Connected Devices',
    },
    warns: {
      noDevices: 'Controller does not have any connected device.',
    },
  },
  menu: {
    options: []
  },
  systemProps: {
    toIgnore: ['ctmp', 'crhr', 'cflw'],
  },
  connectedDevicesLabels: {
    ventConnect: {
      left: {
        dmac: 'Mac Address',
        vcct: 'Control Target Flag',
        cpri: 'Priority',
        vcdt: 'Exhaust Fan Delay Time',
        mvcr: 'Minutes per Hour Exhaust Run',
        cdst: 'Device State',
        frte: 'Fan runtime elapsed',
        vcfr: 'Exhaust Fan Flow Rate',
      },
      right: {
        cail: 'Device Alias',
        slst: 'Switch Status Light State',
        cdid: 'Device ID',
        pcbv: 'PCB Revision',
        cidx: 'Device Index Position',
        vcwm: 'Exhaust Worker Mode',
        fmwv: 'Firmware version',
        cdss: 'Device RSSI',
      },
    },
    freshConnect: {
      left: {
        dmac: 'Mac Address',
        cdid: 'Device ID',
        phas: 'Phase Angle',
        cflw: 'Current Flow Reading',
        estt: 'Energy Read Status',
        frep: 'Forward Reactive Energy Pulses',
        faep: 'Forward Active Energy Pulses',
        cpri: 'Priority',
        pfct: 'Power Factor',
        vrms: 'RMS Voltage',
        fmwv: 'Firmware version',
        crhr: 'Relative Humidity',
        vfrq: 'Voltage Frequency',
      },
      right: {
        rrep: 'Reverse Reactive Energy Pulses',
        pcst: 'Probe Connection Status',
        prob: 'Probe',
        mnap: 'Mean Active Power',
        aaep: 'Absolute Active Energy Pulses',
        mapp: 'Mean AP Power',
        mnrp: 'Mean Reactive Power',
        cdss: 'Device RSSI',
        raep: 'Reverse Active Energy Pulses',
        ddia: 'Air Duct Diameter',
        arep: 'Absolute Reactive Energy Pulses',
        ctmp: 'Current Temperature',
        irms: 'RMS Current',
      },
    },
    pressureConnect: {
      left: {
        dmac: 'Mac Address',
        cdid: 'Device ID',
        cflw: 'Current Flow Reading',
        cpri: 'Priority',
        dfps: 'Differential Pressure',
        stmp: 'Sensor Temperature',
        ctmp: 'Current Temperature',
        crhr: 'Relative Humidity',
      },
      right: {
        cail: 'Device Alias',
        cdss: 'Device RSSI',
        cidx: 'Device Index Position',
        fmwv: 'Firmware version',
        pcst: 'Probe Connection Status',
        prob: 'Probe',
        ddia: 'Air Duct Diameter',
      },
    },
    fanConnect: {
      // not yet supported, need props to display
    },
  },
  fieldsState: {},
  fieldsFocus: {},
  fields: {},
  constraints: {},
};
