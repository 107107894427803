import React from 'react';

import TooltipView from 'react-components/components/Tooltip';
import './styles.scss';

interface ChartLegendProps {
  index: number;
  entry: any;
  color?: any;
}

const ChartLegend: React.FC<ChartLegendProps> = ({
  index,
  entry,
  color,
  ...props
}) => {

  const legendMapper = (value: string) => {
    switch (value) {
      case 'timeEllapsed':
      case 'mixTimeEllapsedB':
        return 'Time Ellapsed';
      case 'timeRemain':
      case 'mixTimeRemainB':
        return 'Time Remaining';
      case 'aircyclerEllapsed':
        return 'AirCycler Ventilation (Done)';
      case 'aircyclerRemain':
        return 'AirCycler Ventilation';
      case 'thermostatEllapsed':
      case 'thermostatRemain':
        return 'Thermostat Event';
      case 'sensorEllapsed':
        return 'Sensor Done (Sensor Call)';
      case 'sensorRemain':
        return 'Sensor Ventilation';
      case 'ventilationAqiRemain':
      case 'ventilationAqiEllapsed':
        return 'IAQ Ventilation';
      case 'ventilator1Ellapsed':
        return 'VentConnect 1';
      case 'ventilator2Ellapsed':
        return 'VentConnect 2';
      case 'ventilator3Ellapsed':
        return 'VentConnect 3';
      case 'ventilator4Ellapsed':
        return 'VentConnect 4';
      case 'bathroomExhaust':
        return 'VentConnect Event';
      case 'mixTimeRemain':
        return 'Mix Time Remaining';
      case 'mixTimeEllapsed':
        return 'Mix Time Ellapsed';
      default:
        return value;
    }
  };

  return (
    <div id="chart-legend" className="legend-container">
      <span className="legend-rect" style={{ backgroundColor: color }}></span>
      <TooltipView title={legendMapper(entry.x)}>
        <span className="legend-text cut-text">{legendMapper(entry.x)}</span>
      </TooltipView>
    </div>
  );
};

export default ChartLegend;
