import React from "react";
import Logo from "../../../business/assets/logo-icone.svg";
import styles from "./index.module.scss";

function LoaderBubble() {

  return (
    <div className={styles.loading}>
      <img src={Logo} alt='logo-icone'/>
    </div>
  );
}

export default LoaderBubble;
