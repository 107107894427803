import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import validate from 'validate.js';
import config from './config';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { preparePayload } from './helpers';
import { ConfigTemplate } from 'business/modules/common';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Notification, Input } from 'react-components';
import {
  AddConfigurationProps,
  ConfigurationProps,
} from './helpers/interfaces';
import {
  CommonSection,
  IndoorAndOutsideSection,
  SaveChanges,
  VentilationSection,
  Select,
  NewConfigPage,
} from './components';
import './styles.scss';



const DEFAULT_NOTIFICATIONS: any = { ...config.notifications };
const DEFAULT_STRS: any = { ...config.strings };
const CONFIG_FIELDS: any = { ...config.fields };
const DEFAULT_ALERTS: any = { ...config.alerts };
const CONFIG_CONSTRAINTS: any = { ...config.constraints };
const DEFAULT_TIMEZONES: any = [...config.timezones];

const AddConfiguration: React.FC<AddConfigurationProps> = ({
  loading,
  indoorAirPreparedSetup,
  outsideAirPreparedSetup,
  createConfig,
  saveCreatedConfigID,
}) => {
  const [newConfigData]: any = useState<ConfigurationProps>();
  const [fieldsState, setFieldsState]: any = useState({
    ...config?.fieldsState,
  });
  const [startCreate, setStartCreate]: any = useState(false);
  const [errors, setErrors]: any = useState({});
  const [showBtn, setShowBtn]: any = useState(false);
  const [isSubWidgetsCall, setIsSubWidgetsCall]: any = useState(false);

  let errorsLet: any = {};

  const handleConfigValidation = async (isSubWidgets?: boolean) => {
    let vlResult: any = validate(fieldsState, CONFIG_CONSTRAINTS);
    setIsSubWidgetsCall(!!isSubWidgets);

    if (vlResult) {
      setErrors(vlResult);

      let msm: string = '';

      Object.keys(vlResult).forEach((key: string) => {
        msm += vlResult[key].map((err: any) => `${err}, `);
      });

      msm = msm.substring(0, msm.length - 2);

      Notification({
        title: 'Error',
        message: msm,
        type: 'error',
      });

      return vlResult;
    } else {
      setStartCreate(true);
    }
  };

  const validateInput = (fieldsStateValue: any, constraint = {}) => {
    let vlResult: any = validate(fieldsStateValue, constraint);

    if (vlResult) {
      errorsLet = { ...(errorsLet || {}), ...vlResult };
      setErrors(errorsLet);
      return;
    } else {
      const [key] = Object.keys(fieldsStateValue);

      if (errorsLet[key]) {
        errorsLet[key] && delete errorsLet[key];
        setErrors(errorsLet);
      }
    }
  };

  const handleSetTimezone = (event: any) => {
    let selectedTimezone = event?.target?.value;
    if (!selectedTimezone) return;

    const index: number = DEFAULT_TIMEZONES.findIndex(
      (_timezone: any) => _timezone.label === selectedTimezone,
    );
    setFieldsState((prevState: any) => {
      return {
        ...prevState,
        tmzn: index,
      };
    });
    setShowBtn(true);
  };

  const onChangeState = (name: string, value: any) => {
    setFieldsState((prevState: any) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
    setShowBtn(true);

    const constraints: any = _.cloneDeep(CONFIG_CONSTRAINTS);

    validateInput({ [name]: value }, { [name]: constraints[name] });
  };

  const handleSelectVentilation = (event: any) => {
    let selectedVentilation: any = event?.target?.value;

    if (!selectedVentilation) return;

    setFieldsState((prevState: any) => {
      return {
        ...prevState,
        ventilation_setup: selectedVentilation,
      };
    });
    setShowBtn(true);
  };

  const onConfirmNewConfig = async () => {
    setStartCreate(false);
    loading.start(config?.strings?.loadingStart);

    const fieldsCopy: any = _.cloneDeep(fieldsState);

    const payload: any = preparePayload(
      fieldsCopy,
      outsideAirPreparedSetup?.setup?.outsideAir,
      indoorAirPreparedSetup?.setup.indoorAir,
    );

    let result = await createConfig(payload);
    if (result) {
      saveCreatedConfigID(result?.config_id);
      Notification({
        title: 'Success!',
        message: DEFAULT_NOTIFICATIONS?.update?.success,
        type: 'success',
      });
    }

    if (!isSubWidgetsCall) setShowBtn(false);

    setTimeout(() => {
      loading.stop();
    }, 1000);
  };

  useEffect(() => {
    saveCreatedConfigID('');

    return () => {
      saveCreatedConfigID('');
      setIsSubWidgetsCall(false);
    };
  }, []);


  return (
    <ConfigTemplate
      id="user-new-config"
      title={DEFAULT_STRS.title}
      titleBackButton
    >
      <BreadcrumbsItem to={config?.strings?.breadCrumbItem}>
        {DEFAULT_STRS.title}
      </BreadcrumbsItem>
      <div className="user-add-config-wrapper">
        <NewConfigPage newConfigData={newConfigData} config={config}>
          <div className="col-xs-12 col-sm-12 col-md-6 col-left">
            <Input
              placeholder={config?.strings?.placeholders?.name}
              handleChange={onChangeState.bind(null, 'config_name')}
              handleChangeError={(err: any) => {
                setErrors((prevState: any) => ({
                  ...prevState,
                  config_name: err,
                }));
              }}
              type={CONFIG_FIELDS?.config_name?.type}
              label={CONFIG_FIELDS?.config_name?.label}
              labelStyles="system-name--label"
              value={fieldsState?.config_name}
              error={errors?.config_name}
              name={'config_name'}
            />

            <Select
              value={fieldsState?.ventilation_setup}
              onChange={handleSelectVentilation}
            />

            <CommonSection
              config={{ ...config }}
              fieldsState={fieldsState}
              errors={errors}
              handleSetTimezone={handleSetTimezone}
              onChangeState={onChangeState}
            />
          </div>

          <VentilationSection
            fieldsState={fieldsState}
            config={{ ...config }}
            errors={errors}
            onChangeState={onChangeState}
          />
        </NewConfigPage>

        <IndoorAndOutsideSection
          loading={loading}
          oaConfig={config?.setupFields?.outsideAir}
          fieldsState={fieldsState}
          showBtnCtrl={setShowBtn}
          handleConfigValidation={handleConfigValidation}
          isMounted={true}
        />

        <SweetAlert
          info
          show={startCreate}
          showCancel
          confirmBtnText={DEFAULT_ALERTS?.create?.btn?.confirm}
          confirmBtnBsStyle="info"
          title={`${DEFAULT_ALERTS?.create?.question}`}
          onConfirm={() => onConfirmNewConfig()}
          onCancel={() => setStartCreate(false)}
          focusCancelBtn
        />

        <SaveChanges
          showBtn={showBtn}
          onClick={() => {
            handleConfigValidation();
          }}
          config={config}
        />
      </div>
    </ConfigTemplate>
  );
};

function mapStateToProps(state: any) {
  const { userConfigs } = state;
  return {
    outsideAirPreparedSetup: userConfigs.outsideAirPreparedSetup,
    indoorAirPreparedSetup: userConfigs.indoorAirPreparedSetup,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { loader, userConfigs } = dispatch;
  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    createConfig: userConfigs.createConfig,
    saveCreatedConfigID: userConfigs.saveCreatedConfigID,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddConfiguration);
