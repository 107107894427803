/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import validate from 'validate.js';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ConnectionBroker } from 'core';
import {
  InputPassword,
  Button,
  Notification,
} from '../../../../../react-components';
import config from './config';
import Form from '../../components/FormSession';

const configInputs: any = config;

const ResetPassword: React.FC = () => {
  const history = useHistory();
  const match: any = useRouteMatch();

  useEffect(() => {
    return () => {};
  }, []);

  const [fieldsState, setFieldsState] = useState(configInputs.fieldsState);
  const [errors, setErrors]: any = useState(true);
  const [loading, setLoading]: any = useState(false);

  const handleSubmit = useCallback(
    async () => {
      setLoading(true);
      let isValid = true;
      await validate.async(fieldsState, config.constraints).then(null, err => {
        setErrors(err);
        isValid = false;
      });
      if (!isValid) {
        setLoading(false);
        return;
      }
      const response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'session.resetLostPassword',
        { token: match.params.token, password: fieldsState.password },
      );
      setLoading(false);
      if (response.result) {
        Notification({
          title: 'Successfully',
          message: 'your password has been updated',
          type: 'success',
        });
        history.push('/login');
      }
    },
    // eslint-disable-next-line
    [fieldsState],
  );

  return (
    <Form>
      <Helmet>
        <title>{config.strings.title} - AirCycler</title>
      </Helmet>
      <h2 className="mt-3 mb-3 text-center">{config.strings.title}</h2>
      <div className="row">
        {Object.keys(configInputs.fields).map((name: string, index: number) => (
          <InputPassword
            handleChange={(value: any) =>
              setFieldsState((prevState: any) => ({
                ...prevState,
                [name]: value,
              }))
            }
            handleChangeError={(err: any) =>
              setErrors((prevState: any) => ({
                ...prevState,
                [name]: err,
              }))
            }
            name={name}
            value={fieldsState[name]}
            error={errors[name]}
            constraint={{
              password: config.constraints['password'],
              confirm_password: config.constraints['confirm_password'],
            }}
            fieldsState={fieldsState}
            key={index}
            {...configInputs.fields[name]}
          />
        ))}
      </div>

      <div className="d-flex  pt-4">
        <Button
          variant="primary"
          className="btn  btn-primary btn-block"
          type="button"
          disabled={loading}
          onClick={() => handleSubmit()}
        >
          {config.strings.confirm}
        </Button>
      </div>

      <div className="d-flex justify-content-center align-items-center pt-4">
        <Button
          variant="link"
          className="btn  btn-link"
          type="button"
          onClick={() => history.push('/login')}
        >
          {config.strings.cancel}
        </Button>
      </div>
    </Form>
  );
};

export default ResetPassword;
