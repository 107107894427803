import React from 'react';
import { UnorderedListProps } from './interface';
import './styles.scss';

const UnorderedList: React.FC<UnorderedListProps> = ({
  data,
  device,
  handleListChildren,
}) => {
  return (
    <div className="col-xs-12 col-sm-12 col-md-6 unordered--list">
      <ul className="list-group wborder">
        {Object.entries<any>(data).map(([key, value], index) => {
          return (
            <li className="list-group-item wborder" key={index}>
              {handleListChildren(device, value)}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default UnorderedList;
