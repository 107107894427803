export const inputTypes: any = {
  systemName: 'text',
  systemLocationState: 'text',
  systemLocationCity: 'text',
  systemLocationZipCode: 'number',
  flowMeasuredHeatCall: 'number',
  flowMeasuredCoolingCall: 'number',
  flowMeasuredFanOnlyCall: 'number',
  flowContinuousAirFlow: 'number',
  exhaustFanFlowRate: 'number',
  additionalMixTime: 'number',
  hoursOfOperation: 'number',
  hourStart: 'number',
  hourStop: 'number',
  currentTime: 'number',
  exhaustFanDelayTime: 'number',
  minutesPerHourExhaustRun: 'number',
  minutesPerHourSupplyFlow: 'number',
  requiredContinuousAirFlow: 'number',
};
export const _config = {
  default: {
    dependentProps: {
      hrsv: {
        prop: 'hroa',
        value: 1,
      },
      hstv: {
        prop: 'hroa',
        value: 1,
      },
    },
    ventSetupTags: {
      calcFlow: [
        'fhfr',
        'fcfr',
        'fffr',
        'carq',
        'vcfr',
        'effr',
        'mixt',
        'hroa',
        'hrsv',
        'hstv',
        'tmzn',
        'efdt',
      ],
      calcTime: [
        'mnsf',
        'mner',
        'mixt',
        'hroa',
        'hrsv',
        'hstv',
        'tmzn',
        'efdt',
      ],
      balancedMode: [
        'mnsf',
        'mner',
        'efdt',
        'mixt',
        'hroa',
        'hrsv',
        'hstv',
        'tmzn',
      ],
    },
  },
};

export const fieldsThatRequireAddLabel = [
  'flowMeasuredHeatCall',
  'flowMeasuredCoolingCall',
  'flowMeasuredFanOnlyCall',
  'flowContinuousAirFlow',
  'exhaustFanFlowRate',
  'additionalMixTime',
  'exhaustFanDelayTime',
];
