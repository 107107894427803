import React from 'react';
import {
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import './styles.scss';

interface PeripheralOptionalProps {
  id?: string;
  label: string;
  value: any;
  options: any;
  onChange?: any;
}

const PeripheralOptional: React.FC<PeripheralOptionalProps> = ({
  id,
  label,
  value,
  options,
  onChange,
  ...props
}) => {
  return (
    <Grid item>
      <div id={id} className="periph-opl">
        <div className="periph-opl--values-wrapper">
          <label className="periph-opl--values-label">{label}</label>
        </div>
        <div style={{ marginLeft: '10px' }}>
          <RadioGroup
            aria-label="exaust-control"
            name="exaust-control"
            value={value}
            onChange={onChange}
          >
            {options &&
              options.map((option: any, index: number) => {
                return (
                  <FormControlLabel
                    key={`rd-${index}`}
                    value={option.value}
                    control={
                      <Radio
                        checked={
                          option.value == 0
                            ? value == option.value
                            : value >= option.value
                        }
                        color="primary"
                      />
                    }
                    label={option.label}
                  />
                );
              })}
          </RadioGroup>
        </div>
      </div>
    </Grid>
  );
};

export default PeripheralOptional;
