import React from 'react';
import { BreadcrumbBtnProps } from './interface';
import './styles.scss';

const BreadcrumbBtn: React.FC<BreadcrumbBtnProps> = ({
  onClick,
  label,
  icon,
}) => {
  return (
    <>
      <div className="col-3 align-self-center"></div>
      <div className="col-2 reload-button-container-col">
        <div className="xs-sm-c">
          <button className="btn btn-info refresh-cmds" onClick={onClick}>
            {icon}
          </button>
        </div>
        <div className="md-lg-xl-c">
          <button className="btn btn-info refresh-cmds" onClick={onClick}>
            {icon}
            <span className="refresh-btn-label">{label}</span>
          </button>{' '}
        </div>
      </div>
    </>
  );
};

export default BreadcrumbBtn;
