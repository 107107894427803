import React from 'react';

interface FooterProps {
  title: string;
  body: string;
}

const Footer: React.FC<FooterProps> = () => {
  return (
    <div className="container the-foot-cnt">
      <div className="row row-ml">
        <div className="col-12 the-footer lead--1">Any questions?</div>
      </div>
      <div className="row row-pbtm">
        <div className="col-12 lead">
          Please, contact us to support@aircycler.com
        </div>
      </div>
    </div>
  );
};

export default Footer;
