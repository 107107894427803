import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { functions } from './helpers';
import { RxMagnifyingGlass } from 'react-icons/rx';
import './styles.scss';

interface SearchProps {
  defSearchTerm: Function;
  searchTermInput: string;
  getUsersList: Function;
  getListOfAdminUsers: Function;
  everyPeoplesList: any;
  everyPplListLoaded: boolean;
  fillEveryPeopleList: Function;
  storeResultsFromSearch: Function;
}

const Search: React.FC<SearchProps> = ({
  defSearchTerm,
  searchTermInput,
  getUsersList,
  getListOfAdminUsers,
  everyPeoplesList,
  fillEveryPeopleList,
  storeResultsFromSearch,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [listOfPeopleIsLoaded, setListOfPeopleIsLoaded] = useState(false);

  const fillThePeopleList = async () => {
    let users: any = await getUsersList();
    let admins: any = await getListOfAdminUsers();

    let filteredPeople: any = [];

    if (users?.statusCode === 200 && admins?.statusCode === 200) {
      functions.fillListWithFilteredPpl(users, 'users', filteredPeople);
      functions.fillListWithFilteredPpl(admins, 'admins', filteredPeople);

      fillEveryPeopleList(filteredPeople);
      setListOfPeopleIsLoaded(true);
    } else {
      //TODO: handle error on call
    }
  };

  const onChangeValue = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const findAndShowResults = (term: string) => {
    let results: any = [];

    everyPeoplesList.map((element: any) => {
      let reg = new RegExp(term.split(' ').join('.*?[ ]'), 'i');
      if (
        element.user.match(reg) ||
        element.email.match(reg) ||
        element.accId.match(reg)
      ) {
        results.push(element);
      }
    });

    storeResultsFromSearch(results);
    return results;
  };

  const searchPeople = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      let trimmedSearchTerm = searchTermInput.trim();

      findAndShowResults(trimmedSearchTerm);

      const modalLauncher =
        document && document.getElementById('launch-results');

      if (modalLauncher) {
        modalLauncher.click();
      }
    }
  };

  useEffect(() => {
    const modalLauncher = document && document.getElementById('launch-results');
  }, []);

  useEffect(() => {
    defSearchTerm(searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    const input = document.querySelector('input[type="text"]');

    input &&
      input.addEventListener('focus', event => {
        fillThePeopleList();
      });
  }, []);

  return (
    <>
      <div
        className="input-group flex-nowrap search-bar admin-search--bar"
        id="search-box-container"
      >
        <div className="input-group-prepend">
          <span className="input-group-text" id="addon-wrapping">
            <RxMagnifyingGlass id="mag-glass-icon" />
          </span>
        </div>
        <input
          type="text"
          id="search-user-input-001-1"
          className="form-control search-user"
          placeholder="Search someone..."
          aria-label="Username"
          aria-describedby="addon-wrapping"
          value={searchTermInput}
          onChange={onChangeValue}
          onKeyPress={event => searchPeople(event)}
        />
      </div>

      <button
        id="launch-results"
        type="button"
        className="launch-results"
        data-toggle="modal"
        data-target="#search-results"
      ></button>
    </>
  );
};

function mapStateToProps(state: any) {
  const { searchUsersState, adminUsersState } = state;

  return {
    searchTermInput: searchUsersState.searchTermInput,
    everyPeoplesList: searchUsersState.everyPeoplesList,
    everyPplListLoaded: searchUsersState.everyPplListLoaded,
    usersList: adminUsersState.usersList,
    usersListIsLoaded: adminUsersState.usersListIsLoaded,
    listOfAdminUsers: adminUsersState.listOfAdminUsers,
    admUsersAreLoaded: adminUsersState.admUsersAreLoaded,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { searchUsersState, adminUsersState } = dispatch;
  return {
    defSearchTerm: searchUsersState.defSearchTerm,
    fillEveryPeopleList: searchUsersState.fillEveryPeopleList,
    storeResultsFromSearch: searchUsersState.storeResultsFromSearch,
    getListOfAdminUsers: adminUsersState.getListOfAdminUsers,
    getUsersList: adminUsersState.getUsersList,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Search);
