import React from 'react';
import './styles.scss';

interface SpinnerProps {}

const Spinner: React.FC<SpinnerProps> = () => {
  return (
    <div className="container d-flex h-100 loading-mh--2">
      <div className="row align-self-center w-100">
        <div className="col-6 mx-auto center-loading-txt">
          <div>
            <div
              className="spinner-grow"
              role="status"
              style={{ color: 'lightblue' }}
            >
              <span className="sr-only">Loading...</span>
            </div>
            <div
              className="spinner-grow"
              role="status"
              style={{ color: 'lightblue' }}
            >
              <span className="sr-only">Loading...</span>
            </div>
            <div
              className="spinner-grow"
              role="status"
              style={{ color: 'lightblue' }}
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Spinner;
