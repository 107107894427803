import React, { Fragment, useEffect, useState } from 'react';
import config from './config';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { functions } from './helpers';
import { AddDeviceProps } from './interface';
import {
  Template,
  Label,
  SwitchBtn,
  Input,
  Button,
  Modal,
} from 'business/modules/admin/common';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { RiSave3Fill, RiEdit2Line } from 'react-icons/ri';
import { HiOutlineXCircle } from 'react-icons/hi';
import './styles.scss';

const AddDevice: React.FC<AddDeviceProps> = ({ fillNewDev, newDevData }) => {
  const history = useHistory();
  const [devData, setDevData] = useState<any>(config?.devState);
  const [switchValue, setSwitchValue] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [errors, setErrors] = useState<any>({
    device: '',
    state: '',
    city: '',
    macAddress: '',
    zipCode: '',
  });

  const handleInput = (event: any) => {
    let name: string = event.target.name;
    let value: any = event.target.value;

    let validate = functions.handleErrors(name, value);
    setErrors({ ...errors, [name]: validate });

    setDevData({ ...devData, [name]: value });
  };

  const handleStatus = () => {
    setSwitchValue(!switchValue);
  };

  const returnVal = (item: string) => {
    return devData[item as keyof typeof devData];
  };

  const saveDev = () => {
    //newDevData
  };

  const cancelAddDev = () => {
    setDevData({
      device: '',
      state: '',
      city: '',
      macAddress: '',
      zipCode: '',
    });

    setSwitchValue(false);

    setErrors({
      device: '',
      state: '',
      city: '',
      macAddress: '',
      zipCode: '',
    });
  };

  useEffect(() => {
    fillNewDev({ ...devData, switchState: switchValue });
  }, [devData, switchValue]);

  useEffect(() => {
    const empty = Object.values(devData).filter(
      (elem: any) => elem.trim().length === 0,
    );

    let errs: any = Object.values(errors);

    let checkErrs: any = errs.filter((elem: any) => elem.trim().length > 0);

    if (empty.length === 0 && checkErrs.length === 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [devData, disabled]);

  return (
    <Template
      id="installers-devs--add"
      title={config.add.title}
      goBack={() => {
        history.push(
          '/admin/dashboard/companies-list/company/installers/devices',
        );
      }}
    >
      <BreadcrumbsItem to={config.add.route}>
        {config.add.breadcrumbItem}
      </BreadcrumbsItem>

      <div className="add-installer-dev-screen">
        <div className="jumbotron spec">
          <h4 className="centered">{config.add.mainTitle} </h4>
          <p className="lead centered card-top-ft">{config.add.pText}</p>
          <hr className="my-4" />

          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5 pdg">
                <form>
                  <div className="form-group">
                    {Object.keys(config.fields).map(
                      (item: any, index: number) => {
                        return (
                          <Fragment key={index}>
                            {item === `status` ? (
                              <div id={`dev-install-status-elem1`}>
                                <Label>{functions.formatLabel(item)}</Label>
                                <SwitchBtn
                                  className=""
                                  id={''}
                                  value={switchValue}
                                  onChange={handleStatus}
                                />
                              </div>
                            ) : (
                              <Fragment key={item}>
                                <Label>{functions.formatLabel(item)}</Label>
                                <Input
                                  name={item}
                                  type="text"
                                  id={`id-elem-${item}`}
                                  placeholder={`Enter ${functions.formatLabel(
                                    item,
                                  )} ${functions.isDev(item)}`}
                                  value={returnVal(item)}
                                  onChange={handleInput}
                                />

                                <div className="render-error">
                                  {errors && errors[item] ? errors[item] : ''}
                                </div>
                              </Fragment>
                            )}
                          </Fragment>
                        );
                      },
                    )}
                  </div>
                </form>
              </div>
            </div>

            <div className="row d-flex justify-content-center mt-1">
              <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5 pdg1">
                <Button
                  className="small-button dismiss-btn float-right-btn"
                  type="button"
                  onClick={cancelAddDev}
                >
                  <span className="btn-label btn-child-ft">
                    {' '}
                    <HiOutlineXCircle className="cancel" />
                    CANCEL
                  </span>
                </Button>

                <Button
                  className="small-button ok float-right-btn mr1"
                  dataToggle="modal"
                  dataTarget={`#save-new-dev--target`}
                  type="button"
                  disabled={disabled}
                >
                  <span className="btn-label btn-child-ft">
                    <RiSave3Fill className="edit" /> SAVE{' '}
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        config={config}
        id={`save-new-dev--target`}
        title={config.modalStrings.edit}
        icon={<RiEdit2Line className="edit" />}
        body={config.modalStrings.aboutToUpdate}
        label={config.modalStrings.labelOK}
        className={`small-button ok`}
        bodyClass={`alert alert-info`}
        sureToProceed={config.modalStrings.sureToProceed}
        hasConfirmBtn={true}
        warning={config.modalStrings.noUndo}
        onClickCancel={cancelAddDev}
        onClick={saveDev}
        //handle modal automatic closing when
        //there is a real dev creation going on:
        /* modalMustClose={modalMustClose}
        handleModalState={handleModalState}*/
        modalMustClose={false}
        handleModalState={() => {}}
      />
    </Template>
  );
};

function mapStateToProps(state: any) {
  const { adminInstallerDevsState } = state;
  return {
    newDevData: adminInstallerDevsState.newDevData,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { adminInstallerDevsState } = dispatch;
  return {
    fillNewDev: adminInstallerDevsState.fillNewDev,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDevice);
