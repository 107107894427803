import {
  TempIcon,
  HumIcon,
  FlowIcon,
  FanIcon,
} from 'business/assets/sensors-icons';
import { WavesIcon } from 'business/assets/icons';
import numeral from 'numeral';

const utils = {
  selectData: (id: string) => {
    switch (id) {
      case 'w-temp':
      case 'inst-w-temp':
      case 'w-temp-inst':
        return {
          _class: 'i-temp',
          left: {
            isIcon: true,
            item: TempIcon,
          },
          right: 'ºF',
        };
      case 'w-rhum':
      case 'w-rhum-inst':
      case 'inst-w-rhum':
      case 'w-rhum-edit-config':
        return {
          _class: 'i-hum',
          left: {
            isIcon: true,
            item: HumIcon,
          },
          right: 'RH',
        };
      case 'w-cfm':
      case 'w-cfm-inst':
      case 'inst-w-cfm':
        return {
          _class: 'i-cfm',
          left: {
            isIcon: true,
            item: WavesIcon,
          },
          right: 'CFM',
        };
      case 'w-pm25':
      case 'w-pm25-inst':
      case 'w-pm25-edit-config':
      case 'inst-w-pm25':
        return {
          _class: 'l-tvoc',
          left: {
            isIcon: false,
            item: 'PM 2.5',
          },
          right: 'ug/m3',
        };
      case 'w-co2':
      case 'inst-w-co2':
      case 'w-co2-inst':
      case 'w-co2-edit-config':
        return {
          _class: 'l-co2',
          left: {
            isIcon: false,
            item: 'CO2',
          },
          right: 'ppm',
        };
      case 'w-tvoc':
      case 'inst-w-tvoc':
      case 'w-tvoc-inst':
      case 'w-tvoc-edit-config':
        return {
          _class: 'l-tvoc',
          left: {
            isIcon: false,
            item: 'tVOC',
          },
          right: 'ppb',
        };
      case 'w-p-conn':
      case 'inst-w-p-conn':
      case 'w-p-conn-inst':
        return {
          _class: 'w-paConn',
          left: {
            isIcon: false,
            item: 'Pa',
          },
          right: '',
        };
      default:
        return;
    }
  },
  createLabel: (option: string, value: any) => {
    switch (option) {
      case 'w-temp':
      case 'w-temp-inst':
      case 'inst-w-temp':
        return `${numeral(value).format('0,0')}ºF`;
      case 'w-rhum':
      case 'inst-w-rhum':
      case 'w-rhum-inst':
      case 'w-rhum-edit-config':
        return `${value}%`;
      default:
        return numeral(value).format('0,0');
    }
  },
  mapNewValues: (values: any, defaults: any) => {
    let tempData: any = [...values];
    const { min, max } = defaults;

    tempData = tempData.map((val: number, index: number) => {
      return index === 0
        ? val !== undefined
          ? val
          : 0
        : val !== -101
        ? val
        : max - 15;
    });

    return [...tempData];
  },
};

export default utils;
