import _ from 'lodash';
import { ConnectionBroker } from '../../core';

export const env = {
  state: {
    open: 'open',
    isUserInit: false,
    ventStack: null,
    weather: {},
    g3Reads: {},
  },
  reducers: {
    updateState(state: any, payload: any, name: string) {
      return { ...state, [name]: payload };
    },
    updateWeather(state: any, payload: any, deviceId: string) {
      const { weather } = state;
      return {
        ...state,
        weather: {
          ...weather,
          [deviceId]: { ...payload },
        },
      };
    },
    updateG3Reads(state: any, payload: any, deviceId: string) {
      const { g3Reads } = state;
      return {
        ...state,
        g3Reads: {
          ...g3Reads,
          [deviceId]: { ...payload },
        },
      };
    },
  },
  effects: (dispatch: any) => ({
    updateVentStack(payload: any, rootState: any) {
      const self: any = this;
      self.updateState([...payload], 'ventStack');
    },
    resetVentStack() {
      const self: any = this;
      self.updateState(null, 'ventStack');
    },
    setUserInit() {
      const self: any = this;
      self.updateState(true, 'isUserInit');
    },
    resetUserInit() {
      const self: any = this;
      self.updateState(false, 'isUserInit');
    },
    async requestWeather(payload: any, rootState: any) {
      const that: any = this;
      const { deviceId } = payload;
      const { env } = rootState;
      const { weather } = env;

      let response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'weather.feed',
        null,
        { device_id: deviceId },
      );

      if (response?.statusCode === 200) {
        let wClone: any = _.cloneDeep({ ...weather });
        wClone = {
          ...weather,
          [deviceId]: { ...response.body },
        };
        that.updateState({ ...wClone }, 'weather');
        return { ...response.body };
      } else if (response?.statusCode === 400) {
        return;
      }
      return { ...weather };
    },
    async requestSystemChartData(data: any, rootState: any){
      const self: any = this;
      const { device_id }: any = data;
      let response: any;

      let result: any = await ConnectionBroker.fetchAPI(
        'jitBE',
        'systems.dataChart',
        null,
        { system_id: device_id },
      );

      if(result.statusCode === 200){
        response = result?.body ? { ...result.body } : null;
      }

      return response;
    },

  }),
};
