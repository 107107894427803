import React, { useState, useEffect } from 'react';
import Avatar from 'react-avatar-edit';
import { Col, Modal, Dropdown } from 'react-bootstrap';
import { Button } from '../../';
import cn from 'classnames';
import styles from './index.module.scss';
import { MdPhotoCamera } from 'react-icons/md';


interface InputProps {
  name: string;
  classname?: any;
  cols?: any;
  handleChange: Function;
  srcCurrent?: any;
}

const Input: React.FC<InputProps> = ({ cols, handleChange, srcCurrent }) => {
  const [src, setSrc] = useState('');
  const [showModalAvatar, setShowModalAvatar] = useState(false);
  const [preview, setPreview] = useState('');
  const [previewPrev, setPreviewPrev] = useState('');

  useEffect(() => {
    setPreview(srcCurrent);
  }, []);

  useEffect(() => {
    return () => {};
  }, []);

  const onCrop = (preview: string) => {
    setPreviewPrev(preview);
  };

  const onCloseModal = () => {
    setPreviewPrev('');
    setShowModalAvatar(false);
  };

  const onSave = () => {
    setPreview(previewPrev);
    setPreviewPrev('');
    handleChange(previewPrev);
    setShowModalAvatar(false);
    setSrc('');
  };

  const onRemove = () => {
    setPreview('');
    setPreviewPrev('');
    handleChange('');
    setPreview(srcCurrent);
  };

  const onBeforeFileLoad = (elem: any) => {
    if (elem.target.files[0].size > 71680) {
      // alert('File is too big!');
      // elem.target.value = '';
    }
  };


  return (
    <Col
      sm={cols?.sm || cols?.col}
      md={cols?.md || cols?.col}
      lg={cols?.lg || cols?.col}
      className={cn('form-group d-flex justify-content-center align-items-center my-5')}
    >
      <Modal show={showModalAvatar} onHide={() => setShowModalAvatar(false)}>
        <Modal.Body className="p-0 d-flex flex-column  justify-content-center">
          <div>
            <Avatar
              width={500}
              height={295}
              onCrop={onCrop}
              onClose={onCloseModal}
              onBeforeFileLoad={onBeforeFileLoad}
              src={src}
              borderStyle={{
                border: '0px',
                textAlign: 'center',
                width: '100%',
              }}
              labelStyle={{ textAlign: 'center' }}
            />
          </div>
          <hr></hr>
          <div className={styles.divButtons}>
            <Button variant="outline-primary" onClick={() => onCloseModal()}>
              Close
            </Button>
            <Button
              className="btn-primary btn-sm"
              disabled={previewPrev ? false : true}
              onClick={onSave}
            >
              Set as profile image
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <div className={styles.avatar}>
        {preview ? (
          <div
            className={styles.avatar__img}
            style={{ backgroundImage: `url(${preview})` }}
          >
            <div className={styles.avatar__img__button}>
              <Dropdown>
                <Dropdown.Toggle
                  variant="secondary"
                  className="btn-sm"
                  id="avatar-basic"
                >
                  <MdPhotoCamera />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setShowModalAvatar(true)}>
                    Change profile photo
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => onRemove()}>
                    Remove
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        ) : (
          <div
            className={styles.avatar__icon}
            onClick={() => setShowModalAvatar(true)}
          >
            <MdPhotoCamera />
          </div>
        )}
      </div>
    </Col>
  );
};

export default Input;
