const shouldRedirectToSysTab = (id: string) => {
  if (
    (id && id === `origin-sys-screen`) ||
    (id && id === `setup-edit-peripheral-back`) ||
    (id && id === 'details-dev1')
  ) {
    return true;
  } else return false;
};

export const helpers = {
  shouldRedirectToSysTab: shouldRedirectToSysTab,
};
