import React from 'react';
import { IoCheckmark } from 'react-icons/io5';

export default {
  strings: {
    title: 'Pressure Connect',
    stLabel: '@pressure-connect::',
    cardTitle: `Pressure Connect`,
    settings: `Pressure Connect settings details.`,
    title1: `Enable / disable section`,
    str3: `These changes will affect the user's configurations. Are you sure you want to proceed?`,
    pa: `Pa`,
    paSettings: `Pa settings`,
    confirmation: `You are about to update the Pressure Connect configurations.`,
    showSetup: `Show setup`,
    hideSetup: `Hide setup`,
    sureToProceed: `Are you sure you wish to proceed?`,
  },
  modalStrings: {
    warning: 'Information',
  },
  titles: {
    pc: `Update configuration`,
  },
  icons: {
    pc: <IoCheckmark className="edit" />,
  },
  bodies: {
    pc: ``,
  },
  labels: {
    pc: ` OK`,
  },
  bodyClass: {
    pc: `alert alert-danger`,
  },
  alerts: {
    save: {
      question: 'Proceed on saving new Pressure Connect configuration?',
      btn: {
        confirm: 'Yes, save it!',
      },
    },
  },
  notifications: {
    update: {
      success: 'Pressure Connect setup successfully saved!',
      error: 'Pressure Connect setup was not updated. Please, try again later.',
    },
  },
  formats: {
    pa: '0,0',
  },
  model: {
    max: -101,
    min: -101,
    current: 0,
    init: false,
  },
  ckModel: {
    exct: false,
    dmst: false,
    frcn: false,
    ahuf: false,
  },
  sliderValues: {
    pa: {
      max: 200,
      min: 0,
    },
  },
  defaults: {
    pa: { max: 150 },
  },
};
