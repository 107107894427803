import { ConnectionBroker } from '../../core';

export const firmware = {
  state: {
    firmware: null,
    firmwareWasUpdated: false,
    modalMustClose: false,
  },
  reducers: {
    updateState(state: any, payload: any, name: string) {
      return { ...state, [name]: payload };
    },
    handleModalState(state: any, payload: any) {
      return {
        ...state,
        modalMustClose: payload,
      };
    },
  },
  effects: (dispatch: any) => ({
    async getCurrentFirmWare(data: any, rootState: any) {
      const self: any = this;
      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        'admin.firmwareVersion',
      );

      if (response?.statusCode === 200) {
        self.updateState({ ...response.body }, 'firmware');
      } else {
        // TODO: add error handler
      }

      return response?.body ? { ...response.body } : null;
    },
    async saveFirmware(payload: any, rootState: any) {
      const self: any = this;
      const that: any = this;
      const body: any = {
        firmware: { ...payload },
      };

      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        'admin.saveFirmware',
        body,
      );

      if (response?.statusCode === 200) {
        //will be used to show a success message:
        self.updateState(true, 'firmwareWasUpdated');

        //Close the modal automatically on getting response
        that.handleModalState(true);

        await that.getCurrentFirmWare();
      } else {
        // TODO: add error handler

        //Close the modal automatically on getting response
        that.handleModalState(true);
      }

      return response;
    },
  }),
};
