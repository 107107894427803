export default {
  strings: {
    title: 'Configurations List',
    btnLabel: `+ Add Configuration`,
    emptyListTitle: 'configurations',
    loadingDelete: 'Deleting configuration...',
    loadingHandleLoad: 'Loading Configurations...',
  },
  alerts: {
    delete: {
      question: 'Are you sure you want to delete configuration: ',
      msm: "You won't be able to revert the operation!",
      btn: {
        confirm: 'Yes, delete it!',
      },
    },
  },
  notifications: {
    delete: {
      msm: 'Configuration deleted successfully!',
    },
  },
  listConfigState: {
    text: ['name'],
    createdAt: { type: 'date', format: 'MM-DD-YYYY' },
  },

  mockData: [
    {
      status: 'active',
      createdAt:
        'Tue Nov 28 2023 16:44:35 GMT+0000 (Coordinated Universal Time)',
      updatedAt:
        'Tue Nov 28 2023 16:44:35 GMT+0000 (Coordinated Universal Time)',
      _id: '656e35e4304e0a0013cbc348',
      installer_id: 'be4e6d2c-5e40-e6e9-2961-051803d1f396',
      company_id: '0f7e1a04-9101-34ab-1520-6810aa333820',
      name: 'ConfigTest2',
      settings: {
        config_name: 'ConfigTest2',
        fhfr: 0,
        fcfr: 0,
        fffr: 0,
        hroa: 1,
        carq: 0,
        effr: 0,
        primary_control: '',
        add_sensor: '',
        add_sensor_id: '',
        ventilation_setup: 'Calculated Time',
        mnsf: 20,
        mner: 0,
        mixt: 0,
        tmzn: 2,
        efdt: 0,
        exct: 0,
        hrsv: 7,
        hstv: 22,
        exwm: 0,
        vcfr: 0,
        vcdt: 0,
        mvcr: 20,
        vcwm: 0,
        settings: {
          fhfr: 0,
          fcfr: 0,
          fffr: 0,
          hroa: 1,
          carq: 0,
          effr: 0,
          ventilation_setup: 'Calculated Time',
          mnsf: 20,
          mner: 0,
          mixt: 0,
          tmzn: 2,
          efdt: 0,
          hrsv: 7,
          hstv: 22,
          exct: 0,
          vcfr: 0,
          vcdt: 0,
          mvcr: 20,
          vcwm: 0,
          exwm: 0,
        },
        setup: {
          outsideAir: {
            temp: {
              max: 95,
              min: 5,
            },
            hum: 95,
            aqi: 52,
            turnedOn: true,
          },
          indoorAir: {
            slider: {
              pm25: 75,
              co2: 586,
              tvoc: 112,
            },
            turnedOn: true,
          },
        },
      },
      config_id: 'a7ad5257-5054-2637-26f3-4fd8f126b723',
      __v: 0,
    },
    {
      status: 'active',
      createdAt:
        'Tue Nov 28 2023 11:53:42 GMT+0000 (Coordinated Universal Time)',
      updatedAt:
        'Tue Nov 28 2023 11:53:42 GMT+0000 (Coordinated Universal Time)',
      _id: '656e37660d28b000132af990',
      installer_id: 'be4e6d2c-5e40-e6e9-2961-051803d1f396',
      company_id: '0f7e1a04-9101-34ab-1520-6810aa333820',
      name: 'ConfigTest3',
      settings: {
        fhfr: 10,
        fcfr: 10,
        fffr: 10,
        hroa: 0,
        carq: 30,
        effr: 0,
        ventilation_setup: 'Calculated Flow',
        mnsf: 0,
        mner: 0,
        mixt: 5,
        tmzn: 2,
        efdt: 0,
        hrsv: 0,
        hstv: 0,
        exct: 0,
        vcfr: 10,
        vcdt: 0,
        mvcr: 0,
        vcwm: 0,
        exwm: 0,
      },
      setup: {
        outsideAir: {
          temp: {
            max: 90,
            min: 6,
          },
          hum: 93,
          aqi: 51,
          turnedOn: true,
        },
        indoorAir: {
          slider: {
            pm25: 78,
            co2: 403,
            tvoc: 81,
          },
          turnedOn: true,
        },
      },
      config_id: 'ce4a08a6-501d-877d-dcf8-b1f4e4f16c84',
      __v: 0,
    },
    {
      status: 'active',
      createdAt:
        'Tue Nov 28 2023 12:26:09 GMT+0000 (Coordinated Universal Time)',
      updatedAt:
        'Tue Nov 28 2023 12:26:09 GMT+0000 (Coordinated Universal Time)',
      _id: '6573b523279a210013fbcb3e',
      installer_id: 'be4e6d2c-5e40-e6e9-2961-051803d1f396',
      company_id: '0f7e1a04-9101-34ab-1520-6810aa333820',
      name: 'ConfigTest5',
      settings: {
        fhfr: 0,
        fcfr: 0,
        fffr: 0,
        hroa: 0,
        carq: 0,
        effr: 0,
        ventilation_setup: 'Calculated Time',
        primary_control: 'fanConnect',
        mnsf: 15,
        mner: 15,
        mixt: 7,
        tmzn: 2,
        efdt: 0,
        hrsv: 0,
        hstv: 0,
        exct: 0,
        vcfr: 0,
        vcdt: 0,
        mvcr: 0,
        vcwm: 0,
        exwm: 0,
      },
      setup: {
        outsideAir: {
          temp: {
            max: 93,
            min: 2,
          },
          hum: 95,
          aqi: 54,
          turnedOn: true,
        },
        indoorAir: {
          slider: {
            pm25: 90,
            co2: 869,
            tvoc: 122,
          },
          turnedOn: true,
        },
      },
      config_id: '66cee67c-fac0-6cd1-008e-1f5baa2e19f5',
      __v: 0,
    },
    {
      status: 'inactive',
      createdAt:
        'Tue Nov 28 2023 12:26:09 GMT+0000 (Coordinated Universal Time)',
      updatedAt:
        'Tue Nov 28 2023 12:26:09 GMT+0000 (Coordinated Universal Time)',
      _id: '6573b523279a210013fbcb3c',
      installer_id: 'be4e6d2c-5e40-e6e9-2961-051803d1f396',
      company_id: '0f7e1a04-9101-34ab-1520-6810aa333820',
      name: 'ConfigTest9',
      settings: {
        fhfr: 0,
        fcfr: 0,
        fffr: 0,
        hroa: 0,
        carq: 0,
        effr: 0,
        ventilation_setup: 'Calculated Time',
        primary_control: 'fanConnect',
        mnsf: 15,
        mner: 15,
        mixt: 7,
        tmzn: 2,
        efdt: 0,
        hrsv: 0,
        hstv: 0,
        exct: 0,
        vcfr: 0,
        vcdt: 0,
        mvcr: 0,
        vcwm: 0,
        exwm: 0,
      },
      setup: {
        outsideAir: {
          temp: {
            max: 93,
            min: 2,
          },
          hum: 95,
          aqi: 54,
          turnedOn: true,
        },
        indoorAir: {
          slider: {
            pm25: 90,
            co2: 869,
            tvoc: 122,
          },
          turnedOn: true,
        },
      },
      config_id: '66cee67c-fac0-6cd1-008e-1f5baa2e19f7',
      __v: 0,
    },
  ],
};
