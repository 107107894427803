export default {
  strings: {
    title: 'PressureConnect',
    stLabel: '@pressure-connect::',
  },
  alerts: {
    save: {
      question:
        'Are you sure you want to save the new PressureConnect configuration?',
      btn: {
        confirm: 'Yes, save it!',
      },
    },
  },
  notifications: {
    update: {
      success: 'PressureConnect setup saved successfully!',
    },
  },
  formats: {
    pa: '0,0',
  },
  model: {
    max: -101,
    min: -101,
    current: 0,
    init: false,
  },
  ckModel: {
    exct: false,
    dmst: false,
    frcn: false,
    ahuf: false,
  },
  sliderValues: {
    pa: {
      max: 200,
      min: 0,
    },
  },
  defaults: {
    pa: { max: 150 },
  },
};
