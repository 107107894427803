import config from '../../../../config/config';

export default {
  strings: {
    ...config.strings,
    title: 'Your account was created',
    subtitle: ' Please check your inbox to validate the account.',
    confirm: 'ok',
    resend:'Resend activation email'
  },

};
