import React, { useState, useEffect } from 'react';
import { SlidersProps } from './interface';
import { SingleSlider } from 'business/modules/admin/common';
import * as Utils from './utils';
import { helpers } from './helpers';
import './styles.scss';

const UTILS = { ...Utils.default };

const Sliders: React.FC<SlidersProps> = ({
  datapoints,
  id,
  values,
  max,
  min,
  onChange,
  defaultMarkValues,
  step,
}) => {
  const [compData, setCompData]: any = useState(null);
  const [composedValues, setComposedValues]: any = useState([]);
  const [compValsAreLoaded, setCompValsLoaded]: any = useState(false);

  useEffect(() => {
    if (id && id !== '') {
      const result: any = UTILS.selectData(id);
      if (result) {
        setCompData({ ...result });
      }
    }
    return () => {
      setCompData(null);
    };
  }, [id]);

  useEffect(() => {
    if (values && datapoints && datapoints.length > 0 && id) {
      setComposedValues([
        datapoints[0][
          helpers.returnDataPointIndex(id) as keyof typeof datapoints[0]
        ],
        values[1],
      ]);
      setCompValsLoaded(true);
    } else if (values && datapoints && datapoints.length === 0) {
      setComposedValues([0, values[1]]);

      setCompValsLoaded(true);
    }
  }, [values, datapoints, id]);

  return (
    <div className="indoor-air-section-details">
      <div className="row">
        <div className="col-12 intro section-data pb-32">
          {helpers.handleSectionIntro(id)}
        </div>
      </div>

      <SingleSlider
        composedValues={composedValues}
        id={id}
        values={values}
        onChange={onChange}
        max={max}
        min={min}
        step={step}
        symbol={``}
        compValsAreLoaded={compValsAreLoaded}
        defaultMark={true}
        defaultMarkValues={defaultMarkValues}
      />
    </div>
  );
};

export default Sliders;
