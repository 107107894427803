// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { Grid, Slider } from '@material-ui/core';
import { renderCustomThumb, LeftSideComponent, RightSideComponent } from './utils';
import ReactSlider from 'react-slider';
import * as Utils from './utils/utils';
import './styles.scss';


const UTILS = { ...Utils.default };

interface WidgetSingleCfmSliderProps {
  id: string;
  values?: any;
  max: any;
  min: any;
  step: any;
  col?: number;
  showLabel?: any;
  symbol?: string;
}

const WidgetSingleCfmSlider: React.FC<WidgetSingleCfmSliderProps> = ({
  id,
  values,
  max,
  min,
  step,
  col,
  showLabel,
  symbol,
  ...props
}) => {
  const [compData, setCompData]: any = useState(null);
  const [compValues, setCompValues]: any = useState([]);


  useEffect(() => {
    if (id && id !== '') {
      const result: any = UTILS.selectData(id);
      if (result) {
        setCompData({ ...result });
      }
    }

    return () => {
      setCompData(null);
    };
  }, [id]);

  useEffect(() => {
    if(values){
      const tempValues: any = UTILS.mapNewValues(values, {max, min});
      setCompValues([...tempValues]);
    }
  }, [values]);

  return (
    <Grid className="widget-cfm-slider">
      <div id={id} className="widget-cfm-slider--container">
        <Grid container>
          <Grid item className={`cfm-slider--left-container--${col ? col : 1}`}>
            {compData && <LeftSideComponent data={compData} />}
          </Grid>
          <Grid item className={`cfm-slider--center-container--${col ? col : 1}`}>
            <ReactSlider
              className="cfm-horizontal--slider"
              thumbClassName="cfm--slider-thumb"
              trackClassName="cfm--slider-track"
              value={compValues}
              step={step}
              max={max}
              min={min}
              disabled={true}
              ariaLabel={['cfm-Left cfm-thumb', 'cfm-Middle cfm-thumb', 'cfm-Right cfm-thumb']}
              renderThumb={renderCustomThumb.bind(null, symbol)}
              minDistance={-max}
              snapDragDisabled={true}
              onSliderClick={(value: number, tIndex: number) => {}}
              onBeforeChange={(value: number, tIndex: number) => {}}
            />
          </Grid>
          <Grid item className={`cfm-slider--right-container--${col ? col : 1}`}>
            {compData && <RightSideComponent data={compData} />}
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default WidgetSingleCfmSlider;
