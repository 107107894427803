import React, { useState, useEffect } from 'react';
import { Grid, Slider } from '@material-ui/core';
import * as Utils from './utils';
import './styles.scss';

const UTILS = { ...Utils.default };

interface WidgetSliderProps {
  id: string;
  value?: any;
  onChange?: any;
  max?: any;
  min?: any;
  step?: any;
  col?: number;
  showLabel?: any;
}

const WidgetSlider: React.FC<WidgetSliderProps> = ({
  id,
  value,
  onChange,
  max,
  min,
  step,
  col,
  showLabel,
  ...props
}) => {
  const [compData, setCompData]: any = useState(null);
  const [marks, setMarks]: any = useState(null);


  const initMarks = (value: any, id: string) => {
    let tempMarks: any = [];
    if(Array.isArray(value)){
      value.forEach((val: any) => {
        tempMarks.push({
          value: val,
          label: UTILS.createLabel(id, val)
        });
      });
    }else{
      tempMarks.push({
        value,
        label: UTILS.createLabel(id, value)
      });
    }
    setMarks(tempMarks);
  };

  const labelsFormater = (label: string) => {
    switch (label) {
      case 'CO2':
        return (
          <>CO<sup>2</sup></>
        );
      case 'ug/m3':
        return (
          <>ug/m<sup>3</sup></>
        );
      default:
        return label;
    }
  };

  const LeftSideComponent = ({ data }: any) => {
    const { _class, left } = data;
    const { isIcon, item } = left;
    const Icon: any = item;

    return (
      <div className="slider--left">
        {isIcon ? (
          <Icon className={`slider--icon ${_class}`} />
        ) : (
          <label className={`slider-label ${_class}`}>{labelsFormater(item)}</label>
        )}
      </div>
    );
  };

  const RightSideComponent = ({ data }: any) => {
    const { _class, right } = data;
    return (
      <div className="slider--right">
        <label className={`slider-label ${_class}`}>{labelsFormater(right)}</label>
      </div>
    );
  };

  useEffect(() => {
    if (id && id !== '') {
      const result: any = UTILS.selectData(id);
      if (result) {
        setCompData({ ...result });
      }
    }

    return () => {
      setCompData(null);
    };
  }, [id]);

  useEffect(() => {
    if(value){
      initMarks(value, id);
    }
  }, [value]);

  return (
    <Grid className="widget-slider">
      <div id={id} className="widget-slider--container">
        <Grid container>
          <Grid
            item
            className={`slider--left-container--${col ? col : 1}`}
          >
            {compData && <LeftSideComponent data={compData} />}
          </Grid>
          <Grid item className={`slider--center-container--${col ? col : 1}`}>
            <Slider
              value={value}
              max={max}
              min={min}
              step={step}
              onChange={onChange}
              marks={marks}
              component="div"
              aria-labelledby="continuous-slider"
            />
          </Grid>
          <Grid
            item
            className={`slider--right-container--${col ? col : 1}`}
          >
            {compData && <RightSideComponent data={compData} />}
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default WidgetSlider;
