import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { AirThingsConnection, AirThingsDisconnect } from '../';

const at_path = '/airthings';
const AT_LABEL: string = '@air-airthings';

interface AtHomeProps {
  loading: any;
  isUserConnected: any;
  updateAirthingsState: any;
}

const AirThingsHome: React.FC<AtHomeProps> = ({ loading, ...props }) => {
  const [path, setPath]: any = useState(null);

  useEffect(() => {
    loading.start();
    
    setPath(
      props?.isUserConnected
        ? '/dashboard/airthings/disconnect'
        : '/dashboard/airthings/connection',
    );
    loading.stop();
  }, [props?.isUserConnected]);

  useEffect(() => {
    props.updateAirthingsState();
  }, []);

  return (
    <>
      <Route exact path={`/dashboard/airthings`}>
        {path && <Redirect to={path} />}
      </Route>
    </>
  );
};

function mapStateToProps(state: any) {
  const { airthings } = state;
  return {
    isUserConnected: airthings.isConnected,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { loader, airthings } = dispatch;
  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    updateAirthingsState: airthings.updateAirthingsState,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AirThingsHome);
