// @ts-nocheck
import _ from 'lodash';

const isValueInit = (value: number, prop: string) => {
  return (
    value[prop] && value[prop].hasOwnProperty('current') && value[prop].init
  );
};

const prepareValuesToSave = (values: any, theDevice: any) => {
  let devSettings: any = _.cloneDeep({ ...theDevice.details.settings });
  const { temp, aqi, hum } = values;
  devSettings.conf.tpmn = temp.min;
  devSettings.conf.tpmx = temp.max;
  devSettings.conf.mxrh = hum.max;
  devSettings.conf.aqvi = aqi.max;

  return devSettings;
};

const processSpecialProp = (prop: string, values: any, oldValues: any) => {
  switch (prop) {
    case 'ctmp':
      return values[prop] &&
        values[prop] !== -99 &&
        values[prop] !== oldValues[prop]
        ? values[prop]
        : oldValues[prop];
    case 'crhr':
    case 'cflw':
      return values[prop] &&
        values[prop] !== -1 &&
        values[prop] !== oldValues[prop]
        ? values[prop]
        : oldValues[prop];
    default:
      return;
  }
};

const prepareToSaveSetup = (
  values: any,
  _device: any,
  outsideAirSectionIsEnabled: boolean,
) => {
  const { device_id } = _device;
  const { temp, aqi, hum, cfm } = values;
  let tempSetup: object = {
    temp: {
      max: temp.max,
      min: temp.min,
    },
    hum: hum.max,
    aqi: aqi.max,
    cfm: cfm.max,
    turnedOn: outsideAirSectionIsEnabled,
  };

  return {
    system_id: device_id,
    setup: {
      outsideAir: { ...tempSetup },
    },
  };
};

const valIsDefined = (val: any) => {
  if (val !== null && val !== undefined) {
    return true;
  } else return false;
};

export const helpers = {
  isValueInit: isValueInit,
  prepareValuesToSave: prepareValuesToSave,
  processSpecialProp: processSpecialProp,
  prepareToSaveSetup: prepareToSaveSetup,
  valIsDefined: valIsDefined,
};
