import React from 'react';
import config from './config';
import { BottomProps } from './interface';
import './styles.scss';

const Bottom: React.FC<BottomProps> = ({ onClick, extended }) => {
  return (
    <div className="row d-flex justify-content-center footer-devs">
      <div className="col-12">
        <button
          className="btn btn-sm btn-primary handle-footer"
          onClick={onClick}
        >
          {extended ? (
            <span className="btn-font">{config.strings.less}</span>
          ) : (
            <span className="btn-font">{config.strings.more}</span>
          )}
        </button>
      </div>
    </div>
  );
};

export default Bottom;
