export default {
  strings: {
    title: 'Peripheral Setup',
    labels: {
      fmwv: 'Firmware Version: ',
      pcbv: 'PCB Revision: ',
      mac: 'Mac Address: ',
      uptm: 'Up Time: ',
      fanr: 'Fan Runtime Elapsed: ',
      effr: 'Exhaust Fan Flow Rate',
      vcfr: 'Exhaust Fan Flow Rate',
      mner: 'Minutes per Hour Exhaust Run',
      mvcr: 'Minutes per Hour Exhaust Run',
      efdt: 'Exhaust Fan Delay Time',
      vcdt: 'Exhaust Fan Delay Time',
      cpri: 'Display Priority',
      slst: 'Light Status',
      exct: 'Exhaust Control Target',
      exwm: 'Exhaust Worker Mode',
    },
    titles: {
      peripherals: 'Peripherals',
      connDevices: 'Connected Devices',
      dialog: 'About Peripheral',
    },
    warns: {
      noDevices: 'Controller does not have any connected device.',
    },
    buttons: {
      confirm: 'Save',
    },
  },
  alerts: {
    setup: {
      btn: {
        confirm: 'Yes, save it!',
      },
      question: 'Are you sure you want to save current values?',
    },
  },
  notifications: {
    update: {
      success: 'Peripheral updated successfully!',
      error: ''
    },
  },
  menu: {
    options: [
        {label: 'About', name: 'about', func: null },
        {label: 'Reset Default', name: 'resetDefault', func: null}
    ]
  },
  fieldsState: {},
  fieldsFocus: {},
  fields: {},
  constraints: {},
};
