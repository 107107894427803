import React from 'react';
import logo from 'business/assets/logos/aircycler-logo-r2.png';
import './styles.scss';


const NoSystemsComponent = ({ ...props }) => {

  return (
    <div id="installer-empty-container" className="empty-dash">
      <img src={logo} className="logo-img" />
      <div className="text-title">
        <p>
          This account don't have installed Systems yet.
        </p>
      </div>
    </div>
  );
};

export default NoSystemsComponent;
