export default {
  strings: {
    key: {
      placeholder: 'Key'
    },
    value: {
      placeholder: 'Value'
    },
  },
};
