import React from 'react';
import { SaveChangesProps } from './interface';
import { RiSave3Fill } from 'react-icons/ri';
import { Button } from 'react-components';
import './styles.scss';

const SaveChanges: React.FC<SaveChangesProps> = ({
  showBtn,
  onClick,
  config,
}) => {
  return (
    <>
      {showBtn && (
        <div className="sticky">
          <Button
            variant="primary"
            className="btn save-configs-btn-purple"
            type="button"
            onClick={onClick}
            disabled={false}
          >
            <RiSave3Fill />
            <span className="save--config--label">
              {config.strings.btnLabel}
            </span>
          </Button>
        </div>
      )}
    </>
  );
};

export default SaveChanges;
