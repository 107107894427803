import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Button, Notification } from '../../../../../react-components';
import { UserTemplate } from 'business/modules/common';
import { AtLogoHeader } from '../../common';
import { ModalAlert } from './components';
import config from './config';
import './styles.scss';

const configInputs: any = config;
const AT_LABEL: string = '@air-airthings';

interface LoadingProps {
  start: Function;
  stop: Function;
}

interface AirThingsProps {
  airthingsUserData?: any;
  resetUserData: any;
  loading: LoadingProps;
  disconnectUser: any;
}

const AirThingsDisconnect: React.FC<AirThingsProps> = ({
  airthingsUserData,
  ...props
}) => {
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const handleDisconnect = (event: any) => {
    setShowAlert(true);
  };

  const onDisconnect = async (event: any) => {
    setShowAlert(false);
    props.loading.start();
    await props.disconnectUser();
    history.push('/dashboard/airthings');
    props.loading.stop();
    // to prevent to return connect page when browser back button clicked
    // history.replace('/dashboard/airthings', {
    //     state: { deleted: false },
    // });
  };

  useEffect(() => {
    if (airthingsUserData) setUsername(airthingsUserData);
  }, [airthingsUserData]);

  return (
    <UserTemplate
      id="at-disconnection"
      title={configInputs?.strings?.title}
      titleBackButton
    >
      <BreadcrumbsItem to={`/dashboard/airthings/disconnect`}>
        Airthings Connection
      </BreadcrumbsItem>
      <Grid container direction="column" alignItems="center">
        <Grid item className="section-1">
          <AtLogoHeader />
        </Grid>
        <Grid item className="section-2">
          <p>Hi, {username}</p>
        </Grid>
        <Grid item className="section-3">
          <p>{configInputs?.strings?.subtitle}</p>
        </Grid>
        <Grid item className="section-4">
          <div className="col d-flex pt-4 pb-2">
            <Button
              className="btn btn-airthings btn-primary btn-block"
              type="button"
              disabled={false}
              onClick={handleDisconnect}
            >
              {configInputs?.strings?.confirm}
            </Button>
          </div>
        </Grid>
      </Grid>
      <ModalAlert
        show={showAlert}
        onConfirm={onDisconnect}
        onCancel={() => {
          setShowAlert(false);
        }}
      />
    </UserTemplate>
  );
};

function mapStateToProps(state: any) {
  return {};
}

function mapDispatchToProps(dispatch: any) {
  const { airthings, loader } = dispatch;
  return {
    disconnectUser: airthings.disconnect,
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AirThingsDisconnect);
