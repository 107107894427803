import React from 'react';
import headsetSolid from '../../assets/headset-solid.png';
import mobileAltSolid from '../../assets/mobile-alt-solid.png';
import propeller from '../../assets/propeller.png';
import logo from '../../assets/logo.svg';

import '../../styles/landing-page.css'
const LandingPage: React.FC = () => {
  return (
    <div className="landing-page">
      <div className="row landing-page-card">
        <div className="col-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center ">
        <img src={logo}  alt="Logo" width='90%' className='landing-page-logo' />
        </div>
        <div className="col-12 col-md-6 col-lg-6 content">
          <h1 className='title'>SIMPLE VENTILATION SOLUTIONS</h1>
          <h2  className='subtitle'>Get to know our services</h2>
          <button className="btn bg-white btn-lg button-knowmore">KNOW MORE</button>
          <div className="row boxs d-flex justify-content-center ">
          <div className="col-3 box">
            <img src={propeller}  alt="Smart" className='smart-ventilation' />
              <p>smart ventilation</p>
            </div>

            <div className="col-3 box">
            <img src={mobileAltSolid}  alt="Mobile" />
              <p>Mobile APP</p>
            </div>
            <div className="col-3 box">
            <img src={headsetSolid}  alt="Customer Support" />
              <p>Customer Support</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
