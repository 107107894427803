// @ts-nocheck
import React from 'react';
import { Switch, FormControlLabel } from '@material-ui/core';
import './styles.scss';

interface SwitchButtonProps {
  id?: string;
  data?: any;
  ckData?: any;
  device: any;
  onChange: any;
  value: any;
}

const SwitchButton: React.FC<SwitchButtonProps> = ({
  id,
  data,
  ckData,
  value,
  ...props
}) => {

  return (
    <>
      <div id={id}>
        <FormControlLabel
          control={
            <Switch
              style={{ marginRight: '2px' }}
              id={id}
              checked={!!value}
              onChange={props.onChange}
              color="primary"
            />
          }
        />
      </div>
    </>
  );
};

export default SwitchButton;
