import React, { useEffect } from 'react';
import { MenuProps } from './interface';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './styles.scss';

const CommonMenu: React.FC<MenuProps> = ({
  expands,
  config,
  btnId,
  onClick,
  open,
  anchorEl,
  handleClose,
  ariaExpanded,
  item,
  expandedCtrl,
  id,
  firstFun,
  secondFun,
  thirdFun,
  fourthFunction,
}) => {
  const menuOptions: any = {
    firstOpt: () => {
      handleClose();
      firstFun && firstFun();
    },
    secondOpt: () => {
      handleClose();
      secondFun && secondFun(item);
    },
    thirdOpt: () => {
      handleClose();
      thirdFun && thirdFun();
    },
    fourthOpt: () => {
      fourthFunction && fourthFunction(item);
    },
  };

  return (
    <div className="common--menu--1">
      <IconButton
        className="row-menu-btn"
        aria-haspopup="true"
        aria-expanded={ariaExpanded}
        id={id}
        onClick={onClick}
      >
        <MoreHoriz />
      </IconButton>

      {expands && (
        <IconButton
          id={btnId}
          aria-haspopup="false"
          aria-expanded={ariaExpanded}
        >
          {expandedCtrl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      )}

      <Menu
        elevation={1}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `more-button-comp`,
        }}
      >
        {config.menu.map((option: any, index: number) => {
          return (
            <MenuItem
              id={id}
              onClick={menuOptions[option.id as keyof typeof menuOptions]}
              key={index}
              data-toggle={option.dataToggle}
              data-target={option.dataTarget}
            >
              {option.label}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default CommonMenu;
