import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import validate from 'validate.js';
import configs from './config';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { preparePayload } from './helpers';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { ConfigTemplate } from 'business/modules/common';
import { EditConfigurationProps, ConfigurationProps } from './interface';
import { Notification, Input } from 'react-components';
import {
  CommonSection,
  IndoorAndOutsideSection,
  SaveChanges,
  VentilationSection,
  EditConfigPage,
  Select,
} from './components';
import './styles.scss';

const DEFAULT_STRS: any = { ...configs.strings };
const CONFIG_FIELDS: any = { ...configs.fields };
const CONFIG_CONSTRAINTS: any = { ...configs.constraints };
const DEFAULT_TIMEZONES: any = [...configs.timezones];
const DEFAULT_ALERTS: any = { ...configs.alerts };

const EditConfiguration: React.FC<EditConfigurationProps> = ({
  loading,
  storeId,
  showConfig,
  currentUserConfigId,
  configShowData,
  configShowDataLoaded,
  resetCurrentSaved,
  outsideAirPreparedSetup,
  indoorAirPreparedSetup,
  editConfiguration,
}) => {
  const [editConfigData, setEditConfigData]: any = useState<
    ConfigurationProps
  >();
  const [startCreate, setStartCreate]: any = useState(false);
  const [config, setConfig]: any = useState();
  const [errors, setErrors]: any = useState({});
  const [showBtn, setShowBtn]: any = useState(false);
  const [realStateLoaded, setRealStateLoaded] = useState(false);

  const [fieldsState, setFieldsState]: any = useState({
    ...configs?.fieldsState,
  });
  let errorsLet: any = {};

  const validateInput = (fieldsStateValue: any, constraint = {}) => {
    let vlResult: any = validate(fieldsStateValue, constraint);

    if (vlResult) {
      errorsLet = { ...(errorsLet || {}), ...vlResult };
      setErrors(errorsLet);
      return;
    } else {
      const [key] = Object.keys(fieldsStateValue);

      if (errorsLet[key]) {
        errorsLet[key] && delete errorsLet[key];
        setErrors(errorsLet);
      }
    }
  };

  const onChangeState = (name: string, value: any) => {
    setFieldsState((prevState: any) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
    setShowBtn(true);
    const constraints: any = _.cloneDeep(CONFIG_CONSTRAINTS);
    validateInput({ [name]: value }, { [name]: constraints[name] });
  };

  const handleSelectVentilation = (event: any) => {
    let selectedVentilation: any = event?.target?.value;

    if (!selectedVentilation) return;

    setFieldsState((prevState: any) => {
      return {
        ...prevState,
        ventilation_setup: selectedVentilation,
      };
    });
    setShowBtn(true);
  };

  const handleSetTimezone = (event: any) => {
    let selectedTimezone = event?.target?.value;
    if (!selectedTimezone) return;

    const index: number = DEFAULT_TIMEZONES.findIndex(
      (_timezone: any) => _timezone.label === selectedTimezone,
    );

    setFieldsState((prevState: any) => {
      return {
        ...prevState,
        tmzn: index,
      };
    });
    setShowBtn(true);
  };

  const handleConfigValidation = async () => {
    let vlResult: any = validate(fieldsState, CONFIG_CONSTRAINTS);

    if (vlResult) {
      setErrors(vlResult);
      let msm: string = '';
      Object.keys(vlResult).forEach((key: string) => {
        msm += vlResult[key].map((err: any) => `${err}, `);
      });
      msm = msm.substring(0, msm.length - 2);

      Notification({
        title: 'Error',
        message: msm,
        type: 'error',
      });

      return vlResult;
    } else {
      setStartCreate(true);
    }
  };

  const onConfirmUpdateConfig = async () => {
    setStartCreate(false);
    loading.start('Updating Configuration...');

    const fieldsCopy: any = _.cloneDeep(fieldsState);

    const payload: any = preparePayload(
      fieldsCopy,
      outsideAirPreparedSetup?.setup?.outsideAir,
      indoorAirPreparedSetup?.setup?.indoorAir,
    );

    let result: any = await editConfiguration({
      data: payload,
      id: currentUserConfigId,
    });

    setTimeout(() => {
      loading.stop();

      if (result === 200) {
        Notification({
          title: 'Success!',
          message: config?.notifications?.update?.success,
          type: 'success',
        });
      } else {
        Notification({
          title: 'Warning!',
          message: config?.notifications?.update?.error1,
          type: 'warning',
        });
      }

      setShowBtn(false);
    }, 1000);
  };

  useEffect(() => {
    setConfig(configs);
  }, [configs]);

  useEffect(() => {
    const current = localStorage.getItem('@currentUserSelectedConfigId');

    if (current) {
      storeId(current);
    }
  }, []);

  useEffect(() => {
    const callConfigurationData = async () => {
      const current = localStorage.getItem('@currentUserSelectedConfigId');

      if (current) {
        loading.start('Loading Configurations...');
        await showConfig(current);

        setTimeout(() => {
          loading.stop();
        }, 1000);
      }
    };
    callConfigurationData();
  }, [currentUserConfigId]);

  useEffect(() => {
    if (configShowDataLoaded) {
      setFieldsState((prevState: any) => {
        return {
          ...prevState,
          config_name: configShowData[0]?.name,
          settings: configShowData[0]?.settings,
          setup: configShowData[0]?.setup,
          ...configShowData[0]?.settings,
        };
      });
      setRealStateLoaded(true);
    }
  }, [configShowData, configShowDataLoaded]);

  useEffect(() => {
    return () => {
      resetCurrentSaved();
    };
  }, []);

  return (
    <ConfigTemplate
      id="user-configs-edit-main-container"
      title={DEFAULT_STRS.title}
      titleBackButton
    >
      <BreadcrumbsItem to={config?.strings?.breadCrumbItem}>
        {DEFAULT_STRS.title}
      </BreadcrumbsItem>

      <div className="user-configs-edit-config-wrapper">
        {!realStateLoaded ? (
          <div className="row">
            <div className="col"></div>
          </div>
        ) : (
          <>
            <EditConfigPage editConfigData={editConfigData} config={config}>
              <div className="col-xs-12 col-sm-12 col-md-6">
                <Input
                  placeholder={config?.strings?.placeholders?.name}
                  handleChange={onChangeState.bind(null, 'config_name')}
                  handleChangeError={(err: any) => {
                    setErrors((prevState: any) => ({
                      ...prevState,
                      config_name: err,
                    }));
                  }}
                  type={CONFIG_FIELDS?.config_name?.type}
                  label={CONFIG_FIELDS?.config_name?.label}
                  labelStyles="system-name--label"
                  value={fieldsState?.config_name}
                  error={errors?.config_name}
                  name={'config_name'}
                />

                <Select
                  value={fieldsState?.ventilation_setup}
                  onChange={handleSelectVentilation}
                />

                <CommonSection
                  config={{ ...configs }}
                  errors={errors}
                  fieldsState={fieldsState}
                  handleSetTimezone={handleSetTimezone}
                  onChangeState={onChangeState}
                />
              </div>

              <VentilationSection
                fieldsState={fieldsState}
                configs={configs}
                errors={errors}
                onChangeState={onChangeState}
              />
            </EditConfigPage>

            <IndoorAndOutsideSection
              loading={loading}
              oaConfig={config}
              showBtnCtrl={setShowBtn}
              handleConfigValidation={handleConfigValidation}
              showBtn={showBtn}
              isMounted={true}
            />
          </>
        )}
      </div>

      <SweetAlert
        info
        show={startCreate}
        showCancel
        confirmBtnText={DEFAULT_ALERTS?.update?.btn?.confirm}
        confirmBtnBsStyle="info"
        title={`${DEFAULT_ALERTS?.update?.question}`}
        onConfirm={() => {
          onConfirmUpdateConfig();
        }}
        onCancel={() => {
          setStartCreate(false);
          setShowBtn(false);
        }}
        focusCancelBtn
      />

      <SaveChanges
        showBtn={showBtn}
        onClick={() => {
          handleConfigValidation();
        }}
        config={config}
      />
    </ConfigTemplate>
  );
};

function mapStateToProps(state: any) {
  const { loader, userConfigs }: any = state || {};
  return {
    isLoading: loader.loading,
    outsideAirPreparedSetup: userConfigs.outsideAirPreparedSetup,
    indoorAirPreparedSetup: userConfigs.indoorAirPreparedSetup,
    currentUserConfigId: userConfigs.currentUserConfigId,
    configShowData: userConfigs.configShowData,
    configShowDataLoaded: userConfigs.configShowDataLoaded,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { loader, userConfigs }: any = dispatch || {};
  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    storeId: userConfigs.storeId,
    showConfig: userConfigs.showConfig,
    resetCurrentSaved: userConfigs.resetCurrentSaved,
    editConfiguration: userConfigs.editConfiguration,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditConfiguration);
