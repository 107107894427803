import React from 'react';
import { AiOutlineNumber } from 'react-icons/ai';
import { BsChat } from 'react-icons/bs';
import { TbSignature, TbNumbers } from 'react-icons/tb';
import { MdLocationCity } from 'react-icons/md';
import { HiOutlineIdentification } from 'react-icons/hi';
import { GiUsaFlag } from 'react-icons/gi';
import { MdAlternateEmail } from 'react-icons/md';
import { FaPhoneAlt } from 'react-icons/fa';

export const relevantKeys = [
  'company_id',
  'code',
  'name',
  'contact',
  'city',
  'zipcode',
  'state',
];

export const icons: any = {
  name: <TbSignature style={{ marginRight: '0.2rem' }} />,
  code: <AiOutlineNumber style={{ marginRight: '0.2rem' }} />,
  contact: <BsChat style={{ marginRight: '0.2rem' }} />,
  state: <GiUsaFlag style={{ marginRight: '0.2rem' }} />,
  city: <MdLocationCity style={{ marginRight: '0.2rem' }} />,
  zipcode: <TbNumbers style={{ marginRight: '0.2rem' }} />,
  company_id: <HiOutlineIdentification style={{ marginRight: '0.2rem' }} />,
  email: <MdAlternateEmail style={{ marginRight: '0.2rem' }} />,
  phone: <FaPhoneAlt style={{ marginRight: '0.2rem' }} />,
};
