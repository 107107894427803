import React from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { json } from '@codemirror/lang-json';
import { CustomParamsRow } from '../';
import './styles.scss';

interface RequestBodyProps {
  index: any;
  loading: any;
  onSend: any;
  links?: any;
  params?: any;
  headers?: any;
  body?: any;
  baseUrl: string;
  response: any;
  link: any;
}

const RequestBody: React.FC<RequestBodyProps> = ({
  index,
  loading,
  onSend,
  links,
  baseUrl,
  params,
  headers,
  body,
  response,
  link,
  ...props
}) => {
  return (
    <div id="custom-request" className="api-request--request">
      <div className="api-request--form">
        <select className="api-request--method">
          <option>GET</option>
          <option>POST</option>
          <option>PUT</option>
          <option>DELETE</option>
        </select>
        <select
          className="api-request--link"
          value={link.value.link}
          onChange={link.func.onChange.bind(null, index)}
        >
          {links &&
            links.length > 0 &&
            links.map((linkData: any, index: number) => {
              const { link }: any = linkData || {};
              return (
                <option key={'link--' + index} value={link}>
                  {baseUrl + link}
                </option>
              );
            })}
        </select>
        <button
          className="api-request--btn"
          onClick={onSend.bind(null, index)}
          disabled={loading}
        >
          {loading ? 'SENDING...' : 'SEND'}
        </button>
      </div>

      <div className="api-request--header">
        <nav>
          <div
            id="api-request--header-tabs"
            className="nav nav-tabs"
            role="tablist"
          >
            <button
              id="nav-params-tab"
              className="nav-link active"
              data-bs-toggle="tab"
              data-bs-target={`#nav-params-${index}`}
              aria-controls={`nav-params-${index}`}
              type="button"
              role="tab"
              aria-selected="true"
            >
              Params
            </button>
            <button
              id="nav-headers-tab"
              className="nav-link"
              data-bs-toggle="tab"
              data-bs-target={`#nav-headers-${index}`}
              aria-controls={`nav-headers-${index}`}
              type="button"
              role="tab"
              aria-selected="false"
            >
              Headers
            </button>
            <button
              id="nav-body-tab"
              className="nav-link"
              data-bs-toggle="tab"
              data-bs-target={`#nav-body-${index}`}
              aria-controls={`nav-body-${index}`}
              type="button"
              role="tab"
              aria-selected="false"
            >
              Body
            </button>
          </div>
        </nav>
        <div id="api-request--header-tabContent" className="tab-content">
          <div
            id={`nav-params-${index}`}
            className="tab-pane fade show active"
            role="tabpanel"
            aria-labelledby="nav-params-tab"
          >
            {params &&
              params.rows &&
              params.rows.length > 0 &&
              params.rows.map((param: any, _index: number) => {
                return (
                  <CustomParamsRow
                    key={`param-body-${index}-${_index}`}
                    _key={param.key}
                    value={param.value}
                    onKeyChange={params.func.onKeyChange.bind(
                      null,
                      'params',
                      index,
                      _index,
                    )}
                    onValueChange={params.func.onValueChange.bind(
                      null,
                      'params',
                      index,
                      _index,
                    )}
                    onRemoveRow={params.func.onRemoveRow.bind(
                      null,
                      'params',
                      index,
                      _index,
                    )}
                  />
                );
              })}
            <button
              className="px-6 py-1 rounded-md param--add-row"
              onClick={params.func.onAddRow.bind(null, 'params', index)}
            >
              Add
            </button>
          </div>
          <div
            id={`nav-headers-${index}`}
            className="tab-pane fade"
            role="tabpanel"
            aria-labelledby="nav-headers-tab"
          >
            {headers &&
              headers.rows &&
              headers.rows.length > 0 &&
              headers.rows.map((header: any, _index: number) => {
                return (
                  <CustomParamsRow
                    key={`header-body-${index}-${_index}`}
                    _key={header.key}
                    value={header.value}
                    onKeyChange={headers.func.onKeyChange.bind(
                      null,
                      'headers',
                      index,
                      _index,
                    )}
                    onValueChange={headers.func.onValueChange.bind(
                      null,
                      'headers',
                      index,
                      _index,
                    )}
                    onRemoveRow={headers.func.onRemoveRow.bind(
                      null,
                      'headers',
                      index,
                      _index,
                    )}
                  />
                );
              })}
            <button
              className="px-6 py-1 rounded-md param--add-row"
              onClick={headers.func.onAddRow.bind(null, 'headers', index)}
            >
              Add
            </button>
          </div>
          <div
            id={`nav-body-${index}`}
            className="tab-pane fade"
            role="tabpanel"
            aria-labelledby="nav-body-tab"
          >
            <CodeMirror
              id={`nav-editor-${index}`}
              height="140px"
              value={body.value}
              extensions={[json()]}
              onChange={body.func.onChange.bind(null, index)}
            />
          </div>
        </div>
      </div>

      <div className="api-request--response">
        <nav className="api-request--response-nav">
          <div
            id="api-response--nav-tab"
            className="nav nav-tabs"
            role="api-response-tablist"
          >
            <button
              id="nav-response-body-tab"
              className="nav-link active"
              data-bs-toggle="tab"
              data-bs-target={`#nav-response-body-${index}`}
              aria-controls={`nav-response-body-${index}`}
              type="button"
              role="tab"
              aria-selected="true"
            >
              Response Body
            </button>
            <button
              id="nav-response-headers-tab"
              className="nav-link"
              data-bs-toggle="tab"
              data-bs-target={`#nav-response-headers-${index}`}
              aria-controls={`nav-response-headers-${index}`}
              type="button"
              role="tab"
              aria-selected="false"
            >
              Response Headers
            </button>
          </div>
        </nav>
        <div id="api-response--header-tabContent" className="tab-content">
          <div
            id={`nav-response-body-${index}`}
            className="tab-pane fade show active"
            role="tabpanel"
            aria-labelledby="nav-response-body-tab"
          >
            <CodeMirror
              id="response-json-displayer"
              height="70vh"
              maxHeight="70vh"
              width="73.5vw"
              value={response}
              readOnly={true}
              editable={false}
              indentWithTab={true}
              extensions={[json()]}
            />
          </div>
          <div
            id={`nav-response-headers-${index}`}
            className="tab-pane fade"
            role="tabpanel"
            aria-labelledby="nav-response-headers-tab"
          >
            <div className="nav-response-headers--row">
              <table>
                <thead>
                  <tr>
                    <th className="headers-row--key">Key</th>
                    <th className="headers-row--value">Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="pb-1"></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestBody;
