import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { UserTemplate } from 'business/modules/common';
import { DeviceSwitch } from './components';
import configs from '../../../../../../config/config';
import config from './config';
import './styles.scss';


interface LoadingProps {
  start: any;
  stop: any;
}

interface DeviceSetupProps {
  device?: any;
  location: any;
  loading: LoadingProps;
  reqDeviceDetails: any;
  setCurrentDevice: any;
}

const STORAGE_DEVICE_LABEL = '@air-device:';
const SOCKET_URL = configs?.apis?.g3iot?.baseSocketURLs[0];
let WEBSOCKET: any;

const DeviceSetup: React.FC<DeviceSetupProps> = ({
  device,
  location,
  ...props
}) => {
  const history: any = useHistory();
  const [isLoading, setIsLoading]: any = useState(false);

  // page refresh hander
  const saveDeviceId = (e: any) => {
    if (device) {
      localStorage.setItem(STORAGE_DEVICE_LABEL, device.device_id);
    } else {
      if (location?.search) {
        let urlId = new URLSearchParams(location.search).get('id');
        if (urlId) localStorage.setItem(STORAGE_DEVICE_LABEL, urlId);
      }
    }
  };

  // page refresh hander
  const recoverDeviceDetails = async () => {
    let storedDeviceId = localStorage.getItem(STORAGE_DEVICE_LABEL);
    if (storedDeviceId) {
      props.loading.start();
      props
        .reqDeviceDetails({ device_id: storedDeviceId })
        .then((resp: any) => {
          // TODO: add notification if response has errors
          props.loading.stop();
        });
    }
  };

  const receiveWsMessage = (device: any, event: any) => {
    if (event?.data) {
      let deviceClone = { ...device };
      const updatedDevice = JSON.parse(event.data);
      deviceClone.details.settings = { ...updatedDevice.details };
      props.setCurrentDevice({ ...deviceClone });
    }
  };

  const handleWsConnectionClosed = (_device: any, event: any) => {
    if(event?.reason === 'Going away') {
      const newSocket: any = setWebSocket(_device);
      WEBSOCKET = newSocket;
    }
  };

  const setWebSocket = (_device: any) => {
    const { api_key } = _device;
    let socket: any;
    const skUrl = `${SOCKET_URL}?x-iot-key=${api_key}`;
    socket = new WebSocket(skUrl);
    socket.addEventListener('message', receiveWsMessage.bind(null, _device));
    socket.addEventListener('close', handleWsConnectionClosed.bind(null, _device));
    return socket;
  };


  useEffect(() => {
    // page refresh hander
    window.addEventListener('beforeunload', saveDeviceId);
    if (!device) {
      recoverDeviceDetails();
    }

    return () => {
      localStorage.removeItem(STORAGE_DEVICE_LABEL);
      window.removeEventListener('beforeunload', saveDeviceId);
    };
  }, []);


  useEffect(() => {
    if(device && !WEBSOCKET){
      WEBSOCKET = setWebSocket(device);
    }

    return () => {
      if(WEBSOCKET){
        if(WEBSOCKET.readyState === WebSocket.OPEN) WEBSOCKET.close();
        WEBSOCKET = null;
      }
    }
  }, [device]);


  return (
    <UserTemplate
      id="device-details"
      title={config?.strings?.title}
      titleBackButton={true}
    >
      <DeviceSwitch device={device} {...props} />
    </UserTemplate>
  );
};

function mapStateToProps(state: any) {
  return {
    device: state.devices.currentDevice,
    zones: state.zones.zones,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { loader, devices } = dispatch;
  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    reqDeviceDetails: devices.getDeviceDetails,
    setCurrentDevice: devices.setCurrentDevice,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceSetup);
