export default {
  strings: [
    {
      id: 'edit-sys',
      label: `Edit name / location`,
      dataToggle: ``,
      dataTarget: ``,
    },

    {
      id: 'delete-sys',
      label: `Delete`,
      dataToggle: `modal`,
      dataTarget: `#delete-device-modal-id01`,
    },
  ],
  sysStrings: {
    title: 'Details',
    paragraphOne: `Check, admin, and update `,
    paragraphOneCont: ` settings.`,
    paragraphThree: `Peripherals`,
    paragraphFour: `Connected devices`,
    paragraphFour1: `Connected sensors`,
    paragraphFive: `About`,
    systemData: `System data`,
    noLocation: `No location`,
    online: 'ONLINE',
    offline: 'OFFLINE',
    noDevs: `No devices.`,
    noSensors: `No local sensors connected.`,
    firmware: `Firmware:`,
    macAddress: `Mac Address:`,
    uptime: `Up time:`,
    connectedDevs: `Connected devices:`,
  },
  formats: {},
  devLabels: [
    { id: 'dev_id', label: 'Device id' },
    { id: 'dev_type', label: 'Device type' },
    { id: 'id', label: 'Id' },
    { id: 'label', label: 'Label' },
    { id: 'prod_code', label: ' Product code' },
  ],
  locationDevLabels: [
    { id: 'country_code', label: 'Country code' },
    { id: 'iso_code', label: 'State ISO Code' },
    { id: 'state', label: 'State' },
    { id: 'city', label: 'City' },
    { id: 'zip_code', label: 'ZIP code' },
    { id: 'country', label: 'Country' },
    { id: 'lat', label: 'Latitude' },
    { id: 'long', label: 'longitude' },
  ],
  settingsDevLabels: [
    { id: 'evrl', label: 'evrl' },
    { id: 'pcst', label: 'pcst' },
    { id: 'prob', label: 'prob' },
    { id: 'uptm', label: 'uptm' },
    { id: 'man_ctrl', label: 'Manual control' },
  ],

  url: `/admin/dashboard/user-details/`,
  edit: `/peripheral/edit/`,
  setup: `/peripheral/setup/`,
  listData: [
    {
      string: 'Fan connected ',
      id: 'fcst',
    },
    {
      string: 'Damper Status ',
      id: 'dmst',
    },
    {
      string: 'Current relative humidity ',
      id: 'crhr',
    },
    { string: 'Current temperature ', id: 'ctmp' },
    {
      string: 'Current flow ',
      id: 'cflw',
    },
  ],
  menu: [
    {
      id: 'firstOpt',
      label: 'Details',
      dataToggle: 'modal',
      dataTarget: '#system-settings-modal-details',
    },
    {
      id: 'secondOpt',
      label: 'Edit',
      dataToggle: ' ',
      dataTarget: ' ',
    },
    {
      id: 'thirdOpt',
      label: 'Disconnect',
      dataToggle: 'modal',
      dataTarget: '#system-disconnect',
    },
  ],
};
