import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { EnterDataProps } from './interface';
import { timezone3, options, possibleErrors } from './config';
import { functions } from './helpers';
import { Select } from '..';
import { Input, RadioBtn } from 'business/modules/admin/common';
import './styles.scss';

const EnterDataCommon: React.FC<EnterDataProps> = ({
  type,
  placeholder,
  id,
  disabled,
  label,
  checkIfNumeric,
  onChangeValues,
  errors,
  systemDetails,
  hoursOfOp,
  hours,
  userQuitSystemUpdate,
  value,
  setDropdownsSelections,
  signalUpdate,
  storeSystemSelectData,
  defCurrentSelectBox,
  setIconToShow,
  markPrimaryCtrAsVC,
  setTimeInHoursAndMins,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [selectType, setSelectType] = useState<string>('');
  const [userSelected, setUserSelected] = useState<boolean>(false);

  const renderErrorMsg = (id: string) => {
    if (id === 'additionalMixTime') {
      return errors?.adMxError;
    } else {
      if (possibleErrors.hasOwnProperty(id)) {
        return errors[id];
      }
    }
  };

  useEffect(() => {
    if (userQuitSystemUpdate) {
      setUserSelected(false);
    }
  }, [userSelected, userQuitSystemUpdate]);

  useEffect(() => {
    if (selectedOption.length > 0 && selectType.length > 0) {
      setDropdownsSelections({
        [selectType]: selectedOption,
      });

      signalUpdate(false);
    }
  }, [selectedOption, selectType]);

  return (
    <div className="system-common-fields">
      {type === `select` ? (
        <div className="container white-c">
          <div className={`row set-spec-style1`}>
            <div
              className={`col ${`set-spec-style1`} ${hours ? `pl--hours` : ``}`}
            >
              <Select
                origin={`tmz`}
                disabled={disabled}
                placeholder={label}
                data={timezone3}
                id={`timezone-select`}
                setUserSelected={setUserSelected}
                setSelectedOption={setSelectedOption}
                setSelectType={setSelectType}
                dropdownLabel={
                  <>
                    {userSelected
                      ? selectedOption
                      : timezone3[systemDetails?.currentTime]?.label}
                  </>
                }
                storeSystemSelectData={storeSystemSelectData}
                defCurrentSelectBox={defCurrentSelectBox}
                setIconToShow={setIconToShow}
                markPrimaryCtrAsVC={markPrimaryCtrAsVC}
                setTimeInHoursAndMins={setTimeInHoursAndMins}
              />
            </div>
          </div>
        </div>
      ) : type === 'text' ? (
        <div className="container white-c">
          <div className={`row set-spec-style1`}>
            <div
              className={`col ${`set-spec-style1`} ${hours ? `pl--hours` : ``}`}
            >
              <div className={`row styles-row1`}>
                <div className={`col-auto additional-label stronger-ft`}>
                  <span className="lead smaller-font styles-lead-span-2- stronger-ft">
                    {label} {functions.renderLabel(id)}
                  </span>
                </div>
              </div>
              <div className={`row styles-row1`}>
                <div className={`col-auto additional-label stronger-ft`}></div>
              </div>
              {id === 'currentTime' && (
                <div
                  className={`form-control current-time-text-space1 ${
                    disabled ? `disabled-no-input1` : ``
                  }
                `}
                >
                  {value}
                </div>
              )}
              {id !== 'currentTime' && (
                <Input
                  className={
                    id === `currentTime`
                      ? `form-control current-time-text-space1 ${
                          disabled ? `disabled-no-input1` : ``
                        }`
                      : ``
                  }
                  name={id}
                  id={id}
                  disabled={disabled}
                  type={id === `currentTime` ? 'text' : checkIfNumeric}
                  placeholder={id === `currentTime` ? value : placeholder}
                  onChange={onChangeValues}
                  value={value}
                />
              )}

              <div className="render-error">{renderErrorMsg(id)} </div>
            </div>
          </div>
        </div>
      ) : type === 'radio' ? (
        <div className="container white-c" id={'radio-id'}>
          <div className={`row 1`}>
            <div
              className={`col ${`set-spec-style1`} ${hours ? `pl--hours` : ``}`}
            >
              <span className="lead smaller-font stronger-ft">{label}</span>
              <RadioBtn
                options={options}
                disabled={disabled}
                id={id}
                onChangeValues={onChangeValues}
                value={hoursOfOp}
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

function mapStateToProps(state: any) {
  const { adminDevicesState } = state;
  return {
    userQuitSystemUpdate: adminDevicesState.userQuitSystemUpdate,
    errors: adminDevicesState.errors,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { adminDevicesState } = dispatch;
  return {
    signalUpdate: adminDevicesState.signalUpdate,
    setDropdownsSelections: adminDevicesState.setDropdownsSelections,
    defCurrentSelectBox: adminDevicesState.defCurrentSelectBox,
    storeSystemSelectData: adminDevicesState.storeSystemSelectData,
    setIconToShow: adminDevicesState.setIconToShow,
    markPrimaryCtrAsVC: adminDevicesState.markPrimaryCtrAsVC,
    setTimeInHoursAndMins: adminDevicesState.setTimeInHoursAndMins,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EnterDataCommon);
