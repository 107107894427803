import React, { useState, useCallback, useRef, useEffect } from 'react';
import { InputProps } from './interface';
import validate from 'validate.js';
import { Col } from 'react-bootstrap';
import cn from 'classnames';
import styles from './index.module.scss';

const Input: React.FC<InputProps> = ({
  name,
  classname,
  label,
  labelStyles,
  handleChangeError,
  cols,
  handleChange,
  handleBlur,
  value,
  maximum,
  type,
  error,
  autoFocus,
  constraint,
  constraintEquality,
  placeholder,
  ...rest
}) => {
  const inputRef: any = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (autoFocus === true) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  useEffect(() => {
    return () => {};
  }, []);

  const handleChangeString = useCallback(
    (event: any) => {
      if (maximum) {
        event.target.value.length - 1 < maximum &&
          handleChange(event.target.value);
      } else {
        handleChange(event.target.value);
      }
      handleChangeError(false);
    },
    // eslint-disable-next-line
    [],
  );

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(async () => {
    setIsFocused(false);
    if (constraint) {
      const inputValue =
        type === 'number'
          ? parseInt(inputRef.current.value)
          : inputRef.current.value;
      await validate
        .async({ [name]: inputValue }, { [name]: constraint })
        .then(null, err => {
          handleChangeError(err[name][0]);
        });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Col
      sm={cols?.sm || cols?.col}
      md={cols?.md || cols?.col}
      lg={cols?.lg || cols?.col}
      className={cn('form-group', styles.formGroup, classname)}
    >
      <label className={labelStyles ? labelStyles : styles.inputLabel}>
        {label}
      </label>
      <input
        ref={inputRef}
        id="firm-box"
        onChange={event => handleChangeString(event)}
        className={cn(
          'form-control',
          styles.input,
          {
            [styles[`input--isFocused`]]: isFocused === true,
          },
          {
            [styles[`input--isError`]]: error,
          },
        )}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        value={value ? value : ''}
        type={type}
        placeholder={placeholder}
        {...rest}
      ></input>
      {error && (
        <span
          className={cn(styles.input, {
            [styles[`input__span--isError`]]: error,
          })}
        >
          {error}
        </span>
      )}
    </Col>
  );
};

export default Input;
