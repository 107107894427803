import { ConnectionBroker } from '../../core';

export const zones = {
  state: {
    zones: [],
    editZone: null,
  },
  reducers: {
    updateState(state: any, payload: any, name: string) {
      return { ...state, [name]: payload };
    },
    setEditZone(state: any, payload: any) {
      return { ...state, editZone: payload };
    },
    resetEditZone(state: any) {
      return { ...state, editZone: null };
    },
  },
  effects: (dispatch: any) => ({
    async getAllZones() {
      const that: any = this;
      let response = await ConnectionBroker.fetchAPI('jitBE', 'zones.list');
      if (response?.statusCode === 200) {
        that.updateState(response.body, 'zones');
      } else {
        // TODO: add error handler
      }
    },
    async getEditZone(data: any) {
      const that: any = this;
      let _data: any = { category_id: data.id };
      let response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'zones.show',
        null,
        _data,
      );
      if (response?.statusCode === 200) {
        that.setEditZone(response.body);
      } else {
        // TODO: add error handler
      }
      return response;
    },
    async editZone(data: object) {
      const that: any = this;
      let response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'zones.update',
        data,
      );
      if (response?.statusCode === 200) {
        that.setEditZone(response.body);
      } else {
        // TODO: add error handler
      }
      return response;
    },
    async deleteZone(data: object) {
      const that: any = this;
      let response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'zones.delete',
        data,
      );
      if (response?.statusCode === 200) {
        await that.getAllZones();
      } else {
        // TODO: add error handler
      }
    },
  }),
};
