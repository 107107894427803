import config from '../../../../config/config';

export default {
  strings: {
    ...config.strings,
    confirm: 'Update password',
  },
  fieldsState: {
    oldPassword: '',
    password: '',
    confirm_password: '',
  },
  fieldsFocus: {
    username: false,
  },
  fields: {
    oldPassword: {
      label: 'Current password',
      type: 'password',
      passwordvalidationshow: "true",
      placeholder: 'Password',
      cols: {
        col: 4,
        sm: 12,
        md: 12,
        lg: 4,
        xl: 4,
      },
      classname: 'mode--left',
    },
    password: {
      label: 'New password',
      type: 'password',
      passwordvalidationshow: "true",
      placeholder: 'Password',
      cols: {
        col: 4,
        sm: 12,
        md: 12,
        lg: 4,
        xl: 4,
      },
      classname: 'mode--center',
    },
    confirm_password: {
      label: 'Confirm Password',
      type: 'password',
      subtype: 'confirm_password',
      placeholder: 'Confirm Password',
      cols: {
        col: 4,
        sm: 12,
        md: 12,
        lg: 4,
        xl: 4,
      },
      classname: 'mode--right',
    },
  },
  constraints: {
    password: {
      presence: true,
      format: {
        pattern:
          '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$',
        message:
          'should be at least 8 characters long and include one uppercase letter, one lowercase letter, numbers and special character',
      },
    },
    confirm_password: {
      presence: true,
      equality: {
        attribute: 'password',
        message: '^ Confirm password does not match password',
      },
    },
  },
};
