export default {
  strings: {
    title1: 'Companies List',
    noResults: 'No results found...',
    timeSlLabel: '',
    title: 'Order list',
    txt: `Return to default order by clicking here`,
    noData: `No data`,
    noReqStr: `No companies to display.`,
  },
  modalStrings: {
    confirm: ``,
    warning: `Information`,
    alertStr: ``,
    alertStr2: ``,
    confirmation: ``,
    sureToProceed: `Are you sure you wish to proceed?`,
    noUndo: `This action is irreversible.`,
    aboutToDel: `You are about to delete this company.`,
    labelOK: ` OK`,
  },
  menuLabels: {
    firstOption: `View`,
    secondOption: `Edit`,
    thirdOption: `Delete`,
    fourthOption: `View Installers`,
  },
  resettedCounter: {
    name: 0,
    location: 0,
    code: 0,
    instCount: 0,
  },
  dataHeader: [
    {
      colClass: 'companies--data-header--1',
      spanClass: `lead header-label name-span`,
      spanClass2: `lead header-label name-span1`,
      headerDesignationCol: `Name`,
    },
    {
      colClass: 'companies--data-header--2',
      spanClass: `lead header-label`,
      spanClass2: `lead header-label mb4-1`,
      headerDesignationCol: `Location`,
    },
    {
      colClass: 'companies--data-header--3',
      spanClass: `lead header-label`,
      spanClass2: `lead header-label mb4-1`,
      headerDesignationCol: `Code`,
    },
    {
      colClass: 'companies--data-header--4',
      spanClass: `lead header-label`,
      spanClass2: `lead header-label mb4-1`,
      headerDesignationCol: `Installers`,
    },
    {
      colClass: 'companies--data-header--5',
      spanClass: `lead header-label pl2`,
      spanClass2: `lead header-label mb4-1 pl2 pl52`,
      headerDesignationCol: `Actions`,
    },
  ],
  modal: {
    view: {
      title: `View company`,
      action: ``,
      bodyText: ``,
      btns: {
        labelOk: ``,
        labelCancel: ``,
      },
      modalHeaderId: `view-company1`,
      sectionTitle: `Company details`,
      sectionSubtitle: `Some company data`,
    },
    delete: {
      title: `Delete company`,
      action: ``,
      bodyText: ``,
      btns: {
        labelOk: ``,
        labelCancel: ``,
      },
      modalHeaderId: `delete-company1`,
    },
  },
  notifications: {
    delete: {
      success: 'The company has been deleted!',
      error: 'Something went wrong!',
    },
  },
  tooltip: {
    title: `Grab the code`,
    txt: `Copy the code to clipboard`,
  },
  menu: [
    {
      id: `firstOpt`,
      label: `View`,
      dataTarget: '#view-company-modal-id',
      dataToggle: `modal`,
    },
    {
      id: `secondOpt`,
      label: `Edit`,
      dataTarget: ``,
      dataToggle: ``,
    },
    {
      id: `thirdOpt`,
      label: `Delete`,
      dataTarget: '#delete-company-modal-id',
      dataToggle: `modal`,
    },
    {
      id: `fourthOpt`,
      label: `View Installers`,
      dataTarget: ``,
      dataToggle: ``,
    },
  ],
  rowClasses: {
    col1: `col-xs-12 col-sm-12 col-md-3 comp-name`,
    span1: `lead lead-adm bolder-ft the-name-with-the-circle1`,
    col2: `col-xs-12 col-sm-12 col-md-3 comp-location`,
    span2: `lead lead-adm location-span1`,
    col3: `col-xs-12 col-sm-12 col-md-2 center--dv--1`,
    span3: `lead lead-adm location-span1`,
    col4: `col-xs-12 col-sm-12 col-md-2 center--dv--1`,
    span4: `lead lead-adm spec-lead-adm1`,
    colMenu: `col-xs-12 col-sm-12 col-md-1 col-lg-2 col-xl-2 btn-vab cent-col-contents1`,
  },
};
