import React from 'react';
import { inputTypes, fieldsThatRequireAddLabel, _config } from './config';
import foobot_dd from '../../../../../../../../assets/small/foobot_dd.png';
import airthings from '../../../../../../../../assets/small/airthings.jpg';
import awair from '../../../../../../../../assets/small/awair.png';

const returnPlaceHolder = (elem: string) => {
  let placeholder = [];
  let plc;
  let lower = elem?.match(/[a-z]+|[0-9]+/g);
  let upper = elem?.match(/[A-Z][a-z]+|[0-9]+/g);

  if (lower) {
    placeholder.push(lower[0]);
  }
  if (upper) {
    placeholder.push(upper.join(' '));
  }
  plc = placeholder.join(' ').toLowerCase();
  return plc.charAt(0).toUpperCase() + plc.slice(1);
};

const returnTypes = (type: string, elem?: any) => {
  switch (type) {
    case 'string':
      return elem && elem === 'systemLocationZipCode' ? 'number' : 'text';
    case 'select':
      return 'select';
    case 'boolean':
      return elem && elem === `hoursOfOperation` ? 'radio' : 'switch';
    case 'number':
      return 'text';
    default:
      return 'text';
  }
};

const checkIfNum = (val: string) => {
  if (inputTypes.hasOwnProperty(val)) {
    return inputTypes[val];
  }
};

const determineIcon = (type: string) => {
  if (type === 'airthings') {
    return (
      <img
        src={airthings}
        style={{ width: '25px', height: '27px', marginRight: '0.2rem' }}
      />
    );
  }
  if (type === 'foobot') {
    return <img src={foobot_dd} />;
  }
  if (type === 'awair-element' || type === 'awair-omni') {
    return (
      <img
        src={awair}
        style={{ width: '24px', height: '26px', marginRight: '0.35rem' }}
      />
    );
  } else return null;
};

const returnAdditionalLabel = (element: string) => {
  if (fieldsThatRequireAddLabel.includes(element)) {
    return true;
  } else return false;
};

const returnVal = (elem: any, inputValues: any) => {
  return elem[0] === `systemName`
    ? inputValues.systemName
    : elem[0] === `systemLocationState`
    ? inputValues.systemLocationState
    : elem[0] === `systemLocationCity`
    ? inputValues.systemLocationCity
    : elem[0] === `systemLocationZipCode`
    ? inputValues.systemLocationZipCode
    : '';
};

const returnPlaceholderValue = (
  loadedSysDetails: boolean,
  systemDetails: any,
  elem: string,
  hasVentConnect?: boolean,
  primaryCont?: string,
) => {
  if (loadedSysDetails && Object.keys(systemDetails).length > 0) {
    handleElemPlaceholder(elem, hasVentConnect, primaryCont, systemDetails);
  }
};

const handleElemPlaceholder = (
  elem: any,
  hasVentConnect: boolean | undefined,
  primaryCont: any,
  systemDetails: any,
) => {
  if (elem === 'minutesPerHourExhaustRun') {
    if (
      hasVentConnect &&
      primaryCont &&
      primaryCont[0] === 'FanConnect' &&
      systemDetails.hasOwnProperty('minutesPerHourExhaustRunFC')
    ) {
      return systemDetails['minutesPerHourExhaustRunFC'];
    } else if (
      hasVentConnect &&
      primaryCont &&
      primaryCont[0] !== 'FanConnect' &&
      systemDetails.hasOwnProperty('minutesPerHourExhaustRunVC')
    ) {
      return systemDetails['minutesPerHourExhaustRunVC'];
    }
  }

  if (elem === 'exhaustFanDelayTime') {
    if (
      hasVentConnect &&
      primaryCont &&
      primaryCont[0] === 'FanConnect' &&
      systemDetails.hasOwnProperty(elem)
    ) {
      return systemDetails[elem];
    } else if (
      hasVentConnect &&
      primaryCont &&
      primaryCont[0] !== 'FanConnect' &&
      systemDetails.hasOwnProperty(elem)
    ) {
      return systemDetails[elem];
    }
  } else if (systemDetails.hasOwnProperty(elem)) {
    return systemDetails[elem];
  }
};

export const functions = {
  returnPlaceHolder: returnPlaceHolder,
  returnTypes: returnTypes,
  checkIfNum: checkIfNum,
  determineIcon: determineIcon,
  returnAdditionalLabel: returnAdditionalLabel,
  returnVal: returnVal,
  returnPlaceholderValue: returnPlaceholderValue,
  handleElemPlaceholder: handleElemPlaceholder,
};
