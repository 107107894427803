import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import {
  ConfigurationsList,
  AddConfiguration,
  EditConfiguration,
} from './pages';


const systems_path = '/configurations';

const ConfigurationsRouter: React.FC = (props) => {

  return (
    <>
      <BreadcrumbsItem to={`/installer${systems_path}`}>
        Configurations
      </BreadcrumbsItem>
      <Switch>
        <Route exact path={`/installer${systems_path}`} component={ConfigurationsList} />
        <Route exact path={`/installer${systems_path}/add`} component={AddConfiguration} />
        <Route path={`/installer${systems_path}/edit`} component={EditConfiguration} />
        <Route path={`*`} component={ConfigurationsList} />
      </Switch>
    </>
  );
};

export default ConfigurationsRouter;
