import React from 'react';
import { toast } from 'react-toastify';

const Notification: React.FC<any | null> = ({title, message, type}): any => {

  const Msg = () => (
    <div>
      <strong style={{textAlign:'center'}}> {title}</strong>
      <br/>
      <span style={{fontSize:'.9rem'}}>{message}</span>
    </div>
  );

  const options: any = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  
  switch (type) {
    case 'info':
      return toast.info(<Msg />, options);
    case 'success':
      return toast.success(<Msg />, options);
    case 'warn':
      return toast.warn(<Msg />, options);
    case 'error':
      return toast.error(<Msg />, options);

    default:
      return toast(<Msg />, options);
  }
};

export default Notification;
