import React from 'react';
import { NativeSelect } from '@material-ui/core';
import './styles.scss';

interface TimezoneProps {
  value: any;
  children: any;
  onChange: any;
}

const Timezone: React.FC<TimezoneProps> = ({
  value,
  onChange,
  children,
}) => {
  return (
    <div className="row">
      <div id="st-select--tmzn-installer" className="">
        <div className="">
          <label className="inputLabel">Timezone</label>
          <div className="">
            <NativeSelect
              className="select"
              value={value}
              onChange={onChange}
            >
              {children}
            </NativeSelect>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timezone;
