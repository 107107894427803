// @ts-nocheck
import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import numeral from 'numeral';
import { WidgetSlider } from '../common';
import config from './config';
import './styles.scss';

const COMPONENT_STRINGS = {...config?.strings};
const FORMATS = {...config.formats};

interface FreshConnectProps {
  device: any;
}

const FreshConnect: React.FC<FreshConnectProps> = ({device, ...props}) => {
  const [expandedCtrl, setIsExpandedCtrl]: any = useState(false);

  const handleChange = (panel: string) => (event: any, isExpanded: any) => {
    setIsExpandedCtrl(isExpanded);
  };

  return (
    <Accordion id="widget--fresh-conn" 
    square expanded={expandedCtrl} 
    onChange={handleChange('fresh-conn')}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="widget--fc"
        id="widget--fc"
      >
        <div className="widget--title-container">
          <div className="widget--row">
            <div className="widget--title-label">{COMPONENT_STRINGS.title}</div>
          </div>
          <div className="widget--row" style={{marginTop: 5}}>
            <div className="widget--col">
              <div className="widget--value">X Watts</div>
            </div>
            <div className="widget--col"></div>
            <div className="widget--col"></div>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="widget--body-container">
          <div className="widget--row"></div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default FreshConnect;
