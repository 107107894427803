import React from 'react';
import { RiSave3Fill } from 'react-icons/ri';

interface SaveProps {
  onClick: any;
  showSaveBtn: boolean;
}

const Save: React.FC<SaveProps> = ({ onClick, showSaveBtn }) => {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-1 offset-md-11 col-save-install-indoor-values">
        {showSaveBtn && (
          <button className="btn save-configs-btn-purple" onClick={onClick}>
            <RiSave3Fill />
            <span className="save-span"> SAVE SETUP</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default Save;
