import React from 'react';
import './styles.scss';


interface TableHeaderProps {
  columns: any;
}

const TableHeader: React.FC<TableHeaderProps> = ({ columns, ...props }) => {

  return (
    <div className="th-helper">
      <div className="th-helper-header th-date">{columns[0].title}</div>
      <div className="th-helper-header th-time">Time</div>
      <div className="th-helper-header th-cf">{columns[3].title}</div>
      <div className="th-helper-header th-damper">{columns[4].title}</div>
      <div className="th-helper-header th-exhaust">{columns[5].title}</div>
      <div className="th-helper-header th-fair">{columns[7].title}</div>
      <div className="th-helper-header th-thermostat">{columns[8].title}</div>
      <div className="th-helper-header th-outside">OutsideAir</div>
      <div className="th-helper-header th-indoor">IndoorAir</div>
      <div className="th-helper-header th-event">{columns[23].title}</div>

      <div className="th-helper-header th-current">{columns[1].title}</div>
      <div className="th-helper-header th-left">{columns[2].title}</div>
      <div className="th-helper-header th-state">State</div>
      <div className="th-helper-header th-light">{columns[6].title}</div>
      <div className="th-helper-header th-temp">{columns[9].title}</div>
      <div className="th-helper-header th-hum">{columns[12].title}</div>
      <div className="th-helper-header th-aqi">{columns[14].title}</div>
      <div className="th-helper-header th-pm25">{columns[16].title}</div>
      <div className="th-helper-header th-co2">{columns[18].title}</div>
      <div className="th-helper-header th-voc">{columns[20].title}</div>
    </div>
  );
};

export default TableHeader;
