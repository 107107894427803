import React, { useRef, useCallback, Fragment } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { NavigateBefore } from '@material-ui/icons';
import Header from './components/Header';
import { useAuth } from '../../../../modules/sessions/hooks/auth';
import config from './config';
import './styles.scss';

interface AsideProps {
  open: any;
  fade: any;
  toggleSidebar: any;
  container: any;
  settingsNodeIds: any;
  setSettingsNodeIds: any;
}

const Aside: React.FC<AsideProps> = ({ open, toggleSidebar, container }) => {
  let timeoutsStack: any = [];
  const { signOutAdmin } = useAuth();
  const history: any = useHistory();
  const asideElem: any = useRef(null);
  const menuElem: any = useRef(null);

  const resetTimeoutsStack = (stack: any) => {
    stack.forEach((item: any) => {
      clearTimeout(item);
    });
  };

  function handleSignOut() {
    signOutAdmin();
    history.push('/admin/login');
  }

  const onMouseEnter = useCallback(
    (event: any) => {
      if (open === 'closed') {
        resetTimeoutsStack(timeoutsStack);
        const asideEl: any = asideElem.current;
        if (asideEl) asideEl.classList.add('absolute-position', 'on-hovering');
        const menuEl: any = menuElem.current;
        if (menuEl) menuEl.classList.add('on-hover');
        const containerEl: any = container.current;
        if (containerEl) containerEl.classList.add('m-left-80');
      }
    },
    [open],
  );

  // to handle with the side menu when closed
  const onMouseLeave = useCallback(() => {
    if (open === 'closed') {
      let timeout = setTimeout(() => {
        const asideEl: any = asideElem.current;
        if (asideEl) asideEl.classList.remove('absolute-position');
        const containerEl: any = container.current;
        if (containerEl) containerEl.classList.remove('m-left-80');
      }, 600);
      timeoutsStack.push(timeout);
      const asideEl: any = asideElem.current;
      if (asideEl) asideEl.classList.remove('on-hovering');
      const menuEl: any = menuElem.current;
      if (menuEl) menuEl.classList.remove('on-hover');
    }
  }, [open]);

  return (
    <div id="left" className={open} ref={asideElem}>
      <div className="icon-menu">
        <IconButton id="expand-btn" onClick={toggleSidebar}>
          <NavigateBefore className={open} />
        </IconButton>
      </div>
      <div
        id="air-sidebar"
        className={`sidebar ${open}`}
        ref={menuElem}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Header
          onClick={() => history.push('/dashboard')}
          spanClass={`logo-text ${open}`}
          headerClass="header"
        />

        <div className="content sidebar-nav">
          <ul id="sidebarnav" className={`menu-${open}`}>
            {config.navItems.map((item: any, index: number) => {
              return (
                <Fragment key={item.id}>
                  <li className={item.liClassName} key={index}>
                    <button
                      className={item.btnClassName}
                      aria-expanded="false"
                      onClick={() => {
                        if (item.id === `logout-option-0`) {
                          handleSignOut();
                        } else {
                          history.push(item.route);
                        }
                      }}
                    >
                      {item.icon}
                      <span className={item.spanClassName}>
                        {item.spanLabel}{' '}
                      </span>
                    </button>
                  </li>
                  <li className={item.liDivider}></li>
                </Fragment>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    settingsNodeIds: state.aside.settingsNodeIds,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    setSettingsNodeIds: dispatch.aside.setSettingsNodeIds,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Aside);
