import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import validate from 'validate.js';
import { Input, Button } from '../../../../../../../react-components';
import { useAuth } from '../../../../../sessions/hooks/auth';
import { config } from './config';

const SignInAdmin: React.FC = () => {
  const { signInAdmin } = useAuth();
  const [fieldsState, setFieldsState] = useState(config.fieldsState);
  const [errors, setErrors]: any = useState(true);
  const [loading, setLoading]: any = useState(false);

  async function handleSubmit(event: any) {
    event.preventDefault();
    setErrors(false);
    setLoading(true);
    let isValid = true;
    await validate.async(fieldsState, config.constraints).then(null, err => {
      setErrors(err);
      isValid = false;
      setLoading(false);
    });
    if (!isValid) return;
    setLoading(false);
    await signInAdmin(fieldsState);
  }

  return (
    <div className="row">
      <Helmet>
        <title>{config.strings.title} - AirCycler</title>
      </Helmet>
      <div className="mt-4 mb-4 text-center">
        <h2 className="mb-3 text-center">{config.strings.title}</h2>
        <p className="text-center ">Enter your Aircycler admin account</p>
      </div>
      <form className="row justify-content-center mb-4" onSubmit={handleSubmit}>
        <div className="col-6 row">
          {Object.keys(config.fields).map((name, index) => (
            <Input
              handleChange={(value: any) =>
                setFieldsState((prevState: any) => ({
                  ...prevState,
                  [name]: value,
                }))
              }
              handleChangeError={(err: any) =>
                setErrors((prevState: any) => ({
                  ...prevState,
                  [name]: err,
                }))
              }
              type={config.fields[name].type}
              cols={config.fields[name].cols}
              label={config.fields[name].label}
              name={name}
              maximum={config.fields[name].maximum}
              value={fieldsState[name]}
              error={errors[name]}
              autoFocus={config.fields[name].autofocus}
              constraint={config.constraints[name]}
              key={index}
            />
          ))}
          <div className="d-flex pt-4 pb-2">
            <Button
              variant="primary"
              className="btn  btn-primary btn-block"
              type="submit"
              disabled={loading}
              onClick={handleSubmit}
            >
              {config.strings.confirm}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SignInAdmin;
