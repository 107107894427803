import React from 'react';
import { IoCheckmark } from 'react-icons/io5';

export default {
  strings: {
    title: `Edit peripheral`,
    breadcrumbItem: `/admin/dashboard/user-details/`,
    breadcrumbItemTitle: `Edit peripheral`,
    mainTitle: `Edit peripheral`,
    pText: `Update Peripheral information.`,
    formTitle: `Data`,
    subStr: `Please, start typing in order to enable saving the new information.`,
    formSubTitle: `Peripheral name`,
    confirmation: `You are about to update the peripheral name`,
    sureToProceed: `Are you sure you wish to proceed?`,
    save: `SAVE`,
    cancel: `CANCEL`,
  },
  modalStrings: {
    warning: 'Information',
  },
  titles: {
    periph__update: `Update Peripheral name`,
  },
  icons: {
    periph__update: <IoCheckmark className="edit" />,
  },
  labels: {
    periph__update: ` OK`,
    loc__periph__update: ` EXIT`,
  },
  bodies: {
    periph__update: ``,
  },
  bodyClass: {
    periph__update: `alert alert-info`,
  },
};
