import React from 'react';
import { DetailsGridProps } from './interface';
import { GridCard } from './components';
import Skeleton from '@material-ui/lab/Skeleton';
import './styles.scss';

const DetailsGrid: React.FC<DetailsGridProps> = ({
  gridData,
  gridContent,
  dataLoaded,
}) => {
  return (
    <div className="row dashboard-top-grid">
      <div className="container-fluid">
        <div className="row align-items-start">
          {gridData.map((item: any, index: number) => {
            if (dataLoaded) {
              return (
                <div className="col" key={index}>
                  <GridCard
                    title={item.title}
                    subtitle={item.subtitle}
                    description={gridContent[index]}
                  />
                  {index === 2 && <div className="row1"></div>}
                </div>
              );
            } else {
              return (
                <div className="col" key={index}>
                  <GridCard
                    title={
                      <Skeleton variant="text" height={25} width={'100%'} />
                    }
                    subtitle={
                      <Skeleton variant="text" height={20} width={'100%'} />
                    }
                    description={
                      <Skeleton variant="text" height={20} width={'100%'} />
                    }
                  />
                  {index === 2 && <div className="row1"></div>}
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default DetailsGrid;
