//import { NotificationManager } from "react-notifications";
import { toast } from 'react-toastify';


const Error = (response: any, status: any, path?: any) => {
  const includesWeather: boolean = path && path !== '' ? path.includes('weather') : false;

  if(includesWeather){
    return;
  }

  if (status === 401) {
   // window.location.href = "/login";
  }
  if (response?.body) {
    // eslint-disable-next-line
    Object.keys(response.body.error).map(name => {
      toast.warn(`${response.body.error[name].message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // NotificationManager.warning(`${response.body.error[name].message}`);
    });
  } else {
    /*
    NotificationManager.warning(
      response?.message ||
        "Network error - there may be a problem with your internet connection"
    );
    */
  }
};
export default Error;
