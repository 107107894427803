import React from 'react';
import { Input } from 'react-components';
import { parseNumber } from '../../helpers';


interface RangeTimeProps {
  onChangeState: (name: string, value: any) => void;
  config: any;
  fieldsState: any;
  errors: any;
}

const RangeTime: React.FC<RangeTimeProps> = ({
  onChangeState,
  config,
  fieldsState,
  errors,
}) => {
  return (
    <div className="container-hours">
      <Input
        handleChange={(value: any) => {
          onChangeState('hrsv', parseNumber(value));
        }}
        handleChangeError={(err: any) => {}}
        disabled={false}
        type={config.fields.hrsv.type}
        label={config.fields.hrsv.label}
        value={`${fieldsState?.hrsv}`}
        error={errors?.hrsv}
        name={'hrsv'}
      />
      <hr />
      <Input
        handleChange={(value: any) => {
          onChangeState('hstv', parseNumber(value));
        }}
        handleChangeError={(err: any) => {}}
        disabled={false}
        type={config.fields.hstv.type}
        label={config.fields.hstv.label}
        value={`${fieldsState?.hstv}`}
        error={errors?.hstv}
        name={'hstv'}
      />
    </div>
  );
};

export default RangeTime;
