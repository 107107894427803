import config from '../../../../config/config';

export default {
  strings: {
    ...config.strings,
    title: 'Recover Password',
    confirm: 'Recover',
    cancel: 'Back',
  },
  fieldsState: {},
  fieldsFocus: {},
  fields: {
    email: {
      label: 'E-mail',
      type: 'email',
      placeholder: 'E-mail',
      cols: {
        col: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      },
    },
  },
  constraints: {
    email: {
      email: true,
      presence: true,
    },
  },
};
