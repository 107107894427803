import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as numeral from 'numeral';
import { DetailsChip } from '../../';
import { AwairLogoHeader } from '../../../../../../../../awair/common';
import { Convertions } from '../../../../../../../../../../core/utils';
import config from './config';
import './styles.scss';

interface AwairProps {
  device: any;
  loading: any;
  requestData: any;
  localSensorData: any;
  updateLocalSensor: Function;
}

const Awair: React.FC<AwairProps> = ({ device, ...props }) => {
  const [deviceSettings, setDeviceSettings]: any = useState(null);
  const [expanded, setExpanded]: any = useState(false);


  const handleRequestData = async (device_id: string) => {
    props.loading.start('Loading Device Data');

    await props.requestData({ id: device_id }).then((response: any) => {
      props.loading.stop();

      if (response?.statusCode === 200 && response?.body) {
        setDeviceSettings(response.body);
      }
    });
  };


  const mapParamIndex = (param: string) => {
    switch (param) {
      case 'time':
        return 0;
      case 'pm':
      case 'pm25':
        return 1;
      case 'tmp':
        return 2;
      case 'hum':
        return 3;
      case 'co2':
        return 4;
      case 'voc':
        return 5;
      case 'radon':
        return 6;
      case 'pm1':
        return 7;
      case 'press':
        return 8;
      default:
        return -1;
    }
  };


  const readAwairValue = (values: any, sensorIndex: number) => {

    if (sensorIndex > -1 && values) {
      const { datapoints } = values;

      if (datapoints && datapoints[0]){
        return datapoints[0][sensorIndex];
      }
    }
    return;
  };

  const readAwairFirmware = (value: any, index: number) => {
    return {
      type: () => {
        return 'N/A';
      },
      version: () => {
        return 'N/A';
      },
    };
  };

  const readAwairSensorStats = (values: any, index: number) => {
    if (values && index > -1) {
      const { datapoints } = values;

      if(datapoints && datapoints[0]){
        return datapoints[0][index] !== null && datapoints[0][index] !== undefined
            ? numeral(datapoints[0][index]).format('0,0.[00]')
            : 'N/A';
      }
    }
    return;
  };

  const handleExpandPanel = (panel: any) => (event: any, isExpanded: any) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getSensorSystemName = (sensorData: any) => {
    const { system }: any = sensorData || {};

    if(system !== null && system !== undefined){
      return `(LOCAL - ${system?.device_name || ''})`;
    }

    return '';
  };




  useEffect(() => {
    if (device) {
      const { details }: any = device || {};

      if(details?.uuid){
        handleRequestData(details.uuid);
      }

    }
  }, [device]);

  useEffect(() => {
    return () => {
      setDeviceSettings(null);
      props.updateLocalSensor(null);
    };
  }, []);


  return (
    <div id="device-details-awair">
      <Grid container>
        <Grid item className="device-details--header">
          <AwairLogoHeader />
        </Grid>
        <Grid item xs={12} sm={12} className="device-details--name">
          <div>
            <h4>{device?.device_name}</h4>
            <h6>{getSensorSystemName(props.localSensorData)}</h6>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        className="device-container-sensors"
        style={{ marginTop: 0 }}
      >
        <Grid item xs={12} sm={12} className="device-details--section-title">
          <div>
            <h5>{config?.strings?.titles?.sensorsStats}</h5>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} className="device-details--values">
          <Grid sm={12} item className="device-details--values-row co2-icon">
            <DetailsChip
              type="co2"
              label={config?.strings?.labels?.co2}
              value={readAwairSensorStats(
                deviceSettings?.datapoint,
                mapParamIndex('co2'),
              )}
            />
          </Grid>
          <Grid sm={12} item className="device-details--values-row">
            <DetailsChip
              type="humidity"
              label={config?.strings?.labels?.humidity}
              value={readAwairSensorStats(
                deviceSettings?.datapoint,
                mapParamIndex('hum'),
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} className="device-details--values">
          <Grid sm={12} item className="device-details--values-row">
            <DetailsChip
              type="temp"
              scale="celcius"
              label={config?.strings?.labels?.temp}
              value={readAwairSensorStats(
                deviceSettings?.datapoint,
                mapParamIndex('tmp'),
              )}
            />
          </Grid>
          <Grid sm={12} item className="device-details--values-row voc-icon">
          </Grid>
        </Grid>
      </Grid>
      <Grid container className="device-container-air">
        <Grid item xs={12} sm={12} className="device-details--section-title">
          <div>
            <h5>{config?.strings?.titles?.airQuality}</h5>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} className="device-details--values">
          <Grid sm={12} item className="device-details--values-row o3-icon">
            <DetailsChip
              type="voc"
              label={config?.strings?.labels?.voc}
              value={readAwairSensorStats(
                deviceSettings?.datapoint,
                mapParamIndex('voc'),
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} className="device-details--values">
          <Grid sm={12} item className="device-details--values-row pm25-icon">
            <DetailsChip
              type="pm25"
              label={config?.strings?.labels?.pm25}
              value={readAwairValue(deviceSettings?.datapoint, mapParamIndex('pm25'))}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container className="device-container-sensors">
        <Accordion
          expanded={expanded === 'about-device'}
          onChange={handleExpandPanel('about-device')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="about-device-content"
            id="about-device-header"
          >
            <Typography
              style={{
                width: '40%',
                flexShrink: 0,
                fontSize: '18px',
                fontWeight: 500,
              }}
            >
              About
            </Typography>
            <Typography style={{ color: '#70707099' }}>
              Click to {expanded === 'about-device' ? 'hide' : 'show'} details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              className="device-details--values"
            >
              <Grid sm={12} item className="device-details--values-row">
                <label>{config?.strings?.labels?.macAddrr} </label>
                <div>{device?.details?.id}</div>
              </Grid>
              <Grid sm={12} item className="device-details--values-row">
                <label>{config?.strings?.labels?.upTime} </label>
                <div>
                  {Convertions.secToHours(
                    deviceSettings?.connectivity?.averageMqttSessionLengthSec,
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              className="device-details--values"
            >
              <Grid sm={12} item className="device-details--values-row">
                <label>{config?.strings?.labels?.firmwareType} </label>
                <div>
                  {readAwairFirmware(
                    deviceSettings,
                    0,
                  ).type()}
                </div>
              </Grid>
              <Grid sm={12} item className="device-details--values-row">
                <label>{config?.strings?.labels?.firmware} </label>
                <div>
                  {readAwairFirmware(
                    deviceSettings,
                    0,
                  ).version()}
                </div>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              className="device-details--values"
            >
              <Grid sm={12} item className="device-details--values-row">
                <label>{config?.strings?.labels?.firmwareType} </label>
                <div>
                  {readAwairFirmware(
                    deviceSettings,
                    1,
                  ).type()}
                </div>
              </Grid>
              <Grid sm={12} item className="device-details--values-row">
                <label>{config?.strings?.labels?.firmware} </label>
                <div>
                  {readAwairFirmware(
                    deviceSettings,
                    1,
                  ).version()}
                </div>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </div>
  );
};

function mapStateToProps(state: any) {
  const { awair } = state;
  return {
    localSensorData: awair.localSensor,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { awair, loader } = dispatch;

  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    requestData: awair.getLastestValues,
    updateLocalSensor: awair.updateLocalSensor,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Awair);
