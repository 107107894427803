import React, { useEffect, useState } from 'react';
import config from './config';
import validate from 'validate.js';
import { connect } from 'react-redux';
import { functions } from './helpers';
import { EditCompanyProps } from './interface';
import { useHistory } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Modal, Notification, Template } from 'business/modules/admin/common';
import { Form, FormFooter, Warning } from '../../common';
import './styles.scss';

const EditCompany: React.FC<EditCompanyProps> = ({
  getTheCompaniesList,
  signalSuccessUpd,
  showCompanyDetails,
  mustResetToUpdatedState,
  companyShowDetail,
  setToEditCompData,
  currentCompanyToEdit,
  getCompanyGeneratedCode,
  setError,
  setIsACompanyEdition,
  isACompanyEdition,
  defineCompanyToEdit,
  clearUpdatedCompany,
  currentlyEmptyFieldsEdition,
  updatedCompany,
  thereWasAlreadyUpd,
  markUpdate,
  resetToUpdatedState,
  toEditCompanyData,
  saveUpdatingCompany,
  generatedCode,
  userClearedEd,
  detectEmptyFieldsOnEditing,
  updatedSuccess,
  updateCurrCompany,
  compItem,
  handleModalState,
  modalMustClose,
  saveCompItemGlobally,
  loading,
}) => {
  const history = useHistory();
  const [persistentComp, setPersistentComp] = useState(config.persistentComp);
  const [readyData, setReadyData] = useState(false);
  const [editPh, setEditPh] = useState('');
  const [errors, setErrors]: any = useState(null);

  const handlePhoneEdit = async (value: any) => {
    let currentFields: any = { phone: value };
    let currentConstraints: any = {
      phone: {
        ...config.constraints['phone' as keyof typeof config.constraints],
      },
    };

    setEditPh(value);

    await validate
      .async(currentFields, currentConstraints)
      .then((res: any) => {
        setErrors((prevErrors: any) => {
          return { ...prevErrors, phone: '' };
        });
      })
      .catch(err => {
        setErrors((prevErrors: any) => {
          return { ...prevErrors, phone: err.phone };
        });
      });
  };

  const handleEditInput = async (event: any) => {
    let name: any = event.target.name;
    resetToUpdatedState(false);

    let currentFields: any = { [name]: event.target.value };

    let currentConstraints: any = {
      [name]: {
        ...config.constraints[name as keyof typeof config.constraints],
      },
    };

    setToEditCompData({
      ...toEditCompanyData,
      [event.target.name]: event.target.value,
      phone: editPh,
    });

    await validate
      .async(currentFields, currentConstraints)
      .then((res: any) => {
        setErrors((prevErrors: any) => {
          return { ...prevErrors, [name]: '' };
        });
      })
      .catch((err: any) => {
        setErrors((prevErrors: any) => {
          return { ...prevErrors, [name]: err[name] };
        });
      });
  };

  const validateIfEmptyFields = () => {
    if (currentlyEmptyFieldsEdition) {
      return;
    }

    let areThereEmptyFields: any;
    areThereEmptyFields = functions.preventEmptyData(updatedCompany);

    if (!areThereEmptyFields) {
      setError('');
    } else if (areThereEmptyFields) {
      setError('mandatoryFieldsMissing');
    }
  };

  const handleClear = () => {
    if (!thereWasAlreadyUpd) {
      clearUpdatedCompany();
    } else {
      resetToUpdatedState(true);
    }
  };

  const getTheCode = async () => {
    await getCompanyGeneratedCode();
  };

  const getCompDetails = async () => {
    loading.start('Loading company details...');

    //handle page refresh data preservation - persistentComp
    let companyDetailsResp: any = await showCompanyDetails({
      id: persistentComp?.company_id,
    });

    //keep the updated values even
    //on clicking the cancel btn
    //after another successful update

    if (companyDetailsResp?.statusCode === 200) {
      localStorage.setItem(
        '@currentCompanyToEdit',
        JSON.stringify(companyDetailsResp?.body?.data[0]),
      );

      setToEditCompData({
        name: companyDetailsResp?.body?.data[0]?.name,
        email: companyDetailsResp?.body?.data[0]?.contact?.email,
        phone: companyDetailsResp?.body?.data[0]?.contact?.phone,
        state: companyDetailsResp?.body?.data[0]?.state,
        zipcode: companyDetailsResp?.body?.data[0]?.zipcode,
        city: companyDetailsResp?.body?.data[0]?.city,
        code: companyDetailsResp?.body?.data[0]?.code,
      });
    } else {
      //TODO handle error
    }

    signalSuccessUpd(false);
    loading.stop();
  };

  const updateCompany = async () => {
    const updatedCompanyData: any = {
      name: updatedCompany.name,
      code: updatedCompany.code,
      phone: updatedCompany.phone,
      email: updatedCompany.email,
      state: updatedCompany.state,
      city: updatedCompany.city,
      zipcode: updatedCompany.zipcode,
    };

    let updated: any = await updateCurrCompany({
      data: updatedCompanyData,
      id: compItem?.company_id,
    });

    if (updated?.statusCode === 200) {
      Notification({
        title: 'Success!',
        message: config?.notifications?.edit?.success,
        type: 'success',
      });
    } else {
      Notification({
        title: 'Warning!',
        message: config?.notifications?.edit?.error,
        type: 'warning',
      });
    }

    await getTheCompaniesList();
  };

  useEffect(() => {
    if (updatedSuccess && readyData) {
      getCompDetails();
    }
  }, [updatedSuccess, readyData]);

  //Clear fields after update
  useEffect(() => {
    if (mustResetToUpdatedState) {
      setToEditCompData({
        name: companyShowDetail?.name,
        email: companyShowDetail?.contact?.email,
        phone: companyShowDetail?.contact?.phone,
        state: companyShowDetail?.state,
        zipcode: companyShowDetail?.zipcode,
        city: companyShowDetail?.city,
        code: companyShowDetail?.code,
      });
    }
  }, [mustResetToUpdatedState]);

  useEffect(() => {
    validateIfEmptyFields();
  }, [toEditCompanyData, generatedCode]);

  //Save input values on changing
  useEffect(() => {
    saveUpdatingCompany({ ...toEditCompanyData, phone: editPh });
  }, [toEditCompanyData, editPh]);

  //Handle user quiting update - replaces the prev values
  useEffect(() => {
    if (userClearedEd) {
      setToEditCompData({
        name: currentCompanyToEdit?.name,
        email: currentCompanyToEdit?.contact?.email,
        phone: currentCompanyToEdit?.contact?.phone,
        state: currentCompanyToEdit?.state,
        zipcode: currentCompanyToEdit?.zipcode,
        city: currentCompanyToEdit?.city,
        code: currentCompanyToEdit?.code,
      });
      setEditPh(currentCompanyToEdit?.contact?.phone);

      setErrors();
    }
  }, [userClearedEd, editPh]);

  useEffect(() => {
    setEditPh(toEditCompanyData.phone);
  }, [toEditCompanyData]);

  useEffect(() => {
    if (readyData) {
      getCompDetails();
      setIsACompanyEdition(true);
    }
  }, [readyData]);

  useEffect(() => {
    return () => {
      setIsACompanyEdition(false);
      markUpdate(false);
      resetToUpdatedState(false);
    };
  }, []);

  useEffect(() => {
    getTheCode();
  }, []);

  useEffect(() => {
    //Handle page refresh
    let storedCurrCmp: any = localStorage.getItem('@currentCompanyToEdit');
    let storedCurrCompanyItem: any = localStorage.getItem('@companyItem');

    if (storedCurrCmp && storedCurrCompanyItem) {
      let parsed = JSON.parse(storedCurrCmp);

      let parsedCompItem: any = JSON.parse(storedCurrCompanyItem);

      setPersistentComp(parsed);
      setReadyData(true);

      setToEditCompData({
        name: parsed?.name,
        email: parsed?.contact?.email,
        phone: parsed?.contact?.phone,
        state: parsed?.state,
        zipcode: parsed?.zipcode,
        city: parsed?.city,
        code: parsed?.code,
      });

      defineCompanyToEdit(parsed);
      saveCompItemGlobally(parsedCompItem);
    }
  }, []);

  //fire the warning and prevent submit data with missing fields
  useEffect(() => {
    if (
      functions.thereIsData(
        isACompanyEdition,
        toEditCompanyData,
        currentCompanyToEdit,
      )
    ) {
      let catchEmptyVals: any = Object.entries(toEditCompanyData).some(
        ([key, val]: [any, any]) => {
          return key !== 'phone' && val.trim().length === 0;
        },
      );

      //handling the warning and hide save btn
      if (catchEmptyVals || editPh.length === 0) {
        detectEmptyFieldsOnEditing(true);
      } else {
        detectEmptyFieldsOnEditing(false);
      }
    }
  }, [toEditCompanyData, isACompanyEdition, editPh, currentCompanyToEdit]);

  useEffect(() => {
    if (isACompanyEdition && Object.keys(currentCompanyToEdit).length > 0) {
      setToEditCompData({
        ...toEditCompanyData,
        name: currentCompanyToEdit?.name,
        email: currentCompanyToEdit?.contact?.email,
        phone: currentCompanyToEdit?.contact?.phone,
        state: currentCompanyToEdit?.state,
        city: currentCompanyToEdit?.city,
        zipcode: currentCompanyToEdit?.zipcode,
        code: currentCompanyToEdit?.code,
      });
    }
  }, [currentCompanyToEdit, isACompanyEdition]);

  return (
    <Template
      id="dashboard-edit-company"
      title={config?.companyEdit?.title}
      goBack={() => {
        history.push('/admin/dashboard/companies-list');
      }}
    >
      <BreadcrumbsItem to={config?.companyEdit?.breadcrumbItem}>
        {config.strings.breadcrumbEdit}
      </BreadcrumbsItem>

      <div className={config.companyEdit.class} id="edit--company--screen">
        <h4 className="centered">{config.companyEdit.mainTitle} </h4>
        <p className="lead centered card-top-ft">{config.companyEdit.pText}</p>
        <hr className="my-4" />

        <Form
          error={errors}
          disabledGenerate={true}
          fields={config.editFields}
          origin={`edit`}
          data={toEditCompanyData}
          generateNewCode={() => {}}
          config={config}
          generatedCode={toEditCompanyData?.code}
          getUserInput={(event: any) => handleEditInput(event)}
          handlePhone={handlePhoneEdit}
          genLabel={config.genLabel}
          tooltip={null}
          phone={editPh}
        />

        {readyData && (
          <FormFooter
            emptyFields={currentlyEmptyFieldsEdition}
            dataTarget={`#save-changes-to-current-company`}
            onClick={handleClear}
            onClickValidate={() => validateIfEmptyFields()}
          />
        )}
        <Warning
          id={`warning-edit`}
          currentlyEmptyFieldsEdition={currentlyEmptyFieldsEdition}
          warning={config.strings.warning}
        />
        <Modal
          config={config}
          bodyClass={functions.handleOpts(`edit__comp`, 'bodyClass')}
          className={`small-button ok`}
          onClick={() => {
            updateCompany();
          }}
          title={functions.handleOpts(`edit__comp`, `title`)}
          id={`save-changes-to-current-company`}
          icon={functions.handleOpts(`edit__comp`, `icon`)}
          body={functions.handleOpts(`edit__comp`, 'bodies')}
          modalMustClose={modalMustClose}
          handleModalState={handleModalState}
          label={config.labels[`edit__comp` as keyof typeof config.labels]}
          hasConfirmBtn={true}
          sureToProceed={config.modalStrings.sureToProceed}
        />
      </div>
    </Template>
  );
};

function mapStateToProps(state: any) {
  const { adminCompaniesState } = state;

  return {
    compItem: adminCompaniesState.compItem,
    generatedCode: adminCompaniesState.generatedCode,
    companyShowDetail: adminCompaniesState.companyShowDetail,
    modalMustClose: adminCompaniesState.modalMustClose,
    userClearedEd: adminCompaniesState.userClearedEd,
    isACompanyEdition: adminCompaniesState.isACompanyEdition,
    updatedCompany: adminCompaniesState.updatedCompany,
    thereWasAlreadyUpd: adminCompaniesState.thereWasAlreadyUpd,
    toEditCompanyData: adminCompaniesState.toEditCompanyData,
    currentCompanyToEdit: adminCompaniesState.currentCompanyToEdit,
    mustResetToUpdatedState: adminCompaniesState.mustResetToUpdatedState,
    updatedSuccess: adminCompaniesState.updatedSuccess,
    currentlyEmptyFieldsEdition:
      adminCompaniesState.currentlyEmptyFieldsEdition,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { adminCompaniesState, loader } = dispatch;

  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    setError: adminCompaniesState.setError,
    getCompanyGeneratedCode: adminCompaniesState.getCompanyGeneratedCode,
    setIsACompanyEdition: adminCompaniesState.setIsACompanyEdition,
    clearUpdatedCompany: adminCompaniesState.clearUpdatedCompany,
    markUpdate: adminCompaniesState.markUpdate,
    resetToUpdatedState: adminCompaniesState.resetToUpdatedState,
    saveUpdatingCompany: adminCompaniesState.saveUpdatingCompany,
    setToEditCompData: adminCompaniesState.setToEditCompData,
    detectEmptyFieldsOnEditing: adminCompaniesState.detectEmptyFieldsOnEditing,
    showCompanyDetails: adminCompaniesState.showCompanyDetails,
    signalSuccessUpd: adminCompaniesState.signalSuccessUpd,
    updateCurrCompany: adminCompaniesState.updateCurrCompany,
    handleModalState: adminCompaniesState.handleModalState,
    getTheCompaniesList: adminCompaniesState.getTheCompaniesList,
    defineCompanyToEdit: adminCompaniesState.defineCompanyToEdit,
    saveCompItemGlobally: adminCompaniesState.saveCompItemGlobally,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCompany);
