import React, { useState, useEffect, Fragment, useRef } from 'react';
import config from './config';
import { connect } from 'react-redux';
import { SystemProps } from './interface';
import { helpers } from './helpers';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  Button,
  UnorderedList,
  CommonMenu,
} from 'business/modules/admin/common';
import { GoSettings } from 'react-icons/go';
import { MdOutlineLocationOn } from 'react-icons/md';
import { AiOutlineFieldTime, AiOutlineHome } from 'react-icons/ai';
import { TbPlugConnected, TbNumbers } from 'react-icons/tb';
import {
  ConnectedIcon,
  DisconnectedIcon,
} from '../../../../../../../../assets/device-icons';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton';
import './styles.scss';

const Systems: React.FC<SystemProps> = ({
  sectionData,
  setItem,
  element,
  systemsWithConnData,
  loadedSystemsWithConnData,
  saveDevDesignations,
  setDataToDelete,
  defineSelectedSystem,
  selectedSystem,
  currentPeripheral,
  setDataInStore,
  showDeviceDetailsAdm,
  saveSystemData,
  saveSystemDetails,
}) => {
  const url = config?.url;
  const edit = config?.edit;
  const setup = config?.setup;
  const currentBtn = useRef(null);
  const [expandedCtrl, setIsExpandedCtrl] = useState<boolean>(false);
  const [anchorSys, setAnchorSys] = useState<any>(null);
  const openSys = Boolean(anchorSys);
  const [connDevices, setConnDevices]: any = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const history: any = useHistory();

  const [localSensors, setLocalSensors] = useState<any>({
    loaded: false,
    sensors: [],
  });
  const [devs, setDevs] = useState<any>({
    loaded: false,
    devs: [],
  });

  const handleChange = (panel: string) => (event: any, isExpanded: any) => {
    defineSelectedSystem(panel.split('_')[1]);
    setIsExpandedCtrl(isExpanded);
  };

  const handleClickSys = (event: any) => {
    event.stopPropagation();
    setAnchorSys(event.currentTarget);

    setItem(element);

    setDataToDelete({
      devName: element.device_name,
      deviceId: element.device_id,
    });
  };

  const handleCloseSys = () => {
    setItem(element);
    setAnchorSys(null);
  };

  const handleMenuOption = (event: any, opt: string) => {
    setAnchorSys(null);
    event.stopPropagation();
    switch (opt) {
      case 'delete-sys':
        return () => {};
      case 'edit-sys':
        return editNameLocation(element);
      default:
        return () => {};
    }
  };

  const returnWifiConnectionStatus = (
    systemsWithConnData: any,
    device: any,
  ) => {
    if (loadedSystemsWithConnData && systemsWithConnData.length > 0) {
      let theItem: any = systemsWithConnData.filter((elem: any) => {
        return elem.device_id === device?.device_id;
      });
      return theItem[0]?.isWifiConn;
    }
  };

  const handleListChildren = (device: any, value: any) => {
    return (
      <>
        {value.string}
        {helpers.returnPillValue(device, value.id)}{' '}
      </>
    );
  };

  const handleClick = (event: any, per: any, dev: any) => {
    setDataInStore(per, 'currPer');
    setDataInStore(dev?.device_name, 'name');
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (perId: string, devId: string) => {
    history.push(`${url}${devId}${edit}${currentPeripheral?.cdid}`);
  };

  const getSetupInfo = async (deviceId: string) => {
    //TODO handle error
    await showDeviceDetailsAdm({
      device_id: deviceId,
      id: localStorage.getItem('currentAccount'),
    });
  };

  const updateSetup = (perId: string, devId: string) => {
    history.push(`${url}${devId}${setup}${perId}`);
  };

  const editNameLocation = (element: any) => {
    saveSystemDetails(element);

    history.push(
      `/admin/dashboard/user-details/devices/edit-details/${element.device_id}`,
    );
  };

  const editSystem = (element: any) => {
    const _payload = {
      deviceId: element.device_id,
      deviceName: element.device_name,
    };

    saveSystemData(_payload);

    defineSelectedSystem(element.device_id);
    saveSystemDetails(element);
    history.push(
      `/admin/dashboard/user-details/system-settings/${element.device_id}`,
    );
  };

  useEffect(() => {
    if (element?.details?.settings) {
      const { hrss } = element?.details?.settings || {};
      if (element?.details?.settings?.conf?.cdvs) {
        setConnDevices(helpers.prepareConnectVents(element));
      }
    }
    return () => {};
  }, [element]);

  useEffect(() => {
    if (connDevices.length > 0) {
      let devsWithLabel: any = connDevices.map((elem: any) =>
        helpers.returnPeriphDesignation(elem),
      );

      saveDevDesignations({
        device_id: element?.device_id,
        device_name: element?.device_name,
        devsWithLabel: devsWithLabel,
      });

      //local sensors
      setLocalSensors({
        loaded: true,
        sensors: connDevices.filter(
          (item: any) => item?.cdid && item.cdid.slice(9, 11) === 'AQ',
        ),
      });

      //devs
      setDevs({
        loaded: true,
        devs: connDevices.filter(
          (item: any) => item?.cdid && item.cdid.slice(9, 11) !== 'AQ',
        ),
      });
    }
    return () => {};
  }, [connDevices]);

  return (
    <div style={{ marginBottom: '16px', marginTop: '16px' }}>
      <Accordion
        className={sectionData.className}
        square
        expanded={expandedCtrl}
        onChange={handleChange(`system-section_${element.device_id}`)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={''}
          id={''}
        >
          <div className={`container cont-m-h systems-accordion `}>
            <div className={`row`} id="special--list">
              <p className="p-leadtitle lead title">
                {config.sysStrings.systemData}
              </p>
              <ul className="list-group special--list">
                <li className="list-group-item jcl">
                  <p className={`card-title lead left-side`}>
                    <MdOutlineLocationOn className="location-icon" />{' '}
                    {!element.hasOwnProperty('device_zipcode') &&
                    !element.hasOwnProperty('device_state') ? (
                      <> {config.sysStrings.noLocation}</>
                    ) : (
                      <>
                        {element?.device_city?.name || 'No city'},{' '}
                        {element?.device_state?.name || 'No state'}{' '}
                      </>
                    )}
                  </p>
                </li>
                <li className="list-group-item jcl">
                  <div className="container plr-zero">
                    <div className="row plr-zero centered-top">
                      <div className="col-auto icon-col plr-zero">
                        <div className="container" id="status--sys">
                          {!loadedSystemsWithConnData ? (
                            <Skeleton variant="text" height={20} width={100} />
                          ) : returnWifiConnectionStatus(
                              systemsWithConnData,
                              element,
                            ) === 'active' ? (
                            <div className="row">
                              <div className="col-auto plr-zero">
                                <ConnectedIcon className="smaller-ic" />
                              </div>
                            </div>
                          ) : (
                            <div className="row">
                              <div className="col-auto plr-zero">
                                <DisconnectedIcon className="smaller-ic" />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-auto plr-zero">
                        <span className=" ">
                          {!loadedSystemsWithConnData ? (
                            <Skeleton variant="text" height={20} width={100} />
                          ) : returnWifiConnectionStatus(
                              systemsWithConnData,
                              element,
                            ) === 'active' ? (
                            <div className="row">
                              <div className="col-auto plr-zero">
                                <span className="status-str on">
                                  {config.sysStrings.online}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div className="row">
                              <div className="col-auto plr-zero">
                                <span className="status-str off">
                                  {config.sysStrings.offline}
                                </span>
                              </div>
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="list-group-item spec-btns">
                  <button
                    className="btn btn-sm edit-sys"
                    onClick={(event: any) => editSystem(element)}
                  >
                    <GoSettings size="1rem" />
                  </button>
                  <>
                    <Button
                      spanClass={`btn-child-ft`}
                      onClick={handleClickSys}
                      type="button"
                      className="btn opts-sys"
                    >
                      <MoreHoriz />
                    </Button>
                    <Menu
                      anchorEl={anchorSys}
                      open={openSys}
                      onClose={handleCloseSys}
                      MenuListProps={{
                        'aria-labelledby': 'more-button',
                      }}
                    >
                      {config.strings.map((option: any, index: number) => {
                        return (
                          <MenuItem
                            key={index}
                            data-toggle={option.dataToggle}
                            data-target={option.dataTarget}
                            onClick={(event: any) => {
                              handleMenuOption(event, option.id);
                            }}
                          >
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Menu>
                  </>
                </li>
              </ul>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="jumbotron system-adm-jumbo">
            <h5 className="align--left"> {element?.device_name}</h5>
            <p className="lead sa-lead align--left">
              {config.sysStrings.paragraphOne}
              {element?.device_name}
              {config.sysStrings.paragraphOneCont}
            </p>
            <hr className="my-4" />
            <p className="lead sa-lead align--left">
              {config.sysStrings.paragraphThree}
            </p>
            <div>
              <div className="container sa-container">
                <div className="row side-p-0">
                  <UnorderedList
                    data={config.listData.slice(0, 3)}
                    device={element}
                    handleListChildren={handleListChildren}
                  />
                  <UnorderedList
                    data={config.listData.slice(3)}
                    device={element}
                    handleListChildren={handleListChildren}
                  />
                </div>{' '}
              </div>
            </div>
            <hr className="my-4" />
            <p className="lead sa-lead align--left">
              {config.sysStrings.paragraphFour}
            </p>

            {connDevices && devs.loaded && devs.devs.length > 0 ? (
              devs.devs.map((per: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <div className="card sa-dev" key={index} ref={currentBtn}>
                      <div className="card-body element-info">
                        <span className="conn-devs-card">
                          {helpers.returnIcon(per)}{' '}
                          {helpers.returnPeriphDesignation(per)}{' '}
                        </span>

                        <CommonMenu
                          expands={false}
                          config={config}
                          ariaExpanded={open ? 'true' : 'false'}
                          onClick={(event: any) =>
                            handleClick(event, per, element)
                          }
                          anchorEl={anchorEl}
                          open={open}
                          handleClose={handleClose}
                          id={per.cdid}
                          onClose={() => {}}
                          secondFun={() => {
                            handleEdit(per?.cdid, selectedSystem);
                          }}
                          fourthFunction={() => {
                            getSetupInfo(element?.device_id);
                            updateSetup(per?.cdid, selectedSystem);
                          }}
                        />
                      </div>
                    </div>
                  </Fragment>
                );
              })
            ) : (
              <span className="no--devices">{config.sysStrings.noDevs}</span>
            )}

            <hr className="my-4" />
            <p className="lead sa-lead align--left">
              {config.sysStrings.paragraphFour1}
            </p>

            {connDevices &&
            localSensors.loaded &&
            localSensors.sensors.length > 0 ? (
              localSensors.sensors.map((per: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <div className="card sa-dev" key={index} ref={currentBtn}>
                      <div className="card-body element-info">
                        <span className="conn-devs-card">
                          {helpers.returnIcon(per)}{' '}
                          {helpers.returnPeriphDesignation(per)}{' '}
                        </span>

                        <CommonMenu
                          expands={false}
                          config={config}
                          ariaExpanded={open ? 'true' : 'false'}
                          onClick={(event: any) =>
                            handleClick(event, per, element)
                          }
                          anchorEl={anchorEl}
                          open={open}
                          handleClose={handleClose}
                          id={per.cdid}
                          onClose={() => {}}
                          secondFun={() => {
                            handleEdit(per?.cdid, selectedSystem);
                          }}
                          fourthFunction={() => {
                            getSetupInfo(element?.device_id);
                            updateSetup(per?.cdid, selectedSystem);
                          }}
                        />
                      </div>
                    </div>
                  </Fragment>
                );
              })
            ) : (
              <span className="no--devices">{config.sysStrings.noSensors}</span>
            )}

            <hr className="my-4" />
            <p className="lead sa-lead align--left">
              {config.sysStrings.paragraphFive}
            </p>
            <div className="container">
              <div className="row side-p-0">
                <div className="col-xs-12 col-sm-12 col-md-6 side-p-0">
                  <ul className="list-group wborder">
                    <li className="list-group-item wborder">
                      <span className="strong-font">
                        <TbNumbers className="mb-icon" />{' '}
                        {config.sysStrings.firmware}{' '}
                      </span>
                      {element?.details?.settings?.fmwv}
                    </li>

                    <li className="list-group-item wborder">
                      <span className="strong-font">
                        {' '}
                        <AiOutlineHome className="mb-icon" />{' '}
                        {config.sysStrings.macAddress}{' '}
                      </span>

                      {element?.details?.settings?.conf?.cdvs &&
                      element?.details?.settings?.conf?.cdvs.length > 0
                        ? element?.details?.settings?.conf?.cdvs[0].dmac
                        : 'No values.'}
                    </li>
                  </ul>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 side-p-0">
                  <ul className="list-group wborder">
                    <li className="list-group-item wborder">
                      <span className="strong-font">
                        {' '}
                        <AiOutlineFieldTime /> {config.sysStrings.uptime}{' '}
                      </span>
                      {element?.details?.settings?.uptm}
                    </li>
                    <li className="list-group-item wborder">
                      <span className="strong-font">
                        {' '}
                        <TbPlugConnected /> {
                          config.sysStrings.connectedDevs
                        }{' '}
                      </span>
                      {element?.details?.settings?.conf?.cdvs?.length}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

function mapStateToProps(state: any) {
  const { adminDevicesState } = state;
  return {
    selectedSystem: adminDevicesState.selectedSystem,
    currentPeripheral: adminDevicesState.currentPeripheral,
    systemsWithConnData: adminDevicesState.systemsWithConnData,
    loadedSystemsWithConnData: adminDevicesState.loadedSystemsWithConnData,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { adminDevicesState, adminSectionsState, adminApiRequests } = dispatch;
  return {
    defineSelectedSystem: adminDevicesState.defineSelectedSystem,
    showDeviceDetailsAdm: adminDevicesState.showDeviceDetailsAdm,
    saveDevDesignations: adminDevicesState.saveDevDesignations,
    setDataToDelete: adminDevicesState.setDataToDelete,
    setDataInStore: adminDevicesState.setDataInStore,
    saveSystemData: adminSectionsState.saveSystemData,
    saveSystemDetails: adminDevicesState.saveSystemDetails,
    getSystemLogs: adminApiRequests.getSystemLogs,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Systems);
