// @ts-nocheck
import React from 'react';
import {
  RouteProps as ReactDOMRouterProps,
  Route as ReactDOMRoute,
  Redirect,
} from 'react-router-dom';
import { useAuth } from '../modules/sessions/hooks/auth';
import {
  AuthenticatedApp,
  AuthenticatedAppAdmin,
  SessionApp,
  AdminSessionApp,
  InstallerAuthenticatedApp,
} from '../layout';


interface RouteProps extends ReactDOMRouterProps {
  isPrivate?: boolean;
  isAdmin?: boolean;
  isConfirm?: boolean;
  isInstaller?: boolean;
  isSingle?: boolean;
  account?: string;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  isAdmin = false,
  isConfirm = false,
  isSingle = false,
  isInstaller,
  account,
  ...rest
}) => {
  const { user, adminUser } = useAuth();

  const hasAdminRoute = (params: any) => {
    const { pathname } = params;
    let hasRoute: boolean = false;
    const pathResult: any =
      pathname && pathname !== '' ? pathname.split('/') : [];

    if(
      pathname.includes('/confirm/delete/request') ||
      pathname.includes('/delete-account')
    ){
      return false;
    }

    if (pathResult && pathResult.length > 0) {
      hasRoute = pathResult.findIndex((path: any) => path === 'admin') > -1;
    }

    return hasRoute;
  };


  const redirectController = (entry: any) => {
    switch (entry) {
      case 'installer':
        return '/installer';
      case 'customer':
      default:
        return '/dashboard';
    }
  };


  const App = props => {
    const { location, isAdminRoute } = props;

    if (isPrivate) {
      if (isAdmin && isAdminRoute) {
        if (adminUser) {
          return (
            <AuthenticatedAppAdmin>
              <Component />
            </AuthenticatedAppAdmin>
          );
        }

        return (
          <AdminSessionApp>
            <Component />
          </AdminSessionApp>
        );
      } else {
        if(isInstaller){
          return (
            <InstallerAuthenticatedApp>
              <Component/>
            </InstallerAuthenticatedApp>
          );
        }

        return (
          <AuthenticatedApp>
            <Component />
          </AuthenticatedApp>
        );
      }
    } else {
      return (
        <SessionApp>
          <Component />
        </SessionApp>
      );
    }
  };

  return (
    <>
      <ReactDOMRoute
        {...rest}
        render={({ location }) => {
          const adminRoute: any = hasAdminRoute(location);

          return isPrivate === !!user || adminRoute || isConfirm || isSingle ? (
            <App location={location} isAdminRoute={adminRoute} {...rest} />
          ) : (
            <Redirect
              to={{
                pathname: isPrivate && !user ? '/login' : redirectController(account),
                state: { from: location },
              }}
            />
          );
        }}
      />
    </>
  );
};

export default Route;
