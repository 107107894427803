import React from 'react';
import Aircycler from './Aircycler';
import Foobot from './Foobot';
import Airthings from './Airthings';
import Awair from './Awair';


interface DeviceSwitchProps {
  device: any;
}

const DeviceSwitch: React.FC<DeviceSwitchProps> = ({ device, ...props }) => {
  switch (device?.device_type) {
    case 'foobot':
      return <Foobot device={device} {...props} />;
    case 'aircycler':
      return <Aircycler device={device} {...props} />;
    case 'airthings':
      return <Airthings device={device} {...props} />;
    case 'awair':
    case 'awair-element':
    case 'awair-omni':
      return <Awair device={device} {...props} />;
    default:
      return device ? <Aircycler device={device} {...props} /> : <></>;
  }
};

export default DeviceSwitch;
