import React from 'react';
import { Grid } from '@material-ui/core';
import { AwairLogo } from '../../../../assets';
import './styles.scss';

const AwairLogoHeader: React.FC = () => {
  return (
    <Grid item xs={12} sm={12} className="awair-logo">
      <div className="awair-logo--container">
        <AwairLogo />
      </div>
    </Grid>
  );
};

export default AwairLogoHeader;
