import React, { useState, useEffect } from 'react';
import config from './config';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { UserDetailsStates, ViewAdminProps } from './interface';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import {
  Template,
  DetailsGrid,
  Button,
  Input,
  SwitchBtn,
  Modal,
} from 'business/modules/admin/common';
import { RiImageEditLine, RiEdit2Line, RiSave3Fill } from 'react-icons/ri';
import { HiOutlineXCircle } from 'react-icons/hi';
import './styles.scss';

const ViewAdmin: React.FC<ViewAdminProps> = ({
  adminUser,
  storeClickedUser,
  profileDataFieldsDisabled,
  editUserProfile,
  loading,
  getDetailsAboutAdmin,
  adminUserClicked,
  updateDisabledState,
}) => {
  const history = useHistory();
  const [errors, setErrors]: any = useState(config.states.errors);
  const [onOffState, setOnOffState] = useState(false);
  const [user, setUser] = useState<UserDetailsStates>(
    config.states.userInitState,
  );

  const gridContent: any = {
    0: '2023/01/12',
    1: adminUser?.status === 'active' ? 'active' : 'inactive',
    2: adminUser?.email && adminUser.email,
    3: 'some other data ',
  };

  const errorRetrieve: any = {
    email: errors?.emailError,
    username: errors?.usernameError,
  };

  const handleGoBack = () => {
    history.push(`/admin/dashboard/admin-list`);
  };

  const validateEmail = (address: string) => {
    if (address.match(config.regex)) {
      setErrors({ ...errors, emailError: '' });
      return true;
    } else {
      setErrors({ ...errors, emailError: 'Invalid email address.' });
      return false;
    }
  };

  const toggleEdit = () => {
    profileDataFieldsDisabled ? editUserProfile(false) : editUserProfile(true);
  };

  const resetFields = () => {
    setUser(config.states.userInitState);
    setErrors(config.states.errors);
  };

  const fetchAdminData = async (userId: string) => {
    let userData: any;
    loading.start('Loading User Details...');
    userData = await getDetailsAboutAdmin(userId);

    if (userData?.statusCode === 200 && userData?.body?.data) {
      setUser(userData?.body?.data);
      setOnOffState(userData?.body.data?.status === `active` ? true : false);
    }
    loading.stop();
  };

  const cancelChanges = () => {
    if (adminUser) {
      setUser(adminUser);
      setOnOffState(adminUser?.status === 'active' ? true : false);
    }
    updateDisabledState(true);
    setErrors({
      ...errors,
      emailError: '',
      usernameError: '',
    });
  };

  const triggerConfirmation = () => {};

  const handleEdit = (event: any) => {
    let name: any = event.target.name;
    let value: any = event.target.value;
    if (profileDataFieldsDisabled) {
      return;
    }
    if (adminUser) {
      if (name === `status`) {
        setOnOffState(!onOffState);
      }

      if (name === 'email') {
        validateEmail(value);
      }

      if (name === 'username') {
        if (value.trim().length === 0) {
          setErrors({ ...errors, usernameError: 'Field is empty.' });
        } else {
          setErrors({ ...errors, usernameError: '' });
        }
      }

      setUser({ ...user, [name]: value });
    }
  };

  const clickToUpload = () => {
    return document?.getElementById('file-input-id-e')?.click();
  };

  const returnPlaceholder = (elem: any) => {
    return adminUser?.[elem.name].toString() || '';
  };

  const isAlwaysDisabled = (str: string) => {
    return str === `account_id` || str === `_id` ? true : false;
  };

  const returnValue = (elem: any) => {
    return elem.name === '__v'
      ? user?.[elem.name as keyof typeof user].toString()
      : user?.[elem.name as keyof typeof user] || '';
  };

  useEffect(() => {
    if (profileDataFieldsDisabled) {
      returnValue('');
    }
  }, [profileDataFieldsDisabled]);

  useEffect(() => {
    if (adminUserClicked && Object.keys(adminUserClicked).length > 0) {
      //redirected from another profile...
      fetchAdminData(adminUserClicked.account_id);
    } else {
      //try to set as editable user the adminUser
      if (adminUser) {
        setUser(adminUser);

        fetchAdminData(adminUser.account_id);
      }
    }
    return () => {};
  }, [adminUser, adminUserClicked]);

  useEffect(() => {
    const persistentAdm: any = localStorage.getItem('_adminUser');

    let parsedAdm;
    if (persistentAdm) {
      parsedAdm = JSON.parse(persistentAdm);
      storeClickedUser(parsedAdm);
    }
  }, []);

  useEffect(() => {
    return () => {
      resetFields();
    };
  }, []);

  return (
    <Template
      id="user-details-adm"
      title={config.strings.title}
      goBack={handleGoBack}
    >
      <BreadcrumbsItem to={config?.strings?.breadcrumbItem}>
        {config?.strings?.title}
      </BreadcrumbsItem>

      <div id="container user-admin-details">
        <DetailsGrid
          gridData={config.gridData}
          user={adminUser}
          gridContent={gridContent}
          dataLoaded={
            adminUser && Object.keys(adminUser).length > 0 ? true : false
          }
        />

        <div className="row flex-md-row profile-cnt">
          <div className={`col-xs-12 col-sm-12 col-md-6 prof-side`}>
            <div className={`row`}>
              <div className="col-12">
                <div className="d-flex justify-content-center">
                  <div className={'row d-flex justify-content-center'}>
                    <div className="row upload-01" id="admin-page-detailed-id">
                      <input
                        type="file"
                        id="file-input-id-e"
                        name="avatar"
                        accept="image/png, image/jpeg"
                      />
                      <div className="col-5"> </div>
                      <div className="col-2 btn-cont-up">
                        <button
                          className="btn btn-sm  upload-btn btn-up-small-button btn-up-ok"
                          onClick={clickToUpload}
                          value="Choose file"
                        >
                          <RiImageEditLine className="edit-img" />
                        </button>
                      </div>
                    </div>

                    <div className={`circle3`}>
                      <span className="avatar-letter">
                        {adminUser && adminUser?.email[0].toUpperCase()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`container buttons-cont`}
                id="buttons-container--adm"
              >
                <div className="row">
                  <div className="col-12 mt-2 pdg">
                    <Button
                      className={`btn small-button2 ok2 ${`br-user-styles`}`}
                      onClick={toggleEdit}
                    >
                      <span className="edit-btn">
                        <RiEdit2Line className="edit" /> EDIT PROFILE{' '}
                      </span>
                    </Button>

                    {!profileDataFieldsDisabled && (
                      <>
                        {errors?.emailError.trim().length === 0 &&
                          errors?.usernameError.trim().length === 0 && (
                            <Button
                              dataToggle="modal"
                              dataTarget="#save-admin--user-updated--fields"
                              type={`button`}
                              className={`btn small-button2 neutral ${`br-user-styles`}`}
                              onClick={triggerConfirmation}
                            >
                              <span className="save-btn">
                                <RiSave3Fill className="edit" /> SAVE CHANGES{' '}
                              </span>
                            </Button>
                          )}

                        <Button
                          className={`btn small-button2 nok2 ${`br-user-styles`}`}
                          onClick={cancelChanges}
                        >
                          <span className="cancel-btn">
                            <HiOutlineXCircle className="cancel" /> CANCEL
                            CHANGES
                          </span>
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>

              {config.formDataAdmin
                .slice(0, 1)
                .map((elem: any, index: number) => {
                  if (elem.type === 'switch') {
                    return (
                      <div
                        key={index}
                        className={`row ${`switch--section-${profileDataFieldsDisabled}`}`}
                      >
                        <div className="col-auto pdg label--side">
                          <label className={`input-label2`}>{elem.label}</label>
                        </div>
                        <div className="col-auto pdg">
                          <SwitchBtn
                            id={`status-id-adm-01`}
                            value={onOffState}
                            onChange={handleEdit}
                            name={elem.name}
                            className=""
                          />
                        </div>

                        <div
                          className={`col pdg centered--1 ${
                            returnPlaceholder(elem) === 'active'
                              ? `gr-01`
                              : `rd-01`
                          }`}
                        >
                          <span className="badge badge-pill badge-info">
                            Currently {onOffState ? ` active` : ` inactive`}
                          </span>
                        </div>
                      </div>
                    );
                  }

                  return (
                    <div className="row input--fields pdg" key={index}>
                      <label className={`input-label`}>{elem.label}</label>
                      <Input
                        type={elem.type}
                        className={elem.classname}
                        id={elem.id}
                        name={elem.name}
                        disabled={
                          isAlwaysDisabled(elem.name)
                            ? true
                            : profileDataFieldsDisabled
                        }
                        value={returnValue(elem)}
                        onChange={handleEdit}
                        placeholder=""
                      />

                      {!isAlwaysDisabled(elem.name) && (
                        <div className="render-error">
                          {
                            errorRetrieve[
                              elem.name as keyof typeof errorRetrieve
                            ]
                          }
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>{' '}
          </div>

          <div className={`col-xs-12 col-sm-12 col-md-6 prof-side2`}>
            {config.formDataAdmin.slice(1).map((elem: any, index: number) => {
              if (elem.type === 'switch') {
                return (
                  <div
                    key={index}
                    className={`row ${`switch--section-${profileDataFieldsDisabled}`}`}
                  >
                    <div className="col-auto pdg label--side">
                      <label className={`input-label2`}>{elem.label}</label>
                    </div>
                    <div className="col-auto pdg">
                      <SwitchBtn
                        id={`status-id-adm-01`}
                        value={onOffState}
                        onChange={handleEdit}
                        name={elem.name}
                        className=""
                      />
                    </div>

                    <div
                      className={`col pdg centered--1 ${
                        returnPlaceholder(elem) === 'active' ? `gr-01` : `rd-01`
                      }`}
                    >
                      <span className="badge badge-pill badge-info">
                        Currently {onOffState ? ` active` : ` inactive`}
                      </span>
                    </div>
                  </div>
                );
              }

              return (
                <div className="row input--fields" key={index}>
                  <label className={`input-label`}>{elem.label}</label>
                  <Input
                    type={elem.type}
                    className={elem.classname}
                    id={elem.id}
                    name={elem.name}
                    disabled={
                      isAlwaysDisabled(elem.name)
                        ? true
                        : profileDataFieldsDisabled
                    }
                    value={returnValue(elem)}
                    onChange={handleEdit}
                    placeholder=""
                  />

                  {!isAlwaysDisabled(elem.name) && (
                    <div className="render-error">
                      {errorRetrieve[elem.name as keyof typeof errorRetrieve]}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <Modal
        config={config}
        id={`save-admin--user-updated--fields`}
        title={config.modalStrings.edit}
        icon={<RiEdit2Line className="edit" />}
        body={config?.modalStrings?.aboutToUpdate}
        label={config?.modalStrings?.labelOK}
        className={`small-button ok`}
        bodyClass={`alert alert-info`}
        sureToProceed={config?.modalStrings?.sureToProceed}
        hasConfirmBtn={true}
        warning={config?.modalStrings?.noUndo}
        onClickCancel={cancelChanges}
        onClick={() => {
          /*Todo: this will confirm user info edition */
        }}
        //handle modal automatic closing when
        //there is a real update going on:
        /* modalMustClose={modalMustClose}
           handleModalState={handleModalState}*/
        modalMustClose={false}
        handleModalState={() => {}}
      />
    </Template>
  );
};

function mapStateToProps(state: any) {
  const { adminState, adminUsersState } = state;
  return {
    userData: adminUsersState.userData,
    adminUserClicked: adminUsersState.adminUserClicked,
    adminUser: adminState.adminUser,
    profileDataFieldsDisabled: adminUsersState.profileDataFieldsDisabled,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { loader, adminState, adminUsersState } = dispatch;
  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    editUserProfile: adminUsersState.editUserProfile,
    getDetailsAboutAdmin: adminUsersState.getDetailsAboutAdmin,
    storeClickedUser: adminState.storeClickedUser,
    updateDisabledState: adminUsersState.cancelUserProfileUpdates,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewAdmin);
