export default {
  strings: [
    {
      id: `description`,
      title: `Delete your AirCycler account`,
      section: `You can delete your account at any time. If you change your mind,
      you might not be able to recover it. Below are the instructions about how to delete your account
      and what you should be aware of when you decide to perform that action.`,
    },
    {
      id: `implications`,
      title: `What does it mean to delete your account`,
      section: `You’ll lose all the personal data related to your account.
      If you decide to use AirCycler app again, you will have to create a new account.`,
    },
    {
      id: `data`,
      title: `Which data is kept and which data is fully deleted`,
      section: `All your personal data is deleted. However, we keep
      the data related to the devices that are registered on your account.`,
    },
    {
      id: `time`,
      title: `How much time does it take?`,
      section: `Once your delete account request is validated, we will
      handle your request, which may take up to 48 hours after that validation.`,
    },
    {
      id: `how-to-delete`,
      title: `How can you delete your account?`,
      section: `You have at your disposal two ways to ask for account deletion:`,
      li1: `You may request it through the AirCycler app menu, on the right corner,
      clicking on the Delete account option. You must be logged in to do so. This option will lead you to the
      deletion request page. Once you are there, please just follow the instructions - you will have to
      provide an e-mail address in order to receive the request link.`,
      li2: `You may also request the account deletion sending an e-mail to: support@aircycler.com`,
    },
  ],
  questionsTitle: `Any questions?`,
  questionsBody: `Please, contact us to support@aircycler.com`,
};
