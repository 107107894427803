import React from 'react';
import { WarningProps } from './interface';

const Warning: React.FC<WarningProps> = ({
  currentlyEmptyFieldsEdition,
  warning,
  id,
}) => {
  return (
    <div className="container side--padding--style1" id={id}>
      <div className="row d-flex justify-content-center">
        <div className="col-xs-12 col-sm-12 col-md-5 warning--col">
          {currentlyEmptyFieldsEdition && (
            <div className="alert alert-info" role="alert">
              {warning}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Warning;
