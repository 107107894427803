import React, { Fragment } from 'react';
import config from './config';

const validateIfObject = (element: any) => {
  if (
    typeof element === 'object' &&
    !Array.isArray(element) &&
    element !== null
  ) {
    for (const [key, value] of Object.entries(element)) {
      return { key: value };
    }

    return true;
  } else {
    return false;
  }
};

const returnLabel = (elem: string) => {
  if (elem.includes('_')) {
    return config.dictionary[elem as keyof typeof config.dictionary];
  } else {
    let label: any = [];
    let lbl;
    let lower = elem?.match(/[a-z]+|[0-9]+/g);
    let upper = elem?.match(/[A-Z][a-z]+|[0-9]+/g);

    if (lower) {
      label.push(lower[0]);
    }
    if (upper) {
      label.push(upper.join(' '));
    }
    lbl = label.join(' ').toLowerCase();

    return lbl.charAt(0).toUpperCase() + lbl.slice(1);
  }
};

const capitalizeFirstChar = (str: string) => {
  let first = str[0].toUpperCase();
  let rest = str.slice(1);
  return first.concat(rest);
};

const returnDesignation = (acronym: string) => {
  if (config.dictionary && config.dictionary.hasOwnProperty(acronym)) {
    return config.dictionary[acronym as keyof typeof config.dictionary];
  } else {
    return acronym;
  }
};

const filterRelevantProps = (sensorObject: any) => {
  let filteredObject: any = {};
  if (Object.keys(sensorObject).length > 0) {
    let filteredPropsArray = Object.entries(sensorObject).filter(
      (item: any, val: any) => {
        return (
          item[0] !== 'api_key' &&
          item[0] !== '_id' &&
          item[0] !== '__v' &&
          item[0] !== 'id'
        );
      },
    );
    filteredPropsArray.forEach((item: any) => {
      filteredObject[item[0]] = item[1];
    });
  }
  return filteredObject;
};

const handleObj = (obj: any) => {
  return Object.entries(obj).map(([key, val]: [any, any], idx: number) => {
    if (key === 'conf') return null;

    return (
      <div className="container pdg" key={idx}>
        <div className="row">
          <div className="col-3 pdg">
            <span className="data--devs-12 lead stronger">
              {' '}
              {returnDesignation(key)}
            </span>
          </div>
          {(key === 'settings' || key === 'conf') && (
            <div className="row"> </div>
          )}

          <div className="col pdg to-the-right br-w">
            {Array.isArray(val) ? (
              val.map((elem: any, i: number) => {
                return Array.isArray(elem) ? (
                  <div className="row ml-r" key={i}>
                    {elem.map((item: any, ind: number) => {
                      return (
                        <div
                          key={ind}
                          style={{
                            wordBreak: 'break-all',
                            paddingLeft: '0px',
                            paddingRight: '0px',
                          }}
                        >{`${item}, `}</div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="row ml-r" key={i}>{`${elem}, `}</div>
                );
              })
            ) : validateIfObject(val) ? (
              Object.entries(val).map(
                ([key, val]: [any, any], index: number) => {
                  return (
                    <Fragment key={index}>
                      {Array.isArray(val) ? (
                        <>{key} </>
                      ) : (
                        <>
                          {returnDesignation(key)} {!val ? 'No values' : val}{' '}
                          <br />
                        </>
                      )}
                    </Fragment>
                  );
                },
              )
            ) : (
              <Fragment key={idx}>{!val ? 'No values' : val}</Fragment>
            )}
          </div>
        </div>
      </div>
    );
  });
};

const renderVal = (v: any) => {
  if (typeof v === 'string') {
    return (
      <div className="container pdg">
        <div className="row">
          <div className="col pdg to-the-right">{v}</div>
        </div>
      </div>
    );
  } else if (validateIfObject(v)) {
    return Object.entries(v).map(([key, val]: [any, any], idx: number) => {
      return (
        <div className="container pdg" key={idx}>
          <div className="row">
            <div className="col-3 pdg">
              {key === 'location' || key === 'data' || key === 'settings' ? (
                <span className={`devs-label-spec`}>
                  {capitalizeFirstChar(key)}
                </span>
              ) : (
                <span className={`data--devs-12 lead stronger`}>
                  {capitalizeFirstChar(key)}
                </span>
              )}
            </div>
            {(key === 'location' || key === 'data' || key === 'settings') && (
              <div className="row"> </div>
            )}

            <div className="col pdg to-the-right">
              {val && typeof val === 'string' ? (
                <div className="col-12 to-the-right pdg">{val}</div>
              ) : validateIfObject(val) ? (
                <div className="col-12 pdg">{handleObj(val)}</div>
              ) : (
                <div className="col-12 pdg to-the-right">
                  {JSON.stringify(val)}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    });
  } else {
    return JSON.stringify(v);
  }
};

const renderValue = (value: any) => {
  if (!validateIfObject(value)) {
    return value.toString();
  }
};

export const helpers = {
  validateIfObject: validateIfObject,
  returnLabel: returnLabel,
  capitalizeFirstChar: capitalizeFirstChar,
  returnDesignation: returnDesignation,
  filterRelevantProps: filterRelevantProps,
  handleObj: handleObj,
  renderVal: renderVal,
  renderValue: renderValue,
};
