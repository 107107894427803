import React from 'react';
import { Grid, Switch, FormControlLabel } from '@material-ui/core';
import './styles.scss';


interface CustomSwitchProps {
  id?: string;
  label: string;
  value: any;
  onChange: any;
}

const CustomSwitch: React.FC<CustomSwitchProps> = ({
  label,
  value,
  onChange,
  ...props
}) => {

  return (
    <Grid item>
      <div className="periph-csw">
        <div className="periph-csw--values-wrapper">
          <label className="periph-csw--values-label">{label}</label>
        </div>
        <FormControlLabel
          control={<Switch checked={value} onChange={onChange} color="primary" />}
          label={value ? 'Connected' : 'Disconnected'}
        />
      </div>
    </Grid>
  );
};

export default CustomSwitch;
