import React from 'react';
import { BodyProps } from './interface';
import './styles.scss';

const Body: React.FC<BodyProps> = ({
  body,
  bodyClass,
  config,
  sureToProceed,
  warning,
}) => {
  return (
    <div className="modal-body">
      <div className="modal-box2">
        <h3 className="h3-modal">{config?.modalStrings?.warning}</h3>
        <div className={bodyClass} role="alert">
          {body}
        </div>

        <p className="lead stronger-ft">{warning}</p>
        <span className="lead">{sureToProceed} </span>
      </div>
    </div>
  );
};

export default Body;
