import {
  TempIcon,
  HumIcon,
  FlowIcon,
  FanIcon,
} from '../../../../../../../assets/sensors-icons';
import { WavesIcon } from '../../../../../../../assets/icons';
import numeral from 'numeral';

const MIN = 0;
const CURRENT = 1;
const MAX = 2;

const utils = {
  selectData: (id: string) => {
    switch (id) {
      case 'w-temp':
        return {
          _class: 'i-temp',
          left: {
            isIcon: true,
            item: TempIcon,
          },
          right: 'ºF',
        };
      case 'w-rhum':
        return {
          _class: 'i-hum',
          left: {
            isIcon: true,
            item: HumIcon,
          },
          right: 'RH',
        };
      case 'w-cfm':
        return {
          _class: 'i-cfm',
          left: {
            isIcon: true,
            item: WavesIcon,
          },
          right: 'CFM',
        };
      case 'w-pm25':
        return {
          _class: 'l-tvoc',
          left: {
            isIcon: false,
            item: 'PM 2.5',
          },
          right: 'ug/m3',
        };
      case 'w-co2':
        return {
          _class: 'l-co2',
          left: {
            isIcon: false,
            item: 'CO2',
          },
          right: 'ppm',
        };
      case 'w-tvoc':
        return {
          _class: 'l-tvoc',
          left: {
            isIcon: false,
            item: 'tVOC',
          },
          right: 'ppb',
        };
      case 'w-p-conn':
        return {
          _class: 'w-paConn',
          left: {
            isIcon: false,
            item: 'Pa',
          },
          right: '',
        };
      default:
        return;
    }
  },
  createLabel: (option: string, value: any) => {
    switch (option) {
      case 'w-temp':
        return `${numeral(value).format('0,0')}ºF`;
      case 'w-rhum':
        return `${value}%`;
      default:
        return numeral(value).format('0,0');
    }
  },
  handleExt: (value: number, option: string, defaults: any) => {
    const { max, min } = defaults;
    switch (option) {
      case 'max':
        return value !== null && value !== undefined && value !== -101
          ? value
          : max - 15;
      case 'min':
        return value !== null && value !== undefined && value !== -101
          ? value
          : min + 15;
      default:
        return value;
    }
  },
  mapNewValues: (values: any, defaults: any) => {
    let tempData: any = [...values];
    tempData = tempData.map((val: number, index: number) => {
      return index === 1
        ? val
        : utils.handleExt(val, index === 2 ? 'max' : 'min', defaults);
    });

    return [...tempData];
  },
};

export default utils;
