import React from 'react';
import { TimezoneProps } from './interface';
import { NativeSelect } from '@material-ui/core';
import './styles.scss';

const Timezone: React.FC<TimezoneProps> = ({
  value,
  onChange,
  children
}) => {

  return (
    <div className="row">
      <div className="col-12 edit-user-configs--main-wrapper">
        <div className="edit-user-configs--select-tmzn--wrapper">
          <label className="inputLabel">Timezone</label>
          <div className="edit-user-configs--select-tmzn">
            <NativeSelect className="select" value={value} onChange={onChange}>
              {children}
            </NativeSelect>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timezone;
