import React from 'react';
import { MdDelete } from 'react-icons/md';
import { MdOutlineCancelPresentation } from 'react-icons/md';

export default {
  strings: {
    title: 'Delete Requests List',
    noResults: 'No results found...',
    timeSlLabel: '',
    breadcrumbItemTo: `/admin/dashboard/delete-requests`,
    breadcrumbTitle: `Delete requests`,
    delReqs: 'Loading delete requests...',
  },
  modalStrings: {
    confirm: ``,
    warning: `Information`,
    alertStr: ``,
    alertStr2: ``,
    confirmation: `Are you sure you wish to proceed?`,
  },
  notifications: {
    delete: {
      success: 'The delete request has been deleted!',
      error: 'Something went wrong!',
    },
    cancel: {
      success: 'The delete request has been canceled!',
      error: 'Something went wrong!',
    },
  },
  tabs: [
    {
      id: 'all-requests',
      label: 'All requests',
      action: '',
      goToSection: '',
      active: true,
    },
    {
      id: 'await-confirmation',
      label: 'Awaiting confirmation',
      action: '',
      goToSection: '',
      active: false,
    },

    {
      id: 'confirmed-reqs',
      label: 'Confirmed by user',
      action: '',
      goToSection: '',
      active: false,
    },
    {
      id: 'deleted-reqs',
      label: 'Deleted requests',
      action: '',
      goToSection: '',
      active: false,
    },
  ],
  titles: {
    delete__req: `Delete request`,
    cancel__req: `Cancel request`,
  },
  icons: {
    delete__req: <MdDelete className="edit" />,
    cancel__req: <MdOutlineCancelPresentation className="edit" />,
  },
  bodies: {
    delete__req: `You are about to delete this request.`,
    cancel__req: `You are about to cancel this request.`,
  },
  labels: {
    delete__req: ` OK`,
    cancel__req: ` OK`,
  },
  bodyClass: {
    delete__req: `alert alert-info`,
    cancel__req: `alert alert-danger`,
  },
};
