import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { AwairConnection, AwairDisconnect } from '../';
import { Notification } from '../../../../../react-components';
import config from './config';

const awair_path = '/awair';


interface AwairHomeProps {
  isUserConnected: any;
  loading: any;
  updateAwairState: any;
  connect: any;
  location: any;
}

const AwairHome: React.FC<AwairHomeProps> = ({ loading, ...props }) => {
  const history = useHistory();
  const [path, setPath]: any = useState(
    props.isUserConnected ? '/dashboard/awair/disconnect' : '/dashboard/awair/connection'
  );



  const handleAwairConnection = async (data: any) => {
    loading.start('Connecting with Awair...');
    let showNotification: boolean = false;
    const urlParams: any = data?.search ? new URLSearchParams(data.search) : null;

    if(urlParams !== null && urlParams !== undefined){
      const code: any = urlParams.get('code');

      if(code !== null && code !== undefined){
        let result: any = await props.connect({ code });

        if(result.statusCode === 200){
          history.push('/dashboard/awair');
        }
      }else{
        showNotification = true;
      }
    }

    loading.stop();

    if(showNotification){
      Notification({
        title: 'Attention',
        message: config?.notifications?.connect?.warning,
        type: 'warn',
      });
    }
  };


  useEffect(() => {
    loading.start();
    setPath(
      props?.isUserConnected
        ? '/dashboard/awair/disconnect'
        : '/dashboard/awair/connection',
    );
    loading.stop();
  }, [props?.isUserConnected]);


  useEffect(() => {
    if(props?.location?.search?.includes('code')){
      handleAwairConnection(props.location);
    }
  }, [props?.location]);


  useEffect(() => {
    const isAwairConnecting: any = localStorage.getItem('isAwairConnecting');

    if(isAwairConnecting === 'true' || isAwairConnecting === true){
      localStorage.removeItem('isAwairConnecting');
    }else{
      props.updateAwairState();
    }
  }, []);


  return (
    <>
      <Route exact path={`/dashboard/awair`}>
        {path && <Redirect to={path} />}
      </Route>
    </>
  );
};

function mapStateToProps(state: any) {
  const { awair } = state;
  return {
    isUserConnected: awair.isConnected,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { loader, awair } = dispatch;
  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    updateAwairState: awair.updateAwairState,
    connect: awair.connect,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AwairHome);
