export const timezone = [
  'Newfoundland Time w/ Daylight Savings Time',
  'Atlantic Time w/ Daylight Savings Time',
  'Eastern Standard w/ Daylight Savings Time',
  'Central Standard w/ Daylight Savings Time',
  'Mountain Standard w/ Daylight Savings Time',
  'Pacific Standard w/ Daylight Savings Time',
  'Alaska Standard w/ Daylight Savings Time',
  'Hawaii Standard w/ Daylight Savings Time',
];

export const timezone2 = [
  'Newfoundland w/ Daylight Sav. Time',
  'Atlantic w/ Daylight Sav. Time',
  'East. Std w/ Daylight Sav. Time',
  'Central Std w/ Daylight Sav. Time',
  'Mountain Std w/ Daylight Sav. Time',
  'Pacific Std w/ Daylight Sav. Time',
  'Alaska Std w/ Daylight Sav. Time',
  'Hawaii Std w/ Daylight Sav. Time',
];


export const timezone3 = [{
    label: 'Newfoundland Time w/ Daylight Sav. Time',
    timezone: 'Canada/Newfoundland',
  },
  {
    label: 'Atlantic Time w/ Daylight Sav. Time',
    timezone: 'Canada/Atlantic',
  },
  {
    label: 'Eastern Std. w/ Daylight Sav. Time',
    timezone: 'Canada/Eastern',
  },
  {
    label: 'Central Std. w/ Daylight Sav. Time',
    timezone: 'US/Central',
  },
  {
    label: 'Mountain Std. w/ Daylight Sav. Time',
    timezone: 'US/Mountain',
  },
  { label: 'Mountain Std. Time', timezone: 'US/Mountain' },
  {
    label: 'Pacific Std. w/ Daylight Sav. Time',
    timezone: 'US/Pacific',
  },
  {
    label: 'Alaska Std. w/ Daylight Sav. Time',
    timezone: 'US/Alaska',
  },
  {
    label: 'Hawaii Std. w/ Daylight Sav. Time',
    timezone: 'US/Hawaii',
  },
]


export const setup = ['Calculated Flow', 'Calculated Time', 'Balance Mode'];


export const inputTypes: any = {
   'systemName' : 'text',
   'systemLocationState': 'text',
   'systemLocationCity':  'text',
   'systemLocationZipCode'  : 'text',
   'flowMeasuredHeatCall' : 'number',
   'flowMeasuredCoolingCall': 'number',
   'flowMeasuredFanOnlyCall': 'number',
   'flowContinuousAirFlow': 'number',
   'exhaustFanFlowRate': 'number',
   'additionalMixTime': 'number',
   'hoursOfOperation':  'number',
   'hourStart':  'number',
   'hourStop':  'number',
   'currentTime': 'number',
   'exhaustFanDelayTime' :  'number',    
   'minutesPerHourExhaustRun' : 'number',
   'minutesPerHourSupplyFlow' : 'number',
   'requiredContinuousAirFlow': 'number'
}


export const options = [
  { label: 'No', value: 0 },
  { label: 'Yes', value: 1 },
];



export const possibleErrors: any = { 
  'exhaustFanDelayTime': 'exhaustFanDelayTime',
  'minutesPerHourSupplyFlow':  'minutesPerHourSupplyFlow',
  'minutesPerHourExhaustRun': 'minutesPerHourExhaustRun', 
  'flowMeasuredHeatCall': 'flowMeasuredHeatCall', 
  'flowMeasuredCoolingCall': 'flowMeasuredCoolingCall', 
  'flowMeasuredFanOnlyCall': 'flowMeasuredFanOnlyCall',
  'requiredContinuousAirFlow': 'requiredContinuousAirFlow',  
  'exhaustFanFlowRate': 'exhaustFanFlowRate'
}
