import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import moment from 'moment';
import _ from 'lodash';
import { UserTemplate } from 'business/modules/common';
import {
  SensorsList,
  ActionsMenu,
  EmptyList,
  ListHeader,
} from './components';
import config from './config';
import {
  filterSensorDevices,
  filterG3Devices,
  hasLocalSensors,
} from './helper';
import './styles.scss';


const WIFI_CONN_DIFF: number = 30; // in seconds
let LOCAL_SENSORS: any = {};

interface LoadingProps {
  start: any;
  stop: any;
}

interface AllDevicesProps {
  devices?: any;
  user?: any;
  hasFoobotConnection: any;
  hasAirthingsConnection: any;
  hasAwairConnection: any;
  loading: LoadingProps;
  setCurrentDevice?: any;
  getDevices?: any;
  getDeviceLogs?: any;
  delete: any;
  refreshDevicesList: Function;
  updateLocalSensorState: Function;
  updateLocalSensor: Function;
  requestData: Function;
}

const AllDevices: React.FC<AllDevicesProps> = ({ devices, ...props }) => {
  const history = useHistory();
  let intervals: any = [];
  const [upDevices, setUpDevices]: any = useState(filterSensorDevices([...devices]));
  const [deviceToDelete, setDeviceToDelete]: any = useState(null);
  const [showDeleteAlert, setShowDeleteAlert]: any = useState(false);
  const [listConfig, setListConfig]: any = useState({
    text: ['device_name', 'system'],
    status: { prop: 'isWifiConn', activeState: 'active' },
  });
  const [g3Devices, setG3Devices]: any = useState([]);
  const [localSensors, setLocalSensors]: any = useState({});
  const [localSensorCheck, setLocalSensorCheck]: any = useState(false);



  const loadDevices = async () => {
    props.loading.start();
    await props.getDevices();
    props.loading.stop();
  };

  const goToDeviceDetails = (device: any) => {
    defineDetailsLocal(device);
    props.setCurrentDevice({ ...device });
    history.push(`/dashboard/devices/details?id=${device?.device_id}`, { type: 'system' });
  };

  const onEditDevice = (device: any, event: any, e: any) => {
    props.setCurrentDevice({ ...device });
    history.push(`/dashboard/devices/edit?id=${device?.device_id}`, { type: 'system' });
    event();
  };

  const onDeviceClick = (rowItem: any, data: any) => {
    goToDeviceDetails(rowItem);
  };

  const onDeleteDevice = (device: any, event: any, e: any) => {
    setDeviceToDelete({ ...device });
    setShowDeleteAlert(true);
    event();
  };

  const onSettingsDevice = (device: any, event: any, e: any) => {
    goToDeviceDetails(device);
    event();
  };

  const resetIntervals = () => {
    if (intervals && intervals.length > 0) {
      intervals.forEach((ival: any) => {
        clearInterval(ival);
      });
    }
  };


  const defineDetailsLocal = (device: any) => {
    if(
      device?.details &&
      (device?.details?.name === 'LOCAL' || device?.details?.status?.includes('local'))
    ){
      const dataClone: any = device?.device_id && localSensors.hasOwnProperty(device.device_id)
        ? _.cloneDeep(localSensors[device.device_id])
        : null;

      if(dataClone){
        props.updateLocalSensor(dataClone);
      }
    }
  };

  const onUpdateListDevices = async (event: any) => {
    const { account_id, details } = props.user;
    const { thirdParty }: any = details || {};
    const { foobot }: any = thirdParty || {};
    props.loading.start('Updating list...');
    await props.refreshDevicesList({ account_id, username: foobot?.username });
    props.loading.stop();
  };

  const hasValidDate = (item: any) => {
    const { createdAt } = item;
    const nowDate: any = moment();
    const devDate: any = moment(new Date(createdAt));
    return nowDate.diff(devDate, 'seconds') < WIFI_CONN_DIFF;
  };

  const isDeviceConnected = async (_device: any) => {
    const { api_key } = _device;
    const resp: any = await props.getDeviceLogs({ deviceKey: api_key });
    return resp && resp?.Count === 0
      ? false
      : resp?.Items?.length > 0
      ? hasValidDate(resp.Items[0])
      : false;
  };

  const deleteDevice = async () => {
    setShowDeleteAlert(false);
    let { device_id } = deviceToDelete;
    if (device_id) {
      props.loading.start('Deleting Device');
      await props.delete({ device_id });
      props.loading.stop();
    }
  };

  const checkAwairState = (localSensor: any, target: any) => {
    let targetClone: any = _.cloneDeep(target);
    targetClone.isWifiConn = 'active';

    if(localSensor){
      const { system }: any = localSensor || {};

      if(!system){
        targetClone.isWifiConn = 'inactive';
      }
    }
    return targetClone;
  };


  const updateDevicesStates = async (_devices: any) => {
    for (let index = 0; index < _devices.length; index++) {
      const {
        device_type,
        device_name,
        device_id,
        details,
        status,
      }: any = _devices[index] || {};

      switch (device_type) {
        case 'foobot':
        case 'airthings':
        case 'awair':
        case 'awair-element':
          _devices[index].isWifiConn =
          status !== null && status !== undefined
            ? status === 'active'
              ? 'active'
              : 'inactive'
            : 'active';
          break;
        case 'awair-omni':
          if(LOCAL_SENSORS.hasOwnProperty(device_id)){
            _devices[index] = checkAwairState(
              LOCAL_SENSORS[device_id],
              _devices[index]
            );
          }else{
            _devices[index].isWifiConn =
              status !== null && status !== undefined
                ? status === 'active'
                  ? 'active'
                  : 'inactive'
                : 'active';
          }
          break;
        case 'aircycler':
          let isConn: boolean = await isDeviceConnected(_devices[index]);
          _devices[index].isWifiConn = isConn ? 'active' : 'inactive';
          break;
        default:
          _devices[index].isWifiConn = 'inactive';
          break;
      }

      if(!device_name || device_name === ''){
        _devices[index].device_name = 'NO NAME SENSOR';
      }

    }
    return _devices;
  };


  const startStateListener = async (_devices: any) => {
    let updatedDevices: any = [..._devices];
    let tempInterval: any = setInterval(async () => {
      updatedDevices = await updateDevicesStates(
        filterSensorDevices([...updatedDevices]),
      );
      setUpDevices(filterSensorDevices(updatedDevices));
    }, 15 * 1000);
    intervals.push(tempInterval);
  };


  const checkConnections = (data: any) => {
    const { hasAirthingsConnection, hasFoobotConnection, hasAwairConnection } = data;
    let hasConnection: boolean = false;

    if(hasAirthingsConnection || hasFoobotConnection || hasAwairConnection){
      hasConnection = true;
    }

    return hasConnection;
  };


  const handleLocalSensors = (sensors: any, systems: any) => {
    if(sensors && sensors.length > 0){
      let localSensorData: any = {};
      const _localSensors: any = sensors.filter((sensor: any) => {
        const { details }: any = sensor || {};
        if(details){
          return details?.name === 'LOCAL' || details?.status?.includes('local');
        }
        return;
      });

      if(_localSensors && _localSensors.length > 0){
        _localSensors.forEach((localSensor: any) => {
          const { device_id, details }: any = localSensor || {};
          const attachedSystem: any = systems.find((system: any) => {
            const localLabel: string = system?.details?.settings?.conf?.lsnm
              ? `${system.details.settings.conf.lsnm}-local`
              : '';
            return localLabel === details.uuid;
          });

          localSensorData[device_id] = {
            system: attachedSystem !== null && attachedSystem !== undefined ? attachedSystem : null,
            sensor: localSensor
          };
        });

        setLocalSensors(_.cloneDeep(localSensorData));
      }
    }
  };





  useEffect(() => {
    LOCAL_SENSORS = _.cloneDeep(localSensors);

    if(localSensors && Object.keys(localSensors).length > 0){
      let sensorsListClone: any = _.cloneDeep(upDevices);

      Object.keys(localSensors).forEach((itemKey: string) => {
        const sensorClone: any = _.cloneDeep(localSensors[itemKey]);
        const sensorIndex: number = sensorsListClone.findIndex(
          ({ device_id }: any) => device_id === itemKey
        );

        if(sensorIndex > -1){
          sensorsListClone[sensorIndex] = checkAwairState(
            sensorClone, sensorsListClone[sensorIndex]
          );
        }
      });

      setUpDevices(sensorsListClone);
    }
  }, [localSensors]);


  useEffect(() => {
    if(
      upDevices.length > 0 &&
      g3Devices.length > 0 &&
      !localSensorCheck
    ){
      handleLocalSensors(upDevices, g3Devices);
      setLocalSensorCheck(true);
    }
  }, [upDevices, g3Devices]);


  useEffect(() => {
    if (devices && devices.length > 0) {
      let updatedDevices: any = [...devices];

      (async () => {
        updatedDevices = await updateDevicesStates([...updatedDevices]);
        setUpDevices(filterSensorDevices(updatedDevices));
        setG3Devices(filterG3Devices(updatedDevices));
      })();

      startStateListener([...devices]);
    } else {
      setUpDevices([]);
      resetIntervals();
    }

    return () => {
      resetIntervals();
      setLocalSensors({});
      setLocalSensorCheck(false);
    };
  }, [devices]);


  useEffect(() => {
    if(upDevices && upDevices.length > 0){
      const _hasLocalSensors: any = hasLocalSensors(upDevices);
      props.updateLocalSensorState(
        _hasLocalSensors,
        'hasLocal'
      );
    }
  }, [upDevices]);


  useEffect(() => {
    loadDevices();
  }, []);

  return (
    <UserTemplate id="all-devices" title={config?.strings?.title}>
      {upDevices && upDevices.length > 0 ? (
        <>
          <SensorsList
            name="all-devices"
            items={upDevices || []}
            onRowClick={onDeviceClick}
            config={listConfig}
            localSensorData={localSensors}
            status={true}
            rowType="device"
            HeaderComponent={(_props: any) => {
              return (
                <>
                  {checkConnections(props) ? <ListHeader
                    {...props}
                    onClick={onUpdateListDevices}
                  /> : <></>}
                </>
              );
            }}
            Component={(props: any) => {
              return (
                <ActionsMenu
                  {...props}
                  onDelete={onDeleteDevice}
                  onEdit={onEditDevice}
                  onSettings={onSettingsDevice}
                />
              );
            }}
          ></SensorsList>
        </>
      ) : (
        <EmptyList title="devices" />
      )}
      <SweetAlert
        danger
        show={showDeleteAlert}
        showCancel
        confirmBtnText={config?.alerts?.delete?.btn?.confirm}
        confirmBtnBsStyle="danger"
        title={`${config?.alerts?.delete?.question} "${deviceToDelete?.device_name}"?`}
        onConfirm={deleteDevice}
        onCancel={() => setShowDeleteAlert(false)}
        focusCancelBtn
      >
        {config?.alerts?.delete?.msm}
      </SweetAlert>
    </UserTemplate>
  );
};

function mapStateToProps(state: any) {
  const { devices, profile, foobot, airthings, awair } = state;

  return {
    devices: devices.devices,
    user: profile.user,
    hasFoobotConnection: foobot.isConnected,
    hasAirthingsConnection: airthings.isConnected,
    hasAwairConnection: awair.isConnected,
  };
}

function mapDispatchToProps(dispatch: any) {
  const {
    loader,
    devices,
    logs,
    foobot,
    airthings,
    awair,
  } = dispatch;

  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    getDevices: devices.getAllDevices,
    delete: devices.deleteDevice,
    setCurrentDevice: devices.setCurrentDevice,
    getDeviceLogs: logs.getDeviceLogs,
    refreshDevicesList: foobot.refreshDevicesList,
    updateLocalSensorState: awair.updateState,
    updateLocalSensor: awair.updateLocalSensor,
    requestData: awair.getLastestValues,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AllDevices);
