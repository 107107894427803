import React, { Fragment } from 'react';
import config from './config';
import { NoDataProps } from './interface';
import { helpers } from './helpers';
import { Button } from 'business/modules/admin/common';
import { MdArrowBackIosNew } from 'react-icons/md';
import { GoRocket } from 'react-icons/go';
import './styles.scss';

const NoData: React.FC<NoDataProps> = ({
  sectionName,
  changeTab,
  currentUser,
  editDetailsByAdmin,
}) => {
  const p: any = {
    dev: `${currentUser.first_name} s account does not have devices.`,
    sens: `${currentUser.first_name} has not enabled Sensors settings.`,
    sys: `${currentUser.first_name} account does not have system settings.`,
    oa: config.strings.genericStrs.nodevs,
    ia: config.strings.genericStrs.nodevs,
    pc: config.strings.genericStrs.nodevs,
    mc: config.strings.genericStrs.nodevs,
  };

  const cnt: any = {
    dev: `Update Device settings for ${currentUser.first_name} account.`,
    sens: `Update Sensor settings for ${currentUser.first_name} account.`,
    sys: `Add a system to ${currentUser.first_name}'s account.`,
    oa: `${config.strings.genericStrs.defineSetts} Outside Air ${config.strings.genericStrs.cont}`,
    ia: `${config.strings.genericStrs.defineSetts} Indoor Air ${config.strings.genericStrs.cont}`,
    pc: `${config.strings.genericStrs.defineSetts} Pressure Connect ${config.strings.genericStrs.cont}`,
    mc: `${config.strings.genericStrs.defineSetts} Manual Control ${config.strings.genericStrs.cont}`,
  };

  const handleRedirect = (section: string) => {
    switch (section) {
      case 'dev':
      case 'sens':
      case 'sys':
        return (
          <Fragment>
            <Button
              className="btn btn-sm no-databtn"
              onClick={() => {
                editDetailsByAdmin(origin);
              }}
            >
              <span className="lead back-span no-databtn">
                <GoRocket className="rocket-icon" /> {config.strings.dev.start}
              </span>
            </Button>
          </Fragment>
        );
      case 'oa':
      case 'ia':
      case 'pc':
      case 'mc':
        return (
          <div className="alert no-devs-screen-alert" role="alert">
            <p className="alert-paragraph">
              <button
                className="btn btn-sm btn-alert"
                onClick={() => {
                  changeTab(`dev`);
                }}
              >
                <MdArrowBackIosNew />
              </button>
              <span className="str-section">
                {config.strings.dev.goDevSection}
              </span>
            </p>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="jumbotron no-devices-screen">
      <h4 className="">{helpers.returnLabel(sectionName)}</h4>
      <p className="lead content-p">{p[sectionName]}</p>
      <hr className="section-divider" />
      <p className="lead content-p">{cnt[sectionName]}</p>

      {handleRedirect(sectionName)}
    </div>
  );
};
export default NoData;
