import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import validate from 'validate.js';
import _ from 'lodash';
import SweetAlert from 'react-bootstrap-sweetalert';
import { RiSave3Fill } from 'react-icons/ri';
import { NativeSelect } from '@material-ui/core';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { preparePayload } from './helpers';
import CommonSection from './components/CommonSection';
import { InstallerTemplate } from 'business/modules/common';
import {
  AddConfigurationProps,
  ConfigurationProps,
} from './helpers/interfaces';
import {
  CalculatedFlow,
  CalculatedTime,
  BalancedMode,
  InstallerIndoorAir,
  InstallerOutsideAir,
} from './components';
import { Button, Notification, Input } from 'react-components';
import config from './config';
import './styles.scss';



const DEFAULT_STRS: any = { ...config.strings };
const DEFAULT_ALERTS: any = { ...config.alerts };
const DEFAULT_NOTIFICATIONS: any = { ...config.notifications };
const G3_LABEL: string = config.defaults.g3CoreLabel || '';
const DEFAULT_TIMEZONES: any = [...config.timezones];
const VENTILATIONSELECT = [...config.ventilationSelect];
const DEP_PROPS: any = { ...config.defaults.dependentProps };
const TAGS_SETUP: any = { ...config.defaults.ventSetupTags };
const CONFIG_FIELDS: any = { ...config.fields };
const CONFIG_CONSTRAINTS: any = { ...config.constraints };

const AddConfiguration: React.FC<AddConfigurationProps> = ({
  loading,
  indoorAirPreparedSetup,
  outsideAirPreparedSetup,
  saveConfIdOABeingCreated,
  currentlyIDConfig,
  editConfiguration,
  ...props
}) => {
  const [newConfigData, setNewConfigData]: any = useState<ConfigurationProps>();
  const [fieldsState, setFieldsState]: any = useState({
    ...config?.fieldsState,
  });
  const [startCreate, setStartCreate]: any = useState(false);
  const [errors, setErrors]: any = useState({});
  const [showBtn, setShowBtn]: any = useState(false);
  const [isSubWidgetsCall, setIsSubWidgetsCall]: any = useState(false);
  let errorsLet: any = {};

  const validateInput = (fieldsStateValue: any, constraint = {}) => {
    let vlResult: any = validate(fieldsStateValue, constraint);

    if (vlResult) {
      errorsLet = { ...(errorsLet || {}), ...vlResult };
      setErrors(errorsLet);
      return;
    } else {
      const [key] = Object.keys(fieldsStateValue);

      if (errorsLet[key]) {
        errorsLet[key] && delete errorsLet[key];
        setErrors(errorsLet);
      }
    }
  };

  const onChangeState = (name: string, value: any) => {
    setFieldsState((prevState: any) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
    setShowBtn(true);
    const constraints: any = _.cloneDeep(CONFIG_CONSTRAINTS);
    validateInput({ [name]: value }, { [name]: constraints[name] });
  };

  const handleSelectVentilation = (event: any) => {
    let selectedVentilation: any = event?.target?.value;

    if (!selectedVentilation) return;

    setFieldsState((prevState: any) => {
      return {
        ...prevState,
        ventilation_setup: selectedVentilation,
      };
    });
    setShowBtn(true);
  };

  const customHandleChange = (prop: string, event: any, newValue: any) => {
    setFieldsState((prevState: any) => {
      return {
        ...prevState,
        [prop]: newValue,
      };
    });
    setShowBtn(true);
  };

  const handleSetTimezone = (event: any) => {
    let selectedTimezone = event?.target?.value;
    if (!selectedTimezone) return;

    const index: number = DEFAULT_TIMEZONES.findIndex(
      (_timezone: any) => _timezone.label === selectedTimezone,
    );

    setFieldsState((prevState: any) => {
      return {
        ...prevState,
        tmzn: index,
      };
    });
    setShowBtn(true);
  };

  const handleConfigValidation = async (isSubWidgets?: boolean) => {
    let vlResult: any = validate(fieldsState, CONFIG_CONSTRAINTS);
    setIsSubWidgetsCall(!!isSubWidgets);

    if (vlResult) {
      setErrors(vlResult);
      let msm: string = '';
      Object.keys(vlResult).forEach((key: string) => {
        msm += vlResult[key].map((err: any) => `${err}, `);
      });
      msm = msm.substring(0, msm.length - 2);

      Notification({
        title: 'Error',
        message: msm,
        type: 'error',
      });

      return vlResult;
    } else {
      setStartCreate(true);
    }
  };

  const onConfirmNewConfig = async () => {
    setStartCreate(false);
    loading.start('Creating New Configuration...');
    const fieldsCopy: any = _.cloneDeep(fieldsState);
    const payload: any = preparePayload(
      fieldsCopy,
      outsideAirPreparedSetup?.setup?.outsideAir,
      indoorAirPreparedSetup?.setup.indoorAir,
    );

    if (currentlyIDConfig) {
      let updCompletePayload: any = { data: payload, id: currentlyIDConfig };
      let result = await editConfiguration(updCompletePayload);

      if (result) {
        Notification({
          title: 'Success!',
          message: DEFAULT_NOTIFICATIONS?.update?.success,
          type: 'success',
        });
      }
    } else if (!currentlyIDConfig) {
      let result = await props.createNewConfig(payload);

      if (result) {
        saveConfIdOABeingCreated(result?.config_id);
        Notification({
          title: 'Success!',
          message: DEFAULT_NOTIFICATIONS?.update?.success,
          type: 'success',
        });
      }
    }

    if (!isSubWidgetsCall) setShowBtn(false);

    setTimeout(() => {
      loading.stop();
    }, 1000);
  };

  useEffect(() => {
    saveConfIdOABeingCreated('');

    return () => {
      saveConfIdOABeingCreated('');
      setIsSubWidgetsCall(false);
    };
  }, []);


  return (
    <InstallerTemplate
      id="installer-new-config"
      title={DEFAULT_STRS.title}
      titleBackButton
    >
      <BreadcrumbsItem to={`/installer/configurations/add`}>
        {DEFAULT_STRS.title}
      </BreadcrumbsItem>
      <div className="installer-add-config-wrapper">
        <div className="header-session">
          <h2>{newConfigData?.config_name || 'New Configuration'}</h2>
        </div>

        <div className="m-container installer-new-config-body">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-left">
              <Input
                placeholder={`Insert a name`}
                handleChange={onChangeState.bind(null, 'config_name')}
                handleChangeError={(err: any) => {
                  setErrors((prevState: any) => ({
                    ...prevState,
                    config_name: err,
                  }));
                }}
                type={CONFIG_FIELDS?.config_name?.type}
                label={CONFIG_FIELDS?.config_name?.label}
                labelStyles="system-name--label"
                value={fieldsState?.config_name}
                error={errors?.config_name}
                name={'config_name'}
              />
              <div className="" id="calculated-flow-st-select_1">
                <label className="inputLabel">
                  {CONFIG_FIELDS?.ventilation_setup?.label}
                </label>
                <div className="configuration--select">
                  <NativeSelect
                    className="select"
                    value={fieldsState?.ventilation_setup}
                    onChange={handleSelectVentilation}
                  >
                    {VENTILATIONSELECT.map((ventilation: any) => (
                      <option key={ventilation?.name} value={ventilation?.name}>
                        {ventilation?.name}
                      </option>
                    ))}
                  </NativeSelect>
                </div>
              </div>

              <CommonSection
                config={{ ...config }}
                fieldsState={fieldsState}
                errors={errors}
                handleSetTimezone={handleSetTimezone}
                onChangeState={onChangeState}
              />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-right">
              {fieldsState?.ventilation_setup === 'Calculated Flow' && (
                <CalculatedFlow
                  config={{ ...config }}
                  errors={errors}
                  fieldsState={fieldsState}
                  onChangeState={onChangeState}
                />
              )}
              {fieldsState?.ventilation_setup === 'Calculated Time' && (
                <CalculatedTime
                  config={config}
                  errors={errors}
                  fieldsState={fieldsState}
                  onChangeState={onChangeState}
                />
              )}
              {fieldsState?.ventilation_setup === 'Balanced Mode' && (
                <BalancedMode
                  config={config}
                  errors={errors}
                  fieldsState={fieldsState}
                  onChangeState={onChangeState}
                />
              )}
            </div>
          </div>
        </div>

        <div className="m-container">
          <div className="row">
            <div className="col-xs-12 col-sm-12">
              <InstallerOutsideAir
                loading={loading}
                oaConfig={config?.setupFields?.outsideAir}
                fieldsState={fieldsState}
                showBtnCtrl={setShowBtn}
                handleConfigValidation={handleConfigValidation}
              />
            </div>

            <div className="col-xs-12 col-sm-12 inst-add-indoor-air-container">
              <InstallerIndoorAir
                isMounted={true}
                loading={loading}
                fieldsState={fieldsState}
                showBtnCtrl={setShowBtn}
                handleConfigValidation={handleConfigValidation}
              />
            </div>
          </div>
        </div>
      </div>
      <SweetAlert
        info
        show={startCreate}
        showCancel
        confirmBtnText={DEFAULT_ALERTS?.create?.btn?.confirm}
        confirmBtnBsStyle="info"
        title={`${DEFAULT_ALERTS?.create?.question}`}
        onConfirm={() => {
          onConfirmNewConfig();
        }}
        onCancel={() => setStartCreate(false)}
        focusCancelBtn
      />
      {showBtn && (
        <div className="sticky">
          <Button
            variant="primary"
            className="btn save-configs-btn-purple"
            type="button"
            onClick={() => {
              handleConfigValidation();
            }}
            disabled={false}
          >
            <RiSave3Fill />
            <span className="save-span-btn">SAVE CONFIGURATION</span>
          </Button>
        </div>
      )}
    </InstallerTemplate>
  );
};

function mapStateToProps(state: any) {
  const { loader, installerConfigs }: any = state || {};
  return {
    isLoading: loader.loading,
    outsideAirPreparedSetup: installerConfigs.outsideAirPreparedSetup,
    indoorAirPreparedSetup: installerConfigs.indoorAirPreparedSetup,
    currentlyIDConfig: installerConfigs.currentlyIDConfig,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { loader, installerConfigs }: any = dispatch || {};

  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    createNewConfig: installerConfigs.createNewConfiguration,
    saveConfIdOABeingCreated: installerConfigs.saveConfIdOABeingCreated,
    editConfiguration: installerConfigs.editConfiguration,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddConfiguration);
