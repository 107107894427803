// @ts-nocheck
import React, { useState, useEffect } from 'react';
import config from './config';
import _ from 'lodash';
import { connect } from 'react-redux';
import { functions } from './helpers';
import { InstallerIndoorAirProps } from './interface';
import { SingleSlider } from '../common';
import { SwitchButton, SwitchGroupV2, Save, Container } from './components';
import { ConfigsUtils } from '../../utils';
import './styles.scss';

const COMPONENT_STRINGS: any = { ...config?.strings };
const MODEL_FORMAT: any = { ...config.model };
const DEFAULT_EXTREMS: any = { ...config.sliderValues };
const SLIDERS_DEFAULTS: any = { ...config.sliders_defaults };

const InstallerIndoorAir: React.FC<InstallerIndoorAirProps> = ({
  configShowData,
  configShowDataLoaded,
  handleConfigValidation,
  prepareToSaveIASetupStore,
  showBtnCtrl,
  showBtn,
  ...props
}) => {
  const [peripherals]: any = useState(config.peripherals);
  const [showSaveBtn, setShowSaveBtn]: any = useState(false);
  const [showAlert, setShowAlert]: boolean = useState(false);
  const [
    insideAirSectionIsEnabled,
    setInsideAirSectionIsEnabled,
  ]: boolean = useState(false);
  const [widgetStates, setWidgetStates]: any = useState({
    pm25: { ...MODEL_FORMAT },
    co2: { ...MODEL_FORMAT },
    tVoc: { ...MODEL_FORMAT },
  });
  const [widgetCkStates, setWidgetCkStates]: any = useState({
    co2: { dmst: false, cfcm: false },
    pm25: { dmst: false, cfcm: false },
    tVoc: { dmst: false, cfcm: false },
  });

  const transformSingleSlider = (values: any, id: string) => {
    const { max, min, current } = values;
    return [current, max === -101 ? SLIDERS_DEFAULTS[id] : max];
  };

  const onChangePm25 = (newValues: any) => {
    const tempValues: any = ConfigsUtils.arrayPackageByProp(newValues, 'pm25');

    if (tempValues) {
      const { prop, data }: any = tempValues;

      setWidgetStates((prevStates: any) => {
        return {
          ...prevStates,
          [prop]: {
            ...prevStates[prop],
            [data[0].pointer]: data[0].value,
            [data[1].pointer]: data[1].value,
          },
        };
      });
    }

    setShowSaveBtn(true);
    showBtnCtrl(true);
  };

  const onChangeCO2 = (newValues: any) => {
    const tempValues: any = ConfigsUtils.arrayPackageByProp(newValues, 'co2');

    if (tempValues) {
      const { prop, data }: any = tempValues;

      setWidgetStates((prevStates: any) => {
        return {
          ...prevStates,
          [prop]: {
            ...prevStates[prop],
            [data[0].pointer]: data[0].value,
            [data[1].pointer]: data[1].value,
          },
        };
      });
    }
    setShowSaveBtn(true);
    showBtnCtrl(true);
  };

  const onChangeTvoc = (newValues: any) => {
    const tempValues: any = ConfigsUtils.arrayPackageByProp(newValues, 'tVoc');

    if (tempValues) {
      const { prop, data }: any = tempValues;

      setWidgetStates((prevStates: any) => {
        return {
          ...prevStates,
          [prop]: {
            ...prevStates[prop],
            [data[0].pointer]: data[0].value,
            [data[1].pointer]: data[1].value,
          },
        };
      });
    }
    setShowSaveBtn(true);
    showBtnCtrl(true);
  };

  const onSaveIndoorData = (event: any) => {
    setShowAlert(false);
    const setupValues: any = functions.prepareValuesToSave(
      widgetStates,
      widgetCkStates,
      insideAirSectionIsEnabled,
    );
    return setupValues ? { ...setupValues } : {};
  };

  const handleSaveIndoorData = async (event: any) => {
    let errValidation = await handleConfigValidation();

    if (errValidation) {
      setShowAlert(false);
    }
  };

  const toggleSection = (status: any) => {
    setInsideAirSectionIsEnabled(!status);
    setShowSaveBtn(true);
    showBtnCtrl(true);
  };

  const onChangeWgtCk = (section: string, event: any, value: any) => {
    const { id } = event?.target;
    const props: any = id.split('-');

    if (props && props[0] && props[1]) {
      if (functions.isNotVC(props[1])) {
        setWidgetCkStates((prevVals: any) => {
          return {
            ...prevVals,
            [section]: {
              ...prevVals[section],
              [props[0]]: value,
            },
          };
        });
      } else {
        setWidgetCkStates((prevVals: any) => {
          return {
            ...prevVals,
            [section]: {
              ...prevVals[section],
              [props[1]]: {
                ...prevVals[section][props[1]],
                [props[0]]: value,
              },
            },
          };
        });
      }
    }

    setShowSaveBtn(true);
    showBtnCtrl(true);
  };

  useEffect(() => {
    const setupResult: any = onSaveIndoorData();

    prepareToSaveIASetupStore(setupResult);
  }, [widgetStates, widgetCkStates, insideAirSectionIsEnabled]);

  useEffect(() => {
    if (!showBtn) {
      setShowSaveBtn(false);
    }
  }, [showBtn]);

  useEffect(() => {
    if (configShowDataLoaded && configShowData.length > 0) {
      const indoorAir: any = configShowData[0]?.setup?.indoorAir;
      const tempWidgetsValues: any = functions.handleIndoorAirValues(indoorAir);
      setInsideAirSectionIsEnabled(indoorAir?.turnedOn);

      setWidgetStates(tempWidgetsValues);

      if (indoorAir) {
        const { checkbox } = indoorAir;
        if (checkbox !== null && checkbox !== undefined) {
          setWidgetCkStates({ ...checkbox });
        }
      }
    }
  }, [configShowData, configShowDataLoaded]);

  return (
    <div className="" id="installer-slider-section-edit">
      <Container cClass="switch-box" colClass={`col`}>
        <SwitchButton
          stringInfo={COMPONENT_STRINGS.title}
          id="indoor-section"
          onChange={() => {
            toggleSection(insideAirSectionIsEnabled);
          }}
          value={insideAirSectionIsEnabled}
        />
      </Container>

      <div className="installer-edit-indoor-wrapper">
        <div className="row">
          <div className="installer-slider-wrapper">
            <SingleSlider
              id="w-pm25-edit-config"
              values={transformSingleSlider(widgetStates?.pm25, 'pm25')}
              max={functions.normalizeMaxValues(
                'pm25',
                DEFAULT_EXTREMS.pm25.max,
                widgetStates.pm25,
              )}
              min={DEFAULT_EXTREMS.pm25.min}
              step={1}
              col={2}
              showLabel={true}
              symbol=""
              onChange={onChangePm25}
              defaultMark={true}
              defaultMarkValues={{
                top: 40,
                left: 29.7,
                value: SLIDERS_DEFAULTS.pm25,
              }}
            />

            <SwitchGroupV2
              data={peripherals}
              onChange={onChangeWgtCk.bind(null, 'pm25')}
              ckData={widgetCkStates?.pm25}
            />
          </div>
          <div className="installer-slider-wrapper">
            <SingleSlider
              id="w-co2-edit-config"
              values={transformSingleSlider(widgetStates?.co2, 'co2')}
              max={functions.normalizeMaxValues(
                'co2',
                DEFAULT_EXTREMS.co2.max,
                widgetStates.co2,
              )}
              min={DEFAULT_EXTREMS.co2.min}
              step={10}
              col={2}
              showLabel={true}
              symbol=""
              onChange={onChangeCO2}
              defaultMark={true}
              defaultMarkValues={{
                top: 40,
                left: 18.4,
                value: SLIDERS_DEFAULTS.co2,
              }}
            />

            <SwitchGroupV2
              data={peripherals}
              ckData={widgetCkStates?.co2}
              onChange={onChangeWgtCk.bind(null, 'co2')}
            />
          </div>
          <div className="installer-slider-wrapper">
            <SingleSlider
              id="w-tvoc-edit-config"
              values={transformSingleSlider(widgetStates?.tVoc, 'tVoc')}
              max={functions.normalizeMaxValues(
                'tVoc',
                DEFAULT_EXTREMS.tVoc.max,
                widgetStates.tVoc,
              )}
              min={DEFAULT_EXTREMS.tVoc.min}
              step={10}
              col={2}
              showLabel={true}
              symbol=""
              onChange={onChangeTvoc}
              defaultMark={true}
              defaultMarkValues={{
                top: 40,
                left: 24.6,
                value: SLIDERS_DEFAULTS.tVoc,
              }}
            />

            <SwitchGroupV2
              data={peripherals}
              ckData={widgetCkStates?.tVoc}
              onChange={onChangeWgtCk.bind(null, 'tVoc')}
            />
          </div>
        </div>
      </div>

      <Save
        onClick={handleSaveIndoorData}
        config={config}
        showSaveBtn={showSaveBtn}
      />

      <div className="lead"></div>
    </div>
  );
};

function mapStateToProps(state: any) {
  const { userConfigs } = state;
  return {
    configShowData: userConfigs.configShowData,
    configShowDataLoaded: userConfigs.configShowDataLoaded,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { adminLoaderState, userConfigs } = dispatch;
  return {
    loading: {
      startLoader: adminLoaderState.startLoader,
      stopLoader: adminLoaderState.stopLoader,
    },
    prepareToSaveIASetupStore: userConfigs.prepareToSaveIASetupStore,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallerIndoorAir);
