import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { InstallerTemplate } from 'business/modules/common';
import { NoSystems } from './components';
import './styles.scss';
import config from './config';


const DEFAULT_STRINGS: any = { ...config.strings };

interface LoadingProps {
  start: Function;
  stop: Function;
}

interface InstallerHomeProps {
  isLoading: boolean;
  loading: LoadingProps;
};

const InstallerHome: React.FC<InstallerHomeProps> = ({ ...props }) => {
  const [installedSystems, setInstalledSystems]: any = useState([]);

  return (
    <InstallerTemplate id="installer-dashboard" title={DEFAULT_STRINGS.title}>
      <div className="installer-dash-container">
        {installedSystems && installedSystems.length > 0 ? (
          installedSystems.map((system: any, index: number) => {
            return <div className="" key={`inst-sys--${index}`}></div>;
          })
        ) : (
          <NoSystems />
        )}
      </div>
    </InstallerTemplate>
  );
};

function mapStateToProps(state: any) {
  const { loader }: any = state || {};
  return {
    isLoading: loader.isLoading,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { loader }: any = dispatch || {};
  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallerHome);
