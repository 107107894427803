import { ConnectionBroker } from '../../core';

const AW_LABEL: string = '@air-awair';

export const awair = {
  state: {
    userData: null,
    isConnected: false,
    hasLocal: false,
    awairDevices: [],
    localSensor: null,
  },

  reducers: {
    updateState(state: any, payload: any, name: string) {
      return { ...state, [name]: payload };
    },
    setTimeouts(state: any, payload: any) {
      let timeClone = [...state.timeouts];
      timeClone.push(payload);
      return { ...state, timeouts: timeClone };
    },
    updateLocalSensor(state: any, payload: any) {
      return {
        ...state,
        localSensor: payload,
      };
    },
  },

  effects: (dispatch: any) => ({
    async loadAwairState(payload: any, rootState: any) {
      const self: any = this;
      const userDetails: any = payload?.details || {};
      let accountState: boolean = false;

      if (userDetails && userDetails?.thirdParty) {
        const { awair }: any = userDetails.thirdParty;
        self.updateState(payload, 'userData');

        if (awair && awair.connected) {
          accountState = true;
          self.updateState(true, 'isConnected');
        }
      }

      return accountState;
    },
    async updateAwairState(payload: any, rootState: any) {
      const self: any = this;
      const { profile } = dispatch;
      let userProfile: any = await profile.loadProfile();

      if (userProfile && userProfile?.body) {
        await self.loadAwairState(userProfile.body);
      }
    },
    resetUserConnection(payload: any, rootState: any) {
      const self: any = this;

      self.updateState(false, 'isConnected');
    },
    async connect(payload: any, rootState: any) {
      const self: any = this;
      const { code }: any = payload || {};

      if (code !== null && code !== undefined) {
        let response: any = await ConnectionBroker.fetchAPI(
          'jitBE',
          'awair.connect',
          payload,
          null,
        );

        if (response?.statusCode === 200) {
          self.updateState(true, 'isConnected');
        }

        return response;
      }

      return;
    },
    async disconnect(payload: any, rootState: any) {
      const self: any = this;
      const { profile } = rootState;

      let response: any = await ConnectionBroker.fetchAPI(
        'jitBE',
        'awair.disconnect',
        {},
        null,
      );

      if (response?.statusCode === 200) {
        self.updateState(false, 'isConnected');
      }

      return response;
    },

    async getAirThingsDevices(payload: any, rootState: any) {
      const self: any = this;
      let { isConnected } = rootState.awair;

      if (isConnected) {
        let response = await ConnectionBroker.fetchAPI(
          'jitBE',
          'awair.list',
          null,
        );

        if (response?.statusCode === 200) {
          self.updateState(response.body, 'awairDevices');
        }

        return response?.statusCode === 200 ? response.body : [];
      }

      return;
    },

    async getLastestValues(payload: any, rootState: any) {
      let { isConnected, hasLocal } = rootState.awair;
      const { id } = payload;
      
      if ((isConnected || hasLocal) && id) {
        let response = await ConnectionBroker.fetchAPI(
          'jitBE',
          'awair.show',
          null,
          { id: id },
        );

        return response;
      }

      return;
    },
  }),
};
