import config from './config';

const getSensorDataForDevice = (
  deviceId: string,
  indoorAirSettingsArr: any,
  indoorAirSettingsAreLoaded: boolean,
) => {
  let sensorObjectData: any = {};
  let sensorName: string = '';

  if (
    indoorAirSettingsArr &&
    indoorAirSettingsArr.length > 0 &&
    indoorAirSettingsAreLoaded
  ) {
    let sensorValues;

    let deviceData = indoorAirSettingsArr.filter(
      (dev: any) => dev?.body?.data?.device_id === deviceId,
    );

    if (deviceData && deviceData.length > 0) {
      sensorValues = deviceData[0].body?.data?.settings || {};
    }

    if (sensorValues && Object.values(sensorValues).length === 0) {
      sensorName = 'MachineShop';
    } else if (sensorValues) {
      sensorObjectData = Object.values(sensorValues)[0];

      if (!sensorObjectData.hasOwnProperty('add_sensor_id')) {
        sensorName = sensorObjectData['add_sensor'];
      }
      if (sensorObjectData.hasOwnProperty('add_sensor_id')) {
        if (sensorObjectData['add_sensor'] === '') {
          sensorName = 'MachineShop';
        } else {
          sensorName = sensorObjectData['add_sensor'];
        }
      }
    }
  }

  return sensorName;
};

const handleOpts = (action: string, section: string) => {
  if (section === `title`) {
    if (config.titles.hasOwnProperty(action)) {
      return config.titles[action as keyof typeof config.titles];
    }
  }
  if (section === `icon`) {
    if (config.icons.hasOwnProperty(action)) {
      return config.icons[action as keyof typeof config.icons];
    }
  }
  if (section === `bodies`) {
    if (config.bodies.hasOwnProperty(action)) {
      return config.bodies[action as keyof typeof config.bodies];
    }
  }

  if (section === `bodyClass`) {
    if (config.bodyClass.hasOwnProperty(action)) {
      return config.bodyClass[action as keyof typeof config.bodyClass];
    }
  }
};

const getCurrentDev = (id: string, userDevices: any, dataIsLoaded: boolean) => {
  let d;
  if (userDevices && userDevices.length > 0 && dataIsLoaded) {
    d = userDevices.filter((dev: any) => dev.device_id === id);

    return d[0];
  }
  // return d[0];
};

export const helpers = {
  getSensorDataForDevice: getSensorDataForDevice,
  handleOpts: handleOpts,
  getCurrentDev: getCurrentDev,
};
