import React from 'react';
import { formatInTimeZone } from 'date-fns-tz';
import { Input } from 'react-components';
import { OperationTime, Timezone } from './components';
import { CommonSectionProps } from './interface';
import './styles.scss';


const CommonSection: React.FC<CommonSectionProps> = ({
  config,
  errors,
  fieldsState,
  handleSetTimezone,
  onChangeState,
}) => {
  const TIMEZONE: any = [...config.timezones];


  return (
    <div
      id="edit-user-configs-time--section"
      className="user-configs-tmzn-edit"
    >
      <OperationTime
        hOpClass={`hours-operation special-spec-op1`}
        fieldsState={fieldsState}
        onChangeState={onChangeState}
        errors={errors}
        config={config}
      />

      <Timezone
        value={TIMEZONE[fieldsState.tmzn]?.label}
        onChange={handleSetTimezone}
      >
        {TIMEZONE.map((tm: any) => (
          <option key={tm?.label} value={tm?.label}>
            {tm?.label || ''}
          </option>
        ))}
      </Timezone>
      <div className="row">
        <div className="col-12 no-side-paddings">
          <div
            className={`tmzn-display--wrapper--${
              fieldsState?.hroa ? 'open' : 'closed'
            }`}
          >
            <Input
              disabled
              handleChangeError={() => {}}
              handleChange={() => {}}
              type="text"
              label={config?.fields?.tmzn?.label}
              labelStyles="calc-mode--curr-time"
              value={`${formatInTimeZone(
                new Date(),
                `${TIMEZONE[fieldsState.tmzn]?.timezone}`,
                'HH:mm',
              )}`}
              error={''}
              name={''}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonSection;
