import React, { useEffect } from 'react';
import config from './config';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { EditPeripheralProps } from './interface';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Button, Input, Modal, Template } from 'business/modules/admin/common';
import { HiOutlineXCircle } from 'react-icons/hi';
import { RiSave3Fill } from 'react-icons/ri';
import './styles.scss';

const EditPeripheral: React.FC<EditPeripheralProps> = ({
  peripheralNewName,
  updateName,
  userStartedToType,
  handleUserTyped,
  changeTab,
}) => {
  const history = useHistory();

  const errs: any = {
    'periph-name': 'Field cannot be blank.',
  };

  const preventSubmitIfEmptyValues = () => {
    if (peripheralNewName.trim().length === 0) {
      return true;
    } else return false;
  };

  const handleError = (field: string) => {
    if (peripheralNewName?.trim().length === 0 && userStartedToType) {
      return errs[field as keyof typeof errs];
    }
    return '';
  };

  useEffect(() => {
    return () => {
      handleUserTyped(false);
    };
  }, []);

  return (
    <div className="edit-peripheral-page">
      <Template
        id="setup-edit-peripheral"
        title={config?.strings?.title}
        goBack={() => {
          history.push(`/admin/dashboard/user-details`);
          changeTab(`sys`, 'edit');
        }}
      >
        <BreadcrumbsItem to={config?.strings?.breadcrumbItem}>
          {config?.strings?.breadcrumbItemTitle}
        </BreadcrumbsItem>

        <div className="container min-height-container">
          <div className="row no-side-space">
            <div className="col no-side-space">
              <div className="data-details">
                <h4 className="centered">{config?.strings?.mainTitle}</h4>
                <p className="lead centered card-top-font">
                  {config?.strings?.pText}
                </p>
                <hr className="my-4" />
                <div className="container">
                  <div className="row no--side-mg">
                    <div className="col-xs-12 col-sm-12 col-md-6 offset-md-3 no--side-pdg">
                      <div className="card xpec-card">
                        <div className="card-body">
                          <h5 className="card-title">
                            {config?.strings?.formTitle}
                          </h5>

                          <h6 className="card-subtitle mb-2 text-muted">
                            {config?.strings?.formSubTitle}
                          </h6>
                          <p className="card-text">
                            <Input
                              type="text"
                              className="form-control mt-1"
                              id="periph-name"
                              placeholder="Enter name here"
                              value={peripheralNewName}
                              onChange={(event: any) =>
                                updateName(event.target.value)
                              }
                            />
                            <div className="render-error">
                              {handleError('periph-name')}
                            </div>
                          </p>

                          <div className="container pdg">
                            <div className="row">
                              <div className="col pdg">
                                <Button
                                  className="small-button dismiss-btn float-mg float-right"
                                  onClick={() => {
                                    updateName('');
                                  }}
                                >
                                  <span className="label-i btn-child-ft">
                                    <HiOutlineXCircle className="cancel-i" />
                                    {config.strings.cancel}
                                  </span>
                                </Button>

                                <Button
                                  className="small-button ok btn--spec--mr1 float-right"
                                  dataToggle="modal"
                                  dataTarget="#save-changes-edit-periph"
                                  disabled={
                                    preventSubmitIfEmptyValues() ? true : false
                                  }
                                >
                                  <span className="label-i btn-child-ft">
                                    <RiSave3Fill className="edit-save" />
                                    {config.strings.save}
                                  </span>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Template>
      <Modal
        config={config}
        id={`save-changes-edit-periph`}
        title={config.titles.periph__update}
        icon={<RiSave3Fill className="edit" />}
        body={config.strings.confirmation}
        //todo save changes
        onClick={() => {}}
        onClickCancel={() => {}}
        // modalMustClose={modalMustClose}
        // handleModalState={handleModalState}
        /*
        handle these when there is an endpoint 
        and a function call to vhange those values */
        modalMustClose={false}
        handleModalState={() => {}}
        label={config.labels.periph__update}
        className={`small-button ok`}
        bodyClass={config.bodyClass.periph__update}
        sureToProceed={config.strings.sureToProceed}
        hasConfirmBtn={true}
      />
    </div>
  );
};

function mapStateToProps(state: any) {
  const { adminDevicesState } = state;
  return {
    peripheralNewName: adminDevicesState.peripheralNewName,
    userStartedToType: adminDevicesState.userStartedToType,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { loader, adminDevicesState, adminSectionsState } = dispatch;
  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    updateName: adminDevicesState.updateName,
    handleUserTyped: adminDevicesState.handleUserTyped,
    changeTab: adminSectionsState.changeTab,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPeripheral);
