import React from 'react';
import config from './config';
import { MCConfigsProps } from './interface';
import { Switch } from '@material-ui/core';

const MCConfigs: React.FC<MCConfigsProps> = ({ dev, index, returnLabel }) => {
  return (
    <div className="card mc-card" key={index}>
      <div className="card-body switches--mc--1">
        <div className="col-12 d-flex justify-content-center">
          <h5 className="card-title lead card-title-sys">{dev?.system_name}</h5>
        </div>

        <h6 className="card-subtitle mb-2 text-muted">
          {config.strings.title}
        </h6>
        {Object.entries(dev?.config).map(([item, value]: [any, any], i) => {
          return (
            <div className="row mc--switches" key={i}>
              {item === 'status' ||
              item === 'account_id' ||
              item === 'system_id' ? null : item === 'cdvs' ? (
                value &&
                value.map((vc: any, i: number) => {
                  return (
                    <div className="container values-mc" key={i}>
                      <div className="row">
                        <div className="col label-side">{vc.cdid}</div>
                        <div className="col-auto">
                          <Switch
                            id={``}
                            checked={!!value.cdst}
                            color="primary"
                          />
                        </div>
                      </div>{' '}
                    </div>
                  );
                })
              ) : (
                <div className="container values-mc" key={i}>
                  <div className="row">
                    <div className="col label-side">{returnLabel(item)}</div>
                    <div className="col-auto">
                      <Switch id={``} checked={!!value} color="primary" />
                    </div>
                  </div>{' '}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MCConfigs;
