import { ConnectionBroker } from '../../core';

export const manualControl = {
  state: {
    manualControlList: [],
    updatedMcSetup: {},
    mcSetup: {},
    mcSetupUpdated: {},
  },
  reducers: {
    updateState(state: any, payload: any, name: string) {
      return { ...state, [name]: payload };
    },
  },
  effects: (dispatch: any) => ({
    async getManualControlList() {
      const that: any = this;

      let response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'manualControl.list',
        null,
        null,
      );
      if (response?.statusCode === 200) {
        that.updateState(response.body, 'manualControlList');
      } else {
        // TODO: add error handler
      }
      return response;
    },
    async createManualControl(data: object) {
      const that: any = this;
      let response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'manualControl.create',
        data,
        null, //no params
      );

      if (response?.statusCode === 200) {
        that.updateState(response.body, 'updatedMcSetup');
      } else {
        // TODO: add error handler
      }
      return response;
    },
    async getManualControlDetails(data: object) {
      const that: any = this;
      let response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'manualControl.show',
        null,
        data,
      );
      if (response?.statusCode === 200) {
        that.updateState(response.body, 'mcSetup');
      } else {
        // TODO: add error handler
      }
      return response;
    },
    async deleteManualControl(data: any) {
      const that: any = this;
      if (data && data.device_id) {
        let response = await ConnectionBroker.fetchAPI(
          'jitBE',
          'manualControl.delete',
          data,
        );
        if (response?.statusCode === 200) {
          await that.getManualControlList();
        } else {
          // TODO: add error handler
        }
        return response;
      }
    },
    // async updateManualControl(payload: any) {
    //   const that: any = this;
    //   let response = await ConnectionBroker.fetchAPI(
    //     'jitBE',
    //     'manualControl.update',
    //     payload,
    //     null,
    //   );
    //   if (response?.statusCode === 200) {
    //     let data: any = { manualControl_id: payload.manualControl_id };
    //     await that.getManualControlDetails(data);
    //     await that.manualControlList();
    //   } else {
    //     // TODO: add error handler
    //   }
    //   return response;
    // },
  }),
};
