import { ConnectionBroker } from '../../core';

export const adminLogsState = {
  state: {
    admSetupLogsList: {},
    admSetupLogsLoaded: false,
    admLogsData: [],
    adminLogsDataLoaded: false,
    orders: {
      orderByDate: false,
    },
    intendedCounter: {
      date: 0,
    },
    counter: 0,
    orderedList: false,
  },
  reducers: {
    updateOrderState(state: any, payload: string) {
      switch (payload) {
        case 'Log Date':
          return {
            ...state,
            orderedList: false,
            orders: {
              orderByDate: true,
            },
          };
        case 'Default':
          return {
            ...state,
            orderedList: true,
            orders: {
              orderByDate: false,
            },
          };
        default:
          return {
            ...state,
            orderedList: payload,
            orders: {
              orderByDate: false,
            },
          };
      }
    },

    handleCounterAndListOrder(state: any, payload: any, counter: number) {
      return {
        ...state,
        intendedCounter: payload,
        counter: counter,
      };
    },

    storeAdmSetupLogs(state: any, payload: any) {
      return {
        ...state,
        admSetupLogsList: payload,
        admSetupLogsLoaded: true,
      };
    },
    storeAdmSetupLogsDetails(state: any, payload: any) {
      return {
        ...state,
        admLogsData: payload,
        adminLogsDataLoaded: true,
      };
    },
  },
  effects: () => ({
    async listAdmSetupLogs(userId: any) {
      const data = { account_id: userId };
      const that: any = this;
      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        `admin.setupLogsList`,
        null,
        data,
      );
      if (response?.statusCode === 200) {
        that.storeAdmSetupLogs(response?.body?.data);
      }
      return response;
    },
    async getSetupLogsShow(data: any) {
      const that: any = this;
      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        `admin.setupLogsShow`,
        null,
        data,
      );
      if (response?.statusCode === 200) {
        that.storeAdmSetupLogsDetails(response?.body?.data);
      }
      return response;
    },
  }),
};
