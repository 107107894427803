// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { Grid, Slider } from '@material-ui/core';
import {
  renderCustomThumb,
  LeftSideComponent,
  RightSideComponent,
} from './hooks';
import TooltipView from 'react-components/components/Tooltip';
import ReactSlider from 'react-slider';
import * as Utils from './utils';
import './styles.scss';

const UTILS = { ...Utils.default };

interface WidgetSingleSliderProps {
  id: string;
  values?: any;
  onChange?: any;
  max: any;
  min: any;
  step: any;
  col?: number;
  showLabel?: any;
  symbol?: string;
  defaultMark?: any;
  defaultMarkValues?: any;
}

const WidgetSingleSlider: React.FC<WidgetSingleSliderProps> = ({
  id,
  values,
  onChange,
  max,
  min,
  step,
  col,
  showLabel,
  symbol,
  defaultMark,
  defaultMarkValues,
  ...props
}) => {
  const [compData, setCompData]: any = useState(null);
  const [compValues, setCompValues]: any = useState([]);

  useEffect(() => {
    if (id && id !== '') {
      const result: any = UTILS.selectData(id);
      if (result) {
        setCompData({ ...result });
      }
    }

    return () => {
      setCompData(null);
    };
  }, [id]);

  useEffect(() => {
    if (values) {
      const tempValues: any = UTILS.mapNewValues(values, { max, min });
      setCompValues([...tempValues]);
    }
  }, [values]);

  return (
    <Grid className="widget-slider">
      <div id={id} className="widget-slider--container">
        <Grid container>
          <Grid item className={`slider--left-container--${col ? col : 1}`}>
            {compData && <LeftSideComponent data={compData} />}
          </Grid>
          <Grid item className={`slider--center-container--${col ? col : 1}`}>
            <ReactSlider
              className="ac-horizontal--slider"
              thumbClassName="ac--slider-thumb"
              trackClassName="ac--slider-track"
              value={compValues}
              step={step}
              max={max}
              min={min}
              ariaLabel={[
                's-Left s-thumb',
                's-Middle s-thumb',
                's-Right s-thumb',
              ]}
              renderThumb={renderCustomThumb.bind(null, symbol)}
              minDistance={-max}
              snapDragDisabled={true}
              onSliderClick={(value: number, tIndex: number) => {}}
              onBeforeChange={(value: number, tIndex: number) => {}}
              onChange={onChange}
            />
            {defaultMark && defaultMarkValues && (
              <div className="default-value--wrapper">
                <TooltipView
                  title={`Default value: ${defaultMarkValues.value}`}
                >
                  <svg
                    width="10"
                    height="8"
                    style={{
                      position: 'absolute',
                      zIndex: 99,
                      top: defaultMarkValues?.top && typeof defaultMarkValues.top === 'string' ? `${defaultMarkValues.top}%` : defaultMarkValues.top,
                      left: defaultMarkValues?.left && typeof defaultMarkValues.left === 'string' ? `${defaultMarkValues.left}%` : defaultMarkValues.left
                    }}
                  >
                    <rect
                      width="10"
                      height="8"
                      style={{ fill: '#90c095', strokeWidth: 0 }}
                    />
                    Sorry, your browser does not support inline SVG.
                  </svg>
                </TooltipView>
              </div>
            )}
          </Grid>
          <Grid item className={`slider--right-container--${col ? col : 1}`}>
            {compData && <RightSideComponent data={compData} />}
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default WidgetSingleSlider;
