import React, { Fragment } from 'react';
import { SwitchesProps } from './interface';
import { SwitchBtn } from 'business/modules/admin/common';
import './styles.scss';

const Switches: React.FC<SwitchesProps> = ({ settings, ckData, onChange }) => {
  const smallerLabel = (originalLabel: any) => {
    if (originalLabel[9] === 'V') {
      return `VentConnect`;
    } else if (originalLabel[9] === 'F') {
      return `FreshConnect2`;
    } else {
      return originalLabel;
    }
  };

  const returnKey = (key: string) => {
    switch (key) {
      case 'dmst':
        return 'Damper Open';
      case 'cfst':
        return 'Central Fan';
      case 'fcst':
        return 'Fan Connect';
      default:
        break;
    }
  };

  const retrieveValue = (_type: any, _ckData: any, _id: any, cdid?: string) => {
    if (_ckData) {
      switch (_type) {
        case 'fcst':
          return _ckData['fanConnect'];
        case 'dmst':
          return _ckData[_type];
        case 'cfst':
          return _ckData['cfcm'];
        case 'ventConnect':
          return cdid && _ckData && _ckData[_type] && _ckData[_type][cdid]
            ? _ckData[_type][cdid]
            : 0;
        default:
          break;
      }
    }
  };

  return (
    <div className="row switches-section-indoor-air">
      <div className="col">
        {Object.entries<any>(settings)
          .slice(0, Object.entries(settings).length / 2)
          .map(([key, value], index) => {
            return key === `cdvs` ? (
              <Fragment key={index}>
                {Object.entries<any>(value).map(
                  ([key, value]: [any, any], i) => {
                    return (
                      <div className="row" key={i}>
                        <div className="col-8 label-side">
                          <div className="container double-label">
                            <div className="row">
                              <span className="label">{value.cdid}</span>
                            </div>

                            <div className="row">
                              <span className="sm-label-vc">
                                {value.cail
                                  ? value.cail
                                  : smallerLabel(value?.cdid)}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <SwitchBtn
                            className="status-switch"
                            id={value.cdid}
                            onChange={onChange}
                            value={retrieveValue(
                              `ventConnect`,
                              ckData,
                              key,
                              value.cdid,
                            )}
                          />
                        </div>
                      </div>
                    );
                  },
                )}
              </Fragment>
            ) : (
              <div className="row" key={index}>
                <div className="col-8 label-side">
                  <span className="label">{returnKey(key)}</span>
                </div>
                <div className="col-2">
                  <SwitchBtn
                    className="status-switch"
                    value={retrieveValue(key, ckData, key)}
                    onChange={onChange}
                    id={key}
                  />
                </div>
              </div>
            );
          })}
      </div>
      <div className="col">
        {Object.entries<any>(settings)
          .slice(Object.entries(settings).length / 2)
          .map(([key, value], index) => {
            return key === `cdvs` ? (
              <Fragment key={index}>
                {Object.entries<any>(value).map(
                  ([key, value]: [any, any], i) => {
                    return (
                      <div className="row" key={i}>
                        <div className="col-8 label-side">
                          <div className="container double-label">
                            <div className="row">
                              <span className="label">{value.cdid}</span>
                            </div>
                            <div className="row">
                              <span className="sm-label-vc">
                                {value.cail
                                  ? value.cail
                                  : smallerLabel(value?.cdid)}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-2">
                          <SwitchBtn
                            id={value.cdid}
                            className="status-switch"
                            value={retrieveValue(
                              `ventConnect`,
                              ckData,
                              key,
                              value.cdid,
                            )}
                            onChange={onChange}
                            onClick={() => {}}
                          />
                        </div>
                      </div>
                    );
                  },
                )}
              </Fragment>
            ) : (
              <div className="row" key={index}>
                <div className="col-8 label-side">
                  <span className="label not-vc-label">{returnKey(key)}</span>
                </div>
                <div className="col-2">
                  <SwitchBtn
                    id={key}
                    className="status-switch"
                    value={retrieveValue(key, ckData, key)}
                    onChange={onChange}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Switches;
