import React from 'react';
import { DataLoggingProps } from './interface';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './styles.scss';

const DataLogging: React.FC<DataLoggingProps> = ({
  data,
  id,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) => {
  return (
    <div className="container" id={id}>
      <div className="row">
        <div className="col-12 system-details">
          <h5 className="card-title">System: {data?.details?.name} </h5>
          <h6 className="card-subtitle mb-2 text-muted">{data?.device_name}</h6>
        </div>
      </div>

      <div className="row" id="row-datepickers">
        <div className="col-xs-12 col-sm-12 col-md-6 c1">
          <DatePicker
            selected={startDate}
            dataFormat={'MM/DD/YYYY'}
            maxDate={moment().toDate()}
            inline
            onChange={setStartDate}
          />
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 c2">
          <DatePicker
            selected={endDate}
            dataFormat={'MM/DD/YYYY'}
            maxDate={moment().toDate()}
            inline
            onChange={setEndDate}
          />
        </div>
      </div>
    </div>
  );
};

export default DataLogging;
