import { ConnectionBroker } from '../../core';

export const adminState = {
  state: {
    adminUserIDtoDelete: '',
    adminUsernameToDelete: '',
    modalKey: 'modal-key',
    devModalKey: 'dev-modal-key',
    systemKey: 'system-key',
    ventStack: null,
    g3Reads: {},
    globalChartsData: [],
    currentlySelecteAdminUser: '',
    currenltySelectedAdminId: '',
    adminUser: null,
  },
  reducers: {
    setSystemKey(state: any, payload: any) {
      return { ...state, systemKey: payload };
    },
    storeClickedUser(state: any, payload: any) {
      localStorage.setItem('_adminUser', JSON.stringify(payload));
      const persistentAdm = localStorage.getItem('_adminUser');
      let parsedAdm;
      if (persistentAdm) {
        parsedAdm = JSON.parse(persistentAdm);
      }

      return {
        ...state,
        currentlySelecteAdminUser: payload.username,
        currenltySelectedAdminId: payload.account_id,
        adminUser: parsedAdm, //payload
      };
    },
    saveChartsDataGlobally(state: any, payload: any) {
      return {
        ...state,
        globalChartsData: payload,
      };
    },
    updateG3Reads(state: any, payload: any, deviceId: string) {
      const { g3Reads } = state;
      return {
        ...state,
        g3Reads: {
          ...g3Reads,
          [deviceId]: { ...payload },
        },
      };
    },
    updateVentStack(state: any, payload: any) {
      return {
        ...state,
        ventStack: payload,
      };
    },
    setDevModalKey(state: any, payload: any) {
      return { ...state, devModalKey: payload };
    },
    setModalKey(state: any, payload: any) {
      return { ...state, modalKey: payload };
    },
    setAdminUserIdToDelete(state: any, payload: any) {
      return {
        ...state,
        adminUserIDtoDelete: payload.account_id,
        adminUsernameToDelete: payload.username,
      };
    },
  },
  effects: (dispatch: any) => ({
    async deleteAdminUser(userId: any) {
      const that: any = this;
      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        `admin.adminUsersDelete`,
        userId,
        null,
      );
      if (response?.statusCode === 200) {
        //use response to give user a feedback about deletion
      }
      return response;
    },
  }),
};
