import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import {
  TableRow,
  TableCell,
} from '@material-ui/core';
import _ from 'lodash';
import {
  ThresholdTableHeader,
} from './components';
import config from './config';
import './styles.scss';


const TABLE_COLUNMS: any = [...config.colunms];
const THRESH_TAGS: any = [...config.threshTags];


interface ThresholdRowProps {
  data: any;
}

const ThresholdRow: React.FC<ThresholdRowProps> = ({
  data,
  ...props
}) => {
  const [dataValues, setDataValues]: any = useState({});


  const filterThresholdValues = (_data: any) => {
    const filteredData: any = {};

    Object.keys(_data).forEach((prop: string) => {
      const index: number = THRESH_TAGS.findIndex(
        (tag: string) => tag === prop
      );

      if(index > -1){
        filteredData[prop] = _data[prop];
      }
    });

    return filteredData;
  };


  useEffect(() => {
    if(data && Array.isArray(data) && data.length > 0){
      const lastResultCopy: any = _.cloneDeep(data[0]);
      const thValues: any = filterThresholdValues(lastResultCopy);
      setDataValues(thValues);
    }
  }, [data]);

  return (
    <div id="thresh-row--container">
      <div className="thresh-row--container">
        <ThresholdTableHeader values={dataValues} />
      </div>
    </div>
  );
}

export default ThresholdRow;
