import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
  Grid,
  Slider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import moment from 'moment';
import { InstallerTemplate } from 'business/modules/common';
import { Convertions } from 'core/utils';
import {
  FanIcon,
  ConnectedIcon,
  DisconnectedIcon,
  BigGreenFan,
  BigWhiteFan,
} from 'business/assets/device-icons';
import {
  Button,
  Notification,
} from 'react-components';
import {
  CustomSlider,
  CustomSwitch,
  PeripheralOptional,
  HeaderMenu,
} from './components';
import config from './config';
import configs from 'business/config/config';
import './styles.scss';



const WIFI_CONN_DIFF = 30; // in seconds

interface LoadingProps {
  start: any;
  stop: any;
}

interface PeripheralProps {
  peripheral?: any;
  device: any;
  isLoading: boolean;
  loading: LoadingProps;
  setPeripheral: any;
  reqDeviceDetails: any;
  updateDevice: any;
  getDeviceLogs: any;
}

const STORAGE_PERIPHERAL_LABEL = '@air-peripheral:';
const SOCKET_URL = configs?.apis?.g3iot?.baseSocketURLs[0];
const DEVICE_LABELS = config?.strings?.labels;
let WEBSOCKET: any;

const PeripheralSetup: React.FC<PeripheralProps> = ({
  peripheral,
  isLoading,
  loading,
  device,
  ...props
}) => {
  const history: any = useHistory();
  let intervals: any = [];
  let menuConfigs = [...config?.menu?.options];
  const [fieldsState, setFieldsState]: any = useState({});
  const [showUpdateAlert, setShowUpdateAlert]: any = useState(false);
  const [showAbout, setShowAbout]: any = useState(false);
  const [upDevice, setUpDevice]: any = useState({});

  const saveDeviceData = () => {
    let data = { ...history.location.state };
    if (data) {
      localStorage.setItem(STORAGE_PERIPHERAL_LABEL, JSON.stringify(data));
    }
  };

  const recoverDeviceDetails = async () => {
    let storedDeviceData: any = localStorage.getItem(STORAGE_PERIPHERAL_LABEL);
    if (storedDeviceData) storedDeviceData = JSON.parse(storedDeviceData);

    if (storedDeviceData?.device_id) {
      loading.start('Loading Peripheral Details');
      props
        .reqDeviceDetails({ device_id: storedDeviceData?.device_id })
        .then((resp: any) => {
          // TODO: add notification if response has errors
          if (resp.statusCode === 200) {
            let cdvs = [...resp?.body?.details?.settings?.conf?.cdvs];
            let currentPeripheral =
              cdvs && cdvs.length > 0
                ? cdvs.find(
                    (periph: any) =>
                      periph?.dmac === storedDeviceData?.peripheral_id,
                  )
                : null;
            if (currentPeripheral) {
              props.setPeripheral({ ...currentPeripheral });
            }
          }
          loading.stop();
        });
    }
  };

  const isVentConnect = (_device: any) => {
    const { cdid }: any = _device || {};

    return cdid && cdid.includes('AirCyclerVC');
  };

  const isVentConnectOn = (_device: any) => {
    const _isVentConnect: any = isVentConnect(_device);
    let isOn: boolean = false;

    if (_isVentConnect) {
      const { cdst }: any = _device || {};
      isOn = cdst > 0;
    }

    return isOn;
  };

  const customHandleChange = (prop: string, event: any, newValue: any) => {
    if (peripheral && peripheral.hasOwnProperty(prop)) {
      props.setPeripheral({
        ...peripheral,
        [prop]: newValue,
      });
    }
  };

  const handleChangeSwitch = (prop: string, event: any, newValue: any) => {
    if (peripheral && peripheral.hasOwnProperty(prop)) {
      props.setPeripheral({
        ...peripheral,
        [prop]: newValue ? 1 : 0,
      });
    }
  };

  const handleChangeOptional = (event: any) => {
    props.setPeripheral({
      ...peripheral,
      exct: event?.target?.value ? Number(event.target.value) : 0,
    });
  };

  const handleSavePeripheralSetup = () => {
    setShowUpdateAlert(true);
  };

  const savePeripheralSetup = async () => {
    setShowUpdateAlert(false);
    loading.start('Updating Peripheral');
    let deviceClone = { ...device };
    let periphIndex = deviceClone.details.settings.conf.cdvs.findIndex(
      (periph: any) =>
        periph.dmac === peripheral.dmac || periph.cdid === peripheral.cdid,
    );
    if (periphIndex > -1) {
      deviceClone.details.settings.conf.cdvs[periphIndex] = peripheral;
      props.updateDevice({ ...deviceClone }).then((resp: any) => {
        if (resp?.statusCode === 200) {
          Notification({
            title: 'Successfully',
            message: config?.notifications?.update?.success,
            type: 'success',
          });
        }
        loading.stop();
      });
    }
  };

  const handleWsConnection = (event: any) => {
    saveDeviceData();
  };

  const handleWsReceiveMessage = async (event: any) => {
    recoverDeviceDetails();
  };

  const computeMenuOptions = () => {
    let menuConfClone: any = [...menuConfigs];
    menuConfClone[0].func = () => {
      setShowAbout(true);
    };
    menuConfClone[1].func = () => {};
  };

  const hasValidDate = (item: any) => {
    const { createdAt, createdDate } = item;
    const nowDate: any = moment();
    const devDate: any = moment(new Date(createdDate));
    return nowDate.diff(devDate, 'seconds') < WIFI_CONN_DIFF;
  };

  const isDeviceConnected = async (_device: any) => {
    const { api_key } = _device;
    const resp: any = await props.getDeviceLogs({ deviceKey: api_key });
    return resp && resp?.Items?.length > 0
      ? hasValidDate(resp.Items[0])
      : false;
  };

  const updateDeviceState = async (_device: any) => {
    let isConn: boolean = await isDeviceConnected(_device);
    // _device.isWifiConn = isConn ? 'active' : 'inactive';
    _device.isWifiConn = 'active';
    setUpDevice({ ..._device });
    return _device;
  };

  const startStateListener = async (_device: any) => {
    let updatedDevice: any = { ..._device };
    let tempInterval: any = setInterval(async () => {
      await updateDeviceState({ ...updatedDevice });
    }, 15 * 1000); // 15 secs
    intervals.push(tempInterval);
  };

  const handleWsConnectionClosed = (_device: any, event: any) => {
    if (event?.reason === 'Going away') {
      const newSocket: any = setWebSocket(_device);
      WEBSOCKET = newSocket;
    }
  };

  const setWebSocket = (_device: any) => {
    const { api_key } = _device;
    let socket: any;
    const skUrl = `${SOCKET_URL}?x-iot-key=${api_key}`;
    socket = new WebSocket(skUrl);
    socket.addEventListener('open', handleWsConnection);
    socket.addEventListener('message', handleWsReceiveMessage);
    socket.addEventListener(
      'close',
      handleWsConnectionClosed.bind(null, _device),
    );
    return socket;
  };

  const resetIntervals = () => {
    if (intervals && intervals.length > 0) {
      intervals.forEach((ival: any) => {
        clearInterval(ival);
      });
    }
  };

  useEffect(() => {
    localStorage.removeItem(STORAGE_PERIPHERAL_LABEL);
    window.addEventListener('beforeunload', saveDeviceData);
    if (!peripheral) {
      recoverDeviceDetails();
    }
    computeMenuOptions();

    return () => {
      props.setPeripheral(null);
      window.removeEventListener('beforeunload', saveDeviceData);
    };
  }, []);

  useEffect(() => {
    if (device) {
      startStateListener({ ...device });
      updateDeviceState({ ...device });
      WEBSOCKET = setWebSocket(device);
    }
    return () => {
      resetIntervals();
      if (WEBSOCKET) {
        if (WEBSOCKET.readyState === WebSocket.OPEN) WEBSOCKET.close();
        WEBSOCKET = null;
      }
    };
  }, [device]);

  // TODO: make templates for connected devices
  // this is just for ventilator,
  // slider step, max, min values should also be replaced with some configured pattern values
  return (
    <InstallerTemplate
      id="peripheral-setup"
      title={config?.strings?.title}
      titleBackButton={true}
    >
      <BreadcrumbsItem to="/dashboard/devices/details/peripheral/setup">
        {config?.strings?.title}
      </BreadcrumbsItem>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid container direction="column" className="section-1">
          <div className="section-1--title">
            <div className="per-name">
              {peripheral?.cail
                ? peripheral.cail
                : peripheral?.cdid
                ? peripheral.cdid
                : ''}
            </div>
            <div className="per-status-container">
              {upDevice.isWifiConn === 'active' ? (
                <ConnectedIcon />
              ) : (
                <DisconnectedIcon />
              )}
              <span>
                {upDevice.isWifiConn === 'active' ? 'Online' : 'Offline'}
              </span>
            </div>
          </div>
          <div>
            <HeaderMenu
              data={peripheral}
              options={menuConfigs || []}
              enabled={true}
            />
          </div>
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className="section-2"
        >
          <div className="section-2--icon">
            {peripheral?.cdst ? <BigGreenFan /> : <BigWhiteFan />}
          </div>
          <div className="section-2--details">
            <div>{peripheral?.cdst ? 'ON' : 'OFF'}</div>
            <div>{DEVICE_LABELS.fanr}</div>
            <div>{Convertions.secToHours(peripheral?.frte)}</div>
          </div>
        </Grid>
        <Grid container spacing={8} direction="row" className="section-3">
          <Grid item xs={12} md={6}>
            <CustomSlider
              id={isVentConnect(peripheral) ? 'vcfr' : 'effr'}
              label={DEVICE_LABELS[isVentConnect(peripheral) ? 'vcfr' : 'effr']}
              value={
                peripheral?.effr || peripheral?.vcfr
                  ? peripheral[isVentConnect(peripheral) ? 'vcfr' : 'effr']
                  : 0
              }
              onChange={customHandleChange.bind(
                null,
                isVentConnect(peripheral) ? 'vcfr' : 'effr',
              )}
              max={250}
              min={0}
              step={1}
            />
            <CustomSlider
              id={isVentConnect(peripheral) ? 'mvcr' : 'mner'}
              label={DEVICE_LABELS[isVentConnect(peripheral) ? 'mvcr' : 'mner']}
              value={
                peripheral?.mner || peripheral?.mvcr
                  ? peripheral[isVentConnect(peripheral) ? 'mvcr' : 'mner']
                  : 0
              }
              onChange={customHandleChange.bind(
                null,
                isVentConnect(peripheral) ? 'mvcr' : 'mner',
              )}
              max={60}
              min={0}
              step={1}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomSlider
              id={isVentConnect(peripheral) ? 'vcdt' : 'efdt'}
              label={DEVICE_LABELS[isVentConnect(peripheral) ? 'vcdt' : 'efdt']}
              value={
                peripheral?.efdt || peripheral?.vcdt
                  ? peripheral[isVentConnect(peripheral) ? 'vcdt' : 'efdt']
                  : 0
              }
              onChange={customHandleChange.bind(
                null,
                isVentConnect(peripheral) ? 'vcdt' : 'efdt',
              )}
              max={60}
              min={0}
              step={1}
            />
            <CustomSlider
              id="cpri"
              label={DEVICE_LABELS.cpri}
              value={peripheral?.cpri ? peripheral.cpri : 0}
              onChange={customHandleChange.bind(null, 'cpri')}
              max={7}
              min={0}
              step={1}
            />
          </Grid>
        </Grid>
        <Grid container className="section-4">
          <Grid item xs={12} md={6}>
            <CustomSwitch
              id="slst"
              label={DEVICE_LABELS.slst}
              value={
                peripheral?.hasOwnProperty('slst') ? !!peripheral.slst : false
              }
              onChange={handleChangeSwitch.bind(null, 'slst')}
            />
            <PeripheralOptional
              id="exct"
              label={DEVICE_LABELS.exct}
              value={peripheral?.hasOwnProperty('exct') ? peripheral.exct : 0}
              options={[
                { value: 1, label: 'Yes' },
                { value: 0, label: 'No' },
              ]}
              onChange={handleChangeOptional}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomSwitch
              id="exwm"
              label={DEVICE_LABELS.exwm}
              value={
                peripheral?.hasOwnProperty('exwm') ? !!peripheral.exwm : false
              }
              onChange={handleChangeSwitch.bind(null, 'exwm')}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className="section-100"
        >
          <Grid item xs={12} md={6}>
            <div className="d-flex pt-4 pb-2">
              <Button
                variant="primary"
                className="btn  btn-primary btn-block"
                type="button"
                disabled={isLoading}
                onClick={handleSavePeripheralSetup}
              >
                {config.strings.buttons.confirm}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <SweetAlert
        warning
        show={showUpdateAlert}
        showCancel
        confirmBtnText={config?.alerts?.setup?.btn?.confirm}
        confirmBtnBsStyle="warning"
        title={`${config?.alerts?.setup?.question}`}
        onConfirm={savePeripheralSetup}
        onCancel={() => setShowUpdateAlert(false)}
        focusCancelBtn
      />
      <Dialog
        id="device-menu--dialog"
        open={showAbout}
        onClose={() => {
          setShowAbout(false);
        }}
      >
        <DialogTitle>{config?.strings?.titles?.dialog}</DialogTitle>
        <DialogContent>
          <div className="section-2--core">
            <div className="section-2--prop">
              <label>{DEVICE_LABELS.fmwv}</label>
              <span>{peripheral?.fmwv ? peripheral.fmwv : 'N/A'}</span>
            </div>
            <div className="section-2--prop">
              <label>{DEVICE_LABELS.pcbv}</label>
              <span>{peripheral?.pcbv ? peripheral.pcbv : 'N/A'}</span>
            </div>
            <div className="section-2--prop">
              <label>{DEVICE_LABELS.mac}</label>
              <span>{peripheral?.dmac ? peripheral.dmac : 'N/A'}</span>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            className="btn btn-default"
            onClick={() => {
              setShowAbout(false);
            }}
          >
            Done
          </button>
        </DialogActions>
      </Dialog>
    </InstallerTemplate>
  );
};

function mapStateToProps(state: any) {
  const { loader, devices } = state;
  return {
    isLoading: loader.loading,
    peripheral: devices.peripheral,
    device: devices.currentDevice,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { loader, devices, logs } = dispatch;
  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    setPeripheral: devices.setPeripheral,
    reqDeviceDetails: devices.getDeviceDetails,
    updateDevice: devices.editDevice,
    setCurrentDevice: devices.setCurrentDevice,
    getDeviceLogs: logs.getDeviceLogs,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PeripheralSetup);
