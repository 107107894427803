// @ts-nocheck
import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Tooltip,
} from '@material-ui/core';
import moment from 'moment';
import _ from 'lodash';
import { ConnectedIcon, DisconnectedIcon } from 'business/assets';
import { IconsHandler } from 'core/utils';
import { ZonesIcon } from 'business/assets/zones-icons';
import './styles.scss';

interface CustomListProps {
  name?: string;
  items?: any;
  rowType?: string;
  onRowClick?: any;
  config?: any;
  status?: any;
  Component?: any;
  HeaderComponent?: any;
  RowIcon?: any;
  localSensorData?: any;
}

// configurations example
const conf = {
  text: ['device_name', 'details.id'],
  avatars: ['active'],
  status: {
    props: '',
    activeState: '',
  },
  actions: [],
};

const CustomList: React.FC<CustomListProps> = ({
  name,
  items,
  rowType,
  onRowClick,
  config,
  status,
  Component,
  HeaderComponent,
  RowIcon,
  localSensorData,
  ...props
}) => {
  const mapIconClass = (type: string) => {
    switch (type) {
      case 'awair':
      case 'awair-element':
      case 'awair-omni':
        return 'st-awair';
      default:
        return '';
    }
  };

  const mapRowIconSize = (name?: string) => {
    switch (name) {
      case 'configs-list':
        return '2em';
      default:
        return '1em';
    }
  };

  const deviceIcon = (type: string) => {
    const Icon: any = IconsHandler.deviceIconByType(type);

    return <Icon className={mapIconClass(type)} />;
  };

  const mapKeyProps = (keyProp: string, keyData: any, value: any) => {
    const { type }: any = keyData || {};

    switch (type) {
      case 'date':
        const { format }: any = keyData || {};
        return {
          value: moment(new Date(value)).format(format),
          class: 'create-at--col',
        };
      default:
        return null;
    }
  };

  const isLocalSensor = (sensorData: any) => {
    const { name, status }: any = sensorData?.details || {};
    return name === 'LOCAL' || status?.includes('local');
  };

  const discoverLocalSensorData = (data: any, id: string) => {
    if(data && data.hasOwnProperty(id)){
      let sensorDataCopy: any = _.cloneDeep(data[id]);
      if(sensorDataCopy){
        const { sensor, system }: any = sensorDataCopy || {};

        if(system !== null && system !== undefined){
          const { device_name }: any = system || {};
          return `(LOCAL - ${device_name || ''})`;
        }

        return isLocalSensor ? '(LOCAL)' : null;
      }
    }
    return;
  };


  const mapConfigText = (
    key: string,
    data: any,
    indexs: any,
    localData?: any,
  ) => {
    const { index, i }: any = indexs || {};

    switch (key) {
      case 'device_name':
        return (
          <ListItemText key={`row-${index}-${i}`}>
            {data[key]}
          </ListItemText>
        );
      case 'system':
        if(localData){
          return (
            <ListItemText
              key={`row-${index}-${i}`}
              className="ra-local-sensor"
            >
              {localData}
            </ListItemText>
          );
        }
        return null;
      default:
        return null;
    }
  };





  return (
    <>
      {HeaderComponent && (
        <div className="master-list-header">
          <HeaderComponent />
        </div>
      )}
      <List id={`${name}-list`} className="air-custom-list">
        {items.map((item: any, index: number) => {
          const { device_id }: any = item || {};
          const localDataSensor: any = discoverLocalSensorData(
            localSensorData, device_id
          );

          return (
            <ListItem
              key={`item-${index}`}
              alignItems="flex-start"
              className={`air-row-${rowType ? rowType : 'item'}`}
              onClick={onRowClick.bind(null, item)}
            >
              <ListItemAvatar>
                {name ? (
                  name.includes('device') ? (
                    deviceIcon(item?.device_type)
                  ) : RowIcon ? (
                    <RowIcon size={mapRowIconSize(name)} />
                  ) : (
                    <ZonesIcon />
                  )
                ) : (
                  <></>
                )}
              </ListItemAvatar>
              {config?.text &&
                config.text.map((key: string, i: number) => {
                  return mapConfigText(
                    key,
                    item,
                    {index, i},
                    localDataSensor
                  );
                })}
              {config &&
                Object.keys(config).length > 0 &&
                Object.keys(config).map((keyProp: string, index: number) => {
                  if (keyProp !== 'text') {
                    const keyMapResult: any = mapKeyProps(
                      keyProp,
                      config[keyProp],
                      item[keyProp],
                    );
                    if (keyMapResult) {
                      return (
                        <div
                          key={`${keyProp}-${index}`}
                          className={keyMapResult.class}
                        >
                          {keyMapResult.value}
                        </div>
                      );
                    }
                  }
                  return null;
                })}
              {status && (
                <ListItemAvatar>
                  <Tooltip
                    title={
                      item[config?.status?.prop] === config?.status?.activeState
                        ? 'online'
                        : 'offline'
                    }
                    placement="top"
                    sx={{ top: 6 }}
                  >
                    {item[config?.status?.prop] ===
                    config?.status?.activeState ? (
                      <ConnectedIcon className="status-icon" />
                    ) : (
                      <DisconnectedIcon className="status-icon" />
                    )}
                  </Tooltip>
                </ListItemAvatar>
              )}
              {Component && (
                <ListItemSecondaryAction>
                  <Component data={item} />
                </ListItemSecondaryAction>
              )}
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export default CustomList;
