export default {
  strings: {
    title: 'Foobot Connection',
    confirm: 'Connect',
  },
  alerts: {},
  notifications: {
    success: 'Successfully connected to Foobot.',
    warning: 'Something wrong happened. Please try again later.',
    error: 'Something wrong happened. Please try again later.',
  },
  fieldsState: {
    username: '',
    apikey: '',
  },
  fieldsFocus: {},
  fields: {
    username: {
      label: 'Username',
      type: 'text',
      placeholder: 'Username',
    },
    apikey: {
      label: 'Api Key',
      type: 'text',
      placeholder: 'Api Key',
    },
  },
  constraints: {
    username: {
      presence: true,
      length: {
        minimum: 2,
      },
    },
    apikey: {
      presence: true,
      length: {
        minimum: 10,
      },
    },
  },
};
