import React, { useState } from 'react';
import config from './config';
import './styles.scss';

interface EmptyListProps {
  title: string;
}

const EmptyList: React.FC<EmptyListProps> = ({ title, children, ...props }) => {
  const [configuration, setConfiguration]: any = useState({ ...config });

  return (
    <div id="ac-empty-list">
      <div className="container">
        {children ? (
          children
        ) : (
          <p>
            {configuration?.strings?.title[title]
              ? configuration.strings.title[title]
              : configuration.strings.title.other}
          </p>
        )}
      </div>
    </div>
  );
};

export default EmptyList;
