import React from 'react';
import { AirCyclerLogoSVG } from '../../../../../../../../assets/device-icons';
import { FaFan } from 'react-icons/fa';

const returnPillValue = (device: any, id: string) => {
  switch (id) {
    case 'fcst':
      return device?.details?.settings?.conf?.fcst === 1 ? (
        <span className="badge pill-on">ON</span>
      ) : (
        <span className="badge pill-off">OFF</span>
      );
    case 'dmst':
      return device?.details?.settings?.conf?.dmst === 1 ? (
        <span className="badge pill-on">OPEN</span>
      ) : (
        <span className="badge pill-off">CLOSED</span>
      );
    case 'crhr':
      return (
        <span className="badge pill-neutral">
          {device?.details?.settings?.conf?.crhr} %
        </span>
      );
    case 'ctmp':
      return (
        <span className="badge pill-neutral">
          {device?.details?.settings?.conf?.ctmp} F
        </span>
      );
    case 'cflw':
      return (
        <span className="badge pill-neutral">
          {device?.details?.settings?.conf?.cflw} ft <sup>3</sup>
        </span>
      );
    default:
      break;
  }
};

const defineDevType = (data: any) => {
  const { cdid }: any = data || {};
  let devType: string = '';

  if (cdid && cdid !== '') {
    if (cdid.startsWith('AirCyclerVC')) {
      devType = 'vent';
    } else if (cdid.startsWith('AirCyclerFH')) {
      devType = 'fresh';
    } else if (cdid.startsWith('AirCyclerPC')) {
      devType = 'press';
    }
  }

  return devType;
};

const parseConnectedDeviceIndex = (devData: any) => {
  const { cidx }: any = devData || {};
  let parsedValue: number = 0;

  if (cidx !== null && cidx !== undefined) {
    if (typeof cidx === 'string') parsedValue = parseInt(cidx);
    else parsedValue = cidx;
  }

  return parsedValue;
};

const prepareConnectVents = (_system: any) => {
  const { settings } = _system?.details || {};

  let hasFanConnect: boolean =
    settings?.conf?.fcsp === 1 || settings?.conf?.fcsp === '1';
  let tempFreshConnect: any;
  let tempVentConnect: any;
  let tempPressConnect: any;

  let ventConnects: any =
    settings?.conf?.cdvs?.length > 0
      ? settings.conf.cdvs.map((ventConn: any, index: number) => {
          let connectDeviceType: string = defineDevType(ventConn);
          let tempIndex: any = parseConnectedDeviceIndex(ventConn);
          const { cdid } = ventConn;

          if (connectDeviceType && connectDeviceType === 'fresh') {
            if (!tempFreshConnect) tempFreshConnect = {};
            tempFreshConnect[cdid] = false;
          } else if (connectDeviceType && connectDeviceType === 'vent') {
            if (!tempVentConnect) tempVentConnect = {};
            tempVentConnect[cdid] = false;
          } else if (connectDeviceType && connectDeviceType === 'press') {
            if (!tempPressConnect) tempPressConnect = {};
            tempPressConnect[cdid] = false;
          }

          return {
            ...ventConn,
            type:
              connectDeviceType && connectDeviceType !== ''
                ? connectDeviceType
                : 'vent',
            index: tempIndex + 1,
          };
        })
      : [];

  if (hasFanConnect) {
    ventConnects.unshift({
      type: 'fan',
      isWifiConn: 'active',
      cdid: 'FanConnect',
      cail: '',
    });
  }
  return ventConnects;
};

const returnIcon = (periph: any) => {
  if (periph.hasOwnProperty('cdid') && periph.cdid.startsWith('AirCyclerAQ')) {
    return <AirCyclerLogoSVG style={{ width: '20px', height: '20px' }} />;
  } else {
    if (periph.hasOwnProperty('dmac')) {
      return <FaFan style={{ marginRight: '0.1rem' }} />;
    } else if (periph.hasOwnProperty('type') && periph.type === 'fan') {
      return <FaFan style={{ marginRight: '0.1rem' }} />;
    }
  }
};

const returnPeriphDesignation = (periph: any) => {
  if (periph.hasOwnProperty('cdid') && periph.cdid.startsWith('AirCyclerAQ')) {
    return periph.cdid;
  } else {
    if (periph.hasOwnProperty('dmac')) {
      return periph?.cail ? periph.cail : periph.cdid;
    } else if (periph.hasOwnProperty('type') && periph.type === 'fan') {
      return periph?.cdid;
    }
  }
};

export const helpers = {
  returnPillValue: returnPillValue,
  prepareConnectVents: prepareConnectVents,
  defineDevType: defineDevType,
  returnPeriphDesignation: returnPeriphDesignation,
  returnIcon: returnIcon,
};
