import React from 'react';
import config from '../../../../config/config';


const Footer: React.FC = () => (
  <footer className="pt-5 pb-5 border-top">
    <div className="row">
      <div className="col-12 col-md text-center">
        © {config?.strings?.year} AirCycler. All Rights Reserved.
      </div>
    </div>
  </footer>
);

export default Footer;
