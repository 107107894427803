import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import './styles.scss';



interface ActionsMenuProps {
  data?: any;
  functions?: any;
}

const ActionsMenu: React.FC<ActionsMenuProps> = ({
  data,
  functions,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);




  const onclickActions = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickOption = (_function: any, event: any) => {
    _function(event);
    handleClose();
  };



  return (
    <div>
      <IconButton
        id="dev-settings-more-button"
        aria-controls={`actions-menu`}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={onclickActions}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="dev-settings-actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'more-button',
        }}
      >
        {data && data.length > 0 ? data.map((item: any) => {
          const { id, text, _function }: any = item || {};

          if(
            _function &&
            _function !== '' &&
            functions.hasOwnProperty(_function)
          ){
            return (
              <MenuItem
                key={`option--${id}`}
                onClick={(event: any) => {
                  onClickOption(functions[_function], event);
                }}
              >
                {text}
              </MenuItem>
            );
          }

          return <></>;
        }) : <></>}
      </Menu>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {};
}

function mapDispatchToProps(dispatch: any) {
  return {
    setCurrentDevice: dispatch.devices.setCurrentDevice,
    setPeripheral: dispatch.devices.setPeripheral,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionsMenu);
