// export default
const utils = {
  connectedDevicesTypes: {
    VENT_CONNECT: 1,
    FRESH_CONNECT: 2,
    PRESSURE_CONNECT: 3,
  },
  checkTyoe: (data: any) => {
    const self: any = utils;
    const { cdid }: any = data || {};
    let devType: number = self.checkConnDeviceType(cdid);
    let result: any = {
      devType,
    };

    switch (devType) {
      case 2:
        return {
          ...result,
          statesFunc: 'freshConnectLightStates',
          titleFunc: 'wrappFreshTitle',
          version: cdid && cdid.startsWith('AirCyclerFT') ? 2 : 1,
        };
      case 1:
      default:
        return {
          ...result,
          statesFunc: 'ventConnectLightStates',
          titleFunc: 'wrappVentTitle',
          version: null,
        };
    }
  },
  fanLightStates: (data: any) => {
    const { cdst, fcst } = data || {};
    let initState: any = {
      fan: false,
      light: false,
    };

    switch (fcst || cdst) {
      case 0:
      case '0':
        break;
      case 1:
      case '1':
        initState.fan = true;
        initState.light = true;
        break;
      case 2:
      case '2':
      case 3:
      case '3':
        initState.fan = true;
        break;
      default:
        break;
    }
    return initState;
  },
  centralFanStates: (data: any) => {
    const { conf, thsa }: any = data || {};
    let sourceState: string = '';
    let fanState: any = 0;

    if(conf !== null && conf !== undefined){
      const { cfst }: any = conf || {};

      sourceState = cfst === 1 ? 'relay' : 'thsa';
      fanState = cfst === 1 ? 1 : (thsa === 1 ? 1 : 0);
    }

    return { fanState, sourceState };
  },
  ventConnectLightStates: (data: any) => {
    const self: any = utils;
    const { cdst, cdid } = data || {};
    let initState: any = {
      fan: false,
      light: false,
    };

    switch (cdst) {
      case 0:
      case '0':
        break;
      case 1:
      case '1':
        initState.fan = true;
        initState.light = true;
        break;
      case 2:
      case '2':
      case 3:
      case '3':
        initState.fan = true;
        break;
      case 4:
      case '4':
        initState.light = true;
        break;
      default:
        break;
    }

    return initState;
  },
  freshConnectLightStates: (data: any) => {
    const self: any = utils;
    const { cdst } = data || {};
    let initState: any = { fan: false };

    switch (cdst) {
      case 1:
      case '1':
      case 2:
      case '2':
      case 3:
      case '3':
        initState.fan = true;
        break;
      case 0:
      case '0':
      default:
        break;
    }

    return initState;
  },
  checkConnDeviceType: (devId: any) => {
    const self: any = utils;
    let devType: number = 0;

    if(devId && devId !== ''){
      if(devId.startsWith('AirCyclerVC')){
        devType = self.connectedDevicesTypes.VENT_CONNECT;
      }else if(devId.startsWith('AirCyclerFH') || devId.startsWith('AirCyclerFT')){
        devType = self.connectedDevicesTypes.FRESH_CONNECT;
      }else if(devId.startsWith('AirCyclerPC')){
        devType = self.connectedDevicesTypes.PRESSURE_CONNECT;
      }else{
        devType = 0;
      }
    }
    return devType;
  },
}

export default utils;
