import React from 'react';
import {
  HiOutlineUserGroup,
  HiOutlineHome,
  HiOutlinePhone,
} from 'react-icons/hi';
import { BsPerson } from 'react-icons/bs';
import { AiOutlineMail } from 'react-icons/ai';

const config = {
  strings: {
    pageTitle: 'Dashboard',
    title: 'Order list',
    title1: 'Users List',
    noResults: 'No results found...',
    timeSlLabel: '',
    defOrderTitle: 'Order list',
    txt: `Return to default order by clicking here`,
    noData: `No data`,
    noReqStr: `No users to display.`,
    noDevs: `This user has no devices.`,
    accountInfo: `Account info`,
    detailsStatus: `Details status:`,
    peripherals: `Peripherals:`,
    mcSettings: `Manual Control settings`,
    noConfigs: `No configurations for this device.`,
  },
  modalStrings: {
    confirm: ``,
    warning: `Information`,
    alertStr: ``,
    alertStr2: ``,
    confirmation: ``,
    sureToProceed: `Are you sure you wish to proceed?`,
    noUndo: `This action is irreversible.`,
    aboutToDel: `You are about to delete this user.`,
    labelOK: ` OK`,
  },
  menuLabels: {
    firstOption: `View`,
    secondOption: `Delete`,
  },
  resettedCounter: {
    name: 0,
    location: 0,
    count: 0,
  },
  dataHeader: [
    {
      colClass: `users--data-header--1`,
      spanClass: `lead header-label name-span`,
      spanClass2: `lead header-label name-span1`,
      headerDesignationCol: `Name`,
    },
    {
      colClass: `users--data-header--2`,
      spanClass: `lead header-label`,
      spanClass2: `lead header-label mb4-1`,
      headerDesignationCol: `Location`,
    },
    {
      colClass: `users--data-header--3`,
      spanClass: `lead header-label dev-ct`,
      spanClass2: `lead header-label mb4-1`,
      headerDesignationCol: `Devices count`,
    },
    {
      colClass: `users--data-header--4`,
      spanClass: `lead header-label  `,
      spanClass2: `lead header-label   `,
      headerDesignationCol: `Actions`,
    },
  ],
  modal: {
    view: {
      title: `View User`,
      action: ``,
      bodyText: ``,
      btns: {
        labelOk: ``,
        labelCancel: ``,
      },
      modalHeaderId: `view-user1`,
      sectionTitle: `User details`,
      sectionSubtitle: `Some user data`,
    },
    delete: {
      title: `Delete user`,
      action: ``,
      bodyText: ``,
      btns: {
        labelOk: ``,
        labelCancel: ``,
      },
      modalHeaderId: `delete-user1`,
    },
  },
  notifications: {
    delete: {
      success: 'The user has been deleted!',
      error: 'Something went wrong!',
    },
  },
  tooltip: {
    title: ``,
    txt: ``,
  },
  menu: [
    {
      id: `firstOpt`,
      label: `View`,
      dataTarget: '',
      dataToggle: ``,
    },
    {
      id: `secondOpt`,
      label: `Delete`,
      dataTarget: `#delete-reg-user-confirmation`,
      dataToggle: `modal`,
    },
  ],
  rowClasses: {
    col1: `ol-xs-12 col-sm-12 col-md-4 comp-name`,
    span1: `lead lead-adm bolder-ft the-name-with-the-circle1`,
    col2: `col-xs-12 col-sm-12 col-md-3 comp-location`,
    span2: `lead lead-adm location-span2`,
    col3: `col-xs-12 col-sm-12 col-md-2 center--dv--1`,
    span3: `lead lead-adm location-span1`,
    col4: `col-xs-12 col-sm-12 col-md-1 optional-col`,
    span4: ``,
    colMenu: `col-xs-12 col-sm-12 col-md-1 col-lg-2 col-xl-2 btn-vab cent-col-contents1`,
  },
  formInfo: [
    {
      info: 'first_name',
      type: 'text',
      label: 'First name',
      placeholder: '',
      icon: <BsPerson style={{ fontSize: '1.1rem' }} />,
    },
    {
      info: 'last_name',
      type: 'text',
      label: 'Last name',
      placeholder: '',
      icon: <HiOutlineUserGroup style={{ fontSize: '1.1rem' }} />,
    },
    {
      info: 'emails',
      type: 'email',
      label: 'Email',
      placeholder: '',
      icon: <AiOutlineMail style={{ fontSize: '1.1rem' }} />,
    },
    {
      info: 'addresses',
      type: 'text',
      label: 'Address',
      placeholder: '',
      icon: <HiOutlineHome style={{ fontSize: '1.1rem' }} />,
    },
    {
      info: 'phones_numbers',
      type: 'text',
      label: 'Phone number',
      placeholder: '',
      icon: <HiOutlinePhone style={{ fontSize: '1.1rem' }} />,
    },
  ],
  labels: {
    first_name: 'First Name',
    last_name: 'Last Name',
    emails: 'Email',
    addresses: 'Address',
    phones_numbers: 'Phone',
  },
  relevantKeys: [
    'isSystem',
    'device_type',
    'device_name',
    'status',
    'details',
    'device_city',
    'device_state',
    'device_zipcode',
  ],
  keys: {
    isSystem: 'Is system',
    device_type: 'Device type',
    device_name: 'Device name',
    status: 'Status',
    details: <span className="details-span">Details</span>,
    device_city: 'City',
    device_state: 'State',
    device_zipcode: 'Zip code',
  },
  sections: {
    manualCtr: {
      noConfigs: [
        {
          col: `col-12 titles`,
          element: (
            <h5 className="card-title lead card-title-sys">Account info</h5>
          ),
        },
        {
          col: `col-12 titles`,
          element: (
            <h6 className="card-subtitle mb-2 text-muted">
              Manual Control settings
            </h6>
          ),
        },
        {
          col: `col-12 titles`,
          element: <p className="card-text">No configurations found.</p>,
        },
      ],
    },
  },
};

export { config };
