import _ from 'lodash';

const parseNumber = (value='') => {
  return  value.replace(/[^0-9]/g, '') === ''
      ? 0
      : parseInt(value.replace(/[^0-9]/g, ''));
};


const preparePayload = (fieldsData: any, outsideData: any, indoorData: any) => {
  let payload: any = {
    name: 'Default Config Name',
    details: {},
    settings: {},
    setup: {
      outsideAir: outsideData ? { ...outsideData } : {},
      indoorAir: indoorData ? { ...indoorData } : {}
    },
  };

  if(fieldsData && Object.keys(fieldsData).length > 0){
    const { config_name } = fieldsData;
    payload.name = config_name;

    Object.keys(fieldsData).forEach((field: string) => {
      if(field !== 'config_name'){
        payload.settings[field] = fieldsData[field];
      }
    });
  }

  return payload;
};


export {
  parseNumber,
  preparePayload,
};
