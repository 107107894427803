import React from 'react';
import { BsQuestionCircle } from 'react-icons/bs';
import TooltipView from 'react-components/components/Tooltip';
import { RefreshTooltip } from './components';
import './styles.scss';


interface ListHeaderProps {
  onClick?: any;
}


const ListHeader: React.FC<ListHeaderProps> = ({ onClick, ...props }: any) => {
    

  return (
    <div className="main-list-header">
      <div className="list-header--msm-wrapper">
        <div className="list-header-msm">

        </div>
      </div>
      <div className="list-header--btn-wrapper">
        <button
          id="refresh-btn"
          type="button"
          className="small-btn btn-rounded"
          onClick={onClick}
        >
          Refresh
        </button>
        <TooltipView title={<RefreshTooltip />} >
          <div className="helper--wrapper">
            <BsQuestionCircle
              className="widget--title-helper-icon"
              size="1rem"
            />
          </div>
        </TooltipView>
      </div>
    </div>
  );
};

export default ListHeader;
