import React from 'react';


interface TemperatureSymbolProps {
  value?: any;
  scale?: string;
}

const TemperatureSymbol: React.FC<TemperatureSymbolProps> = ({ value, scale }) => {
  return (
    <>
      {`${value} º${scale === 'fahrenheit' ? 'F' : 'C'}`}
    </>
  );
};

export default TemperatureSymbol;
