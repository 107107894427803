import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import {
  AirThingsHome,
  AirThingsConnection,
  AirThingsDisconnect,
} from './pages';

const at_path = '/airthings';

const AirThings: React.FC = ({ ...props }) => {

  return (
    <>
      <BreadcrumbsItem to={`/dashboard${at_path}`}>Airthings</BreadcrumbsItem>
      <Switch>
        <Route exact path={`/dashboard${at_path}`} component={AirThingsHome} />
        <Route
          exact
          path={`/dashboard${at_path}/connection`}
          component={AirThingsConnection}
        />
        <Route
          exact
          path={`/dashboard${at_path}/disconnect`}
          component={AirThingsDisconnect}
        />
      </Switch>
    </>
  );
};

export default AirThings;
