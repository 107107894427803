import React from 'react';


interface FlowSymbolProps {
  value?: any;
}

const FlowSymbol: React.FC<FlowSymbolProps> = ({ value }) => {

  return (
    <>
      {value} ft<sup>3</sup>
    </>
  );
};

export default FlowSymbol;
