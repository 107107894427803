import React, { useState, useEffect, useCallback, Fragment } from 'react';
import config from './config';
import numeral from 'numeral';
import { connect } from 'react-redux';
import { OutsideAirProps } from './interface';
import { helpers } from './helpers';
import { DevicesUtils } from './utils';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { io } from 'socket.io-client';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import {
  TooltipView,
  SwitchBtn,
  Notification,
  GradientSlider,
  DoubleSlider,
  Slider,
  ProbeSlider,
  Modal,
  DetailsHeader,
} from 'business/modules/admin/common';
import { FaExclamation } from 'react-icons/fa';
import { RiSave3Fill } from 'react-icons/ri';
import { BsQuestionCircle } from 'react-icons/bs';
import { MdOutlineAddLocationAlt } from 'react-icons/md';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton';
import { useAuth } from '../../../../../../../sessions/hooks/auth';
import * as globalConfigs from '../../../../../../../../config/config';
import './styles.scss';

const MODEL_FORMAT = { ...config.outsideAir.model };
const WEATHER_REQ_INTERVAL = config.outsideAir.setup.weatherInterval;
const DEFAULT_VALUES: any = { ...config.outsideAir.sliderValues.defaults };
const NOTIFICATIONS: any = { ...config.outsideAir.notifications };
const ST_LABEL: string = config.outsideAir.strings.stLabel;
const DEFAULT_EXTREMS = { ...config.outsideAir.sliderValues };

let INTERVALS: any = [];
let WEB_SOCKETS: any = {};
let REQUEST_STATES: any = {};

const OutsideAir: React.FC<OutsideAirProps> = ({
  sectionData,
  element,
  getAdminWeather,
  toggleUpdateMenuVisibility,
  setDevDetails,
  setCurrentDeviceAdmin,
  completeDev,
  adminWeather,
  setup,
  g3Reads,
  editOutsideAirAdmin,
  outAirChangesMenuIsVisible,
  modalMustClose,
  handleModalState,
  selectedDevice,
  updateOAStates,
  oaWidgetStates,
  updateSwitch,
  oaEnabled,
  outsideAirValuesUpdated,
  getProbeMostRecentValues,
  designation,
  systemsCurrentStatus,
}) => {
  const { adminToken } = useAuth();
  const history = useHistory();
  const [init, setInit] = useState<boolean>(false);
  const [probeStatus, setProbeStatus] = useState<number>(0);
  const [isLocalSet, setIsLocalSet] = useState<boolean>(false);
  const [sysSetup, setSysSetup] = useState<any>({});
  const [probeDataStatus, setProbeDataStatus]: any = useState(false);
  const [expandedCtrl, setIsExpandedCtrl] = useState<boolean>(false);
  const [hasProbeConn, setHasProbeConn]: any = useState(false);
  const [wInterval, setWInterval]: any = useState(null);
  const [onEditMode, setToEditMode] = useState<boolean>(false);
  const [isWeatherInit, setIsWeatherInit] = useState<boolean>(false);
  const [currentDeviceId, setCurrentDeviceId]: any = useState();
  const [enabled, setEnabled] = useState<boolean>(false);
  const [showSaveBtn, setShowSaveBtn]: any = useState(false);
  const [widgetStates, setWidgetStates]: any = useState({
    temp: { ...MODEL_FORMAT },
    hum: { ...MODEL_FORMAT },
    aqi: { ...MODEL_FORMAT },
    cfm: { ...MODEL_FORMAT },
  });
  const [weatherValues, setWeatherValues]: any = useState({
    ctmp: null,
    crhr: null,
  });
  const [probValidValues, setProbValidValues]: any = useState({
    ctmp: null,
    crhr: null,
  });

  const startSocket = async (
    _device: any,
    deviceId: string,
    data: any,
    channel: string,
  ) => {
    const { device_id }: any = _device || {};
    const _token: string = adminToken?.token;
    const { path, process } = data;

    let channelSTR: string = `${channel}-${deviceId}`;

    const socket: any =
      globalConfigs?.default?.apis?.jitBE?.baseSocketURLs &&
      globalConfigs?.default?.apis?.jitBE?.baseSocketURLs[0]
        ? io(globalConfigs?.default?.apis?.jitBE?.baseSocketURLs[0], {
            path: '/socket.io/',
            transports: ['websocket'],
            autoConnect: false,
            withCredentials: true,
            auth: {
              token: _token,
              path: path,
              process: process,
              channel: channel,
              name: deviceId,
              channelName: channelSTR,
            },
          })
        : null;

    if (socket) {
      WEB_SOCKETS[device_id] = {
        socket: socket.connect(),
        device: _device,
        channel: channelSTR,
        connected: false,
      };

      WEB_SOCKETS[device_id].socket.on('connect', () => {
        WEB_SOCKETS[device_id].connected = true;
      });

      WEB_SOCKETS[device_id].socket.on('disconnect', (reason: any) => {});

      WEB_SOCKETS[device_id].socket.on('connect_error', (error: any) => {});

      WEB_SOCKETS[device_id].socket.on(
        WEB_SOCKETS[device_id].channel,
        (data: any) => {
          //Todo: handle setState updates coming from socket
        },
      );
    }
  };

  const destroySocket = (_device: any) => {
    const { device_id }: any = _device || {};

    if (
      WEB_SOCKETS[device_id] !== null &&
      WEB_SOCKETS[device_id] !== undefined
    ) {
      WEB_SOCKETS[device_id].socket.off('connect');
      WEB_SOCKETS[device_id].socket.off('disconnect');
      WEB_SOCKETS[device_id].socket.off('connect_error');

      if (WEB_SOCKETS[device_id].channel) {
        WEB_SOCKETS[device_id].socket.off(WEB_SOCKETS[device_id].channel);
      }

      WEB_SOCKETS[device_id].socket.disconnect();
      WEB_SOCKETS[device_id] = null;

      delete WEB_SOCKETS[device_id];
    }
  };

  const handleAddLocation = () => {
    if (completeDev) {
      localStorage.setItem(
        'selectedDevIdOutsideAirAdmin',
        completeDev?.device_id,
      );

      setDevDetails(completeDev);
    }
    const { device_id } = completeDev;

    setCurrentDeviceAdmin({ ...completeDev });

    history.push(
      `/admin/dashboard/user-details/devices/edit/location/${device_id}`,
    );
  };

  const handleChange = (panel: string) => (event: any, isExpanded: any) => {
    setCurrentDeviceAdmin({ ...completeDev });

    if (isLocalSet) {
      setIsExpandedCtrl(isExpanded);
      localStorage.setItem(`${ST_LABEL}${currentDeviceId}`, isExpanded);
    }
  };

  const toggleSection = (event: any, id: string, element: any) => {
    event.stopPropagation();

    setCurrentDeviceAdmin(element);

    setEnabled(!enabled);

    updateSwitch(!enabled);
    updateOAStates(widgetStates);

    if (!enabled) setShowSaveBtn(true);
    toggleUpdateMenuVisibility({ state: true, section: 'oa' });
  };

  const onChangeTemps = (newValues: any) => {
    toggleUpdateMenuVisibility({ state: true, section: 'oa' });

    setWidgetStates((prevValues: any) => {
      return {
        ...prevValues,
        temp: {
          ...prevValues.temp,
          min: newValues[0],
          max: newValues[2],
        },
      };
    });

    updateOAStates(widgetStates);
    updateSwitch(enabled);

    setShowSaveBtn(true);
    setToEditMode(true);
  };

  const onChangeHumidity = (newValues: any) => {
    toggleUpdateMenuVisibility({ state: true, section: 'oa' });

    setWidgetStates((prevValues: any) => {
      return {
        ...prevValues,
        hum: {
          ...prevValues.hum,
          min: newValues[0],
          max: newValues[1],
        },
      };
    });

    updateOAStates(widgetStates);
    updateSwitch(enabled);

    setShowSaveBtn(true);
    setToEditMode(true);
  };

  const onChangeAqi = (newValues: any) => {
    toggleUpdateMenuVisibility({ state: true, section: 'oa' });

    setWidgetStates((prevValues: any) => {
      return {
        ...prevValues,
        aqi: {
          ...prevValues.aqi,
          min: newValues[0],
          max: newValues[1],
        },
      };
    });

    updateOAStates(widgetStates);
    updateSwitch(enabled);

    setShowSaveBtn(true);
    setToEditMode(true);
  };

  const onChangeProbeThreshold = (newValues: any) => {
    toggleUpdateMenuVisibility({ state: true, section: 'oa' });

    setWidgetStates((prevValues: any) => {
      return {
        ...prevValues,
        cfm: {
          ...prevValues.cfm,
          min: newValues[0],
          max: newValues[1],
        },
      };
    });

    updateOAStates(widgetStates);
    updateSwitch(enabled);

    setShowSaveBtn(true);
    setToEditMode(true);
  };

  const transformSingleSlider = useCallback((values: any) => {
    const { max, min, current } = values;
    return [current, max];
  }, []);

  const transformCfmSliderValues = useCallback((values: any) => {
    const { max, min, current } = values;
    return [current, max];
  }, []);

  const transformDoubleSlider = useCallback((values: any) => {
    const { max, min, current } = values;
    return [min, current, max];
  }, []);

  const onSaveOutsideConfigs = async () => {
    let oaValues: any;
    const updatedDevSettings: any = helpers.prepareValuesToSave(
      oaWidgetStates,
      selectedDevice,
    );

    const setupValues: any = helpers.prepareToSaveSetup(
      oaWidgetStates,
      selectedDevice,
      oaEnabled,
    );

    let deviceClone: any = _.cloneDeep({ ...selectedDevice });

    deviceClone.details.settings = { ...updatedDevSettings };
    oaValues = {
      setup: setupValues.setup.outsideAir,
      system_id: setupValues.system_id,
    };
    setShowSaveBtn(false);

    const result: any = await editOutsideAirAdmin(oaValues);
    if (result) {
      setSysSetup({ ...result });
      Notification({
        title: 'Success!',
        message: NOTIFICATIONS.update?.success,
        type: 'success',
      });
      toggleUpdateMenuVisibility({ state: false, section: 'oa' });
    }
  };

  const renderProbeInformation = () => {
    return hasProbeConn && probeDataStatus ? 'Flow Probe' : 'Weather Api';
  };

  const initUpdateWeather = async (deviceId: string) => {
    const id: any = localStorage.getItem('currentAccount');
    if (wInterval) clearInterval(wInterval);

    await getAdminWeather({
      device_id: deviceId,
      id: id,
    });

    let interval: any = setInterval(() => {
      getAdminWeather({
        device_id: deviceId,
        id: id,
      });
    }, WEATHER_REQ_INTERVAL);

    setWInterval(interval);
    setIsWeatherInit(true);
  };

  const handleAirFlowValue = async (system: any) => {
    const user_account = localStorage.getItem('currentAccount');

    const { device_id }: any = system || {};
    let currentAirFlow: number = 0;

    if (device_id) {
      const probeLastValues: any = await getProbeMostRecentValues({
        id: user_account,
        system_id: device_id,
      });

      if (probeLastValues && probeLastValues?.data) {
        const { cflw }: any = probeLastValues?.data || {};

        currentAirFlow = cflw;
      } else {
        const cfmVal: number = DevicesUtils.getCfmValue(system);
        currentAirFlow = cfmVal;
      }
    }

    setProbValidValues((preValues: any) => {
      return {
        ...preValues,
        cflw: currentAirFlow,
      };
    });
  };

  useEffect(() => {
    if (enabled && helpers.valIsDefined(currentDeviceId)) {
      let storedState: any = localStorage.getItem(
        `${ST_LABEL}${currentDeviceId}`,
      );
      if (helpers.valIsDefined(storedState)) {
        setIsExpandedCtrl(storedState === 'true');
      }
    }
  }, [enabled, currentDeviceId]);

  useEffect(() => {
    if (completeDev?.device_id) {
      const result: any = DevicesUtils.canRequestWeather(completeDev);

      if (completeDev?.device_id?.details?.settings?.pcst === 1) {
        // get the aqi value

        getAdminWeather({
          device_id: completeDev.device_id,
          id: localStorage.getItem('currentAccount'),
        });
      }
      const probeResult: any = DevicesUtils.isProbeConnected(completeDev);
      const { connected, validData, _probeStatus } = probeResult;

      setHasProbeConn(connected);
      setProbeDataStatus(validData);
      setProbeStatus(_probeStatus);
      setCurrentDeviceId(completeDev.device_id);

      if (result.ok && !connected) {
        const { device_id } = result;

        if (!isWeatherInit) {
          initUpdateWeather(device_id);
        }
      }
      setIsLocalSet(result.ok);
    }
    return () => {};
  }, [completeDev?.device_id]);

  useEffect(() => {
    if (probValidValues?.cflw) {
      setWidgetStates((prevValues: any) => {
        return {
          ...prevValues,
          cfm: {
            ...prevValues.cfm,
            current: probValidValues.cflw,
          },
        };
      });
    }
  }, [probValidValues]);

  useEffect(() => {
    if (sysSetup) {
      if (
        sysSetup?.body?.data?.setup !== undefined &&
        sysSetup?.body?.data?.setup.hasOwnProperty('outsideAir')
      ) {
        const { turnedOn } = sysSetup?.body?.data?.setup?.outsideAir;
        if (turnedOn !== null && turnedOn !== undefined) {
          setEnabled(turnedOn);
        }
      }
    }
  }, [sysSetup]);

  useEffect(() => {
    if (setup) {
      setSysSetup({ ...setup });
    }

    return () => {};
  }, [setup]);

  useEffect(() => {
    return () => {
      if (wInterval) {
        clearInterval(wInterval);
        setWInterval(null);
      }
    };
  }, [wInterval]);

  useEffect(() => {
    if (hasProbeConn) {
      handleAirFlowValue(completeDev);
    }

    return () => {};
  }, [hasProbeConn]);

  useEffect(() => {
    setInit(true);
    return () => {};
  }, []);

  useEffect(() => {
    if (!enabled && init) {
      onSaveOutsideConfigs();
    }
  }, [enabled]);

  //Setting the new states after updates are made:
  useEffect(() => {
    if (outsideAirValuesUpdated) {
      if (adminWeather[completeDev?.device_id]) {
        const { weather, airQuality } = adminWeather[completeDev?.device_id];

        const cfmVal: number = DevicesUtils.getCfmValue(element);
        let extractedValues: any = DevicesUtils.extractFields(
          weather,
          airQuality,
          cfmVal,
        );

        if (extractedValues) {
          let weatherValuesClone: any = _.cloneDeep(weatherValues);

          if (extractedValues.hasOwnProperty('temp')) {
            weatherValuesClone.ctmp = extractedValues.temp;
          }
          if (extractedValues.hasOwnProperty('hum')) {
            weatherValuesClone.crhr = extractedValues.hum;
          }

          setWeatherValues(weatherValuesClone);
        }

        let tempExtrems: any = DevicesUtils.getTempSetup(
          setup?.body?.data?.setup?.outsideAir || {},
        );

        let humExtrems: any = DevicesUtils.getHumSetup(
          setup?.body?.data?.setup?.outsideAir || {},
        );

        let aqiExtrems: any = DevicesUtils.getAqiSetup(
          setup?.body?.data?.setup?.outsideAir || {},
        );

        let probExtrems: any = DevicesUtils.getCfmSetup(
          setup?.body?.data?.setup?.outsideAir || {},
        );

        let mergeResult: any = DevicesUtils.mergeExtWithState(
          extractedValues,
          { ...widgetStates },
          'current',
        );

        let packResult: any = DevicesUtils.packageFields(mergeResult);

        let tempPackExt: any = DevicesUtils.packageByPointers(
          'temp',
          ['min', 'max'],
          tempExtrems,
        );

        if (tempPackExt && tempPackExt.length > 0 && !onEditMode) {
          packResult.data = packResult.data.concat([...tempPackExt]);
        }

        let humPackExt: any = DevicesUtils.packageByPointers(
          'hum',
          ['max'],
          humExtrems,
        );

        if (humPackExt && humPackExt.length > 0 && !onEditMode) {
          packResult.data = packResult.data.concat([...humPackExt]);
        }

        let probPackExt: any = DevicesUtils.packageByPointers(
          'cfm',
          ['max'],
          probExtrems,
        );

        if (probPackExt && probPackExt.length > 0 && !onEditMode) {
          packResult.data = packResult.data.concat([...probPackExt]);
        }

        let aqiPackExt: any = DevicesUtils.packageByPointers(
          'aqi',
          ['max'],
          aqiExtrems,
        );

        if (aqiPackExt && aqiPackExt.length > 0 && !onEditMode) {
          packResult.data = packResult.data.concat([...aqiPackExt]);
        }

        if (
          !!g3Reads[completeDev?.device_id] &&
          hasProbeConn &&
          probeDataStatus
        ) {
          const { ctmp, crhr } = g3Reads[completeDev.device_id];

          if (helpers.valIsDefined(ctmp)) {
            let tempIndex: number = packResult.data.findIndex(
              (item: any) =>
                item._prop === 'temp' && item.pointer === 'current',
            );

            if (tempIndex > -1) {
              packResult.data[tempIndex].value = ctmp;
            }
          }

          if (helpers.valIsDefined(crhr)) {
            let humIndex: number = packResult.data.findIndex(
              (item: any) => item._prop === 'hum' && item.pointer === 'current',
            );
            if (humIndex > -1) {
              packResult.data[humIndex].value = crhr;
            }
          }
        }

        setWidgetStates((prevValues: any) => {
          let valuesClone: any = _.cloneDeep(prevValues);

          if (packResult && packResult?.data && packResult.data.length > 0) {
            packResult.data.forEach((item: any) => {
              const { _prop, pointer, value, init } = item;
              if (valuesClone && valuesClone.hasOwnProperty(_prop)) {
                if (
                  pointer === 'max' ||
                  (_prop === 'temp' && pointer === 'min')
                ) {
                  valuesClone[_prop][pointer] =
                    value === -101 ? DEFAULT_VALUES[_prop][pointer] : value;
                } else {
                  valuesClone[_prop][pointer] = value;
                }
                valuesClone[_prop].init = init;
              }
            });
          }

          return { ...valuesClone };
        });

        return () => {};
      }
    }
  }, [outsideAirValuesUpdated]);

  useEffect(() => {
    if (init) {
      (async () => {
        if (
          setup &&
          Object.keys(setup).length > 0 &&
          setup?.body?.data?.hasOwnProperty(setup) &&
          Object.keys(setup?.body?.data?.setup).length > 0
        ) {
          if (!WEB_SOCKETS.hasOwnProperty(element?.device_id)) {
            startSocket(
              element,
              element?.device_id,
              { path: 'notifications', process: 'outsideAir' },
              `Admin-Setup-Update-outsideAir`,
            );
          }
        }
      })();
    }
    return () => {};
  }, [init, sysSetup]);

  useEffect(() => {
    return () => {
      if (INTERVALS && INTERVALS.length > 0) {
        for (const interval of INTERVALS) {
          clearInterval(interval);
        }
        INTERVALS = [];
      }

      if (REQUEST_STATES && Object.keys(REQUEST_STATES).length > 0) {
        for (const key in REQUEST_STATES) {
          REQUEST_STATES[key] = false;
        }
      }

      destroySocket(element);
      setInit(false);
    };
  }, []);

  useEffect(() => {
    if (adminWeather[completeDev?.device_id]) {
      const { weather, airQuality } = adminWeather[completeDev?.device_id];

      const cfmVal: number = DevicesUtils.getCfmValue(element);
      let extractedValues: any = DevicesUtils.extractFields(
        weather,
        airQuality,
        cfmVal,
      );

      if (extractedValues) {
        let weatherValuesClone: any = _.cloneDeep(weatherValues);

        if (extractedValues.hasOwnProperty('temp')) {
          weatherValuesClone.ctmp = extractedValues.temp;
        }

        if (extractedValues.hasOwnProperty('hum')) {
          weatherValuesClone.crhr = extractedValues.hum;
        }

        setWeatherValues(weatherValuesClone);
      }

      let tempExtrems: any = DevicesUtils.getTempSetup(
        setup?.body?.data?.setup?.outsideAir || {},
      );

      let humExtrems: any = DevicesUtils.getHumSetup(
        setup?.body?.data?.setup?.outsideAir || {},
      );

      let aqiExrtrems: any = DevicesUtils.getAqiSetup(
        setup?.body?.data?.setup?.outsideAir || {},
      );

      let probExtrems: any = DevicesUtils.getCfmSetup(
        setup?.body?.data?.setup?.outsideAir || {},
      );

      let mergeResult: any = DevicesUtils.mergeExtWithState(
        extractedValues,
        { ...widgetStates },
        'current',
      );

      let packResult: any = DevicesUtils.packageFields(mergeResult);

      let tempPackExt: any = DevicesUtils.packageByPointers(
        'temp',
        ['min', 'max'],
        tempExtrems,
      );

      if (tempPackExt && tempPackExt.length > 0 && !onEditMode) {
        packResult.data = packResult.data.concat([...tempPackExt]);
      }

      let humPackExt: any = DevicesUtils.packageByPointers(
        'hum',
        ['max'],
        humExtrems,
      );

      if (humPackExt && humPackExt.length > 0 && !onEditMode) {
        packResult.data = packResult.data.concat([...humPackExt]);
      }

      let probPackExt: any = DevicesUtils.packageByPointers(
        'cfm',
        ['max'],
        probExtrems,
      );

      if (probPackExt && probPackExt.length > 0 && !onEditMode) {
        packResult.data = packResult.data.concat([...probPackExt]);
      }

      let aqiPackExt: any = DevicesUtils.packageByPointers(
        'aqi',
        ['max'],
        aqiExrtrems,
      );

      if (aqiPackExt && aqiPackExt.length > 0 && !onEditMode) {
        packResult.data = packResult.data.concat([...aqiPackExt]);
      }

      if (
        !!g3Reads[completeDev?.device_id] &&
        hasProbeConn &&
        probeDataStatus
      ) {
        const { ctmp, crhr } = g3Reads[completeDev.device_id];

        if (helpers.valIsDefined(ctmp)) {
          let tempIndex: number = packResult.data.findIndex(
            (item: any) => item._prop === 'temp' && item.pointer === 'current',
          );

          if (tempIndex > -1) {
            packResult.data[tempIndex].value = ctmp;
          }
        }

        if (helpers.valIsDefined(crhr)) {
          let humIndex: number = packResult.data.findIndex(
            (item: any) => item._prop === 'hum' && item.pointer === 'current',
          );
          if (humIndex > -1) {
            packResult.data[humIndex].value = crhr;
          }
        }
      }

      setWidgetStates((prevValues: any) => {
        let valuesClone: any = _.cloneDeep(prevValues);

        if (packResult && packResult?.data && packResult.data.length > 0) {
          packResult.data.forEach((item: any) => {
            const { _prop, pointer, value, init } = item;
            if (valuesClone && valuesClone.hasOwnProperty(_prop)) {
              if (
                pointer === 'max' ||
                (_prop === 'temp' && pointer === 'min')
              ) {
                valuesClone[_prop][pointer] =
                  value === -101 ? DEFAULT_VALUES[_prop][pointer] : value;
              } else {
                valuesClone[_prop][pointer] = value;
              }
              valuesClone[_prop].init = init;
            }
          });
        }

        return { ...valuesClone };
      });
    }
    return () => {};
  }, [adminWeather]);

  useEffect(() => {
    if (hasProbeConn && probeDataStatus && g3Reads[completeDev?.device_id]) {
      const { ctmp, crhr } = g3Reads[completeDev?.device_id];

      const tempValue: any = helpers.processSpecialProp(
        'ctmp',
        g3Reads[completeDev?.device_id],
        probValidValues,
      );

      const humValue: any = helpers.processSpecialProp(
        'crhr',
        g3Reads[completeDev?.device_id],
        probValidValues,
      );
      setProbValidValues((preValues: any) => {
        return {
          ...preValues,
          ctmp: tempValue,
          crhr: humValue,
        };
      });
      setWidgetStates((prevValues: any) => {
        return {
          ...prevValues,
          temp: {
            ...prevValues.temp,
            current: tempValue,
          },
          hum: {
            ...prevValues.hum,
            current: humValue,
          },
        };
      });
    } else {
      setWidgetStates((prevValues: any) => {
        return {
          ...prevValues,
          temp: {
            ...prevValues.temp,
            current: weatherValues.ctmp
              ? weatherValues.ctmp
              : prevValues?.temp?.current || -99,
          },
          hum: {
            ...prevValues.hum,
            current: weatherValues.crhr
              ? weatherValues.crhr
              : prevValues?.hum?.current || -1,
          },
        };
      });
    }
    return () => {};
  }, [g3Reads, outsideAirValuesUpdated]);

  useEffect(() => {
    if (
      completeDev?.details?.settings &&
      completeDev?.details?.settings?.conf
    ) {
      const tempVal: number = helpers.processSpecialProp(
        'ctmp',
        completeDev?.details?.settings?.conf,
        probValidValues,
      );

      const humVal: number = helpers.processSpecialProp(
        'ctmp',
        completeDev?.details?.settings?.conf,
        probValidValues,
      );

      setProbValidValues((preValues: any) => {
        return {
          ...preValues,
          ctmp: tempVal,
          crhr: humVal,
        };
      });
    }
    return () => {};
  }, [completeDev?.details?.settings]);

  return (
    <div
      style={{ marginBottom: '16px', marginTop: '16px' }}
      onClick={() => {
        setCurrentDeviceAdmin({ ...completeDev });
      }}
    >
      <DetailsHeader
        designation={designation}
        sensorProp={''}
        icon={
          systemsCurrentStatus &&
          Object.keys(systemsCurrentStatus).length > 0 &&
          systemsCurrentStatus.hasOwnProperty(element?.device_id) &&
          systemsCurrentStatus[element?.device_id].statusIsLoaded &&
          systemsCurrentStatus[element?.device_id].stopped ? (
            <TooltipView title="Disconnected">
              <span>
                <div className="circle-excl">
                  <FaExclamation
                    size="0.8rem"
                    color="red"
                    className="system-status-icon"
                  />
                </div>
              </span>
            </TooltipView>
          ) : null
        }
      />
      <div style={{ marginTop: '16px' }}>
        <Accordion
          className={sectionData.className}
          square
          expanded={expandedCtrl && enabled}
          onChange={handleChange(`outside-air-adm`)}
        >
          <AccordionSummary
            expandIcon={isLocalSet ? <ExpandMoreIcon /> : <></>}
            aria-controls={''}
            id={''}
            className={!isLocalSet ? 'no-cursor' : ''}
          >
            <div
              className={`container admin-section-container outside-accordion`}
            >
              <div className="row">
                <div
                  className={`col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 title-label-1 ${
                    !isLocalSet ? 'no-local' : ''
                  }`}
                >
                  <div className="row section-designation">
                    <div className="col-auto pdg main-label">
                      {config.outsideAir.strings.title}
                    </div>

                    <div className="col-auto pdg tooltip-cnt">
                      <div className="a-i">
                        <div className="b-i">
                          <TooltipView
                            title={
                              <>
                                <span className="tooltip-ft">
                                  {config.outsideAir.tooltip.title}
                                </span>
                                <p className="tooltip-paragraph">
                                  {config.outsideAir.tooltip.p}
                                </p>
                                <span>{config.outsideAir.tooltip.span}</span>
                                <p>{config.outsideAir.tooltip.p1}</p>
                                <span>{config.outsideAir.tooltip.span2}</span>
                                <p>{config.outsideAir.tooltip.p2}</p>
                              </>
                            }
                          >
                            <span className="icon-tooltip">
                              <BsQuestionCircle className="" size="0.8rem" />
                            </span>
                          </TooltipView>
                        </div>
                      </div>
                    </div>

                    <div className="col-auto pdg sensor-designation">
                      <div className="local-data">
                        {isLocalSet && renderProbeInformation()}
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`col-xs-12 col-sm-12 col-md-2 title-actions`}>
                  {isLocalSet && (
                    <TooltipView
                      title={
                        <Fragment>
                          <span className="acc-tooltp-font">
                            {config.outsideAir.onOffTooltip.title}
                          </span>
                          <p className="tooltip-paragraph">
                            {config.outsideAir.onOffTooltip.str}
                          </p>
                        </Fragment>
                      }
                    >
                      <span>
                        <SwitchBtn
                          id={element?.device_id}
                          value={enabled}
                          onClick={(event: any) =>
                            toggleSection(event, element?.device_id, element)
                          }
                          className={`enable-disable-admin`}
                          disabled={
                            systemsCurrentStatus &&
                            Object.keys(systemsCurrentStatus).length > 0 &&
                            systemsCurrentStatus.hasOwnProperty(
                              element?.device_id,
                            ) &&
                            systemsCurrentStatus[element?.device_id]
                              .statusIsLoaded &&
                            systemsCurrentStatus[element?.device_id].stopped
                              ? true
                              : false
                          }
                        />
                      </span>
                    </TooltipView>
                  )}
                  {!isLocalSet && (
                    <button
                      className="btn btn-sm insert-location"
                      onClick={handleAddLocation}
                    >
                      <span className="add-location">
                        {config.outsideAir.strings.addLocation}
                        <MdOutlineAddLocationAlt className="location-icon" />
                      </span>
                    </button>
                  )}
                </div>
              </div>
              <div className="row values">
                {isLocalSet ? (
                  <div className="container pdg">
                    <div className="row containing-values-div">
                      {hasProbeConn ? (
                        <>
                          {!helpers.isValueInit(widgetStates, 'temp') ? (
                            <div className="col-xs-12 col-sm-12 col-md-3 side-1">
                              <span className={`active-values`}>
                                <Skeleton
                                  className="skeleton-mr"
                                  variant="text"
                                  height={60}
                                />
                              </span>
                            </div>
                          ) : (
                            <div className="col-xs-12 col-sm-12 col-md-3 side-1 pdg">
                              <span
                                className={`${
                                  enabled
                                    ? `active-values av-left`
                                    : `off--values av-left`
                                }`}
                              >
                                {numeral(widgetStates.temp.current).format(
                                  config.outsideAir.formats.temp,
                                )}{' '}
                                {config.outsideAir.strings.labels.temp}
                              </span>
                            </div>
                          )}

                          {!helpers.isValueInit(widgetStates, 'hum') ? (
                            <div className="col-xs-12 col-sm-12 col-md-3 mid-1">
                              <span className={`active-values`}>
                                <Skeleton
                                  className="skeleton-mr-half"
                                  variant="text"
                                  height={60}
                                />
                              </span>
                            </div>
                          ) : (
                            <div className="col-xs-12 col-sm-12 col-md-3 mid-1">
                              <span
                                className={`${
                                  enabled ? `active-values` : `off--values`
                                }`}
                              >
                                {numeral(widgetStates.hum.current).format(
                                  config.outsideAir.formats.hum,
                                )}{' '}
                                {config.outsideAir.strings.labels.rhum}
                              </span>
                            </div>
                          )}

                          {!helpers.isValueInit(widgetStates, 'aqi') ? (
                            <div className="col-xs-12 col-sm-12 col-md-3 mid-2">
                              <span className={`active-values`}>
                                <Skeleton
                                  className="skeleton-ml-half"
                                  variant="text"
                                  height={60}
                                />
                              </span>
                            </div>
                          ) : (
                            <div className="col-xs-12 col-sm-12 col-md-3 mid-2">
                              <span
                                className={`${
                                  enabled ? `active-values` : `off--values`
                                }`}
                              >
                                {numeral(widgetStates.aqi.current).format(
                                  config.outsideAir.formats.airq,
                                )}{' '}
                                {config.outsideAir.strings.labels.airq}
                              </span>
                            </div>
                          )}

                          {!helpers.isValueInit(widgetStates, 'cfm') ? (
                            <div className="col-xs-12 col-sm-12 col-md-3 side-3">
                              <span className={`active-values`}>
                                <Skeleton
                                  className="skeleton-ml"
                                  variant="text"
                                  height={60}
                                />
                              </span>
                            </div>
                          ) : (
                            <div className="col-xs-12 col-sm-12 col-md-3 side-3 pdg">
                              <span
                                className={`${
                                  enabled
                                    ? `active-values av-right`
                                    : `off--values av-right`
                                }`}
                              >
                                {numeral(widgetStates.cfm.current).format(
                                  config.outsideAir.formats.cfm,
                                )}{' '}
                                {config.outsideAir.strings.labels.cfm}
                              </span>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {!helpers.isValueInit(widgetStates, 'temp') ? (
                            <div className="col-xs-12 col-sm-12 col-md-4 side-1">
                              <span className={`active-values`}>
                                <Skeleton
                                  className="skeleton-mr"
                                  variant="text"
                                  height={60}
                                />
                              </span>
                            </div>
                          ) : (
                            <div className="col-xs-12 col-sm-12 col-md-4 side-1 pdg">
                              <span
                                className={`${
                                  enabled
                                    ? `active-values av-left`
                                    : `off--values av-left`
                                }`}
                              >
                                {numeral(widgetStates.temp.current).format(
                                  config.outsideAir.formats.temp,
                                )}{' '}
                                {config.outsideAir.strings.labels.temp}
                              </span>
                            </div>
                          )}

                          {!helpers.isValueInit(widgetStates, 'hum') ? (
                            <div className="col-xs-12 col-sm-12 col-md-4 mid-1">
                              <span className={`active-values`}>
                                {' '}
                                <Skeleton
                                  className="skeleton-bothm-half"
                                  variant="text"
                                  height={60}
                                />
                              </span>
                            </div>
                          ) : (
                            <div className="col-xs-12 col-sm-12 col-md-4 mid-1">
                              <span
                                className={`${
                                  enabled ? `active-values ` : `off--values`
                                }`}
                              >
                                {' '}
                                {numeral(widgetStates.hum.current).format(
                                  config.outsideAir.formats.hum,
                                )}{' '}
                                {config.outsideAir.strings.labels.rhum}
                              </span>
                            </div>
                          )}

                          {!helpers.isValueInit(widgetStates, 'aqi') ? (
                            <div className="col-xs-12 col-sm-12 col-md-4 side-3">
                              <span className={`active-values `}>
                                <Skeleton
                                  className="skeleton-ml"
                                  variant="text"
                                  height={60}
                                />
                              </span>
                            </div>
                          ) : (
                            <div className="col-xs-12 col-sm-12 col-md-4 side-3 pdg">
                              <span
                                className={`${
                                  enabled
                                    ? `active-values av-right`
                                    : `off--values av-right`
                                }`}
                              >
                                {numeral(widgetStates.aqi.current).format(
                                  config.outsideAir.formats.airq,
                                )}{' '}
                                {config.outsideAir.strings.labels.airq}
                              </span>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="col-12 pdg">
                    <div className="row">
                      {Object.entries(config.outsideAir.strings.labels)
                        .slice(
                          0,
                          Object.keys(config.outsideAir.strings.labels).length -
                            1,
                        )
                        .map(([key, value]: [string, string], index) => {
                          return (
                            <div
                              className={`col-xs-12 col-sm-12 col-md-4 ${
                                index === 1 ? `oa-middle` : `oa-side-${index}`
                              }`}
                              key={index}
                            >
                              <span className={`values-oa-span`}>
                                _ _ {value}
                              </span>
                            </div>
                          );
                        })}
                    </div>
                    <div className="row">
                      <div className="col-12 lead no--configs">
                        <div className="d-flex justify-content-center mg-t-6">
                          {config.outsideAir.strings.noConfig}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="container outside-air-details">
              <div className="row">
                <div className="col-12">
                  <DoubleSlider
                    id="w-temp-adm"
                    values={transformDoubleSlider(widgetStates.temp)}
                    max={DEFAULT_EXTREMS.temp.max}
                    min={DEFAULT_EXTREMS.temp.min}
                    step={1}
                    col={2}
                    showLabel={true}
                    symbol="ºF"
                    onChange={onChangeTemps}
                    defaultMarks={true}
                    defaultMarksValues={{
                      left: {
                        top: 20,
                        left: '22%',
                        value: DEFAULT_VALUES.temp.min,
                      },
                      right: {
                        top: 20,
                        right: '32%',
                        value: DEFAULT_VALUES.temp.max,
                      },
                    }}
                  />
                </div>
                <div className="col-12">
                  <Slider
                    id="w-rhum-adm-oa"
                    values={transformSingleSlider(widgetStates.hum)}
                    max={DEFAULT_EXTREMS.hum.max}
                    min={DEFAULT_EXTREMS.hum.min}
                    step={1}
                    col={2}
                    showLabel={true}
                    symbol="%"
                    onChange={onChangeHumidity}
                    defaultMark={true}
                    defaultMarkValues={{
                      top: 20,
                      left: '80%',
                      value: DEFAULT_VALUES.hum.max,
                    }}
                  />
                </div>
                <div className="col-12">
                  <GradientSlider
                    id="w-aqi-adm-oa"
                    values={transformSingleSlider(widgetStates.aqi)}
                    max={DEFAULT_EXTREMS.aqi.max}
                    min={DEFAULT_EXTREMS.aqi.min}
                    step={1}
                    col={2}
                    showLabel={true}
                    symbol=""
                    onChange={onChangeAqi}
                  />
                </div>
                {hasProbeConn && (
                  <div className="col-12">
                    <ProbeSlider
                      id="w-cfm-adm"
                      values={transformCfmSliderValues(widgetStates.cfm)}
                      max={DEFAULT_EXTREMS.cfm.max}
                      min={DEFAULT_EXTREMS.cfm.min}
                      step={1}
                      col={2}
                      showLabel={true}
                      symbol=""
                      onChange={onChangeProbeThreshold}
                      probeStatus={probeStatus}
                    />
                  </div>
                )}
              </div>

              {showSaveBtn && outAirChangesMenuIsVisible && (
                <div id="floating--div--4" className="float-btn--wrapper2">
                  <div className="float-btn--content2 save--dv">
                    <div className="container pdg mg-b-15">
                      <div className="row pdg">
                        <div className="col-10 pdg"></div>
                        <div className="col-2 pdg c-2">
                          <button
                            className={`btn small-button ok`}
                            type="button"
                            data-toggle="modal"
                            data-target="#outside--air--changes"
                            onClick={() => {
                              updateOAStates(widgetStates);

                              updateSwitch(enabled);

                              toggleUpdateMenuVisibility({
                                state: false,
                                section: 'oa',
                              });
                            }}
                          >
                            <span className="btn-label btn-child-ft">
                              <RiSave3Fill className="svg-ii" /> SAVE{' '}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <Modal
        config={config}
        id={`outside--air--changes`}
        title={config.outsideAir.titles.oa}
        icon={<RiSave3Fill className="edit" />}
        body={config.outsideAir.strings.confirmation}
        modalMustClose={modalMustClose}
        handleModalState={handleModalState}
        onClick={() => {
          onSaveOutsideConfigs();
        }}
        label={config.outsideAir.labels.oa}
        className={`small-button ok`}
        bodyClass={`alert alert-danger`}
        sureToProceed={config.outsideAir.strings.sureToProceed}
        hasConfirmBtn={true}
      />
    </div>
  );
};

function mapStateToProps(state: any) {
  const { adminState, adminSectionsState, adminDevicesState } = state;
  return {
    outAirChangesMenuIsVisible: adminSectionsState.outAirChangesMenuIsVisible,
    adminWeather: adminSectionsState.adminWeather,
    g3Reads: adminState.g3Reads,
    modalMustClose: adminSectionsState.modalMustClose,
    selectedDevice: adminSectionsState.selectedDevice,
    oaWidgetStates: adminSectionsState.oaWidgetStates,
    oaEnabled: adminSectionsState.oaEnabled,
    outsideAirValuesUpdated: adminSectionsState.outsideAirValuesUpdated,
    systemsCurrentStatus: adminDevicesState.systemsCurrentStatus,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { adminSectionsState } = dispatch;
  return {
    setCurrentDeviceAdmin: adminSectionsState.setCurrentDeviceAdmin,
    setDevDetails: adminSectionsState.setDevDetails,
    editOutsideAirAdmin: adminSectionsState.editOutsideAirAdmin,
    getAdminWeather: adminSectionsState.getAdminWeather,
    toggleUpdateMenuVisibility: adminSectionsState.toggleUpdateMenuVisibility,
    handleModalState: adminSectionsState.handleModalState,
    updateOAStates: adminSectionsState.updateOAStates,
    updateSwitch: adminSectionsState.updateSwitch,
    getProbeMostRecentValues: adminSectionsState.getProbeMostRecentValues,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OutsideAir);
