import React, { useEffect, useState } from 'react';
import config from './config';
import { connect } from 'react-redux';
import _ from 'lodash';
import validate from 'validate.js';
import { FirmwareVersionProps } from './interface';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { ListGroup, ListGroupItem, Button } from 'react-bootstrap';
import HELPERS from './helpers';
import { Notification, Modal, Template } from 'business/modules/admin/common';
import { Input, FileLocationElements, LocationButtons } from './components';
import { RiEdit2Line } from 'react-icons/ri';
import './styles.scss';

const FirmwareVersion: React.FC<FirmwareVersionProps> = ({
  firmwareVersion,
  modalMustClose,
  handleModalState,
  ...props
}) => {
  const [firmwareState, setFirmwareState]: any = useState(
    _.cloneDeep(config.firmwareObject),
  );
  const [deviceEditState, setDeviceEditState]: any = useState(
    config.editStates,
  );
  const [currentEditDevice, setCurrentEditDevice]: any = useState();
  const [errors, setErrors]: any = useState(null);
  const [alertCtrl, setAlertCtrl]: any = useState(false);
  const [resetFirmware, setResetFirmware]: any = useState(false);
  const [locations, setLocations]: any = useState([]);
  const [newLocation, setNewLocation]: any = useState({ id: null, value: '' });
  const [newLocationErrors, setNewLocationErrors]: any = useState(null);
  const [showAddLocation, setShowAddLocation]: any = useState(false);

  const validateFields = (fieldsData: any) => {
    let tempErrors: any = {};
    if (fieldsData && Object.keys(fieldsData).length > 0) {
      config.firmwareItems.forEach((field: string) => {
        if (fieldsData.hasOwnProperty(field)) {
          const vResult: any = validate(fieldsData[field], config.constraints);
          if (vResult) {
            tempErrors = {
              ...tempErrors,
              [field]: vResult[field],
            };
          }
        }
      });
    }
    setErrors(tempErrors);
  };

  const onUpdateFieldState = (
    field: string,
    parentField: string,
    value: any,
  ) => {
    let statesCopy: any = _.cloneDeep(firmwareState);

    if (
      statesCopy &&
      statesCopy.hasOwnProperty(parentField) &&
      statesCopy[parentField].hasOwnProperty(field)
    ) {
      setFirmwareState((prevStates: any) => {
        return {
          ...prevStates,
          [parentField]: {
            ...prevStates[parentField],
            [field]: value,
          },
        };
      });
    }
  };

  const handleSaveFirmWareVersion = async (editedData: any) => {
    props.loading.start('Saving Firmware...');
    await props.updateFirmware(editedData);
    props.loading.stop();
  };

  const handleLoadFirmware = async () => {
    props.loading.start('Loading Firmware Version...');
    await props.getFirmwareVersion();
    props.loading.stop();
  };

  const handleAddNewLocation = (event: any) => {
    setShowAddLocation(true);
  };

  const onAddNewLocation = (value: any) => {
    const locationIndex: any = locations?.length ? locations.length : 0;
    setNewLocation({
      id: locationIndex,
      value,
    });
  };

  const onConfirmAddNew = () => {
    if (newLocation?.value !== '') {
      let allLocationsClone: any = _.cloneDeep(locations);
      allLocationsClone.push(newLocation);
      setLocations(allLocationsClone);

      setNewLocation({ id: null, value: '' });
    }
    setShowAddLocation(false);
  };

  const onCancelAddNew = () => {
    setNewLocation({ id: null, value: '' });
    setShowAddLocation(false);
  };

  const resetFirmwareByParentField = (field: string) => {
    const firmStatesCopy: any = firmwareVersion
      ? _.cloneDeep(firmwareVersion)
      : null;

    if (firmStatesCopy.hasOwnProperty(field)) {
      const fieldsCopy: any = _.cloneDeep(firmStatesCopy[field]);

      if (fieldsCopy && Object.keys(fieldsCopy).length > 0) {
        setFirmwareState((prevStates: any) => {
          return {
            ...prevStates,
            [field]: fieldsCopy,
          };
        });
      }
    }
  };

  const handleSelectVentilation = (field: any, event: any) => {
    let selectedLocation: any = event?.target?.value;
    if (field && selectedLocation) {
      setFirmwareState((prevStates: any) => {
        return {
          ...prevStates,
          [field]: {
            ...prevStates[field],
            file_location: selectedLocation,
          },
        };
      });
    }
  };

  const onSaveEdit = (field: string, event: any) => {
    setCurrentEditDevice(field);
    setAlertCtrl(true);
  };

  const handleSaveDeviceFirmware = async (data: any) => {
    setAlertCtrl(false);
    props.loading.start('Saving firmware updates...');

    if (currentEditDevice) {
      setDeviceEditState((prevStates: any) => {
        return {
          ...prevStates,
          [currentEditDevice]: 'normal',
        };
      });
    }
    await handleSaveFirmWareVersion(firmwareState);
    props.loading.stop();
  };

  const onCancelEdit = (field: string, event: any) => {
    resetFirmwareByParentField(field);
    setDeviceEditState((prevStates: any) => {
      return {
        ...prevStates,
        [field]: 'normal',
      };
    });
    setCurrentEditDevice(null);
  };

  const handleEditClick = (field: string, event: any) => {
    setDeviceEditState((prevStates: any) => {
      return {
        ...prevStates,
        [field]: 'edit',
      };
    });
  };

  useEffect(() => {
    if (firmwareVersion && Object.values(firmwareVersion).length > 0) {
      const tempResult: any = HELPERS.handleLocationsAsOptions(
        _.cloneDeep(firmwareVersion),
      );
      setFirmwareState(_.cloneDeep(firmwareVersion));

      if (tempResult?.length > 0) {
        setLocations(tempResult);
      }
    }
  }, [firmwareVersion]);

  useEffect(() => {
    if (resetFirmware) {
      if (firmwareVersion && Object.values(firmwareVersion).length > 0) {
        setFirmwareState(_.cloneDeep(firmwareVersion));
      }
    }
  }, [resetFirmware]);

  useEffect(() => {
    if (props.firmwareWasUpdated) {
      Notification({
        title: 'Success',
        message: config.strings.notifications.success,
        type: 'success',
      });
      props.updateFirmwareStates(false, 'firmwareWasUpdated');
    }
  }, [props.firmwareWasUpdated]);

  useEffect(() => {
    handleLoadFirmware();
  }, []);

  return (
    <Template id="edit-firmware--wrapper" title={'Firmware'}>
      <BreadcrumbsItem to={`/admin/dashboard/firmware-version`}>
        {`Firmware version`}
      </BreadcrumbsItem>

      <div className={`container firmware-container-screen`}>
        <div className={`row no-side-space`}>
          <div className={`col no-side-space`}>
            <div className="jumbotron firmware-data">
              <h4 className="centered">{`Edit Firmwares`}</h4>
              <p className="lead centered sm-ft">{`Check and update Firmware version.`}</p>

              <hr className="my-4" />

              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="container pdg">
                      <div className="row location-row">
                        <div className="col-auto pdg">
                          <span className="firm-location--title">
                            Current Container Locations
                          </span>
                        </div>

                        <div className="col-auto pdg">
                          <Button
                            className="add-btn1"
                            onClick={handleAddNewLocation}
                          >
                            <span className="add-btn--icon">+</span>
                          </Button>
                        </div>
                      </div>
                      {showAddLocation && (
                        <div className="row">
                          <div className="col-xs-12 col-sm-12 col-md-6 pdg">
                            <Input
                              label="New URL"
                              placeholder="Add new container URL..."
                              name="new--location"
                              value={newLocation?.value}
                              error={newLocationErrors}
                              type="text"
                              handleChange={onAddNewLocation}
                              handleChangeError={() => {}}
                            />
                          </div>

                          <div className="col-xs-12 col-sm-12 col-md-6 firm-location--confirm-wrapper pdg">
                            <Button
                              className="firm-location--confirm-new"
                              onClick={onConfirmAddNew}
                            >
                              Confirm Add
                            </Button>
                            <Button
                              className="firm-location--cancel-new"
                              onClick={onCancelAddNew}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      )}

                      <div className="row firm-location--list-wrapper">
                        <ListGroup className="firm-location--list-main">
                          {locations?.length > 0 &&
                            locations.map((localItem: any, index: number) => {
                              return (
                                <ListGroupItem
                                  key={`location--${index}`}
                                  className="firm-location--list-item"
                                >
                                  {localItem.value}
                                </ListGroupItem>
                              );
                            })}
                        </ListGroup>
                      </div>
                    </div>

                    <div className="firm--divider-container">
                      <hr className="div-elem" />
                    </div>
                  </div>
                </div>
              </div>

              <div id="fe_wrapper" className="container edit-firm--container">
                <div className="row">
                  {firmwareState &&
                    Object.keys(firmwareState).map(
                      (parentField: string, index: number) => {
                        const itemClone: any = _.cloneDeep(
                          firmwareState[parentField],
                        );
                        return (
                          <div
                            key={`firm--item-${index}`}
                            className="col-xs-12 col-sm-12 col-md-6"
                          >
                            <div className="edit-firm--item-title">
                              {HELPERS.titlesMapper(parentField)}
                            </div>
                            <div className="edit-firm--inputs-container">
                              {Object.keys(itemClone).map(
                                (field: any, fieldIndex: number) => {
                                  switch (field) {
                                    case 'file_location':
                                      return (
                                        <FileLocationElements
                                          key={`${parentField}-${field}-${fieldIndex}`}
                                          allLocations={locations}
                                          mode={deviceEditState[parentField]}
                                          value={itemClone[field]}
                                          field={field}
                                          parentField={parentField}
                                          onChange={handleSelectVentilation}
                                          returnLabel={HELPERS.returnLabel}
                                        />
                                      );
                                    default:
                                      return (
                                        <Input
                                          key={`item-${parentField}-${fieldIndex}`}
                                          label={HELPERS.returnLabel(field)}
                                          placeholder={HELPERS.returnLabel(
                                            field,
                                          )}
                                          name={field}
                                          value={itemClone[field]}
                                          error={
                                            errors &&
                                            errors[parentField] &&
                                            errors[parentField][field]
                                              ? errors[parentField][field]
                                              : null
                                          }
                                          type="text"
                                          handleChange={onUpdateFieldState.bind(
                                            null,
                                            field,
                                            parentField,
                                          )}
                                          handleChangeError={() => {}}
                                          disabled={
                                            deviceEditState[parentField] ===
                                            'normal'
                                          }
                                        />
                                      );
                                  }
                                },
                              )}
                              <div className="edit-firm--btns-container">
                                <LocationButtons
                                  mode={deviceEditState[parentField]}
                                  field={parentField}
                                  onCancelEdit={onCancelEdit}
                                  onSaveEdit={onSaveEdit}
                                  handleEditClick={handleEditClick}
                                  dataToggle={`modal`}
                                  dataTarget={`#update--firmware--modal`}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      },
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        config={config}
        id={`update--firmware--modal`}
        title={config.modalStrings.edit}
        icon={<RiEdit2Line className="edit" />}
        body={config.modalStrings.aboutToUpdate}
        label={config.modalStrings.labelOK}
        className={`small-button ok`}
        bodyClass={`alert alert-info`}
        sureToProceed={config.modalStrings.sureToProceed}
        hasConfirmBtn={true}
        warning={config.modalStrings.noUndo}
        onClickCancel={() => {
          onCancelEdit(currentEditDevice, null);
        }}
        onClick={() => {
          handleSaveDeviceFirmware(firmwareState);
        }}
        modalMustClose={modalMustClose}
        handleModalState={handleModalState}
      />
    </Template>
  );
};

function mapStateToProps(state: any) {
  const { firmware } = state;

  return {
    firmwareVersion: firmware.firmware,
    firmwareWasUpdated: firmware.firmwareWasUpdated,
    modalMustClose: firmware.modalMustClose,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { firmware, loader } = dispatch;

  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    updateFirmware: firmware.saveFirmware,
    getFirmwareVersion: firmware.getCurrentFirmWare,
    updateFirmwareStates: firmware.updateState,
    handleModalState: firmware.handleModalState,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FirmwareVersion);
