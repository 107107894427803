export default {
  strings: {
    title: 'Device Details',
    labels: {
      firmware: 'Firmware Version: ',
      macAddrr: 'Mac Address: ',
      upTime: 'Up Time: ',
      conDevices: 'Connected Devices: ',
      fanCon: 'Fan Connected/Switch Present',
      damper: 'Damper Status',
      currentTemp: 'Current Temperature',
      currentFlow: 'Current Flow',
      relativeHumid: 'Current Relative Humidity',
    },
    titles: {
      peripherals: 'Peripherals',
      connDevices: 'Connected Devices',
    },
    warns: {
      noDevices: 'Controller does not have any connected device.',
    },
  },
  menu: {
    options: []  
  },
  fieldsState: {},
  fieldsFocus: {},
  fields: {},
  constraints: {},
};
