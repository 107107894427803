// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { WidgetSwitch } from '../../../common';
import './styles.scss';

interface SwitchGroupProps {
  id?: string;
  data?: any;
  ckData?: any;
  device: any;
  onChange: any;
  classes?: any;
  ckDataMC: any;
}

const SwitchGroupV2: React.FC<SwitchGroupProps> = ({
  id,
  data,
  ckData,
  ...props
}) => {
  const [orderedEl, setOrderedEl]: any = useState([]);

  const retriveValue = (_type: any, _ckData: any, _id: any) => {
    const { setup } = _ckData || {};

    switch (_type) {
      case 'damper':
      case 'fanConnect':
      case 'centralFan':
        return setup.hasOwnProperty(_id) && setup[_id] === 1;
      case 'ventConnect':
      case 'freshConnect':
      case 'pressureConnect':
      default:
        const vcItem: any =
          setup?.cdvs && setup?.cdvs?.length > 0
            ? setup.cdvs.find((item: any) => item.cdid === _id)
            : null;

        if (vcItem) {
          return vcItem.cdst === 3 || vcItem.cdst === 1;
        }
    }

    return false;
  };


  const validateConnectedDevice = (_type: string) => {
    switch (_type) {
      case 'freshConnect':
      case 'ventConnect':
        return true;
      default:
        return false;
    }
  };



  useEffect(() => {
    if (
      data &&
      Object.values(data).length > 0
    ) {
      let tempEl: any = [];
      if (data?.ventConnect) {
        data.ventConnect.forEach((periph: any, i: number) => {
          const { cdid, cidx } = periph;
          tempEl.push({
            name: `VentConnect`,
            type: 'ventConnect',
            id: cdid,
            cidx,
          });
        });
      }

      if (data?.damper) {
        tempEl.push({
          name: `Damper Open`,
          type: 'damper',
          id: 'dmst',
        });
      }

      if (data?.fanConnect) {
        tempEl.push({
          name: `FanConnect`,
          type: 'fanConnect',
          id: 'fcst',
        });
      }

      if (data?.pressureConnect) {
        data.pressureConnect.forEach((periph: any, i: number) => {
          const { cdid, cidx } = periph;
          tempEl.push({
            name: `PressureConnect`,
            type: 'pressureConnect',
            id: cdid,
            cidx,
          });
        });
      }

      if (data?.freshConnect) {
        data.freshConnect.forEach((periph: any, i: number) => {
          const { cdid, cidx } = periph;
          tempEl.push({
            name:
              cdid && cdid.startsWith('AirCyclerFT')
                ? `FreshConnect`
                : `FreshConnect`,
            type: 'freshConnect',
            id: cdid,
            cidx,
          });
        });
      }

      if (data?.centralFan) {
        tempEl.push({
          name: `Central Fan`,
          type: 'centralFan',
          id: 'cfst',
        });
      }

      setOrderedEl(tempEl);
    }
  }, [data]);


  return (
    <>
      <div id={id} className="switchs--wrapper">
        {orderedEl.length > 0 &&
          orderedEl.map((element: any, index: number) => {
            const { id, name, type, cidx } = element || {};
            const isValidForId: boolean = validateConnectedDevice(type);

            return (
              <div
                key={`${name}+${index}`}
                className="switchs--wrapper--container"
              >
                <WidgetSwitch
                  id={`${id}-${type}-${
                    cidx !== undefined && cidx !== null ? cidx : 'na'
                  }`}
                  label={isValidForId ? id : name}
                  value={retriveValue(type, ckData, id)}
                  onChange={props.onChange}
                  origin={`manualControl`}
                  component={isValidForId ? name : null}
                />
              </div>
            );
          })}
      </div>
    </>
  );
};

export default SwitchGroupV2;
