export default {
  strings: {
    minPerHour: `| Min per hr`,
    hmFormat: `HH:mm`,
    noState: `No state data.`,
    noCity: `No city data.`,
    noZip: `No zip code data.`,
    modalTitle: `System information`,
    modalBody: `You are about to save system updated information.`,
    modalRest: `Do you wish to proceed?`,
    warning: `Please, make sure all the fields are filled / fix errors to save changes.`,
  },
  timezone3: [
    {
      label: 'Newfoundland Time w/ Daylight Sav. Time',
      timezone: 'Canada/Newfoundland',
    },
    {
      label: 'Atlantic Time w/ Daylight Sav. Time',
      timezone: 'Canada/Atlantic',
    },
    {
      label: 'Eastern Std. w/ Daylight Sav. Time',
      timezone: 'Canada/Eastern',
    },
    {
      label: 'Central Std. w/ Daylight Sav. Time',
      timezone: 'US/Central',
    },
    {
      label: 'Mountain Std. w/ Daylight Sav. Time',
      timezone: 'US/Mountain',
    },
    { label: 'Mountain Std. Time', timezone: 'US/Mountain' },
    {
      label: 'Pacific Std. w/ Daylight Sav. Time',
      timezone: 'US/Pacific',
    },
    {
      label: 'Alaska Std. w/ Daylight Sav. Time',
      timezone: 'US/Alaska',
    },
    {
      label: 'Hawaii Std. w/ Daylight Sav. Time',
      timezone: 'US/Hawaii',
    },
  ],
  sysStateStrs: {
    noState: `No state data.`,
    noCity: `No city data.`,
    noZip: `No zip code data.`,
  },
  ventOptions: [
    { name: 'Calculated Flow' },
    { name: 'Calculated Time' },
    { name: 'Balanced Mode' },
  ],
  tz: [
    'Newfoundland Time w/ Daylight Savings Time',
    'Atlantic Time w/ Daylight Savings Time',
    'Eastern Standard w/ Daylight Savings Time',
    'Central Standard w/ Daylight Savings Time',
    'Mountain Standard w/ Daylight Savings Time',
    'Pacific Standard w/ Daylight Savings Time',
    'Alaska Standard w/ Daylight Savings Time',
    'Hawaii Standard w/ Daylight Savings Time',
  ],
  hours: {
    hourStart: 'number',
    hourStop: 'number',
  },
  calculatedTime: {
    minutesPerHourSupplyFlow: 'number',
    minutesPerHourExhaustRun: 'number',
    exhaustFanDelayTime: 'number',
  },
  calculatedFlow: {
    flowMeasuredHeatCall: 'number',
    flowMeasuredCoolingCall: 'number',
    flowMeasuredFanOnlyCall: 'number',
    requiredContinuousAirFlow: 'number',
    exhaustFanFlowRate: 'number',
    exhaustFanDelayTime: 'number',
  },
  balancedMode: {
    minutesPerHourSupplyFlow: 'number',
    minutesPerHourExhaustRun: 'number',
    exhaustFanDelayTime: 'number',
  },
  _config: {
    default: {
      dependentProps: {
        hrsv: {
          prop: 'hroa',
          value: 1,
        },
        hstv: {
          prop: 'hroa',
          value: 1,
        },
      },
      ventSetupTags: {
        calcFlow: [
          'fhfr',
          'fcfr',
          'fffr',
          'carq',
          'vcfr',
          'effr',
          'mixt',
          'hroa',
          'hrsv',
          'hstv',
          'tmzn',
          'efdt',
        ],
        calcTime: [
          'mnsf',
          'mner',
          'mixt',
          'hroa',
          'hrsv',
          'hstv',
          'tmzn',
          'efdt',
        ],
        balancedMode: [
          'mnsf',
          'mner',
          'efdt',
          'mixt',
          'hroa',
          'hrsv',
          'hstv',
          'tmzn',
        ],
      },
    },
  },
  fieldsThatRequireAddLabel: [
    'flowMeasuredHeatCall',
    'flowMeasuredCoolingCall',
    'flowMeasuredFanOnlyCall',
    'flowContinuousAirFlow',
    'exhaustFanFlowRate',
    'additionalMixTime',
    'exhaustFanDelayTime',
  ],
  inputTypes: {
    systemName: 'text',
    systemLocationState: 'text',
    systemLocationCity: 'text',
    systemLocationZipCode: 'number',
    flowMeasuredHeatCall: 'number',
    flowMeasuredCoolingCall: 'number',
    flowMeasuredFanOnlyCall: 'number',
    flowContinuousAirFlow: 'number',
    exhaustFanFlowRate: 'number',
    additionalMixTime: 'number',
    hoursOfOperation: 'number',
    hourStart: 'number',
    hourStop: 'number',
    currentTime: 'number',
    exhaustFanDelayTime: 'number',
    minutesPerHourExhaustRun: 'number',
    minutesPerHourSupplyFlow: 'number',
    requiredContinuousAirFlow: 'number',
  },
  config: {
    systemName: 'string',
    ventilationSetup: 'select',
    primaryControl: 'select',
    addSensor: 'select',
    systemLocationState: 'string',
    systemLocationCity: 'string',
    systemLocationZipCode: 'string',
    deviceStatus: 'boolean',
    additionalMixTime: 'number',
    timezone: 'select',
    currentTime: 'string',
    hoursOfOperation: 'boolean',
  },
  idsWithAdditionalLabel: [
    'minutesPerHourSupplyFlow',
    'minutesPerHourExhaustRun',
    'exhaustFanDelayTime',
    'additionalMixTime',
    'flowMeasuredHeatCall',
    'flowMeasuredCoolingCall',
    'flowMeasuredFanOnlyCall',
    'requiredContinuousAirFlow',
    'exhaustFanFlowRate',
    'exhaustFanDelayTime',
  ],
  modal: {
    view: {
      title: `View User`,
      action: ``,
      bodyText: ``,
      btns: {
        labelOk: ``,
        labelCancel: ``,
      },
      modalHeaderId: `view-user1`,
      sectionTitle: `User details`,
      sectionSubtitle: `Some user data`,
    },
    edit: {
      title: `Update system`,
      action: ``,
      bodyText: ``,
      btns: {
        labelOk: ``,
        labelCancel: ``,
      },
      modalHeaderId: `update-sys`,
    },
  },
  modalStrings: {
    confirm: ``,
    warning: `Information`,
    alertStr: ``,
    alertStr2: ``,
    confirmation: ``,
    sureToProceed: `Are you sure you wish to proceed?`,
    labelOK: ` OK`,
    aboutToUpdate: `You are about to update this system.`,
  },
};
