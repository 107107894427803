import React, { useState } from 'react';
import config from './config';
import './styles.scss';


interface EmptyListProps {
  title?: any;
};

const EmptyList: React.FC<EmptyListProps> = ({ title, ...props }) => {
  const [configuration, setConfig]: any = useState({ ...config });

  return (
    <div id="ac-empty-list">
      <div className="container">
        <p>
          {configuration?.strings?.title[title]
            ? configuration.strings.title[title]
            : configuration.strings.title.other}
        </p>
      </div>
    </div>
  );
};

export default EmptyList;
