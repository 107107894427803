export default {
  defaults: {
    g3CoreLabel: 'g3CoreSettings',
    ventSetupTags: {
      calcFlow: ['fhfr', 'fcfr', 'fffr', 'carq', 'vcfr', 'effr', 'mixt', 'hroa', 'hrsv', 'hstv', 'tmzn', 'efdt'],
      calcTime: ['mnsf', 'mner', 'mixt', 'hroa', 'hrsv', 'hstv', 'tmzn', 'efdt'],
      balanceMode: ['mnsf', 'mner', 'efdt', 'mixt', 'hroa', 'hrsv', 'hstv', 'tmzn'],
    },
  },
};
