import React from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import DropdownToggle from 'react-bootstrap/DropdownToggle';
import { useAuth } from '../../../../modules/sessions/hooks/auth';

interface BreadcrumbProps {
  variant?: string;
  title?: string;
  breadcrumb?: boolean;
}

const UserProfileDropdown: React.FC<BreadcrumbProps> = ({}) => {
  const history = useHistory();
  const { signOutAdmin, adminUser }: any = useAuth();

  function handleSignOut() {
    signOutAdmin();
    history.push('/admin/login');
  }

  return (
    <li className="nav-item dropdown">
      <Dropdown>
        <DropdownToggle as="span" className="nav-link cursor-pointer">
          <img
            src="/avatar.png"
            alt="user"
            className="rounded-circle"
            width="40"
          />
          <span className="ml-2 d-none d-lg-inline-block">
            <span>Hello,</span>{' '}
            <span className="text-dark">{adminUser?.user?.first_name}</span>{' '}
            <FiChevronDown />
          </span>
        </DropdownToggle>
        <DropdownMenu>
          <div className="dropdown-header">
            <i className="i-Lock-User mr-1"></i> Admin
          </div>
          <button
            className="dropdown-item cursor-pointer"
            onClick={handleSignOut}
          >
            Sign out
          </button>
        </DropdownMenu>
      </Dropdown>
    </li>
  );
};

export default UserProfileDropdown;
