import React from 'react';
import { AlertProps } from './interface';
import './styles.scss';

const Alert: React.FC<AlertProps> = ({ alertClass, children }) => {
  return (
    <div
      className={`alert ${alertClass ? alertClass : `alert-gen-class`}`}
      role="alert"
    >
      {children}
    </div>
  );
};

export default Alert;
