import React, { useState, useCallback, useEffect } from 'react';
import { InputAvatar } from '../../../../../../../react-components';
import styles from './index.module.scss';
import { useAuth } from '../../../../../../modules/sessions/hooks/auth';
import { ConnectionBroker } from 'core';
import { Notification, Button } from '../../../../../../../react-components';

const ProfileHeader: React.FC = () => {
  const { user, updateUser }: any = useAuth();
  const [toSave, setToSave]: any = useState(false);
  const [loading, setLoading]: any = useState(false);
  const [fieldsState, setFieldsState]: any = useState({});

  const handleSubmit = useCallback(
    async () => {
      setLoading(true);
      const response: any = await ConnectionBroker.fetchAPI(
        'jitBE',
        'profile.uploadAvatar',
        { avatar: fieldsState.avatar },
        null
      );
      if (response.result) {
        const { last_name, first_name, picture } = response.body;
        picture.profile.url += `?${Date.now()}`;
        // picture += `?${Date.now()}`;

        updateUser({ user: { last_name, first_name, picture } });
        Notification({
          title: 'Successfully',
          message: 'your picture has been updated',
          type: 'success',
        });
      }
      setLoading(false);
    },
    // eslint-disable-next-line
    [fieldsState],
  );


  useEffect(() => {
    return () => {};
  }, []);


  return (
    <div>
      <div className={styles.profileHeader}>
        <div className={styles.profileHeader__name}>
          <h3 className="text-dark">
            {user.user?.first_name} {user.user?.last_name}
          </h3>
        </div>
        <div className={styles.profileHeader__img}>
          <InputAvatar
            handleChange={(value: any) => {
              setToSave(true);
              setFieldsState((prevState: any) => ({
                ...prevState,
                avatar: value,
              }));
            }}
            cols={{
              col: 6,
              sm: 12,
              md: 12,
              lg: 6,
              xl: 6,
            }}
            name={'avatar'}
            key={'avatar'}
            srcCurrent={`${user.user?.picture?.profile?.url}`}
          />
        </div>
        {toSave && (
          <Button disabled={loading} onClick={() => handleSubmit()}>
            Save picture
          </Button>
        )}
      </div>
    </div>
  );
};

export default ProfileHeader;
//
