import api from "./api";
import axios from 'axios';
export interface IConnectionBroker {
  method: string;
  data: object;
  headers: object;
}

class ConnectionBroker {
  private axios:any;
  constructor(){

     axios.interceptors.response.use(
      function (response:any) {
        return response;
      },
      function (error:any) {
        return Promise.reject(error);
      },
    );
  }
  /**
   * [call description] -
   * @param  key [description] - cache record identifier
   * @return     [description] - cache data
   */
  async call(options:any) {
    return new Promise((resolve, reject) => {
      api
        .request(options)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {        
          reject(err);
        });
    });
  }
  async callUpload(endpoint:any, data:any) {
    return new Promise((resolve, reject) => {
     /*
      api
        .post(`${endpoints.baseURLs[0]}${endpoint}`, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err)
          reject(err);
        });
     */
    });
  }
}

export default new ConnectionBroker();
