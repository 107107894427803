export default {
  strings: {
    title: 'Configurations List',
  },
  alerts: {
    delete: {
      question: 'Are you sure you want to delete configuration: ',
      msm: "You won't be able to revert the operation!",
      btn: {
        confirm: 'Yes, delete it!',
      },
    },
  },
  notifications: {
    delete: {
      msm: 'Configuration deleted successfully!',
    },
  },
};
