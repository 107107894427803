const returnStateProp = (str: string) => {
  switch (str) {
    case 'device_name':
      return 'device_name';
    case 'state':
      return 'device_state';
    case 'city':
      return 'device_city';
    case 'zip_code':
      return 'device_zipcode';
    default:
      return '';
  }
};

const areFieldsEmpty = (formValues: any) => {
  if (
    Object.values(formValues?.device_state).length === 0 ||
    Object.values(formValues?.device_city).length === 0 ||
    Object.values(formValues?.device_zipcode).length === 0 ||
    formValues?.device_name.trim().length === 0
  ) {
    return true;
  } else {
    return false;
  }
};

export const helpers = {
  returnStateProp: returnStateProp,
  areFieldsEmpty: areFieldsEmpty,
};
