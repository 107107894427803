import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import validate from 'validate.js';
import { Input, Button, Notification } from '../../../../../react-components';
import { UserTemplate } from 'business/modules/common';
import { FoobotLogoHeader } from '../../common';
import { useAuth } from '../../../sessions/hooks/auth';
import config from './config';
import './styles.scss';



const configInputs: any = config;
const FOOBOT_LABEL: string = '@air-foobot';

interface FoobotProps {
  loading: any;
  profile: any;
  saveFoobotData: any;
}

const FoobotConnection: React.FC<FoobotProps> = ({ profile, ...props }) => {
  const history = useHistory();
  const [isInit, setInit]: any = useState(false);
  const [btnDisabled, setBtnDisabled]: any = useState(true);
  const [fieldsState, setFieldsState]: any = useState({
    ...configInputs.fieldsState,
  });
  const [errors, setErrors]: any = useState(null);

  const onChangeState = async (name: string, value: any) => {
    let currentFields = {
      [name]: value,
    };
    let currentConstraints = {
      [name]: { ...configInputs.constraints[name] },
    };
    await validate
      .async(currentFields, currentConstraints)
      .then((res: any) => {
        setFieldsState((prevState: any) => {
          return {
            ...prevState,
            [name]: res[name],
          };
        });
      })
      .catch(err => {
        setErrors((prevErrors: any) => {
          return { ...prevErrors, [name]: err[name] };
        });
      });

    setFieldsState((prevState: any) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };


  const negativeResponseHandler = (response: any) => {
    const { statusCode }: any = response || {};

    switch (statusCode) {
      case 400:
      case '400':
      case 500:
      case '500':
        Notification({
          title: 'Error!',
          message: configInputs?.notifications?.error,
          type: 'error',
        });
        break;
      default:
        Notification({
          title: 'Warning!',
          message: configInputs?.notifications?.warning,
          type: 'warn',
        });
        break;
    }
  };


  const saveUserData = async (fields: any) => {
    let profileClone = { ...profile };
    if (!profileClone?.details.hasOwnProperty('thirdParty')) {
      profileClone.details.thirdParty = {};
    }
    if (!profileClone?.details?.thirdParty.hasOwnProperty('foobot')) {
      profileClone.details.thirdParty.foobot = {};
    }

    profileClone.details.thirdParty.foobot = { ...fields };

    return await props.saveFoobotData(profileClone);
  };

  const handleSubmit = async (event: any) => {
    props.loading.start('Connecting to Foobot');
    await saveUserData(fieldsState).then((resp: any) => {
      // delaying the stop of loading
      setTimeout(() => {
        props.loading.stop();
        if (resp?.statusCode === 200) {
          Notification({
            title: 'Success!',
            message: configInputs?.notifications?.success,
            type: 'success',
          });
          // history.push('/dashboard/foobot');
          // to prevent return disconnect page when browser back button clicked
          history.replace('/dashboard/foobot', { state: { deleted: false } });
        }else{
          negativeResponseHandler(resp);
        }
      }, (2 * 1000));
    });
  };

  useEffect(() => {
    if (isInit) {
      let vlResult = validate(fieldsState, configInputs.constraints);

      if (!vlResult) {
        setBtnDisabled(false);
      }
    }
  }, [fieldsState]);

  useEffect(() => {
    setInit(true);
  }, []);

  return (
    <>
    <UserTemplate
    id="foobot-connection"
    title={config?.strings?.title}
    titleBackButton
    >
    <BreadcrumbsItem to={`/dashboard/foobot/connection`}>
      Foobot Connection
    </BreadcrumbsItem>
    <Grid container direction="column" alignItems="center">
      <Grid item className="section-1">
        <FoobotLogoHeader />
      </Grid>
      <Grid item className="section-2">
        <p>
          Connect to your <span className="section-2--bl">FOOBOT</span>{' '}
          account in order to import your devices to the{' '}
          <span className="section-2--bl">AirCyclerApp</span>
        </p>
      </Grid>
      <Grid item className="section-3">
        <ul>
          <li>
            1 - Click the link:{' '}
            <a
              className="section-3--link"
              href="https://api.foobot.io/apidoc/index.html"
              target="_blank"
            >
              Click Here
            </a>
          </li>
          <li>2 - Request the API key.</li>
          <li>3 - Paste the API key on the field below.</li>
        </ul>
      </Grid>
      <Grid item className="section-4">
        <div>
          {configInputs?.fields &&
            Object.keys(configInputs.fields).map(
              (name: any, index: number) => {
                return (
                  <Input
                    key={`foo-input--${index}`}
                    handleChange={onChangeState.bind(null, name)}
                    handleChangeError={(err: any) => {
                      setErrors((prevState: any) => ({
                        ...prevState,
                        [name]: err,
                      }));
                    }}
                    type={configInputs.fields[name].type}
                    label={configInputs.fields[name].label}
                    value={fieldsState[name]}
                    error={errors ? errors[name] : ''}
                    name={name}
                  />
                );
              },
            )}
        </div>
      </Grid>
      <Grid item className="section-5">
        <div className="col d-flex pt-4 pb-2">
          <Button
            className="btn  btn-primary btn-block"
            type="button"
            disabled={btnDisabled}
            onClick={handleSubmit}
          >
            {config.strings.confirm}
          </Button>
        </div>
      </Grid>
    </Grid>
    </UserTemplate>
    </>
  );
};

function mapStateToProps(state: any) {
  const { profile } = state;
  return {
    profile: profile.user,
  };
}

function mapDispatchToProps(dispatch: any) {
  let { loader, profile, foobot } = dispatch;
  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    saveFoobotData: foobot.saveFoobotUserData,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FoobotConnection);
