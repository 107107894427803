import React from 'react';
import { Tooltip, TooltipProps } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 180,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    borderRadius: '8px',
    padding: '10px',
    boxShadow: '3px 2px 15px 0px #aaaaaa82',
    justifyContent: 'center',
    alignItems: 'center',
  },
  arrow: {
    color: '#f5f5f9',
    fontSize: 20,
    '&:before': {
      border: '1px solid #dadde9',
    },
  },
}))(Tooltip);

const TooltipView: React.FC<TooltipProps> = ({ children, title }) => {
  return (
    <HtmlTooltip title={title} placement="top" arrow>
      {children}
    </HtmlTooltip>
  );
};

export default TooltipView;
