import { ConnectionBroker } from 'core';

export const installerConfigs = {
  state: {
    allConfigs: [],
    currentId: undefined,
    currentConfName: undefined,
    configShowData: [],
    configShowDataLoaded: false,
    outsideAirEnabled: false,
    indoorAirEnabled: false,
    editData: {},
    outsideAirPreparedSetup: {},
    indoorAirPreparedSetup: {},
    currentlyIDConfig: '',
  },
  reducers: {
    saveConfIdOABeingCreated(state: any, payload: any) {
      return {
        ...state,
        currentlyIDConfig: payload,
      };
    },

    prepareToSaveSetupStore(state: any, payload: any) {
      return {
        ...state,
        outsideAirPreparedSetup: payload,
      };
    },

    prepareToSaveIASetupStore(state: any, payload: any) {
      return {
        ...state,
        indoorAirPreparedSetup: payload,
      };
    },

    saveUpdateFieldsGlobally(state: any, payload: any) {
      return {
        ...state,
        editData: { ...state.editData, payload },
      };
    },
    updateState(state: any, payload: any, name: string) {
      return { ...state, [name]: payload };
    },
    storeId(state: any, payload: any) {
      localStorage.setItem('@currentSelectedConfigId', payload);

      let savedId = localStorage.getItem('@currentSelectedConfigId');

      return {
        ...state,
        currentId: savedId,
      };
    },
    storeName(state: any, payload: any) {
      localStorage.setItem('@currentSelectedConfigName', payload);

      let savedId = localStorage.getItem('@currentSelectedConfigName');

      return {
        ...state,
        currentConfName: savedId,
      };
    },
    saveCurrConfig(state: any, payload: any) {
      return {
        ...state,
        configShowData: payload,
        configShowDataLoaded: true,
      };
    },
  },
  effects: (dispatch: any) => ({
    async listAllConfigurations(data: any, rootState: any) {
      const that: any = this;
      const response: any = await ConnectionBroker.fetchAPI(
        'jitBE',
        'installer.listConfigs',
      );

      if (response?.statusCode === 200) {
        that.updateState(
          response?.body.length > 0 ? response.body : [],
          'allConfigs',
        );
      }

      return response?.body ? response.body : [];
    },
    async showConfigs(data: any) {
      const that: any = this;
      const response: any = await ConnectionBroker.fetchAPI(
        'jitBE',
        'installer.showConfig',
        null,
        {
          id: data,
        },
      );

      if (response?.statusCode === 200) {
        if (response?.body) {
          that.saveCurrConfig(response?.body);
        }
      } else {
        //TODO handle errors
      }

      return response?.body ? response.body : [];
    },
    async createNewConfiguration(payload: any, rootState: any) {
      const that: any = this;
      const response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'installer.createConfig',
        payload,
      );

      if (response.statusCode === 200) {
        await that.listAllConfigurations();
      }

      return response?.body ? response.body : null;
    },
    async deleteConfiguration(data: any) {
      const that: any = this;
      const response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'installer.deleteConfig',
        null,
        { id: data.id },
      );

      if (response?.statusCode === 200) {
        await that.listAllConfigurations();
      }

      return response?.body ? response.body : null;
    },
    async editConfiguration(payload: any) {
      const that: any = this;
      const {data, id}: any = payload;

      const response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'installer.updateConfig',
        {...data},
        { id: id },
      );

      if (response?.statusCode === 200) {
        await that.listAllConfigurations();
      }

      return response?.statusCode;
    },
  }),
};
