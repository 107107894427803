import React from 'react';
import config from './config';
import moment from 'moment-timezone';

const isToRenderKey = (key: string) => {
  if (key === `_id` || key === `system_id`) {
    return false;
  } else return true;
};

const renderStatus = (value: boolean) => {
  return value ? 'active' : 'inactive';
};

const formatDate = (date: any, key: string) => {
  if (key === `createdAt` || key === `updatedAt`) {
    return date ? moment(new Date(date)).format('MM-DD-YYYY, hh:mm A') : '';
  }
};

const returnLabel = (key: string) => {
  return (
    <span className="badge badge-info spec-info-bdg">
      {transformKeyString(key)}
    </span>
  );
};

const transformKeyString = (key: string) => {
  if (config?.keys && config?.keys.hasOwnProperty(key)) {
    return config.keys[key as keyof typeof config.keys];
  }
};

const returnKey = (key: string) => {
  if (config.relevantKeys.includes(key)) {
    let edited: string = key[0]
      .toUpperCase()
      .concat(key.substring(1))
      .replace('_', ' ');

    return edited
      .split(/(?=[A-Z])/)
      .toString()
      .replace(',', ' ');
  }
};

const returnValue = (val: any, k: string) => {
  if (config.relevantKeys.includes(k)) {
    return val.toString() === 'false'
      ? 'inactive'
      : val.toString() === 'true'
      ? 'active'
      : val;
  }
};

const isContact = (key: any) => {
  return key === `contact` ? true : false;
};

const returnIcon = (key: any) => {
  if (config.icons.hasOwnProperty(key)) {
    return config.icons[key as keyof typeof config.icons];
  }
  return <></>;
};

export const functions = {
  isToRenderKey: isToRenderKey,
  renderStatus: renderStatus,
  returnLabel: returnLabel,
  formatDate: formatDate,
  returnKey: returnKey,
  returnValue: returnValue,
  isContact: isContact,
  returnIcon: returnIcon,
};
