import _ from 'lodash';
import config from './config';

const returnLabel = (str: string) => {
  if (config.labels.hasOwnProperty(str)) {
    return config.labels[str as keyof typeof config.labels];
  }
};

const titlesMapper = (title: string) => {
  switch (title) {
    case 'VC':
      return 'VentConnect';
    case 'PC':
      return 'PressureConnect';
    case 'FH':
      return 'FreshConnect';
    case 'IAQ':
      return 'Indoor Air Quality Sensor';
    case 'G3':
    default:
      return title;
  }
};

const handleLocationsAsOptions = (allData: any) => {
  let locations: any = [];
  let locationsIndex: number = 0;

  if (allData && Object.values(allData).length > 0) {
    Object.values(allData).forEach((item: any) => {
      if (item.hasOwnProperty('file_location')) {
        const locationIndex: number = locations.findIndex(
          (local: any) => local?.value === item['file_location'],
        );

        if (locationIndex === -1) {
          locations[locationsIndex] = {
            id: locationsIndex + 1,
            value: item['file_location'],
          };
          locationsIndex++;
        }
      }
    });
  }

  return _.cloneDeep(locations);
};

export default {
  returnLabel,
  titlesMapper,
  handleLocationsAsOptions,
};
