import { ConnectionBroker } from '../../core';

const FOOBOT_LABEL: string = '@air-foobot';

export const foobot = {
  state: {
    userData: null,
    isConnected: false,
    canRequest: true,
    timeouts: [],
    fooDevices: null,
    fooDevice: null,
  },
  reducers: {
    updateState(state: any, payload: any, name: string) {
      return { ...state, [name]: payload };
    },
    setTimeouts(state: any, payload: any) {
      let timeClone = [...state.timeouts];
      timeClone.push(payload);
      return { ...state, timeouts: timeClone };
    },
  },
  effects: (dispatch: any) => ({
    async setFoobotUserData() {
      const { profile } = dispatch;
      const self: any = this;

      await profile.loadProfile().then((profile: any) => {
        if (profile.statusCode === 200) {
          let { details } = profile.body;
          let { thirdParty } = details;

          if (thirdParty && thirdParty?.foobot) {
            let { username } = thirdParty.foobot;
            if (username) {
              localStorage.setItem(FOOBOT_LABEL, username);
              self.updateState(username, 'userData');
              self.updateState(true, 'isConnected');
            }
          } else {
            localStorage.setItem(FOOBOT_LABEL, '');
            self.updateState(null, 'userData');
            self.updateState(false, 'isConnected');
          }
        }
      });
    },
    async resetFoobotUserData() {
      const self: any = this;
      localStorage.removeItem(FOOBOT_LABEL);
      self.updateState(null, 'userData');
      self.updateState(false, 'isConnected');
    },
    async saveFoobotUserData(payload: any, rootState: any) {
      const self: any = this;
      // const { profile } = dispatch;
      let response: any = await ConnectionBroker.fetchAPI(
        'jitBE',
        'foobot.connect',
        payload,
        null,
      );

      if (response?.statusCode === 200) {
        await self.setFoobotUserData();
        self.updateState(response.body, 'fooDevices');
      }

      return response;
    },
    async disconnect(payload: any, rootState: any) {
      const self: any = this;
      const { profile } = rootState;
      const { account_id } = profile?.user;

      let response: any = await ConnectionBroker.fetchAPI(
        'jitBE',
        'foobot.disconnect',
        { account_id },
        null,
      );
      if (response?.statusCode === 200) {
        self.resetFoobotUserData();
        await self.setFoobotUserData();
        self.updateState(null, 'fooDevices');
      }
      return response;
    },
    async getFoobotDevice(payload: any, rootState: any) {
      const self: any = this;
      let { isConnected } = rootState.foobot;

      if (isConnected) {
        const { device_id } = payload;
        let response = await ConnectionBroker.fetchAPI(
          'jitBE',
          'foobot.show',
          null,
          { id: device_id },
        );

        if (response?.statusCode === 200) {
          self.updateState(response.body, 'fooDevice');
          // self.updateState(false, 'canRequest');
          // let tm = setTimeout(() => {
          //   self.updateState(true, 'canRequest');
          // }, 60*60*1000);
          // self.setTimeouts(tm);
        } else {
          // TODO: add error handler
        }
        return response;
      }
      return;
    },
    async getLastDataPoint(payload: any, rootState: any) {
      let { isConnected } = rootState.foobot;

      if (isConnected) {
        const { device_id } = payload;
        let response = await ConnectionBroker.fetchAPI(
          'jitBE',
          'foobot.lastDataPoint',
          null,
          { id: device_id },
        );

        return response;
      }
      return;
    },

    async refreshDevicesList(payload: any, rootState: any) {
      // const self = this;
      const { devices } = dispatch;
      const { account_id, username } = payload;
      let updatedDevices: any = [];

      let response: any = await ConnectionBroker.fetchAPI(
        'jitBE',
        'foobot.updateDevices',
        { id: account_id, username },
      );

      if (response.statusCode === 200) {
        let upDevicesResp: any = await devices.getAllDevices();
        const { body }: any = upDevicesResp || {};

        if (body && Array.isArray(body) && body.length > 0) {
          updatedDevices = [...body];
        }
      }

      return updatedDevices;
    },
  }),
};
