import React from 'react';

const returnDataPointIndex = (id: string) => {
  switch (id) {
    case 'w-pm25':
      return 1;
    case 'w-co2':
      return 4;
    case 'w-tvoc':
      return 5;
    default:
      break;
  }
};

const handleSectionIntro = (section: string) => {
  switch (section) {
    case 'w-pm25':
      return (
        <div className="row section-data">
          <div className="col section-data">
            <div className="row section-data">
              <span className="slider-label2 section-data intro">PM 2.5</span>
            </div>
            <div className="row section-data">
              <span className="slider-label2--sm section-data">
                PM 2.5 settings
              </span>
            </div>
          </div>
        </div>
      );
    case 'w-co2':
      return (
        <div className="row section-data">
          <div className="col section-data">
            <div className="row section-data">
              <span className="slider-label2 section-data intro">
                CO<sup style={{ top: '5px' }}>2</sup>
              </span>
            </div>
            <div className="row section-data">
              <span className="slider-label2--sm section-data">
                CO<sup>2</sup> settings
              </span>
            </div>
          </div>
        </div>
      );

    case 'w-tvoc':
      return (
        <div className="row section-data">
          <div className="col section-data">
            <div className="row section-data">
              <span className="slider-label2 section-data intro">tVOC</span>
            </div>
            <div className="row section-data">
              <span className="slider-label2--sm section-data">
                tVOC settings
              </span>
            </div>
          </div>
        </div>
      );

    default:
      return '';
  }
};

export const helpers = {
  returnDataPointIndex: returnDataPointIndex,
  handleSectionIntro: handleSectionIntro,
};
