import React from 'react';
import { Form } from 'react-bootstrap';
//import styles from './index.module.scss';

interface CheckProps {
  name: string;
  label?: string;
  handleChange: Function;
  value: boolean;
  type?: string;
}

const InputCheck: React.FC<CheckProps> = ({
  name,
  label,
  handleChange,
  value,
}) => {

  return (
    <div >
     {label&&<label htmlFor={name}>{label}</label>}
      <Form.Check
        id={name}
        type="checkbox"
        defaultChecked={value}
        onClick={() => handleChange(!value)}
      />
    </div>
  );
};

export default InputCheck;
