import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { PeripheralSetup, PeripheralEdit } from './pages';

const peripheral_path = '/peripheral';

const Peripherals: React.FC = props => {
  return (
    <>
      <Switch>
        <Route
          exact
          path={`/dashboard/devices/details${peripheral_path}/setup`}
          component={PeripheralSetup}
        />
        <Route
          path={`/dashboard/devices/details/:device_id${peripheral_path}/setup`}
          component={PeripheralSetup}
        />
        <Route
          exact
          path={`/dashboard/devices/details${peripheral_path}/edit`}
          component={PeripheralEdit}
        />
        <Route
          path={`/dashboard/devices/details/:device_id${peripheral_path}/edit`}
          component={PeripheralEdit}
        />
      </Switch>
    </>
  );
};

export default Peripherals;
