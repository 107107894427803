import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import SweetAlert from 'react-bootstrap-sweetalert';
import { MdOutlineAdd } from 'react-icons/md';
import { Template as RequestTemplate } from 'business/modules/admin/common';
import { CustomTabButton, CustomTabBody, RequestBody } from './components';
import config from './config';
import globalConfigs from 'business/config/config';
import './styles.scss';

const DEFAULT_STR: any = { ...config.strings };
const DEFAULT_LINKS: any = [...config.default.requests.links];
const TAB_TEMPLATE: any = { ...config.default.tab };
const DATA_TEMPLTE: any = { ...config.default.dataTemplate };
const TEMPLATE_ROW: any = { ...config.default.templateRow };
const BASE_URLS: any = [...globalConfigs.apis.jitBE.baseURLs];
let CURRENT_REQUESTS: any;
let DEFAULT_REQUESTS: any;

interface ApiRequestPageProps {
  getLogsBySystem: any;
  getSystemsByUserId: any;
  getSensorsByUserId: any;
}

const ApiRequestPage: React.FC<ApiRequestPageProps> = ({ ...props }) => {
  const [tabIndex, setTabIndex]: any = useState(-1);
  const [currentRequest, setRequests]: any = useState([]);
  const [defaultData, setDefaultData]: any = useState();
  const [loading, setLoading]: any = useState(false);

  const onAddNewRequest = (event: any) => {
    let requestsClone: any = _.cloneDeep(currentRequest);
    const reqNum: number = requestsClone.length + 1;
    let tabDefault: any = _.cloneDeep(TAB_TEMPLATE);

    tabDefault.id = `nav-request-${reqNum}`;
    tabDefault.title = `Request ${reqNum}`;
    tabDefault.data.link.func.onChange = onChangeLink;
    tabDefault.data.params.func.onAddRow = onAddNewRow;
    tabDefault.data.headers.func.onAddRow = onAddNewRow;

    tabDefault.data.params.func.onRemoveRow = onRemoveRow;
    tabDefault.data.headers.func.onRemoveRow = onRemoveRow;

    tabDefault.data.params.func.onValueChange = onValueChange;
    tabDefault.data.headers.func.onValueChange = onValueChange;

    tabDefault.data.params.func.onKeyChange = onKeyChange;
    tabDefault.data.headers.func.onKeyChange = onKeyChange;

    tabDefault.data.body.func.onChange = onChangeBody;

    requestsClone.push(tabDefault);

    setRequests(requestsClone);
  };

  const onChangeLink = (index: any, event: any) => {
    let currentData: any = _.cloneDeep(
      isNaN(index) ? DEFAULT_REQUESTS : CURRENT_REQUESTS,
    );
    let currentLink: any = _.cloneDeep(DEFAULT_LINKS[0]);
    const seletedOption: any =
      event.target.options[event.target.selectedIndex].value;

    if (seletedOption) {
      const seletedLink: any = DEFAULT_LINKS.find(
        (item: any) => item.link === seletedOption,
      );

      if (seletedLink) {
        if (isNaN(index)) {
          currentData.link.value = seletedLink;

          setDefaultData(_.cloneDeep(currentData));
          DEFAULT_REQUESTS = _.cloneDeep(currentData);
        } else {
          currentData[index].data.link.value = seletedLink;

          setRequests(_.cloneDeep(currentData));
          CURRENT_REQUESTS = _.cloneDeep(currentData);
        }
      }
    }
  };

  const onAddNewRow = (_type: string, index: any, event: any) => {
    let currentData: any = _.cloneDeep(
      isNaN(index) ? DEFAULT_REQUESTS : CURRENT_REQUESTS,
    );
    let rowData: any = _.cloneDeep(TEMPLATE_ROW);

    if (isNaN(index)) {
      let lastIndex: number =
        currentData[_type].rows[currentData[_type].rows.length - 1].id;
      rowData.id =
        lastIndex !== null && lastIndex !== undefined ? lastIndex + 1 : 0;

      currentData[_type].rows.push(rowData);
      setDefaultData(_.cloneDeep(currentData));
      DEFAULT_REQUESTS = _.cloneDeep(currentData);
    } else {
      let _lastIndex: number =
        currentData[index].data[_type].rows[
          currentData[index].data[_type].rows.length - 1
        ].id;
      rowData.id =
        _lastIndex !== null && _lastIndex !== undefined ? _lastIndex + 1 : 0;

      currentData[index].data[_type].rows.push(rowData);
      setRequests(_.cloneDeep(currentData));
      CURRENT_REQUESTS = _.cloneDeep(currentData);
    }
  };

  const onRemoveRow = (
    _type: string,
    reqIndex: any,
    index: number,
    event: any,
  ) => {
    let currentData: any = _.cloneDeep(
      isNaN(reqIndex) ? DEFAULT_REQUESTS : CURRENT_REQUESTS,
    );

    if (isNaN(reqIndex)) {
      if (currentData[_type].rows.length > 1) {
        _.remove(currentData[_type].rows, currentData[_type].rows[index]);

        setDefaultData(_.cloneDeep(currentData));
        DEFAULT_REQUESTS = _.cloneDeep(currentData);
      }
    } else {
      if (currentData[reqIndex].data[_type].rows.length > 1) {
        _.remove(
          currentData[reqIndex].data[_type].rows,
          currentData[reqIndex].data[_type].rows[index],
        );

        setRequests(_.cloneDeep(currentData));
        CURRENT_REQUESTS = _.cloneDeep(currentData);
      }
    }
  };

  const onValueChange = (
    _type: string,
    reqIndex: any,
    index: number,
    event: any,
  ) => {
    let currentData: any = _.cloneDeep(
      isNaN(reqIndex) ? DEFAULT_REQUESTS : CURRENT_REQUESTS,
    );

    if (isNaN(reqIndex)) {
      currentData[_type].rows[index].value = event.target.value;

      setDefaultData(_.cloneDeep(currentData));
      DEFAULT_REQUESTS = _.cloneDeep(currentData);
    } else {
      currentData[reqIndex].data[_type].rows[index].value = event.target.value;

      setRequests(_.cloneDeep(currentData));
      CURRENT_REQUESTS = _.cloneDeep(currentData);
    }
  };

  const onKeyChange = (
    _type: string,
    reqIndex: any,
    index: number,
    event: any,
  ) => {
    let currentData: any = _.cloneDeep(
      isNaN(reqIndex) ? DEFAULT_REQUESTS : CURRENT_REQUESTS,
    );

    if (isNaN(reqIndex)) {
      currentData[_type].rows[index].key = event.target.value;

      setDefaultData(_.cloneDeep(currentData));
      DEFAULT_REQUESTS = _.cloneDeep(currentData);
    } else {
      currentData[reqIndex].data[_type].rows[index].key = event.target.value;

      setRequests(_.cloneDeep(currentData));
      CURRENT_REQUESTS = _.cloneDeep(currentData);
    }
  };

  const onChangeBody = (_index: any, event: any) => {
    let currentData: any = _.cloneDeep(
      isNaN(_index) ? DEFAULT_REQUESTS : CURRENT_REQUESTS,
    );

    if (isNaN(_index)) {
      currentData.body.value = event;

      setDefaultData(_.cloneDeep(currentData));
      DEFAULT_REQUESTS = _.cloneDeep(currentData);
    } else {
      currentData[_index].data.body.value = event;

      setRequests(_.cloneDeep(currentData));
      CURRENT_REQUESTS = _.cloneDeep(currentData);
    }
  };

  const compileRequestData = (requestData: any, _type?: number) => {
    const { params, headers, body }: any =
      _type === 1 ? requestData.data : requestData;

    if (params && params.rows && params.rows.length > 0) {
      let respParams: any = {};

      params.rows.forEach((param: any) => {
        const { key, value }: any = param || {};

        if (key && key !== '') {
          respParams[key] = value;
        }
      });

      return respParams;
    }

    return {};
  };

  const selectedRequestHandler = async (requestData: any, params: any) => {
    const { link }: any = tabIndex === -1 ? requestData : requestData.data;

    if (link?.value) {
      const { id }: any = link.value || {};

      switch (id) {
        case 1:
          return await props.getLogsBySystem(params);
        case 2:
          return await props.getSystemsByUserId(params);
        case 3:
          return await props.getSensorsByUserId(params);
        default:
          return;
      }
    }

    return;
  };

  const sendRequestHandler = async (index: any, event: any) => {
    let currentData: any = _.cloneDeep(
      isNaN(index) ? DEFAULT_REQUESTS : CURRENT_REQUESTS,
    );
    let params: any = {};

    setLoading(true);

    if (tabIndex === -1) {
      params = compileRequestData(currentData);
    } else {
      const currentRequestData: any = _.cloneDeep(currentData[tabIndex]);
      params = compileRequestData(currentRequestData, 1);
    }

    const requestResult: any = await selectedRequestHandler(
      tabIndex === -1 ? currentData : currentData[tabIndex],
      params,
    );

    if (requestResult?.ok) {
      const currentResp: any = _.cloneDeep(requestResult.data);
      const strResp: string = JSON.stringify(currentResp, null, 2);

      if (tabIndex === -1) {
        currentData.response = strResp;
        setDefaultData(_.cloneDeep(currentData));
        DEFAULT_REQUESTS = _.cloneDeep(currentData);
      } else {
        currentData[index].data.response = strResp;
        setRequests(_.cloneDeep(currentData));
        CURRENT_REQUESTS = _.cloneDeep(currentData);
      }
    }

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    if (currentRequest.length > 0) {
      CURRENT_REQUESTS = _.cloneDeep(currentRequest);
    }

    if (defaultData) {
      DEFAULT_REQUESTS = _.cloneDeep(defaultData);
    }
  }, [currentRequest, defaultData]);

  useEffect(() => {
    let _defaultData: any = _.cloneDeep(DATA_TEMPLTE);
    _defaultData.link.func.onChange = onChangeLink;
    _defaultData.params.func.onAddRow = onAddNewRow;
    _defaultData.headers.func.onAddRow = onAddNewRow;

    _defaultData.params.func.onRemoveRow = onRemoveRow;
    _defaultData.headers.func.onRemoveRow = onRemoveRow;

    _defaultData.params.func.onValueChange = onValueChange;
    _defaultData.headers.func.onValueChange = onValueChange;

    _defaultData.params.func.onKeyChange = onKeyChange;
    _defaultData.headers.func.onKeyChange = onKeyChange;

    _defaultData.body.func.onChange = onChangeBody;

    setDefaultData(_defaultData);
    DEFAULT_REQUESTS = _.cloneDeep(_defaultData);

    return () => {};
  }, []);

  return (
    <RequestTemplate id="api-request--container" title={DEFAULT_STR?.title}>
      <div className="api-request--main-container">
        <nav>
          <div
            id="api-request--nav-tab"
            className="nav nav-tabs"
            role="api-request-tablist"
          >
            <button
              id="nav-home-tab"
              className="nav-link active"
              data-bs-toggle="tab"
              data-bs-target="#nav-home"
              aria-controls="nav-home"
              aria-selected="true"
              role="tab"
              type="button"
              onClick={(event: any) => {
                setTabIndex(-1);
              }}
            >
              Default
            </button>
            {currentRequest.length > 0 &&
              currentRequest.map((req: any, index: number) => {
                const { id, title }: any = req || {};
                return (
                  <CustomTabButton
                    key={'tab--' + index}
                    id={id}
                    title={title}
                    onTabClick={(event: any) => {
                      setTabIndex(index);
                    }}
                  />
                );
              })}
            <button
              id="nav-add"
              className="nav-link add-request"
              type="button"
              onClick={onAddNewRequest}
            >
              <MdOutlineAdd className="add-request-icon" />
            </button>
          </div>
        </nav>
        <div id="api-request--nav-tabContent" className="tab-content">
          <div
            id="nav-home"
            className="tab-pane fade show active"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
          >
            {defaultData && (
              <RequestBody
                index="body--0"
                loading={loading}
                onSend={sendRequestHandler}
                links={DEFAULT_LINKS}
                baseUrl={BASE_URLS[0]}
                params={defaultData?.params || {}}
                headers={defaultData?.headers || {}}
                body={defaultData?.body || {}}
                response={defaultData?.response || {}}
                link={defaultData?.link || {}}
              />
            )}
          </div>
          {currentRequest.length > 0 &&
            currentRequest.map((req: any, index: number) => {
              const { id, data }: any = req || {};
              return (
                <CustomTabBody id={id} key={'body--' + index}>
                  <RequestBody
                    index={index}
                    loading={loading}
                    onSend={sendRequestHandler}
                    links={DEFAULT_LINKS}
                    baseUrl={BASE_URLS[0]}
                    params={data?.params || {}}
                    headers={data?.headers || {}}
                    body={data?.body || {}}
                    response={data?.response || {}}
                    link={data?.link || {}}
                  />
                </CustomTabBody>
              );
            })}
        </div>
      </div>
    </RequestTemplate>
  );
};

function mapStateToProps(state: any) {
  const { adminApiRequests }: any = state || {};
  return {};
}

function mapDispatchToProps(dispatch: any) {
  const { adminApiRequests }: any = dispatch || {};

  return {
    getLogsBySystem: adminApiRequests.getSystemLogs,
    getSystemsByUserId: adminApiRequests.getSystemsByUserId,
    getSensorsByUserId: adminApiRequests.getSensorsByUserId,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApiRequestPage);
