export default {
  add: {
    title: `Add device`,
    breadcrumbItem: `Add device`,
    mainTitle: `Device details`,
    pText: `Add installers' device information.`,
    route: `/admin/dashboard/company/installers/devices/edit-add-device`,
  },
  fields: {
    status: '',
    device: '',
    state: '',
    city: '',
    macAddress: '',
    zipCode: '',
  },
  devState: {
    device: '',
    state: '',
    city: '',
    macAddress: '',
    zipCode: '',
  },
  modalStrings: {
    edit: `Add device`,
    confirm: ``,
    warning: `Information`,
    alertStr: ``,
    alertStr2: ``,
    confirmation: ``,
    sureToProceed: `Are you sure you wish to proceed?`,
    noUndo: ``,
    aboutToUpdate: `You are about to add a new device.`,
    labelOK: ` OK`,
  },
  zipRegex: '[0-9][0-9\\- ]{0,10}[0-9]$',
  macAddress: '/^[0-9a-f]{1,2}([.:-])[0-9a-f]{1,2}(?:\x01[0-9a-f]{1,2}){4}$/i',
  errorMsg: {
    device: 'Device name should not be blank.',
    state: 'State should be at least 2 characters long.',
    city: 'City should be at least 2 characters long.',
    macAddress: 'Mac Address is invalid.',
    zipCode: 'Invalid zipcode.',
  },
};
