export default {
  firmwareItems: ['G3', 'VC', 'FH', 'FT', 'PC', 'IQA'],
  firmwareObject: {
    G3: {
      latest_version: '',
      file_name: '',
      file_location: '',
    },
    VC: {
      latest_version: '',
      file_name: '',
      file_location: '',
    },
    FH: {
      latest_version: '',
      file_name: '',
      file_location: '',
    },
    FT: {
      latest_version: '',
      file_name: '',
      file_location: '',
    },
    PC: {
      latest_version: '',
      file_name: '',
      file_location: '',
    },
    IQA: {
      latest_version: '',
      file_name: '',
      file_location: '',
    },
  },
  editStates: {
    G3: 'normal',
    VC: 'normal',
    FH: 'normal',
    FT: 'normal',
    PC: 'normal',
    IQA: 'normal',
  },
  labels: {
    latest_version: 'Latest version',
    file_name: 'File name',
    file_location: 'File location',
  },
  strings: {
    alert: {
      btn: {
        confirm: 'Update',
      },
      question: 'Do you realy want to save changes ?',
    },
    notifications: {
      success: 'Firmware updated successfully!',
    },
  },
  constraints: {
    latest_version: {
      presence: true,
      length: {
        minimum: 3,
      },
    },
    file_name: {
      presence: true,
      length: {
        minimum: 3,
      },
    },
    file_location: {
      presence: true,
      length: {
        minimum: 3,
      },
    },
  },
  modalStrings: {
    edit: `Save changes`,
    confirm: ``,
    warning: `Information`,
    alertStr: ``,
    alertStr2: ``,
    confirmation: ``,
    sureToProceed: `Are you sure you wish to proceed?`,
    noUndo: ``,
    aboutToUpdate: `You are about to update this firmware.`,
    labelOK: ` OK`,
  },
};
