import React, { useEffect, useState } from 'react';
import config from './config';
import numeral from 'numeral';
import { connect } from 'react-redux';
import { SensorsProps } from './interface';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { io } from 'socket.io-client';
import _ from 'lodash';
import { useAuth } from '../../../../../../../sessions/hooks/auth';
import * as globalConfigs from '../../../../../../../../config/config';
import { AirThingsLogo1, FoobotLogo, AwairLogo } from 'business/assets';
import { ApiComponent } from 'business/modules/admin/common';
import { MdInfoOutline } from 'react-icons/md';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './styles.scss';

const MODEL_FORMAT: any = { ...config.model };
let INTERVALS: any = [];
let WIDGETSTATES_COPY: any = {};
let WEB_SOCKETS: any = {};
let REQUEST_STATES: any = {};

const Sensors: React.FC<SensorsProps> = ({
  sectionData,
  element,
  setIAadmDetailsLoaded,
  indoorAirAdmDetails,
}) => {
  const { adminToken } = useAuth();
  const [expandedCtrl, setIsExpandedCtrl] = useState<boolean>(false);
  const [apiIsHidden, setApiIsHidden] = useState<boolean>(true);
  const [widgetStates, setWidgetStates]: any = useState({
    pm25: { ...MODEL_FORMAT },
    co2: { ...MODEL_FORMAT },
    tVoc: { ...MODEL_FORMAT },
  });

  const icons: any = {
    foobot: <FoobotLogo className="foo-logo-1" />,
    'awair-element': <AwairLogo className="awair-logo-1" />,
    'awair-omni': <AwairLogo className="awair-logo-1" />,
    airthings: <AirThingsLogo1 className="airthings-logo-1" />,
  };

  const toggleApiVisibility = () => {
    apiIsHidden ? setApiIsHidden(false) : setApiIsHidden(true);
  };

  const handleChange = (panel: string) => (event: any, isExpanded: any) => {
    setIsExpandedCtrl(isExpanded);
  };

  const getCurrentValsForSensors = (
    sensorId: string,
    setIAadmDetailsLoaded: boolean,
    indoorAirAdmDetails: any,
  ) => {
    let data: any;
    if (setIAadmDetailsLoaded && indoorAirAdmDetails.length > 0) {
      indoorAirAdmDetails.map((item: any) => {
        if (item.deviceId === sensorId) {
          data = item;
        }
      });
    }

    return data || {};
  };

  const returnValue = (id: string) => {
    if (element.hasOwnProperty(id)) {
      if (id === 'isSystem') {
        return element?.[id].toString();
      }
      return element?.[id] !== null ? element?.[id] : 'No values.';
    }
    if (element?.details?.hasOwnProperty(id)) {
      return element?.details?.[id] !== null
        ? element?.details?.[id]
        : 'No values.';
    }
    if (element?.details?.data?.hasOwnProperty(id)) {
      return element?.details?.data?.[id] !== null
        ? element?.details?.data?.[id] + '  ' + ',' + '  '
        : 'No values.';
    } else {
      return 'No values.';
    }
  };

  const mapAirthingsDatapointProps = (prop: string) => {
    switch (prop) {
      case 'pm25':
        return 'pm';
      case 'tVoc':
        return 'voc';
      default:
        return prop;
    }
  };

  const updateCurrentStates = (data: any) => {
    const { datapoints, sensors } = data;
    const currentProps: any = ['pm25', 'co2', 'tVoc'];
    let prevValuesClone: any = _.cloneDeep(WIDGETSTATES_COPY);

    currentProps.forEach((prop: string) => {
      let tempIndex: number = sensors.findIndex(
        (sens: any) => sens === mapAirthingsDatapointProps(prop),
      );

      if (
        tempIndex > -1 &&
        datapoints &&
        datapoints.length > 0 &&
        datapoints[0][tempIndex] !== null &&
        datapoints[0][tempIndex] !== undefined
      ) {
        prevValuesClone[prop].current = datapoints[0][tempIndex];
      }
    });

    return _.cloneDeep(prevValuesClone);
  };

  const startSocket = async (
    _device: any,
    deviceId: string,
    data: any,
    channel: string,
  ) => {
    const { device_id }: any = _device || {};
    const _token: string = adminToken?.token;
    const { path, process } = data;
    let channelSTR: string = `${channel}-${deviceId}`;

    const socket: any =
      globalConfigs?.default?.apis?.jitBE?.baseSocketURLs &&
      globalConfigs?.default?.apis?.jitBE?.baseSocketURLs[0]
        ? io(globalConfigs?.default?.apis?.jitBE?.baseSocketURLs[0], {
            path: '/socket.io',
            transports: ['websocket'],
            autoConnect: false,
            withCredentials: true,
            auth: {
              token: _token,
              path: path,
              process: process,
              channel: channel,
              name: deviceId,
              channelName: channelSTR,
            },
          })
        : null;

    if (socket) {
      WEB_SOCKETS[device_id] = {
        socket: socket.connect(),
        device: _device,
        channel: channelSTR,
        connected: false,
      };

      WEB_SOCKETS[device_id].socket.on('connect', () => {
        WEB_SOCKETS[device_id].connected = true;
      });

      WEB_SOCKETS[device_id].socket.on('disconnect', (reason: any) => {});

      WEB_SOCKETS[device_id].socket.on('connect_error', (error: any) => {});

      WEB_SOCKETS[device_id].socket.on(
        WEB_SOCKETS[device_id].channel,
        (data: any) => {
          const { datapoint } = data || {};

          if (datapoint) {
            let tempData: any = updateCurrentStates(datapoint);

            if (tempData && Object.keys(tempData).length > 0) {
              let tempStatesClone: any = _.cloneDeep(WIDGETSTATES_COPY);

              for (const prop in tempData) {
                tempStatesClone = {
                  ...tempStatesClone,
                  [prop]: tempData[prop],
                };
              }

              setWidgetStates(_.cloneDeep(tempStatesClone));
            }
          }
        },
      );
    }
  };

  const destroySocket = (_device: any) => {
    const { device_id }: any = _device || {};

    if (
      WEB_SOCKETS[device_id] !== null &&
      WEB_SOCKETS[device_id] !== undefined
    ) {
      WEB_SOCKETS[device_id].socket.off('connect');
      WEB_SOCKETS[device_id].socket.off('disconnect');
      WEB_SOCKETS[device_id].socket.off('connect_error');

      if (WEB_SOCKETS[device_id].channel) {
        WEB_SOCKETS[device_id].socket.off(WEB_SOCKETS[device_id].channel);
      }

      WEB_SOCKETS[device_id].socket.disconnect();
      WEB_SOCKETS[device_id] = null;
      delete WEB_SOCKETS[device_id];
    }
  };

  useEffect(() => {
    if (widgetStates !== null && widgetStates !== undefined) {
      WIDGETSTATES_COPY = _.cloneDeep({ ...widgetStates });
    }
  }, [widgetStates]);

  useEffect(() => {
    return () => {
      if (INTERVALS && INTERVALS.length > 0) {
        for (const interval of INTERVALS) {
          clearInterval(interval);
        }
        INTERVALS = [];
      }

      if (REQUEST_STATES && Object.keys(REQUEST_STATES).length > 0) {
        for (const key in REQUEST_STATES) {
          REQUEST_STATES[key] = false;
        }
      }

      destroySocket(element);
    };
  }, []);

  useEffect(() => {
    if (element) {
      (async () => {
        if (
          element?.device_type === 'airthings' &&
          !WEB_SOCKETS.hasOwnProperty(element?.device_id)
        ) {
          startSocket(
            element,
            element?.details?.uuid,
            { path: 'notifications', process: 'airthings' },
            'Admin-AirThings',
          );
        } else if (
          (element?.device_type === 'awair' ||
            element?.device_type === 'awair-element' ||
            element?.device_type === 'awair-omni') &&
          !WEB_SOCKETS.hasOwnProperty(element?.device_id)
        ) {
          startSocket(
            element,
            element?.details?.uuid,
            { path: 'notifications', process: 'awair' },
            'Admin-Awair',
          );
        } else if (
          element?.device_type === 'aircycler-local' &&
          !WEB_SOCKETS.hasOwnProperty(element?.device_id)
        ) {
          startSocket(
            element,
            element?.details?.uuid,
            { path: 'notifications', process: 'Local.AirCycler' },
            'Admin-Local.AirCycler',
          );
        }
      })();
    }
  }, [element]);

  useEffect(() => {
    //set the current sensor values on page loading
    if (setIAadmDetailsLoaded && element) {
      let result: any = getCurrentValsForSensors(
        element.device_id,
        setIAadmDetailsLoaded,
        indoorAirAdmDetails,
      );

      if (Object.keys(result).length > 0) {
        if (element?.device_id === Object.values(result)[0]) {
          setWidgetStates((prevStates: any) => {
            return {
              ...prevStates,
              co2: {
                ...prevStates.co2,
                current: result?.co2,
              },
              pm25: {
                ...prevStates.pm25,
                current: result?.pm,
              },
              tVoc: {
                ...prevStates.tVoc,
                current: result?.tVoc,
              },
            };
          });
        }
      }
    }
  }, [setIAadmDetailsLoaded, indoorAirAdmDetails, element]);

  return (
    <div style={{ marginBottom: '16px', marginTop: '16px' }}>
      <Accordion
        className={sectionData.className}
        square
        expanded={expandedCtrl}
        onChange={handleChange('sensors-adm')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={''}
          id={''}
        >
          <div className="container admin-section-container cont-m-h sensor-accordion">
            <div className={`row styles-row-c`}>
              <div className={`col pdg-l--5`}>
                <h5 className={`card-title mg-btm-0-0`}>
                  <div className="container col-cont-sensor--logo">
                    <div className="row sensor-r-line1">
                      <div className="col-auto col-offset-1 col-cont-sensor--logo">
                        {icons[element.device_type as keyof typeof icons]}
                      </div>
                    </div>
                  </div>
                </h5>
              </div>
            </div>

            <div className="row">
              <div className="col-12 lead lead-sm-ft mrg-btm">
                {' '}
                {element.device_type}
              </div>
              <div className={`col-4 left--side atc--cont`}>
                <span className={`values-label--style left-labels`}>
                  {numeral(widgetStates?.pm25?.current).format(
                    config?.formats?.ugm3,
                  )}{' '}
                  {config?.units?.ugm}
                  {<sup>3</sup>}
                </span>
              </div>

              <div className={`col-4 y atc--cont`}>
                <span className={`values-label--style`}>
                  {numeral(widgetStates?.co2?.current).format(
                    config?.formats?.ppm,
                  )}{' '}
                  {config?.units?.ppm}
                </span>
              </div>

              <div className={`col-4 right--side atc--cont`}>
                <span className={`values-label--style right-labels`}>
                  {numeral(widgetStates?.tVoc?.current).format(
                    config?.formats?.ppb,
                  )}{' '}
                  {config?.units?.ppb}
                </span>
              </div>
            </div>

            <div className="row">
              <div className={`col-4 left--side atc--cont`}>
                <span className={`bottom-label-style left-labels`}>
                  {' '}
                  {config?.units?.pm25}
                </span>
              </div>

              <div className={`col-4 y atc--cont`}>
                <span className={`bottom-label-style`}>
                  {' '}
                  {config?.units?.co}
                  <sub>2</sub>
                </span>
              </div>

              <div className={`col-4 right--side atc--cont`}>
                <span className={`bottom-label-style right-labels`}>
                  {' '}
                  {config?.units?.tVoc}
                </span>
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="container dev-details-container no-side-space">
            <div className="row no-left-space">
              <div className="col">
                <div className="card crd-details align-left">
                  <div className="card-body spec-crd-body">
                    <h5 className="card-title">{`Device details`}</h5>
                    <h6 className="card-subtitle mb-2 text-muted">
                      <MdInfoOutline className="info-icon" />
                      {`Information`}
                    </h6>

                    <div className="card-text sensors-and-api">
                      {config.sensorsData.map((label: any, index: number) => {
                        if (label.id === `api_key`) {
                          return (
                            <ApiComponent
                              id="sensors-api-display"
                              className={`container api-box mb-1`}
                              className2={`col-auto api-box`}
                              className3={`col api-box api-box-r`}
                              key={index}
                              onClick={toggleApiVisibility}
                              apiInfo={
                                !apiIsHidden ? (
                                  <AiOutlineEye className="sensors-icon" />
                                ) : (
                                  <AiOutlineEyeInvisible className="sensors-icon" />
                                )
                              }
                              txt={!apiIsHidden && returnValue(label.id)}
                            />
                          );
                        }
                        return (
                          label.label !== 'isSystem' && (
                            <div
                              className="container cont-inf sensor--data--spaces"
                              key={index}
                            >
                              <div className="row details-information sensor--data--spaces">
                                <div className="col sensor--data--spaces">
                                  <span className="lead stronger ft-size12">
                                    {label.label}:
                                  </span>{' '}
                                </div>

                                <div className="col-auto sensor--data--spaces">
                                  <span className="lead ft-size12">
                                    {Array.isArray(returnValue(label.id))
                                      ? returnValue(label.id).map(
                                          (item: any, index: number) => {
                                            return (
                                              <span
                                                className="red-span"
                                                key={index}
                                              >
                                                {item}
                                              </span>
                                            );
                                          },
                                        )
                                      : returnValue(label.id)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )
                        );
                      })}
                    </div>
                    <hr style={{ color: 'white' }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

function mapStateToProps(state: any) {
  const { adminSectionsState } = state;
  return {
    setIAadmDetailsLoaded: adminSectionsState.setIAadmDetailsLoaded,
    indoorAirAdmDetails: adminSectionsState.indoorAirAdmDetails,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { loader } = dispatch;
  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Sensors);
