import React from 'react';
import { Input } from 'react-components';
import { parseNumber } from '../../../../helpers';
import './styles.scss';


interface RangeTimeProps {
  onChangeState: (name: string, value: any) => void;
  config: any;
  fieldsState: any;
  errors: any;
}

const RangeTime: React.FC<RangeTimeProps> = ({
  onChangeState,
  config,
  fieldsState,
  errors,
}) => {
  return (
    <div
      id="installer-container-hours"
      className="installer-container-hours container-hours"
    >
      <div className="installer-container-hours--row">
        <Input
          handleChange={(value: any) => {
            onChangeState('hrsv', parseNumber(value));
          }}
          handleChangeError={(err: any) => {}}
          disabled={false}
          type={config.fields.hrsv.type}
          label={config.fields.hrsv.label}
          value={`${fieldsState?.hrsv}`}
          error={errors?.hrsv}
          name={'hrsv'}
        />
      </div>
      <div className="installer-container-hours--row row-hours--left">
        <Input
          handleChange={(value: any) => {
            onChangeState('hstv', parseNumber(value));
          }}
          handleChangeError={(err: any) => {}}
          disabled={false}
          type={config.fields.hstv.type}
          label={config.fields.hstv.label}
          value={`${fieldsState?.hstv}`}
          error={errors?.hstv}
          name={'hstv'}
        />
      </div>
    </div>
  );
};

export default RangeTime;
