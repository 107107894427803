import config from './config';


const TAGS_SETUP: any = { ...config.defaults.ventSetupTags };
const G3_LABEL: string = config.defaults.g3CoreLabel || '';



const parseNumber = (value='') =>{
  return  value.replace(/[^0-9]/g, '') === ''
      ? 0
      : parseInt(value.replace(/[^0-9]/g, ''));
};


const filterSensorName = (sensor: any) => {
  const { device_name, device_type, details }: any = sensor || {};

  if(
    device_type === 'awair-omni' ||
    device_type === 'aircycler-local'
  ){
    if(details?.name === 'LOCAL' || details?.status?.includes('local')){
      return !device_name || device_name === '' ? 'NO NAME SENSOR' : `${device_name} ( Local )`;
    }
  }

  return !device_name || device_name === '' ? 'NO NAME SENSOR' : device_name;
};


const disableLocalSensor = (allSensors: any, device: any) => {
  const { conf }: any = device?.details?.settings || {};

  if(conf){
    const { lsnm }: any = conf || {};
    const localSensorLabel: string =
      lsnm !== null && lsnm !== undefined && lsnm !== '' ? `${lsnm}-local` : '';
    const foundSensor: any =
      allSensors && allSensors.length > 0
        ? allSensors.find(({ device_type, details }: any, index: number) => {
            if (
              device_type === 'awair-omni' ||
              device_type === 'aircycler-local'
            ) {
              return (
                (
                  details?.name === 'LOCAL' ||
                  details?.status?.includes('local')
                ) &&
                (
                  details.uuid === localSensorLabel ||
                  details.uuid === lsnm ||
                  details.id === localSensorLabel ||
                  details.id == lsnm
                )
              );
            }
            return false;
          })
        : null;

    if (
      localSensorLabel === foundSensor?.details?.uuid
    ) {
      return true;
    }
  }

  return false;
};


const disableLocalSensorOption = (sensor: any) => {
  const { device_type, details }: any = sensor || {};

  if(
    device_type === 'awair-omni' ||
    device_type === 'aircycler-local'
  ){
    return details?.name === 'LOCAL' || details?.status?.includes('local');
  }

  return false;
};


const mapConnectedLabel = (devId: any) => {
  let label: string = '';

  if (devId !== null && devId !== undefined) {
    if (devId.startsWith('AirCyclerVC')) label = 'VentConnect';
    if (devId.startsWith('AirCyclerPC')) label = 'PressureConnect';
    if (devId.startsWith('AirCyclerFH')) label = 'FreshConnect';
    if (devId.startsWith('AirCyclerFT')) label = 'FreshConnect';
  }

  return label;
};


const mapVentSetupMode = (mode: string) => {
  switch (mode) {
    case 'Calculated Flow':
      return TAGS_SETUP.calcFlow;
    case 'Calculated Time':
      return TAGS_SETUP.calcTime;
    case 'Balance Mode':
      return TAGS_SETUP.balanceMode;
    default:
      return [];
  }
};


const mapIconClassName = (type: any) => {
  let className: string = '';

  switch (type) {
    case 'awair':
    case 'awair-element':
    case 'awair-omni':
      className = 'awair-icon';
      break;
    default:
      break;
  }

  return className;
};


const filterConnDeviceName = (fieldsValues: any) => {
  const { primary_control }: any = fieldsValues || {};

  return !!primary_control && primary_control !== ''
    ? primary_control
    : G3_LABEL;
};


const preparePayload = (fieldsData: any, outsideData: any, indoorData: any) => {
  let payload: any = {
    name: 'System Settings Configuration',
    details: {},
    settings: {},
    setup: {
      outsideAir: outsideData ? { ...outsideData } : {},
      indoorAir: indoorData ? { ...indoorData } : {}
    },
  };

  if(fieldsData && Object.keys(fieldsData).length > 0){
    const { config_name } = fieldsData;

    payload.name = config_name;

    Object.keys(fieldsData).forEach((field: string) => {
      if(field !== 'config_name'){
        payload.settings[field] = fieldsData[field];
      }
    });
  }

  return payload;
};


export {
  parseNumber,
  filterSensorName,
  disableLocalSensor,
  disableLocalSensorOption,
  mapConnectedLabel,
  mapVentSetupMode,
  mapIconClassName,
  filterConnDeviceName,
  preparePayload,
};
