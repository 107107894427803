import React from 'react';
import { Input } from 'react-components';
import { RangeTimeProps } from './interface';
import { parseNumber } from '../../../../helpers';
import './styles.scss';


const RangeTime: React.FC<RangeTimeProps> = ({
  onChangeState,
  config,
  fieldsState,
  errors,
}) => {

  return (
    <div id="user--edit-hours" className="container-hours">
      <div className="user--edit-hours--row">
        <Input
          handleChange={(value: any) => {
            onChangeState('hrsv', parseNumber(value));
          }}
          handleChangeError={(err: any) => {}}
          disabled={false}
          type={config.fields.hrsv.type}
          label={config.fields.hrsv.label}
          value={`${fieldsState?.hrsv}`}
          error={errors?.hrsv}
          name={'hrsv'}
        />
      </div>
      <div className="user--edit-hours--row hours--row-left">
        <Input
          handleChange={(value: any) => {
            onChangeState('hstv', parseNumber(value));
          }}
          handleChangeError={(err: any) => {}}
          disabled={false}
          type={config.fields.hstv.type}
          label={config.fields.hstv.label}
          value={`${fieldsState?.hstv}`}
          error={errors?.hstv}
          name={'hstv'}
        />
      </div>
    </div>
  );
};

export default RangeTime;
