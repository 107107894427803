// @ts-nocheck
const isValueInit = (value: number, prop: string) => {
  return (
    value[prop] && value[prop].hasOwnProperty('current') && value[prop].init
  );
};

const convertKey = (key: string) => {
  switch (key) {
    case 'damper':
      return 'dmst';
    case 'centralFan':
      return 'cfcm';
    default:
      return key;
  }
};

const extractPeripherals = (_details: any) => {
  const { conf } = _details?.settings || {};
  const cdvsClone: any = conf?.cdvs ? _.cloneDeep(conf.cdvs) : [];
  return {
    ventConnect: cdvsClone.filter((item: any) =>
      item.cdid.startsWith('AirCyclerVC'),
    ),
    fanConnect: conf?.fcsp === 1 || conf?.fcsp === '1',
    damper: true,
    pressureConnect: cdvsClone.filter((item: any) =>
      item.cdid.startsWith('AirCyclerPC'),
    ),
    freshConnect: cdvsClone.filter(
      (item: any) =>
        item.cdid.startsWith('AirCyclerFH') ||
        item.cdid.startsWith('AirCyclerFT'),
    ),
    centralFan: true,
  };
};

const prepareCkboxStates = (connectedDevs: any) => {
  let tempStates: any = {};
  if (Object.values(connectedDevs).length > 0) {
    Object.keys(connectedDevs).forEach((key: string) => {
      if (key === 'fanConnect') {
        tempStates.fanConnect = false;
      } else if (key === 'damper') {
        tempStates.dmst = false;
      } else if (key === 'centralFan') {
        tempStates.cfcm = false;
      } else {
        connectedDevs[key].forEach((cDev: any) => {
          const { cdid } = cDev;
          if (!tempStates[key]) tempStates[key] = {};
          tempStates[key][cdid] = false;
        });
      }
    });
  }
  return tempStates;
};

const getPeripheralsList = (peripherals: any) => {
  if (peripherals && Object.values(peripherals).length > 0) {
    let tempElements: any = [];
    if (peripherals?.ventConnect) {
      peripherals.ventConnect.forEach((periph: any, i: number) => {
        const { cdid, cail } = periph;
        tempElements.push({
          name: `VentConnect`,
          type: 'ventConnect',
          alias: cail,
          id: cdid,
        });
      });
    }

    if (peripherals?.damper) {
      tempElements.push({
        name: `Damper`,
        type: 'damper',
        alias: null,
        id: 'dmst',
      });
    }

    if (peripherals?.fanConnect) {
      tempElements.push({
        name: `FanConnect`,
        type: 'fanConnect',
        alias: null,
        id: 'fanConnect',
      });
    }

    if (peripherals?.pressureConnect) {
      peripherals.pressureConnect.forEach((periph: any, i: number) => {
        const { cdid } = periph;
        tempElements.push({
          name: `PressureConnect`,
          type: 'pressureConnect',
          alias: null,
          id: cdid,
        });
      });
    }

    if (peripherals?.freshConnect) {
      peripherals.freshConnect.forEach((periph: any, i: number) => {
        const { cdid } = periph;
        tempElements.push({
          name: `FreshConnect`,
          type: 'freshConnect',
          alias: null,
          id: cdid,
        });
      });
    }

    if (peripherals?.centralFan) {
      tempElements.push({
        name: `Central Fan`,
        type: 'centralFan',
        alias: null,
        id: 'cfcm',
      });
    }

    return tempElements;
  }
};

const isVCorFC = (value: any) => {
  return value.name === `VentConnect` || value.name === `FreshConnect`
    ? true
    : false;
};

const renderLabel = (value: any) => {
  if (value?.id[9] === 'V') {
    return `VentConnect`;
  } else if (value?.id[9] === 'F') {
    return 'FreshConnect2';
  } else {
    return '';
  }
};

const convertKeyFn = (key: string) => {
  switch (key) {
    case 'damper':
    case 'dmst':
      return 'dmst';
    case 'centralFan':
    case 'cfcm':
      return 'cfcm';
    case 'freshConnect':
      return 'ventConnect';

    default:
      return key;
  }
};

const retrieveValue = (_type: any, _ckData: any, _id: any) => {
  let tp = convertKeyFn(_type);
  if (_ckData) {
    if (
      _type === 'fanConnect' ||
      _type === 'damper' ||
      _type === 'centralFan'
    ) {
      return _ckData[tp];
    }
    if (_type === 'freshConnect') {
      return _ckData && _ckData[tp] && _ckData[tp][_id] ? _ckData[tp][_id] : 0;
    } else {
      return _ckData && _ckData[_type] && _ckData[_type][_id]
        ? _ckData[_type][_id]
        : 0;
    }
  }
};

export const helpers = {
  isVCorFC: isVCorFC,
  renderLabel: renderLabel,
  getPeripheralsList: getPeripheralsList,
  isValueInit: isValueInit,
  convertKey: convertKey,
  extractPeripherals: extractPeripherals,
  prepareCkboxStates: prepareCkboxStates,
  convertKeyFn: convertKeyFn,
  retrieveValue: retrieveValue,
};
