import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { AllSystems, EditSystem, SystemDetails } from './pages';


const systems_path = '/systems';

const Systems: React.FC = props => {
  return (
    <>
      <BreadcrumbsItem to={`/installer${systems_path}`}>
        Systems
      </BreadcrumbsItem>
      <Switch>
        <Route exact path={`/installer${systems_path}`} component={AllSystems} />
        <Route exact path={`/installer${systems_path}/edit`} component={EditSystem} />
        <Route path={`/installer${systems_path}/details`} component={SystemDetails} />
        <Route path={`*`} component={AllSystems} />
      </Switch>
    </>
  );
};

export default Systems;
