import React, { useEffect, useState } from 'react';
import {
  Cell,
  Sector,
  Legend,
} from 'recharts';
import './styles.scss';

interface ActiveShapeProps {
  cx: any;
  cy: any;
  midAngle: any;
  innerRadius: any;
  outerRadius: any;
  startAngle: any;
  endAngle: any;
  fill: any;
  payload: any;
  percent: number;
  y: number;
  vtPercentage: any;
  minutes: any;
  stoped: any;
}

const ActiveShape: React.FC<ActiveShapeProps> = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
  fill,
  payload,
  percent,
  y,
  vtPercentage,
  minutes,
  stoped,
  ...props
}) => {
  const [sliceName, setSliceName]: any = useState();
  const RADIAN = Math.PI / 185;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 12) * cos;
  const my = cy + (outerRadius + 12) * sin;
  // const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ex = (mx + (cos >= 0 ? 1 : -1) * 22) + (cos >= 0 ? 5 : -5);
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';
  const yDoneVal = cy - 40;


  useEffect(() => {
    const { name }: any = props || {};

    setSliceName(
      name !== null && name !== undefined ? name : null
    );
  }, [props]);


  return (
    <g id="custom-chart-tools" className={`${stoped ? 'title-opak' : ''}`}>
      <text x={cx} y={yDoneVal} dy={8} textAnchor="middle" fill={'#818181'} className="chart-tools--title">
        <tspan x={cx} y={yDoneVal} dy={-20} className="chart-tools--title-value" >
          {parseInt(vtPercentage)}%
        </tspan>
        <tspan x={cx} y={yDoneVal} dy={10} className="chart-tools--title-label">
          Complete
        </tspan>
        <tspan x={cx} y={yDoneVal} dy={40} className="chart-tools--title-mins">
          {minutes?._left || 0} MIN REMAINING
        </tspan>
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${y} Min`}</text>

    </g>
  );
};

export default ActiveShape;
