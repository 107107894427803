export default {
  strings: {
    title: `Create user`,
    breadcrumbItem: `/admin/dashboard/create-user`,
    breadcrumbStr: `Create user`,
    mainTitle: `New user`,
    pText: `Create a profile.`,
    role: `Chose a role`,
  },
  regex: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  fields: [
    {
      outerClass: 'form-group',
      label: 'Email address',
      htmlFor: 'exampleInputEmail1',
      type: 'email',
      id: 'exampleInputEmail1',
      ariaDescribedby: 'emailHelp',
      placeholder: 'Enter email',
      className: 'form-control mb-1',
      labelClass: 'lead label-create-user',
      name: 'email',
    },

    {
      outerClass: 'form-group',
      label: 'Password',
      htmlFor: 'exampleInputPassword1',
      type: 'password',
      id: 'exampleInputPassword1',
      ariaDescribedby: ' ',
      placeholder: 'Password',
      className: 'form-control mb-1',
      labelClass: 'lead label-create-user',
      name: 'password',
    },
  ],
  roles: [
    {
      type: 'super_admin',
      option: 'Super admin',
    },
    {
      type: 'admin',
      option: 'Admin',
    },
    {
      type: 'admin_viewer',
      option: 'Admin viewer',
    },
  ],
  modalStrings: {
    confirm: ``,
    warning: `Information`,
    alertStr: ``,
    alertStr2: ``,
    confirmation: ``,
    sureToProceed: `Are you sure you wish to proceed?`,
    noUndo: `This action is irreversible.`,
    aboutToCreate: `You are about to create an admin user.`,
    labelOK: ` OK`,
  },
  modal: {
    create: {
      title: `Create User`,
      action: `create__adm__user`,
      bodyText: ``,
      btns: {
        labelOk: ``,
        labelCancel: ``,
      },
      modalHeaderId: `create-user3`,
      sectionTitle: `User details`,
      sectionSubtitle: `Some user data`,
    },
  },
  notifications: {
    create: {
      success: 'Admin user created successfully!',
    },
  },
};
