import React, { useEffect } from 'react';
import ProfileUser from './components/Profile';
import ProfileHeader from './components/ProfileHeader';
import { Breadcrumb } from '../../../../../react-components';

const Profile: React.FC = () => {
    
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div>
      <div className=" p-0">
        <Breadcrumb title="My Profile" />
        <div className="custom-card">
          <div className="row d-flex justify-content-center">
            <div className="col-12">
              <div className=" border-0 bg-transparent">
                <ProfileHeader />
                <div className="p-3">
                  <ProfileUser />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
