// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import _ from 'lodash';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { WidgetActions, NoDevicesAlert } from '../common';
import { SwitchGroupV2, FloatButton, SwitchButton } from './components';
import config from './config';
import './styles.scss';

const COMPONENT_STRINGS: any = { ...config?.strings };
const FORMATS: any = { ...config.formats };
const DEFAULT_EXTREMS: any = { ...config.sliderValues };
const ST_LABEL: string = config.strings.stLabel;

interface LoadingProps {
  start: any;
  stop: any;
}

interface ManualControlProps {
  device: any;
  manualControlList: any;
  getSysSettings: Function;
  createManualControl: Function;
  loading: LoadingProps;
  status: any;
}

const ManualControl: React.FC<ManualControlProps> = ({
  device,
  manualControlList,
  loading,
  ...props
}) => {
  const [expandedCtrl, setIsExpandedCtrl]: any = useState(false);
  const [peripherals, setPeripherals]: any = useState({});
  const [isInit, setIsInit]: boolean = useState(false);
  const [widgetCkStates, setWidgetCkStates]: any = useState({});
  const [showSaveBtn, setShowSaveBtn]: any = useState(false);
  const [manualCtrlStatus, setManualCtrlStatus]: boolean = useState(false);
  const [currentDeviceId, setCurrentDeviceId]: any = useState();



  const toggleOnOffManualCtrl = (event: any) => {
    if(
      props &&
      props.hasOwnProperty('status') &&
      props?.status?.stoped
    ){
      return;
    }

    if (!expandedCtrl) setIsExpandedCtrl(true);
    setManualCtrlStatus((prevState: any) => {
      return !prevState;
    });
    if (!showSaveBtn) setShowSaveBtn(true);
  };


  const blockAccordion = event => {
    event.stopPropagation();
  };

  const handleChange = (panel: string) => (event: any, isExpanded: any) => {
    if(manualCtrlStatus){
      setIsExpandedCtrl(isExpanded);
      localStorage.setItem(`${ST_LABEL}${currentDeviceId}`, isExpanded);
    }
  };


  const handleStoredExpandState = (devId: any, turnedOn: any) => {
    if (
      devId !== null &&
      devId !== undefined
    ) {
      const storedExpanded: any = localStorage.getItem(
        `${ST_LABEL}${devId}`,
      );

      if (
        storedExpanded !== null &&
        storedExpanded !== undefined
      ) {
        setIsExpandedCtrl(
          turnedOn ? (storedExpanded === 'true' || storedExpanded === true) : false
        );
      }
    }
  };


  const mergeStoredData = (sentData: any) => {
    if (sentData) {
      const { setup }: any = sentData || {};

      if (setup && Object.keys(setup).length > 0) {
        setWidgetCkStates((prevWidgetStates: any) => {
          let prevWidgetStatesCopy: any = { ...prevWidgetStates };

          for (const prop in setup) {
            if (prop === 'cdvs') {
              if (prevWidgetStatesCopy?.setup?.cdvs && setup && setup[prop]) {
                for (const vc of setup[prop]) {
                  const { cidx, cdid, cdst }: any = vc || {};
                  const prevIndex: number = prevWidgetStatesCopy.setup.cdvs.findIndex(
                    // (item: any) => item.cidx === cidx,
                    (item: any) => item?.cdid === cdid,
                  );

                  if (prevIndex > -1) {
                    prevWidgetStatesCopy.setup.cdvs[prevIndex].cdst = cdst;
                  }
                }
              }
            } else {
              prevWidgetStatesCopy.setup[prop] = setup[prop];
            }
          }

          return prevWidgetStatesCopy;
        });
      }
    }
  };


  const extractPeripherals = (_details: any) => {
    const { conf } = _details?.settings || {};
    const cdvsClone: any = conf?.cdvs ? _.cloneDeep(conf.cdvs) : [];

    return {
      ventConnect: cdvsClone.filter((item: any) =>
        item.cdid.startsWith('AirCyclerVC'),
      ),
      fanConnect: conf?.fcsp === 1 || conf?.fcsp === '1',
      damper: true,
      pressureConnect: cdvsClone.filter((item: any) =>
        item.cdid.startsWith('AirCyclerPC'),
      ),
      freshConnect: cdvsClone.filter(
        (item: any) =>
          item.cdid.startsWith('AirCyclerFH') ||
          item.cdid.startsWith('AirCyclerFT'),
      ),
      centralFan: true,
    };
  };

  const mapPeripheralProp = (prop: string) => {
    switch (prop) {
      case 'damper':
        return 'dmst';
      case 'centralFan':
        return 'cfst';
      case 'fanConnect':
        return 'fcst';
      default:
        return;
    }
  };

  const prepareManualControlData = (device: any, periphs: any) => {
    const { device_id } = device || {};
    let newData: any = {
      system_id: device_id,
      setup: {},
      status: false,
    };

    if (periphs && Object.keys(periphs).length > 0) {
      for (const key in periphs) {
        if (
          key !== 'ventConnect' &&
          key !== 'freshConnect' &&
          key !== 'pressureConnect'
        ) {
          if (periphs[key]) {
            let mappedProp: any = mapPeripheralProp(key);
            if (mappedProp) newData.setup[mappedProp] = 0;
          }
        } else {
          if (periphs[key] && periphs[key].length > 0) {
            if (!newData.setup.hasOwnProperty('cdvs')) newData.setup.cdvs = [];

            for (const vc of periphs[key]) {
              const { cdid, cidx, dmac, cdst } = vc || {};
              const tempVcData: any = { cdid, cidx, dmac, cdst };
              newData.setup.cdvs.push(tempVcData);
            }
          }
        }
      }
    }

    return newData;
  };


  const handleSaveManualControlSettings = async () => {
    let widgetCkStatesClone: any = _.cloneDeep(widgetCkStates);

    loading.start('Saving configuration...');
    widgetCkStatesClone.status = manualCtrlStatus;
    await props.createManualControl(widgetCkStatesClone);

    setShowSaveBtn(false);
    loading.stop();
  };


  const onChangeWgtCk = (section: string, event: any, value: any) => {
    const devData: any = event?.target?.id;
    const devDataIds: any = devData.split('-');
    const _type: any = devDataIds[1];
    const statesClone: any = _.cloneDeep(widgetCkStates);

    if (_type) {
      switch (_type) {
        case 'fanConnect':
        case 'centralFan':
        case 'damper':
          statesClone.setup[mapPeripheralProp(_type)] = value ? 1 : 0;
          break;
        case 'ventConnect':
        case 'freshConnect':
        case 'pressureConnect':
        default:
          let index: number = devDataIds[2] ? parseInt(devDataIds[2]) : 0;

          if (
            index !== null &&
            index !== undefined &&
            statesClone.setup &&
            statesClone.setup.cdvs
          ) {
            if (!isNaN(index) && index > -1) {
              index = statesClone.setup.cdvs.findIndex(
                (item: any) => item?.cidx === index,
              );
            }

            if (index > -1) statesClone.setup.cdvs[index].cdst = value ? 3 : 0;
          }
          break;
      }
    }

    setWidgetCkStates(statesClone);
    setShowSaveBtn(true);
  };







  useEffect(() => {
    if (device && !isInit) {
      const { details } = device;
      const _peripherals: any = extractPeripherals(details);
      const preCkData: any = prepareManualControlData(device, _peripherals);

      setCurrentDeviceId(device.device_id);
      setPeripherals(_peripherals);

      setWidgetCkStates(preCkData);
      setIsInit(true);
    }
  }, [device]);


  useEffect(() => {
    if (
      currentDeviceId !== null &&
      currentDeviceId !== undefined
    ) {
      const storedExpanded: any = localStorage.getItem(
        `${ST_LABEL}${currentDeviceId}`,
      );

      if (
        storedExpanded !== null &&
        storedExpanded !== undefined
      ) {
        setIsExpandedCtrl(
          manualCtrlStatus ? (storedExpanded === 'true' || storedExpanded === true) : false
        );
      }
    }
  }, [currentDeviceId]);


  useEffect(() => {
    if(isInit && !manualCtrlStatus){
      setIsExpandedCtrl(false);
      handleSaveManualControlSettings();
    }
  }, [manualCtrlStatus]);


  useEffect(() => {
    if (
      manualControlList &&
      Array.isArray(manualControlList) &&
      manualControlList.length > 0
    ) {
      const { device_id } = device || {};

      if (device_id) {
        const devSetup: any = manualControlList.find((item: any) => {
          return item.system_id === device_id;
        });

        setManualCtrlStatus(!!devSetup?.status);
        handleStoredExpandState(
          currentDeviceId, !!devSetup?.status
        );
        mergeStoredData(devSetup);
      }
    }
  }, [manualControlList]);


  useEffect(() => {
    const fetchData = async () => {
      const data = await props.getManualControlList();

      if (data) {
        const { body }: any = data || {};

        if (body && Array.isArray(body) && body.length > 0) {
          const { device_id } = device || {};
          const devSetup: any = body.find((item: any) => {
            return item.system_id === device_id;
          });

          if (devSetup) mergeStoredData(devSetup);
        }
      }
    };

    fetchData();
  }, []);



  return (
    <Accordion
      id="user-widget--manual-control"
      square
      expanded={expandedCtrl}
      onChange={handleChange('manual-control')}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="widget--fc"
        id="widget--fc"
      >
        <div className="widget--title-container">
          <div className="widget--row">
            <div className="widget--title-label">{COMPONENT_STRINGS.title}</div>
            <div className="widget--title-actions">
              <SwitchButton
                id="manual-ctrl-settings"
                onChange={toggleOnOffManualCtrl}
                value={manualCtrlStatus}
                origin={`on-off`}
                status={manualCtrlStatus ? `On` : `Off`}
                onClick={event => blockAccordion(event)}
              />
            </div>
          </div>
          <div
            className="widget--row"
            style={{ marginTop: 3 }}
          >
            <div className="widget-state-btn-wrapper"></div>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="widget--body-container">
          <div className="widget--row">
            <div className="widget--row-content">
              <div className="widget--row-content">
                {Object.values(peripherals).length > 0 ? (
                  <SwitchGroupV2
                    id={`switch-group-manual-control`}
                    device={device}
                    data={peripherals}
                    ckData={widgetCkStates}
                    onChange={onChangeWgtCk.bind(null, 'manualControl')}
                  />
                ) : (
                  <NoDevicesAlert />
                )}
              </div>

              {showSaveBtn && (
                <WidgetActions onSave={handleSaveManualControlSettings} />
              )}
              {showSaveBtn && (
                <FloatButton onSave={handleSaveManualControlSettings} />
              )}
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

function mapStateToProps(state: any) {
  const { manualControl } = state;
  return {
    manualControlList: manualControl.manualControlList,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { manualControl, loader } = dispatch;

  return {
    createManualControl: manualControl.createManualControl,
    deleteManualControl: manualControl.deleteManualControl,
    getManualControlDetails: manualControl.getManualControlDetails,
    getManualControlList: manualControl.getManualControlList,
    updateState: manualControl.updateState,
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManualControl);
