import React, { useEffect } from 'react';
import { Grid, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import './styles.scss';

interface CustomRadioGroupProps {
  id?: string;
  label: string;
  value: any;
  options: any;
  onChange: any;
}

const CustomRadioGroup: React.FC<CustomRadioGroupProps> = ({
  id,
  label,
  value,
  options,
  onChange,
  ...props
}) => {

  return (
    <Grid item>
      <div id={id} className="rg--container">
        <div className="rg--values-wrapper">
          <label className="rg--values-label">{label}</label>
        </div>
        <div className="rg--radios-wrapper">
          <RadioGroup
            row
            aria-label="rg-control"
            name="rg-control"
            value={value}
            onChange={onChange}
          >
            {options && Array.isArray(options) &&
              options.map((option: any, index: number) => {
                return (
                  <FormControlLabel
                    key={`rd-${index}`}
                    value={option.value}
                    control={<Radio checked={value === option.value} color="primary" />}
                    label={option.label}
                  />
                );
              })}
          </RadioGroup>
        </div>
      </div>
    </Grid>
  );
};

export default CustomRadioGroup;
