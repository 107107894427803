import React from 'react';
import { TbSignature, TbNumbers } from 'react-icons/tb';
import { MdLocationCity } from 'react-icons/md';
import { GiUsaFlag } from 'react-icons/gi';
import { AiOutlineHome } from 'react-icons/ai';
import { FaRegCheckSquare } from 'react-icons/fa';

export default {
  strings: {
    title1: "Installer devices' list",
    noResults: 'No results found...',
    timeSlLabel: '',
    title: 'Order list',
    txt: `Return to default order by clicking here`,
    btn: `DEVICE`,
    main: `Device overview`,
    lead: `Check some information about `,
    cardTitle: `Main data`,
    cardSubtitle: `If you wish to update the current data, please select the Edit option (Installers' Devices List menu).`,
  },
  keys: {
    city: 'City',
    device_name: 'Device name',
    macAddress: 'Mac Address',
    state: 'State',
    status: 'Status',
    zipcode: 'Zip Code',
  },
  resettedCounter: {
    name: 0,
    location: 0,
    status: 0,
  },
  dataHeader: [
    {
      colClass: `col-2 name-text`,
      spanClass: `lead name-span `,
      spanClass2: `lead name-span1`,
      headerDesignationCol: `Name`,
    },
    {
      colClass: `col-3 users-text1`,
      spanClass: `lead other-spans`,
      spanClass2: `lead mb4-1`,
      headerDesignationCol: `Location`,
    },
    {
      colClass: `col-3 centered2`,
      spanClass: `lead other-spans`,
      spanClass2: `lead mb4-1`,
      headerDesignationCol: `Status`,
    },
    {
      colClass: `col-3 end`,
      spanClass: `lead actions--span other-spans`,
      spanClass2: `lead mb4-1`,
      headerDesignationCol: `Actions`,
    },
  ],
  rowClasses: {
    col1: `col-xs-12 col-sm-12 col-md-3 name--col`,
    span1: `lead lead-adm bolder-ft`,
    col2: `col-xs-12 col-sm-12 col-md-3 location--col`,
    span2: `lead lead-adm`,
    col3: `col-xs-12 col-sm-12 col-md-3 status--col`,
    span3: `lead lead-adm the-icon-lead`,
    col4: `col-xs-12 col-sm-12 col-md-2 inv`,
    colMenu: `col-xs-12 col-sm-12 col-md-3 expand--side`,
  },
  menu: [
    {
      id: `firstOpt`,
      label: `View device`,
      dataTarget: '#view-device-modal-id',
      dataToggle: `modal`,
    },
    {
      id: `secondOpt`,
      label: `Edit device`,
      dataTarget: ``,
      dataToggle: ``,
    },
    {
      id: `thirdOpt`,
      label: `Delete device`,
      dataTarget: '#delete-device-modal-id',
      dataToggle: `modal`,
    },
  ],
  modalStrings: {
    confirm: ``,
    warning: `Information`,
    alertStr: ``,
    alertStr2: ``,
    confirmation: ``,
    sureToProceed: `Are you sure you wish to proceed?`,
    noUndo: `This action is irreversible.`,
    aboutToDel: `You are about to delete this device.`,
    labelOK: ` OK`,
  },
  modal: {
    view: {
      title: `View device`,
      action: ``,
      bodyText: ``,
      btns: {
        labelOk: ``,
        labelCancel: ``,
      },
      modalHeaderId: `view-dev1`,
      sectionTitle: `Device details`,
      sectionSubtitle: `Some device data`,
    },
    delete: {
      title: `Delete device`,
      action: ``,
      bodyText: ``,
      btns: {
        labelOk: ``,
        labelCancel: ``,
      },
      modalHeaderId: `delete-dev1`,
    },
  },
  relevantKeys: [
    'status',
    'state',
    'macAddress',
    'device_name',
    'city',
    'zipcode',
    'state',
  ],
  icons: {
    device_name: <TbSignature style={{ marginRight: '0.2rem' }} />,
    state: <GiUsaFlag style={{ marginRight: '0.2rem' }} />,
    city: <MdLocationCity style={{ marginRight: '0.2rem' }} />,
    zipcode: <TbNumbers style={{ marginRight: '0.2rem' }} />,
    status: <FaRegCheckSquare style={{ marginRight: '0.2rem' }} />,
    macAddress: <AiOutlineHome style={{ marginRight: '0.2rem' }} />,
  },
  mockDevices: {
    statusCode: 200,
    body: {
      data: [
        {
          _id: '123456789',
          system_id: '96ccb0fa-ff46-85dc-df40-6f45b97a6def',
          status: true,
          device_name: 'Device Name 1',
          state: 'Alabama',
          city: 'Abeville',
          macAddress: 'ACD-EFT-001',
          zipcode: '36310',
        },
        {
          _id: '1258788855',
          system_id: '96ccb0fa-ff46-85dc-abgf-6f45b97a6def',
          status: false,
          device_name: 'Other name',
          state: 'California',
          city: 'Los Angeles',
          macAddress: 'ACD-EFT-001',
          zipcode: '90001',
        },
        {
          _id: '1258788855',
          system_id: '96ccb0fa-ff46-85dc-abgf-6f45b97a6def',
          status: true,
          device_name: 'Be-1device',
          state: 'Massachusetts',
          city: 'Boston',
          macAddress: 'ACD-EFT-0031',
          zipcode: '02108',
        },
      ],
    },
  },
};
