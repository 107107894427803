import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import {
  InstallerHome,
  Systems,
  Configurations,
  DeleteAccount,
} from './pages';
import MyProfile from '../profile';

const base_path = '/installer';

interface InstallerDashboardProps {
  userAccount: any;
  isUserInit: any;
  setUserInit: Function;
}

const InstallerDashboard: React.FC<InstallerDashboardProps> = ({
  ...props
}) => {
  useEffect(() => {
    if (props.userAccount && !props.isUserInit) {
      props.setUserInit();
    }
  }, [props.userAccount]);

  return (
    <>
      <BreadcrumbsItem to={base_path}>Dashboard</BreadcrumbsItem>
      <Switch>
        <Route exact path={base_path} component={InstallerHome} />
        <Route path={`${base_path}/profile`} component={MyProfile} />
        <Route path={`${base_path}/systems`} component={Systems} />
        <Route
          path={`${base_path}/configurations`}
          component={Configurations}
        />
        <Route
          path={`${base_path}/delete-account`}
          component={DeleteAccount}
        />
      </Switch>
    </>
  );
};

function mapStateToProps(state: any) {
  const { profile, env } = state;
  return {
    isUserInit: env.isUserInit,
    userAccount: profile.user,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { env }: any = dispatch || {};
  return {
    setUserInit: env.setUserInit,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallerDashboard);
