import { ConnectionBroker } from 'core';


export const installerSystems = {
  state: {
    systems: [],
  },
  reducres: {
    updateState(state: any, payload: any, name: string) {
      return { ...state, [name]: payload };
    },
  },
  effects: () => ({
    async listAllSystems(){
      const that: any = this;
    },
  }),
};
