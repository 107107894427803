// @ts-nocheck
import _ from 'lodash';
import numeral from 'numeral';

const AIR_QUALITY_PARAM = 'PM2.5';


const utils = {
  arrayPackageByProp: (values: any, prop: string) => {
    let tempData: any = {
      prop: prop,
      data: [],
    };
    tempData.data = values.map((val: number, index: number) => {
      return {
        pointer: index === 0 ? 'current' : 'max',
        value: val,
        init: true
      };
    });
    return { ...tempData };
  },
};

export default utils;
