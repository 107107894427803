import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { ConnectionBroker } from 'core';
import { BsPersonCheck } from 'react-icons/bs';
import { MdCancel } from 'react-icons/md';
import { LoaderBubble, Button } from '../../../../../react-components';
import Form from '../../components/FormSession';


const SignIn: React.FC = (): JSX.Element => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loading, setLoading]: any = useState(true);
  const [success, setSuccess] = useState<boolean>(false);


  // eslint-disable-next-line react-hooks/exhaustive-deps
  const verification = async () => {
    const token = match.params;
    const response = await ConnectionBroker.fetchAPI(
      'jitBE',
      'session.verifyToken',
      token,
    );
    setLoading(false);
    if (response.statusCode === 200) {
      setSuccess(true);
    }
  };

  useEffect(() => {
    if (loading) verification();
  }, [loading, verification]);

  const SuccessComponent = () => {
    if (success) {
      return (
        <div className="pt-5 mt-5 text-center">
          <BsPersonCheck size={80} className="text-primary" />
          <h2 className="pt-5">Verification Success</h2>
          <p>You email has been confirmed</p>
          <div className="d-flex justify-content-center align-items-center pt-4">
            <Button
              variant="link"
              className="btn btn-link"
              type="button"
              onClick={() => history.push('/login')}
            >
              Login
            </Button>
          </div>
        </div>
      );
    }
    return (
      <div className="pt-5 mt-5 text-center">
        <MdCancel size={80} className="text-primary" />
        <h2 className="pt-5">Verification faild</h2>
        <p>You not email has been confirmed</p>
        <div className="d-flex justify-content-center align-items-center pt-4">
          <Button
            variant="link"
            className="btn btn-link"
            type="button"
            onClick={() => history.push('/login')}
          >
            Login
          </Button>
        </div>
      </div>
    );
  };

  return <Form>{loading ? <LoaderBubble /> : <SuccessComponent />}</Form>;
};

export default SignIn;
