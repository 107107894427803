import React from 'react';
import { TimezoneDisplayProps } from './interface';
import { BiTime } from 'react-icons/bi';
import './styles.scss';

const TimezoneDisplay: React.FC<TimezoneDisplayProps> = ({
  timezoneData,
  config,
  className,
}) => {
  const returnTimzString = (timezoneData: any) => {
    let currTz: string;
    if (config.timezones && config.timezones.length > 0) {
      currTz = config.timezones[Number(timezoneData)]?.label;

      return currTz;
    } else {
      return 'Current data not available!';
    }
  };

  return (
    <div className={`container ${className}`}>
      <div className="row pdg" id={`${className}--id`}>
        <div className="col pdg">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-5 col-xl-4">
              <div className="container pdg">
                <div className="row pdg">
                  <div className="col-auto pdg centered">
                    <BiTime className="stl-timezone" />
                  </div>

                  <div className="col to-the-left pl-spec">
                    <span className="spec-tmz-label">
                      {config.labels.currentTz}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-5 col-xl-4">
              <span className="lead smaller-lead">
                {returnTimzString(timezoneData)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimezoneDisplay;
