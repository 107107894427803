import React, { useState, useCallback, useRef, useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { Col } from 'react-bootstrap';
import cn from 'classnames';
import styles from './index.module.scss';

interface InputProps {
  name: string;
  classname?: any;
  label?: string;
  handleChangeError: Function;
  cols?: {
    col?: number;
    sm?: number;
    md?: number;
    lg?: number;
    lx?: number;
  };
  handleChange: Function;
  handleBlur?: any;
  maximum?: any;
  error?: any;
  constraint?: any;
  constraintEquality?: any;
  value: string;
  type?: string;
  disabled?: boolean;
}
const InputPhone: React.FC<InputProps> = ({
  name,
  classname,
  label,
  handleChangeError,
  cols,
  handleChange,
  handleBlur,
  value,
  maximum,
  type,
  error,
  disabled,
  constraint,
  constraintEquality,
  ...rest
}) => {
  const inputRef = useRef(null);
  const [edited, setEdited] = useState(false);

  const handleInputBlur = useCallback(() => {
    if (!disabled) {
      if (!isValidPhoneNumber('+' + value) && edited) {
        handleChangeError('Invalid phone number');
      } else {
        handleChangeError(false);
      }
    }
    // eslint-disable-next-line
  }, [disabled, value, inputRef, edited]);

  const handleChangeInput = useCallback(
    (value, country, formattedValue) => {
      if (!disabled) {
        handleChange(value);

        if (isValidPhoneNumber('+' + value)) {
          handleChangeError(false);
        }
        setEdited(true);
      }
    },
    // eslint-disable-next-line
    [disabled, value],
  );

  return (
    <Col
      sm={cols?.sm || cols?.col}
      md={cols?.md || cols?.col}
      lg={cols?.lg || cols?.col}
      className={cn('form-group', styles.formGroup, classname)}
    >
      <label className={styles.inputLabel}>{label}</label>
      <PhoneInput
        disabled={disabled}
        country={'us'}
        value={value}
        onBlur={() => handleInputBlur()}
        onChange={(value, country, e, formattedValue) =>
          handleChangeInput(value, country, formattedValue)
        }
        onlyCountries={['us']}
        inputClass={cn(styles.input, {
          [styles[`input--isError`]]: error,
        })}
        inputProps={{
          name: 'phone',
          autoFocus: false,
        }}
      />
      {error && (
        <span
          className={cn(styles.input, {
            [styles[`input__span--isError`]]: error,
          })}
        >
          {error}
        </span>
      )}
    </Col>
  );
};

export default InputPhone;
