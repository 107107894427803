const returnChar = (item: any, pos?: string) => {
  //handle the case of user just have a first name
  if (item?.details?.name?.last_name[0].trim().length === 0) {
    return item?.details?.name?.first_name[0].toUpperCase();
  } else {
    if (pos === 'first') {
      return item?.details?.name?.first_name[0].toUpperCase();
    } else if (pos === 'second') {
      return item?.details?.name?.last_name[0].toUpperCase();
    }
  }
};

const handleDate = (date: string) => {
  return new Date(date).toLocaleString('en-US', {
    timeZone: 'UTC',
  });
};

export const helpers = {
  returnChar: returnChar,
  handleDate: handleDate,
};
