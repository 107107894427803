const renderSuitableError = (type: string, hasError: boolean) => {
  if (hasError) {
    switch (type) {
      case 'flowMeasuredHeatCall':
      case 'flowMeasuredCoolingCall':
      case 'flowMeasuredFanOnlyCall':
      case 'requiredContinuousAirFlow':
      case 'exhaustFanFlowRate':
        return `Value not allowed, it cannot exceed 250 CFM.`;
      case 'exhaustFanDelayTime':
      case 'adMxError':
      case 'minutesPerHourSupplyFlow':
      case 'minutesPerHourExhaustRun':
      case 'additionalMixTime':
        return 'Please, select a value between 0 and 60.';
      case 'hourStart':
        return 'Please, select a value between 0 and 23 hours.';
      case 'hourStop':
        return 'Please, select a value between 0 and 23 hours.';
      default:
        break;
    }
  } else {
    switch (type) {
      case 'exhaustFanDelayTime':
      case 'flowMeasuredFanOnlyCall':
      case 'requiredContinuousAirFlow':
      case 'exhaustFanFlowRate':
      case 'adMxError':
      case 'minutesPerHourSupplyFlow':
      case 'minutesPerHourExhaustRun':
      case 'flowMeasuredHeatCall':
      case 'flowMeasuredCoolingCall':
      case 'hourError':
      case 'endHourError':
      case 'additionalMixTime':
        return '';
      default:
        break;
    }
  }
};

export const functions = {
  renderSuitableError: renderSuitableError,
};
