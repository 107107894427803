import React, { useState, useCallback, useEffect } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import validate from 'validate.js';
import {
  Button,
  InputPassword,
  Notification,
  Breadcrumb,
} from 'react-components';
import { useAuth } from '../../../sessions/hooks/auth';
import { ConnectionBroker } from '../../../../../core';
import './styles.scss';
import config from './config';


const configInputs: any = config;

const ProfilePassword: React.FC = () => {
  const { accountType }: any = useAuth();
  const [fieldsState, setFieldsState]: any = useState(configInputs.fieldsState);
  const [errors, setErrors]: any = useState(true);
  const [loading, setLoading]: any = useState(false);


  const rootPathCtrl = (account: any) => {
    switch (account) {
      case 'installer':
        return 'installer';
      case 'customer':
      default:
        return 'dashboard';
    }
  };


  const handleSubmit = useCallback(
    async () => {
      setErrors(false);
      setLoading(true);
      let isValid = true;
      await validate.async(fieldsState, config.constraints).then(null, err => {
        setErrors(err);
        setLoading(false);
        isValid = false;
      });
      if (!isValid) {
        setLoading(false);
        return;
      }
      let confirm_password = fieldsState.confirm_password;
      delete fieldsState.confirm_password;

      let response: any = await ConnectionBroker.fetchAPI(
        'jitBE',
        'session.resetPassword',
        fieldsState,
        null
      );
      if (response.result) {
        Notification({
          title: 'Successfully',
          message: 'your password has been updated',
          type: 'success',
        });
      }
      fieldsState.confirm_password = confirm_password;
      setLoading(false);
    },
    // eslint-disable-next-line
    [fieldsState, config],
  );

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div>
      <BreadcrumbsItem to={`/${rootPathCtrl(accountType)}/profile/update-password`}>
        Update Password
      </BreadcrumbsItem>
      <div className="p-0" id="update-passwd">
        <Breadcrumb title="Update Password" titleBackButton />
        <div className="custom-card">
          <div className="row d-flex justify-content-center">
            <div className="col-12">
              <div className=" border-0 bg-transparent">
                <div className="card-body">
                  <div className="row">
                    {fieldsState && Object.keys(configInputs.fields).map((name: string, index: number) => {
                      return (
                        <InputPassword
                          handleChange={(value: any) =>
                            setFieldsState((prevState: any) => ({
                              ...prevState,
                              [name]: value,
                            }))
                          }
                          handleChangeError={(err: any) =>
                            setErrors((prevState: any) => ({
                              ...prevState,
                              [name]: err,
                            }))
                          }
                          name={name}
                          value={fieldsState[name]}
                          error={errors[name]}
                          constraint={{
                            password: config.constraints['password'],
                            confirm_password:
                              config.constraints['confirm_password'],
                          }}
                          fieldsState={fieldsState}
                          key={index}
                          {...configInputs.fields[name]}
                        />
                      );
                    })}
                  </div>
                  <div className="d-flex justify-content-end pt-4 btn-wrapper">
                    <Button
                      variant="primary"
                      className="btn btn-primary"
                      type="button"
                      disabled={loading}
                      onClick={() => handleSubmit()}
                    >
                      {config.strings.confirm}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePassword;
