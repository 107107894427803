import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  Companies,
  CreateCompany,
  EditCompany,
  Installers,
  AddInstaller,
  EditInstaller,
  AddDevice,
  InstalledDevices,
  EditDevice,
} from './pages';

const base_path = '/admin/dashboard/companies-list';

const CompanyRoutes: React.FC = ({ ...props }: any) => {
  return (
    <>
      <Switch>
        <Route exact path={`${base_path}`} component={Companies} />
        <Route
          exact
          path={`${base_path}/company/add`}
          component={CreateCompany}
        />
        <Route
          exact
          path={`${base_path}/company/edit`}
          component={EditCompany}
        />

        <Route
          exact
          path={`${base_path}/company/installers`}
          component={Installers}
        />

        <Route
          exact
          path={`${base_path}/company/installers/add-installer`}
          component={AddInstaller}
        />

        <Route
          exact
          path={`${base_path}/company/installers/edit-installer`}
          component={EditInstaller}
        />

        <Route
          exact
          path={`${base_path}/company/installers/devices`}
          component={InstalledDevices}
        />

        <Route
          exact
          path={`${base_path}/company/installers/devices/add-device`}
          component={AddDevice}
        />

        <Route
          exact
          path={`${base_path}/company/installers/devices/edit-device`}
          component={EditDevice}
        />
      </Switch>
    </>
  );
};

export default CompanyRoutes;
