import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { CircleLoader } from 'react-spinners';
import config from '../../../../config/config';
import './styles.scss';

interface LoaderProps {
  isLoading?: boolean;
  message?: string;
  stop?: any;
  start?: any;
};

const AppLoader: React.FC<LoaderProps> = ({ isLoading, message, ...props }) => {


  const autoStopLoader = () => {
    let timeJob = setTimeout(
      () => {
        if (isLoading) props.stop();
        clearTimeout(timeJob);
      },
      config?.loader?.timeout ? config.loader.timeout : 8000,
    );
  };

  useEffect(() => {
    if (isLoading) autoStopLoader();
  }, [isLoading]);

  return (
    <>
      {isLoading && (
        <div className="app-loader-spinner">
          <div>
            <CircleLoader loading={true} size={50} color={'#FFFFFF'} />
          </div>
          <div className="spinner-text">
            <span>{message}</span>
          </div>
        </div>
      )}
    </>
  );
};

function mapStateToProps(state: any) {
  return {
    isLoading: state.loader.loading,
    message: state.loader.message,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    start: dispatch.loader.startLoader,
    stop: dispatch.loader.stopLoader,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppLoader);
