import React, { useEffect, useState } from 'react';
import './styles.scss';


interface CustomClockProps {
  activeData: any;
  menuOpen: any;
  deviceId: any;
  stoped: any;
}

const CustomClock: React.FC<CustomClockProps> = ({
  activeData,
  menuOpen,
  deviceId,
  stoped,
  ...props
}) => {
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle }: any = activeData || {};
  const tempR1: any = innerRadius ? (innerRadius - 5) : 0;
  const [clockData, setClockData]: any = useState({
    r1: tempR1,
    x1: tempR1 ? (tempR1 - 8) : 0,
    x1_1: tempR1 ? (tempR1 - 5) : 0,
    x2: tempR1 ? tempR1 : 0,
  });


  const validateInputs = () => {
    return !!(activeData !== null
      && activeData !== undefined
      && innerRadius !== null
      && innerRadius !== undefined);
  };


  useEffect(() => {
    if(activeData){
      const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle }: any = activeData || {};
      setClockData((prevData: any) => {
        const r1: number = innerRadius - 5;
        return {
          r1: r1,
          x1: r1 - 8,
          x1_1: r1 - 5,
          x2: r1,
        };
      });
    }
  }, [menuOpen, activeData]);


  return validateInputs() ? (
    <>
      <circle key="cicle-main--2" cx={cx} cy={cy} r={clockData.r1} fill={'none'} stroke={stoped ? '#72747830' : '#727478'} strokeWidth="2" />
      <g key={`hour-lines--2`} className={`marks ${stoped ? 'marks-stop' : ''}`} style={{ transform: `translate(${cx}px, ${cy}px)`, }}>
        <line x1={clockData.x1} y1="0" x2={clockData.r1} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
        <line x1={clockData.x1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
        <line x1={clockData.x1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
        <line x1={clockData.x1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
        <line x1={clockData.x1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
        <line x1={clockData.x1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
        <line x1={clockData.x1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
        <line x1={clockData.x1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
        <line x1={clockData.x1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
        <line x1={clockData.x1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
        <line x1={clockData.x1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
        <line x1={clockData.x1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
          <line x1={clockData.x1_1} y1="0" x2={clockData.x2} y2="0" />
      </g>
    </>
  ) : (<></>);
};

export default CustomClock;
