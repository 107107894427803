import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { AllDevices, EditDevice, DeviceDetails } from './pages';

const devices_path = '/devices';

const Devices: React.FC = props => {

  return (
    <>
      <BreadcrumbsItem to={`/dashboard${devices_path}`}>
        Devices
      </BreadcrumbsItem>
      <Switch>
        <Route exact path={`/dashboard${devices_path}`} component={AllDevices} />
        <Route exact path={`/dashboard${devices_path}/all`} component={AllDevices} />
        <Route exact path={`/dashboard${devices_path}/edit`} component={EditDevice} />
        <Route path={`/dashboard${devices_path}/details`} component={DeviceDetails} />
      </Switch>
    </>
  );
};

export default Devices;
