import React, { useEffect } from 'react';
import { FiChevronDown, FiUser } from 'react-icons/fi';
import { Dropdown } from 'react-bootstrap';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import DropdownToggle from 'react-bootstrap/DropdownToggle';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../../modules/sessions/hooks/auth';

interface BreadcrumbProps {
  variant?: string;
  title?: string;
  breadcrumb?: boolean;
}

const UserProfileDropdown: React.FC<BreadcrumbProps> = ({}) => {
  const { signOut, user }: any = useAuth();

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <li className="nav-item dropdown">
      <Dropdown>
        <DropdownToggle as="span" className="nav-link cursor-pointer">
          {user.user?.picture?.profile ? (
            <img
              src={`${user.user.picture?.profile?.url}`}
              alt="user"
              className="rounded-circle"
              width="40"
            />
          ) : (
            <img
              src="/avatar.png"
              alt="user"
              className="rounded-circle"
              width="40"
            />
          )}
          <span className="ml-2 d-none d-lg-inline-block">
            <span>Hello,</span>{' '}
            <span className="text-dark">{user?.user?.first_name}</span>{' '}
            <FiChevronDown />
          </span>
        </DropdownToggle>
        <DropdownMenu>
          <div className="dropdown-header">
            <i className="i-Lock-User mr-1"></i> User
          </div>
          <Link
            to="/installer/profile"
            className="dropdown-item cursor-pointer"
          >
            My profile
          </Link>

          <Link
            to="/installer/delete-account"
            className="dropdown-item cursor-pointer"
          >
            Delete account
          </Link>

          <button
            className="dropdown-item cursor-pointer"
            onClick={() => signOut()}
          >
            Sign out
          </button>
        </DropdownMenu>
      </Dropdown>
    </li>
  );
};

export default UserProfileDropdown;
