import config from './config';
import moment from 'moment';
import {
  fieldsUtils as UtilsFields,
  csvConverterUtil as CsvConverter,
} from './utils';

const TABLE_COLUNMS: any = [...config.datalogging.table.columns];
const G3_TIMEZONES: any = [...config.datalogging.timezones];

const handleOpts = (action: string, section: string) => {
  if (section === `title`) {
    if (config.titles.hasOwnProperty(action)) {
      return config.titles[action as keyof typeof config.titles];
    }
  }
  if (section === `icon`) {
    if (config.icons.hasOwnProperty(action)) {
      return config.icons[action as keyof typeof config.icons];
    }
  }
  if (section === `bodies`) {
    if (config.bodies.hasOwnProperty(action)) {
      return config.bodies[action as keyof typeof config.bodies];
    }
  }

  if (section === `bodyClass`) {
    if (config.bodyClass.hasOwnProperty(action)) {
      return config.bodyClass[action as keyof typeof config.bodyClass];
    }
  }
};

const transformDate = (value: any, option: string) => {
  switch (option) {
    case 'end':
      return moment(value).add(1, 'days').valueOf();
    default:
      return moment(value).valueOf();
  }
};

const checkDifference = (dateA: any, dateB: any) => {
  const momentA: any = moment(dateA);
  return momentA.diff(moment(dateB), 'days');
};

const mapDataLogs = (data: any, _timezone: any) => {
  let mappedData: any = [];

  if (data && Array.isArray(data) && data.length > 0) {
    mappedData = data.map((item: any) => {
      const { details, createdAt }: any = item;
      const tempDetailsMapp: any = handleDetailsMapping(details, _timezone);

      return { ...tempDetailsMapp, createdAt };
    });
  }

  return mappedData;
};

const handleDetailsMapping = (details: any, _timezone: any) => {
  let rowMapped: any = {};
  const { dates }: any = details;

  TABLE_COLUNMS.forEach((cell: any, index: number) => {
    const { field }: any = cell;
    const value: any = UtilsFields.mapDataLogField(field, details);

    if (
      (field === 'time' || field === 'date') &&
      _timezone &&
      _timezone !== ''
    ) {
      const mappedTime: any = UtilsFields.mapTimezone(
        _timezone,
        value,
        dates?.date,
        field,
      );

      rowMapped[field] = mappedTime && mappedTime !== '' ? mappedTime : value;
    } else {
      rowMapped[field] = value;
    }
  });

  if (dates) {
    rowMapped.sortDate = dates.date;
  }

  return { ...rowMapped };
};

const handleSystemTimeZone = (system: any) => {
  const { details }: any = system || {};
  const { conf }: any = details?.settings || {};
  const _sysTimeZone: number = conf && conf.tmzn ? conf.tmzn : -1;
  const currTimezone: string = UtilsFields.timezoneMapper(
    G3_TIMEZONES,
    _sysTimeZone,
  );

  return currTimezone;
};

export const functions = {
  handleOpts: handleOpts,
  transformDate: transformDate,
  checkDifference: checkDifference,
  mapDataLogs: mapDataLogs,
  handleSystemTimeZone: handleSystemTimeZone,
};
