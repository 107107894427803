export default {
  strings: {
    title: 'Edit Configuration',
    confirm: 'Save Configuration',
  },
  alerts: {
    update: {
      question: 'Are you sure you want to update Configuration?',
      btn: {
        confirm: 'Yes, update it!',
      },
    },
    notifications: {
      update: {
        success: 'Successful update!',
      },
    },
  },
  notifications: {
    update: {
      success: 'Configuration updated successfully!',
      error: 'Configuration could not be updated, please try again!',
    },
    noConnDevs: {
      alert:
        'Atention: Control Target is set to Exhaust but there are any "VentConnect" connected, please check connections.',
    },
  },
  defaults: {
    g3CoreLabel: 'g3CoreSettings',
    // ventConnectProps: ['vcfr', 'exct', 'vcdt', 'mvcr', 'vcwm',],
    // ventConnectFlags: ['vcfr', 'exct', 'vcdt', 'mvcr', 'vcwm', 'cdst', 'frte', 'cail', 'slst', 'cdss'],
    dependentProps: {
      hrsv: {
        prop: 'hroa',
        value: 1,
      },
      hstv: {
        prop: 'hroa',
        value: 1,
      },
    },
    ventSetupTags: {
      calcFlow: [
        'fhfr',
        'fcfr',
        'fffr',
        'carq',
        'vcfr',
        'effr',
        'mixt',
        'hroa',
        'hrsv',
        'hstv',
        'tmzn',
        'efdt',
      ],
      calcTime: [
        'mnsf',
        'mner',
        'mixt',
        'hroa',
        'hrsv',
        'hstv',
        'tmzn',
        'efdt',
      ],
      balanceMode: [
        'mnsf',
        'mner',
        'efdt',
        'mixt',
        'hroa',
        'hrsv',
        'hstv',
        'tmzn',
      ],
    },
  },
  fieldsState: {
    config_name: null, //name
    fhfr: 0, // inside settings object
    fcfr: 0,
    fffr: 0,
    hroa: 0,
    carq: 0,
    effr: 0,
    primary_control: '',
    add_sensor: '',
    add_sensor_id: '',
    ventilation_setup: 'Calculated Flow',
    mnsf: 0,
    mner: 0,
    mixt: 0,
    tmzn: 0,
    efdt: 0,
    exct: 0,
    hrsv: 0,
    hstv: 0,
    exwm: 0,
    vcfr: 0,
    vcdt: 0,
    mvcr: 0,
    vcwm: 0,
  },
  fields: {
    config_name: {
      label: 'Configuration Name',
      type: 'text',
      placeholder: 'Configuration Name',
    },
    fhfr: {
      label: 'Flow measured during heat call',
      type: 'number',
      placeholder: 'Flow measured during heat call',
      units: 'CFM',
    },
    exct: {
      label: 'Balanced Mode',
      type: 'number',
      placeholder: 'Balanced Mode',
    },
    hours_operation: {
      label: 'Hours of Operation',
      type: 'number',
      placeholder: 'Hours of Operation',
    },
    fcfr: {
      label: 'Flow measured during cooling call',
      type: 'number',
      placeholder: 'Flow measured during cooling call',
      units: 'CFM',
    },
    fffr: {
      label: 'Flow measured during fan-only call',
      type: 'number',
      placeholder: 'Flow measured during fan-only call',
      units: 'CFM',
    },
    effr: {
      label: 'Exhaust Fan Flow Rate',
      type: 'number',
      placeholder: 'Exhaust Fan Flow Rate',
      units: 'CFM',
    },
    carq: {
      label: 'Required continuous air flow',
      type: 'number',
      placeholder: 'Required continuous air flow',
      units: 'CFM',
    },
    vcfr: {
      label: 'Exhaust Fan Flow Rate',
      type: 'number',
      placeholder: 'Exhaust Fan Flow Rate',
      units: 'CFM',
    },
    vcdt: {
      label: 'Exhaust Fan Delay Time',
      type: 'number',
      placeholder: 'Exhaust Fan Delay Time',
      units: 'Min/Hr',
    },
    mvcr: {
      label: 'Minutes per Hour Exhaust Run',
      type: 'number',
      placeholder: 'Minutes per Hour Exhaust Run',
      units: 'Min/Hr',
    },
    vcwm: {
      label: 'Exhaust Worker Mode',
      type: 'number',
      placeholder: 'Exhaust Worker Mode',
    },
    exwm: {
      label: 'Exhaust Worker Mode',
      type: 'number',
      placeholder: 'Exhaust Worker Mode',
    },
    mnsf: {
      label: 'Central Fan Run Time',
      type: 'number',
      placeholder: 'Central Fan Run Time',
      units: 'Min/Hr',
    },
    mner: {
      label: 'Minutes per Hour Exhaust Run',
      type: 'number',
      placeholder: 'Minutes per Hour Exhaust Run',
      units: 'Min/Hr',
    },
    mixt: {
      label: 'Additional mix time',
      type: 'number',
      placeholder: 'Additional mix time',
      units: 'Min/Hr',
    },
    tmzn: {
      label: 'Current time',
      type: 'text',
      placeholder: 'Current time',
    },
    efdt: {
      label: 'Exhaust Fan Delay Time',
      type: 'number',
      placeholder: 'Exhaust fan delay time',
      units: 'Min/Hr',
    },
    hrsv: {
      label: 'Hour start',
      type: 'number',
    },
    hstv: {
      label: 'Hour stop',
      type: 'number',
    },
    ventilation_setup: {
      label: 'Ventilation Setup ',
      type: 'text',
      placeholder: 'Ventilation Setup',
    },
    primary_control: {
      label: 'Primary Control ',
      type: 'text',
      placeholder: 'Primary Control',
    },
    add_sensor: {
      label: 'Add Sensor ',
      type: 'text',
      placeholder: 'Add Sensor',
    },
  },
  setupFields: {
    indoorAir: {
      slider: {
        pm25: 140,
        co2: 1500,
        tVoc: 1500,
      },
      checkbox: {
        pm25: {
          dmst: false,
          cfcm: false,
        },
        co2: {
          dmst: false,
          cfcm: false,
        },
        tVoc: {
          dmst: false,
          cfcm: false,
        },
      },
      turnedOn: false,
    },
    outsideAir: {
      temp: { max: 75, min: 10, current: -20 }, 
      hum: { max: 80 },
      aqi: { max: 100 },
      cfm: { max: 50 },
    },
    pressureConnect: {
      pa: { max: 150 },
    },
  },
  constraints: {
    config_name: {
      presence: true,
      length: {
        minimum: 6,
      },
    },
    fhfr: {
      numericality: {
        lessThanOrEqualTo: 250,
        message: "^'%{value}' is not allowed, the value cannot exceed 250 CFM",
      },
    },
    fcfr: {
      numericality: {
        lessThanOrEqualTo: 250,
        message: "^'%{value}' is not allowed, the value cannot exceed 250 CFM",
      },
    },
    fffr: {
      numericality: {
        lessThanOrEqualTo: 250,
        message: "^'%{value}' is not allowed, the value cannot exceed 250 CFM",
      },
    },
    effr: {
      numericality: {
        lessThanOrEqualTo: 250,
        message: "^'%{value}' is not allowed, the value cannot exceed 250 CFM",
      },
    },
    carq: {
      numericality: {
        lessThanOrEqualTo: 250,
        message: "^'%{value}' is not allowed, the value cannot exceed 250 CFM",
      },
    },
    mixt: {
      numericality: {
        lessThanOrEqualTo: 60,
        message:
          "^'%{value}' is not allowed, the value cannot exceed 60 minutes",
      },
    },
    efdt: {
      numericality: {
        lessThanOrEqualTo: 60,
        message:
          "^'%{value}' is not allowed, the value cannot exceed 60 minutes",
      },
    },
    mner: {
      numericality: {
        lessThanOrEqualTo: 60,
        message:
          "^'%{value}' is not allowed, the value cannot exceed 60 minutes",
      },
    },
    hrsv: {
      numericality: {
        lessThanOrEqualTo: 23,
        message: "^'%{value}' is not allowed, the value cannot exceed 23 hours",
      },
    },
    hstv: {
      numericality: {
        lessThanOrEqualTo: 23,
        message: "^'%{value}' is not allowed, the value cannot exceed 23 hours",
      },
    },
    vcfr: {
      numericality: {
        lessThanOrEqualTo: 250,
        message: "^'%{value}' is not allowed, the value cannot exceed 250 CFM",
      },
    },
    vcdt: {
      numericality: {
        lessThanOrEqualTo: 60,
        message: "^'%{value}' is not allowed, the value cannot exceed 60 Min",
      },
    },
    mvcr: {
      numericality: {
        lessThanOrEqualTo: 60,
        message: "^'%{value}' is not allowed, the value cannot exceed 60 Min",
      },
    },
  },
  timezones: [
    {
      label: 'Newfoundland Time w/ Daylight Savings Time',
      timezone: 'Canada/Newfoundland',
    },
    {
      label: 'Atlantic Time w/ Daylight Savings Time',
      timezone: 'Canada/Atlantic',
    },
    {
      label: 'Eastern Standard w/ Daylight Savings Time',
      timezone: 'Canada/Eastern',
    },
    {
      label: 'Central Standard w/ Daylight Savings Time',
      timezone: 'US/Central',
    },
    {
      label: 'Mountain Standard w/ Daylight Savings Time',
      timezone: 'US/Mountain',
    },
    { label: 'Mountain Standard Time', timezone: 'US/Mountain' },
    {
      label: 'Pacific Standard w/ Daylight Savings Time',
      timezone: 'US/Pacific',
    },
    {
      label: 'Alaska Standard w/ Daylight Savings Time',
      timezone: 'US/Alaska',
    },
    {
      label: 'Hawaii Standard w/ Daylight Savings Time',
      timezone: 'US/Hawaii',
    },
  ],
  ventilationSelect: [
    { name: 'Calculated Flow' },
    { name: 'Calculated Time' },
    { name: 'Balanced Mode' },
  ],
};
