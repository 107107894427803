import React from 'react';
import config from './config';
import './styles.scss';


const STR_DEFAULT: any = { ...config.strings };


interface CustomParamsRowProps {
  _key: any;
  value: any;
  onKeyChange: any;
  onValueChange: any;
  onRemoveRow: any;
}

const CustomParamsRow: React.FC<CustomParamsRowProps> = ({
  _key,
  value,
  onKeyChange,
  onValueChange,
  onRemoveRow,
  ...props
}) => {

  return (
    <div id="custom-params-header" className="custom-params-header-row">
      <input
        className="param-key"
        placeholder={STR_DEFAULT.key.placeholder}
        value={_key}
        onChange={onKeyChange}
      />
      <input
        className="param-value"
        placeholder={STR_DEFAULT.value.placeholder}
        value={value}
        onChange={onValueChange}
      />
      <button className="ml-4 px-4 rounded-md" onClick={onRemoveRow}>
        Remove
      </button>
    </div>
  );
};

export default CustomParamsRow;
