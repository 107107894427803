import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DetailsChip } from '../../';
import { FoobotLogoHeader } from '../../../../../../../../foobot/common';
import { Convertions } from '../../../../../../../../../../core/utils';
import config from './config';
import './styles.scss';

interface FoobotProps {
  device: any;
  loading: any;
  requestData: any;
}

const Foobot: React.FC<FoobotProps> = ({ device, ...props }) => {
  const [deviceSettings, setDeviceSettings]: any = useState(null);
  const [expanded, setExpanded]: any = useState(false);

  const handleRequestData = async (device_id: string) => {
    props.loading.start('Loading Device Data');
    await props.requestData({ device_id }).then((response: any) => {
      props.loading.stop();
      if (response?.statusCode === 200) {
        setDeviceSettings(response.body);
      }
    });
  };

  const readFoobotValue = (value: any, sensor: number) => {
    if (value && value.length > 0) {
      if (value[0].data[sensor]) return value[0].data[sensor]?.value;
    }
    return;
  };

  const readFoobotFirmware = (value: any, index: number) => {
    return {
      type: () => {
        if (value && value?.firmwareVersions.length > 0)
          return value.firmwareVersions[index].firmwareType;
        return;
      },
      version: () => {
        if (value && value?.firmwareVersions.length > 0)
          return value.firmwareVersions[index].version;
        return;
      },
    };
  };

  const readFoobotSensorStats = (value: any, sensor: string) => {
    if (value && value?.sensorsStats.length > 0) {
      return value?.sensorsStats[0]?.stats[sensor]
        ? value.sensorsStats[0]?.stats[sensor]?.mean
        : null;
    }
    return;
  };

  const handleExpandPanel = (panel: any) => (event: any, isExpanded: any) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (device) {
      if (device?.details?.uuid) handleRequestData(device.details.uuid);
    }
  }, [device]);

  useEffect(() => {
    return () => {
      setDeviceSettings(null);
    };
  }, []);


  return (
    <div id="device-details-foobot">
      <Grid container>
        <Grid item className="device-details--header">
          <FoobotLogoHeader />
        </Grid>
        <Grid item xs={12} sm={12} className="device-details--name">
          <div>
            <h4>{device?.device_name}</h4>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        className="device-container-sensors"
        style={{ marginTop: 0 }}
      >
        <Grid item xs={12} sm={12} className="device-details--section-title">
          <div>
            <h5>{config?.strings?.titles?.sensorsStats}</h5>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} className="device-details--values">
          <Grid sm={12} item className="device-details--values-row co2-icon">
            <DetailsChip
              type="co2"
              label={config?.strings?.labels?.co2}
              value={readFoobotSensorStats(
                deviceSettings?.sensorsStatus,
                'co2',
              )}
            />
          </Grid>
          <Grid sm={12} item className="device-details--values-row">
            <DetailsChip
              type="humidity"
              label={config?.strings?.labels?.humidity}
              value={readFoobotSensorStats(
                deviceSettings?.sensorsStatus,
                'hum',
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} className="device-details--values">
          <Grid sm={12} item className="device-details--values-row">
            <DetailsChip
              type="temp"
              scale="celcius"
              label={config?.strings?.labels?.temp}
              value={readFoobotSensorStats(
                deviceSettings?.sensorsStatus,
                'tmp',
              )}
            />
          </Grid>
          <Grid sm={12} item className="device-details--values-row voc-icon">
            <DetailsChip
              type="voc"
              label={config?.strings?.labels?.voc}
              value={readFoobotSensorStats(
                deviceSettings?.sensorsStatus,
                'voc',
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container className="device-container-air">
        <Grid item xs={12} sm={12} className="device-details--section-title">
          <div>
            <h5>{config?.strings?.titles?.airQuality}</h5>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} className="device-details--values">
          <Grid sm={12} item className="device-details--values-row o3-icon">
            <DetailsChip
              type="o3"
              label={config?.strings?.labels?.o3}
              value={readFoobotValue(deviceSettings?.airQuality, 0)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} className="device-details--values">
          <Grid sm={12} item className="device-details--values-row pm25-icon">
            <DetailsChip
              type="pm25"
              label={config?.strings?.labels?.pm25}
              value={readFoobotValue(deviceSettings?.airQuality, 1)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container className="device-container-sensors">
        <Accordion
          expanded={expanded === 'about-device'}
          onChange={handleExpandPanel('about-device')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="about-device-content"
            id="about-device-header"
          >
            <Typography
              style={{
                width: '40%',
                flexShrink: 0,
                fontSize: '18px',
                fontWeight: 500,
              }}
            >
              About
            </Typography>
            <Typography style={{ color: '#70707099' }}>
              Click to {expanded === 'about-device' ? 'hide' : 'show'} details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              className="device-details--values"
            >
              <Grid sm={12} item className="device-details--values-row">
                <label>{config?.strings?.labels?.macAddrr} </label>
                <div>{device?.details?.id}</div>
              </Grid>
              <Grid sm={12} item className="device-details--values-row">
                <label>{config?.strings?.labels?.upTime} </label>
                <div>
                  {Convertions.secToHours(
                    deviceSettings?.connectivity?.averageMqttSessionLengthSec,
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              className="device-details--values"
            >
              <Grid sm={12} item className="device-details--values-row">
                <label>{config?.strings?.labels?.firmwareType} </label>
                <div>
                  {readFoobotFirmware(
                    deviceSettings?.fwUpgradeStatus,
                    0,
                  ).type()}
                </div>
              </Grid>
              <Grid sm={12} item className="device-details--values-row">
                <label>{config?.strings?.labels?.firmware} </label>
                <div>
                  {readFoobotFirmware(
                    deviceSettings?.fwUpgradeStatus,
                    0,
                  ).version()}
                </div>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              className="device-details--values"
            >
              <Grid sm={12} item className="device-details--values-row">
                <label>{config?.strings?.labels?.firmwareType} </label>
                <div>
                  {readFoobotFirmware(
                    deviceSettings?.fwUpgradeStatus,
                    1,
                  ).type()}
                </div>
              </Grid>
              <Grid sm={12} item className="device-details--values-row">
                <label>{config?.strings?.labels?.firmware} </label>
                <div>
                  {readFoobotFirmware(
                    deviceSettings?.fwUpgradeStatus,
                    1,
                  ).version()}
                </div>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </div>
  );
};

function mapStateToProps(state: any) {
  const { foobot } = state;
  return {};
}

function mapDispatchToProps(dispatch: any) {
  const { foobot, loader } = dispatch;
  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    requestData: foobot.getFoobotDevice,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Foobot);
