import React, { useEffect, useState, Fragment } from 'react';
import config from './config';
import { connect } from 'react-redux';
import { functions } from './helpers';
import { CommandsProps } from './interface';
import { useHistory } from 'react-router-dom';
import { List, TimezoneDisplay, Template } from 'business/modules/admin/common';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { FiRefreshCcw } from 'react-icons/fi';
import Skeleton from '@material-ui/lab/Skeleton';
import './styles.scss';

const STR_CONFIGS: any = { ...config.strings };

const Commands: React.FC<CommandsProps> = ({
  getCommandsList,
  currentAccount,
  currentDev,
  commandsListIsLoaded,
  loading,
  deviceLocationTimezone,
  storeDevLocationTmz,
  commandsList,
  orders,
  intendedCounter,
  counter,
  handleCounterAndListOrder,
  updateOrderState,
  orderedList,
}) => {
  const history = useHistory();
  const [loaded, setLoaded] = useState(false);
  const [currentDevice, setCurrentDevice]: any = useState();
  const [current, setCurrent] = useState('');
  const [unorderedDefault, setUnorderedDefault] = useState([]);

  const incremented: any = {
    date: intendedCounter?.date + 1,
    events: intendedCounter?.events + 1,
    status: intendedCounter?.status + 1,
  };

  const fetchCmds = async () => {
    let savedDefaultArr: any = [];

    loading.start('Loading commands data...');

    const _currentAccount: any = localStorage.getItem('currentAccount');
    const _currentDev: any = localStorage.getItem('currentDev');

    let _g3Device: any = localStorage.getItem('g3Device'); // it can be used to get device timezone
    _g3Device = _g3Device ? JSON.parse(_g3Device) : null;

    let response: any = await getCommandsList({
      id: _currentAccount ? _currentAccount : currentAccount,
      device_id: _currentDev ? _currentDev : currentDev,
    });

    if (response?.statusCode === 200) {
      let defaultData: any = response?.body?.data?.items.forEach(
        (item: any) => {
          savedDefaultArr.push(item);
        },
      );

      setUnorderedDefault(savedDefaultArr);
      loading.stop();
    }

    setCurrentDevice(_g3Device);
    setLoaded(true);
  };

  const returnAccordionDetails = (item: any) => {
    return <></>;
  };

  const retrieveColValue = (item: any, position: string) => {
    switch (position) {
      case '1':
        return functions.formatDate(item?.createdDate, deviceLocationTimezone);
      case '2':
        return Object.entries(item.cmds).map((elem: any, idx: number) => {
          return (
            <div
              key={idx}
              className={`col pdg key-col cmd-key-${
                elem[1] && Array.isArray(elem[1]) && elem[1].length >= 1
                  ? 'col pdg'
                  : 'row'
              }`}
            >
              {functions.returnKey(elem[0])}
              {Array.isArray(elem[1]) &&
                elem[1].map((elem: any, i: number) => {
                  return (
                    <Fragment key={i}>
                      <br />
                      <div className="container spc-cnt">
                        <div className="row spc-cnt">
                          <div className="col pdg spc-cnt">
                            {Object.keys(elem).map(
                              (itm: any, index: number) => {
                                if (itm !== 'cidx') {
                                  return (
                                    <div
                                      className="col pdg key-col spc-cnt"
                                      key={index}
                                    >
                                      {functions.returnKey(itm)}
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div key={index} className="cmd-conn-devs">
                                      <div className="cmd--cd-1">
                                        {functions.returnKey(itm)}
                                      </div>
                                      <div className="cmd--cd-2">
                                        {functions.returnKeyIndexValue(
                                          elem,
                                          itm,
                                        )}
                                      </div>
                                    </div>
                                  );
                                }
                              },
                            )}
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  );
                })}
            </div>
          );
        });
      case '3':
        return Object.entries(item.cmds).map((elem: any, idx: number) => {
          return (
            <div className="col pdg spc-cnt status-icon-wrapper" key={idx}>
              <div className="status--col">
                {functions.returnValue(elem[1])}
              </div>
            </div>
          );
        });

      default:
        return ``;
    }
  };

  const reloadCommandsList = () => {
    fetchCmds();
  };

  useEffect(() => {
    fetchCmds();

    return () => {
      setCurrentDevice(null);
      setLoaded(false);
    };
  }, []);

  useEffect(() => {
    if (loaded) {
      loading.stop();
    }
  }, [loaded]);

  useEffect(() => {
    let persistentTmz: any = localStorage.getItem('@deviceLocalTimezone');

    if (persistentTmz) {
      storeDevLocationTmz(persistentTmz);
    }
  }, [deviceLocationTimezone]);

  useEffect(() => {
    if (orders.orderByDate) {
      setCurrent(`date`);
    } else if (orders.orderByEvents) {
      setCurrent(`events`);
    } else if (orders.orderByStatus) {
      setCurrent(`status`);
    } else if (
      orderedList &&
      commandsListIsLoaded &&
      unorderedDefault.length > 0
    ) {
      setCurrent(``);
    }
  }, [unorderedDefault, orders, intendedCounter, counter]);

  return (
    <Template
      id="device-commands"
      title={STR_CONFIGS.title1}
      fun={reloadCommandsList}
      hasRightBtn={true}
      goBack={() => {
        history.push(`/admin/dashboard/user-details`);
      }}
      icon={<FiRefreshCcw className="svg-refresh" />}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 pdg">
            {currentDevice ? (
              <>
                <h5 className="card-title">
                  System: {(currentDevice && currentDevice?.device_name) || ''}{' '}
                </h5>

                <h6 className="card-subtitle mb-2 text-muted">
                  {(currentDevice && currentDevice?.device_type) || ''}{' '}
                </h6>
              </>
            ) : (
              <>
                <div className="row mb-1">
                  <Skeleton variant="text" height={25} width={'30%'} />{' '}
                </div>
                <div className="row mb-2">
                  <Skeleton variant="text" height={20} width={'20%'} />{' '}
                </div>
              </>
            )}
          </div>

          <div className="col-12 pdg divider--1"></div>
        </div>
      </div>

      {deviceLocationTimezone && (
        <TimezoneDisplay
          timezoneData={deviceLocationTimezone}
          config={config}
          className={`timezone--display-cmds`}
        />
      )}

      <List
        rowClasses={config.rowClasses}
        noMenu={true}
        icon={<HiOutlineDocumentReport className="icon-cmds" />}
        id="the--commands"
        list={commandsList}
        loaded={loaded}
        itemsPerPage={10}
        manageListOrder={handleCounterAndListOrder}
        orderedList={orderedList}
        orders={orders}
        sectionCounter={intendedCounter}
        sectionCount={counter}
        updateOrderState={updateOrderState}
        incremented={incremented}
        current={current}
        unorderedDefault={unorderedDefault}
        handles={{}}
        config={config}
        firstFun={(item: any) => {}}
        setItem={(item: any) => {}}
        retrieveColValue={retrieveColValue}
        returnAccordionDetails={returnAccordionDetails}
        helperOrderData={[]}
      />
    </Template>
  );
};

function mapStateToProps(state: any) {
  const { adminDevicesState } = state;

  return {
    currentAccount: adminDevicesState.currentAccount,
    currentDev: adminDevicesState.currentDev,
    commandsListIsLoaded: adminDevicesState.commandsListIsLoaded,
    deviceLocationTimezone: adminDevicesState.deviceLocationTimezone,
    commandsList: adminDevicesState.commandsList,
    orders: adminDevicesState.orders,
    intendedCounter: adminDevicesState.intendedCounter,
    counter: adminDevicesState.counter,
    orderedList: adminDevicesState.orderedList,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { loader, adminDevicesState } = dispatch;

  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    getCommandsList: adminDevicesState.getCommandsList,
    storeDevLocationTmz: adminDevicesState.storeDevLocationTmz,
    handleCounterAndListOrder: adminDevicesState.handleCounterAndListOrder,
    updateOrderState: adminDevicesState.updateOrderState,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Commands);
