import React from 'react';
import { RadioProps } from './interface';
import { Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import './styles.scss';

const RadioBtn: React.FC<RadioProps> = ({
  options,
  disabled,
  id,
  onChangeValues,
  value,
}) => {
  return (
    <div className="radio-button-common">
      <div className="radio-div-style">
        <RadioGroup
          row
          aria-label="exaust-control"
          name="exaust-control"
          value={value}
        >
          {options &&
            options.map((option: any, index: number) => {
              return (
                <FormControlLabel
                  id={!disabled ? `radio-setup` : `radio-setup-dis`}
                  key={`rd-${index}`}
                  control={
                    <Radio
                      id={id}
                      checked={
                        value && option.label === `Yes`
                          ? value
                          : !value && option.label === `No` && !value
                      }
                      color="primary"
                      onChange={(event: any) =>
                        onChangeValues(event, option.label)
                      }
                    />
                  }
                  label={option.label}
                />
              );
            })}
        </RadioGroup>
      </div>
    </div>
  );
};

export default RadioBtn;
