import React from 'react';
import config from './config';
import './styles.scss';

interface ListItemProps {
  item: any;
  style?: any;
}

const ListItem: React.FC<ListItemProps> = ({ item, style }) => {
  const returnVal = (str: string) => {
    if (item.hasOwnProperty(str)) {
      if (item?.isAdmin && str === 'isAdmin') {
        return 'Admin';
      }
      return str === 'isAdmin' ? 'User' : item[str];
    }
  };
  return (
    <>
      {config.labels.map((elem: any) => {
        return (
          <div key={elem} className="container data--results">
            <div className="row cnt-dr">
              <div className="col-12 pdg">
                <span className={`lead elem-${elem} list--item--style`}>
                  {returnVal(elem)}
                  <br />
                </span>{' '}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ListItem;
