import config from './config';

const returnPlaceHolder = (elem: string) => {
  let placeholder = [];
  let plc;
  let lower = elem?.match(/[a-z]+|[0-9]+/g);
  let upper = elem?.match(/[A-Z][a-z]+|[0-9]+/g);

  if (lower) {
    placeholder.push(lower[0]);
  }
  if (upper) {
    placeholder.push(upper.join(' '));
  }
  plc = placeholder.join(' ').toLowerCase();
  return plc.charAt(0).toUpperCase() + plc.slice(1);
};

const checkIfNum = (val: string) => {
  if (config.inputTypes.hasOwnProperty(val)) {
    return config.inputTypes[val as keyof typeof config.inputTypes];
  }
};

const handleElemPlaceholder = (
  elem: any,
  hasVentConnect: boolean | undefined,
  primaryCont: any,
  systemDetails: any,
) => {
  if (elem === 'minutesPerHourExhaustRun') {
    if (
      hasVentConnect &&
      primaryCont &&
      primaryCont[0] === 'FanConnect' &&
      systemDetails.hasOwnProperty('minutesPerHourExhaustRunFC')
    ) {
      return systemDetails['minutesPerHourExhaustRunFC'];
    } else if (
      hasVentConnect &&
      primaryCont &&
      primaryCont[0] !== 'FanConnect' &&
      systemDetails.hasOwnProperty('minutesPerHourExhaustRunVC')
    ) {
      return systemDetails['minutesPerHourExhaustRunVC'];
    }
  }

  if (elem === 'exhaustFanDelayTime') {
    if (
      hasVentConnect &&
      primaryCont &&
      primaryCont[0] === 'FanConnect' &&
      systemDetails.hasOwnProperty(elem)
    ) {
      return systemDetails[elem];
    } else if (
      hasVentConnect &&
      primaryCont &&
      primaryCont[0] !== 'FanConnect' &&
      systemDetails.hasOwnProperty(elem)
    ) {
      return systemDetails[elem];
    }
  } else if (systemDetails.hasOwnProperty(elem)) {
    return systemDetails[elem];
  }
};

export const functions = {
  returnPlaceHolder: returnPlaceHolder,
  checkIfNum: checkIfNum,
  handleElemPlaceholder: handleElemPlaceholder,
};
