//@ts-nocheck
import React from 'react';
import { DataHeaderProps } from './interface';
import { helpers } from './helpers';

const DataHeader: React.FC<DataHeaderProps> = ({
  dataHeader,
  isAdminHeader,
  defineOrderType,
  handleCounterAndListOrder,
  intendedCounter,
  counter,
}) => {
  const handleReordering = (col: string) => {
    defineOrderType(col);

    handleCounterAndListOrder(
      {
        ...intendedCounter,
        [helpers.returnProp(col)]:
          intendedCounter[[helpers.returnProp(col)]] + 1,
      },
      counter + 1,
    );
  };

  return (
    <>
      {dataHeader.map((elem: any, i: number) => {
        return (
          <div
            id={`header-info-1-${i}`}
            className={elem.colClass}
            key={i}
            onClick={() => {
              if (elem.headerDesignationCol === `Actions`) {
                return;
              } else {
                handleReordering(elem.headerDesignationCol);
              }
            }}
          >
            <div className="header-info--label">
              {helpers.renderIcon(elem.headerDesignationCol)}
              <span
                className={isAdminHeader ? elem.spanClass : elem.spanClass2}
              >
                {elem.headerDesignationCol}
              </span>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default DataHeader;
