import React from 'react';
import { FaFan } from 'react-icons/fa';
import { TiFlowSwitch } from 'react-icons//ti';
import { BsThermometerSun } from 'react-icons/bs';
import './styles.scss';

interface CentralFanStateProps {
  title: string;
  fanState?: any;
  stoped?: any;
  sourceState: any;
}

const CentralFanState: React.FC<CentralFanStateProps> = ({
  title,
  fanState,
  stoped,
  sourceState,
  ...props
}) => {


  return (
    <div key="cf-fanStates" id="cf-fanStates" className="ct-fan-wrapper">
      <div className="widgets--title-wrapper">
        <h4 className="widgets--title">{title}</h4>
      </div>
      <div className="icons-container">
        <FaFan className={fanState && !stoped ? 'rotate' : ''} size="2.3rem" />
        <div className="source-state-wrapper">
          {sourceState === 'relay' ? (
            <TiFlowSwitch size="2.3rem" />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default CentralFanState;
