import React from 'react';
import './styles.scss';


const NoDevicesAlert: React.FC = ({...props}) => {
  return (
    <div className="nd-alert">
      <span>No Connected Devices Founded.</span>
    </div>
  );
}

export default NoDevicesAlert;
