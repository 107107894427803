import React from 'react';
import { Grid } from '@material-ui/core';
import { InputPlus } from 'react-components';
import { parseNumber } from '../../helpers';
import localConfig from './config';
import './styles.scss';

interface CalculetedFlowProps {
  config: any;
  errors: any;
  fieldsState: any;
  onChangeState: (name: string, value: any) => void;
  type?: string;
}

const G3_STRINGS: any = { ...localConfig.strings.g3Controller };

const CalculatedFlow: React.FC<CalculetedFlowProps> = ({
  config,
  errors,
  fieldsState,
  onChangeState,
  type,
}) => {
  const TIMEZONE: any = [...config.timezones];

  return (
    <div className="container-paddings" id="calculated-flow">
      <div className="row">
        <div className="col-12 container-paddings">
          <InputPlus
            handleChange={(value: any) => {
              onChangeState('fhfr', parseNumber(value));
            }}
            handleChangeError={(err: any) => {}}
            disabled={false}
            type={config.fields.fhfr.type}
            label={config.fields.fhfr.label}
            value={`${fieldsState?.fhfr}`}
            units={config.fields.fhfr.units}
            error={errors?.fhfr}
            name={'fhfr'}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 container-paddings">
          <InputPlus
            handleChange={(value: any) => {
              onChangeState('fcfr', parseNumber(value));
            }}
            handleChangeError={(err: any) => {}}
            disabled={false}
            type={config.fields.fcfr.type}
            label={config.fields.fcfr.label}
            value={`${fieldsState?.fcfr}`}
            units={config.fields.fcfr.units}
            error={errors?.fcfr}
            name={'fcfr'}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 container-paddings">
          <InputPlus
            handleChange={(value: any) => {
              onChangeState('fffr', parseNumber(value));
            }}
            handleChangeError={(err: any) => {}}
            disabled={false}
            type={config.fields.fffr.type}
            label={config.fields.fffr.label}
            value={`${fieldsState?.fffr}`}
            units={config.fields.fffr.units}
            error={errors?.fffr}
            name={'fffr'}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 container-paddings">
          <InputPlus
            handleChange={(value: any) => {
              onChangeState('carq', parseNumber(value));
            }}
            handleChangeError={(err: any) => {}}
            disabled={false}
            type={config.fields.carq.type}
            label={config.fields.carq.label}
            value={`${fieldsState?.carq}`}
            units={config.fields.carq.units}
            error={errors?.carq}
            name={'carq'}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12 container-paddings">
          {fieldsState?.primary_control === 'fanConnect' && (
            <Grid item className="section-1 calc-mode">
              <div className="calc-mode--wrapper">
                <InputPlus
                  handleChange={(value: any) => {
                    onChangeState('effr', parseNumber(value));
                  }}
                  handleChangeError={(err: any) => {}}
                  disabled={false}
                  type={config.fields.effr.type}
                  label={config.fields.effr.label}
                  value={`${fieldsState?.effr}`}
                  units={config.fields.effr.units}
                  error={errors?.effr}
                  name={'effr'}
                />
              </div>
            </Grid>
          )}
          {fieldsState?.primary_control !== 'fanConnect' && (
            <Grid item className="section-1 calc-mode">
              <div className="calc-mode--wrapper">
                <InputPlus
                  handleChange={(value: any) => {
                    onChangeState('vcfr', parseNumber(value));
                  }}
                  handleChangeError={(err: any) => {}}
                  disabled={false}
                  type={config.fields.vcfr.type}
                  label={config.fields.vcfr.label}
                  value={`${fieldsState?.vcfr}`}
                  units={config.fields.vcfr.units}
                  error={errors?.vcfr}
                  name={'vcfr'}
                />
              </div>
            </Grid>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-12 container-paddings">
          {fieldsState?.primary_control === 'fanConnect' && (
            <Grid item className="section-1 calc-mode">
              <div className="calc-mode--wrapper mt-4">
                <InputPlus
                  handleChange={(value: any) => {
                    onChangeState('efdt', parseNumber(value));
                  }}
                  handleChangeError={(err: any) => {}}
                  disabled={false}
                  type={config.fields.efdt.type}
                  label={config.fields.efdt.label}
                  value={`${fieldsState?.efdt}`}
                  units={config.fields.efdt.units}
                  error={errors?.efdt}
                  name={'efdt'}
                />
              </div>
            </Grid>
          )}
          {fieldsState?.primary_control !== 'fanConnect' && (
            <Grid item className="section-1 calc-mode">
              <div className="calc-mode--wrapper mt-4">
                <InputPlus
                  handleChange={(value: any) => {
                    onChangeState('vcdt', parseNumber(value));
                  }}
                  handleChangeError={(err: any) => {}}
                  disabled={false}
                  type={config.fields.vcdt.type}
                  label={config.fields.vcdt.label}
                  value={`${fieldsState?.vcdt}`}
                  units={config.fields.vcdt.units}
                  error={errors?.vcdt}
                  name={'vcdt'}
                />
              </div>
            </Grid>
          )}
        </div>{' '}
      </div>

      <div className="row">
        <div className="col-12 container-paddings" id={type ? type : ``}>
          <InputPlus
            handleChange={(value: any) => {
              onChangeState('mixt', parseNumber(value));
            }}
            handleChangeError={(err: any) => {}}
            disabled={false}
            type={config.fields.mixt.type}
            label={config.fields.mixt.label}
            value={`${fieldsState?.mixt}`}
            units={config.fields.mixt.units}
            error={errors?.mixt}
            name={'mixt'}
          />
        </div>
      </div>
    </div>
  );
};

export default CalculatedFlow;
