import React, { useState, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import validate from 'validate.js';
import { Input, Button } from '../../../../../react-components/';
import { useAuth } from '../../hooks/auth';
import config from './config';

import { useHistory } from 'react-router-dom';
import Form from '../../components/FormSession';
const configInputs: any = config;

const SignIn: React.FC = () => {
  const history = useHistory();
  const { signIn } = useAuth();
  const [fieldsState, setFieldsState] = useState(configInputs.fieldsState);
  const [errors, setErrors]: any = useState(true);
  const [loading, setLoading]: any = useState(false);

  const handleSubmit = useCallback(
    async () => {
      setErrors(false);
      setLoading(true);
      let isValid = true;

      await validate.async(fieldsState, config.constraints).then(null, err => {
        setErrors(err);
        isValid = false;
        setLoading(false);
      });

      if (!isValid) return;

      setLoading(false);
      await signIn(fieldsState);
    },

    // eslint-disable-next-line
    [fieldsState],
  );

  useEffect(() => {
    const body = document.querySelector('body');

    if(body){
      body.classList.add('login');
    }
    return () => {};
  }, []);

  return (
    <Form>
      <Helmet>
        <title>{config.strings.title} - AirCycler</title>
      </Helmet>
      <div>
        <h2 className="mt-1 mb-3 text-center">{config.strings.title}</h2>
        <p className=" pb-3 text-center ">Enter your Aircycler account</p>
      </div>
      <div className="row">
        {Object.keys(configInputs.fields).map((name, index) => (
          <Input
            handleChange={(value: any) =>
              setFieldsState((prevState: any) => ({
                ...prevState,
                [name]: value,
              }))
            }
            handleChangeError={(err: any) =>
              setErrors((prevState: any) => ({
                ...prevState,
                [name]: err,
              }))
            }
            type={configInputs.fields[name].type}
            cols={configInputs.fields[name].cols}
            label={configInputs.fields[name].label}
            name={name}
            maximum={configInputs.fields[name].maximum}
            value={fieldsState[name]}
            error={errors[name]}
            autoFocus={configInputs.fields[name].autofocus}
            constraint={configInputs.constraints[name]}
            key={index}
          />
        ))}
      </div>

      <div className="d-flex pt-4 pb-2">
        <Button
          variant="primary"
          className="btn  btn-primary btn-block"
          type="button"
          disabled={loading}
          onClick={() => handleSubmit()}
        >
          {config.strings.confirm}
        </Button>
      </div>
      <div className="d-flex justify-content-center pb-3">
        <Button
          variant="link"
          className="btn  btn-link btn-sm"
          type="button"
          onClick={() => history.push('/recover-password')}
        >
          {config.strings.lostPassword}
        </Button>
      </div>
      <hr />
      <div className="d-flex justify-content-center align-items-center pt-4">
        Don't have an account?
        <Button
          variant="link"
          className="btn  btn-link"
          type="button"
          onClick={() => history.push('/signup')}
        >
          {config.strings.createAccount}
        </Button>
      </div>
      <div>
        <span className=""></span>
      </div>
      <br />
    </Form>
  );
};

export default SignIn;
