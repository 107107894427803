import config from './config';

const preventEmptyData = (companyData: any) => {
  let emptyFields: any = [];

  if (companyData) {
    Object.entries<any>(companyData).map(([key, val], i) => {
      if (
        key !== 'code' &&
        typeof val === 'string' &&
        val.trim().length === 0
      ) {
        emptyFields.push(key);
      } else if (key !== 'code' && typeof val !== 'string') {
        //WIP
      }
    });

    if (emptyFields.length > 0) {
      return true;
    } else {
      return false;
    }
  }
};

const handleOpts = (action: string, section: string) => {
  if (section === `title`) {
    if (config.titles.hasOwnProperty(action)) {
      return config.titles[action as keyof typeof config.titles];
    }
  }
  if (section === `icon`) {
    if (config.icons.hasOwnProperty(action)) {
      return config.icons[action as keyof typeof config.icons];
    }
  }
  if (section === `bodies`) {
    if (config.bodies.hasOwnProperty(action)) {
      return config.bodies[action as keyof typeof config.bodies];
    }
  }

  if (section === `bodyClass`) {
    if (config.bodyClass.hasOwnProperty(action)) {
      return config.bodyClass[action as keyof typeof config.bodyClass];
    }
  }
};

export const functions = {
  preventEmptyData: preventEmptyData,
  handleOpts: handleOpts,
};
