

export const aside = {
  state: {
    settingsNodeIds: [],
    menuOpen: true,
  },
  reducers: {
    updateState(state: any, payload: any, name: string) {
      return { ...state, [name]: payload };
    },
    setSettingsNodeIds(state: any, payload: any){
      return { ...state, settingsNodeIds: payload };
    },
    setMenuState(state: any, payload: any){
      return { ...state, menuOpen: payload };
    },
  },
  effects: (dispatch: any) => ({

  }),
};
