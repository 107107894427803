// @ts-nocheck
import React from 'react';
import { SwitchButtonProps } from './interface';
import { Switch, FormControlLabel } from '@material-ui/core';
import './styles.scss';

const SwitchButton: React.FC<SwitchButtonProps> = ({
  id,
  data,
  ckData,
  value,
  stringInfo,
  ...props
}) => {
  return (
    <div id={id}>
      <FormControlLabel
        control={
          <Switch
            style={{ marginRight: '2px' }}
            id={id}
            checked={!!value}
            onChange={props.onChange}
            color="primary"
          />
        }
      />
      <span className="lead switch-lb00-01">{stringInfo} </span>
    </div>
  );
};

export default SwitchButton;
