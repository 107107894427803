import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { TreeItem } from '@material-ui/lab';

interface StyledTreeItemProps {
  id: string;
  nodeId: any;
  labelText: string;
  labelIcon: any;
  labelInfo?: string;
  menuOpen?: string;
  color?: any;
  bgColor?: any;
  onClick?: any;
}

const StyledTreeItem: React.FC<StyledTreeItemProps> = ({
  id,
  nodeId,
  labelText,
  labelIcon: LabelIcon,
  labelInfo,
  menuOpen,
  color,
  bgColor,
  ...props
}) => {

  return (
    <TreeItem
      id={id}
      nodeId={nodeId}
      label={
        <div className="styled-tree-link">
          <LabelIcon color="inherit" />
          <span className="hide-menu pl-2">{labelText}</span>
        </div>
      }
      {...props}
    />
  );
};

export default StyledTreeItem;
