import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Button } from 'react-components';
import { UserTemplate } from 'business/modules/common';
import { AwairLogoHeader } from '../../common';
import config from './config';
import './styles.scss';

const configInputs: any = config;

const AWAIR_URL: string = configInputs?.setup?.auth;
const REDIRECT_URL: string = configInputs?.setup?.redirectURI;
const RESPONSE_TYPE: string = configInputs?.setup?.responseType;
const CLIENT_ID: string = configInputs?.setup?.clientId;

interface LoadingProps {
  start: Function;
  stop: Function;
}

interface AwairProps {
  loading: LoadingProps;
  profile: any;
  saveAwairData: any;
}

const AwairConnection: React.FC<AwairProps> = ({ profile, ...props }) => {
  const [isInit, setInit]: any = useState(false);
  const [btnDisabled, setBtnDisabled]: any = useState(false);

  const handleSubmit = async (event: any) => {
    props.loading.start('Connecting to Awair');
    localStorage.setItem('isAwairConnecting', true.toString());
    window.location.href = `${AWAIR_URL}?state=aircycler&response_type=${RESPONSE_TYPE}&scope=aircycler&redirect_uri=${REDIRECT_URL}&client_id=${CLIENT_ID}`;
    props.loading.stop();
  };

  useEffect(() => {
    setInit(true);
  }, []);

  return (
    <UserTemplate
      id="awair-connection"
      title={config?.strings?.title}
      titleBackButton
    >
      <BreadcrumbsItem to={`/dashboard/awair/connection`}>
        Awair Connection
      </BreadcrumbsItem>
      <Grid container direction="column" alignItems="center">
        <Grid item className="section-1">
          <AwairLogoHeader />
        </Grid>
        <Grid item className="section-2">
          <p>
            Connect to your <span className="section-2--bl">AWAIR</span> account
            in order to import your devices to the{' '}
            <span className="section-2--bl">AirCyclerApp</span>
          </p>
        </Grid>
        <Grid item className="section-3">
          <ul>
            <li>1 - Click on the "Connect" button below.</li>
            <li>
              2 - The Aircycler app will redirect you to the Awair server.
            </li>
            <li>3 - Use your credentials to login into Awair account.</li>
            <li>4 - You will be redirected again to the AirCycler App.</li>
          </ul>
        </Grid>
        <Grid item className="section-4"></Grid>
        <Grid item className="section-5">
          <div className="col d-flex pt-4 pb-2">
            <Button
              className="btn  btn-primary btn-block"
              type="button"
              disabled={btnDisabled}
              onClick={handleSubmit}
            >
              {config.strings.confirm}
            </Button>
          </div>
        </Grid>
      </Grid>
    </UserTemplate>
  );
};

function mapStateToProps(state: any) {
  const { profile } = state;
  return {};
}

function mapDispatchToProps(dispatch: any) {
  let { loader } = dispatch;
  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AwairConnection);
