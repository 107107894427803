import React from 'react';

interface CardProps {
  children: any;
}

const Card: React.FC<CardProps> = ({ children }) => {
  return (
    <div className="">
      <div className="row d-flex justify-content-center">
        <div className="col-12">
          <div className="jumbotron del-screen">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
