// @ts-nocheck
import config from './config';
const MAIN_PROPS: any = ['pm25', 'co2', 'tVoc'];
const SLIDERS_DEFAULTS: any = { ...config.sliders_defaults };

const normalizeMaxValues = (
  prop: string,
  maxValue: any,
  currentValues: any,
) => {
  let normalizedValue: any = maxValue;
  const { current, init }: any = currentValues || {};
  let gainA: number = 100;

  switch (prop) {
    case 'co2':
    case 'tVoc':
      gainA = 200;
      break;
    case 'pm25':
    default:
      break;
  }

  if (init && current && current > maxValue) {
    const factor: number = parseInt(current).toString().length;
    let gain: number = Math.pow(10, factor - 1);
    normalizedValue = current + 100;
    if (gain > 0) normalizedValue = parseInt(normalizedValue / gain) * gain;
  }

  return normalizedValue;
};

const formatToSaveOnStore = (values: any, ckValues: any, enabled: boolean) => {
  let tempSettings: any = {
    slider: {},
    checkbox: {},
    turnedOn: enabled,
  };

  MAIN_PROPS.forEach((prop: string) => {
    tempSettings.slider[prop] =
      values.hasOwnProperty(prop) &&
      values[prop].max !== null &&
      values[prop].max !== undefined &&
      values[prop].max !== -101
        ? values[prop].max
        : SLIDERS_DEFAULTS[prop];

    tempSettings.checkbox[prop] = { ...ckValues[prop] };
  });

  return {
    setup: { indoorAir: tempSettings },
  };
};

const isNotVC = (type: string) => {
  if (type === 'damper' || type === 'fanConnect' || type === 'centralFan') {
    return true;
  } else return false;
};

export const functions = {
  normalizeMaxValues: normalizeMaxValues,
  formatToSaveOnStore: formatToSaveOnStore,
  isNotVC: isNotVC,
};
