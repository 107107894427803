import config from '../../../../config/config';

export default {
  strings: {
    ...config.strings,
    title: 'Reset Password',
    confirm: 'Reset Password',
    cancel: 'Back',
  },
  fieldsState: {},
  fieldsFocus: {},
  fields: {
    password: {
      label: 'Password',
      type: 'password',
      placeholder: 'Password',
      cols: {
        col: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      },
    },
    confirm_password: {
      label: 'Confirm password',
      type: 'password',
      subtype:"confirm_password",
      placeholder: 'Password',
      cols: {
        col: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      },
    },
  },
  constraints: {
    password: {
      presence: true,
      format: {
        pattern:"^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
        message:
          'should be at least 8 characters long and include one uppercase letter, one lowercase letter, numbers and special character',
      },
    },
    confirm_password: {
      presence: true,
      equality: {
        attribute:"password",
        message: "^ Confirm password does not match password",
      },
    },
  },
};
