import React from 'react';
import { Grid, Switch, FormControlLabel } from '@material-ui/core';
import './styles.scss';

interface WidgetSwitchProps {
  id?: string;
  label: string;
  rootLabel: string;
  alias: any;
  value: any;
  onChange: any;
  origin?: string;
}

const WidgetSwitch: React.FC<WidgetSwitchProps> = ({
  id,
  label,
  rootLabel,
  alias,
  value,
  onChange,
  origin,
  ...props
}) => {
  const isVentConnect = (_id: any) => {
    let notAllowed: any = ['fanConnect', 'dmst', 'cfcm'];
    return notAllowed.findIndex((widget: any) => widget === _id) === -1;
  };

  return (
    <Grid item>
      <div
        className={
          origin && origin === `indoor-air-config`
            ? `row widget-switch-container1`
            : `widget-switch-container`
        }
      >
        <div className="widget-switch--values-wrapper">
          <label
            className={`widget-switch--values-label1 ${
              isVentConnect(rootLabel) ? '' : 'top-mg'
            }`}
          >
            {isVentConnect(rootLabel) ? (alias ? alias : rootLabel) : label}
          </label>
          {isVentConnect(rootLabel) && (
            <label className={`widget-switch--values-root-label mr-bt`}>
              {label}
            </label>
          )}
        </div>

        <FormControlLabel
          control={
            <Switch
              id={id}
              checked={!!value}
              onChange={onChange}
              color="primary"
            />
          }
          label={''}
        />
      </div>
    </Grid>
  );
};

export default WidgetSwitch;
