const orderListByCriteria = (orderBy: string, dataToOrder: any) => {
  if (orderBy === 'name') {
    let returnResult;
    let willBeOrdered = dataToOrder;

    returnResult = willBeOrdered.sort((a: any, b: any) => {
      let aString = a.details.name.first_name.toUpperCase();
      let bString = b.details.name.first_name.toUpperCase();
      return aString < bString ? -1 : aString > bString ? 1 : 0;
    });

    return returnResult;
  } else if (orderBy === 'date') {
    let returnResult;
    let willBeOrdered = dataToOrder;

    returnResult = willBeOrdered.sort((a: any, b: any) => {
      let aDate = a.createdAt;
      let bDate = b.createdAt;
      return aDate < bDate ? -1 : aDate > bDate ? 1 : 0;
    });

    return returnResult;
  } else if (orderBy === 'status') {
    let returnResult;
    let willBeOrdered = dataToOrder;

    returnResult = willBeOrdered.sort((a: any, b: any) =>
      a.confirmed === b.confirmed ? 0 : a.confirmed ? -1 : 1,
    );

    return returnResult;
  }
};

const counterIsEvenAndNotZero = (counter: any) => {
  if (counter % 2 === 0 && counter > 0) {
    return true;
  } else if (counter % 2 === 1) {
    return false;
  }
};

const handleOrderCriteria = (selectedOrder: any) => {
  if (selectedOrder?.byName) {
    return `name`;
  } else if (selectedOrder.byDate) {
    return `date`;
  } else if (selectedOrder?.byStatus) {
    return `status`;
  } else return `Default`;
};

const handleOrderCounter = (selectedOrder: any, intendedCounter: any) => {
  if (selectedOrder.byName) {
    return intendedCounter.name;
  } else if (selectedOrder.byDate) {
    return intendedCounter.date;
  } else if (selectedOrder.byStatus) {
    return intendedCounter.status;
  }
};

export const functions = {
  orderListByCriteria: orderListByCriteria,
  counterIsEvenAndNotZero: counterIsEvenAndNotZero,
  handleOrderCriteria: handleOrderCriteria,
  handleOrderCounter: handleOrderCounter,
};
