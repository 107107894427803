import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, Switch } from '@material-ui/core';
import { State, City } from 'country-state-city';
import ZipCodes from 'zipcodes';
import validate from 'validate.js';

import {
  AutosuggestInput,
  Notification,
} from '../../../../../../../react-components';
import config from './config';
import './styles.scss';

interface LoadingProps {
  start: any;
  stop: any;
}

interface EditDeviceProps {
  device?: any;
  isLoading?: boolean;
  loading: LoadingProps;
  getDeviceDetails: any;
  location?: any;
  device_name: string;
  startUpdated: boolean;
  updateDevice: any;
  setStartUpdated: any;
  props?: any;
}

const STORAGE_DEVICE_LABEL = '@air-editDevice:';
const COUNTRY_CODE = 'US';
const DEFAULT_STATE = { ...config.defaults.state };
const DEFAULT_CITY = { ...config.defaults.city };
const DEFAULT_ZIP_CODE = { ...config.defaults.zipCode };

const EditDevice: React.FC<EditDeviceProps> = ({
  device,
  isLoading,
  location,
  device_name,
  startUpdated,
  setStartUpdated,
  updateDevice,
  ...props
}) => {
  const [fieldsState, setFieldsState]: any = useState({
    ...config?.fieldsState,
  });
  const [errors, setErrors]: any = useState(null);
  const [allStates, setAllStates]: any = useState([]);
  const [allCities, setAllCites]: any = useState([]);
  const [allCityZipCodes, setAllCityZipCodes]: any = useState([]);

  const loadDeviceDetails = async (deviceId: string) => {
    props.loading.start();
    await props.getDeviceDetails({ device_id: deviceId });
    props.loading.stop();
  };

  const saveDeviceId = () => {
    if (device) {
      localStorage.setItem(STORAGE_DEVICE_LABEL, device.device_id);
    } else {
      if (location?.search) {
        let urlId = new URLSearchParams(location.search).get('id');
        if (urlId) localStorage.setItem(STORAGE_DEVICE_LABEL, urlId);
      }
    }
  };

  const initAllStates = () => {
    let allStates: any = State.getStatesOfCountry(COUNTRY_CODE);
    allStates = formatLocations(allStates);
    setAllStates(allStates);
    return allStates;
  };

  const formatLocations = (data: any) => {
    return data.map((item: any) => {
      const { name, isoCode } = item;
      return {
        ...item,
        label: name,
        value: isoCode ? isoCode : name,
      };
    });
  };

  const formatZipCodes = (zipCodes: any) => {
    return zipCodes.map((zpCode: any) => {
      const { zip } = zpCode;
      return {
        ...zpCode,
        value: zip,
        label: zip,
      };
    });
  };

  const initZipCodes = (cityData: any) => {
    const { stateCode, name } = cityData;
    let zipCodeData: any =
      stateCode && name ? ZipCodes.lookupByName(name, stateCode) : [];
    return zipCodeData.length > 0 ? formatZipCodes(zipCodeData) : zipCodeData;
  };

  const setLocationCities = (statesData: any) => {
    const { isoCode, value } = statesData;
    let tempCities: any = City.getCitiesOfState(COUNTRY_CODE, value);
    tempCities = formatLocations(tempCities);
    setAllCites([...tempCities]);
    return DEFAULT_STATE.isoCode === isoCode ? { ...DEFAULT_CITY } : {};
  };

  const prepareFields = () => {
    let tempCity: any = [],
      tempZipCodes: any = [];
    let deviceClone = { ...fieldsState };
    if (device) {
      deviceClone.device_name = device.device_name;
      deviceClone.status = device.status;
      deviceClone.device_state = device?.device_state
        ? { ...device.device_state }
        : DEFAULT_STATE; // { ...DEFAULT_STATE };
      if (deviceClone?.device_state)
        tempCity = setLocationCities(deviceClone.device_state);
      deviceClone.device_city = device?.device_city
        ? { ...device.device_city }
        : DEFAULT_CITY; // { ...tempCity };
      deviceClone.device_zipcode = device?.device_zipcode
        ? { ...device.device_zipcode }
        : DEFAULT_ZIP_CODE;
      if (deviceClone.device_city)
        tempZipCodes = initZipCodes(deviceClone.device_city);
      setAllCityZipCodes([...tempZipCodes]);

      setFieldsState(deviceClone);
    }
  };

  // page refresh hander
  const recoverDeviceDetails = () => {
    let storedDeviceId = localStorage.getItem(STORAGE_DEVICE_LABEL);
    if (storedDeviceId) {
      loadDeviceDetails(storedDeviceId);
    }
  };

  const onChangeState = (name: string, value: any) => {
    setFieldsState((prevState: any) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const onChangeStatus = (event: any) => {
    setFieldsState((prevState: any) => {
      return {
        ...prevState,
        status: event?.target?.checked ? 'active' : 'inactive',
      };
    });
  };

  const handleUpdateDevice = async () => {
    setStartUpdated(false);
    const isAircycler: boolean = device?.device_type === 'aircycler';
    let vlResult: any = validate(
      fieldsState,
      isAircycler ? config.constraints : config.constraints1,
    );
    if (vlResult) {
      setErrors(vlResult);
      let msm: string = '';
      Object.keys(vlResult).forEach((key: string) => {
        msm += vlResult[key].map((err: any) => `${err}, `);
      });
      msm = msm.substring(0, msm.length - 2);
      Notification({
        title: 'Error',
        message: msm,
        type: 'error',
      });
    } else {
      setErrors(vlResult);
      let fieldsClone = { ...fieldsState };
      fieldsClone.device_id = device.device_id;
      fieldsClone.device_name = device_name;
      updateDevice(fieldsClone);
    }
  };

  const onblurValidate = (fieldsStateValue: any, constraint: any) => {
    let errorsLet: any = errors || {};
    let vlResult: any = validate(fieldsStateValue, constraint);

    if (vlResult) {
      errorsLet = { ...(errorsLet || {}), ...vlResult };
      setErrors(errorsLet);
      return;
    } else {
      const [key] = Object.keys(fieldsStateValue);
      if (errorsLet[key]) {
        errorsLet[key] && delete errorsLet[key];
        setErrors(errorsLet);
      }
    }
  };


  useEffect(() => {
    if (startUpdated) {
      handleUpdateDevice();
    }
  }, [startUpdated]);

  useEffect(() => {
    prepareFields();
  }, [device]);

  useEffect(() => {
    saveDeviceId();
    window.addEventListener('beforeunload', saveDeviceId);
    recoverDeviceDetails();
    initAllStates();
    return () => {
      localStorage.removeItem(STORAGE_DEVICE_LABEL);
      window.removeEventListener('beforeunload', saveDeviceId);
    };
  }, []);

  useEffect(() => {
    if (fieldsState?.device_state?.label) {
      const { name, value } = fieldsState.device_state;
      let tempCities: any = City.getCitiesOfState(COUNTRY_CODE, value);
      tempCities = formatLocations(tempCities);
      setAllCites([...tempCities]);
    }
  }, [fieldsState?.device_state?.label]);

  useEffect(() => {
    if (fieldsState?.device_city?.label) {
      const { name, stateCode, latitude, longitude } = fieldsState.device_city;
      let tempZipCodesByCoords: any = ZipCodes.lookupByCoords(
        latitude,
        longitude,
      );
      let tempZipCodes: any = ZipCodes.lookupByName(name, stateCode);
      tempZipCodes = formatZipCodes(
        tempZipCodes && tempZipCodes.length > 0
          ? tempZipCodes
          : [tempZipCodesByCoords],
      );
      setAllCityZipCodes([...tempZipCodes]);
    }
  }, [fieldsState?.device_city?.label]);

  return (
    <div className="edit-device-container">
      <Grid item>
        {device?.device_type === 'aircycler' && (
          <>
            <Grid item >
              {allStates.length > 0 && (
                <AutosuggestInput
                  id="device-state"
                  handleChange={(newState: any) => {
                    setFieldsState((prevState: any) => {
                      return {
                        ...prevState,
                        device_state: { ...newState },
                      };
                    });
                    onblurValidate(
                      { device_state: newState },
                      { device_state: config.constraints.device_state },
                    );
                  }}
                  onBlur={(value: any) => {
                    onblurValidate(
                      { device_state: value },
                      { device_state: config.constraints.device_state },
                    );
                  }}
                  data={allStates}
                  error={errors?.device_state}
                  value={fieldsState?.device_state?.name}
                  {...config.fields.device_state}
                />
              )}
            </Grid>
            <Grid item className="edit-device-row">
              <AutosuggestInput
                id="device-city"
                handleChange={(newState: any) => {
                  setFieldsState((prevState: any) => {
                    return {
                      ...prevState,
                      device_city: { ...newState },
                    };
                  });
                  onblurValidate(
                    { device_city: newState },
                    { device_city: config.constraints.device_city },
                  );
                }}
                onBlur={(value: any) => {
                  onblurValidate(
                    { device_city: value },
                    { device_city: config.constraints.device_city },
                  );
                }}
                data={allCities}
                error={errors?.device_city}
                value={fieldsState?.device_city?.name}
                {...config.fields.device_city}
              />
            </Grid>
            <Grid item className="edit-device-row">
              <AutosuggestInput
                id="device-city--zipcode"
                handleChange={(newState: any) => {
                  setFieldsState((prevState: any) => {
                    return {
                      ...prevState,
                      device_zipcode: newState,
                    };
                  });
                  onblurValidate(
                    { device_zipcode: newState },
                    { device_zipcode: config.constraints.device_zipcode },
                  );
                }}
                data={allCityZipCodes}
                error={errors?.device_zipcode}
                onBlur={(value: any) => {
                  onblurValidate(
                    { device_zipcode: value },
                    { device_zipcode: config.constraints.device_zipcode },
                  );
                }}
                value={fieldsState?.device_zipcode?.value}
                setFieldsState={setFieldsState}
                {...config.fields.device_zipcode}
              />
            </Grid>
          </>
        )}
      </Grid>

      <div className="form-group">
        <label className="inputLabel">{config?.fields?.status?.label}</label>
        <Switch
          checked={fieldsState?.status === 'active'}
          onChange={onChangeStatus}
          color="primary"
          name="checkedB"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  let { loader, devices } = state;
  return {
    isLoading: loader.loading,
    device: devices.currentDevice,
  };
}

function mapDispatchToProps(dispatch: any) {
  let { loader, devices } = dispatch;
  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    getDeviceDetails: devices.getDeviceDetails,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDevice);
