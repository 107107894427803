import React from 'react';
import config from './config';

interface TextProps {}

const Text: React.FC<TextProps> = () => {
  return (
    <>
      <h3 className="">{config?.strings?.title}</h3>
      <p className="lead lead-ft">
        {config?.strings?.firstP}
        <br />
        {config?.strings?.secondP}
      </p>
      <p className="stronger-ft-warning">{config?.strings?.bold}</p>
      <div className="delete-space"></div>
    </>
  );
};
export default Text;
