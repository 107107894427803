import React from 'react';
import TooltipView from 'react-components/components/Tooltip';
import {
  DamperOpen,
  DamperClosed,
} from '../../../../../../assets/device-icons';
import { DamperOpenPNG, DamperClosedPNG } from '../../../../../../assets/logos';
import { TbWindmill, TbWindmillOff } from 'react-icons/tb';
import './styles.scss';

const isSafari: any = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent,
);

interface DamperStatusProps {
  status: any;
  stoped: any;
}

const DamperStatus: React.FC<DamperStatusProps> = ({
  status,
  stoped,
  ...props
}) => {


  const toolTipMessage = (status: any) => {
    return (
      <>
        <span style={{ fontWeight: 'bold' }}>Damper</span>
        <p style={{ marginTop: 3, marginBottom: -3 }}>
          Damper {status ? 'Open' : 'Closed'}.
        </p>
      </>
    );
  };


  return (
    <div className={`damper-wrapper ${isSafari ? 'safary_only' : ''}`}>
      <div className="damp-state--container">
        <div className={`damp--center ${isSafari ? 'safary_only' : ''}`}>
          <TooltipView title={toolTipMessage(status)}>
            {status ? (
              <>
                {isSafari ? (
                  <img
                    src={DamperOpenPNG}
                    className={`png-open ${stoped ? 'ping-stoped' : ''}`}
                  />
                ) : (
                  <TbWindmill
                    className="styled-damper open-damper-sf"
                    style={{
                      stroke: '#7a7d82',
                      // fill: '#565656',
                      strokeWidth: 1,
                      strokeLinecap: 'round',
                      strokeLinejoin: 'round',
                      height: '7em',
                      width: '7em',
                      backgroundColor: 'white !important',
                    }}
                  />
                )}
              </>
            ) : (
              <>
                {isSafari ? (
                  <img
                    src={DamperClosedPNG}
                    className={`png-closed ${stoped ? 'ping-stoped' : ''}`}
                  />
                ) : (
                  <svg
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="styled-damper"
                    style={{
                      stroke: '#7a7d82',
                      strokeWidth: 1,
                      // fill: '#565656',
                      height: '6.5em',
                      width: '6.5em',
                      marginTop: 30,
                    }}
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <desc></desc>
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M15.061 11.06c1.18 -.824 1.939 -2.11 1.939 -3.56c0 -2.49 -2.24 -4.5 -5 -4.5v5"></path>
                    <path d="M12 12c0 2.76 2.01 5 4.5 5c.166 0 .33 -.01 .49 -.03m2.624 -1.36c.856 -.91 1.386 -2.19 1.386 -3.61h-5"></path>
                    <path d="M12 12c-2.76 0 -5 2.01 -5 4.5s2.24 4.5 5 4.5v-9z"></path>
                    <path d="M6.981 7.033c-2.244 .285 -3.981 2.402 -3.981 4.967h9"></path>
                    <path d="M3 3l18 18"></path>
                  </svg>
                )}
              </>
            )}
          </TooltipView>
        </div>
      </div>
    </div>
  );
};

export default DamperStatus;
