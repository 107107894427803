// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { WidgetSwitch } from '../../../common';
import './styles.scss';

interface SwitchGroupProps {
  id?: string;
  data?: any;
  ckData?: any;
  device: any;
  onChange: any;
  comesFrom?: string;
}

const SwitchGroupV2: React.FC<SwitchGroupProps> = ({
  id,
  data,
  ckData,
  comesFrom,
  ...props
}) => {
  const [orderedEl, setOrderedEl]: any = useState([]);

  const retrieveValue = (_type: any, _ckData: any, _id: any) => {
    return _type
      ? _type === 'damper' || _type === 'fanConnect' || _type === 'centralFan'
        ? _ckData[_id]
        : _ckData && _ckData[_type] && _ckData[_type][_id]
        ? _ckData[_type][_id]
        : 0
      : 0;
  };

  useEffect(() => {
    if (data && Object.values(data).length > 0) {
      let tempEl: any = [];

      if (data.hasOwnProperty('damper')) {
        tempEl.push({
          name: `Damper Open`,
          type: 'damper',
          alias: null,
          id: 'dmst',
        });
      }
      if (data.hasOwnProperty('centralFan')) {
        tempEl.push({
          name: `Central Fan`,
          type: 'centralFan',
          alias: null,
          id: 'cfcm',
        });
      }

      setOrderedEl(tempEl);
    }
  }, [data]);

  return (
    <div id="indoor-air-1">
      <div id={id} className={`switchs--wrapper`}>
        {orderedEl.map((element: any, index: any) => {
          const { id, name, alias, type } = element || {};
          return (
            <div className="col-xs-12 col-sm-12 col-md-6" key={index}>
              <div
                key={`${name}+${index}`}
                className={`switchs--wrapper--container`}
              >
                <WidgetSwitch
                  origin={`indoor-air-config`}
                  id={`${id}-${type}`}
                  label={name ? name : id}
                  rootLabel={id}
                  alias={alias}
                  value={retrieveValue(type, ckData, id)}
                  onChange={props.onChange}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SwitchGroupV2;
