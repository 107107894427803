import React from 'react';
import { functions } from './helpers';
import { VentilationSetupProps } from './interface';
import {
  EnterDataCalculatedTime,
  EnterDataCalculatedFlow,
  EnterDataBalancedMode,
  EnterDataCommon,
} from '..';

const VentilationSetup: React.FC<VentilationSetupProps> = ({
  calculatedTime,
  disabled,
  primaryControl,
  onChangeValues,
  errors,
  systemDetails,
  hoursOfOp,
  selectVentSetup,
  calculatedFlow,
  balancedMode,
  config,
  cdvs,
  ventSetupValues,
  timeinHoursAndMins,
  loadedSysDetails,
  userQuitSystemUpdate,
}) => {
  const returnPlaceholderValue = (
    elem: string,
    hasVentConnect?: boolean,
    primaryCont?: string,
  ) => {
    if (loadedSysDetails && Object.keys(systemDetails).length > 0) {
      functions.handleElemPlaceholder(
        elem,
        hasVentConnect,
        primaryCont,
        systemDetails,
      );
    } else {
      return '';
    }
  };

  return (
    <>
      {selectVentSetup === `Calculated Time` &&
        Object.entries(calculatedTime).map((elem: any, index: number) => {
          return (
            <EnterDataCalculatedTime
              userQuitSystemUpdate={userQuitSystemUpdate}
              disabled={disabled}
              label={functions.returnPlaceHolder(elem[0])}
              key={index}
              className="form-control"
              id={elem[0]}
              aria-describedby={`system`}
              checkIfNumeric={functions.checkIfNum(elem[0])}
              returnAdditionalLabel={functions.returnAdditionalLabel(elem[0])}
              onChangeValues={onChangeValues}
              errors={errors}
              placeholder={returnPlaceholderValue(
                elem[0],
                cdvs && cdvs.length > 0 ? true : false,
                primaryControl,
              )}
              value={functions.handleCTVal(
                elem,
                ventSetupValues,
                primaryControl,
              )}
            />
          );
        })}
      {selectVentSetup === `Calculated Flow` &&
        Object.entries(calculatedFlow).map((elem: any, index: number) => {
          functions.handleNoRender(elem, primaryControl);

          return (
            <EnterDataCalculatedFlow
              userQuitSystemUpdate={userQuitSystemUpdate}
              disabled={disabled}
              label={functions.returnPlaceHolder(elem[0])}
              key={index}
              className="form-control"
              id={elem[0]}
              aria-describedby={`system`}
              checkIfNumeric={functions.checkIfNum(elem[0])}
              returnAdditionalLabel={functions.returnAdditionalLabel(elem[0])}
              onChangeValues={onChangeValues}
              errors={errors}
              value={functions.returnValues(elem[0], ventSetupValues)}
            />
          );
        })}
      {selectVentSetup === 'Balanced Mode' &&
        Object.entries(balancedMode).map((elem: any, index: number) => {
          return (
            <EnterDataBalancedMode
              userQuitSystemUpdate={userQuitSystemUpdate}
              disabled={disabled}
              label={functions.returnPlaceHolder(elem[0])}
              key={index}
              className="form-control"
              id={elem[0]}
              aria-describedby={`system`}
              checkIfNumeric={functions.checkIfNum(elem[0])}
              returnAdditionalLabel={functions.returnAdditionalLabel(elem[0])}
              onChangeValues={onChangeValues}
              errors={errors}
              value={functions.handleBMVal(
                elem,
                ventSetupValues,
                primaryControl,
              )}
            />
          );
        })}
      {Object.entries(config.config)
        .slice(8)
        .map((elem: any, index: number) => {
          return (
            <EnterDataCommon
              disabled={disabled}
              label={functions.returnPlaceHolder(elem[0])}
              key={index}
              type={functions.returnTypes(elem[1], elem[0])}
              className={`form-control`}
              id={elem[0]}
              aria-describedby={`system`}
              placeholder={returnPlaceholderValue(elem[0])}
              checkIfNumeric={functions.checkIfNum(elem[0])}
              onChangeValues={onChangeValues}
              errors={errors}
              systemDetails={systemDetails}
              hoursOfOp={hoursOfOp}
              value={functions.handleCommonVals(
                elem,
                ventSetupValues,
                timeinHoursAndMins,
              )}
            />
          );
        })}
    </>
  );
};

export default VentilationSetup;
