import React, { Fragment } from 'react';
import { functions } from './helpers';
import { SystemIdProps } from './interface';
import { EnterDataSystemId } from '..';

const SystemId: React.FC<SystemIdProps> = ({
  config,
  originalVSet,
  primaryDefault,
  disabled,
  primaryControl,
  addSensorLabels,
  ventilationOpts,
  onChangeValues,
  errors,
  loadedList,
  systemDetails,
  inputValues,
  currentVentSetup,
  devStatus,
  hoursOfOp,
  loadedSysDetails,
}) => {
  const returnPlaceholderValue = (
    elem: string,
    hasVentConnect?: boolean,
    primaryCont?: string,
  ) => {
    if (loadedSysDetails && Object.keys(systemDetails).length > 0) {
      functions.handleElemPlaceholder(
        elem,
        hasVentConnect,
        primaryCont,
        systemDetails,
      );
    }
  };

  return (
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 system-details-c-cols">
      {Object.entries(config.config)
        .slice(0, 8)
        .map((elem: any, index: number) => {
          return (
            <Fragment key={index}>
              <EnterDataSystemId
                originalVSet={originalVSet}
                primaryDefault={primaryDefault}
                disabled={disabled}
                label={functions.returnPlaceHolder(elem[0])}
                key={index}
                type={functions.returnTypes(elem[1], elem[0])}
                className="form-control"
                id={elem[0]}
                aria-describedby={`system`}
                devType={false}
                primaryControl={primaryControl}
                addSensorLabels={addSensorLabels}
                checkIfNumeric={functions.checkIfNum(elem[0])}
                ventilationOpts={ventilationOpts}
                returnAdditionalLabel={functions.returnAdditionalLabel(elem[0])}
                determineIcon={functions.determineIcon}
                onChangeValues={onChangeValues}
                errors={errors}
                loadedList={loadedList}
                systemDetails={systemDetails}
                currentVentSetup={currentVentSetup}
                devStatus={devStatus}
                hoursOfOp={hoursOfOp}
                name={elem[0]}
                placeholder={returnPlaceholderValue(elem[0])}
                value={functions.returnVal(elem, inputValues)}
              />
            </Fragment>
          );
        })}
    </div>
  );
};

export default SystemId;
