import React from 'react';
import { SelectProps } from './interface';
import { NativeSelect } from '@material-ui/core';
import './styles.scss';

const Select: React.FC<SelectProps> = ({
  value,
  locations,
  onChange,
  label,
  field,
  ...props
}) => {
  return (
    <div id="firm-select-wrapper">
      <label className="firm-select--label">
        {label !== null && label !== undefined ? label : 'File location'}
      </label>
      <NativeSelect
        className="select"
        value={value}
        onChange={onChange.bind(null, field)}
      >
        {locations.map((local: any, index: number) => (
          <option key={`location--${local?.id}-${index}`} value={local?.value}>
            {local.value}
          </option>
        ))}
      </NativeSelect>
    </div>
  );
};

export default Select;
