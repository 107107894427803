// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { Grid, Slider } from '@material-ui/core';
import ReactSlider from 'react-slider';
import { renderCustomThumb, LeftSideComponent, RightSideComponent } from './hooks';
import TooltipView from 'react-components/components/Tooltip';
import * as Utils from './utils';
import './styles.scss';

const UTILS = { ...Utils.default };

interface WidgetGradientSliderProps {
  id: string;
  values: any;
  onChange?: any;
  max?: any;
  min?: any;
  step?: any;
  col?: number;
  showLabel?: any;
  symbol?: string;
}

const WidgetGradientSlider: React.FC<WidgetGradientSliderProps> = ({
  id,
  values,
  onChange,
  max,
  min,
  step,
  col,
  showLabel,
  symbol,
  ...props
}) => {
  const [compData, setCompData]: any = useState(null);
  const [compValues, setCompValues]: any = useState([0, 1]);



  useEffect(() => {
    if (id && id !== '') {
      const result: any = UTILS.selectData(id);
      if (result) {
        setCompData({ ...result });
      }
    }

    return () => {
      setCompData(null);
    };
  }, [id]);

  useEffect(() => {
    if(values){
      const tempValues: any = UTILS.mapNewValues(values, {max, min});
      setCompValues([...tempValues]);
    }
  }, [values]);

  return (
    <Grid className="widget-slider-grad">
      <div id={id} className="widget-slider-grad--container">
        <Grid container>
          <Grid item className={`slider-grad--left-container--${col ? col : 1}`}>
            {compData && <LeftSideComponent data={compData} />}
          </Grid>
          <Grid item className={`slider-grad--center-container--${col ? col : 1}`}>
            <ReactSlider
              className="ac-horizontal--slider-grad"
              thumbClassName="ac--slider-grad-thumb"
              trackClassName="ac--slider-grad-track"
              value={compValues}
              step={step}
              max={max}
              min={min}
              ariaLabel={['sg-Left sg-thumb', 'sg-Middle sg-thumb', 'sg-Right sg-thumb']}
              renderThumb={renderCustomThumb.bind(null, symbol)}
              minDistance={-max}
              snapDragDisabled={true}
              onSliderClick={(value: number, tIndex: number) => {}}
              onBeforeChange={(value: number, tIndex: number) => {}}
              onChange={onChange}
            />
            <div className="slider-grad--custom-track"></div>
            <div className="default-value--wrapper">
              <TooltipView title="Default value: 100">
                <svg
                  width="10"
                  height="8" 
                  style={{
                    position: 'absolute',
                    top: 20,
                    left: '19%',
                  }}
                >
                  <rect
                    width="10"
                    height="8"
                    style={{
                      fill: '#90c095',
                      strokeWidth: 0,
                    }}
                  />
                  Sorry, your browser does not support inline SVG.
                </svg>
              </TooltipView>
            </div>
          </Grid>
          <Grid item className={`slider-grad--right-container--${col ? col : 1}`}>
            {compData && <RightSideComponent data={compData} />}
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default WidgetGradientSlider;
