import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import {
  Select,
  MenuItem,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import MaterialTable from 'material-table';
import DatePicker from 'react-datepicker';
import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import {
  DataLoggingTemplate,
} from '../../../common';
import {
  Button,
  Notification,
} from 'react-components';
import {
  DataLogsTableHead,
  TablePageSize,
  ThresholdRow,
  ThresholdRowSec,
} from './components';
import {
  fieldsUtils as UtilsFields,
  csvConverterUtil as CsvConverter,
} from './utils';
import config from './config';
import 'react-datepicker/dist/react-datepicker.css';
import './styles.scss';



const REQ_TRIES: number = 2;
const STR_LABELS: any = { ...config.strings };
const PAGE_OPTIONS: any = [...config.table.pageOptions];
const TABLE_COLUNMS: any = [...config.table.columns];
const DEFAULT_TABLE_OPTIONS: any = { ...config.table.defaultOptions };
const TABLE_OPTIONS: any = { ...config.table.options };
const THRESH_TAGS: any = [...config.threshTags];
const TOTAL_COUNT: number = config.table.totalCount;
const G3_TIMEZONES: any = [...config.timezones];
const PAGE_SIZE_STORAGE: string = '@air:page->size::';


interface LoadingProps {
  start: Function;
  stop: Function;
}


type TimeZone = {
  label: String,
  timezone: String
};

interface DataLoggsProps {
  loading: LoadingProps;
  totals: any;
  getSystems: Function;
  getSystemLogs: Function;
  systemDataLogsByPage: Function;
  dataLoggsByPeriods: Function;
  requestCsvData: Function;
  requestDatesCsvData: Function;
  menuState?: any;
}

const DataLoggs: React.FC<DataLoggsProps> = ({ ...props }) => {
  const [loggSystems, setLoggSystems]: any = useState([]);
  const [selectedSystem, setSelectedSystem]: any = useState();
  const [currentPage, setCurrentPage]: any = useState(0);
  const [_menuState, setMenuState]: any = useState('open');
  const [selectedSystemId, setSelectedSystemId]: any = useState('');
  const [selectedSystemLabel, setSelectedSystemLabel]: any = useState();
  const [pageSize, setPageSize]: any = useState(DEFAULT_TABLE_OPTIONS.pageSize);
  const [totalCount, setTotalCount]: any = useState(props.totals);
  const [selectedPageSize, setSelectedPageSize]: any = useState(1);
  const [totalPageOptions, setTotalPageOptions]: any = useState(
    DEFAULT_TABLE_OPTIONS.pageSizeOptions()
  );
  const [currentData, setCurrentData]: any = useState({
    title: '',
    data: [],
  });
  const [threshData, setThreshData]: any = useState({
    top: null,
    show: false,
    data: {},
  });
  const [openExportDialog, setOpenExportDialog]: any = useState(false);
  const [startDate, setStartDate]: any = useState();
  const [endDate, setEndDate]: any = useState();
  const [downloadEnabled, setDownloadEnabled]: any = useState(false);
  const [onInit, setOnInit]: any = useState(true);
  const [sysTimezone, setSysTimezone]: any = useState('');



  const onChangeSystem = useCallback((event: any) => {
    const { value }: any = event?.target || {};

    let foundedSystem: any = value && loggSystems.length > 0 ? loggSystems.find(
      (item: any) => item?.device_id === value
    ) : null;

    if(foundedSystem){
      const currTimezone: string = handleSystemTimeZone(foundedSystem);

      setSysTimezone(currTimezone);
      setSelectedSystemId(value);
      setSelectedSystem(foundedSystem);
    }
  }, [loggSystems]);


  const defineSelectStyle = useCallback(() => {
    if(selectedSystemLabel){
      const selectWidth: number = selectedSystemLabel.length * 0.6;
      return { width: `${selectWidth}%` };
    }
    return {};
  }, [selectedSystemLabel]);


  const defineTimeZoneStyle = useCallback(() => {
    if(selectedSystemLabel){
      let timeZoneWidth: number = selectedSystemLabel.length * 0.55;

      if(timeZoneWidth > 52) timeZoneWidth = 52;

      return { marginLeft: `${timeZoneWidth}%` };
    }
    return {};
  }, [selectedSystemLabel]);


  const onPageChange = (event: any, page: number) => {
    if(page !== null && page !== undefined){
      setCurrentPage(page);
    }
  };


  const handleSystemTimeZone = (system: any) => {
    const { details }: any = system || {};
    const { conf }: any = details?.settings || {};
    const _sysTimeZone: number = conf && conf.tmzn ? conf.tmzn : -1;
    const currTimezone: string = UtilsFields.timezoneMapper(G3_TIMEZONES, _sysTimeZone);

    return currTimezone;
  };

  const pageSizeStorage: any = {
    save: (value: any) => {
      localStorage.setItem(PAGE_SIZE_STORAGE, value);
    },
    recover: () => {
      const value: any = localStorage.getItem(PAGE_SIZE_STORAGE);

      return value !== null && value !== undefined ? value : 1;
    }
  };

  const onPageSizeChange = (event: any) => {
    if(event?.target?.value){
      const selectedPage: number = Number(event.target.value);
      setSelectedPageSize(selectedPage);
      pageSizeStorage.save(selectedPage);
    }
  };


  const displaySystemTimezone = useCallback(() => {
    const currentTmzData: any = G3_TIMEZONES.find((tmz: TimeZone) => {
      return tmz.timezone === sysTimezone;
    });

    return currentTmzData?.label ? currentTmzData.label : (STR_LABELS?.defaultTimezone || '');
  }, [sysTimezone]);


  const loadSystems = async () => {
    props.loading.start(STR_LABELS.loading);
    let tempResponse: any = await props.getSystems();
    let tempSelected: any;

    if(tempResponse && Array.isArray(tempResponse) && tempResponse.length > 0){
      tempSelected = _.cloneDeep(tempResponse[0]);

      setLoggSystems(
        _.cloneDeep(tempResponse)
      );

      if(tempSelected?.device_id){
        const currTimezone: string = handleSystemTimeZone(tempSelected);

        setSysTimezone(currTimezone);
        setSelectedSystemId(tempSelected.device_id);
        setSelectedSystem(tempSelected);
      }
    }

    // if selected system is not null
    // loading will be stoped in "handleNewSelectionByPage" function
    if(!tempSelected){
      props.loading.stop();
    }

  };


  const handleDetailsMapping = (details: any, _timezone: any) => {
    let rowMapped: any = {};
    const { dates }: any = details;

    TABLE_COLUNMS.forEach((cell: any, index: number) => {
      const { field }: any = cell;
      const value: any = UtilsFields.mapDataLogField(field, details);

      if(
        (field === 'time' || field === 'date') &&
        _timezone &&
        _timezone !== ''
      ){
        const mappedDate: any = UtilsFields.mapTimezone(
          _timezone, value, dates?.date, field
        );

        rowMapped[field] = mappedDate && mappedDate !== '' ? mappedDate : value;
      }else{
        rowMapped[field] = value;
      }
    });

    if(dates){
      rowMapped.sortDate = dates.date;
    }

    return { ...rowMapped };
  };


  const mapDataLogs = (data: any, _timezone: any) => {
    let mappedData: any = [];

    if(data && Array.isArray(data) && data.length > 0){
      mappedData = data.map((item: any) => {
        const { details, createdAt }: any = item;
        const tempDetailsMapp: any = handleDetailsMapping(details, _timezone);

        return { ...tempDetailsMapp, createdAt };
      });
    }

    return mappedData;
  };


  const retryLogsData = async (_system: any, page: number, periodsSize: number) => {
    const { device_name, device_id } = _system;

    props.loading.start(STR_LABELS.reTryLoading);

    const result: any = !periodsSize || periodsSize <= 1 ? await props.systemDataLogsByPage(
      { systemId: device_id, page }
    ) : await props.dataLoggsByPeriods(
      { systemId: device_id, page, periods: periodsSize }
    );

    props.loading.stop();

    return result;
  };


  const handleNewSelectionByPage = async (
    _system: any,
    page: number,
    periodsSize: number,
    _timezone: string
  ) => {
    let dataClone: any = _.cloneDeep(currentData);
    const { device_name, device_id } = _system;
    let reqTimes: number = 0;

    if(device_id){
      props.loading.start(STR_LABELS.loading);
      let systemLogs: any = !periodsSize || periodsSize <= 1 ? await props.systemDataLogsByPage(
        { systemId: device_id, page }
      ) : await props.dataLoggsByPeriods(
        { systemId: device_id, page, periods: periodsSize }
      );

      // retry request
      if(systemLogs === 400 && onInit){
        do {
          systemLogs = await retryLogsData(_system, page, periodsSize);
          reqTimes++;
        } while (reqTimes < REQ_TRIES);
      }

      dataClone.title = device_name;
      const tempMapData: any = systemLogs && systemLogs.length > 0 ? mapDataLogs(systemLogs, _timezone) : [];
      dataClone.data = _.cloneDeep(tempMapData);

      if(tempMapData.length !== pageSize){
        setPageSize(tempMapData.length);
      }

      props.loading.stop();
    }

    setOnInit(false);
    setCurrentData(dataClone);
  };


  const handleCsvRequestData = async (
    systemId: string,
    page: number,
    periods: number
  ) => {
    const result: any = await props.requestCsvData(
      { systemId, page, periods }
    );

    if(result?.length > 0){
      return mapDataLogs(result, sysTimezone);
    }

    return;
  };

  const handleCsvDatesRequestData = async (
    systemId: string,
    startTime: number,
    endTime: number
  ) => {
    const result: any = await props.requestDatesCsvData(
      { systemId, start: startTime, end: endTime }
    );

    if(result?.length > 0){
      return mapDataLogs(result, sysTimezone);
    }

    return;
  };


  const handleCsvRequestDayByDay = async (
    systemId: string,
    startTime: number,
    endTime: number
  ) => {
    const twentyFourHours: number = ((1000 * 60) * 60) * 24;
    let tempStartTime: number = startTime;
    let tempEndTime: number = startTime + twentyFourHours;
    let responseData: any = [];

    do {
      const tempResult: any = await handleCsvDatesRequestData(systemId, tempStartTime, tempEndTime);

      if(tempResult && tempResult.length > 0){
        const resultReversed: any = tempResult.reverse();
        responseData = responseData.concat(resultReversed);
      }

      tempStartTime = tempEndTime;
      tempEndTime = tempEndTime + twentyFourHours;
    } while (tempEndTime <= endTime);

    return responseData;
  };


  const transformDate = (value: any, option: string) => {
    switch (option) {
      case 'end':
        return moment(value).add(1, 'days').valueOf();
      default:
        return moment(value).valueOf();
    }
  };

  const checkDifference = (dateA: any, dateB: any) => {
    const momentA: any = moment(dateA);
    return momentA.diff(moment(dateB), 'days');
  };


  const handleExportCsv = async (
    _system: any,
    page: number,
    pageSize: number,
    start: any,
    end: any,
    columns: any,
    data: any,
  ) => {
    setOpenExportDialog(false);
    props.loading.start('Loading CSV Data...');
    const { device_id }: any = _system;
    const requestParams: any = CsvConverter.defineCsvRequestParams(page, pageSize);
    let totalData: any = data && Array.isArray(data) && data.length > 0 ? _.cloneDeep(data) : [];
    let reqResult: any;
    let timeGain: number = 1;

    if(device_id){
      if(start && end){
        const startTime: any = transformDate(start, 'start');
        const endTime: any = transformDate(end, 'end');
        const datesDiff: any = checkDifference(endTime, startTime);
        timeGain = datesDiff;

        if(datesDiff && datesDiff > 4){
          reqResult = await handleCsvRequestDayByDay(device_id, startTime, endTime);
        }else{
          const tempData: any = await handleCsvDatesRequestData(device_id, startTime, endTime);
          reqResult = tempData && tempData.length > 0 ? tempData.reverse() : tempData;
        }
      }else{
        const { _page, _periods }: any = requestParams;
        const reqData: any = await handleCsvRequestData(device_id, _page, _periods);
        reqResult = reqData && reqData.length > 0 ? reqData.reverse() : reqData;
      }

      CsvConverter.exportCsv(columns, (reqResult ? reqResult : data));
    }

    props.loading.stop();
    setStartDate();
    setEndDate();
  };

  const handleOpendDialog = () => {
    setOpenExportDialog(true);
  };

  const handleCloseExportDialog = () => {
    setStartDate();
    setEndDate();
    setOpenExportDialog(false);
  };



  const handleTableScroll = (data: any) => {
    const tBodyElement: any = document.getElementsByClassName('page-wrapper');
    const tableElement: any = document.getElementsByTagName('table');
    let rowElements: any = [];

    if(data.length > 0){
      for (const item of data) {
        const { index, row } = item;
        const rwElement: any = document.getElementById(`row--${index}`);

        if(rwElement){
          const rwRect: any = rwElement.getBoundingClientRect();

          if(rwRect.y) rowElements.push(rwRect.y - 49);
        }
      }
    }

    if(tBodyElement !== null && tBodyElement !== undefined){
      const tRect: any = tableElement[0].getBoundingClientRect();
      const tTop: number = tRect?.y >= 0 ? tRect.y : (tRect.y * (-1));

      tBodyElement[0].onscroll = (event: any) => {
        const { scrollTop }: any = event?.target;
        const markIndex: number = rowElements.findIndex((mark: number, index: number) => {
          const scrollMark: number = scrollTop + 245;

          return scrollTop > tTop ?
            (rowElements.length - 1) >= index + 1
              ? scrollMark >= mark && scrollMark < rowElements[index + 1]
              : scrollMark >= mark
            : false;
        });

        if(markIndex > -1){
          const dataClone: any = _.cloneDeep(data[markIndex]);

          setThreshData((prevData: any) => {
            return {
              top: scrollTop - 245,
              show: true,
              data: dataClone.row,
            };
          });
        }else{
          setThreshData((prevData: any) => {
            return {
              top: null,
              show: false,
              data: {},
            };
          });
        }
      };
    }

  };


  const filterThresoldChanges = (data: any) => {
    let filteredData: any = [];
    const fieldsCk: any = _.cloneDeep(THRESH_TAGS);
    let lastValues: any = {};
    const checkRow = (row: any, _lastValues: any) => {
      let valid: boolean = false;

      for (const key in _lastValues) {
        if (_lastValues[key] !== row[key]) {
          valid = true;
          break;
        }
      }
      return valid;
    };

    fieldsCk.forEach((field: any) => {
      lastValues[field] = null;
    });

    for (const [index, row] of data.entries()) {
      if(checkRow(row, lastValues)){
        fieldsCk.forEach((field: any) => {
          lastValues[field] = row[field];
        });

        filteredData.push({
          index: index,
          row: { ...lastValues },
        });
      }
    }

    return filteredData;
  };




  useEffect(() => {
    if(
      startDate !== null &&
      startDate !== undefined &&
      endDate !== null &&
      endDate !== undefined
    ){
      setDownloadEnabled(true);
    }else{
      setDownloadEnabled(false);
    }

  }, [startDate, endDate]);



  useEffect(() => {
    if(currentData?.data && currentData.data.length > 0){
      const filteredData: any = filterThresoldChanges(currentData.data);

      handleTableScroll(filteredData);
    }
  }, [currentData]);


  useEffect(() => {
    if(props?.totals){
      setTotalCount(
        props.totals > TOTAL_COUNT ? TOTAL_COUNT : props.totals
      );
    }
  }, [props?.totals]);


  useEffect(() => {
    if(selectedSystem){
      const { device_name, details }: any = selectedSystem || {};

      setThreshData((prevData: any) => {
        return { top: null, show: false, data: {} };
      });
      handleNewSelectionByPage(
        selectedSystem, (currentPage + 1), selectedPageSize, sysTimezone
      );

      if(device_name && details?.name){
        setSelectedSystemLabel(`${device_name} ( ${details.name} )`);
      }
    }
  }, [selectedSystem, currentPage, selectedPageSize]);


  useEffect(() => {
    if(props?.menuState){
      setMenuState(props.menuState);
    }
  }, [props?.menuState]);


  useEffect(() => {
    let storedPageSize: any = pageSizeStorage.recover();

    if(storedPageSize && !isNaN(storedPageSize)){
      storedPageSize = Number(storedPageSize);
      setSelectedPageSize(storedPageSize);
    }

    loadSystems();

    return () => {
      setDownloadEnabled(false);
      setOnInit(true);
      setSysTimezone('');
      setSelectedSystemLabel();
    };
  }, []);


  return (
    <DataLoggingTemplate id="data-logging-main" title="Data Logging">
      <div className="data-loggs--container">
        <div className="data-loggs--header">
          <h4>{STR_LABELS?.titleLabel}</h4>
        </div>
        <div className="data-loggs--toggler">
          <div
            className="select-wrapper"
            style={
              selectedSystem && selectedSystem?.device_name?.length >= 22
                ? defineSelectStyle()
                : {}
            }
          >
            <Select
              className="select"
              value={selectedSystemId}
              onChange={onChangeSystem}
            >
              {loggSystems &&
                loggSystems.map((_system: any, index: number) => (
                  <MenuItem
                    key={_system?.device_id + index}
                    value={_system?.device_id}
                  >
                    {_system?.device_name && _system.device_name !== "" ? _system.device_name : _system?.details?.name}
                    {
                        <div
                          className="system--alias"
                          style={{marginLeft: 15, fontSize: '0.7rem', fontWeight: 500}}
                        >
                          ( {_system?.details?.name || ''} )
                        </div>
                    }
                  </MenuItem>
                ))}
            </Select>
          </div>

          <div className="toggler-right filters-margin">
          </div>
        </div>
        <div
          id="data-logging-table"
          className={`data-loggs--table-wrapper menu-${_menuState}`}
        >
          <div className="data-loggs--table-wrapper--t-sec">
            <ThresholdRowSec columns={TABLE_COLUNMS} {...threshData} />
          </div>
          <div className="timezone-wrapper" style={
            selectedSystem && selectedSystem?.device_name?.length >= 22
              ? defineTimeZoneStyle()
              : {}
          }>
            <div className="timezone-label">
                {STR_LABELS?.tmzLabel}
            </div>
            <div className="timezone-value">
              {displaySystemTimezone()}
            </div>
          </div>
          <MaterialTable
            key={`m-table--${currentData?.data?.length || 0}`}
            columns={TABLE_COLUNMS}
            data={currentData.data}
            title={currentData.title}
            options={{
              exportCsv: handleOpendDialog,
              pageSize: pageSize,
              pageSizeOptions: totalPageOptions,
              ...TABLE_OPTIONS,
            }}
            components={{
              Container: ({ children }: any, other: any) => {
                return (
                  <div className="data-loggs--table-container">
                    {children && children.length > 0 && children.map((component: any, index: number) => {
                      return index !== 1 ? component : <ThresholdRow key={'thresh-key--' + index} data={currentData.data || []} />;
                    })}
                  </div>
                );
              },
              Header: _props => {
                return (
                  <DataLogsTableHead columns={TABLE_COLUNMS} props={_props} />
                );
              },
              Row: ({ data, index, path }: any) => {
                return (
                  <TableRow
                    id={path && path.length > 0 ? `row--${path[0]}` : `${index}--id`}
                    className={`dtl--cell ${index % 2 === 0 ? 'row-shadow' : ''}`}
                  >
                    {TABLE_COLUNMS.map((row: any, index: number) => {
                      const { field }: any = row;
                      const isThreshTag: boolean = THRESH_TAGS.findIndex(
                        (tag: any) => tag === field
                      ) > -1;

                      if(isThreshTag){
                        return null;
                      }

                      return (
                        <TableCell key={`index--${index}`} align="center" className={field === 'curr_mode' ? field : ''}>
                          {data[field]}
                          {UtilsFields.handleFieldsUnits(field, data[field])}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              },
              Pagination: (_props: any, other: any) => {
                return (
                  <>
                    <TablePageSize
                      options={PAGE_OPTIONS}
                      pageSize={selectedPageSize}
                      onPageSizeChange={onPageSizeChange}
                    />
                    <TablePagination
                      id="main-pag-table"
                      labelRowsPerPage={''}
                      page={currentPage}
                      count={totalCount}
                      rowsPerPageOptions={totalPageOptions}
                      rowsPerPage={pageSize}
                      onPageChange={onPageChange}
                      onRowsPerPageChange={() => {}}
                    />
                  </>
                );
              },
            }}
          />
        </div>
        <Dialog id="dtl-dialog" open={openExportDialog} onClose={handleCloseExportDialog}>
          <DialogTitle id="dtl-dialog-title">
            Export as CSV
          </DialogTitle>
          <DialogContent className="dtl-dialog--container">
            <div className="dtl-dialog--date-picker">
              <div className="date-picker-from">
                <div className="dp-title">Start Date</div>
                <DatePicker
                  selected={startDate}
                  dataFormat={'MM/DD/YYYY'}
                  maxDate={moment().toDate()}
                  inline
                  onChange={(date: any) => {
                    setStartDate(date);
                  }}
                />
              </div>
              <div className="date-picker-to">
                <div className="dp-title">End Date</div>
                <DatePicker
                  selected={endDate}
                  dataFormat={'MM/DD/YYYY'}
                  maxDate={moment().toDate()}
                  inline
                  onChange={(date: any) => {
                    setEndDate(date);
                  }}
                />
              </div>
            </div>
            <div className={`dtl-dialog--date-error ${!downloadEnabled ? 'show' : ''}`}>
              <span className="dtl-dialog--date-text">
                Please select a valid date range
              </span>
            </div>
          </DialogContent>
          <DialogActions className="dtl-dialog--actions">
            <div className="dtl-dialog--actions-wrapper">
              <Button
                variant="secondary"
                onClick={handleCloseExportDialog}
                className="mr-4"
              >
                Cancel
              </Button>
              <Button
                className=""
                disabled={!downloadEnabled}
                onClick={handleExportCsv.bind(
                  null, selectedSystem, currentPage, selectedPageSize,
                  startDate, endDate,
                )}
              >
                Download
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    </DataLoggingTemplate>
  );
};


function mapStateToProps(state: any) {
  const { dataLogging, env } = state;

  return {
    dataLogs: dataLogging.data,
    totals: dataLogging.totalCount,
    menuState: env.open,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { loader, devices, dataLogging } = dispatch;

  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    getSystems: devices.getSystems,
    getSystemLogs: dataLogging.getDataLogsBySystem,
    systemDataLogsByPage: dataLogging.getSystemDataLogsByPage,
    dataLoggsByPeriods: dataLogging.getSystemDataLogsByMuiltiPeriods,
    requestCsvData: dataLogging.requestCsvData,
    requestDatesCsvData: dataLogging.requestDatesCsvData,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DataLoggs);
