import { ConnectionBroker } from '../../core';
import config from '../config/config';

export const adminInstallerDevsState = {
  state: {
    userCleared: false,
    deviceItem: {},
    updatingDevData: {
      device: '',
      state: '',
      city: '',
      macAddress: '',
      zipCode: '',
      switchState: false,
    },
    newDevData: {
      device: '',
      state: '',
      city: '',
      macAddress: '',
      zipCode: '',
      switchState: false,
    },
    mandatoryFieldsMissing: true,
    mandatoryEditionFieldsMissing: false,
    devUpdated: false,
    updMessage: ``,
    errors: { mandatoryFieldsMissing: 'Please fill all the required data.' },
    editionErrors: {
      mandatoryEditionFieldsMissing: 'Please fill all the required data.',
    },
    installersDevCounter: 0,
    installersDevIntendedCounter: { name: 0, location: 0, status: 0 },
    orderedList: false,
    orders: {
      orderByLocation: false,
      orderByStatus: false,
      orderByName: false,
      defaultOrder: true,
    },
  },
  reducers: {
    updateOrderState(state: any, payload: string) {
      switch (payload) {
        case 'Name':
          return {
            ...state,
            orderedList: false,
            orders: {
              orderByLocation: false,
              orderByStatus: false,
              orderByName: true,
              defaultOrder: false,
            },
          };
        case 'Location':
          return {
            ...state,
            orderedList: false,
            orders: {
              orderByLocation: true,
              orderByStatus: false,
              orderByName: false,
              defaultOrder: false,
            },
          };
        case 'Status':
          return {
            ...state,
            orderedList: false,
            orders: {
              orderByLocation: false,
              orderByStatus: true,
              orderByName: false,
              defaultOrder: false,
            },
          };
        case 'Default':
          return {
            ...state,
            orderedList: true,
            orders: {
              orderByLocation: false,
              orderByStatus: false,
              orderByName: false,
              defaultOrder: true,
            },
          };
        default:
          return {
            ...state,
            orderedList: true,
            orders: {
              orderByLocation: false,
              orderByStatus: false,
              orderByName: false,
              defaultOrder: true,
            },
          };
      }
    },
    userStartedToType(state: any) {
      return {
        ...state,
        userCleared: false,
      };
    },
    setError(state: any, payload: any) {
      if (payload === '') {
        return {
          ...state,
          mandatoryFieldsMissing: false,
        };
      }
      if (payload === 'mandatoryFieldsMissing') {
        return {
          ...state,
          mandatoryFieldsMissing: true,
          errors: {
            mandatoryFieldsMissing:
              config.adminInstallersDevState.mandatoryFieldsMissing,
          },
        };
      }
    },
    saveDeviceItemGlobally(state: any, payload: any) {
      localStorage.setItem('@instDevItem', JSON.stringify(payload));
      const persistentDev = localStorage.getItem('@instDevItem');
      let parsed;

      if (persistentDev) {
        parsed = JSON.parse(persistentDev);
      }
      return {
        ...state,
        deviceItem: parsed,
      };
    },
    handleDevsListCounterAndOrder(state: any, payload: any, counter: number) {
      return {
        ...state,
        installersDevIntendedCounter: payload,
        installersDevCounter: counter,
      };
    },
    setDefaultOrder(state: any, payload: boolean) {
      return {
        ...state,
        defaultOrder: payload,
      };
    },
    updateDev(state: any, payload: any) {
      return {
        ...state,
        updatingDevData: payload,
      };
    },
    fillNewDev(state: any, payload: any) {
      return {
        ...state,
        newDevData: payload,
      };
    },
    clearNewDevInstaller(state: any) {
      return {
        ...state,
        userCleared: true,
        newDevData: {
          device: '',
          state: '',
          city: '',
          macAddress: '',
          zipCode: '',
          switchState: false,
        },
      };
    },
    setAddDevError(state: any, payload: any) {
      if (payload === '') {
        return {
          ...state,
          mandatoryFieldsMissing: false,
        };
      }
      if (payload === 'mandatoryEditionFieldsMissing') {
        return {
          ...state,
          mandatoryFieldsMissing: true,
          errors: {
            mandatoryFieldsMissing:
              config.adminInstallersDevState.mandatoryFieldsMissing,
          },
        };
      }
    },
  },
  effects: (dispatch: any) => ({
    //WIP - waiting for endpts
    async getTheInstallersDevicesList() {
      const that: any = this;
      let response: any = {};
      //TODO get installers list fetch

      return response;
    },
    async createInstallerDevice(data: any) {
      //TODO create installer device

      let response: any = {};

      return response;
    },
  }),
};
