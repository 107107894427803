import React from 'react';

interface HeaderProps {
  onClick: any;
  spanClass: any;
  headerClass: string;
}

const Header: React.FC<HeaderProps> = ({ onClick, spanClass, headerClass }) => {
  return (
    <div className={headerClass}>
      <span className={spanClass} onClick={onClick}></span>
    </div>
  );
};

export default Header;
