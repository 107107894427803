import React from 'react';
import { PhoneInputProps } from './interface';
import { InputPhone } from '../../../../../../../react-components';
import './styles.scss';

const PhoneInput: React.FC<PhoneInputProps> = ({
  index,
  name,
  configInputs,
  errors,
  editMode,
  handleChange,
  handleChangeError,
  value,
}) => {
  return (
    <div className="input--phone--field">
      <InputPhone
        key={`phone--${index}`}
        handleChange={handleChange}
        handleChangeError={handleChangeError}
        name={name}
        value={value}
        error={errors}
        constraint={configInputs}
        disabled={editMode}
      />
    </div>
  );
};

export default PhoneInput;
