// @ts-nocheck

const prepareToSaveSetup = (values: any, enabled: boolean) => {
  const { temp, aqi, hum, cfm } = values;

  let tempSetup: object = {
    temp: {
      max: temp.max,
      min: temp.min,
    },
    hum: hum.max,
    aqi: aqi.max,
    cfm: cfm.max,
    turnedOn: enabled,
  };

  return {
    setup: {
      outsideAir: { ...tempSetup },
    },
  };
};

export const functions = {
  prepareToSaveSetup: prepareToSaveSetup,
};
