import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { FiChevronDown } from 'react-icons/fi';
import { BsBell } from 'react-icons/bs';
import { Dropdown } from 'react-bootstrap';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import DropdownToggle from 'react-bootstrap/DropdownToggle';
import { NotificationComponent } from './components';
import configs from '../../../../config/config';
import config from './config';
import './styles.scss';


interface Notification {
  devices: any;
  user: any;
  isInit: any;
  currentAlerts: any;
  socketConnected: any;
  setSocketConnection: Function;
  updateAlerts: Function;
  listSystemNotifications: Function;
  markAsRead: Function;
}

const Notification: React.FC<Notification> = ({
  devices,
  currentAlerts,
  user,
  listSystemNotifications,
  ...props
}) => {
  const [systems, setSystems]: any = useState([]);
  const [notifications, setNotifications] = useState([]);


  const filterSystems = (data: any) => {
    return data.filter((item: any) => {
      return item.device_type === 'aircycler' && item.isSystem;
    });
  };

  const filterNotifications = (items: any) => {
    let uniqIds: any = items && Array.isArray(items) ? [...new Set(items.map((item: any) => item?.content?.id))] : [];
    uniqIds = uniqIds.filter(
      (iD: any) => iD !== undefined && iD !== null
    );
    let filtered: any = [];

    if(uniqIds.length > 0)
    uniqIds.forEach((id: string) => {
      const tempItem: any = items.find(({ content }: any) => {
        return content.id === id;
      });
      filtered.push(tempItem);
    });

    return filtered;
  };


  const handleClickNotification = (item: any, event: any) => {
    let notfClone: any = _.cloneDeep(notifications);
    const { id }: any = item?.content || {};
    const nIndex: number = notfClone.findIndex(
      ({ content }: any) => content.id === id
    );

    if(nIndex > -1){
      let filteredNot: any = notfClone.filter(
        ({ content }: any) => content.id !== notfClone[nIndex]?.content?.id
      );
      const { message_id, system_id } = notfClone[nIndex];
      const sysMatch: any = systems.find((sys: any) => {
        const { details } = sys;
        return details.id === system_id;
      });

      if(sysMatch){
        const { api_key } = sysMatch;
        props.markAsRead({ id: message_id, key: api_key });
      }

      setNotifications(filteredNot);
    }
  };


  const loadAlerts = async (apiKey: string) => {
    let allAlerts: any = await listSystemNotifications({ key: apiKey });
    let tempAlerts: any = [];

    if(allAlerts && Array.isArray(allAlerts) && allAlerts.length > 0){
      tempAlerts = allAlerts.map((alert: any) => {
        return { ...alert };
      });
    }

    return tempAlerts;
  };


  const startLoadSystemsAlerts = async (localSystems: any) => {
    let tempAlerts: any = [];

    for (const system of systems) {
      const { device_id, api_key } = system;
      let temp: any = await loadAlerts(api_key);
      if(temp && Array.isArray(temp) && temp.length > 0){
        tempAlerts = tempAlerts.concat(temp);
      }
    }

    setNotifications(tempAlerts);
  };


  useEffect(() => {
    if(devices && devices.length > 0){
      const currentSystems: any = filterSystems(devices);
      if(currentSystems && Array.isArray(currentSystems) && currentSystems.length > 0) setSystems([...currentSystems]);
    }
  }, [devices]);


  useEffect(() => {
    if(user && systems && systems.length > 0){
      const { account_id } = user;
      const { api_key } = systems[0];

      if(!props.socketConnected){
        props.setSocketConnection({
          type: config.sockets.type,
          apiKey: api_key,
          userId: account_id,
        });
      }

    }
  }, [user, systems]);


  useEffect(() => {
    if(currentAlerts){
      let notsClone: any = _.cloneDeep(notifications);
      notsClone = notsClone.concat(currentAlerts);
      let filteredNotifications: any = filterNotifications(notsClone);
      setNotifications(filteredNotifications);
    }
  }, [currentAlerts]);


  useEffect(() => {
    if(systems && systems.length > 0 && props?.socketConnected && !props.isInit){
      startLoadSystemsAlerts(systems);
    }
  }, [systems, props]);


  return (
    <div className="dropdown notifications-wrapper">
      <Dropdown>
        <DropdownToggle as="span" className="nav-link cursor-pointer">
          <div className="notifications-container">
            <BsBell size={25} />
            {notifications.length > 0 && <div className="bell-badge">
              {notifications.length}
            </div>}
          </div>
          <div className="ml-2 d-none d-lg-inline-block">
            <FiChevronDown />
          </div>
        </DropdownToggle>
        <DropdownMenu align="right" className="container-dropdown">
          <ul>
            {notifications.length > 0 ? notifications.map((notification: any, index: number) => (
              <li
                key={`${notification} + ${index}`}
                className="nav-link cursor-pointer"
                onClick={handleClickNotification.bind(null, notification)}
              >
                <NotificationComponent {...notification} />
              </li>
            )) :
              <li className="nav-link nav-link--empty">
                <span>no notifications...</span>
              </li>}
          </ul>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};


function mapStateToProps(states: any) {
  const { devices, profile, alerts } = states;
  return {
    devices: devices.devices,
    user: profile.user,
    isInit: alerts.isInit,
    currentAlerts: alerts.currentNotifications,
    socketConnected: alerts.isConnected,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { alerts } = dispatch;
  return {
    setSocketConnection: alerts.setAlertsConnection,
    updateAlerts: alerts.updateNotifications,
    listSystemNotifications: alerts.showActiveAlertsNotifications,
    markAsRead: alerts.markNotificationsAsRead,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
