export default {
  formats: {
    ppm: '0,0',
    ugm3: '0,0',
    ppb: '0,0',
  },
  model: {
    max: -101,
    min: -101,
    current: 0,
    init: false,
  },
  units: {
    ugm: `ug/m`,
    ppm: `ppm`,
    ppb: `ppb`,
    pm25: `PM 2.5`,
    co: `CO`,
    tVoc: `tVOC`,
  },
  sensorsData: [
    { id: 'api_key', label: 'API key' },
    { id: 'airQuality', label: 'Air quality' },
    { id: 'id', label: 'ID' },
    { id: 'sensorsStats', label: 'Sensors Stats' },
    { id: 'station', label: 'Station' },
    { id: 'device_id', label: 'Device ID' },
    { id: 'uuid', label: 'UUID' },
    { id: 'device_name', label: 'Device name' },
    { id: 'device_type', label: 'Device type' },
    { id: 'isSystem', label: 'Is system' },
    { id: 'status', label: 'Status' },
    { id: 'user_id', label: 'User ID' },
    { id: '__v', label: '__v' },
    { id: '_id', label: '_ID' },
    { id: 'name', label: 'Name' },
    { id: 'datapoints', label: 'Datapoints' },
    { id: 'end', label: 'End' },
    { id: 'sensors', label: 'Sensors' },
    { id: 'start', label: 'Start' },
    { id: 'units', label: 'Units' },
  ],
};
