import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Grid, Switch, NativeSelect } from '@material-ui/core';
import SweetAlert from 'react-bootstrap-sweetalert';
import { State, City } from 'country-state-city';
import ZipCodes from 'zipcodes';
import validate from 'validate.js';
import { UserTemplate } from 'business/modules/common';
import {
  DropdownFilter,
  AutosuggestInput,
  Input,
  Button,
  Notification,
} from '../../../../../react-components';
import config from './config';
import './styles.scss';




interface LoadingProps {
  start: any;
  stop: any;
}

interface EditSystemProps {
  device?: any;
  isLoading?: boolean;
  loading: LoadingProps;
  getDeviceDetails: any;
  updateDevice: any;
  location: any;
  props: any;
  getAllSensors: Function;
  allSensors: any;
}

const STORAGE_DEVICE_LABEL = '@air-editDevice:';
const COUNTRY_CODE = 'US';
const DEFAULT_STATE = { ...config.defaults.state };
const DEFAULT_CITY = { ...config.defaults.city };


const EditSystem: React.FC<EditSystemProps> = ({
  device,
  isLoading,
  location,
  ...props
}) => {
  const [fieldsState, setFieldsState]: any = useState({
    ...config?.fieldsState,
  });
  const [tempStateVal, setTempStateVal] = useState('');
  const [errors, setErrors]: any = useState(null);
  const [showUpdateAlert, setShowUpdateAlert]: any = useState(false);
  const [allStates, setAllStates]: any = useState([]);
  const [allCities, setAllCites]: any = useState([]);
  const [allCityZipCodes, setAllCityZipCodes]: any = useState([]);


  const loadDeviceDetails = async (deviceId: string) => {
    props.loading.start();
    await props.getDeviceDetails({ device_id: deviceId });
    props.loading.stop();
  };

  const saveDeviceId = () => {
    if (device) {
      localStorage.setItem(STORAGE_DEVICE_LABEL, device.device_id);
    } else {
      if (location?.search) {
        let urlId = new URLSearchParams(location.search).get('id');
        if (urlId) localStorage.setItem(STORAGE_DEVICE_LABEL, urlId);
      }
    }
  };

  const initAllStates = () => {
    let allStates: any = State.getStatesOfCountry(COUNTRY_CODE);
    allStates = formatLocations(allStates);
    setAllStates(allStates);
    return allStates;
  };

  const formatLocations = (data: any) => {
    return data.map((item: any) => {
      const { name, isoCode } = item;
      return {
        ...item,
        label: name,
        value: isoCode ? isoCode : name,
      };
    });
  };

  const formatZipCodes = (zipCodes: any) => {
    return zipCodes.map((zpCode: any) => {
      const { zip } = zpCode;
      return {
        ...zpCode,
        value: zip,
        label: zip,
      };
    });
  };

  const initZipCodes = (cityData: any) => {
    const { stateCode, name } = cityData;
    let zipCodeData: any =
      stateCode && name ? ZipCodes.lookupByName(name, stateCode) : [];
    return zipCodeData.length > 0 ? formatZipCodes(zipCodeData) : zipCodeData;
  };

  const setLocationCities = (statesData: any) => {
    const { isoCode, value } = statesData;
    let tempCities: any = City.getCitiesOfState(COUNTRY_CODE, value);
    tempCities = formatLocations(tempCities);
    setAllCites([...tempCities]);
    return DEFAULT_STATE.isoCode === isoCode ? { ...DEFAULT_CITY } : {};
  };

  const prepareFields = () => {
    const _allStates: any = initAllStates();
    let tempCity: any = [],
      tempZipCodes: any = [];
    let deviceClone = { ...fieldsState };
    if (device) {
      deviceClone.device_name = device.device_name;
      deviceClone.status = device.status;
      deviceClone.device_state = device?.device_state
        ? { ...device.device_state }
        : {}; // { ...DEFAULT_STATE };
      if (deviceClone?.device_state)
        tempCity = setLocationCities(deviceClone.device_state);
      deviceClone.device_city = device?.device_city
        ? { ...device.device_city }
        : {}; // { ...tempCity };
      deviceClone.device_zipcode = device?.device_zipcode
        ? { ...device.device_zipcode }
        : {};
      if (deviceClone.device_city)
        tempZipCodes = initZipCodes(deviceClone.device_city);
      setAllCityZipCodes([...tempZipCodes]);

      setFieldsState(deviceClone);
    }
  };

  // page refresh hander
  const recoverDeviceDetails = () => {
    let storedDeviceId = localStorage.getItem(STORAGE_DEVICE_LABEL);
    if (storedDeviceId) {
      loadDeviceDetails(storedDeviceId);
    }
  };

  const onChangeState = (name: string, value: any) => {
    setFieldsState((prevState: any) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const onChangeStatus = (event: any) => {
    setFieldsState((prevState: any) => {
      return {
        ...prevState,
        status: event?.target?.checked ? 'active' : 'inactive',
      };
    });
  };



  const handleUpdateDevice = async () => {
    let vlResult: any = validate(
      fieldsState,
      config.constraints
    );

    if (vlResult) {
      setErrors(vlResult);
      let msm: string = '';
      Object.keys(vlResult).forEach((key: string) => {
        msm += vlResult[key].map((err: any) => `${err}, `);
      });
      msm = msm.substring(0, msm.length - 2);
      Notification({
        title: 'Error',
        message: msm,
        type: 'error',
      });
    } else {
      setShowUpdateAlert(true);
    }
  };

  const updateDevice = async () => {
    let fieldsClone = { ...fieldsState };
    fieldsClone.device_id = device.device_id;
    setShowUpdateAlert(false);
    props.loading.start('Updating Device');
    props.updateDevice(fieldsClone).then((resp: any) => {
      props.loading.stop();

      if (resp?.statusCode === 200) {
        Notification({
          title: 'Successfully',
          message: config?.notifications?.update?.success,
          type: 'success',
        });
      }
    });
  };


  useEffect(() => {
    prepareFields();
  }, [device]);

  useEffect(() => {
    saveDeviceId();
    window.addEventListener('beforeunload', saveDeviceId);
    recoverDeviceDetails();

    return () => {
      localStorage.removeItem(STORAGE_DEVICE_LABEL);
      window.removeEventListener('beforeunload', saveDeviceId);
    };
  }, []);

  useEffect(() => {
    if (fieldsState?.device_state?.label) {
      const { name, value } = fieldsState.device_state;
      let tempCities: any = City.getCitiesOfState(COUNTRY_CODE, value);
      tempCities = formatLocations(tempCities);
      setAllCites([...tempCities]);
    }
  }, [fieldsState?.device_state?.label]);

  useEffect(() => {
    if (fieldsState?.device_city?.label) {
      const { name, stateCode, latitude, longitude } = fieldsState.device_city;
      let tempZipCodesByCoords: any = ZipCodes.lookupByCoords(
        latitude,
        longitude,
      );
      let tempZipCodes: any = ZipCodes.lookupByName(name, stateCode);
      tempZipCodes = formatZipCodes(
        tempZipCodes && tempZipCodes.length > 0 ? tempZipCodes : [tempZipCodesByCoords],
      );
      setAllCityZipCodes([...tempZipCodes]);
    }
  }, [fieldsState?.device_city?.label]);



  return (
    <UserTemplate id="edit-system" title={config?.strings?.title} titleBackButton>
      <BreadcrumbsItem to={`/dashboard/system/edit?id=${device?.device_id}`}>
        {config?.strings?.title}
      </BreadcrumbsItem>

      <Grid container direction="column">
        <Grid item xs={12} sm={12} className="zone-details--name">
          <div>
            <h4>{fieldsState?.device_name}</h4>
          </div>
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item className="section-1">
            <div className="zone-img-wrapper">
              <div className="form-group">
              </div>
            </div>
          </Grid>
          <Grid item className="section-3">
            <div>
              <Input
                handleChange={onChangeState.bind(null, 'device_name')}
                handleChangeError={(err: any) => {
                  setErrors((prevState: any) => ({
                    ...prevState,
                    device_name: err,
                  }));
                }}
                type={config.fields.device_name.type}
                label={config.fields.device_name.label}
                value={fieldsState?.device_name}
                error={errors?.device_name}
                name={'device_name'}
              />
            </div>
          </Grid>
          <Grid item className="section-4">
            {device?.device_type === 'aircycler' && <>
              <Grid item xs={4} className="section-4--col-1">
              {allStates.length > 0 && (
                <div className="section-4--wrapper">
                  <AutosuggestInput
                    id="device-state"
                    handleChange={(newState: any) => {
                      setFieldsState((prevState: any) => {
                        return {
                          ...prevState,
                          device_state: { ...newState },
                        };
                      });
                    }}
                    data={allStates}
                    error={errors?.device_state}
                    value={fieldsState?.device_state?.name}
                    {...config.fields.device_state}
                  />
                </div>
              )}
              </Grid>
              <Grid item xs={4} className="section-4--col-2">
                <div className="section-4--wrapper">
                  <AutosuggestInput
                    id="device-city"
                    handleChange={(newState: any) => {
                      setFieldsState((prevState: any) => {
                        return {
                          ...prevState,
                          device_city: { ...newState },
                        };
                      });
                    }}
                    data={allCities}
                    error={errors?.device_city}
                    value={fieldsState?.device_city?.name}
                    {...config.fields.device_city}
                  />
                </div>
              </Grid>
              <Grid item xs={4} className="section-4--col-3">
                <div className="section-4--wrapper">
                  <AutosuggestInput
                    id="device-city--zipcode"
                    handleChange={(newState: any) => {
                      setFieldsState((prevState: any) => {
                        return {
                          ...prevState,
                          device_zipcode: { ...newState },
                        };
                      });
                    }}
                    data={allCityZipCodes}
                    error={errors?.device_zipcode}
                    value={fieldsState?.device_zipcode?.value}
                    {...config.fields.device_zipcode}
                  />
                </div>
              </Grid>
            </>}
          </Grid>
          <Grid item className="section-2">
            <div className="status-wrapper">
              <div className="form-group">
                <label className="inputLabel">
                  {config?.fields?.status?.label}
                </label>
                <Switch
                  checked={fieldsState?.status === 'active'}
                  onChange={onChangeStatus}
                  color="primary"
                  name="checkedB"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
            </div>
          </Grid>
          <Grid item className="section-5">
            <div className="col d-flex section-5--wrapper">
              <Button
                variant="primary"
                className="btn  btn-primary btn-block"
                type="button"
                disabled={isLoading}
                onClick={handleUpdateDevice}
              >
                {config?.strings?.confirm ? config.strings.confirm : 'Save'}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <SweetAlert
        info
        show={showUpdateAlert}
        showCancel
        confirmBtnText={config?.alerts?.update?.btn?.confirm}
        confirmBtnBsStyle="info"
        title={`${config?.alerts?.update?.question}`}
        onConfirm={updateDevice}
        onCancel={() => setShowUpdateAlert(false)}
        focusCancelBtn
      />
    </UserTemplate>
  );
};

function mapStateToProps(state: any) {
  let { loader, devices } = state;
  return {
    isLoading: loader.loading,
    device: devices.currentDevice,
    allSensors: devices.allSensors,
  };
}

function mapDispatchToProps(dispatch: any) {
  let { loader, devices } = dispatch;
  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    getDeviceDetails: devices.getDeviceDetails,
    updateDevice: devices.editDevice,
    getAllSensors: devices.getAllSensors,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSystem);
