import React from 'react';

interface InfoProps {
  section: any;
  additionalInfo?: any;
}

const Info: React.FC<InfoProps> = ({ section, additionalInfo }) => {
  return (
    <div className="container p-btm lead">
      <div className="row">
        <div className="col-12">{section.section}</div>
        {additionalInfo}
      </div>
    </div>
  );
};

export default Info;
