// @ts-nocheck
import React from 'react';
import {
  RouteProps as ReactDOMRouterProps,
  Route as ReactDOMRoute,
  Redirect,
} from 'react-router-dom';
import { useAuth } from '../../sessions/hooks/auth';


interface RouteProps extends ReactDOMRouterProps {
  isPrivateAdmin?: boolean;
  isAdmin?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPrivateAdmin = false,
  component: Component,
  isAdmin = false,
  ...rest
}) => {
  const { adminUser } = useAuth();


  const hasAdminRoute = (params: any) => {
    const { pathname } = params;
    let hasRoute: boolean = false;
    const pathResult: any = pathname && pathname !== '' ? pathname.split('/') : []

    if(pathResult && pathResult.length > 0){
      hasRoute = pathResult.findIndex((path: any) => path === 'admin') > -1;
    }

    return hasRoute;
  };


  const App = props => {
    const { location, isAdminRoute } = props;

    return (
      <>
        <Component />
      </>
    );
  };

  return (
    <>
      <ReactDOMRoute
        {...rest}
        render={({ location }) => {
          const adminRoute: any = hasAdminRoute(location);

          return (
            (isPrivateAdmin === !!adminUser) ? (
              <App location={location} isAdminRoute={adminRoute} />
            ) : (
              <Redirect
                to={{
                  pathname: !!adminUser ? '/admin/dashboard' : '/admin/login',
                  state: { from: location },
                }}
              />
            )
          );
        }}
      />
    </>
  );
};

export default Route;
