export default {
  adminCompaniesState: {
    mandatoryFieldsMissing:
      'Please, verify if all the required data is filled before proceed!',
    success: `Success! The company is now updated.`,
  },
  adminInstallersState: {
    mandatoryFieldsMissing:
      'Please, verify if all the required data is filled before proceed!',
  },
  adminInstallersDevState: {
    mandatoryFieldsMissing:
      'Please, verify if all the required data is filled before proceed!',
  },
  adminSectionsState: {
    payload: {
      mcDetailsErr: `manualControlDetailsAdmErr`,
      oaDetailsErr: `outsideAirDetailsAdmErr`,
    },
    err: {
      mcDetailsErr: `Something went wrong while loading manual control details. Please try again later!`,
      oaDetailsErr: `Something went wrong while loading details. Please try again later!`,
    },
  },
  adminUsersState: {
    payload: {
      devs: `devicesPerUserErr`,
      users: `usersListErr`,
      uData: `userDataErr`,
      missingFields: `mandatoryFieldsMissing`,
    },
    err: {
      devs: `Something went wrong. Please try again later!`,
      users: `Something went wrong. Please try again later!`,
      uData: `Something went wrong while getting user data. Please try again later!`,
      missingFields: `Please, check all the required data before proceed!`,
    },
  },
  adminDevicesState: {
    payload: {
      sensorsErr: `sensorsErr`,
      sensorsErrPerAcc: `sensorsErrPerAcc`,
    },
    errs: {
      sensorsErr:
        'Something went wrong while loading sensor information. Please try again later!',

      sensorsErrPerAcc:
        'Something went wrong while loading information. Please try again later!',
    },
  },
  adminDataLoggingState: {
    emptyList: `No information available for the requested interval.`,
    readyData: `Your download is ready!`,
  },
};
