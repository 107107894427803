import React from 'react';
import './styles.scss';

interface NoResultsProps {
  message: string;
  title: string;
  icon: any;
}

const NoResults: React.FC<NoResultsProps> = ({ message, title, icon }) => {
  return (
    <div className="card results-crd">
      <div className="d-flex justify-content-center pt16"> {icon}</div>
      <div className="card-body card--body">
        <h5 className="card-title dflex-centered">{title}</h5>
        <p className="card-text dflex-centered lead sm-lead">{message}</p>
      </div>
    </div>
  );
};

export default NoResults;
