export default {
    strings: {
      title: 'Manual Control',
      stLabel: '@manual-ctrl::',
    },
    formats: {
      ppm: '0,0',
      ugm3: '0,0',
      ppb: '0,0',
    },
    sliderValues: {
      pm25: {
        max: 500,
        min: 0,
      },
      co2: {
        max: 4000,
        min: 0,
      },
      tVoc: {
        max: 2000,
        min: 0,
      },
    },
  };
