import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import Header from './components/Header';
import Aside from './components/Aside';
import Footer from './components/Footer';
import Loader from './components/Loader';
import './styles.scss';


interface InstallerAuthenticatedAppProps {
  menuState?: any;
  setMenuState?: any;
}

const InstallerAuthenticatedApp: React.FC<InstallerAuthenticatedAppProps> = ({
  menuState,
  setMenuState,
  children,
}) => {
  const [fade, setFade] = useState(false);
  const mainContainer: any = useRef();

  const toggleSidebar = () => {
    setMenuState(menuState === 'open' ? 'closed' : 'open', 'open');
    checkLogoFade(menuState);
  };

  const checkLogoFade = (state: any) => {
    setFade(state === 'closed');
    if (state === 'closed') {
      setTimeout(() => {
        let ele = document.getElementById('air-logo');
        if (ele) ele.classList.remove('logo-fadeIn');
      }, 1000);
    }
  };


  return (
    <div id="main-wrapper" className="navbar-light">
      <Aside
        open={menuState}
        fade={fade}
        toggleSidebar={toggleSidebar}
        container={mainContainer}
      />
      <div id="main" ref={mainContainer}>
        <Header open={menuState} />
        <div className="content page-wrapper">
          <div className="container-fluid">{children}</div>
          <Footer />
        </div>
      </div>
      <Loader />
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    menuState: state.env.open,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    setMenuState: dispatch.env.updateState,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallerAuthenticatedApp);
