const retrieveValue = (_type: any, _ckData: any, _id: any) => {
  return _type
    ? _type === 'damper' || _type === 'fanConnect' || _type === 'centralFan'
      ? (_ckData && _ckData.hasOwnProperty(_id) ? _ckData[_id] : 0)
      : _ckData && _ckData[_type] && _ckData[_type][_id]
      ? _ckData[_type][_id]
      : 0
    : 0;
};

const listElements = (data: any) => {
  let tempEl: any = [];

  if (data.hasOwnProperty('damper')) {
    tempEl.push({
      name: `Damper Open`,
      type: 'damper',
      alias: null,
      id: 'dmst',
    });
  }
  if (data.hasOwnProperty('centralFan')) {
    tempEl.push({
      name: `Central Fan`,
      type: 'centralFan',
      alias: null,
      id: 'cfcm',
    });
  }

  return tempEl;
};

export const functions = {
  retrieveValue: retrieveValue,
  listElements: listElements,
};
