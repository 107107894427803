import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';


interface ActionsMenuProps {
  data?: any;
  parent?: any;
  setPeripheral: any;
};

const ActionsMenu: React.FC<ActionsMenuProps> = ({ data, parent, ...props }) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const onclickActions = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickSetup = (peripheral: any, event: any) => {
    props.setPeripheral({ ...peripheral });
    history.push(
      `/dashboard/devices/details/${parent?.device_id}/peripheral/setup`,
      {
        device_id: parent?.device_id,
        peripheral_id: peripheral?.dmac,
      },
    );
    handleClose();
  };

  const onClickEdit = (peripheral: any, event: any) => {
    props.setPeripheral({ ...peripheral });
    history.push(
      `/dashboard/devices/details/${parent?.device_id}/peripheral/edit`,
      {
        device_id: parent?.device_id,
        peripheral_id: peripheral?.dmac,
      },
    );
    handleClose();
  };

  return (
    <div>
      <IconButton
        id="more-button"
        aria-controls={`actions-menu-${data.device_id}`}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={onclickActions}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        id={`actions-menu-${data.device_id}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'more-button',
        }}
      >
        <MenuItem onClick={onClickEdit.bind(null, data)}>Edit</MenuItem>
        <MenuItem onClick={handleClose}>Disconnect</MenuItem>
        <MenuItem onClick={onClickSetup.bind(null, data)}>Setup</MenuItem>
      </Menu>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {};
}

function mapDispatchToProps(dispatch: any) {
  return {
    setCurrentDevice: dispatch.devices.setCurrentDevice,
    setPeripheral: dispatch.devices.setPeripheral
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionsMenu);
