import React from 'react';
import { NewConfigPageProps } from './interface';
import './styles.scss';

const NewConfigPage: React.FC<NewConfigPageProps> = ({
  newConfigData,
  config,
  children,
}) => {
  return (
    <>
      <div className="header-section">
        <h2>{newConfigData?.config_name || config?.strings?.newConfig}</h2>
      </div>
      <div className="m-container user-new-config">
        <div className="row">{children}</div>
      </div>
    </>
  );
};

export default NewConfigPage;
