import React, { useState } from 'react';
import config from './config';
import { ListGroupProps } from './interface';
import {
  GenTooltip,
  TooltipView,
  TempMessage,
} from 'business/modules/admin/common';
import { FaRegCopy } from 'react-icons/fa';
import { MdDone } from 'react-icons/md';
import './styles.scss';

const ListGroup: React.FC<ListGroupProps> = ({
  currentCompanyItem,
  relevantKeys,
  returnIcon,
  returnKey,
  isContact,
  returnValue,
  copyContent,
  classes,
}) => {
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const handleAlert = () => {
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };
  return (
    <div className="div-informations">
      <ul className="list-group" id="view-cmp-dtls">
        {Object.keys(currentCompanyItem).length > 0 &&
          Object.entries<any>(currentCompanyItem)
            .filter(([key, value]) => relevantKeys.indexOf(key) > -1)
            .map(([key, value], idx) => {
              if (key === 'contact') return null;
              return (
                <div className="container pdg break-w" key={idx}>
                  <div className="row pdg contents">
                    <span className="lead lead--smaller ftw600">
                      {returnIcon(key)} {returnKey(key)}
                    </span>

                    <div className="form-control input-alike">
                      {!isContact(key) && (
                        <span className="lead span-key-040">
                          {returnValue(value, key)}{' '}
                          {key === 'code' && (
                            <TooltipView
                              title={
                                <GenTooltip
                                  title={config.tooltip.title}
                                  textOne={config.tooltip.txt}
                                />
                              }
                            >
                              <span>
                                <button id="show-alert2" type="button">
                                  <FaRegCopy
                                    style={{
                                      color: '#000000',
                                      strokeWidth: '30',
                                      stroke: 'white',
                                    }}
                                    className="copy-to-clipboard-icon"
                                    onClick={() => {
                                      copyContent(value);
                                      handleAlert();
                                    }}
                                  />
                                </button>
                              </span>
                            </TooltipView>
                          )}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}

        {config.data.map((elem: any, index: number) => {
          return (
            <div className="container pdg break-w" key={index}>
              <div className="row pdg contents">
                <span className="lead lead--smaller ftw600">
                  {returnIcon(elem.arg)}
                  {returnKey(elem.arg2)}
                  {elem.label}
                </span>

                <div className="form-control input-alike">
                  <span className="lead span-key-040">
                    {' '}
                    {elem.label === `Phone`
                      ? currentCompanyItem?.contact?.phone
                      : currentCompanyItem?.contact?.email}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </ul>

      {showAlert && (
        <div className="copy-to-clip-temp-msg">
          <TempMessage
            id={`copy--clip--launch--view`}
            title={
              <span className="success--alert">
                Success! <MdDone style={{ marginBottom: '2px' }} />
              </span>
            }
            text={`Copied to clipboard.`}
            closeId={`close--alert--clipboard2`}
            classnames={classes}
            closeBtn="close2"
          />
        </div>
      )}
    </div>
  );
};
export default ListGroup;
