import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { AwairHome, AwairConnection, AwairDisconnect } from './pages';

const awair_path = '/awair';

const Foobot: React.FC = ({ ...props }) => {
  return (
    <>
      <BreadcrumbsItem to={`/dashboard${awair_path}`}>Awair</BreadcrumbsItem>
      <Switch>
        <Route exact path={`/dashboard${awair_path}`} component={AwairHome} />
        <Route
          exact
          path={`/dashboard${awair_path}/connection`}
          component={AwairConnection}
        />
        <Route
          exact
          path={`/dashboard${awair_path}/disconnect`}
          component={AwairDisconnect}
        />
      </Switch>
    </>
  );
};

export default Foobot;
