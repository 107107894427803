import React from 'react';
import { Grid } from '@material-ui/core';
import { FoobotLogo } from '../../../../assets';
import './styles.scss';


const FoobotLogoHeader: React.FC = () => {

  return (
      <Grid item xs={12} sm={12} className="foobot-logo">
        <div className="foobot-logo--container">
          <FoobotLogo />
        </div>
      </Grid>
  );
};

export default FoobotLogoHeader;
