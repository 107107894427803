
export default {
  string: {},
  colunms: {
    top: [
      { title: 'OutsideAir Thresholds', field: '' },
      { title: 'IndoorAir Thresholds', field: '' },
      { title: 'Current Mode', field: '' },
    ],
    bottom: [
      { title: 'Max', field: 'out_temp_max' },
      { title: 'Min', field: 'out_temp_min' },
      { title: 'Humidity', field: 'out_hum_value' },
      { title: 'Air Quality', field: 'out_aq_value' },
      { title: 'PM2.5', field: 'in_pm25_value' },
      { title: 'CO2', field: 'in_co2_value' },
      { title: 'VOC', field: 'in_voc_value' },
    ]
  },
}
