import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Button, Notification } from '../../../../../react-components';
import { UserTemplate } from 'business/modules/common';
import { FoobotLogoHeader } from '../../common';
import { ModalAlert } from './components';
import config from './config';
import './styles.scss';



const configInputs: any = config;
const FOOBOT_LABEL: string = '@air-foobot';

interface FoobotProps {
  foobotUserData?: any;
  resetUserData: any;
  loading: any;
  disconnectUser: any;
}

const FoobotDisconnect: React.FC<FoobotProps> = ({
  foobotUserData,
  ...props
}) => {
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const handleDisconnect = (event: any) => {
    setShowAlert(true);
  };

  const onDisconnect = async (event: any) => {
    setShowAlert(false);
    props.loading.start();
    await props.disconnectUser().then((resp: any) => {
      setTimeout(() => {
        if (resp.statusCode === 200) {
          props.loading.start();
          // history.push('/dashboard/foobot');
          // to prevent to return connect page when browser back button clicked
          history.replace('/dashboard/foobot', { state: { deleted: false } });
        }
      }, 1000);
    });
  };

  useEffect(() => {
    if (foobotUserData) setUsername(foobotUserData);
  }, [foobotUserData]);

  return (
    <>
    <UserTemplate
         id="foobot-disconnection"
         title={configInputs?.strings?.title}
         titleBackButton
       >
         <BreadcrumbsItem to={`/dashboard/foobot/disconnect`}>
           Foobot Connection
         </BreadcrumbsItem>
         <Grid container direction="column" alignItems="center">
           <Grid item className="section-1">
             <FoobotLogoHeader />
           </Grid>
           <Grid item className="section-2">
             <p>Hi, {username}</p>
           </Grid>
           <Grid item className="section-3">
             <p>{configInputs?.strings?.subtitle}</p>
           </Grid>
           <Grid item className="section-4">
             <div className="col d-flex pt-4 pb-2">
               <Button
                 className="btn  btn-primary btn-block"
                 type="button"
                 disabled={false}
                 onClick={handleDisconnect}
               >
                 {configInputs?.strings?.confirm}
               </Button>
             </div>
           </Grid>
         </Grid>
         <ModalAlert
           show={showAlert}
           onConfirm={onDisconnect}
           onCancel={() => {
             setShowAlert(false);
           }}
         />
       </UserTemplate>
    </>
  );
};

function mapStateToProps(state: any) {
  return {
    foobotUserData: state.foobot.userData,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { foobot, loader } = dispatch;
  return {
    resetUserData: foobot.resetFoobotUserData,
    disconnectUser: foobot.disconnect,
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FoobotDisconnect);
