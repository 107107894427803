import React, { useEffect, ButtonHTMLAttributes } from 'react';
import { Button } from 'react-bootstrap';
import cn from 'classnames';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: string;
}
const ButtonComponent: React.FC<ButtonProps> = ({
  children,
  className,
  variant,
  ...rest
}) => {
  useEffect(() => {
    return () => {};
  }, []);
  return (
    <Button className={cn(className)} variant={variant} {...rest}>
      {children}
    </Button>
  );
};

export default ButtonComponent;
