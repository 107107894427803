import React from 'react';
import { SwitchBoxProps } from './interface';
import './styles.scss';

const SwitchBox: React.FC<SwitchBoxProps> = ({ children, className }) => {
  return (
    <div className="switch-box">
      <div className="row">
        <div className={className}>{children}</div>
      </div>
    </div>
  );
};

export default SwitchBox;
