import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { EnterDataProps } from './interface';
import { timezone3 } from './config';
import { functions } from './helpers';
import { Input, SwitchBtn } from 'business/modules/admin/common';
import { Select } from '..';
import './styles.scss';

const EnterDataSystemId: React.FC<EnterDataProps> = ({
  type,
  placeholder,
  id,
  disabled,
  label,
  primaryControl,
  addSensorLabels,
  ventilationOpts,
  determineIcon,
  onChangeValues,
  loadedList,
  systemDetails,
  currentVentSetup,
  devStatus,
  primaryDefault,
  hours,
  userQuitSystemUpdate,
  originalVSet,
  name,
  value,
  setDefaultIconToShow,
  setIconToShow,
  iconToShow,
  currentSelectBox,
  setDropdownsSelections,
  signalUpdate,
  isInitialRender,
  storeSystemSelectData,
  defCurrentSelectBox,
  markPrimaryCtrAsVC,
  setTimeInHoursAndMins,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [selectType, setSelectType] = useState<string>('');
  const [userSelected, setUserSelected] = useState<boolean>(false);
  const [devType, setDevType] = useState<any>();
  const [currentSensor, setCurrentSensor] = useState<any>();
  const [sensorIsLoaded, setSensorIsLoaded] = useState<boolean>(false);
  const [isVc, setIsVC] = useState<boolean>(false);
  const [isFc, setIsFC] = useState<boolean>(false);
  const [isLocal, setIsLocal] = useState<boolean>(false);

  const handleInputError = (value: any) => {
    if (value.trim().length === 0) {
      return 'Field cannot be blank';
    } else return '';
  };

  const defineTheIconToShow = (currentIcon: any) => {
    setIconToShow(currentIcon);
  };

  useEffect(() => {
    if (devType) {
      let r: any = functions.determineIconFn(devType);

      if (currentSelectBox === 'vent_setup') {
        return;
      }

      defineTheIconToShow(r);
    }
  }, [devType]);

  useEffect(() => {
    if (addSensorLabels && addSensorLabels.length > 0) {
      let result: any = functions.determineIconFn(
        addSensorLabels[0].device_type,
      );

      if (result) {
        if (currentSelectBox === 'vent_setup') {
          return;
        }
        defineTheIconToShow(result);
      }
    }
  }, [addSensorLabels]);

  useEffect(() => {
    return () => {
      setCurrentSensor(null);
      setSensorIsLoaded(false);
    };
  }, []);

  useEffect(() => {
    if (
      systemDetails.add_sensor_id &&
      systemDetails.add_sensor_id.trim().length > 0
    ) {
      if (addSensorLabels && addSensorLabels.length > 0) {
        let sens: any = addSensorLabels.filter(
          (elem: any) => elem.id === systemDetails.add_sensor_id,
        );

        setCurrentSensor(sens[0]);
        setSensorIsLoaded(true);

        if (!devType && sens.length > 0) {
          setDevType(sens[0].device_type);

          let r: any = functions.determineIconFn(sens[0].device_type);

          // saves default  icon to replace the updated icon if user quits update
          setDefaultIconToShow(r);

          if (currentSelectBox === 'vent_setup') {
            return;
          }
          defineTheIconToShow(r);
        }
      }
    }
  }, [systemDetails, addSensorLabels]);

  useEffect(() => {
    if (userQuitSystemUpdate) {
      setUserSelected(false);
    }
  }, [userSelected, userQuitSystemUpdate]);

  useEffect(() => {
    if (selectedOption.length > 0 && selectType.length > 0) {
      setDropdownsSelections({
        [selectType]: selectedOption,
      });

      signalUpdate(false);
    }
  }, [selectedOption, selectType]);

  useEffect(() => {
    if (isInitialRender && sensorIsLoaded && currentSensor) {
      setDropdownsSelections({
        timezone: timezone3[systemDetails?.currentTime]?.label,
        vent_setup: originalVSet,
        add_sens: currentSensor.name,
        primary_control:
          primaryDefault &&
          primaryDefault?.cail &&
          primaryDefault?.cail.length > 0
            ? primaryDefault?.cail
            : primaryDefault?.cdid,
      });
    }
  }, [isInitialRender, currentSensor, sensorIsLoaded]);

  useEffect(() => {
    if (selectedOption && selectedOption.length > 0) {
      if (functions.checkTypeOfAircycler(selectedOption) === `VentConnect`) {
        setIsVC(true);
        setIsFC(false);
        setIsLocal(false);
      } else if (
        functions.checkTypeOfAircycler(selectedOption) === `FreshConnect2`
      ) {
        setIsVC(false);
        setIsFC(true);
        setIsLocal(false);
      } else if (
        selectedOption &&
        selectedOption.length > 0 &&
        selectedOption.slice(0, 11) === 'AirCyclerAQ'
      ) {
        setIsVC(false);
        setIsFC(false);
        setIsLocal(true);
      }
    }
  }, [selectedOption]);

  useEffect(() => {
    if (primaryDefault && Object.keys(primaryDefault).length > 0) {
      if (functions.checkTypeOfAircycler(primaryDefault) === 'VentConnect') {
        setIsVC(true);
        setIsFC(false);
        setIsLocal(false);
      }
      if (functions.checkTypeOfAircycler(primaryDefault) === 'FreshConnect2') {
        setIsVC(false);
        setIsFC(true);
        setIsLocal(false);
      }
    }
  }, [primaryDefault]);

  return (
    <div className="system-id-fields">
      {type === `select` ? (
        <div className="container white-c">
          <div className={`row set-spec-style1`}>
            <div
              className={`col ${`set-spec-style1`} ${hours ? `pl--hours` : ``}`}
            >
              {id === `ventilationSetup` ? (
                <Select
                  origin={`vent_setup`}
                  disabled={disabled}
                  placeholder={label}
                  data={ventilationOpts}
                  id={`inputGroupSelect02`}
                  setUserSelected={setUserSelected}
                  setSelectedOption={setSelectedOption}
                  setSelectType={setSelectType}
                  dropdownLabel={
                    <>{userSelected ? currentVentSetup : originalVSet} </>
                  }
                  storeSystemSelectData={storeSystemSelectData}
                  defCurrentSelectBox={defCurrentSelectBox}
                  setIconToShow={setIconToShow}
                  markPrimaryCtrAsVC={markPrimaryCtrAsVC}
                  setTimeInHoursAndMins={setTimeInHoursAndMins}
                />
              ) : id === `primaryControl` ? (
                primaryControl.length > 0 && (
                  <Select
                    origin={`prim_ctrl`}
                    disabled={disabled}
                    placeholder={label}
                    data={primaryControl}
                    id={`inputGroupSelect03`}
                    setUserSelected={setUserSelected}
                    setSelectedOption={setSelectedOption}
                    setSelectType={setSelectType}
                    dropdownLabel={
                      <>
                        {userSelected && isVc ? (
                          <>
                            {selectedOption}{' '}
                            <span className="smaller-label">
                              {primaryDefault.vent === `VentConnect` &&
                                `(VentConnect)`}
                            </span>
                          </>
                        ) : userSelected && !isVc ? (
                          selectedOption
                        ) : (
                          <>
                            {primaryDefault &&
                            primaryDefault?.cail &&
                            primaryDefault?.cail.length > 0
                              ? primaryDefault?.cail
                              : primaryDefault?.cdid}
                            <span className="smaller-label">
                              {primaryDefault.vent === `VentConnect` &&
                                `(VentConnect)`}

                              {primaryDefault.vent === `FreshConnect2` &&
                                `(FreshConnect2)`}
                            </span>{' '}
                          </>
                        )}
                      </>
                    }
                    storeSystemSelectData={storeSystemSelectData}
                    defCurrentSelectBox={defCurrentSelectBox}
                    setIconToShow={setIconToShow}
                    markPrimaryCtrAsVC={markPrimaryCtrAsVC}
                    setTimeInHoursAndMins={setTimeInHoursAndMins}
                  />
                )
              ) : id === `addSensor` ? (
                loadedList && addSensorLabels && addSensorLabels.length > 0 ? (
                  <Select
                    selectedOption={selectedOption}
                    origin={`add_sens`}
                    disabled={disabled}
                    placeholder={label}
                    data={addSensorLabels}
                    id={`inputGroupSelect05`}
                    dropdownLabel={
                      userSelected
                        ? selectedOption
                        : !currentSensor
                        ? `No sensor detected`
                        : currentSensor?.name
                    }
                    defaultIcon={determineIcon(currentSensor?.device_type)}
                    setSelectType={setSelectType}
                    setDevType={setDevType}
                    iconToShow={currentSensor ? iconToShow : null}
                    setUserSelected={setUserSelected}
                    setSelectedOption={setSelectedOption}
                    storeSystemSelectData={storeSystemSelectData}
                    defCurrentSelectBox={defCurrentSelectBox}
                    setIconToShow={setIconToShow}
                    markPrimaryCtrAsVC={markPrimaryCtrAsVC}
                    setTimeInHoursAndMins={setTimeInHoursAndMins}
                  />
                ) : (
                  <></>
                )
              ) : null}
            </div>
          </div>
        </div>
      ) : type === 'text' || type === 'number' ? (
        <div className="container white-c">
          <div className={`row set-spec-style1`}>
            <div
              className={`col ${`set-spec-style1`} ${hours ? `pl--hours` : ``}`}
            >
              <div className={`row styles-row1`}>
                <div className={`col-auto additional-label stronger-ft`}>
                  <span className="lead smaller-font styles-lead-span-2- stronger-ft">
                    {label}
                  </span>
                </div>
              </div>
              <div className={`row styles-row1`}>
                <div className={`col-auto additional-label stronger-ft`}></div>
              </div>

              <Input
                id={id}
                disabled={disabled}
                type={id === 'systemLocationZipCode' ? 'number' : 'text'}
                placeholder={placeholder || ''}
                onChange={onChangeValues}
                value={value}
                name={name}
              />

              <div className="render-error">{handleInputError(value)}</div>
            </div>
          </div>
        </div>
      ) : type === 'switch' ? (
        <div className="container white-c">
          <div className={`row set-spec-style1`}>
            <div
              className={`col ${`set-spec-style1`} ${hours ? `pl--hours` : ``}`}
            >
              <span className="lead smaller-font stronger-ft">{label}</span>
              <SwitchBtn
                className={disabled ? `off` : `enable-disable-admin`}
                disabled={disabled}
                id={id}
                value={devStatus}
                onChange={onChangeValues}
                name={`dev-status`}
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

function mapStateToProps(state: any) {
  const { adminDevicesState } = state;
  return {
    userQuitSystemUpdate: adminDevicesState.userQuitSystemUpdate,
    iconToShow: adminDevicesState.iconToShow,
    defaultIcon: adminDevicesState.defaultIcon,
    currentSelectBox: adminDevicesState.currentSelectBox,
    originalVSet: adminDevicesState.originalVSet,
    isInitialRender: adminDevicesState.isInitialRender,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { adminDevicesState } = dispatch;
  return {
    setDefaultIconToShow: adminDevicesState.setDefaultIconToShow,
    setIconToShow: adminDevicesState.setIconToShow,
    signalUpdate: adminDevicesState.signalUpdate,
    setDropdownsSelections: adminDevicesState.setDropdownsSelections,
    storeSystemSelectData: adminDevicesState.storeSystemSelectData,
    defCurrentSelectBox: adminDevicesState.defCurrentSelectBox,
    markPrimaryCtrAsVC: adminDevicesState.markPrimaryCtrAsVC,
    setTimeInHoursAndMins: adminDevicesState.setTimeInHoursAndMins,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EnterDataSystemId);
