export default {
  info: [
    {
      col: `col-xs-12 col-sm-12 col-md-4 information-spec-cols`,
      cardValuesCls: `lead smaller-lead-ft--1`,
      spanCls: `badge badge-info spec-info-bdg`,
      spanLabel: `Phone`,
      spanCls2: `lead smaller-lead-ft--1`,
    },
    {
      col: `col-xs-12 col-sm-12 col-md-4 information-spec-cols`,
      cardValuesCls: `lead smaller-lead-ft--1`,
      spanCls: `badge badge-info spec-info-bdg`,
      spanLabel: `Email`,
      spanCls2: `lead smaller-lead-ft--1`,
    },
  ],
};
