export default {
  strings: {
    title: 'Order list',
    title1: 'Device commands',
    noResults: 'No results found...',
    timeSlLabel: 'Change timezone',
    txt: `Return to default order by clicking here`,
  },
  labels: {
    currentTz: `Current timezone`,
  },
  timezones: [
    {
      label: 'Newfoundland w/ Daylight Sav. Time',
      timezone: 'Canada/Newfoundland',
    },
    {
      label: 'Atlantic w/ Daylight Sav. Time',
      timezone: 'Canada/Atlantic',
    },
    {
      label: 'Eastern Std w/ Daylight Sav. Time',
      timezone: 'Canada/Eastern',
    },
    {
      label: 'Central Std w/ Daylight Sav. Time',
      timezone: 'US/Central',
    },
    {
      label: 'Mountain Std w/ Daylight Sav. Time',
      timezone: 'US/Mountain',
    },
    { label: 'Mountain Std Time', timezone: 'US/Mountain' },
    {
      label: 'Pacific Std w/ Daylight Sav. Time',
      timezone: 'US/Pacific',
    },
    {
      label: 'Alaska Std w/ Daylight Sav. Time',
      timezone: 'US/Alaska',
    },
    {
      label: 'Hawaii Std w/ Daylight Sav. Time',
      timezone: 'US/Hawaii',
    },
  ],
  dataHeader: [
    {
      colClass: `col-xs-12 col-sm-12 col-md-3 the--date`,
      spanClass: `lead header-label name-span`,
      spanClass2: `lead header-label name-span1`,
      headerDesignationCol: `Command Date`,
    },
    {
      colClass: `col-xs-12 col-sm-12 col-md-6 the--events`,
      spanClass: `lead header-label`,
      spanClass2: `lead header-label mb4-1`,
      headerDesignationCol: `Command Events`,
    },
    {
      colClass: `col-xs-12 col-sm-12 col-md-2 the--status`,
      spanClass: `lead header-label dev-ct`,
      spanClass2: `lead header-label mb4-1`,
      headerDesignationCol: `Command Status`,
    },
  ],
  menu: [],
  rowClasses: {
    col1: `col-xs-12 col-sm-12 col-md-3 command--date`,
    span1: `lead lead-adm bolder-ft`,
    col2: `col-xs-12 col-sm-12 col-md-7 command--events`,
    span2: `lead lead-adm  `,
    col3: `col-xs-12 col-sm-12 col-md-2 command-status`,
    span3: `lead lead-adm location-span1`,
  },
  resettedCounter: {
    date: 0,
    events: 0,
    status: 0,
  },
  dictionary: {
    aqse: 'Air Quality Sensor Control Flag',
    ccmd: 'Connected Device Command',
    oacm: 'Outside Air Damper Relay Set Command',
    cfcm: 'Central Fan Relay Set Command',
    cidx: 'Connected Device Index Pos On MQTT Broker',
    cdst: 'Connected Device State',
    excm: 'Exhaust Relay Set Command',
    oaqe: 'Outdoor Air Quality Event Control Flag',
    g3id: 'AircyclerG3 device id',
    dmac: 'G3 Device Mac Addresses',
    uptm: 'uptime',
    fmwv: 'Firmware version',
    pcbv: 'PCB Revision',
    vpct: 'Ventilation Percentage Complete',
    thsa: 'Thermostat Call Active',
    uctr: 'User Control Flag',
    vcta: 'Ventilation Control Cycle Active',
    evrt: 'Exhaust Vent Required On Time',
    evrl: 'Exhaust Vent Required On Time Left',
    mhsv: 'Minute in Hour Start Exhaust Vent',
    cfrt: 'Central Fan Required On Time',
    cfrl: 'Central Fan Required On Time Left',
    mhsf: 'Minute in Hour Start Central Fan',
    mplf: 'Minutes in Period Left',
    evot: 'Fan Connect (wired exhaust) On Time in Minutes',
    errc: 'Error count',
    conf: 'configuration',
    fmwu: 'Firmware url',
    fmwm: 'Firmware md5',
    lfdt: 'Last foreground time',
    crdt: 'current_date_time',
    resl: 'result',
    smth: 'Supply Method',
    fffr: 'Fan Only Flow Rate CFM',
    fhfr: 'Fan Heat Flow Rate CFM',
    fcfr: 'Fan Cool Flow Rate CFM',
    exct: 'Exhaust Control Target',
    fcsp: 'Fan Connect Switch Present',
    fcst: 'Fan Connect Switch State',
    fcds: 'Fan Connect Delay State',
    fcdr: 'Fan Connect Delay Minutes Remaining',
    effr: 'Exhaust Fan Flow Rate (fan connect)',
    efdt: 'Exhaust Fan Delay Time (fan connect)',
    mner: 'Minutes Per Hour Exhaust Run (fan connect)',
    exwm: 'Exhaust Worker Mode (fan connect)',
    carq: 'Constant Air Required CFM',
    mixt: 'Mix Time',
    hroa: 'Hours of Operation Active',
    hrsv: 'Hour Start Ventilation',
    hstv: 'Hour Stop Ventilation',
    dmst: 'Damper Status',
    cfst: 'Central Fan Relay Status',
    tpmn: 'Temperature Probe Minimum',
    tpmx: 'Temperature Probe Maximum',
    ctmp: 'Current Temperature Reading',
    mxrh: 'Relative Humidity Probe Maximum Relative Humidity',
    crhr: 'Current Relative Humidity Reading',
    ddia: 'Air Duct Diameter',
    cflw: 'Current Flow Reading',
    mnsf: 'Minutes per Hour Supply Flow',
    lcap: 'Last Connected Access Point SSID',
    tmzn: 'Current Set Timezone',
    prmd: 'Pair Mode',
    pmto: 'Pair Mode Timeout',
    blto: 'Bluetooth LE Timeout',
    gail: 'G3 Device Alias',
    fdrs: 'Factory Default Reset',
    cdvc: 'Connected Device Count',
    cdvs: 'Connected Devices',
    cdid: 'Connected Device ID',
    cail: 'Connected Device Alias',
    cpri: 'Connected Device Display Priority',
    vcct: 'Vent Connect Control Target Flag',
    vcfr: 'Exhaust Fan Flow Rate (vent connect)',
    vcdt: 'Exhaust Fan Delay Time (vent connect)',
    mvcr: 'Minutes per Hour Exhaust Run (vent connect)',
    vcwm: 'Exhaust Worker Mode (vent connect)',
    slst: 'Switch Status Light State',
    fcon: 'Force On',
    frte: 'Fan runtime elapsed',
    pcst: 'Probe Connection Status',
    none: 'Probe Status',
    dfps: 'Differential Pressure',
    stmp: 'Sensor Temperature',
    faep: 'Forward Active Energy Pulses',
    raep: 'Reverse Active Energy Pulses',
    aaep: 'Absolute Active Energy Pulses',
    frep: 'Forward Reactive Energy Pulses',
    rrep: 'Reverse Reactive Energy Pulses',
    arep: 'Absolute Reactive Energy Pulses',
    estt: 'Energy Read Status',
    irms: 'RMS Current',
    vrms: 'RMS Voltage',
    mnap: 'Mean Active Power',
    mnrp: 'Mean Reactive Power',
    vfrq: 'Voltage Frequency',
    pfct: 'Power Factor',
    phas: 'Phase Angle',
    mapp: 'Mean AP Power',
    prun: 'probe run',
  },
};
