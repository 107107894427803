import { ConnectionBroker } from '../../core';


export const requestDelete = {
  state: {},
  reducers: {
    updateState(state: any, payload: any, name: string) {
      return { ...state, [name]: payload };
    },
  },
  effects: (dispatch: any) => ({
    async requestDeleteAccount(payload: any, rootState: any) {
      const self: any = this;
      const { email }: any = payload || {};

      if(email && email !== ''){
        const response = await ConnectionBroker.fetchAPI(
          'jitBE',
          'deleteAccount.request',
          { email: email },
        );

        if(response?.statusCode === 200 && !!response?.body?.id){
          return {
            code: 200,
            message: 'Delete request saved successfully',
          };
        }
      }

      return;
    },
    async confirmDeleteRequest(payload: any, rootState: any) {
      const { token } = payload;

      if (token) {
        const response = await ConnectionBroker.fetchAPI(
          'jitBE',
          'deleteAccount.confirm',
          { token },
        );

        if (response?.statusCode === 200 && response?.body?.confirmed) {
          return {
            code: 200,
            message: 'Delete request saved successfully',
          };
        }
      }

      return;
    },
  }),
};
