import React from 'react';
import moment from 'moment';
import momentTmz from 'moment-timezone';
import numeral from 'numeral';
import _ from 'lodash';

const fieldsUtils: any = {
  fieldsMapper: () => {
    return {
      check: (value: any) => {
        return value !== null && value !== undefined;
      },
      centralFan: (value: any) => {
        switch (value) {
          case 1:
            return 'ON';
          default:
            return 'OFF';
        }
      },
      damper: (value: any) => {
        switch (value) {
          case 1:
          case '1':
            return 'OPEN';
          default:
            return 'CLOSED';
        }
      },
      generic: (value: any) => {
        switch (value) {
          case 1:
          case '1':
          case true:
          case 'true':
            return 'ON';
          default:
            return 'OFF';
        }
      },
      airFlow: (value: any) => {
        return `${numeral(value).format('0')}`;
      },
      outside: (prop: string, value: any) => {
        switch (prop) {
          case 'temp':
            return `${numeral(value).format('0')}`;
          case 'hum':
            return `${numeral(value).format('0')}`;
          case 'aqi':
          default:
            return `${numeral(value).format('0')}`;
        }
      },
      indoorAir: (value: any, prop: string) => {
        switch (prop) {
          case 'pm25':
            return `${numeral(value).format('0')}`;
          case 'co2':
            return `${numeral(value).format('0')}`;
          case 'voc':
            return `${numeral(value).format('0')}`;
          default:
            return value;
        }
      },
      threshold: (value: any) => {
        return value;
      },
      mode: (mode: any) => {
        const { name, props }: any = mode || {};
        const { smth, exwm }: any = props || {};

        if (smth === 1 && exwm === 1) {
          return 'Balance Mode';
        } else if (smth === 0 && exwm === 0) {
          return 'Calculated Flow';
        } else {
          return 'Calculated Time';
        }
      },
      sys_events: (event: any) => {
        switch (event) {
          case 'indoor':
            return 'IndoorAir';
          case 'outside':
            return 'OutsideAir';
          case 'manual-control':
            return 'Manual-Control';
          default:
            return '';
        }
      },
    };
  },
  mapDataLogField: (field: string, values: any) => {
    const {
      dates,
      air_flow,
      cf_state,
      dp_state,
      exhaust,
      thsa_state,
      in_pm25,
      in_co2,
      in_voc,
      out_aqi,
      out_hum,
      out_tmp,
      mode,
      system_events,
    }: any = values;
    const mapper: any = fieldsUtils.fieldsMapper();

    switch (field) {
      case 'date':
        return mapper.check(dates?.date)
          ? moment(dates.date, 'YYYY-MM-DDThh:mm:ss').format('MM/DD/YYYY')
          : 'N/A';
      case 'time':
        return mapper.check(dates?.time) ? dates.time : 'N/A';
      case 'min_hour':
        return mapper.check(dates?.inHour) ? dates.inHour : 'N/A';
      case 'cfn_state':
        return mapper.check(cf_state?.value)
          ? mapper.centralFan(cf_state.value)
          : 'N/A';
      case 'dmp_state':
        return mapper.check(dp_state?.value)
          ? mapper.damper(dp_state.value)
          : 'N/A';
      case 'ext_state':
        return mapper.check(exhaust?.state)
          ? mapper.generic(exhaust.state)
          : 'N/A';
      case 'lgt_state':
        return mapper.check(exhaust?.light)
          ? mapper.generic(exhaust.light)
          : 'N/A';
      case 'air_flow':
        return mapper.check(air_flow?.value)
          ? mapper.airFlow(air_flow.value)
          : 'N/A';
      case 'ths_state':
        return mapper.check(thsa_state?.value)
          ? mapper.generic(thsa_state.value)
          : 'N/A';
      case 'out_temp':
        return mapper.check(out_tmp?.value)
          ? mapper.outside('temp', out_tmp.value)
          : 'N/A';
      case 'out_temp_max':
        return mapper.check(out_tmp?.threshold?.max)
          ? mapper.threshold(out_tmp.threshold.max)
          : 'N/A';
      case 'out_temp_min':
        return mapper.check(out_tmp?.threshold?.min)
          ? mapper.threshold(out_tmp.threshold.min)
          : 'N/A';
      case 'out_hum':
        return mapper.check(out_hum?.value)
          ? mapper.outside('hum', out_hum.value)
          : 'N/A';
      case 'out_hum_value':
        return mapper.check(out_hum?.threshold)
          ? mapper.threshold(out_hum.threshold)
          : 'N/A';
      case 'out_aq':
        return mapper.check(out_aqi?.value)
          ? mapper.outside('aqi', out_aqi.value)
          : 'N/A';
      case 'out_aq_value':
        return mapper.check(out_aqi?.threshold)
          ? mapper.threshold(out_aqi.threshold)
          : 'N/A';
      case 'in_pm25':
        return mapper.check(in_pm25?.value)
          ? mapper.indoorAir(in_pm25.value, 'pm25')
          : 'N/A';
      case 'in_pm25_value':
        return mapper.check(in_pm25?.threshold)
          ? mapper.threshold(in_pm25.threshold)
          : 'N/A';
      case 'in_co2':
        return mapper.check(in_co2?.value)
          ? mapper.indoorAir(in_co2.value, 'co2')
          : 'N/A';
      case 'in_co2_value':
        return mapper.check(in_co2?.threshold)
          ? mapper.threshold(in_co2.threshold)
          : 'N/A';
      case 'in_voc':
        return mapper.check(in_voc?.value)
          ? mapper.indoorAir(in_voc.value, 'voc')
          : 'N/A';
      case 'in_voc_value':
        return mapper.check(in_voc?.threshold)
          ? mapper.threshold(in_voc.threshold)
          : 'N/A';
      case 'curr_mode':
        return mapper.check(mode?.name) ? mapper.mode(mode) : 'N/A';
      case 'system_events':
        return mapper.check(system_events?.value)
          ? mapper.sys_events(system_events.value)
          : '--';
      default:
        return 'N/A';
    }
  },
  handleFieldsUnits: (field: string, value: any) => {
    switch (field) {
      case 'cfn_state':
      case 'ext_state':
      case 'lgt_state':
      case 'ths_state':
        return <span className={`fan_${value.toLowerCase()}`}></span>;
      case 'air_flow':
        return <span className="field-units">CFM</span>;
      case 'out_temp':
        return <span className="field-units">ºF</span>;
      case 'out_hum':
        return <span className="field-units">%</span>;
      case 'in_co2':
        return <span className="field-units">ppm</span>;
      case 'in_voc':
        return <span className="field-units">ppb</span>;
      case 'in_pm25':
        return (
          <span className="field-units">
            ug/m
            <sup>3</sup>
          </span>
        );
      default:
        return '';
    }
  },
  timezoneMapper: (timezones: any, timezoneId: number) => {
    const tmznClone: any =
      timezones && Array.isArray(timezones) ? _.cloneDeep(timezones) : [];

    return !isNaN(timezoneId) && timezoneId >= 0 && tmznClone[timezoneId]
      ? tmznClone[timezoneId].timezone
      : '';
  },
  mapTimezone: (tmz: string, time: any, date: any, option: string) => {
    let formatedTmz: any;

    if (tmz && tmz !== '') {
      if (date && date !== '') {
        switch (option) {
          case 'time':
            formatedTmz = momentTmz(date).tz(tmz).format('HH:mm');
            break;
          case 'date':
            formatedTmz = momentTmz(date).tz(tmz).format('MM/DD/YYYY');
            break;
          default:
            formatedTmz = date;
            break;
        }
      }
    }

    return formatedTmz &&
      formatedTmz !== '' &&
      formatedTmz.toLowerCase() !== 'invalid date'
      ? formatedTmz
      : time;
  },
};

export default fieldsUtils;
