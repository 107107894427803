import React from 'react';

const IndoorAirTooltip: React.FC = () => {
  return (
    <>
      <span style={{ fontWeight: 'bold' }}>Indoor Air Quality (IAQ)</span>
      <p style={{ marginTop: 3 }}>
        IAQ parameters and data sourced from connected air quality monitor.
      </p>
      <p>
        Set preferred parameter limits and select how your AirCycler® Connect
        System responds to IAQ events.
      </p>
    </>
  );
};

export default IndoorAirTooltip;
