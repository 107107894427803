import React from 'react';
import { ListHeaderProps } from './interface';
import { MdReorder } from 'react-icons/md';
import { GenTooltip, TooltipView } from 'business/modules/admin/common';
import { DataHeader } from './components';
import './styles.scss';

const ListHeader: React.FC<ListHeaderProps> = ({
  defineOrderType,
  handleCounterAndListOrder,
  intendedCounter,
  counter,
  id,
  incremented,
  config,
}) => {
  return (
    <div className="container main-container--data-header" id={id}>
      <div className="row d-flex" id={`${id}--row`}>
        <div className="col-1 data-header--default-btn">
          <TooltipView
            title={
              <GenTooltip
                title={config?.strings?.title}
                textOne={config?.strings?.txt}
              />
            }
          >
            <span>
              <button
                id={`${id}--order-btn`}
                onClick={() => {
                  defineOrderType(`Default`);
                  handleCounterAndListOrder(incremented, counter + 1);
                }}
              >
                <MdReorder className={`${id}--reorder-icon`} />
              </button>
            </span>
          </TooltipView>
        </div>
        <DataHeader
          handleCounterAndListOrder={handleCounterAndListOrder}
          defineOrderType={defineOrderType}
          counter={counter}
          intendedCounter={intendedCounter}
          dataHeader={config?.dataHeader}
          isAdminHeader={true}
        />
      </div>
    </div>
  );
};

export default ListHeader;
