import React, { useEffect, useState } from 'react';
import config from './config';
import validate from 'validate.js';
import { connect } from 'react-redux';
import { CreateCompanyProps } from './interface';
import { functions } from './helpers';
import { useHistory } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { GrCircleQuestion } from 'react-icons/gr';
import { Form, FormFooter, Warning } from '../../common';
import {
  Modal,
  Notification,
  TooltipView,
  GenTooltip,
  Template,
} from 'business/modules/admin/common';
import './styles.scss';

const CreateCompany: React.FC<CreateCompanyProps> = ({
  getTheCompaniesList,
  setCompanyData,
  getCompanyGeneratedCode,
  newCompany,
  setError,
  clearNewCompany,
  companyData,
  saveNewCompany,
  userCleared,
  generatedCode,
  handleModalState,
  modalMustClose,
  mandatoryFieldsMissing,
  createANewCompany,
  loading,
}) => {
  const history = useHistory();
  const [phone, setPhone] = useState('');
  const [errors, setErrors]: any = useState(null);

  const handleCreateInput = async (event: any) => {
    let name: any = event.target.name;

    let currentFields: any = { [name]: event.target.value };

    let currentConstraints: any = {
      [name]: {
        ...config.constraints[name as keyof typeof config.constraints],
      },
    };

    setCompanyData({
      ...companyData,
      [event.target.name]: event.target.value,
      phone: phone,
    });

    await validate
      .async(currentFields, currentConstraints)
      .then((res: any) => {
        setErrors((prevErrors: any) => {
          return { ...prevErrors, [name]: '' };
        });
      })
      .catch((err: any) => {
        setErrors((prevErrors: any) => {
          return { ...prevErrors, [name]: err[name] };
        });
      });
  };

  const handlePhoneCreate = async (value: any) => {
    let currentFields: any = { phone: value };
    let currentConstraints: any = {
      phone: {
        ...config.constraints['phone' as keyof typeof config.constraints],
      },
    };
    setPhone(value);

    await validate
      .async(currentFields, currentConstraints)
      .then((res: any) => {
        setErrors((prevErrors: any) => {
          return { ...prevErrors, phone: '' };
        });
      })
      .catch((err: any) => {
        setErrors((prevErrors: any) => {
          return { ...prevErrors, phone: err.phone };
        });
      });
  };

  //Handle the blue warning
  const validateIfEmptyFields = () => {
    let areThereEmptyFields: any;
    areThereEmptyFields = functions.preventEmptyData(newCompany);

    if (!areThereEmptyFields) {
      setError('');
    } else if (areThereEmptyFields) {
      setError('mandatoryFieldsMissing');
    }
  };

  const generateNewCode = async () => {
    let newCode: any = await getCompanyGeneratedCode();

    if (newCode?.statusCode === 200) {
      setCompanyData({ ...companyData, code: newCode?.body?.data?.code });
    } else {
      //TODO handle code gen error
      return;
    }
  };

  const createCompany = async () => {
    const newCompanyData: any = {
      name: newCompany?.name?.trim(),
      code: newCompany?.code,
      phone: newCompany?.phone,
      email: newCompany?.email?.trim(),
      state: newCompany?.state?.trim(),
      city: newCompany?.city?.trim(),
      zipcode: newCompany?.zipcode?.trim(),
    };

    loading.start('Creating company...');

    let createCompany: any = await createANewCompany(newCompanyData);

    clearNewCompany();

    if (createCompany?.statusCode === 200 && createCompany?.result) {
      Notification({
        title: 'Success!',
        message: config?.notifications?.create?.success,
        type: 'success',
      });
    } else {
      Notification({
        title: 'Warning!',
        message: config?.notifications?.create?.error,
        type: 'warning',
      });
    }

    clearNewCompany();

    loading.stop();

    await getTheCompaniesList();
  };

  useEffect(() => {
    const getTheCode = async () => {
      await getCompanyGeneratedCode();
    };

    getTheCode();
  }, []);

  //Save input values on changing - new company
  useEffect(() => {
    saveNewCompany({ ...companyData, phone: phone, code: generatedCode });
  }, [companyData, phone, generatedCode]);

  //Handle user quiting create
  useEffect(() => {
    if (userCleared) {
      setCompanyData({
        name: '',
        email: '',
        phone: '',
        state: '',
        zipcode: '',
        city: '',
        code: generatedCode,
      });

      setPhone('');
      setErrors();
    }
  }, [userCleared]);

  useEffect(() => {
    validateIfEmptyFields();
  }, [newCompany, generatedCode]);

  return (
    <Template
      title={config?.companyCreate?.title}
      id="dashboard-create-company"
      goBack={() => {
        history.push('/admin/dashboard/companies-list');
      }}
    >
      <BreadcrumbsItem to={config?.companyCreate?.breadcrumbItem}>
        {config.strings.breadcrumbCreate}
      </BreadcrumbsItem>

      <div
        className={config.companyCreate.class}
        id="create-new-company--screen"
      >
        <h4 className="centered">{config.companyCreate.mainTitle} </h4>
        <p className="lead centered card-top-ft">
          {config.companyCreate.pText}
        </p>
        <hr className="my-4" />
        <Form
          error={errors}
          phone={phone}
          disabledGenerate={false}
          fields={config.fields}
          origin={`create`}
          data={companyData}
          generateNewCode={generateNewCode}
          config={config}
          generatedCode={generatedCode}
          getUserInput={(event: any) => handleCreateInput(event)}
          handlePhone={handlePhoneCreate}
          genLabel={config.genLabel}
          tooltip={
            <TooltipView
              title={
                <GenTooltip
                  title={config?.strings?.tooltipTitle}
                  textOne={config?.strings?.tooltipTxt}
                />
              }
            >
              <span>
                <GrCircleQuestion className="click-here-tooltip" />
              </span>
            </TooltipView>
          }
        />
        <FormFooter
          dataTarget={`#save-company`}
          emptyFields={false}
          onClick={clearNewCompany}
          onClickValidate={() => validateIfEmptyFields()}
          disabled={mandatoryFieldsMissing}
        />

        <Warning
          currentlyEmptyFieldsEdition={mandatoryFieldsMissing}
          warning={config.strings.warning}
          id={`warning-create`}
        />

        <Modal
          config={config}
          bodyClass={functions.handleOpts(`create__comp`, 'bodyClass')}
          className={`small-button ok`}
          onClick={() => {
            createCompany();
          }}
          title={functions.handleOpts(`create__comp`, `title`)}
          id={`save-company`}
          icon={functions.handleOpts(`create__comp`, `icon`)}
          body={functions.handleOpts(`create__comp`, 'bodies')}
          modalMustClose={modalMustClose}
          handleModalState={handleModalState}
          label={config.labels[`create__comp` as keyof typeof config.labels]}
          hasConfirmBtn={true}
          sureToProceed={config.modalStrings.sureToProceed}
        />
      </div>
    </Template>
  );
};

function mapStateToProps(state: any) {
  const { adminCompaniesState } = state;

  return {
    generatedCode: adminCompaniesState.generatedCode,
    companyData: adminCompaniesState.companyData,
    mandatoryFieldsMissing: adminCompaniesState.mandatoryFieldsMissing,
    modalMustClose: adminCompaniesState.modalMustClose,
    newCompany: adminCompaniesState.newCompany,
    userCleared: adminCompaniesState.userCleared,
    currentCompanyToEdit: adminCompaniesState.currentCompanyToEdit,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { adminCompaniesState, loader } = dispatch;

  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    clearNewCompany: adminCompaniesState.clearNewCompany,
    setError: adminCompaniesState.setError,
    getCompanyGeneratedCode: adminCompaniesState.getCompanyGeneratedCode,
    resetToUpdatedState: adminCompaniesState.resetToUpdatedState,
    setCompanyData: adminCompaniesState.setCompanyData,
    saveNewCompany: adminCompaniesState.saveNewCompany,
    createANewCompany: adminCompaniesState.createANewCompany,
    handleModalState: adminCompaniesState.handleModalState,
    getTheCompaniesList: adminCompaniesState.getTheCompaniesList,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateCompany);
