import React from 'react';
import { LocationButtonsProps } from './interface';
import { Button } from 'react-bootstrap';

const LocationButtons: React.FC<LocationButtonsProps> = ({ ...props }) => {
  const ButtonsSwitch = ({
    mode,
    field,
    onCancelEdit,
    onSaveEdit,
    handleEditClick,
  }: any) => {
    switch (mode) {
      case 'edit':
        return (
          <>
            <Button
              className="edit-firm--btn-cancel"
              onClick={onCancelEdit.bind(null, field)}
            >
              Cancel
            </Button>
            <Button
              onClick={onSaveEdit.bind(null, field)}
              data-target={props.dataTarget}
              data-toggle={props.dataToggle}
            >
              Save
            </Button>
          </>
        );
      case 'normal':
      default:
        return (
          <Button onClick={handleEditClick.bind(null, field)}>Edit</Button>
        );
    }
  };

  return <ButtonsSwitch {...props} />;
};

export default LocationButtons;
