

export const filterSensorDevices = (_devices: any) => {
  let updatedDevices: any = [..._devices].filter((device: any) => {
    const { device_type } = device;

    switch (device_type) {
      case 'foobot':
      case 'airthings':
      case 'awair':
      case 'awair-element':
      case 'awair-omni':
        return true;
      case 'aircycler-local':
      default:
        return false;
    }
  });

  return updatedDevices;
};



export const filterG3Devices = (_devices: any) => {
  let g3Devices: any = [..._devices].filter((device: any) => {
    const { device_type } = device;

    switch (device_type) {
      case 'aircycler':
        return true;
      default:
        return false;
    }
  });

  return g3Devices;
};


export const hasLocalSensors = (allSensors: any) => {
  let hasLocal: boolean = false;

  if(allSensors && allSensors.length > 0){
    const localIndex: number = allSensors.findIndex(({details}: any) => {
      const { name, uuid, status }: any = details || {};

      if(name !== '' || status !== ''){
        return name?.toLowerCase() === 'local' || status?.includes('local');
      }

      return false;
    });

    hasLocal = localIndex > -1;
  }

  return hasLocal;
};
