import React from 'react';
import './styles.scss';


const PrivacyPolicy: React.FC = () => {


  return (
    <div id="aircycler-privacy" className="container">
      <header>
        <h2>AirCycler Privacy Policy</h2>
      </header>
      <p></p>

      <article>
        <section className="pp-top">
          <h3>Terms of Use</h3>
          <p>
            These Terms of Use (the “Agreement”) apply to the mobile app (together with the Software, as defined below, the “App”) operated by Lipidex Corporation (“we,” “our” or “us”).
            The Agreement is a legally binding contract between us and any person who elects to use the App (“you” or “your”).
            By using the App, you agree to be bound by all of the terms and conditions of this Agreement as such terms and conditions may be modified by us from time-to-time.
            <strong> If you do not agree to all of the terms and conditions of the Agreement, do not use the App.</strong>
          </p>
          <p>
            <strong>Read this Agreement carefully.</strong>  It contains important disclaimers and limitations, including the following:
            <strong>  THE APP IS NOT INTENDED TO PROVIDE HEALTH AND SAFETY INFORMATION AND YOU SHOULD NOT TAKE ANY ACTIONS, OR FAIL TO TAKE ANY ACTIONS,
            BASED ONLY ON THE INFORMATION PROVIDED THROUGH THE APP.
            THE APP DOES NOT PROVIDE EMERGENCY INFORMATION TO YOU OR US.</strong>
          </p>
          <p>
            <strong>
              Under Paragraph 15 below, any disputes or claims related to this Agreement will be resolved by arbitration to the extent permitted by law.
              The Agreement contains a waiver of any rights to sue in court, including through a class action.
              You may opt-out of these provisions by following the instructions in Paragraph 15.
            </strong>
          </p>
        </section>
        <p></p>
        <section className="pp-section">
          <h3>1. Age Restriction</h3>
          <p className="pp-section-collect">
            The App is intended for persons who are at least 18 or older and are of legal age to form a binding agreement.
            If you are under 18, or not of age to form a legally binding agreement, you may not access or use the App.
          </p>
        </section>
        <p></p>

        <section className="pp-info">
          <h3>2. Privacy Policy</h3>
          <p>
            All information provided and collected through the App is subject to our <a href="https://cdn.shopify.com/s/files/1/0221/7316/files/AirCycler_Mobile_App_Privacy_Policy_Final.pdf" className="pp-privacy-policy">Privacy Policy</a>,
            which is hereby incorporated into and made part of this Agreement.
          </p>
        </section>
        <p></p>

        <section className="pp-personal-info">
          <h3>3. Passwords and Security</h3>
          <p>
            You must create an account to use the App.  When you register for an account, you agree to submit accurate and complete information and to update such information as required from time-to-time.
            Each person may have only one account.  If we suspect that your information is inaccurate, duplicate or incomplete, we may suspend or terminate your access to the App.
            You will be asked to select a personal, non-transferable password when you create an account.  If you submit a request for a lost or forgotten username or password,
            you may be asked to provide information to confirm your identity.  You are solely responsible for all activities that occur under your passwordprotected account and for ensuring the
            protection of your account information.  If you require a username or password reset, your information may be permanently deleted.  You agree to notify us immediately of any
            unauthorized use of your password or account or any other breach of security that is known or suspected by you.
          </p>
        </section>
        <p></p>

        <section className="pp-tec">
          <h3>4. Limited License</h3>
          <p>
            Subject to the terms and conditions of this Agreement, we grant you a non-exclusive, nontransferable, non-sub-licensable, term-based,
            revocable, limited license to use the software embedded in the App (“Software”) for the sole and non-commercial purpose of using the
            products listed at  <span>https://www.aircycler.com/connect</span> which are compatible with the App (“Products”).
          </p>
        </section>
        <p></p>

        <section className="pp-minors">
          <h3>5. System</h3>
          <p>
            With respect to the App and related Software, source code, platforms, servers and interfaces, whether owned by us or third parties (collectively, “System”),
            you must not: (a) copy, redistribute, publish, reverse engineer, decompile, disassemble, modify, alter, revise, translate, creative derivative works,
            or make any unauthorized attempt to access or use; (b) sell, assign, sublicense, transfer, distribute, lease, rent or grant a security interest; (c) reproduce,
            duplicate, copy, sell, resell, or exploit for commercial purposes; or (d) access or use in a manner prohibited by applicable laws, directives, or regulations or this Agreement.
          </p>
        </section>
        <p></p>

        <section className="pp-rights">
          <h3>6. Automatic System Updates</h3>
          <p>
            You acknowledge and agree that the System may, from time-to-time and at our discretion, be automatically updated without prior notice to you or obtaining any additional consents.
            You also agree to install and/or launch any updates promptly upon any notice from us sent in any manner, including through the System,
            that an update is available if it is not automatically installed or launched on your device.  This Agreement will apply to the App including all
            updates that are automatically installed and launched and all updates that we require you to install and launch.  Updates may include improvements, fixes,
            security patches, modifications, additional features, deletion of features and more.  If you disagree with an update, your only remedy is to stop using the App.
            Your continued use of the App indicates you agree to all updates.
          </p>
        </section>
        <p></p>

        <section className="pp-us-policy">
          <h3>7.  Content</h3>
          <p>
            All content and other materials available at or through the App, including without limitation publications, articles, trademarks, service marks, trade names, images,
            audio, text, videos, software, designs and the “look and feel” of the App (collectively, “Content”) are owned or licensed by us and/or our affiliates and are protected by copyright,
            trademark, and other intellectual property laws.  You may access Content only for your own informational and non-commercial purposes, provided that you comply with the terms of
            this Agreement with respect to such Content.  Nothing herein creates a license for you to use Content in any other manner.  The Content may not be up-to-date at all times.
            You may not use any Content for commercial purposes or reproduce, republish, distribute, display, perform, modify, transmit, or sell Content.
            Without limiting any other disclaimers herein, we expressly disclaim any liability or responsibility arising out of actions or inactions you or others take based on the Content.
            All rights not expressly granted to you in this Agreement are reserved by us and/or our licensors.
          </p>
        </section>
        <p></p>

        <section className="pp-retention">
          <h3>8. Submissions</h3>
          <p>
            We do not accept any unsolicited ideas including without limitation images, suggestions about promotion of the App, additions to our services, or changes in methods of doing business.
            If, notwithstanding this policy, you send us any ideas, suggestions, images, drawings, graphics, innovations, concepts, recommendations, or similar materials (“Submissions”),
            you agree that the Submissions are not confidential and we assume no obligation, expressed or implied, by considering them.  You hereby assign such Submissions to us
            without compensation (or the expectation of compensation) and agree that we may disclose, reproduce, republish, modify, distribute, display, perform, transmit, sell,
            or otherwise use your Submissions for commercial or non-commercial purposes with no compensation to you.  For any Submissions that cannot be legally assigned to us,
            you hereby grant us an unrestricted, perpetual, royalty-free, irrevocable and worldwide license to disclose, reproduce, republish, modify, distribute, display, perform,
            transmit, sell, or otherwise use your Submissions for commercial or non-commercial purposes in any manner and medium with no compensation to you.
          </p>
        </section>
        <p></p>
        <section className="pp-retention">
          <h3>9. Prohibited Conduct</h3>
          <p>
            You agree to abide by all applicable laws and not to (a) upload, transmit, post, email, or otherwise make available to the System any material in any format that (i) is false, inaccurate,
            misleading, fraudulent, unlawful, harmful, threatening, tortious, defamatory, vulgar, invasive of another's privacy, or libelous; (ii) infringes any third party's intellectual
            property or other proprietary right or rights of publicity or privacy; or (iii) contains viruses, worms, Trojan horses, time bombs, corrupted files, or any other software or
            programs designed to interrupt, interfere, intercept, expropriate, destroy or limit the functionality of the System or any computer software or hardware or equipment associated with the System;
            (b) alter, remove, obscure or falsify any attributions, trademark, copyright or other proprietary designations of origin or source of the System or Content; (c) impersonate any person or
            entity; (d) attempt, through any means, to gain unauthorized access to the System or Content in a way not intended by us or for any unlawful purpose, or another person’s account or
            information on or through the System; (e) use any robot, scraper, spider, or any other automatic device or manual process to monitor or copy the System or any Content unless we have
            authorized such use; (f) take any action that imposes an unreasonable or disproportionately large load on the System; (g) take any action that creates liability for us or causes us
            to lose any of the services of our business partners, vendors or suppliers; (h) take any action that would cause us to violate any applicable law, statute, ordinance or regulation,
            or that violates the Agreement; (i) attempt to tamper with, alter, disable, hinder, by-pass, override, or circumvent any security, reliability, integrity, restriction or requirement of
            the System; (j) interfere with or disrupt the integrity or performance of the System or the data contained therein; or (k) access the System or Content in order to build a competitive
            product or service, copy any features, functions or graphics of the System or Content or monitor the availability and/or functionality of the System or Content for any benchmarking or
            competitive purposes
          </p>
        </section>
        <p></p>
        <section className="pp-retention">
          <h3>10. Other Applications and Websites </h3>
          <p>
            The App may contain links or other options to connect to third party applications and/or websites that are not owned or operated by us (collectively, “Linked Sites”).
            We do not have any control over Linked Sites and are not responsible for any information, functionality, products, services or content of such Linked Sites.
            Your use of the Linked Sites is subject to the privacy policies and terms of use of the Linked Sites and you should read and understand them before using any Linked Sites.
            Our use of Linked Sites does not mean that we sponsor or endorse the Linked Sites or any content available thereon, or that we have entered into a relationship
            with the entities owning the Linked Sites.  You access and use Linked Sites at your own risk.  We disclaim any responsibility for any harm resulting
            from your use or attempted use of Linked Sites.
          </p>
        </section>
        <p></p>
        <section className="pp-retention">
          <h3>11. Use Disclaimer</h3>
          <p>
            The App may provide information about air quality data, ventilation operations, energy consumption and other technical data that is intended to keep you
            informed about the use and operation of connected Products, within the limits of the available data and technology.
            <strong> THE APP IS NOT INTENDED TO PROVIDE HEALTH AND SAFETY INFORMATION AND YOU SHOULD NOT TAKE ANY ACTIONS, OR FAIL TO TAKE ANY ACTIONS,
            BASED ONLY ON THE INFORMATION PROVIDED THROUGH THE APP.</strong>
          </p>
          <p>
            Without limiting the foregoing, air quality data accuracy may vary depending on various factors, such as sensor location, atmospheric conditions,
            interference and other conditions where the Products are located.  We make no representations, and do not guarantee, that the App or its information
            will result in lower energy use or consumption costs, accurate air quality data, or more efficient or reliable operations.
            <strong> DO NOT TAKE ANY ACTIONS, OR FAIL TO TAKE ANY ACTIONS, BASED ONLY ON THE AIR QUALITY DATA AVAILABLE THROUGH THE APP.</strong>
          </p>
          <p>
            <strong>THE APP IS NOT INTENDED, AND SHOULD NOT BE RELIED UPON, TO PROVIDE ANY EMERGENCY NOTIFICATIONS TO YOU OR US.</strong>
             We do not monitor the App or its information for any emergency situations
          </p>
        </section>
        <p></p>

        <section className="pp-retention">
          <h3>12. Disclaimer</h3>
          <p>
            YOU AGREE THAT USE OF THE APP, SYSTEM AND CONTENT IS AT YOUR SOLE RISK.  THE APP, SYSTEM AND CONTENT ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS.
            TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION,
            ANY WARRANTY OF MERCHANTABILITY, TITLE, QUIET ENJOYMENT, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT.
            NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM US OR AT OR THROUGH THE APP SHALL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.
          </p>
          <p>
            TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE MAKE NO WARRANTY THAT THE APP, SYSTEM AND CONTENT WILL BE ACCURATE, COMPLETE, CURRENT OR TIMELY,
            UNINTERRUPTED, SECURE, OR ERROR FREE.  WE DO NOT WARRANT THAT THE APP, SYSTEM AND CONTENT ARE FREE OF
            DEFECTS, VIRUSES, MALFUNCTIONS, OR HARMFUL COMPONENTS THAT COULD DAMAGE OR ALLOW UNAUTHORIZED ACCESS TO YOUR DATA.
          </p>
        </section>
        <p></p>
        <section className="pp-retention">
          <h3>13. Limitation of Liability</h3>
          <p>
            TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, NEITHER WE NOR OUR AFFILIATES OR OUR RESPECTIVE OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES, AGENTS,
            CONTRACTORS OR REPRESENTATIVES (OR THEIR RESPECTIVE SUCCESSORS AND ASSIGNS) SHALL BE LIABLE IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE FOR ANY DIRECT, INDIRECT,
            INCIDENTAL, SPECIAL, PUNITIVE OR CONSEQUENTIAL DAMAGES RESULTING FROM THE USE OR ATTEMPTED USE OR INABILITY TO USE THE APP, SYSTEM OR CONTENT, INCLUDING WITHOUT LIMITATION LOST
            REVENUE, LOST OR STOLEN DATA, LOST OPPORTUNITIES, DAMAGED DEVICES OR OTHER INTANGIBLES, EVEN IF WE OR YOU HAVE BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            IF ANY PART OF THIS LIMITATION OF LIABILITY IS FOUND TO BE INVALID OR UNENFORCEABLE FOR ANY REASON OR IF WE ARE OTHERWISE FOUND TO BE LIABLE TO YOU IN ANY MANNER,
            THEN YOU AGREE THAT OUR TOTAL LIABILITY TO YOU FOR DAMAGES, REGARDLESS OF THE FORM OF ACTION, SHALL NOT EXCEED $1 IN THE AGGREGATE FOR ALL CLAIMS.
            THE FOREGOING LIMITATIONS WILL APPLY EVEN IF THE ABOVE STATED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.  SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR
            LIMITATION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES.  THEREFORE, THE EXCLUSIONS SET FORTH ABOVE MAY NOT APPLY TO YOU.
          </p>
        </section>
        <p></p>
        <section className="pp-retention">
          <h3>14. Indemnification</h3>
          <p>
            You agree to indemnify, hold harmless, and release us and our affiliates and our respective officers, directors, shareholders, employees, agents, contractors,
            and representatives (and their respective successors and assigns) from and against any and all claims, demands, liabilities, damages, costs and expenses,
            including, but not limited to, attorneys’ fees and costs, arising from or related to: (i) your access, use, attempted use, inability to use or misuse of the App, System or Content;
            (ii) your violation of any of terms of this Agreement or any applicable law; (iii) your violation of any third party right, including without
            limitation any copyright, property or privacy right; and (iv) any claim that your use of the App, System or Content caused damage to a third party.
          </p>
        </section>
        <p></p>
        <section className="pp-retention">
          <h3>15. Arbitration and Disputes</h3>
          <p>
            Any dispute, claim, or controversy arising out of or relating to this Agreement, including the determination of the scope or applicability of this Agreement to arbitrate,
            shall be determined by individual arbitration in Massachusetts before a single neutral arbitrator.  The arbitration shall be administered by
            JAMS pursuant to its Comprehensive Arbitration Rules and Procedures.  Any appeal shall be heard and decided by a panel of three neutral arbitrators.
            All arbitrators shall be retired judges or justices of any Massachusetts state or federal court, and shall in their substantive rulings (as opposed to procedural or
            discovery-related rulings that are otherwise governed by the
            JAMS Comprehensive Arbitration Rules and Procedures), apply the laws of Massachusetts without giving effect to any choice or conflict of law provision or rules that would cause
            the application of the laws of any jurisdiction other than Massachusetts.  The award of the arbitrator(s) shall be binding and final on all parties.
            The arbitrator(s) shall award to the prevailing party, if any, the costs and attorneys' fees reasonably incurred by the prevailing party in connection with the arbitration.
            If the arbitrator(s) determine a party to be the prevailing party under circumstances where the prevailing party won on some but not all of the claims and counterclaims,
            the arbitrator(s) may award the prevailing party an appropriate percentage of the costs and attorneys' fees reasonably incurred by the prevailing party in connection with the arbitration.  Judgment on the award rendered may be entered in any court having jurisdiction.  The arbitrators may not award any punitive, incidental, indirect, special, or consequential damages, including, but not limited to, damages for lost profits.
          </p>
          <p>
            Any dispute resolution proceeding arising out of or relating to this Agreement, including without limitation arbitration,
            will be conducted only on an individual basis and not in a class or representative action on behalf of others.
            There is no right for any dispute hereunder to be brought or heard as a class arbitration, class action, or private attorney general action or for the consolidation of arbitrations.
          </p>
          <p>
            For any matters which are not subject to arbitration as set forth in this Agreement, you hereby expressly consent to exclusive jurisdiction and venue in the courts
            located in Plymouth County, which shall apply the laws of Massachusetts without giving effect to any choice or conflict of law provision or rules that would cause
            the application of the laws of any jurisdiction other than Massachusetts.
          </p>
          <p>
            To the extent permitted by applicable law, any claims asserted by you in connection with the App, System and Content must be asserted in writing to us within one (1)
            year of the date such claim first arose, or such claim is forever waived by you.
          </p>
          <p>
            You may opt-out of and not be bound by the arbitration and class action waivers set forth above by sending written notice to support@aircycler.com within 30 days of the date you first access the App.
            If you timely opt-out, we will also not be bound by these provisions.  If you do not timely opt-out, these provisions will apply to you and us.
          </p>

        </section>
        <p></p>
        <section className="pp-retention">
          <h3>16.  Export Compliance</h3>
          <p>
            You are solely responsible for complying with any export laws, regulations, orders, or other restrictions, which may be imposed from time-to-time by the United States
            government and any other governments with jurisdiction.  You agree not to export or re-export the Software, App or information pertaining thereto directly or indirectly to
            any country for which a U.S. government agency requires an export license or other governmental approval without first obtaining express authorization to do so from us,
            and all such necessary licenses and approvals.
          </p>
        </section>
        <p></p>
        <section className="pp-retention">
          <h3>17.  Miscellaneous</h3>
          <p>
            You agree that we may send to you in electronic form any notices or other communications regarding the App and such electronic form will satisfy any legal
            requirements with respect to communications or notice.
          </p>
          <p>
            The App is controlled and operated by us from our offices in the United States and is intended to operate only in the United States.
            We make no representation that that the App is available in, appropriate for or complies with laws outside the United States.
            If you access the App outside the United States, you do so at your own risk and are responsible for complying with your local laws and regulations; provided,
            however, that you agree that your access to the App does not subject us to any laws, regulations or jurisdictions of any area outside the United States.
          </p>
          <p>
            You may not assign this Agreement or your obligations hereunder to any other party, and any attempt to do so will be void and without effect.
            We may assign this Agreement without your consent or notice to you.
          </p>
          <p>
            If any part of this Agreement shall be held or declared to be invalid or unenforceable for any reason by any court of competent jurisdiction,
            such provision shall be ineffective but shall not affect any other part of this Agreement, and in such event, such provision shall be changed and interpreted so as to
            best accomplish the objectives of such unenforceable or invalid provision within the limits of applicable law or applicable court decisions.
          </p>
          <p>
            Our failure to partially or fully exercise any rights or our waiver of any breach of this Agreement shall not prevent our subsequent exercise of such right or be deemed a
            waiver of any subsequent breach of the same or any other term of this Agreement.
            Our rights and remedies under this Agreement and any other applicable agreement between us and you shall be cumulative, and the exercise of any such right or
            remedy shall not limit our right to exercise any other right or remedy.
          </p>
          <p>
            We may at any time, without notice and for any reason in our sole discretion, modify or discontinue the App, delete Content, or terminate or restrict your access to the App.
            We have not responsibility to update or maintain the Software or App.  In addition, this Agreement may be changed at any time.
            We will notify you of such changes by posting them on the App or through other methods.  You should check the App for such changes frequently.
            Your continued access of the App after such changes have been posted conclusively demonstrates your acceptance of those changes
          </p>
          <p>
            Questions regarding this Agreement should be directed to support@aircycler.com or write to us at the address below:
          </p>
          <div className="contact-us-wrapper">
            <span>AirCycler</span><br/>
            <span>Customer Support</span><br/>
            <span>411 Plain Street</span><br/>
            <span>Marshfield, MA 02050</span><br/>
          </div>
        </section>
        <p></p>

      </article>

    </div>
  );
};

export default PrivacyPolicy;
