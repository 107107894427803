import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Grid } from '@material-ui/core';
import SweetAlert from 'react-bootstrap-sweetalert';
import { State, City } from 'country-state-city';
import ZipCodes from 'zipcodes';
import validate from 'validate.js';
import { InstallerTemplate } from 'business/modules/common';
import {
  InputImage,
  Input,
  Button,
  Notification,
  AutosuggestInput,
} from 'react-components';
import config from './config';
import './styles.scss';


interface LoadingProps {
  start: any;
  stop: any;
};

interface EditZoneProps {
  zone?: any;
  isLoading?: boolean;
  loading: LoadingProps;
  getZoneDetails: any;
  clearStore: any;
  updateZone: any;
  location: any
};

const STORAGE_ZONE_LABEL = '@air-editZone:';
const COUNTRY_CODE = 'US';


const EditZone: React.FC<EditZoneProps> = ({ zone, isLoading, location, ...props }) => {
  const [fieldsState, setFieldsState]: any = useState(null);
  const [errors, setErrors]: any = useState(null);
  const [showUpdateAlert, setShowUpdateAlert]: any = useState(false);

  const [allStates, setAllStates]: any = useState([]);
  const [allCities, setAllCites]: any = useState([]);
  const [allCityZipCodes, setAllCityZipCodes]: any = useState([]);


  // page refresh hander
  const saveZoneId = (event: any) => {
    if (zone) {
      localStorage.setItem(STORAGE_ZONE_LABEL, zone.category_id);
    } else {
      if (location?.search) {
        let urlId = new URLSearchParams(location.search).get('id');
        if (urlId) localStorage.setItem(STORAGE_ZONE_LABEL, urlId);
      }
    }
  };

  const loadZoneDetails = async (zoneId: string) => {
    props.loading.start();
    await props.getZoneDetails({ id: zoneId });
    props.loading.stop();
  };

  const initAllStates = () => {
    let allStates: any = State.getStatesOfCountry(COUNTRY_CODE);
    allStates = formatLocations(allStates);
    setAllStates(allStates);
    return allStates;
  };

  const formatLocations = (data: any) => {
    return data.map((item: any) => {
      const { name, isoCode } = item;
      return {
        ...item,
        label: name,
        value: isoCode ? isoCode : name,
      };
    });
  };

  const formatZipCodes = (zipCodes: any) => {
    return zipCodes.map((zpCode: any) => {
      const { zip } = zpCode;
      return {
        ...zpCode,
        value: zip,
        label: zip,
      };
    });
  };

  // page refresh hander
  const recoverZoneDetails = () => {
    let storedZoneId = localStorage.getItem(STORAGE_ZONE_LABEL);
    if (storedZoneId) {
      loadZoneDetails(storedZoneId);
    }
  };

  const onChangeState = (prop: string, value: any) => {
    setFieldsState((prevState: any) => {
      return {
        ...prevState,
        [prop]: value,
      };
    });
  };

  const handleUpdateZone = async () => {
    let vlResult: any = await validate(fieldsState, config.constraints);
    if (vlResult) {
      setErrors(vlResult);
      let msm: string = '';
      Object.keys(vlResult).forEach((key: string) => {
        msm += vlResult[key].map((err: any) => `${err}, `);
      });
      msm = msm.substring(0, msm.length - 2);
      Notification({
        title: 'Error',
        message: msm,
        type: 'error',
      });
    } else {
      setShowUpdateAlert(true);
    }
  };


  const updateZone = async () => {
    setShowUpdateAlert(false);
    let fieldsClone = { ...fieldsState };
    props.loading.start('Updating System');
    props.updateZone({ ...fieldsClone }).then((resp: any) => {
      props.loading.stop();
      if (resp?.statusCode === 200) {
        Notification({
          title: 'Successfully',
          message: config?.notifications?.update?.msm,
          type: 'success',
        });
      }
    });
  };

  useEffect(() => {
    window.addEventListener('beforeunload', saveZoneId);
    initAllStates();
    // page refresh hander
    if (!zone) {
      recoverZoneDetails();
    }
    return () => {
      props.clearStore();
      localStorage.removeItem(STORAGE_ZONE_LABEL);
      window.removeEventListener('beforeunload', saveZoneId);
    };
  }, []);


  useEffect(() => {
    if (fieldsState?.system_state?.label) {
      const { name, value } = fieldsState.system_state;
      let tempCities: any = City.getCitiesOfState(COUNTRY_CODE, value);
      tempCities = formatLocations(tempCities);
      setAllCites([...tempCities]);
    }
}, [fieldsState?.system_state?.label]);

  useEffect(() => {
    if (fieldsState?.system_city?.label) {
      const { name, stateCode, latitude, longitude } = fieldsState.system_city;
      let tempZipCodesByCoords: any = ZipCodes.lookupByCoords(
        latitude,
        longitude,
      );
      let tempZipCodes: any = ZipCodes.lookupByName(name, stateCode);
      tempZipCodes = formatZipCodes(
        tempZipCodes && tempZipCodes.length > 0 ? tempZipCodes : [tempZipCodesByCoords],
      );
      setAllCityZipCodes([...tempZipCodes]);
    }
}, [fieldsState?.system_city?.label]);


  useEffect(() => {
    let defClone: any = { ...config.fieldsState };
    if (zone) {
      Object.keys(defClone).forEach((key: string) => {
        if (zone[key]) defClone[key] = zone[key];
        // if (key === 'avatar') defClone[key] += `?${Date.now()}`;
      });
    }
    setFieldsState(defClone);
  }, [zone]);

  return (
    <InstallerTemplate id="edit-zone" title={config?.strings?.title} titleBackButton>
      <BreadcrumbsItem
        to={`/dashboard/systems/edit?id=${zone?.category_id}`}
      >
        {config?.strings?.title}
      </BreadcrumbsItem>
      <Grid container direction="column">
        <Grid item xs={12} sm={12} className="zone-details--name">
          <div>
            <h4>{fieldsState?.name}</h4>
          </div>
        </Grid>

        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item className="section-1">

          </Grid>
          <Grid item className="section-2">
            <div>
              <Input
                handleChange={onChangeState.bind(null, 'name')}
                handleChangeError={(err: any) => {
                  setErrors((prevState: any) => ({ ...prevState, name: err }));
                }}
                type={config.fields.name.type}
                label={config.fields.name.label}
                value={fieldsState?.name}
                error={errors?.name}
                name={'name'}
              />
            </div>
          </Grid>
          <Grid item className="section-4">
            <Grid item xs={4}>
            {allStates.length > 0 && (
              <AutosuggestInput
                id="system-state"
                handleChange={(newState: any) => {
                  setFieldsState((prevState: any) => {
                    return {
                      ...prevState,
                      system_state: { ...newState },
                    };
                  });
                }}
                data={allStates}
                error={errors?.system_state}
                value={fieldsState?.system_state?.name}
                {...config.fields.system_state}
              />
            )}
            </Grid>
            <Grid item xs={4}>
            <AutosuggestInput
              id="system-city"
              handleChange={(newState: any) => {
                setFieldsState((prevState: any) => {
                  return {
                    ...prevState,
                    system_city: { ...newState },
                  };
                });
              }}
              data={allCities}
              error={errors?.system_city}
              value={fieldsState?.system_city?.name}
              {...config.fields.system_city}
            />
            </Grid>
            <Grid item xs={4}>
            <AutosuggestInput
              id="system-city--zipcode"
              handleChange={(newState: any) => {
                setFieldsState((prevState: any) => {
                  return {
                    ...prevState,
                    system_zipcode: { ...newState },
                  };
                });
              }}
              data={allCityZipCodes}
              error={errors?.system_zipcode}
              value={fieldsState?.system_zipcode?.value}
              {...config.fields.system_zipcode}
            />
            </Grid>
          </Grid>
          <Grid item className="section-3">
            <div className="col d-flex pt-4 pb-2">
              <Button
                variant="primary"
                className="btn btn-primary btn-block"
                type="button"
                disabled={isLoading}
                onClick={handleUpdateZone}
              >
                {config.strings.confirm}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <SweetAlert
        info
        show={showUpdateAlert}
        showCancel
        confirmBtnText="Yes, update it!"
        confirmBtnBsStyle="info"
        title={`${config?.alerts?.update?.title}`}
        onConfirm={updateZone}
        onCancel={() => setShowUpdateAlert(false)}
        focusCancelBtn
      />
    </InstallerTemplate>
  );
};

function mapStateToProps(state: any) {
  return {
    isLoading: state.loader.loading,
    zone: state.zones.editZone,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    loading: {
      start: dispatch.loader.startLoader,
      stop: dispatch.loader.stopLoader,
    },
    clearStore: dispatch.zones.resetEditZone,
    getZoneDetails: dispatch.zones.getEditZone,
    updateZone: dispatch.zones.editZone,
    setStoreState: dispatch.zones.updateState,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditZone);


// <div>
//   <InputImage
//     onChange={onChangeState.bind(null, 'avatar')}
//     error={errors?.avatar}
//     value={fieldsState?.avatar}
//   />
// </div>
