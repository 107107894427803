/* tslint:disable */
// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { ChartLegend } from './components';
import config from './config';
import './styles.scss';


let CONFIG: any = { ...config };
let CHARTS_COLORS: any = { ...config.chartsColors };
let ORDERED_TAGS: any = [...config.charts.orderedTags];


const ChartLegendMain: React.FC = ({ data, ...props }) => {
  const [legendData, setLegendData]: any = useState([]);
  const [ventData, setVentData]: any = useState([]);
  const [exhaustData, setExhaustData]: any = useState([]);


  const colorPicker: any = (entry: any) => {
    const { colors }: any = entry || {};
    const { cat, type }: any = colors || {};

    return cat && type && CHARTS_COLORS[cat][type]
      ? CHARTS_COLORS[cat][type]
      : CHARTS_COLORS.freeTime;
  };

  const renderChartLegend = (entry: any, index: number) => {
    const color: any = colorPicker(entry);
    return (
      <ChartLegend
        key={index + '-value'}
        entry={entry}
        color={color}
        index={index}
      />
    );
  };

  useEffect(() => {
    const { vent, exhaust } = data || {};

    if (vent && vent.length > 0) {
      setVentData([...vent]);
    }

    if (exhaust && exhaust.length > 0) {
      setExhaustData([...exhaust]);
    }
  }, [data]);

  useEffect(() => {
    const mergedData: any = ventData.concat(exhaustData);
    if (mergedData.length > 0) {
      let uniqueRows: any = [];
      let dataClone: any = [...new Set(mergedData.map((row: any) => row.x))];
      dataClone = dataClone.filter(
        (item: string) => item !== 'mixTimeEllapsedB' && item !== 'mixTimeRemainB'
      );

      dataClone.sort((itemA: string, itemB: string) => {
        let indexA: number = ORDERED_TAGS.findIndex((tag: string) => tag === itemA);
        let indexB: number = ORDERED_TAGS.findIndex((tag: string) => tag === itemB);

        if(indexA > indexB) return 1;
        else if(indexA < indexB) return -1;
        else return 0;
      });

      dataClone.forEach((item: string) => {
        const temp: any = mergedData.find((row: any) => row.x === item);
        if (temp) uniqueRows.push(temp);
      });

      setLegendData([...uniqueRows]);
    }
  }, [ventData, exhaustData]);

  return (
    <div id="legend-wrapper" className="legend-wrapper">
      {legendData.map(renderChartLegend)}
    </div>
  );
}

export default ChartLegendMain;
