// @ts-nocheck
import _ from 'lodash';
import numeral from 'numeral';

const AIR_QUALITY_PARAM = 'PM2.5';

const foobotMap = {
  pm25: 'pm',
  tVoc: 'voc',
  co2: 'co2'
};

const utils = {
  packageFields: (values: any) => {
    let tempPack: any = {
      type: 'updateMultiProps',
      data: [],
    };
    Object.keys(values).forEach((prop: string) => {
      let tempData: any = utils.ObjectPackageByProp(values[prop], prop);
      tempPack.data = tempPack.data.concat(tempData);
    });
    return { ...tempPack };
  },
  mergeExtWithState: (values: any, state: any, pointerProp: string) => {
      const stateClone: any = _.cloneDeep({...state});
      let tempStateData: any = {};
      Object.keys(values).forEach((prop: string) => {
        if(stateClone[prop]){
          if(!tempStateData[prop]) tempStateData[prop] = {};
          tempStateData[prop][pointerProp] = values[prop];
        }
      });
      return {...tempStateData};
  },
  extractFields: (wData: any, aData: any, cfData: any) => {
    const { current, forecast } = wData || {};
    const { temp_f, humidity, pressure_mb } = current || {};
    let currAqi: number = 0;
    let cfm: number = cfData;

    if(aData && Object.keys(aData).length > 0){
      let pm25: any = Object.values(aData).find(
        (item: any) => item.ParameterName === AIR_QUALITY_PARAM
      );
      if(pm25) currAqi = pm25.AQI;
      else currAqi = Object.values(aData)[0].AQI;
    }

    return {
      temp: temp_f,
      hum: humidity,
      prss: pressure_mb,
      aqi: currAqi,
      cfm: cfm
    };
  },
  ObjectPackageByProp: (values: any, prop: string) => {
    let tempData: any = [];
    Object.keys(values).forEach((iProp: string) => {
      let tempItem: any = {
        _prop: prop,
        pointer: iProp,
        value: values[iProp],
        init: true,
      };
      tempData.push({...tempItem});
    });
    return [...tempData];
  },
  packageTemps: (newValues: any) => { 
    let tempData: any = {
      type: 'updateMulti',
      prop: 'temp',
      data: [],
    };
    tempData.data = newValues.map((val: number, index: number) => {
      return {
        pointer: index === 0 ? 'min' : index === 1 ? 'current' : 'max',
        value: val,
        init: true,
      };
    });
    return { ...tempData };
  },
  arrayPackageByProp: (values: any, prop: string) => {
 
    let tempData: any = {
      type: 'updateMulti',
      prop: prop,
      data: [],
    };
    tempData.data = values.map((val: number, index: number) => {
      return {
        pointer: index === 0 ? 'current' : 'max',
        value: val,
        init: true
      };
    });
    return { ...tempData };
  },
  packageSingleValue: (value: any, pointer: string, prop: string) => {
    return {
      type: 'update',
      prop: prop,
      data: {
        pointer: pointer,
        value: value,
        init: true
      }
    };
  },
  canRequestWeather: (device: any) => {
    const { device_city, device_zipcode, device_id } = device;
    return {
      ok: (!!device_city && device_city?.label) || (!!device_zipcode && device_zipcode?.label),
      device_id
    };
  },
  isProbeConnected: (device: any) => {
    let isConnected: boolean = false;
    let isValid: boolean = false;
    const { settings } = device?.details || {};
     
    if(settings && Object.values(settings).length > 0){
      const { conf } = settings;

      if(settings?.pcst === 1 || settings?.pcst === '1' || settings?.pcst === 2 || settings?.pcst === '2' ){
         isConnected = true;
        if(settings?.prob === 3 || settings?.prob === '3'){
          isValid = true;
        }
      }else if(conf?.pcst === 1 || conf?.pcst === '1' || conf?.pcst === 2 || conf?.pcst === '2'){
        isConnected = true;
        if(conf?.prob === 3 || conf?.prob === '3'){
          isValid = true;
        }
      }
    }
    
    return {
      connected: isConnected,
      validData: isValid,
      _probeStatus: settings?.prob || 0,
    };
  },
  packageByPointers: (prop: string, pointers: any, values: any) => {
    return pointers.map((pt: string, index: number) => {
      return {
        _prop: prop,
        pointer: pt,
        value: values[Object.keys(values)[index]],
        init: true
      };
    });
  },
  // to get read value from G3Controller
  getTempValues: (device: any) => {
    const { tpmn, tpmx } = device?.details?.settings?.conf || {};
    let tempData: any = { min: tpmn || -101, max: tpmx || -101 };
    return { ...tempData };
  },
  getTempSetup: (setup: any) => {
    
    const { temp } = setup || {};
    let tempData: any = { min: temp?.min || -101, max: temp?.max || -101 };
    return { ...tempData };
  },
  getHumValues: (device: any) => {
    const { mxrh } = device?.details?.settings?.conf || {};
    let tempData: any = { max: mxrh || -101 };
    return { ...tempData };
  },
  getHumSetup: (setup: any) => {
    const { hum } = setup || {};
    let tempData: any = { max: hum || -101 };
    return { ...tempData };
  },
  getAqiValues: (device: any) => {
    const { aqvi } = device?.details?.settings?.conf || {};
    let tempData: any = { aqvi: aqvi || -101 };
    return { ...tempData };
  },
  getAqiSetup: (setup: any) => {
    const { aqi } = setup || {};
    let tempData: any = { aqvi: aqi || -101 };
    return { ...tempData };
  },
  getCfmSetup: (setup: any) => {
    const { cfm } = setup || {};
    let tempData: any = { max: cfm || -101 };
    return { ...tempData };
  },
  getCfmValue: (device: any) => {       
    let cfmValue: number = 0;
    const { conf } = device?.details?.settings || {};  

    if(conf && Object.values(conf).length > 0){  
       const { cflw } = conf;

       cfmValue = cflw || 0;
     }  
    return cfmValue;
  }, 
 

  getPaValue: (device: any) => {
    const { aqvi } = device?.details?.settings?.conf || {};
  },
  getPressureValue: (device: any) => {
    let paValue: number = 0;
    const connDevices: any = device?.details?.settings?.conf?.cdvs;
    if(connDevices && connDevices.length > 0){
      const foundedConnDev: any = connDevices.find((cDev: any) => cDev.hasOwnProperty('dfps'));
      const { dfps } = foundedConnDev || {};
      paValue = dfps || 0;
    }
    return paValue;
  },
  getIndoorAirQty: (setup: any) => {
    const { inaq } = setup || {};
    const { pm25, co2, tVoc } = inaq || {};
    return { pm25: pm25, co2: co2, tVoc: tVoc };
  },
  extractWeatherProp: (device: any, data: any, prop: string) => {
    const { device_id } = device;
    let val: any = undefined;
    if(data[device_id]){
      switch (prop) {
        case 'temp':
          const { weather } = data[device_id];
          val = numeral(weather?.current?.temp_f || 0).format('0,0');
          val = `${val}ºF`;
          break;
        default:
          break;
      }
    }
    return val ? val : 'N/A';
  },
  selectProbeValues: (device: any) => {
    const { settings } = device?.details || {};
    let tempValues: any = {};
    if(settings){
      const { conf } = settings || {};
      const { ctmp, crhr, cflw } = conf || {};
      tempValues = { ...tempValues, ctmp, crhr, cflw };
    }
    return tempValues;
  },
  // for foobot devices  --  START  ---
  extractFoobotProps: (datapoints: any, sensors: any, prop: string) => {
    let propIndex: number = sensors.findIndex((sns: string) => sns === foobotMap[prop]);
    let currentVal: number = 0;
    if(propIndex > -1){
      currentVal = datapoints[0][propIndex];
    }
    return { value: currentVal, relation: foobotMap[prop] };
  },
  // for foobot devices  --  END  ---
};

export default utils;
