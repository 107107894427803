import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { FirmwareVersion } from './pages';

const base_path = '/admin/dashboard';

const FirmwareRoutes: React.FC = ({ ...props }: any) => {
  return (
    <>
      <Switch>
        <Route
          exact
          path={`${base_path}/firmware-version`}
          component={FirmwareVersion}
        />
      </Switch>
    </>
  );
};

export default FirmwareRoutes;
