//@ts-nocheck
import React from 'react';
import { useHistory } from 'react-router-dom';
import { helpers } from './helpers';
import { BackSectionProps } from './interface';
import { MdArrowBackIosNew } from 'react-icons/md';
import './styles.scss';

const BackSection: React.FC<BackSectionProps> = ({
  origin,
  section,
  id,
  changeTab,
  spec,
}) => {
  const history = useHistory();

  return (
    <div className={`back-section-cnt`}>
      <div className="row back-row">
        <div className={`col-12 ${spec ? spec : ``}`}>
          <button
            onClick={() => {
              history.goBack();

              if (id && helpers.shouldRedirectToSysTab(id)) {
                changeTab(`sys`, 'edit');
              }
            }}
          >
            <div className="container back-btn-inside-cnt">
              <div className="row back-btn-inside-cnt">
                <div className="col-1 back-btn-inside-cnt">
                  <MdArrowBackIosNew className="arrow-back" />
                </div>
                <div className="col back-btn-inside-cnt">
                  <span className="lead back-span">{section}</span>
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default BackSection;
