import React from 'react';
import { GenerateIDProps } from './interface';
import './styles.scss';

const GenerateID: React.FC<GenerateIDProps> = ({
  onClick,
  disabledGenerate,
  label,
}) => {
  return (
    <div className="input-group-append">
      <button
        disabled={disabledGenerate}
        className="btn btn-info spec-generate-id"
        type="button"
        onClick={onClick}
      >
        {label}
      </button>
    </div>
  );
};

export default GenerateID;
