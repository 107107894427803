import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import {
  AllSystems,
  EditSystem,
  SystemDetails,
} from './pages';

const systems_path = '/systems';

const Systems: React.FC = props => {
  return (
    <>
      <BreadcrumbsItem to={`/dashboard${systems_path}`}>
        Systems
      </BreadcrumbsItem>
      <Switch>
        <Route exact path={`/dashboard${systems_path}`} component={AllSystems} />
        <Route exact path={`/dashboard${systems_path}/all`} component={AllSystems} />
        <Route exact path={`/dashboard${systems_path}/edit`} component={EditSystem} />
        <Route path={`/dashboard${systems_path}/details`} component={SystemDetails} />
        <Route path={`*`} component={AllSystems} />
      </Switch>
    </>
  );
};

export default Systems;
// <Route exact path={`/dashboard${systems_path}/add`} component={AddSystem} />
