import React from 'react';
import { LabelProps } from './interface';
import './styles.scss';

const Label: React.FC<LabelProps> = ({ children }) => {
  return (
    <label className="label-cmp-1" htmlFor="labelForm1">
      {children}
    </label>
  );
};

export default Label;
