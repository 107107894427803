import React, { useEffect } from 'react';
import { ModalProps } from './interface';
import { Top, Header, Footer, Body } from './components';

const Modal: React.FC<ModalProps> = ({
  id,
  title,
  icon,
  body,
  onClick,
  modalMustClose,
  handleModalState,
  label,
  className,
  bodyClass,
  config,
  sureToProceed,
  hasConfirmBtn,
  warning,
  onClickCancel,
  larger,
}) => {
  useEffect(() => {
    if (
      modalMustClose &&
      document &&
      document.getElementById(`${id}-close-modal`)
    ) {
      let close =
        document && document?.getElementById(`${id}-close-modal`)?.click();

      if (onClickCancel) {
        onClickCancel();
      }

      setTimeout(() => {
        handleModalState(false);
      }, 250);  
    }
  }, [modalMustClose]);

  return (
    <Top id={id}>
      <div
        className={`modal-dialog modal-dialog-centered ${larger ? larger : ''}`}
        role="document"
      >
        <div className="modal-content">
          <Header
            title={title}
            id={`${id}-close-modal`}
            onClick={onClickCancel}
          />
          <Body
            body={body}
            bodyClass={bodyClass}
            config={config}
            sureToProceed={sureToProceed}
            warning={warning}
          />
          <Footer
            label={label}
            icon={icon}
            onClick={onClick}
            className={className}
            hasConfirmBtn={hasConfirmBtn}
            onClickCancel={onClickCancel}
          />
        </div>
      </div>
    </Top>
  );
};

export default Modal;
