import React from 'react';
import { DetailsHeaderProps } from './interface';
import { Spinner } from '..';
import './styles.scss';

const DetailsHeader: React.FC<DetailsHeaderProps> = ({
  designation,
  sensorProp,
  icon,
}) => {
  return (
    <div className="chart-wrapper--title-container1">
      <div className="col--left" />
      <div className="col-center">
        <div className={`container details-header-c pdg`}>
          <div className="row">
            <div className="col-auto pdg">
              <h5>
                {!designation ? <Spinner /> : designation} {icon}{' '}
              </h5>
            </div>
            <div className="col pdg">
              <span className="is-local-string">{sensorProp}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsHeader;
