import React, { Fragment, useState, useEffect } from 'react';
import config from './config';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { functions } from './helpers';
import { EditDeviceProps } from './interface';
import {
  Template,
  Label,
  Input,
  SwitchBtn,
  Button,
  Modal,
} from 'business/modules/admin/common';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { RiSave3Fill, RiEdit2Line } from 'react-icons/ri';
import { HiOutlineXCircle } from 'react-icons/hi';
import './styles.scss';

const EditDevice: React.FC<EditDeviceProps> = ({
  updateDev,
  deviceItem,
  saveDeviceItemGlobally,
}) => {
  const history = useHistory();
  const [devData, setDevData] = useState<any>(config?.devState);
  const [switchValue, setSwitchValue] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({
    device: '',
    state: '',
    city: '',
    macAddress: '',
    zipCode: '',
  });

  const handleInput = (event: any) => {
    let name: string = event.target.name;
    let value: any = event.target.value;

    let validate = functions.handleErrors(name, value);
    setErrors({ ...errors, [name]: validate });

    setDevData({ ...devData, [name]: value });
  };

  const handleStatus = () => {
    setSwitchValue(!switchValue);
  };

  const returnValue = (item: string) => {
    return devData[item] || '';
  };

  const cancelUpdate = () => {
    setDevData({
      ...devData,
      city: deviceItem?.city,
      device: deviceItem?.device_name,
      macAddress: deviceItem?.macAddress,
      state: deviceItem?.state,
      zipCode: deviceItem?.zipcode,
    });
    setSwitchValue(deviceItem?.status);

    setErrors({
      device: '',
      state: '',
      city: '',
      macAddress: '',
      zipCode: '',
    });
  };

  useEffect(() => {
    updateDev({ ...devData, switchState: switchValue });
  }, [devData, switchValue]);

  useEffect(() => {
    if (deviceItem && Object.keys(deviceItem).length > 0) {
      setDevData({
        ...devData,
        city: deviceItem?.city,
        device: deviceItem?.device_name,
        macAddress: deviceItem?.macAddress,
        state: deviceItem?.state,
        zipCode: deviceItem?.zipcode,
      });

      setSwitchValue(deviceItem?.status);
    }
  }, [deviceItem]);

  useEffect(() => {
    const persistentDev: any = localStorage.getItem('@instDevItem');
    let parsed;

    if (persistentDev) {
      parsed = JSON.parse(persistentDev);
    }

    saveDeviceItemGlobally(parsed);
  }, []);

  //prevent saving if there are empty fields:

  useEffect(() => {
    let values = Object.values(devData);

    let empty: any = values.filter((elem: any) => elem.trim().length === 0);

    let errs: any = Object.values(errors);

    let checkErrs: any = errs.filter((elem: any) => elem.trim().length > 0);

    if (empty.length === 0 && checkErrs.length === 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [devData]);

  return (
    <Template
      id="installers-devs"
      title={config.ed.title}
      goBack={() => {
        history.push(
          '/admin/dashboard/companies-list/company/installers/devices',
        );
      }}
    >
      <BreadcrumbsItem to={config.ed.route}>
        {config.ed.breadcrumbItem}
      </BreadcrumbsItem>

      <div className={`jumbotron edit--dev--instal`}>
        <h4 className="centered">{config.ed.mainTitle} </h4>
        <p className="lead centered card-top-ft">{config.ed.pText}</p>
        <hr className="my-4" />

        <div className="container" id="id-update-dev-form-02">
          <div className="row d-flex justify-content-center">
            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 the-form-col1">
              <form>
                <div className="form-group">
                  {Object.keys(config.fields).map(
                    (item: any, index: number) => {
                      return (
                        <Fragment key={index}>
                          {item === `status` ? (
                            <div id={`dev-install-status`}>
                              <Label>{functions.formatLabel(item)}</Label>
                              <SwitchBtn
                                className=""
                                id={''}
                                value={switchValue}
                                onChange={handleStatus}
                              />
                            </div>
                          ) : (
                            <Fragment key={item}>
                              <Label>{functions.formatLabel(item)}</Label>

                              <Input
                                name={item}
                                value={returnValue(item)}
                                onChange={handleInput}
                                type="text"
                                id={`id-${item}`}
                                placeholder={`Enter ${functions.formatLabel(
                                  item,
                                )} ${functions.isDev(item)}`}
                              />
                              <div className="render-error">
                                {errors && errors[item] ? errors[item] : ''}
                              </div>
                            </Fragment>
                          )}
                        </Fragment>
                      );
                    },
                  )}
                </div>
              </form>
            </div>
          </div>

          <div className="row d-flex justify-content-center mt-1">
            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 the-form-col1">
              <Button
                className="small-button dismiss-btn float-right-btn"
                onClick={cancelUpdate}
                type="button"
              >
                <span className="btn-label btn-child-ft">
                  <HiOutlineXCircle className="cancel" /> CANCEL{' '}
                </span>
              </Button>

              <Button
                className="small-button ok float-right-btn"
                dataToggle="modal"
                dataTarget={`#save-changes-to-current-dev`}
                type="button"
                disabled={disabled}
              >
                <span className="btn-label btn-child-ft">
                  <RiSave3Fill className="edit" /> SAVE
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        config={config}
        id={`save-changes-to-current-dev`}
        title={config.modalStrings.edit}
        icon={<RiEdit2Line className="edit" />}
        body={config.modalStrings.aboutToUpdate}
        label={config.modalStrings.labelOK}
        className={`small-button ok`}
        bodyClass={`alert alert-info`}
        sureToProceed={config.modalStrings.sureToProceed}
        hasConfirmBtn={true}
        warning={config.modalStrings.noUndo}
        onClickCancel={() => {}}
        onClick={() => {}}
        //handle modal automatic closing when
        //there is a real dev creation going on:
        /* modalMustClose={modalMustClose}
        handleModalState={handleModalState}*/
        modalMustClose={false}
        handleModalState={() => {}}
      />
    </Template>
  );
};

function mapStateToProps(state: any) {
  const { adminInstallerDevsState } = state;
  return {
    deviceItem: adminInstallerDevsState.deviceItem,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { adminInstallerDevsState } = dispatch;
  return {
    updateDev: adminInstallerDevsState.updateDev,
    saveDeviceItemGlobally: adminInstallerDevsState.saveDeviceItemGlobally,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDevice);
