import React, { useState, useEffect, useRef } from 'react';
import config from './config';
import moment from 'moment';
import { connect } from 'react-redux';
import { DetailsProps } from './interface';
import _ from 'lodash';
import { functions } from './helpers';
import { useHistory } from 'react-router-dom';
import { csvConverterUtil as CsvConverter } from './utils';
import { G3Card, Settings, DataLogging } from './components';
import {
  Button,
  Modal,
  DetailsHeader,
  TooltipView,
} from 'business/modules/admin/common';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  MdCheck,
  MdInfoOutline,
  MdOutlineLocationOn,
  MdClose,
} from 'react-icons/md';
import { FaExclamation } from 'react-icons/fa';
import { FaFan } from 'react-icons/fa';
import { TbWorldLatitude, TbWorldLongitude } from 'react-icons/tb';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import './styles.scss';

const TABLE_COLUNMS: any = [...config.datalogging.table.columns];

const Details: React.FC<DetailsProps> = ({
  sectionData,
  element,
  setItem,
  setDataToListCommands,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  cancelDataLog,
  setMessage,
  devToGetLogs,
  modalMustClose,
  handleModalState,
  loading,
  getLogsByDates,
  systemsList,
  systemsListIsLoaded,
  designation,
  systemsCurrentStatus,
}) => {
  const history = useHistory();
  const toTheTopRef = useRef<any>(null);
  const [expandedCtrl, setIsExpandedCtrl] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [sysTimezone, setSysTimezone]: any = useState('');
  const [dlKey, setDlKey] = useState('startkey');

  const handleChange = (panel: string) => (event: any, isExpanded: any) => {
    setIsExpandedCtrl(isExpanded);
  };

  const handleClick = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setItem(element);
  };

  const handleClose = (event: any) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const checkCommands = () => {
    localStorage.setItem('currentDev', element.device_id);
    localStorage.setItem('g3Device', JSON.stringify(element));

    // add device object to access timezone
    setDataToListCommands({
      currentAccount: localStorage.getItem('currentAccount'),
      currentDev: localStorage.getItem('currentDev'),
      g3Device: element.device_id,
    });

    history.push(`/admin/dashboard/details/devices/commands`);
  };

  const checkSetupLogs = () => {
    localStorage.setItem('currentDev', element.device_id);

    localStorage.setItem('g3Device', JSON.stringify(element));

    history.push(`/admin/dashboard/details/devices/setup-logs`);
  };

  const handleOption = (optionId: string) => {
    switch (optionId) {
      case 'commands-option-dev':
        return checkCommands();
      case 'setup-logs-option-dev':
        return checkSetupLogs();
      case 'datalogg-option-dev':
        return () => {};
      default:
        return () => {};
    }
  };

  const returnValue = (id: string) => {
    if (element.hasOwnProperty(id)) {
      return element?.[id];
    }
    if (element?.device_state?.hasOwnProperty(id)) {
      return element?.device_state?.[id];
    }
    if (element?.device_zipcode?.hasOwnProperty(id)) {
      return element?.device_zipcode?.[id];
    }
    if (element?.details?.hasOwnProperty(id)) {
      return element?.details?.[id];
    }
    if (element?.details?.settings?.hasOwnProperty(id)) {
      return element?.details?.settings?.[id];
    } else {
      return 'No values.';
    }
  };

  const returnLabel = (tag: string) => {
    if (config.dictionary.hasOwnProperty(tag)) {
      return config.dictionary[tag as keyof typeof config.dictionary];
    } else {
      return tag;
    }
  };

  const cancelDownload = () => {
    cancelDataLog(true);
    setStartDate();
    setEndDate();
    setMessage(``);
    setDlKey(`cancel-key`);
  };

  //get the logs by dates
  const handleCsvDatesRequestData = async (
    systemId: string,
    startTime: number,
    endTime: number,
  ) => {
    const account = localStorage.getItem('currentAccount');
    const result: any = await getLogsByDates({
      systemId,
      account_id: account,
      start: startTime,
      end: endTime,
    });

    if (result?.length > 0) {
      return functions.mapDataLogs(result, sysTimezone);
    }
    return;
  };

  const handleCsvRequestDayByDay = async (
    systemId: string,
    startTime: number,
    endTime: number,
  ) => {
    const twentyFourHours: number = 1000 * 60 * 60 * 24;
    let tempStartTime: number = startTime;
    let tempEndTime: number = startTime + twentyFourHours;
    let responseData: any = [];

    do {
      const tempResult: any = await handleCsvDatesRequestData(
        systemId,
        tempStartTime,
        tempEndTime,
      );

      if (tempResult && tempResult.length > 0) {
        const resultReversed: any = tempResult.reverse();
        responseData = responseData.concat(resultReversed);
      }

      tempStartTime = tempEndTime;
      tempEndTime = tempEndTime + twentyFourHours;
    } while (tempEndTime <= endTime);

    return responseData;
  };

  const confirmDLDownload = async (system: any, start: any, end: any) => {
    loading.start('Loading CSV Data...');

    const { device_id }: any = system;
    let reqResult: any;
    let timeGain: number = 1;

    if (device_id) {
      if (start && end) {
        const startTime: any = functions.transformDate(start, 'start');
        const endTime: any = functions.transformDate(end, 'end');
        const datesDiff: any = functions.checkDifference(endTime, startTime);
        timeGain = datesDiff;

        if (datesDiff && datesDiff > 4) {
          reqResult = await handleCsvRequestDayByDay(
            device_id,
            startTime,
            endTime,
          );
        } else {
          const tempData: any = await handleCsvDatesRequestData(
            device_id,
            startTime,
            endTime,
          );

          reqResult =
            tempData && tempData.length > 0 ? tempData.reverse() : tempData;
        }
      } else {
        //no dates defined, request the info from now up to last 24 hours
        let today = moment();
        let yesterday = today.subtract(1, 'days');

        const twentyFourHoursAgo: any = functions.transformDate(
          yesterday,
          'start',
        );
        const rightNow: any = functions.transformDate(today, 'end');

        const tempData: any = await handleCsvDatesRequestData(
          device_id,
          twentyFourHoursAgo,
          rightNow,
        );

        reqResult =
          tempData && tempData.length > 0 ? tempData.reverse() : tempData;
      }

      CsvConverter.exportCsv(TABLE_COLUNMS, reqResult, {
        device_id: system?.device_id,
        device_name: system?.device_name,
      });
    }
    loading.stop();
  };

  useEffect(() => {
    if (systemsListIsLoaded) {
      let tempSelected: any;

      if (systemsList?.length > 0) {
        tempSelected = _.cloneDeep(systemsList[0]);

        if (tempSelected?.device_id) {
          const currTimezone: string = functions.handleSystemTimeZone(
            tempSelected,
          );
          setSysTimezone(currTimezone);
        }
      }
    }

    return () => {
      setSysTimezone('');
    };
  }, [systemsListIsLoaded, systemsList]);

  return (
    <div style={{ marginBottom: '16px', marginTop: '16px' }}>
      <DetailsHeader
        designation={designation}
        sensorProp={''}
        icon={
          systemsCurrentStatus &&
          Object.keys(systemsCurrentStatus).length > 0 &&
          systemsCurrentStatus.hasOwnProperty(element?.device_id) &&
          systemsCurrentStatus[element?.device_id].statusIsLoaded &&
          systemsCurrentStatus[element?.device_id].stopped ? (
            <TooltipView title="Disconnected">
              <span>
                <div className="circle-excl">
                  <FaExclamation
                    size="0.8rem"
                    color="red"
                    className="system-status-icon"
                  />
                </div>
              </span>
            </TooltipView>
          ) : null
        }
      />

      <div style={{ marginTop: '16px' }}>
        <Accordion
          className={sectionData.className}
          square
          expanded={expandedCtrl}
          onChange={handleChange(sectionData.handleChangeID)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={''}
            id={sectionData.summaryId}
          >
            <div className="container admin-section-container cont-m-h devices-details">
              <div className="row accordion-r">
                <div className="col-12 accordion-col">
                  <div className="row">
                    <div className="col accordion-col">
                      <p className={`lead accordion-title`}>{config.title}</p>

                      <div className="row mb-1">
                        <div className="col-xs-1 col-sm-1 col-md-auto pdg the-icon">
                          <span className="lead smr-lead left-pdg">
                            <MdOutlineLocationOn className="location-icon" />
                          </span>
                        </div>

                        <div className="col pdg the-letters">
                          <span className="lead smr-lead left-pdg">
                            {!element.hasOwnProperty('device_zipcode') &&
                            !element.hasOwnProperty('device_state') ? (
                              <>No location</>
                            ) : (
                              <>
                                {element?.device_city?.name || 'No city'},{' '}
                                {element?.device_state?.name || 'No state'}{' '}
                              </>
                            )}
                          </span>
                        </div>
                      </div>

                      <div className="row mb-1">
                        <div className="col-xs-1 col-sm-1 col-md-auto the-icon pdg">
                          <TbWorldLatitude className="lat-long-icon" />
                        </div>
                        <div className="col-auto pdg the-letters mr1">
                          <span className="lead nums-lead left-pdg">
                            {element?.device_zipcode?.latitude &&
                            element?.device_zipcode?.longitude
                              ? element?.device_zipcode?.latitude
                              : `No latitude`}
                          </span>
                        </div>
                        <div className="col-xs-1 col-sm-1 col-md-auto pdg the-icon">
                          <TbWorldLongitude className="lat-long-icon" />
                        </div>
                        <div className="col-auto pdg the-letters mr1">
                          <span className="lead nums-lead left-pdg">
                            {element?.device_zipcode?.latitude &&
                            element?.device_zipcode?.longitude
                              ? element?.device_zipcode?.longitude
                              : `No longitude`}
                          </span>
                        </div>
                      </div>

                      <div className="row mb-1">
                        <div className="col-xs-1 col-sm-1 col-md-auto the-icon pdg">
                          {element.isSystem ? (
                            <MdCheck className="check-icon" />
                          ) : (
                            <MdClose className="no-check" />
                          )}
                        </div>
                        <div className="col-auto pdg the-letters mr1">
                          <span className="lead smr-lead left-pdg">System</span>
                        </div>
                        <div className="col-xs-1 col-sm-1 col-md-auto pdg the-icon fan--i">
                          <FaFan className="fan-icon" />{' '}
                        </div>
                        <div className="col-auto pdg the-letters mr1">
                          <span className="lead smr-lead left-pdg">
                            {element?.details?.settings?.conf?.cdvs?.length}{' '}
                            {element?.details?.settings?.conf?.cdvs?.length ===
                            1
                              ? 'peripheral'
                              : 'peripherals'}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-1">
                      <Button
                        onClick={handleClick}
                        type="button"
                        className="btn menu-btn"
                        spanClass={`btn-child-ft`}
                      >
                        <MoreHoriz />
                      </Button>
                      <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          'aria-labelledby': 'more-button',
                        }}
                      >
                        {config.strings.map((option: any, index: number) => {
                          return (
                            <MenuItem
                              key={index}
                              onClick={(event: any) => {
                                handleClose(event);
                                handleOption(option.id);
                              }}
                              data-toggle={option.dataToggle}
                              data-target={option.dataTarget}
                            >
                              {option.label}
                            </MenuItem>
                          );
                        })}
                      </Menu>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="container dev-details-container pdg">
              <div className="row no-left-space">
                <div className="col col-p-5">
                  <div className="card crd-details align-left">
                    <div className="card-body p-zero" ref={toTheTopRef}>
                      <h5 className="card-title">
                        {config.accordionDetails.card1.cardTitle}
                      </h5>
                      <h6 className="card-subtitle mb-2 text-muted">
                        <MdInfoOutline className="info-icon" />{' '}
                        {config.accordionDetails.card1.cardSubTitle}
                      </h6>
                      <div className={`card-text`}>
                        {config.devLabels.map((label: any, index: number) => {
                          return (
                            <div className="container col-p-0" key={index}>
                              <div className="row col-p-0 item">
                                <div className="col col-p-0 handle-overflow">
                                  <span className="tw-px lead stronger">
                                    {label.label}:{' '}
                                  </span>
                                </div>
                                <div className="col-6 pdg">
                                  <span className="alert-device lead flt-right pt2">
                                    {returnValue(label.id)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <hr className="divider-hr" />
                      <h6 className="card-subtitle mb-2 text-muted">
                        <MdOutlineLocationOn className="icon-card-title" />{' '}
                        {config.accordionDetails.card2.subTitle}
                      </h6>
                      <div className={`card-text mb--0`}>
                        {config.locationDevLabels.map(
                          (label: any, index: number) => {
                            return (
                              <div className="container col-p-0" key={index}>
                                <div className="row col-p-0 item">
                                  <div className="col col-p-0 handle-overflow">
                                    <span className="tw-px lead stronger">
                                      {label.label}:{' '}
                                    </span>
                                  </div>
                                  <div className="col-6 pdg">
                                    <span className="alert-device lead flt-right pt2">
                                      {returnValue(label.id)}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          },
                        )}
                      </div>

                      <G3Card
                        config={config}
                        device={element}
                        returnLabel={returnLabel}
                        returnValue={returnValue}
                      />
                      <hr style={{ color: 'white' }} />

                      <Settings
                        config={config}
                        device={element}
                        returnLabel={returnLabel}
                        returnValue={returnValue}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <Modal
        config={config}
        bodyClass={functions.handleOpts('download_dl', 'bodyClass')}
        className={`small-button ok`}
        onClick={() => {
          confirmDLDownload(devToGetLogs, startDate, endDate);
        }}
        onClickCancel={cancelDownload}
        title={functions.handleOpts('download_dl', `title`)}
        icon={functions.handleOpts('download_dl', `icon`)}
        body={
          <DataLogging
            key={dlKey}
            data={devToGetLogs}
            id={`data-logging-download-modal`}
            endDate={endDate}
            startDate={startDate}
            setStartDate={(date: any) => setStartDate(date)}
            setEndDate={(date: any) => setEndDate(date)}
          />
        }
        label={config.labels['download_dl' as keyof typeof config.labels]}
        hasConfirmBtn={true}
        id={'data-logging'}
        modalMustClose={modalMustClose}
        handleModalState={handleModalState}
        larger={`modal-lg`}
      />
    </div>
  );
};

function mapStateToProps(state: any) {
  const { adminDataLoggingState, adminDevicesState } = state;
  return {
    devToGetLogs: adminDataLoggingState.devToGetLogs,
    startDate: adminDataLoggingState.startDate,
    endDate: adminDataLoggingState.endDate,
    modalMustClose: adminDataLoggingState.modalMustClose,
    systemsList: adminDevicesState.systemsList,
    systemsListIsLoaded: adminDevicesState.systemsListIsLoaded,
    systemsCurrentStatus: adminDevicesState.systemsCurrentStatus,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { loader, adminDevicesState, adminDataLoggingState } = dispatch;
  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    setDataToListCommands: adminDevicesState.setDataToListCommands,
    setStartDate: adminDataLoggingState.setStartDate,
    setEndDate: adminDataLoggingState.setEndDate,
    cancelDataLog: adminDataLoggingState.cancelDataLog,
    setMessage: adminDataLoggingState.setMessage,
    handleModalState: adminDataLoggingState.handleModalState,
    getLogsByDates: adminDataLoggingState.getLogsByDates,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Details);
