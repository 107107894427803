import React from 'react';
import { FiRefreshCcw } from 'react-icons/fi';
import { HiOutlinePlus } from 'react-icons/hi';
import './styles.scss';

interface CommandsButtonSectionProps {
  onClick: any;
  typeOfBtn?: string;
}

const CommandsButtonSection: React.FC<CommandsButtonSectionProps> = ({
  onClick,
  typeOfBtn,
}) => {
  return typeOfBtn && typeOfBtn === `add-comp` ? (
    <>
      <div className="col-3 align-self-center"></div>
      <div className="col-2 reload-button-container-col">
        <div className="xs-sm-c">
          <button className="btn btn-info refresh-cmds" onClick={onClick}>
            <HiOutlinePlus className="svg-refresh" />
          </button>
        </div>
        <div className="md-lg-xl-c">
          <button className="btn btn-info refresh-cmds" onClick={onClick}>
            <HiOutlinePlus
              className="svg-refresh"
              style={{ marginRight: '0.4rem' }}
            />
            <span className="refresh-btn-label">ADD COMPANY</span>
          </button>{' '}
        </div>
      </div>
    </>
  ) : (
    <>
      {' '}
      <div className="col-3 align-self-center"></div>
      <div className="col-2 reload-button-container-col">
        <div className="xs-sm-c">
          <button className="btn btn-info refresh-cmds" onClick={onClick}>
            <FiRefreshCcw className="svg-refresh" />
          </button>
        </div>
        <div className="md-lg-xl-c">
          <button className="btn btn-info refresh-cmds" onClick={onClick}>
            <FiRefreshCcw
              className="svg-refresh"
              style={{ marginRight: '0.4rem' }}
            />
            <span className="refresh-btn-label">RELOAD LIST</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default CommandsButtonSection;
