import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import {
  ConfigurationsList,
  AddConfiguration,
  EditConfiguration,
} from './pages';

const base_path = '/configurations';

const ConfigurationsRouter: React.FC = props => {
  return (
    <>
      <BreadcrumbsItem to={`/dashboard${base_path}`}>
        Configurations
      </BreadcrumbsItem>
      <Switch>
        <Route
          exact
          path={`/dashboard${base_path}`}
          component={ConfigurationsList}
        />
        <Route
          exact
          path={`/dashboard${base_path}/add`}
          component={AddConfiguration}
        />
        <Route
          path={`/dashboard${base_path}/edit`}
          component={EditConfiguration}
        />
        <Route path={`*`} component={ConfigurationsList} />
      </Switch>
    </>
  );
};

export default ConfigurationsRouter;
