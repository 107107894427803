import React from 'react';

const WarnTooltip: React.FC = () => {
  return (
    <>
      <span style={{ fontWeight: 'bold' }}>System Settings Warning</span>
      <p style={{ marginTop: 3 }}>
        For the system to work as expected it is necessary to configure a sensor in order to have reference values.
      </p>
      <p>
        Please configure the sensor in "System Settings". You can click on the icon and you will go to the configuration page.
      </p>
    </>
  );
};

export default WarnTooltip;
