import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';


interface ActionsMenuProps {
  data?: any;
  onDelete?: any;
  setCurrentDevice: any;
}

const ActionsMenu: React.FC<ActionsMenuProps> = ({ data, onDelete, ...props }) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const onclickActions = (device: any, event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const onClickEdit = (device: any, event: any) => {
    props.setCurrentDevice({...device});
    history.push(`/dashboard/devices/edit?id=${device?.device_id}`);
    handleClose();
  };

  const onClickSettings = (device: any, event: any) => {
    props.setCurrentDevice({...device});
    history.push(`/dashboard/devices/details?id=${device?.device_id}`);
    handleClose();
  };

  const onClickDelete = (device: any, event: any) => {
    onDelete({ ...device });
    handleClose();
  };

  return (
    <div>
      <IconButton
        id="more-button"
        aria-controls={`actions-menu-${data.device_id}`}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={onclickActions.bind(null, data)}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        id={`actions-menu-${data.device_id}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'more-button',
        }}
      >
        <MenuItem onClick={onClickEdit.bind(null, data)}>Edit</MenuItem>
        <MenuItem onClick={onClickDelete.bind(null, data)}>Delete</MenuItem>
        <MenuItem onClick={onClickSettings.bind(null, data)}>Details</MenuItem>
      </Menu>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {};
}

function mapDispatchToProps(dispatch: any) {
  return {
    setCurrentDevice: dispatch.devices.setCurrentDevice,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionsMenu);
