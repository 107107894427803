import React, { useEffect, useState, Fragment } from 'react';
import config from './config';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { functions } from './helpers';
import { SetupLogsProps } from './interface';
import {
  List,
  TimezoneDisplay,
  Spinner,
  Template,
} from 'business/modules/admin/common';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { FiRefreshCcw } from 'react-icons/fi';
import Skeleton from '@material-ui/lab/Skeleton';
import './styles.scss';

const SetupLogs: React.FC<SetupLogsProps> = ({
  listAdmSetupLogs,
  loading,
  storeIDToAccessLogs,
  deviceLocationTimezone,
  storeDevLocationTmz,
  currDevIDLogs,
  admSetupLogsLoaded,
  admLogsData,
  adminLogsDataLoaded,
  getSetupLogsShow,
  intendedCounter,
  counter,
  handleCounterAndListOrder,
  orderedList,
  orders,
  updateOrderState,
}) => {
  const history = useHistory();

  const [listOfLogs, setListOfLogs] = useState([]);
  const [loadedLogs, setLoadedLogs] = useState(false);
  const [current, setCurrent] = useState('');
  const [unorderedDefault, setUnorderedDefault] = useState([]);
  const [g3Device, setG3Device] = useState<any>(null);

  const incremented: any = {
    date: intendedCounter.date + 1,
  };

  const callAdminSetupLogs = async () => {
    let savedDefaultArr: any = [];

    loading.start('Loading setup details...');

    const _currentAccount: any = localStorage.getItem('currentAccount');

    let response: any = await listAdmSetupLogs(_currentAccount);

    if (response && response?.statusCode === 200) {
      let defaultData: any = response?.body?.data[currDevIDLogs].forEach(
        (item: any) => {
          savedDefaultArr.push(item);
        },
      );

      setListOfLogs(response?.body?.data[currDevIDLogs]);
      setUnorderedDefault(savedDefaultArr);
      setLoadedLogs(true);
      loading.stop();
    }
  };

  const reloadSetupLogs = () => {
    callAdminSetupLogs();
  };

  const getLogDetails = async () => {
    let dev: any = localStorage.getItem('currentDev');
    let user: any = localStorage.getItem('currentAccount');

    if (dev && user) {
      await getSetupLogsShow({
        system_id: dev,
        account_id: user,
      });
    }
  };

  const filterTheLog = (createdAt: any) => {
    let result = admLogsData.filter((item: any, index: number) => {
      return item.createdAt === createdAt;
    });

    if (
      result &&
      result.length > 0 &&
      result[0].hasOwnProperty('normalSetups')
    ) {
      return result[0]['normalSetups'];
    } else if (
      result &&
      result.length > 0 &&
      result[0].hasOwnProperty('conDevSetups')
    ) {
      return result[0]['conDevSetups'];
    } else {
      return null;
    }
  };

  const retrieveColValue = (item: any, position: string) => {
    switch (position) {
      case '1':
        return (
          <div className="col-xs-12 col-sm-6 col-md-4 date---descript date--only">
            {functions.formatDate(
              item.createdAt,
              config.timezones[deviceLocationTimezone].timezone,
            )}
          </div>
        );
      case '2':
        return (
          <div className="col-11 the-11-col mh--48 pdg">
            {item?.normalSetups && item?.normalSetups?.length > 0 ? (
              item?.normalSetups.map((elem: any, index: number) => {
                return (
                  <Fragment key={index}>
                    {Object.entries<any>(elem).map(
                      ([key, value], index: number) => {
                        return (
                          <div
                            className={`row row--w100 ${
                              item?.normalSetups?.length === 1
                                ? `one-item-only`
                                : ``
                            }`}
                            key={index}
                          >
                            <div className="col-12 key-container-text-acr mh--48 pdg">
                              {functions.returnKey(key)}
                              <span className="badge badge-info acr-data">
                                {key}
                              </span>
                            </div>
                          </div>
                        );
                      },
                    )}
                  </Fragment>
                );
              })
            ) : item?.conDevSetups && item?.conDevSetups?.length > 0 ? (
              item?.conDevSetups.map((elem: any, index: number) => {
                return Object.entries<any>(elem).map(
                  ([key, value], index: number) => {
                    return (
                      <div className="container pdg" key={index}>
                        <div className="row">
                          <div className="col-12 key-container-text-acr mh--48 pdg">
                            {functions.returnKey(key)}
                            <span className="badge badge-info acr-data">
                              {key}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  },
                );
              })
            ) : (
              <> </>
            )}
          </div>
        );

      default:
        return ``;
    }
  };

  const returnAccordionDetails = (item: any) => {
    return (
      <div className={`jumbotron`} id="setup--logs---page">
        <h6 className="display-5">Setup Log Details</h6>
        <p className="lead small-lead">
          Here are the details related to timestamp #
          <span className="curr-dev-string">{item?.timestamp}</span>
        </p>
        <hr className="my-4" />

        <div className="container side-space--1" id="logs--detailed--list">
          <div className="row side-space--1">
            <div className="col side-space--1">
              {!adminLogsDataLoaded ? (
                <Spinner />
              ) : adminLogsDataLoaded && admLogsData.length === 0 ? (
                <></>
              ) : (
                <div
                  className={`logs-detailed-list-scrolled ${
                    Array.isArray(filterTheLog(item.createdAt)) &&
                    filterTheLog(item.createdAt).length > 0
                      ? `hidden-scr`
                      : ``
                  }`}
                >
                  {Array.isArray(filterTheLog(item.createdAt)) &&
                    filterTheLog(item.createdAt).length > 0 && (
                      <div
                        className={`alert alert-light h-np`}
                        role="alert"
                        id="header--setup---1"
                      >
                        <div className="container pdg">
                          <div className="row">
                            {config.header.map((elem: any, i: number) => (
                              <div className={elem.className} key={i}>
                                {elem.label}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}

                  {Array.isArray(filterTheLog(item.createdAt)) &&
                  filterTheLog(item.createdAt).length > 0 ? (
                    filterTheLog(item.createdAt).map(
                      (item: any, index: number) => {
                        return Object.entries<any>(item).map(
                          ([key, value], index: number) => {
                            return (
                              <div className="container pdg" key={index}>
                                <div className="row mb-1">
                                  <div
                                    className={`${config.classes.narrower} pdg lead sm--lead--ft`}
                                  >
                                    <span className="badge badge-primary key-detailed-row1">
                                      {key}
                                    </span>
                                  </div>
                                  <div
                                    className={`${config.classes.lger} pdg lead sm--lead--ft`}
                                  >
                                    {functions.returnExtendedString(key)}
                                  </div>
                                  <div
                                    className={`${config.classes.narrower} pdg lead sm--lead--ft`}
                                  >
                                    {value}
                                  </div>
                                </div>
                              </div>
                            );
                          },
                        );
                      },
                    )
                  ) : (
                    <div
                      className="alert alert-primary alert-style--logs"
                      role="alert"
                    >
                      No details to show.
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    return () => {
      setLoadedLogs(false);
    };
  }, []);

  useEffect(() => {
    if (loadedLogs) {
      loading.stop();
    } else {
      loading.start('Loading setup details...');
    }
  }, [loadedLogs, listOfLogs]);

  useEffect(() => {
    let currentIDDev = localStorage.getItem('currDevIDLogs');
    if (currentIDDev) {
      storeIDToAccessLogs(currentIDDev);

      if (currDevIDLogs) {
        callAdminSetupLogs();

        getLogDetails();
      }
    }
  }, [currDevIDLogs]);

  useEffect(() => {
    let persistentTmz = localStorage.getItem('@deviceLocalTimezone');

    if (persistentTmz) {
      storeDevLocationTmz(persistentTmz);
    }
  }, [deviceLocationTimezone]);

  useEffect(() => {
    if (orders.orderByDate) {
      setCurrent(`date`);
    } else if (
      orderedList &&
      admSetupLogsLoaded &&
      unorderedDefault.length > 0
    ) {
      setCurrent(``);
    }
  }, [unorderedDefault, orders, intendedCounter, counter]);

  useEffect(() => {
    let device: any = localStorage.getItem('g3Device');
    if (device) {
      setG3Device(JSON.parse(device));
    }
  }, []);

  return (
    <Template
      id="setup-logs-list"
      title={config.strings.title1}
      fun={reloadSetupLogs}
      hasRightBtn={true}
      goBack={() => {
        history.push(`/admin/dashboard/user-details`);
      }}
      icon={<FiRefreshCcw className="svg-refresh" />}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 pdg">
            {g3Device ? (
              <>
                <h5 className="card-title">
                  System: {(g3Device && g3Device?.device_name) || ''}{' '}
                </h5>

                <h6 className="card-subtitle mb-2 text-muted">
                  {(g3Device && g3Device?.device_type) || ''}{' '}
                </h6>
              </>
            ) : (
              <>
                <div className="row mb-1">
                  <Skeleton variant="text" height={25} width={'30%'} />{' '}
                </div>
                <div className="row mb-2">
                  <Skeleton variant="text" height={20} width={'20%'} />{' '}
                </div>
              </>
            )}
          </div>

          <div className="col-12 pdg divider--1"></div>
        </div>
      </div>

      {deviceLocationTimezone && (
        <TimezoneDisplay
          timezoneData={deviceLocationTimezone}
          config={config}
          className={`timezone--display-setup-logs`}
        />
      )}

      <List
        onlyCollapse={true}
        icon={<HiOutlineDocumentReport className="icon-cmds" />}
        rowClasses={config.rowClasses}
        id="the--logs"
        list={listOfLogs}
        loaded={loadedLogs}
        itemsPerPage={10}
        manageListOrder={handleCounterAndListOrder}
        orderedList={orderedList}
        orders={orders}
        sectionCounter={intendedCounter}
        sectionCount={counter}
        updateOrderState={updateOrderState}
        incremented={incremented}
        current={current}
        unorderedDefault={unorderedDefault}
        handles={{}}
        config={config}
        firstFun={(item: any) => {}}
        setItem={(item: any) => {}}
        retrieveColValue={retrieveColValue}
        returnAccordionDetails={returnAccordionDetails}
        helperOrderData={[]}
      />
    </Template>
  );
};

function mapStateToProps(state: any) {
  const { adminDevicesState, adminLogsState } = state;

  return {
    intendedCounter: adminLogsState.intendedCounter,
    admLogsData: adminLogsState.admLogsData,
    adminLogsDataLoaded: adminLogsState.adminLogsDataLoaded,
    currDevIDLogs: adminDevicesState.currDevIDLogs,
    deviceLocationTimezone: adminDevicesState.deviceLocationTimezone,
    counter: adminLogsState.counter,
    orderedList: adminLogsState.orderedList,
    orders: adminLogsState.orders,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { loader, adminLogsState, adminDevicesState } = dispatch;

  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    listAdmSetupLogs: adminLogsState.listAdmSetupLogs,
    storeIDToAccessLogs: adminDevicesState.storeIDToAccessLogs,
    storeDevLocationTmz: adminDevicesState.storeDevLocationTmz,
    admSetupLogsLoaded: adminLogsState.admSetupLogsLoaded,
    getSetupLogsShow: adminLogsState.getSetupLogsShow,
    updateOrderState: adminLogsState.updateOrderState,
    handleCounterAndListOrder: adminLogsState.handleCounterAndListOrder,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SetupLogs);
