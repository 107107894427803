export const adminLoaderState = {
  state: {
    loading: false,
    message: 'Loading',
  },
  reducers: {
    updateState(state: any, payload: any, name: string) {
      return { ...state, [name]: payload };
    },
    startLoader(state: any, payload: any) {
      return {
        ...state,
        loading: true,
        message: payload ? payload : 'Loading',
      };
    },
    stopLoader(state: any, payload: any) {
      return {
        ...state,
        loading: false,
        message: payload ? payload : 'Loading',
      };
    },
  },
  effects: (dispatch: any) => ({}),
};
