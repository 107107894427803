import React, { useState, useEffect } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';
import './styles.scss';


interface DataLogsTableHeadProps {
  columns?: any;
  props: any;
}

const DataLogsTableHead: React.FC<DataLogsTableHeadProps> = ({ columns, props }) => {
  const [tableColumns, setTableColumns]: any = useState(columns);

  useEffect(() => {
    if(columns !== null && columns !== undefined){
      setTableColumns(columns);
    }
  }, [columns]);

  return (
    <TableHead>
      <TableRow>
        <TableCell rowSpan={2} className="single-head th--date">{tableColumns[0].title}</TableCell>
        <TableCell colSpan={2} align="center" className="th--time">Time</TableCell>
        <TableCell rowSpan={2} className="single-head th--central-fan">{tableColumns[3].title}</TableCell>
        <TableCell rowSpan={2} className="single-head th--damper">{tableColumns[4].title}</TableCell>
        <TableCell colSpan={2} align="center" className="th--exhaust">{tableColumns[5].title}</TableCell>
        <TableCell rowSpan={2} className="single-head th--air-flow">{tableColumns[7].title}</TableCell>
        <TableCell rowSpan={2} className="single-head th--thermostat">{tableColumns[8].title}</TableCell>
        <TableCell colSpan={3} align="center" className="th--outside">OutsideAir</TableCell>
        <TableCell colSpan={3} align="center" className="th--indoor">IndoorAir</TableCell>
        <TableCell rowSpan={2} align="center" className="single-head th--event">{tableColumns[23].title}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="center">{tableColumns[1].title}</TableCell>
        <TableCell align="center">{tableColumns[2].title}</TableCell>
        <TableCell align="center">State</TableCell>
        <TableCell align="center">{tableColumns[6].title}</TableCell>
        <TableCell align="center">{tableColumns[9].title}</TableCell>
        <TableCell align="center">{tableColumns[12].title}</TableCell>
        <TableCell align="center">{tableColumns[14].title}</TableCell>
        <TableCell align="center">{tableColumns[16].title}</TableCell>
        <TableCell align="center">{tableColumns[18].title}</TableCell>
        <TableCell align="center">{tableColumns[20].title}</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default DataLogsTableHead;
