import React, { useState, useEffect } from 'react';
import { SingleSliderProps } from './interface';
import * as Utils from './utils';
import ReactSlider from 'react-slider';
import { TooltipView } from 'business/modules/admin/common';
import {
  renderCustomThumb,
  LeftSideComponent,
  RightSideComponent,
} from './hooks';
import './styles.scss';

const UTILS = { ...Utils.default };

const SingleSlider: React.FC<SingleSliderProps> = ({
  id,
  onChange,
  max,
  min,
  step,
  symbol,
  composedValues,
  compValsAreLoaded,
  defaultMark,
  defaultMarkValues,
}) => {
  const [compData, setCompData]: any = useState(null);
  useEffect(() => {
    if (id && id !== '') {
      const result: any = UTILS.selectData(id);
      if (result) {
        setCompData({ ...result });
      }
    }
    return () => {
      setCompData(null);
    };
  }, [id]);
  return composedValues.length === 0 && !compValsAreLoaded ? (
    <></>
  ) : composedValues.length === 0 && compValsAreLoaded ? (
    <></>
  ) : (
    <div className="container widget-slider--container2 the-slider-container">
      <div className="row">
        <div className="col side-col" id="slider-left-side--">
          {compData && <LeftSideComponent data={compData} />}
        </div>
        <div className="col middle-col-slider">
          <ReactSlider
            className="ac-horizontal--slider"
            thumbClassName="ac--slider-thumb"
            trackClassName="ac--slider-track"
            value={composedValues}
            step={step}
            max={max}
            min={min}
            ariaLabel={[
              's-Left s-thumb',
              's-Middle s-thumb',
              's-Right s-thumb',
            ]}
            renderThumb={renderCustomThumb.bind(null, symbol)}
            minDistance={-max}
            snapDragDisabled={true}
            onSliderClick={(value: number, tIndex: number) => {}}
            onBeforeChange={(value: number, tIndex: number) => {}}
            onChange={onChange}
          />
          {defaultMark && defaultMarkValues && (
            <div className="default-value--wrapper">
              <TooltipView title={`Default value: ${defaultMarkValues.value}`}>
                <svg
                  width="10"
                  height="8"
                  style={{
                    position: 'absolute',
                    zIndex: 99,
                    top: defaultMarkValues.top,
                    left: defaultMarkValues.left,
                  }}
                >
                  <rect
                    width="10"
                    height="8"
                    style={{ fill: '#90c095', strokeWidth: 0 }}
                  />
                  Sorry, your browser does not support inline SVG.
                </svg>
              </TooltipView>
            </div>
          )}
        </div>
        <div className="col side-col" id="slider-right-side--">
          {compData && <RightSideComponent data={compData} />}
        </div>
      </div>
    </div>
  );
};
export default SingleSlider;
