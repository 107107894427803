import logger from 'js-logger';
const options = Object.assign(
  {
    level: 'info',
  },
  'DEBUG',
);
logger.useDefaults({
  defaultLevel: logger[options.level.toUpperCase()],
});

export default logger;
