// @ts-nocheck
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { BsQuestionCircle } from 'react-icons/bs';
import numeral from 'numeral';
import _ from 'lodash';
import TooltipView from 'react-components/components/Tooltip';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
  WidgetSlider,
  WidgetSingleSlider,
  WidgetDoubleSlider,
  WidgetGradientSlider,
  WidgetActions,
} from '../common';
import { Notification } from 'react-components';
import {
  CfmSingleSlider,
  ProbeSingleSlider,
  OutsideAirTooltip,
  FloatButton,
  SwitchButton,
} from './components';
import { DevicesUtils } from '../../utils';
import config from './config';
import './styles.scss';

const WEATHER_REQ_INTERVAL: any = config.setup.weatherInterval;
const ALERTS_STRINGS: any = { ...config.alerts };
const COMPONENT_STRINGS: any = { ...config?.strings };
const FORMATS: any = { ...config.formats };
const LABELS: any = { ...config?.strings?.labels };
const DEFAULT_EXTREMS: any = { ...config.sliderValues };
const MODEL_FORMAT: any = { ...config.model };
const DEFAULT_VALUES: any = { ...config.sliderValues.defaults };
const ST_LABEL: string = config.strings.stLabel;
let STACK_DATA: any = {};

interface OutsideAirProps {
  device: any;
  deviceSettings?: any;
  setup: any;
  weather: any;
  loading: any;
  requestWeather: any;
  updateDevice: any;
  g3Reads: any;
  getSysSetup: Function;
  updateSysSetup: Function;
  getProbeLastValues: Function;
  ctrlValue?: any;
  setCtrl: any;
  stackData?: any;
  status: any;
}

const OutsideAir: React.FC<OutsideAirProps> = ({
  device,
  stackData,
  ...props
}) => {
  const history = useHistory();
  const [init, setInit]: any = useState(false);
  const [
    outsideAirSectionIsEnabled,
    setOutsideAirSectionIsEnabled,
  ]: boolean = useState(false);
  const [expandedCtrl, setIsExpandedCtrl]: any = useState(false);
  const [showAlert, setShowAlert]: boolean = useState(false);
  const [hasProbeConn, setHasProbeConn]: any = useState(false);
  const [isLocalSet, setIsLocalSet]: any = useState(false);
  const [wInterval, setWInterval]: any = useState(null);
  const [showSaveBtn, setShowSaveBtn]: any = useState(false);
  const [sysSetup, setSysSetup]: any = useState({});
  const [onEditMode, setToEditMode]: boolean = useState(false);
  const [probeDataStatus, setProbeDataStatus]: any = useState(false);
  const [probeStatus, setProbeStatus]: any = useState(0);
  const [isWeatherInit, setIsWeatherInit]: any = useState(false);
  const [currentDeviceId, setCurrentDeviceId]: any = useState();
  const [weatherValues, setWeatherValues]: any = useState({
    ctmp: null,
    crhr: null,
  });
  const [probValidValues, setProbValidValues]: any = useState({
    ctmp: null,
    crhr: null,
    cflw: null,
  });
  const [widgetStates, setWidgetStates]: any = useState({
    temp: { ...MODEL_FORMAT },
    hum: { ...MODEL_FORMAT },
    aqi: { ...MODEL_FORMAT },
    cfm: { ...MODEL_FORMAT },
  });

  const handleAddLocation = (event: any) => {
    const { device_id }: any = device || {};
    props.setCurrentDevice({ ...device });
    history.push(`/dashboard/systems/edit?id=${device_id}`);
  };


  const requestSystemSetup = async (_device: any) => {
    const { device_id } = _device || {};
    const result: any = await props.getSysSetup({ id: device_id });
    if (result) {
      setSysSetup({ ...result });
    }
  };


  const handleChange = (panel: string) => (event: any, isExpanded: any) => {
    if (isLocalSet) {
      setIsExpandedCtrl(isExpanded);
      localStorage.setItem(`${ST_LABEL}${currentDeviceId}`, isExpanded);
    }
  };


  const isValueInit = (value: number, prop: string) => {
    return (
      value[prop] && value[prop].hasOwnProperty('current') && value[prop].init
    );
  };


  const onChangeTemps = (newValues: any) => {
    setWidgetStates((prevValues: any) => {
      return {
        ...prevValues,
        temp: {
          ...prevValues.temp,
          min: newValues[0],
          max: newValues[2],
        },
      };
    });
    setShowSaveBtn(true);
    setToEditMode(true);
  };


  const onChangeHumidity = (newValues: any) => {
    setWidgetStates((prevValues: any) => {
      return {
        ...prevValues,
        hum: {
          ...prevValues.hum,
          min: newValues[0],
          max: newValues[1],
        },
      };
    });
    setShowSaveBtn(true);
    setToEditMode(true);
  };


  const onChangeAqi = (newValues: any) => {
    setWidgetStates((prevValues: any) => {
      return {
        ...prevValues,
        aqi: {
          ...prevValues.aqi,
          min: newValues[0],
          max: newValues[1],
        },
      };
    });
    setShowSaveBtn(true);
    setToEditMode(true);
  };


  const onChangeProbeThreshold = (newValues: any) => {
    setWidgetStates((prevValues: any) => {
      return {
        ...prevValues,
        cfm: {
          ...prevValues.cfm,
          min: newValues[0],
          max: newValues[1],
        },
      };
    });
    setShowSaveBtn(true);
    setToEditMode(true);
  };


  const transformSingleSlider = useCallback((values: any) => {
    const { max, min, current } = values;
    return [current, max];
  });


  const transformCfmSliderValues = useCallback((values: any) => {
    const { max, min, current } = values;
    return [current, max];
  });


  const transformDoubleSlider = useCallback((values: any) => {
    const { max, min, current } = values;
    return [min, current, max];
  });


  const prepareValuesToSave = (values: any, _device: any) => {
    let devSettings: any = _.cloneDeep({ ..._device.details.settings });

    const { temp, aqi, hum } = values;
    devSettings.conf.tpmn = temp.min;
    devSettings.conf.tpmx = temp.max;
    devSettings.conf.mxrh = hum.max;
    devSettings.conf.aqvi = aqi.max;

    return devSettings;
  };


  const retriveFontName = () => {
    // return hasProbeConn ? 'Flow Probe' : 'Weather Api';
    return hasProbeConn && probeDataStatus ? 'Flow Probe' : 'Weather Api';
  };


  const prepareToSaveSetup = (values: any, _device: any) => {
    const { device_id } = _device;
    const { temp, aqi, hum, cfm } = values;
    let tempSetup: object = {
      temp: {
        max: temp.max,
        min: temp.min,
      },
      hum: hum.max,
      aqi: aqi.max,
      cfm: cfm.max,
      turnedOn: outsideAirSectionIsEnabled,
    };

    return {
      system_id: device_id,
      setup: {
        outsideAir: { ...tempSetup },
      },
    };
  };


  const onSaveOutsideConfigs = async (event: any) => {
    setShowAlert(false);
    props.loading.start('Updating device settings...');
    setShowSaveBtn(false);
    const updatedDevSettings: any = prepareValuesToSave(widgetStates, device);
    const setupValues: any = prepareToSaveSetup(widgetStates, device);
    let deviceClone: any = _.cloneDeep({ ...device });
    deviceClone.details.settings = { ...updatedDevSettings };
    const result: any = await props.updateSysSetup(setupValues);

    if (result) {
      setSysSetup({ ...result });
      Notification({
        title: 'Successfully',
        message: config?.notifications?.update?.success,
        type: 'success',
      });
    }
    props.loading.stop();
  };


  const handleSaveOutsideData = async (event: any) => {
    setShowAlert(true);
    setToEditMode(false);
  };


  const onCancelAlert = (event: any) => {
    setShowAlert(false);
    setShowSaveBtn(false);
    setToEditMode(false);
    requestSystemSetup(device);
    props.requestWeather({ deviceId: device.device_id });
  };


  const initUpdateWeather = (deviceId: string) => {
    if (wInterval) clearInterval(wInterval);
    props.requestWeather({ deviceId });

    let interval: any = setInterval(() => {
      if (!STACK_DATA || !STACK_DATA[deviceId]?.stoped) {
        props.requestWeather({ deviceId });
      }
    }, WEATHER_REQ_INTERVAL);

    setWInterval(interval);
    setIsWeatherInit(true);
  };


  const toggleSection = (_status: any) => {
    if(
      props &&
      props.hasOwnProperty('status') &&
      props?.status?.stoped
    ){
      return;
    }

    setOutsideAirSectionIsEnabled(!_status);

    if (!_status) setShowSaveBtn(true);
  };

  const handleAirFlowValue = async (system: any) => {
    const { device_id }: any = system || {};
    let currentAirFlow: number = 0;

    if (device_id) {
      const probeLastValues: any = await props.getProbeLastValues({
        device_id,
      });

      if (probeLastValues) {
        const { cflw }: any = probeLastValues || {};
        currentAirFlow = cflw;
      } else {
        const cfmVal: number = DevicesUtils.getCfmValue(system);
        currentAirFlow = cfmVal;
      }
    }

    setProbValidValues((preValues: any) => {
      return {
        ...preValues,
        cflw: currentAirFlow,
      };
    });
  };

  const processSpecialProp = (prop: string, values: any, oldValues: any) => {
    switch (prop) {
      case 'ctmp':
        return values[prop] &&
          values[prop] !== -99 &&
          values[prop] !== oldValues[prop]
          ? values[prop]
          : oldValues[prop];
      case 'crhr':
        return values[prop] &&
          values[prop] !== -1 &&
          values[prop] !== oldValues[prop]
          ? values[prop]
          : oldValues[prop];
      case 'cflw':
        return values[prop] &&
          values[prop] !== -1 &&
          values[prop] !== 0 &&
          values[prop] !== oldValues[prop]
          ? values[prop]
          : oldValues[prop];
      default:
        return;
    }
  };











  // handle with enpaned state...
  useEffect(() => {
    if (
      outsideAirSectionIsEnabled &&
      currentDeviceId !== null &&
      currentDeviceId !== undefined
    ) {
      let storedState: any = localStorage.getItem(
        `${ST_LABEL}${currentDeviceId}`,
      );

      if (storedState !== null && storedState !== undefined) {
        setIsExpandedCtrl(storedState === 'true');
      }
    }
  }, [outsideAirSectionIsEnabled, currentDeviceId]);

  useEffect(() => {
    if (props?.ctrlValue) {
      const { device_id }: any = device || {};
      handleAirFlowValue(device);

      props.setCtrl((prevValues: any) => {
        return {
          ...prevValues,
          [device_id]: false,
        };
      });
    }
  }, [props?.ctrlValue]);

  useEffect(() => {
    if (props.weather[device?.device_id]) {
      const { weather, airQuality }: any = props.weather[device?.device_id] || {};
      const cfmVal: number = DevicesUtils.getCfmValue(device);
      let extratedValues: any = DevicesUtils.extractFields(
        weather,
        airQuality,
        cfmVal,
      );

      if (extratedValues) {
        let weatherValuesClone: any = _.cloneDeep(weatherValues);

        if (extratedValues.hasOwnProperty('temp')) {
          weatherValuesClone.ctmp = extratedValues.temp;
        }

        if (extratedValues.hasOwnProperty('hum')) {
          weatherValuesClone.crhr = extratedValues.hum;
        }

        setWeatherValues(weatherValuesClone);
      }

      let tempExtrems: any = DevicesUtils.getTempSetup(
        sysSetup?.outsideAir || {},
      );
      let humExtrems: any = DevicesUtils.getHumSetup(
        sysSetup?.outsideAir || {},
      );
      let aqiExrtrems: any = DevicesUtils.getAqiSetup(
        sysSetup?.outsideAir || {},
      );
      let probExtrems: any = DevicesUtils.getCfmSetup(
        sysSetup?.outsideAir || {},
      );

      let mergeResult: any = DevicesUtils.mergeExtWithState(
        extratedValues,
        { ...widgetStates },
        'current',
      );

      let packResult: any = DevicesUtils.packageFields(mergeResult);

      let tempPackExt: any = DevicesUtils.packageByPointers(
        'temp',
        ['min', 'max'],
        tempExtrems,
      );
      if (
        tempPackExt &&
        tempPackExt.length > 0 &&
        !onEditMode
      ) {
        packResult.data = packResult.data.concat([...tempPackExt]);
      }
      let humPackExt: any = DevicesUtils.packageByPointers(
        'hum',
        ['max'],
        humExtrems,
      );
      if (
        humPackExt &&
        humPackExt.length > 0 &&
        !onEditMode
      ) {
        packResult.data = packResult.data.concat([...humPackExt]);
      }

      let probPackExt: any = DevicesUtils.packageByPointers(
        'cfm',
        ['max'],
        probExtrems,
      );
      if (
        probPackExt &&
        probPackExt.length > 0 &&
        !onEditMode
      ) {
        packResult.data = packResult.data.concat([...probPackExt]);
      }

      let aqiPackExt: any = DevicesUtils.packageByPointers(
        'aqi',
        ['max'],
        aqiExrtrems,
      );
      if (
        aqiPackExt &&
        aqiPackExt.length > 0 &&
        !onEditMode
      ){
        packResult.data = packResult.data.concat([...aqiPackExt]);
      }

      // case probe on G3Controller is conneted, remove temperature and humidity from array
      // if (!!props?.g3Reads[device?.device_id] && probeDataStatus) {
      if (
        !!props?.g3Reads[device?.device_id] &&
        hasProbeConn &&
        probeDataStatus
      ) {
        const { ctmp, crhr }: any = props.g3Reads[device.device_id] || {};

        if (
          ctmp !== null &&
          ctmp !== undefined
        ) {
          let tempIndex: number = packResult.data.findIndex(
            (item: any) => item._prop === 'temp' && item.pointer === 'current',
          );

          if (tempIndex > -1) {
            packResult.data[tempIndex].value = ctmp;
          }
        }

        if (
          crhr !== null &&
          crhr !== undefined
        ) {
          let humIndex: number = packResult.data.findIndex(
            (item: any) => item._prop === 'hum' && item.pointer === 'current',
          );

          if (humIndex > -1) {
            packResult.data[humIndex].value = crhr;
          }
        }
      }

      setWidgetStates((prevValues: any) => {
        let valuesClone: any = _.cloneDeep(prevValues);

        if (
          packResult &&
          packResult?.data &&
          packResult.data.length > 0
        ) {
          packResult.data.forEach((item: any) => {
            const { _prop, pointer, value, init }: any = item || {};

            if (valuesClone && valuesClone.hasOwnProperty(_prop)) {
              if (
                pointer === 'max' ||
                (_prop === 'temp' && pointer === 'min')
              ) {
                valuesClone[_prop][pointer] =
                  value === -101 ? DEFAULT_VALUES[_prop][pointer] : value;
              } else {
                if (
                  _prop !== 'cfm' ||
                  (_prop === 'cfm' && pointer !== 'current')
                ) {
                  valuesClone[_prop][pointer] = value;
                }
              }

              valuesClone[_prop].init = init;
            }
          });
        }

        return { ...valuesClone };
      });
    }
  }, [props.weather]);


  useEffect(() => {
    const { device_id, device_name }: any = device || {};

    if (
      hasProbeConn &&
      probeDataStatus &&
      props?.g3Reads[device?.device_id]
    ) {
      const { ctmp, crhr }: any = props.g3Reads[device.device_id] || {};
      const tempValue: any = processSpecialProp(
        'ctmp',
        props.g3Reads[device.device_id],
        probValidValues,
      );

      const humValue: any = processSpecialProp(
        'crhr',
        props.g3Reads[device.device_id],
        probValidValues,
      );

      setProbValidValues((preValues: any) => {
        return {
          ...preValues,
          ctmp: tempValue,
          crhr: humValue,
        };
      });

      setWidgetStates((prevValues: any) => {
        return {
          ...prevValues,
          temp: {
            ...prevValues.temp,
            current: tempValue,
          },
          hum: {
            ...prevValues.hum,
            current: humValue,
          },
        };
      });
    } else {
      setWidgetStates((prevValues: any) => {
        return {
          ...prevValues,
          temp: {
            ...prevValues.temp,
            current: weatherValues.ctmp
              ? weatherValues.ctmp
              : prevValues?.temp?.current || -99,
          },
          hum: {
            ...prevValues.hum,
            current: weatherValues.crhr
              ? weatherValues.crhr
              : prevValues?.hum?.current || -1,
          },
        };
      });
    }
  }, [props?.g3Reads]);

  useEffect(() => {
    if (stackData && device) {
      const { device_id }: any = device || {};

      STACK_DATA[device_id] = _.cloneDeep(stackData);
    }
  }, [stackData]);

  useEffect(() => {
    if (device) {
      const result: any = DevicesUtils.canRequestWeather(device);
      const probeResult: boolean = DevicesUtils.isProbeConnected(device);
      const { connected, validData, _probeStatus }: any = probeResult || {};

      setHasProbeConn(connected);
      setProbeDataStatus(validData);
      setProbeStatus(_probeStatus);
      setCurrentDeviceId(device.device_id);

      if (result.ok && !connected) {
        const { device_id } = result;

        if (!isWeatherInit) {
          initUpdateWeather(device_id);
        }
      }

      setIsLocalSet(result.ok);
    }
  }, [device]);



  useEffect(() => {
    if (
      props?.deviceSettings &&
      props?.deviceSettings?.conf
    ) {
      const tempVal: number = processSpecialProp(
        'ctmp',
        props.deviceSettings.conf,
        probValidValues,
      );

      const humVal: number = processSpecialProp(
        'crhr',
        props.deviceSettings.conf,
        probValidValues,
      );

      setProbValidValues((preValues: any) => {
        return {
          ...preValues,
          ctmp: tempVal,
          crhr: humVal,
        };
      });
    }
  }, [props.deviceSettings]);

  useEffect(() => {
    if (probValidValues?.cflw) {
      setWidgetStates((prevValues: any) => {
        return {
          ...prevValues,
          cfm: {
            ...prevValues.cfm,
            current: probValidValues.cflw,
          },
        };
      });
    }
  }, [probValidValues]);


  useEffect(() => {
    if (!outsideAirSectionIsEnabled && init) {
      const setupValues: any = prepareToSaveSetup(widgetStates, device);
      props.updateSysSetup(setupValues);
    }
  }, [outsideAirSectionIsEnabled]);


  useEffect(() => {
    if (sysSetup) {
      const { outsideAir }: any = sysSetup || {};

      if (outsideAir) {
        const { turnedOn }: any = outsideAir || {};

        if (
          turnedOn !== null &&
          turnedOn !== undefined
        ){
          setOutsideAirSectionIsEnabled(turnedOn);
        }
      }
    }
  }, [sysSetup]);


  useEffect(() => {
    if (props.setup) {
    
      setSysSetup({ ...props.setup });
    }
  }, [props.setup]);


  useEffect(() => {
    return () => {
      if (wInterval) {
        clearInterval(wInterval);
        setWInterval(null);
      }
    };
  }, [wInterval]);


  useEffect(() => {
    if (hasProbeConn) {
      handleAirFlowValue(device);
    }
  }, [hasProbeConn]);


  useEffect(() => {
    setInit(true);
  }, []);






  return (
    <>
      <Accordion
        id="user-widget--outside-air"
        square
        expanded={outsideAirSectionIsEnabled && expandedCtrl}
        onChange={handleChange('outside-air')}
      >
        <AccordionSummary
          expandIcon={isLocalSet ? <ExpandMoreIcon /> : <></>}
          aria-controls="widget--oa"
          id="widget--oa"
          className={!isLocalSet ? 'no-cursor' : ''}
        >
          <div className="widget--title-container">
            <div className="widget--row">
              <div
                className={`widget--title-label ${
                  !isLocalSet ? 'no-local' : ''
                }`}
              >
                {COMPONENT_STRINGS.title}
                <TooltipView title={<OutsideAirTooltip />}>
                  <span className="helper--wrapper">
                    <BsQuestionCircle
                      className="widget--title-helper-icon"
                      size="1rem"
                    />
                  </span>
                </TooltipView>
                {isLocalSet && (
                  <span className="widget--title-fname">
                    {retriveFontName()}
                  </span>
                )}
              </div>
              <div className="widget--title-actions">
                {!isLocalSet ? (
                  <span className="btn-add" onClick={handleAddLocation}>
                    ADD
                  </span>
                ) : (
                  <SwitchButton
                    id="outside-section"
                    onChange={() => toggleSection(outsideAirSectionIsEnabled)}
                    value={outsideAirSectionIsEnabled}
                  />
                )}
              </div>
            </div>
            <div
              className="widget--row"
              style={{ marginTop: 5, minHeight: 63 }}
            >
              <div
                className={`widget--col ac-col-3 col-display ${
                  isLocalSet ? 'col-display--left' : 'col-display--left--b'
                }`}
              >
                {!isValueInit(widgetStates, 'temp') && isLocalSet ? (
                  <Skeleton
                    variant="text"
                    height={60}
                    width={hasProbeConn ? 115 : 165}
                    style={{ float: 'left' }}
                  />
                ) : (
                  <div
                    className={
                      !outsideAirSectionIsEnabled
                        ? `widget--value--disabled`
                        : `widget--value`
                    }
                  >
                    <span>
                      {isLocalSet
                        ? numeral(widgetStates.temp.current).format(
                            FORMATS.temp,
                          )
                        : '_ _'}
                      {LABELS.temp}
                    </span>
                  </div>
                )}
              </div>
              <div
                className={`widget--col ac-col-3 col-display ${
                  isLocalSet ? 'col-display--center' : 'col-display--center--b'
                }`}
              >
                {!isValueInit(widgetStates, 'hum') && isLocalSet ? (
                  <Skeleton
                    variant="text"
                    height={60}
                    width={hasProbeConn ? 115 : 165}
                    style={{ float: hasProbeConn ? 'center' : 'right' }}
                  />
                ) : (
                  <div
                    className={
                      !outsideAirSectionIsEnabled
                        ? `widget--value--disabled ${
                            hasProbeConn && 'air-m-right'
                          }`
                        : `widget--value ${hasProbeConn && 'air-m-right'}`
                    }
                  >
                    {isLocalSet
                      ? numeral(widgetStates.hum.current).format(FORMATS.hum)
                      : '_ _'}{' '}
                    {LABELS.rhum}
                  </div>
                )}
              </div>
              <div
                className={`widget--col ac-col-3 col-display col-display--${
                  hasProbeConn
                    ? isLocalSet
                      ? 'center'
                      : 'center--b'
                    : isLocalSet
                    ? 'right'
                    : 'right--b'
                }`}
              >
                {!isValueInit(widgetStates, 'aqi') && isLocalSet ? (
                  <Skeleton
                    variant="text"
                    height={60}
                    width={hasProbeConn ? 115 : 165}
                    style={{ float: hasProbeConn ? 'right' : 'center' }}
                  />
                ) : (
                  <div
                    className={
                      !outsideAirSectionIsEnabled
                        ? `widget--value--disabled ${
                            hasProbeConn && 'air-m-left'
                          }`
                        : `widget--value ${hasProbeConn && 'air-m-left'}`
                    }
                  >
                    {isLocalSet
                      ? numeral(widgetStates.aqi.current).format(FORMATS.airq)
                      : '_ _'}{' '}
                    {LABELS.airq}
                  </div>
                )}
              </div>
              {hasProbeConn && isLocalSet && (
                <div
                  className={`widget--col ac-col-4 col-display col-display--right--b`}
                >
                  {!isValueInit(widgetStates, 'cfm') ? (
                    <Skeleton
                      variant="text"
                      height={60}
                      width={115}
                      style={{ float: 'left' }}
                    />
                  ) : (
                    <div
                      className={
                        !outsideAirSectionIsEnabled
                          ? `widget--value--disabled`
                          : `widget--value`
                      }
                    >
                      {numeral(widgetStates.cfm.current).format(FORMATS.cfm)}{' '}
                      {LABELS.cfm}
                    </div>
                  )}
                </div>
              )}
            </div>
            {!isLocalSet && (
              <div className="widget--row no-conns">
                <div className="widget--no-location">
                  {COMPONENT_STRINGS.noRequests}
                </div>
              </div>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="widget--body-container">
            <div className="widget--row">
              <WidgetDoubleSlider
                id="w-temp"
                values={transformDoubleSlider(widgetStates.temp)}
                max={DEFAULT_EXTREMS.temp.max}
                min={DEFAULT_EXTREMS.temp.min}
                step={1}
                col={2}
                showLabel={true}
                symbol="ºF"
                onChange={onChangeTemps}
                defaultMarks={true}
                defaultMarksValues={{
                  left: {
                    top: 20,
                    left: '21.6',
                    value: DEFAULT_VALUES.temp.min,
                  },
                  right: {
                    top: 20,
                    right: '31.5',
                    value: DEFAULT_VALUES.temp.max,
                  },
                }}
              />
            </div>
            <div className="widget--row">
              <WidgetSingleSlider
                id="w-rhum"
                values={transformSingleSlider(widgetStates.hum)}
                max={DEFAULT_EXTREMS.hum.max}
                min={DEFAULT_EXTREMS.hum.min}
                step={1}
                col={2}
                showLabel={true}
                symbol="%"
                onChange={onChangeHumidity}
                defaultMark={true}
                defaultMarkValues={{
                  top: 20,
                  left: '79',
                  value: DEFAULT_VALUES.hum.max,
                }}
              />
            </div>
            <div className="widget--row">
              <WidgetGradientSlider
                id="w-aqi"
                values={transformSingleSlider(widgetStates.aqi)}
                max={DEFAULT_EXTREMS.aqi.max}
                min={DEFAULT_EXTREMS.aqi.min}
                step={1}
                col={2}
                showLabel={true}
                symbol=""
                onChange={onChangeAqi}
              />
            </div>
            {hasProbeConn && (
              <div className="widget--row">
                <ProbeSingleSlider
                  id="w-cfm"
                  values={transformCfmSliderValues(widgetStates.cfm)}
                  max={DEFAULT_EXTREMS.cfm.max}
                  min={DEFAULT_EXTREMS.cfm.min}
                  step={1}
                  col={2}
                  showLabel={true}
                  symbol=""
                  probeStatus={probeStatus}
                  onChange={onChangeProbeThreshold}
                  defaultMarkValues={{
                    top: 20,
                    left: '23.4',
                  }}
                />
              </div>
            )}
            {showSaveBtn && <WidgetActions onSave={handleSaveOutsideData} />}
            {showSaveBtn && <FloatButton onSave={handleSaveOutsideData} />}
          </div>
        </AccordionDetails>
      </Accordion>
      <SweetAlert
        info
        showCancel
        confirmBtnText={ALERTS_STRINGS.save?.btn?.confirm}
        title={ALERTS_STRINGS?.save?.question}
        confirmBtnBsStyle="info"
        show={showAlert}
        onConfirm={onSaveOutsideConfigs}
        onCancel={onCancelAlert}
        focusCancelBtn
      />
    </>
  );
};

function mapStateToProps(state: any) {
  const { env } = state;
  return {
    weather: env.weather,
    g3Reads: env.g3Reads,
  };
}

function mapDispatchToProps(dispatch: any) {
  const {
    env,
    logs,
    devices,
    systemSettings,
    loader,
  }: any = dispatch || {};

  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    requestWeather: env.requestWeather,
    updateWeather: env.updateWeather,
    setCurrentDevice: devices.setCurrentDevice,
    updateDevice: devices.editDevice,
    getSysSetup: systemSettings.getSystemSetupBySysId,
    updateSysSetup: systemSettings.editSystemSetup,
    getProbeLastValues: logs.getProbeLaastValues,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OutsideAir);
