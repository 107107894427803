import React from 'react';
import { IoCheckmark } from 'react-icons/io5';

export default {
  strings: {
    confirmation: `You are about to update the Indoor Air configurations.`,
    sureToProceed: `Are you sure you wish to proceed?`,
    updateLocation: `Update the system location information here, in order to set the Outside Air values.`,
    devName: `Device name`,
    devStatus: `Device status`,
    cancel: `CANCEL`,
    save: `SAVE`,
  },
  modalStrings: {
    warning: 'Information',
  },
  titles: {
    loc__update: `Update System location`,
  },
  icons: {
    loc__update: <IoCheckmark className="edit" />,
  },
  labels: {
    loc__update: ` OK`,
    loc__quit__update: ` EXIT`,
  },
  bodies: {
    loc__update: ``,
  },
  bodyClass: {
    loc__update: `alert alert-danger`,
  },
  formConfig: [
    {
      label: 'Device location - State',
      className: 'col-xs-12 col-sm-12 col-md-4 cont--1--p auto-sug-field',
      placeholder: 'State',
      id: 'state',
      type: 'auto-suggest',
      field: 'device_state',
      fields: {
        label: 'System Location - State',
        type: 'dropdown',
        title: 'Enter State',
        placeholder: 'Enter system state',
      },
    },
    {
      label: 'Device location - City',
      className: 'col-xs-12 col-sm-12 col-md-4 cont--1--p auto-sug-field',
      placeholder: 'City',
      id: 'city',
      type: 'auto-suggest',
      field: 'device_city',
      fields: {
        label: 'System Location - City',
        type: 'dropdown',
        title: 'Enter City',
        placeholder: 'Enter system city',
      },
    },
    {
      label: 'Device location - Zip Code',
      className: 'col-xs-12 col-sm-12 col-md-4 cont--1--p auto-sug-field',
      placeholder: 'Zip Code',
      id: 'zip_code',
      type: 'auto-suggest',
      field: 'device_zipcode',
      fields: {
        label: 'System Location - Zip Code',
        type: 'dropdown',
        title: 'Enter zip code',
        placeholder: 'Enter system zip code',
      },
    },
  ],
  states: {
    formValues: {
      device_name: '',
      device_city: {},
      device_state: {},
      device_zipcode: {},
    },
  },
  defaults: {
    state: {
      name: 'Massachusetts',
      isoCode: 'MA',
      countryCode: 'US',
      latitude: '42.40721070',
      longitude: '-71.38243740',
      label: 'Massachusetts',
      value: 'MA',
    },
    city: {
      name: 'Boston',
      countryCode: 'US',
      stateCode: 'MA',
      latitude: '42.35843000',
      longitude: '-71.05977000',
      label: 'Boston',
      value: 'Boston',
    },
  },
  constraints: {
    device_name: {
      presence: true,
      length: {
        minimum: 6,
      },
    },
    status: {
      presence: false,
    },
    device_state: {
      presence: true,
      length: (
        _value: any,
        attributes: any,
        attributeName: any,
        options: any,
        constraints: any,
      ) => {
        const {
          countryCode,
          isoCode,
          label,
          latitude,
          longitude,
          name,
          value,
        } = _value;
        return !(
          countryCode &&
          isoCode &&
          label &&
          latitude &&
          longitude &&
          name &&
          value
        );
      },
    },

    device_city: {
      presence: true,
      length: (
        _value: any,
        attributes: any,
        attributeName: any,
        options: any,
        constraints: any,
      ) => {
        const {
          countryCode,
          label,
          latitude,
          longitude,
          name,
          stateCode,
          value,
        } = _value;
        return !(
          countryCode &&
          label &&
          latitude &&
          longitude &&
          name &&
          stateCode &&
          value
        );
      },
    },
    device_zipcode: {
      presence: true,
      length: (
        _value: any,
        attributes: any,
        attributeName: any,
        options: any,
        constraints: any,
      ) => {
        const {
          city,
          country,
          label,
          latitude,
          longitude,
          value,
          zip,
        } = _value;
        return !(
          city &&
          country &&
          label &&
          latitude &&
          longitude &&
          value &&
          zip
        );
      },
    },
  },
};
