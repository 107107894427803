import React, { useState, useCallback, useEffect } from 'react';
import validate from 'validate.js';
import _ from 'lodash';
import {
  Button,
  Input,
  Notification,
  InputPhone,
  DropdownFilter,
} from '../../../../../../../react-components';
import { useHistory } from 'react-router-dom';
import { ConnectionBroker } from '../../../../../../../core';
import { useAuth } from '../../../../../sessions/hooks/auth';
import config from './config';
import './styles.scss';



const CONFIGS: any = { ...config };
const CONFIG_FIELDS: any = { ...CONFIGS.fields };
const CONFIG_CONSTRAINTS: any = { ...CONFIGS.constraints };


const ProfilePassword: React.FC = () => {
  const { updateUser, accountType }: any = useAuth();
  const history = useHistory();
  const [fieldsState, setFieldsState]: any = useState(CONFIGS.fieldsState);
  const [fieldsStateTmp, setFieldsStateTmp] = useState(
      CONFIGS.fieldsState,
  );
  const [errors, setErrors]: any = useState(true);
  const [editMode, setEditMode]: any = useState(false);
  const [loading, setLoading]: any = useState(false);


  const rootPathCtrl = (account: any) => {
    switch (account) {
      case 'installer':
        return 'installer';
      case 'customer':
      default:
        return 'dashboard';
    }
  };

  const load = async () => {
    const response = await ConnectionBroker.fetchAPI(
      'jitBE',
      'profile.show',
      {},
      null
    );

    if (response.result) {
      loadProfileFieldsState(response.body);
    }
  };

  const cancelEditProfile = () => {
    setFieldsState(fieldsStateTmp);
    setEditMode(false);
    setErrors(false);
  };

  const loadProfileFieldsState = (profile: any) => {
    const {
      first_name,
      last_name,
      addresses,
      phones_numbers,
      emails,
      details,
      account_details,
    } = profile;
    const {
      phone,
      is_mobile,
      is_primary,
      marketing_sms_opt_in,
    } = phones_numbers[0];
    const { email } = emails[0];
    const { address_line_one, city, state, zip_code } = addresses[0];
    let tempFormData: any = {
      first_name,
      last_name,
      address_line_one,
      city,
      state,
      zip_code,
      phone,
      is_mobile,
      is_primary,
      marketing_sms_opt_in,
      email,
    };

    if(account_details && accountType === 'installer'){
      const { company, key }: any = account_details;

      tempFormData = {
        ...tempFormData,
        company,
        installerKey: key,
      };
    }

    setFieldsState(_.cloneDeep(tempFormData));
    setFieldsStateTmp(_.cloneDeep(tempFormData));
  };

  const handleSubmit = useCallback(
    async () => {
      setErrors(false);
      setLoading(true);
      let isValid = true;
      let fieldsClone: any = _.cloneDeep(fieldsState);
      let constraintsClone: any = _.cloneDeep(config.constraints);

      if(accountType !== 'installer'){
        delete fieldsClone.company;
        delete fieldsClone.installerKey;

        delete constraintsClone.company;
        delete constraintsClone.installerKey;
      }

      await validate.async(
        fieldsClone, constraintsClone
      ).then(null, err => {
        setErrors(err);
        setLoading(false);
        isValid = false;
      });

      if (!isValid) {
        setLoading(false);
        return;
      }

      let stateAPI: any = {
        account_id: fieldsClone.account_id,
        first_name: fieldsClone.first_name,
        last_name: fieldsClone.last_name,
        addresses: [
          {
            address_line_one: fieldsClone.address_line_one,
            address_line_two: fieldsClone.address_line_two,
            city: fieldsClone.city,
            state: fieldsClone.state,
            zip_code: fieldsClone.zip_code,
          },
        ],
        phones_numbers: [
          {
            phone: fieldsClone.phone,
            is_mobile: fieldsClone.is_mobile,
            is_primary: fieldsClone.is_primary,
            marketing_sms_opt_in: fieldsClone.marketing_sms_opt_in,
          },
        ],
        emails: [
          {
            email: fieldsClone.email,
          },
        ],
        email: fieldsClone.email,
        username: fieldsClone.email,
      };

      if(accountType === 'installer'){
        stateAPI = {
          ...stateAPI,
          company: fieldsClone.company,
          installerKey: fieldsClone.installerKey,
        }
      }

      let response: any = await ConnectionBroker.fetchAPI(
        'jitBE',
        'profile.update',
        stateAPI,
        null
      );

      if (response.result) {
        const { last_name, first_name, picture } = response.body;
        updateUser({ user: { last_name, first_name, picture } });
        Notification({
          title: 'Successfully',
          message: 'your profile has been updated',
          type: 'success',
        });
      }

      setLoading(false);
    },
    // eslint-disable-next-line
    [fieldsState, config, accountType],
  );



  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    return () => {};
  }, []);


  return (
    <div id="profile-main-container" className="card-body">
      <div className="row">
        {Object.keys(CONFIG_FIELDS).map((name: string, index: number) => {
          switch (CONFIG_FIELDS[name].type) {
            case 'dropdown':
              return (
                <DropdownFilter
                  key={`dropdown--${index}`}
                  handleChange={(value: any) =>
                    setFieldsState((prevState: any) => ({
                      ...prevState,
                      [name]: value,
                    }))
                  }
                  error={errors[name]}
                  value={fieldsState[name]}
                  disabled={editMode}
                  {...CONFIG_FIELDS[name]}
                />
              );
            case 'phone':
              return (
                <InputPhone
                  key={`phone--${index}`}
                  handleChange={(value: any) =>
                    setFieldsState((prevState: any) => ({
                      ...prevState,
                      [name]: value,
                    }))
                  }
                  handleChangeError={(err: any) =>
                    setErrors((prevState: any) => ({
                      ...prevState,
                      [name]: err,
                    }))
                  }
                  name={name}
                  value={fieldsState[name]}
                  error={errors[name]}
                  constraint={CONFIG_CONSTRAINTS[name]}
                  fieldsState={fieldsState}
                  disabled={!editMode}
                  {...CONFIG_FIELDS[name]}
                />
              );
            case 'text-option':
              if(accountType === 'installer'){
                return (
                  <Input
                    key={`input--${index}`}
                    handleChange={(value: any) =>
                      setFieldsState((prevState: any) => ({
                        ...prevState,
                        [name]: value,
                      }))
                    }
                    handleChangeError={(err: any) =>
                      setErrors((prevState: any) => ({
                        ...prevState,
                        [name]: err,
                      }))
                    }
                    type={CONFIG_FIELDS[name].type}
                    cols={CONFIG_FIELDS[name].cols}
                    label={CONFIG_FIELDS[name].label}
                    name={name}
                    maximum={CONFIG_FIELDS[name].maximum}
                    value={fieldsState[name]}
                    error={errors[name]}
                    autoFocus={CONFIG_FIELDS[name].autofocus}
                    constraint={CONFIG_CONSTRAINTS[name]}
                    disabled={!editMode}
                    classname={CONFIG_FIELDS[name].classname}
                  />
                );
              }
              return null;
            default:
              return (
                <Input
                  key={`input--${index}`}
                  handleChange={(value: any) =>
                    setFieldsState((prevState: any) => ({
                      ...prevState,
                      [name]: value,
                    }))
                  }
                  handleChangeError={(err: any) =>
                    setErrors((prevState: any) => ({
                      ...prevState,
                      [name]: err,
                    }))
                  }
                  type={CONFIG_FIELDS[name].type}
                  cols={CONFIG_FIELDS[name].cols}
                  label={CONFIG_FIELDS[name].label}
                  name={name}
                  maximum={CONFIG_FIELDS[name].maximum}
                  value={fieldsState[name]}
                  error={errors[name]}
                  autoFocus={CONFIG_FIELDS[name].autofocus}
                  constraint={CONFIG_CONSTRAINTS[name]}
                  disabled={!editMode}
                  classname={CONFIG_FIELDS[name].classname}
                />
              );
          }
        })}
      </div>
      <div className="row mt-3">
        <div className="col-12 d-flex justify-content-between">
          <div className="buttons-wrapper">
            {editMode ? (
              <div>
                <Button
                  variant="secondary"
                  onClick={() => cancelEditProfile()}
                  className=" mr-2"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  className="btn  btn-primary "
                  type="button"
                  disabled={loading}
                  onClick={() => handleSubmit()}
                >
                  {config.strings.confirm}
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  onClick={() => history.push(`/${rootPathCtrl(accountType)}/profile/update-password`)}
                  className="mr-2"
                >
                  Change Password
                </Button>
                <Button onClick={() => setEditMode(true)}>Edit Profile</Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex  justify-content-end pt-4"></div>
    </div>
  );
};

export default ProfilePassword;
