import React, { useState, useEffect } from 'react';
import config from './config';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { NoResults, Loading, Circle, ListItem } from './components';
import { HiOutlineXCircle } from 'react-icons/hi';
import { FaUserAltSlash } from 'react-icons/fa';
import './styles.scss';

interface SearchResultsProps {
  resultsFromUserSearch: any;
  resultsAreLoaded: boolean;
  getDetailsAboutAdmin: Function;
  getUserData: Function;
  defSearchTerm: Function;
  setCurrentUser: Function;
  defUser: Function;
  storeClickedUser: Function;
}

const SearchResults: React.FC<SearchResultsProps> = ({
  resultsFromUserSearch,
  resultsAreLoaded,
  getDetailsAboutAdmin,
  getUserData,
  defSearchTerm,
  setCurrentUser,
  defUser,
  storeClickedUser,
}) => {
  const history = useHistory();
  const [accDetailsLoaded, setAccDetailsLoaded] = useState<boolean>(false);

  const showAccountDetailsAndRedirect = async (
    userId: string,
    isAdmin: boolean,
  ) => {
    const dmsBtn = document && document.getElementById('dismiss-mdl-btn');
    let admSearch;
    let userSearch;

    if (isAdmin) {
      admSearch = await getDetailsAboutAdmin(userId);
    } else {
      userSearch = await getUserData(userId);
    }

    if (admSearch) {
      setAccDetailsLoaded(true);

      dmsBtn && dmsBtn.click();

      let adminUser: any = admSearch?.body?.data;

      storeClickedUser(adminUser);

      history.push(
        `/admin/dashboard/admin-user-details/${adminUser?.account_id}`,
        {
          id: adminUser?.account_id,
          user: adminUser,
        },
      );
    }
    if (userSearch) {
      setAccDetailsLoaded(true);
      //redirects to user profile:
      dmsBtn && dmsBtn.click();

      let user: any = userSearch?.body?.data;

      setCurrentUser(user); //define the user in order to redirect to the correct page, if div clicked
      defUser(user);

      history.push(`/admin/dashboard/user-details`, { user });
    }
  };

  useEffect(() => {
    let el: any = document.getElementById('modal-content-results');

    if (el) {
      document.addEventListener('click', event => {
        let et: any = event.target;

        if (el && !el.contains(et)) {
          defSearchTerm('');
        } else {
        }
      });
    }
  }, []);

  return (
    <div
      className="modal fade"
      id={`search-results`}
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLongTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content" id="modal-content-results">
          <div className="modal-header modal--header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              {config.strings.h5}
            </h5>

            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              id={`dismiss-mdl-btn`}
              onClick={() => {
                defSearchTerm('');
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body mb-style-modal-body">
            {!resultsAreLoaded ? (
              <Loading />
            ) : resultsAreLoaded && resultsFromUserSearch.length === 0 ? (
              <NoResults
                icon={<FaUserAltSlash className="icon-slash" />}
                message={config.strings.nrMessage}
                title={config.strings.title}
              />
            ) : (
              resultsAreLoaded &&
              resultsFromUserSearch.map((item: any, index: number) => {
                return (
                  <>
                    <div
                      className="itm"
                      key={index}
                      onClick={() =>
                        showAccountDetailsAndRedirect(item.accId, item.isAdmin)
                      }
                    >
                      <div className="container spec-spaces" key={index}>
                        <div className="row spec-spaces no-side-marg list-element">
                          <div className="col-2 left--side1">
                            {/*ignore true avatars for now because some urls have errors */}
                            {/* <img src={item.avatar} /> */}
                            <Circle
                              firstLetter={item.user[0].toUpperCase()}
                              isAdmin={item.isAdmin}
                            />
                          </div>

                          <div className="col sr-styles-pl0">
                            <ListItem item={item} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className="list-separator" />
                  </>
                );
              })
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="small-button dismiss-btn"
              data-dismiss="modal"
            >
              <span className="exit--results--label">
                {' '}
                <HiOutlineXCircle className="cancel-icon001" /> EXIT
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  const { searchUsersState } = state;
  return {
    resultsFromUserSearch: searchUsersState.resultsFromUserSearch,
    resultsAreLoaded: searchUsersState.resultsAreLoaded,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { searchUsersState, adminUsersState, adminState } = dispatch;
  return {
    defSearchTerm: searchUsersState.defSearchTerm,
    getUserData: adminUsersState.getUserData,
    getDetailsAboutAdmin: adminUsersState.getDetailsAboutAdmin,
    setCurrentUser: adminUsersState.setCurrentUser,
    defUser: adminUsersState.defUser,
    storeClickedUser: adminState.storeClickedUser,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
