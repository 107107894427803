import React from 'react';

interface WaitingScreenProps {}

const WaitingScreen: React.FC<WaitingScreenProps> = () => {
  return (
    <div className="">
      <h6 className=""></h6>
      <p className=""></p>

      <div className="">
        <div className="">
          <div className="row">
            <div className="col"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaitingScreen;
