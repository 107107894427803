import React, { useState, useEffect } from 'react';
import { EnterDataProps } from './interface';
import { functions } from './helpers';
import { possibleErrors } from './config';
import { Input } from 'business/modules/admin/common';
import './styles.scss';

const EnterDataBalancedMode: React.FC<EnterDataProps> = ({
  id,
  disabled,
  label,
  checkIfNumeric,
  onChangeValues,
  errors,
  hours,
  userQuitSystemUpdate,
  value,
}) => {
  const [userSelected, setUserSelected] = useState(false);

  const renderErrorMsg = (id: string) => {
    if (id === 'hourStart') {
      return errors?.hourError;
    }
    if (id === 'hourStop') {
      return errors?.endHourError;
    }
    if (id === 'additionalMixTime') {
      return errors?.adMxError;
    } else {
      if (possibleErrors.hasOwnProperty(id)) {
        return errors[id];
      }
    }
  };

  useEffect(() => {
    if (userQuitSystemUpdate) {
      setUserSelected(false);
    }
  }, [userSelected, userQuitSystemUpdate]);

  return (
    <div className="balanced-mode-fields">
      <div className="container white-c">
        <div className={`row set-spec-style1`}>
          <div
            className={`col ${`set-spec-style1`} ${hours ? `pl--hours` : ``}`}
          >
            <div className={`row styles-row1`}>
              <div className={`col-auto additional-label stronger-ft`}>
                <span className="lead smaller-font styles-lead-span-2- stronger-ft">
                  {label} {functions.renderLabel(id)}
                </span>
              </div>
            </div>
            <div className={`row styles-row1`}>
              <div className={`col-auto additional-label stronger-ft`}></div>
            </div>

            <Input
              id=""
              disabled={disabled}
              type={checkIfNumeric}
              className={`form-control`}
              ariaLabel="input-data"
              ariaDescribedBy="basic-addon1"
              onChange={onChangeValues}
              name={id}
              value={value}
            />

            <div className="render-error">{renderErrorMsg(id)} </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterDataBalancedMode;
