import React from 'react';
import config from './config';
import { FaRegCopy } from 'react-icons/fa';
import { GenTooltip, TooltipView } from 'business/modules/admin/common';

const filterRelevantProps = (compItem: any) => {
  let filteredObject: any = {};
  if (Object.keys(compItem).length > 0) {
    let filteredPropsArray = Object.entries(compItem).filter(
      (item: any, val: any) => {
        return (
          item[0] !== 'company_id' && item[0] !== '_id' && item[0] !== '__v'
        );
      },
    );
    filteredPropsArray.forEach((item: any) => {
      filteredObject[item[0]] = item[1];
    });
  }
  return filteredObject;
};

const retrieveColValue = (item: any, position: string) => {
  switch (position) {
    case '1':
      return item.name;
    case '2':
      return `${item.zipcode} , ${item.state}`;
    case '3':
      return (
        <div className="container clipboard-align" id="cnt-clip">
          <div className="row clipboard-align">
            <div className="col-7 clipboard-align">
              <span id={item?.code}>{item?.code}</span>
            </div>
            <div className="col-2 clipboard-align">
              <TooltipView
                title={
                  <GenTooltip
                    title={config.tooltip.title}
                    textOne={config.tooltip.txt}
                  />
                }
              >
                <span>
                  <button
                    data-backdrop="false"
                    id="show-alert"
                    data-toggle="modal"
                    data-target="#copy--clip--launch"
                    type="button"
                  >
                    <FaRegCopy
                      style={{
                        color: '#000000',
                        strokeWidth: '30',
                        stroke: 'white',
                      }}
                      className="copy-to-clipboard-icon"
                      onClick={() =>
                        copyToClipboard(item?.code, 'close--alert--clipboard')
                      }
                    />
                  </button>
                </span>
              </TooltipView>
            </div>
          </div>
        </div>
      );
    case '4':
      return `1`; //waiting for real data about installers
    default:
      return ``;
  }
};

const copyContent = async (copyTxtToClip: any) => {
  try {
    await navigator.clipboard.writeText(copyTxtToClip);
  } catch (err) {
    //handle error copying to clipboard
  }
};

const copyToClipboard = (item: any, id: string) => {
  copyContent(item);

  //automatically open and close alert
  let open = document?.getElementById(id)?.click();
  setTimeout(() => {
    let close = document?.getElementById(id)?.click();
  }, 3000);
};

export const functions = {
  filterRelevantProps: filterRelevantProps,
  retrieveColValue: retrieveColValue,
  copyContent: copyContent,
  copyToClipboard: copyToClipboard,
};
