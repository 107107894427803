import React, { useState } from 'react';
import { IconsHandler } from '../../../../../../../../../core/utils';
import { FlowSymbol, HumiditySymbol, TemperatureSymbol } from './components';
import './styles.scss';


interface DetailsChipProps {
  id?: string;
  type?: string;
  label?: string;
  value?: any;
  scale?: string;
}

const DetailsChip: React.FC<DetailsChipProps> = ({
  id,
  type,
  label,
  value,
  scale,
  ...props
}) => {

  const Component: any = ({className}: any) => {
    const IconComponent: any = IconsHandler.sensorTypeToIcon(type || '');
    return <IconComponent className={className} />;
  };

  const getTypeLabel = (type: string, value: any, scale: string) => {
    if (value !== null && value !== undefined && value !== '') {
      switch (type) {
        case 'humidity':
          return <HumiditySymbol value={value} />;
        case 'status':
        case 'fan':
          return value ? 'On' : 'Off';
        case 'temp':
          return <TemperatureSymbol value={value} scale={scale ? scale : 'fahrenheit'} />;
        case 'flow':
          return <FlowSymbol value={value} />;
        case 'voc':
        case 'o3':
          return `${value} ppb`;
        case 'radon':
          return `${value} bqm3`;
        case 'co2':
          return `${value} ppm`;
        case 'pm25':
          return `${value} ug/m3`;
        default:
          return value;
      }
    }
    return 'N/A';
  };

  return (
    <div id={id} className="ac-details-chip--container">
      <label>{label ? label : ''}</label>
      <div className="ac-details-chip--wrapper">
        {Component && <Component className={`ac-details-chip--icon ${type}`} />}
        <span className="ac-details-chip--value">
          {getTypeLabel(type || '', value, scale || '')}
        </span>
      </div>
    </div>
  );
};

export default DetailsChip;
