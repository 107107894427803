import { ConnectionBroker } from '../../core';

export const profile = {
  state: {
    user: null,
  },
  reducers: {
    updateState(state: any, payload: any, name: string) {
      return { ...state, [name]: payload };
    },
  },
  effects: (dispatch: any) => ({
    async loadProfile() {
      const self: any = this;
      const response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'profile.show',
        {},
        null,
      );
      if (response?.statusCode === 200) {
        self.updateState(response.body, 'user');
      }
      return response;
    },
    async resetProfile() {
      const self: any = this;
      self.updateState(null, 'user');
    },

  }),
};
