import React from 'react';
import { InputProps } from './interface';

const Input: React.FC<InputProps> = ({
  type,
  id,
  ariaDescribedBy,
  placeholder,
  value,
  name,
  onChange,
  disabled,
  className,
  ariaLabel,
}) => {
  return (
    <input
      type={type}
      className={`form-control ${className}`}
      id={id}
      aria-describedby={ariaDescribedBy}
      placeholder={placeholder}
      value={value}
      name={name}
      onChange={onChange}
      disabled={disabled}
      aria-labelledby={ariaLabel}
    />
  );
};
export default Input;
