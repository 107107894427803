import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FcDataConfiguration } from 'react-icons/fc';
import SweetAlert from 'react-bootstrap-sweetalert';
import { InstallerTemplate } from 'business/modules/common';
import { List, Notification } from 'react-components';
import { ActionsMenu, EmptyList } from './components';
import _ from 'lodash';
import config from './config';
import './styles.scss';


const DEFAULT_STRS: any = { ...config.strings };
const DEFAULT_ALERTS: any = { ...config.alerts };
const DEFAULT_NOTIFICATIONS: any = { ...config.notifications };

interface LoadingProps {
  start: Function;
  stop: Function;
}

interface ConfigurationsListProps {
  isLoading?: boolean;
  loading: LoadingProps;
  allConfigs: any;
  listAllConfigs: Function;
  deleteConfig: Function;
  storeId: Function;
}

const ConfigurationsList: React.FC<ConfigurationsListProps> = ({
  loading,
  storeId,
  ...props
}) => {
  const history = useHistory();
  const [configsList, setConfigsList]: any = useState([]);
  const [listConfig, setListConfig]: any = useState({
    text: ['name'],
    createdAt: { type: 'date', format: 'MM-DD-YYYY' },
  });
  const [configToDelete, setConfigToDelete]: any = useState();
  const [showDeleteAlert, setShowDeleteAlert]: any = useState(false);



  const onAddConfig = () => {
    history.push(`/installer/configurations/add`);
  };

  const onEditConfig = (row: any, event: any) => {
    const { config_id }: any = row || {};

    storeId(config_id);
    history.push(`/installer/configurations/edit?id=${config_id}`);
  };

  const handleLoadConfigs = async () => {
    loading.start('Loading Configurations...');
    await props.listAllConfigs();
    setTimeout(() => {
      loading.stop();
    }, 1000);
  };

  const onDeleteConfig = (row: any, event: any) => {
    setConfigToDelete(row);
    setShowDeleteAlert(true);
    event();
  };

  const onDeleteConfirmed = async () => {
    setShowDeleteAlert(false);
    const { config_id }: any = configToDelete || {};

    if (config_id) {
      loading.start('Deleting configuration...');
      setConfigToDelete();
      await props.deleteConfig({ id: config_id });
      setTimeout(() => {
        loading.stop();
      }, 1000);
    }
  };

  useEffect(() => {
    if (props?.allConfigs && props?.allConfigs.length > 0) {
      setConfigsList(_.cloneDeep(props.allConfigs));
    }
  }, [props?.allConfigs]);

  useEffect(() => {
    handleLoadConfigs();

    return () => {
      setConfigToDelete();
    };
  }, []);

  return (
    <InstallerTemplate id="installer-config-list" title={DEFAULT_STRS.title}>
      <div className="installer-clist-wrapper">
        <div className="installer-clist--btn-container">
          <div className="inst-btn-sub-container">
            <button
              type="button"
              className="small-btn btn-rounded"
              onClick={onAddConfig}
            >
              + Add Configuration
            </button>
          </div>
        </div>
        <div className="installer-clist--list-container">
          {configsList && configsList.length > 0 ? (
            <List
              name="configs-list"
              items={configsList}
              onRowClick={onEditConfig}
              config={listConfig}
              status={false}
              rowType="device"
              RowIcon={FcDataConfiguration}
              HeaderComponent={(_props: any) => {
                return <></>;
              }}
              Component={(props: any) => {
                return (
                  <ActionsMenu
                    {...props}
                    onDelete={onDeleteConfig}
                    onEdit={onEditConfig}
                  />
                );
              }}
            ></List>
          ) : (
            <EmptyList
              title="configurations"
            />
          )}
        </div>
      </div>
      <SweetAlert
        danger
        show={showDeleteAlert}
        showCancel
        confirmBtnText={DEFAULT_ALERTS?.delete?.btn?.confirm}
        confirmBtnBsStyle="danger"
        title={`${DEFAULT_ALERTS?.delete?.question} "${configToDelete?.name}"?`}
        onConfirm={onDeleteConfirmed}
        onCancel={() => setShowDeleteAlert(false)}
        focusCancelBtn
      >
        {DEFAULT_ALERTS?.delete?.msm}
      </SweetAlert>
    </InstallerTemplate>
  );
};

function mapDispatchToProps(dispatch: any) {
  const { loader, installerConfigs }: any = dispatch || {};
  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    listAllConfigs: installerConfigs.listAllConfigurations,
    deleteConfig: installerConfigs.deleteConfiguration,
    storeId: installerConfigs.storeId,
  };
}

function mapStateToProps(state: any) {
  const { loader, installerConfigs }: any = state || {};
  return {
    isLoading: loader.loading,
    allConfigs: installerConfigs.allConfigs,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationsList);
