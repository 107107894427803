export default {
  strings: {
    title: `Request for account deletion`,
    firstP: `In this section, you may submit a request to delete your AirCycler
      account and personal associated data, which are stored on our system.
      After sending your request, you will receive an e-mail message for
      confirmation. After that validation, we will proceed on deleting the
      account and the related data.`,
    secondP: `Your personal data will be completely deleted, but devices associated
      data will be kept.`,
    bold: `Note: Please consider that the request may take up to 48 hours to
      be concluded after your confirmation via e-mail.`,
  },
};
