import React from 'react';
import {
  RangeTime,
  PeripheralOptional
} from '../';
import { OperationTimeProps } from './interface';
import './styles.scss';


const OperationTime: React.FC<OperationTimeProps> = ({
  fieldsState,
  onChangeState,
  errors,
  config,
  hOpClass,
}) => {

  return (
    <div className="row">
      <div className="col-12 user-edit-configs--op-time">
        <div className={hOpClass}>
          <PeripheralOptional
            id="hroa"
            label="Hours of operation"
            value={fieldsState?.hroa ? fieldsState?.hroa : 0}
            options={[
              { value: 1, label: 'Yes' },
              { value: 0, label: 'No' },
            ]}
            onChange={(event: any) => {
              onChangeState('hroa', parseInt(event.target.value));
            }}
          />
          {fieldsState?.hroa === 1 && (
            <RangeTime
              onChangeState={onChangeState}
              errors={errors}
              fieldsState={fieldsState}
              config={config}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OperationTime;
