const fillListWithFilteredPpl = (
  originalData: any,
  origin: string,
  destArray: any,
) => {
  if (origin === 'users') {
    originalData.body.data.forEach((elem: any) => {
      destArray.push({
        accId: elem.account_id,
        email: elem.emails.length > 0 ? elem.emails[0].email : '',
        user: elem.first_name + ' ' + elem.last_name,
        avatar: getAvatar(elem.picture),
        isAdmin: false,
      });
    });
  } else if (origin === 'admins') {
    originalData.body.data.forEach((elem: any) => {
      destArray.push({
        accId: elem.account_id,
        email: elem.email,
        user: elem.username,
        avatar: '',
        isAdmin: true,
      });
    });
  }
  return destArray;
};

const getAvatar = (data: any) => {
  if (!data) {
    return '';
  } else if (data?.profile) {
    return data?.profile?.url;
  } else {
    return '';
  }
};

export const functions = {
  fillListWithFilteredPpl: fillListWithFilteredPpl,
  getAvatar: getAvatar,
};
