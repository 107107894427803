import { ConnectionBroker } from '../../core';

export const adminUsersState = {
  state: {
    userAndDevsQuantityList: [],
    userAndDevQtyListLoaded: false,
    currentUser: '',
    userAdminHasSelected: '',
    userData: {},
    userDataIsLoaded: false,
    profileDataFieldsDisabled: true,
    usersList: [],
    usersListIsLoaded: false,
    listOfAdminUsers: [],
    admUsersAreLoaded: false,
    newUser: { email: '', password: '', typeOfUser: '' },
    userCleared: true,
    mandatoryFieldsMissing: true,
    userStartedToType: false,
    errors: { mandatoryFieldsMissing: 'Please fill all the required data.' },
    userCreated: false,
    messages: { createdUser: 'You have successfully created a new user.' },
    devicesPerUser: [],
    devicesPerUserAreLoaded: false,
    adminUserData: {},
    uListLoadingIsComplete: false,
    thereIsAnExpandedAccordion: false,
    thereIsAnAdminExpandedAccordion: false,
    adminUserClicked: {},
    adminsOrderedInsideProfile: false,
    unorderedAdmList: [],
    unorderedIsLoaded: false,
    currentUsersListOrder: 'default',
    userToDelete: {},
    successfullyDeleted: false,
    peripheralsCount: [],
    filledIds: false,
    //handle bad responses:
    loadingErrors: {
      usersListErr: ``,
      devicesPerUserErr: '',
      userDataErr: '',
    },
    orders: {
      orderByLocation: false,
      orderByCount: false,
      orderByName: false,
    },
    intendedCounter: {
      name: 0,
      location: 0,
      count: 0,
    },
    counter: 0,
    modalMustClose: false,
    orderedList: false,
    user: {},
    updatingUser: {
      last_name: '',
      first_name: '',
      picture: '',
      email: '',
      phone: '',
      address_line_one: '',
      address_line_two: '',
      zip_code: '',
      city: '',
      state: '',
    },
    enteredUserPage: false,
    adminsOrderedList: false,
    adminIntendedCounter: {
      email: 0,
      status: 0,
    },
    adminCounter: 0,
    adminOrders: {
      orderByUserName: false,
      orderByStatus: false,
    },
    admModalMustClose: false,
    eachUserDevs2: {},
    eachUserDevsLoaded2: false,
  },
  reducers: {
    defDevsByUser2(state: any, payload: any) {
      return {
        ...state,
        eachUserDevs2: {
          ...state.eachUserDevs2,
          [payload.id]: payload.array,
        },
      };
    },
    markDevsLoaded2(state: any, payload: boolean) {
      return {
        ...state,
        eachUserDevsLoaded2: payload,
      };
    },

    handleAdmModalState(state: any, payload: any) {
      return {
        ...state,
        admModalMustClose: payload,
      };
    },
    updateAdmOrderState(state: any, payload: string) {
      switch (payload) {
        case 'Users':
          return {
            ...state,
            adminsOrderedList: false,
            adminOrders: {
              orderByUserName: true,
              orderByStatus: false,
            },
          };
        case 'Status':
          return {
            ...state,
            adminsOrderedList: false,
            adminOrders: {
              orderByUserName: false,
              orderByStatus: true,
            },
          };
        case 'Default':
          return {
            ...state,
            adminsOrderedList: payload,
            adminOrders: {
              orderByUserName: false,
              orderByStatus: false,
            },
          };
        default:
          return {
            ...state,
            adminsOrderedList: payload,
            adminOrders: {
              orderByUserName: false,
              orderByStatus: false,
            },
          };
      }
    },
    setEnteredInUserPage(state: any, payload: boolean) {
      return {
        ...state,
        enteredUserPage: payload,
      };
    },
    clearDevs(state: any) {
      return {
        ...state,
        devicesPerUser: [],
        devicesPerUserAreLoaded: false,
      };
    },
    setUser(state: any, payload: any) {
      return {
        ...state,
        updatingUser: payload,
      };
    },
    defUser(state: any, payload: any) {
      let stringifiedUser: any = JSON.stringify(payload);

      localStorage.setItem('user', stringifiedUser);

      return {
        ...state,
        user: payload,
      };
    },
    handleModalState(state: any, payload: any) {
      return {
        ...state,
        modalMustClose: payload,
      };
    },

    fillListWithDevQtyAndUserId(state: any, payload: any) {
      return {
        ...state,
        userAndDevsQuantityList: state.userAndDevsQuantityList.concat(payload),
      };
    },
    setIDAndDevListLoaded(state: any, payload: boolean) {
      return {
        ...state,
        userAndDevQtyListLoaded: payload,
      };
    },
    updateOrderState(state: any, payload: string) {
      switch (payload) {
        case 'Name':
          return {
            ...state,
            orderedList: false,
            orders: {
              orderByLocation: false,
              orderByCount: false,
              orderByName: true,
            },
          };
        case 'Location':
          return {
            ...state,
            orderedList: false,
            orders: {
              orderByLocation: true,
              orderByCount: false,
              orderByName: false,
            },
          };
        case 'Devices count':
          return {
            ...state,
            orderedList: false,
            orders: {
              orderByLocation: false,
              orderByCount: true,
              orderByName: false,
            },
          };
        case 'Default':
          return {
            ...state,
            orderedList: true,
            orders: {
              orderByLocation: false,
              orderByCount: false,
              orderByName: false,
            },
          };
        default:
          return {
            ...state,
            orderedList: payload,
            orders: {
              orderByLocation: false,
              orderByCount: false,
              orderByName: false,
            },
          };
      }
    },
    handleCounterAndListOrder(state: any, payload: any, counter: number) {
      return {
        ...state,
        intendedCounter: payload,
        counter: counter,
      };
    },
    handleAdminListCounterAndOrder(state: any, payload: any, counter: number) {
      return {
        ...state,
        adminIntendedCounter: payload,
        adminCounter: counter,
      };
    },
    setToDelete(state: any, payload: any) {
      return {
        ...state,
        userToDelete: payload,
      };
    },
    reorderUsersList(state: any, payload: string) {
      return {
        ...state,
        currentUsersListOrder: payload,
      };
    },
    saveUnorderdAAdmList(state: any, payload: any) {
      return {
        ...state,
        unorderedAdmList: payload,
        unorderedIsLoaded: true,
      };
    },
    updateAdmOrderStateInsideProfile(state: any, payload: boolean) {
      return {
        ...state,
        adminsOrderedInsideProfile: payload,
      };
    },
    selectAdminUser(state: any, payload: any) {
      return {
        ...state,
        adminUserClicked: payload,
      };
    },
    manageAdminListAccordionState(state: any, payload: boolean) {
      return {
        ...state,
        thereIsAnAdminExpandedAccordion: payload,
      };
    },
    manageListAccordionState(state: any, payload: boolean) {
      return {
        ...state,
        thereIsAnExpandedAccordion: payload,
      };
    },
    updateLoadingState(state: any, payload: any) {
      if (payload.update === 'users') {
        return {
          ...state,
          uListLoadingIsComplete: payload.state,
        };
      } else if (payload.update === 'adm') {
        return {
          ...state,
        };
      } else {
        return {
          ...state,
        };
      }
    },
    setCurrentUser(state: any, payload: any) {
      localStorage.setItem('currentAccount', payload.account_id);

      if (payload) {
        return { ...state, currentUser: payload };
      } else {
        return { ...state };
      }
    },
    saveUserData(state: any, payload: any) {
      localStorage.setItem('currentAccount', payload.account_id);
      localStorage.setItem('first_name', payload.first_name);
      localStorage.setItem('last_name', payload.last_name);
      return {
        ...state,
        userData: payload,
        userAdminHasSelected: payload.account_id,
        userDataIsLoaded: true,
      };
    },
    cancelUserProfileUpdates(state: any, payload: any) {
      if (payload) {
        return { ...state, profileDataFieldsDisabled: payload };
      }
      return { ...state };
    },
    editUserProfile(state: any, payload: any) {
      return { ...state, profileDataFieldsDisabled: payload };
    },
    saveUsersList(state: any, payload: any) {
      return {
        ...state,
        usersList: payload,
        usersListIsLoaded: true,
      };
    },
    saveListOfAdminUsers(state: any, payload: any) {
      return {
        ...state,
        listOfAdminUsers: payload,
        admUsersAreLoaded: true,
      };
    },
    saveNewUser(state: any, payload: any) {
      return { ...state, newUser: payload, userCleared: false };
    },
    clearNewUser(state: any) {
      return {
        ...state,
        userCleared: true,
        newUser: { email: '', password: '', typeOfUser: '' },
      };
    },
    setUserIsTyping(state: any, payload: any) {
      return {
        ...state,
        userStartedToType: payload,
      };
    },
    fillPeriphArr(state: any, payload: any) {
      let theIDS: any = [];

      payload.forEach((elem: any) => {
        theIDS.push({ [elem]: [] });
      });

      return {
        ...state,
        peripheralsCount: theIDS,
        filledIds: true,
      };
    },
    resetFillIDS(state: any, payload: boolean) {
      return {
        ...state,
        filledIds: payload,
      };
    },
    saveListOfDevicesPerUser(state: any, payload: any) {
      let ids: any = payload.map((elem: any) => elem.device_id);
      return {
        ...state,
        devicesPerUser: payload,
        devicesPerUserAreLoaded: true,
      };
    },
    storePeripheralsCount(state: any, payload: any) {
      let currPeriphCountCopy: any;
      let foundResult: any;

      //if ids list is already filled
      if (state.filledIds) {
        currPeriphCountCopy = state.peripheralsCount;

        currPeriphCountCopy.map((item: any) => {
          if (item.hasOwnProperty(payload.device_id)) {
            foundResult = item;
          }
          return foundResult;
        });

        foundResult[payload.device_id] = payload.cdvs;

        currPeriphCountCopy.forEach((elem: any, index: number) => {
          Object.entries<any>(elem).map(([key, value], i) => {
            if (foundResult && Object.keys(foundResult)[0] === key) {
              currPeriphCountCopy.splice(index, 1, foundResult);
            }
          });
        });
      }
      return {
        ...state,
        peripheralsCount: currPeriphCountCopy,
      };
    },
    saveAdminUserData(state: any, payload: any) {
      return {
        ...state,
        adminUserData: payload,
      };
    },
    handleErr(state: any, payload: any) {
      if (payload === 'devicesPerUserErr') {
        return {
          ...state,
          loadingErrors: {
            ...state.loadingErrors,
            devicesPerUserErr: 'Something went wrong. Please try again later!',
          },
        };
      }
      if (payload === 'usersListErr') {
        return {
          ...state,
          loadingErrors: {
            ...state.loadingErrors,
            usersListErr: 'Something went wrong. Please try again later!',
          },
        };
      }
      if (payload === 'userDataErr') {
        return {
          ...state,
          loadingErrors: {
            ...state.loadingErrors,
            userDataErr:
              'Something went wrong while getting user data. Please try again later!',
          },
        };
      }

      return {
        ...state,
      };
    },
    setDeleted(state: any, payload: boolean) {
      return { ...state, successfullyDeleted: payload };
    },
  },
  effects: (dispatch: any) => ({
    async getUserData(accountId: any) {
      const that: any = this;
      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        `admin.showUser`,
        null,
        { id: accountId },
      );

      if (response?.statusCode !== 200) {
        that.saveUserData({
          account_id: '',
          addresses: [],
          api_key: '',
          details: { role: '', customer: false },
          emails: [],
          first_name: '',
          last_name: '',
          phones_numbers: [],
          picture: { profile: null },
          __v: 0,
          _id: '',
        });
        that.handleErr('userDataErr');
      }

      if (response?.statusCode === 200) {
        that.saveUserData(response?.body?.data);
      }

      return response;
    },
    async getUsersList() {
      const that: any = this;

      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        'admin.adminUsersList',
      );

      if (!response || (response && response?.statusCode !== 200)) {
        that.handleErr('usersListErr');
      }
      if (response?.statusCode === 200) {
        that.saveUsersList(response?.body?.data);
      } else {
      }
      return response;
    },
    async getListOfAdminUsers() {
      const that: any = this;

      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        'admin.adminUsersAdminList',
      );
      if (response?.statusCode === 200) {
        that.saveListOfAdminUsers(response.body.data);
      } else {
        // TODO: add error handler
      }

      return response;
    },
    async createNewUser(newUser: any) {
      const that: any = this;
      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        'admin.adminUsersCreate',
        newUser,
      );
      if (response?.statusCode === 200) {
        that.handleAdmModalState(true);
      } else {
        // TODO: add error handler
        that.handleAdmModalState(true);
      }
      return response;
    },
    async getDevicesPerUser(accountId: any) {
      const that: any = this;

      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        `admin.devicesListPerUser`,
        null,
        { id: accountId },
      );

      if (response?.statusCode === 200) {
        that.saveListOfDevicesPerUser(response?.body?.data);
      } else {
        that.handleErr('devicesPerUserErr');
      }

      return response;
    },
    async getDetailsAboutAdmin(userId: any) {
      const that: any = this;

      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        `admin.adminUsersShow`,
        null,
        { id: userId },
      );
      if (response?.statusCode === 200) {
        that.saveAdminUserData(response?.body?.data);
      } else {
        // TODO: add error handler
      }

      return response;
    },
    async deleteAnUser(user: any) {
      const that: any = this;

      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        'admin.deleteUser',
        user,
      );
      if (response?.statusCode === 200) {
        that.setDeleted(true);
        that.handleModalState(true);
      } else if (response?.statusCode !== 200) {
        //TODO handle error
        that.handleModalState(true);
      }
      return response;
    },
  }),
};
