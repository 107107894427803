export default {
  strings: {
    title: 'Edit System',
    confirm: 'Update',
    cancel: 'Cancel',
  },
  alerts: {
    update: {
      title: 'Are you sure you want to update system?',
    },
  },
  notifications: {
    update: {
      msm: 'System updated successfully!',
    },
  },
  fieldsState: {
    category_id: null,
    name: '',
    details: {},
    parent: '',
    items: [],
  },
  fieldsFocus: {
    name: false,
    system_state: {},
    system_city: {},
    system_zipcode: {}
  },
  fields: {
    name: {
      label: 'System Name',
      type: 'text',
      placeholder: 'System Name',
    },
    system_state: {
      label: 'System Location - State',
      type: 'dropdown',
      title: 'Enter State',
      placeholder: 'Device state...',
      cols: {
        col: 6,
        sm: 12,
        md: 12,
        lg: 6,
        xl: 6,
      },
    },
    system_city: {
      label: 'System Location - City',
      type: 'dropdown',
      title: 'Enter City',
      placeholder: 'System city...',
      cols: {
        col: 6,
        sm: 12,
        md: 12,
        lg: 6,
        xl: 6,
      },
    },
    system_zipcode: {
      label: 'System Location - Zip Code',
      type: 'dropdown',
      title: 'Enter zip code',
      placeholder: 'System zip code...',
      cols: {
        col: 6,
        sm: 12,
        md: 12,
        lg: 6,
        xl: 6,
      },
    },
  },
  constraints: {
    category_id: {
      presence: true,
    },
    name: {
      presence: true,
      length: {
        minimum: 3,
      },
    },
    system_state: {
      presence: true,
      length: (
        _value: any,
        attributes: any,
        attributeName: any,
        options: any,
        constraints: any,
      ) => {
        const {
          countryCode,
          isoCode,
          label,
          latitude,
          longitude,
          name,
          value,
        } = _value;
        return !(
          countryCode &&
          isoCode &&
          label &&
          latitude &&
          longitude &&
          name &&
          value
        );
      },
    },
    system_city: {
      presence: true,
      length: (
        _value: any,
        attributes: any,
        attributeName: any,
        options: any,
        constraints: any,
      ) => {
        const {
          countryCode,
          label,
          latitude,
          longitude,
          name,
          stateCode,
          value,
        } = _value;
        return !(
          countryCode &&
          label &&
          latitude &&
          longitude &&
          name &&
          stateCode &&
          value
        );
      },
    },
    system_zipcode: {
      presence: true,
      length: (
        _value: any,
        attributes: any,
        attributeName: any,
        options: any,
        constraints: any,
      ) => {
        const {
          city,
          country,
          label,
          latitude,
          longitude,
          value,
          zip,
        } = _value;
        return !(
          city &&
          country &&
          label &&
          latitude &&
          longitude &&
          value &&
          zip
        );
      },
    },
  },
};
