import React from 'react';
import config from './config';
import foobot_dd from '../../../../../../../../assets/small/foobot_dd.png';
import airthings from '../../../../../../../../assets/small/airthings.jpg';
import awair from '../../../../../../../../assets/small/awair.png';

const returnPlaceHolder = (elem: string) => {
  let placeholder = [];
  let plc;
  let lower = elem?.match(/[a-z]+|[0-9]+/g);
  let upper = elem?.match(/[A-Z][a-z]+|[0-9]+/g);

  if (lower) {
    placeholder.push(lower[0]);
  }
  if (upper) {
    placeholder.push(upper.join(' '));
  }
  plc = placeholder.join(' ').toLowerCase();
  return plc.charAt(0).toUpperCase() + plc.slice(1);
};

const returnTypes = (type: string, elem?: any) => {
  switch (type) {
    case 'string':
    case 'number':
      return 'text';
    case 'select':
      return 'select';
    case 'boolean':
      return elem && elem === `hoursOfOperation` ? 'radio' : 'switch';
    default:
      return 'text';
  }
};

const checkIfNum = (val: string) => {
  if (config.inputTypes.hasOwnProperty(val)) {
    return config.inputTypes[val as keyof typeof config.inputTypes];
  }
};

const determineIcon = (type: string) => {
  if (type === 'airthings') {
    return (
      <img
        src={airthings}
        style={{ width: '25px', height: '27px', marginRight: '0.2rem' }}
      />
    );
  }
  if (type === 'foobot') {
    return <img src={foobot_dd} />;
  }
  if (type === 'awair-element' || type === 'awair-omni') {
    return (
      <img
        src={awair}
        style={{ width: '24px', height: '26px', marginRight: '0.35rem' }}
      />
    );
  }
};

const returnAdditionalLabel = (element: string) => {
  if (config.fieldsThatRequireAddLabel.includes(element)) {
    return true;
  } else return false;
};

const returnValues = (elem: any, ventSetupValues: any) => {
  return ventSetupValues?.[elem] || '';
};

const handleNoRender = (elem: any, primaryControl: any) => {
  if (
    primaryControl.length === 0 &&
    (elem[0] === 'exhaustFanFlowRate' || elem[0] === 'exhaustFanDelayTime')
  ) {
    return null;
  }
};

const handleCTVal = (elem: any, ventSetupValues: any, primaryControl: any) => {
  return elem[0] === `minutesPerHourSupplyFlow`
    ? ventSetupValues.minutesPerHourSupplyFlow
    : elem[0] === `minutesPerHourExhaustRun`
    ? !primaryControl
      ? ''
      : primaryControl[0] === 'FanConnect'
      ? ventSetupValues.minutesPerHourExhaustRunFC
      : ventSetupValues.minutesPerHourExhaustRunVC
    : elem[0] === `exhaustFanDelayTime`
    ? ventSetupValues.exhaustFanDelayTime
    : '';
};

const handleBMVal = (elem: any, ventSetupValues: any, primaryControl: any) => {
  return elem[0] === `minutesPerHourSupplyFlow`
    ? ventSetupValues.minutesPerHourSupplyFlow
    : elem[0] === `minutesPerHourExhaustRun`
    ? !primaryControl
      ? ''
      : primaryControl[0] === 'FanConnect'
      ? ventSetupValues.minutesPerHourExhaustRunFC
      : ventSetupValues.minutesPerHourExhaustRunVC
    : elem[0] === `exhaustFanDelayTime`
    ? ventSetupValues.exhaustFanDelayTime
    : '';
};

const handleCommonVals = (
  elem: any,
  ventSetupValues: any,
  timeinHoursAndMins: any,
) => {
  return elem[0] === `additionalMixTime`
    ? ventSetupValues.additionalMixTime
    : elem[0] === `currentTime`
    ? timeinHoursAndMins
    : '';
};

const handleElemPlaceholder = (
  elem: any,
  hasVentConnect: boolean | undefined,
  primaryCont: any,
  systemDetails: any,
) => {
  if (elem === 'minutesPerHourExhaustRun') {
    if (
      hasVentConnect &&
      primaryCont &&
      primaryCont[0] === 'FanConnect' &&
      systemDetails.hasOwnProperty('minutesPerHourExhaustRunFC')
    ) {
      return systemDetails['minutesPerHourExhaustRunFC'];
    } else if (
      hasVentConnect &&
      primaryCont &&
      primaryCont[0] !== 'FanConnect' &&
      systemDetails.hasOwnProperty('minutesPerHourExhaustRunVC')
    ) {
      return systemDetails['minutesPerHourExhaustRunVC'];
    }
  }

  if (elem === 'exhaustFanDelayTime') {
    if (
      hasVentConnect &&
      primaryCont &&
      primaryCont[0] === 'FanConnect' &&
      systemDetails.hasOwnProperty(elem)
    ) {
      return systemDetails[elem];
    } else if (
      hasVentConnect &&
      primaryCont &&
      primaryCont[0] !== 'FanConnect' &&
      systemDetails.hasOwnProperty(elem)
    ) {
      return systemDetails[elem];
    }
  } else if (systemDetails.hasOwnProperty(elem)) {
    return systemDetails[elem];
  } else {
    return '';
  }
};

export const functions = {
  returnPlaceHolder: returnPlaceHolder,
  returnTypes: returnTypes,
  checkIfNum: checkIfNum,
  determineIcon: determineIcon,
  returnAdditionalLabel: returnAdditionalLabel,
  returnValues: returnValues,
  handleNoRender: handleNoRender,
  handleCTVal: handleCTVal,
  handleBMVal: handleBMVal,
  handleCommonVals: handleCommonVals,
  handleElemPlaceholder: handleElemPlaceholder,
};
