export default {
  strings: {
    title: 'Edit Device',
    confirm: 'Update Device',
  },
  defaults: {
    state: {
      name: 'Massachusetts',
      isoCode: 'MA',
      countryCode: 'US',
      latitude: '42.40721070',
      longitude: '-71.38243740',
      label: 'Massachusetts',
      value: 'MA',
    },
    city: {
      name: 'Boston',
      countryCode: 'US',
      stateCode: 'MA',
      latitude: '42.35843000',
      longitude: '-71.05977000',
      label: 'Boston',
      value: 'Boston',
    },
  },
  alerts: {
    update: {
      question: 'Are you sure you want to update device?',
      btn: {
        confirm: 'Yes, update it!',
      },
    },
  },
  notifications: {
    update: {
      success: 'Device updated successfully!',
      error: '',
    },
  },
  fieldsState: {
    device_name: null,
    status: null,
    device_state: {},
    device_city: {},
    device_zipcode: {},
  },
  fieldsFocus: {},
  fields: {
    device_name: {
      label: 'Device Name',
      type: 'text',
      placeholder: 'Device Name',
    },
    status: {
      label: 'Device Status',
      type: 'text',
      placeholder: 'Device Status',
    },
    device_state: {
      label: 'Device Location - State',
      type: 'dropdown',
      title: 'Enter State',
      placeholder: 'Device state...',
      cols: {
        col: 6,
        sm: 12,
        md: 12,
        lg: 6,
        xl: 6,
      },
    },
    device_city: {
      label: 'Device Location - City',
      type: 'dropdown',
      title: 'Enter City',
      placeholder: 'Device city...',
      cols: {
        col: 6,
        sm: 12,
        md: 12,
        lg: 6,
        xl: 6,
      },
    },
    device_zipcode: {
      label: 'Device Location - Zip Code',
      type: 'dropdown',
      title: 'Enter zip code',
      placeholder: 'Device zip code...',
      cols: {
        col: 6,
        sm: 12,
        md: 12,
        lg: 6,
        xl: 6,
      },
    },
  },
  constraints: {
    device_name: {
      presence: true,
      length: {
        minimum: 6,
      },
    },
    status: {
      presence: false,
    },
    device_state: {
      presence: true,
      length: (
        _value: any,
        attributes: any,
        attributeName: any,
        options: any,
        constraints: any,
      ) => {
        const {
          countryCode,
          isoCode,
          label,
          latitude,
          longitude,
          name,
          value,
        } = _value;
        return !(
          countryCode &&
          isoCode &&
          label &&
          latitude &&
          longitude &&
          name &&
          value
        );
      },
    },
    device_city: {
      presence: true,
      length: (
        _value: any,
        attributes: any,
        attributeName: any,
        options: any,
        constraints: any,
      ) => {
        const {
          countryCode,
          label,
          latitude,
          longitude,
          name,
          stateCode,
          value,
        } = _value;
        return !(
          countryCode &&
          label &&
          latitude &&
          longitude &&
          name &&
          stateCode &&
          value
        );
      },
    },
    device_zipcode: {
      presence: true,
      length: (
        _value: any,
        attributes: any,
        attributeName: any,
        options: any,
        constraints: any,
      ) => {
        const {
          city,
          country,
          label,
          latitude,
          longitude,
          value,
          zip,
        } = _value;
        return !(
          city &&
          country &&
          label &&
          latitude &&
          longitude &&
          value &&
          zip
        );
      },
    },
  },
  constraints1: {
    device_name: {
      presence: true,
      length: {
        minimum: 6,
      },
    },
    status: {
      presence: false,
    },
  },
};
