import React from 'react';
import { IoCheckmark } from 'react-icons/io5';
import { MdOutlineCancelPresentation } from 'react-icons/md';

export default {
  strings: {
    confirmation: `Are you sure you wish to delete `,
    part: ` , which is part of `,
    warning: `This action is irreversible.`,
  },
  modalStrings: {
    warning: `Information`,
  },
  titles: {
    dev: `Show device details`,
    sens: `Show sensors details`,
    sys: `Delete system`,
    settings: `Details`,
    disconnect: `Disconnect peripheral`,
  },
  icons: {
    dev: <IoCheckmark className="edit" />,
    sens: <IoCheckmark className="edit" />,
    sys: <IoCheckmark className="edit" />,
    cancel: <MdOutlineCancelPresentation className="edit" />,
    settings: <IoCheckmark className="edit" />,
    disconnect: <IoCheckmark className="edit" />,
  },
  bodies: {
    dev: `  `,
    sens: `  `,
    sys: `You are about to delete this system.`,
    settings: ``,
    disconnect: ``,
  },
  labels: {
    dev: ` OK`,
    sens: ` OK`,
    sys: ` OK`,
    settings: ` OK`,
    disconnect: ` OK`,
  },
  bodyClass: {
    dev: `container col-p-0`,
    sens: `container col-p-0`,
    sys: `alert alert-danger`,
    settings: `container col-p-0`,
    disconnect: `container col-p-0`,
  },
  selectData: {
    dev: {
      optionValue: `Select a device`,
      inputClass: `input-group mb-3`,
      selectClass: `custom-select`,
      selectId: `devs_id`,
      title: `Select a device`,
    },
    sens: {
      optionValue: `Select a sensor`,
      inputClass: `input-group mb-3`,
      selectClass: `custom-select`,
      selectId: `sensors_id`,
      title: `Select a sensor`,
    },
  },
  settingsLabels: [
    {
      term: 'arep',
      label: 'Absolute Reactive Energy Pulses',
    },
    {
      term: 'cflw',
      label: 'Current Flow Reading',
    },
    {
      term: 'estt',
      label: 'Energy Read Status',
    },
    {
      term: 'frep',
      label: 'Forward Reactive Energy Pulses',
    },
    {
      term: 'faep',
      label: 'Forward Active Energy Pulses',
    },
    {
      term: 'cpri',
      label: 'Priority',
    },
    {
      term: 'crhr',
      label: 'Relative Humidity',
    },
    {
      term: 'pfct',
      label: 'Power Factor',
    },
    {
      term: 'vrms',
      label: 'RMS Voltage',
    },
    {
      term: 'vfrq',
      label: 'Voltage Frequency',
    },
    {
      term: 'rrep',
      label: 'Reverse Reactive Energy Pulses',
    },
    {
      term: 'pcst',
      label: 'Probe Connection Status',
    },
    {
      term: 'prob',
      label: 'Probe',
    },
    {
      term: 'aaep',
      label: 'Absolute Active Energy Pulses',
    },
    {
      term: 'mapp',
      label: 'Mean AP Power',
    },
    {
      term: 'mnrp',
      label: 'Mean Reactive Power',
    },
    {
      term: 'raep',
      label: 'Reverse Active Energy Pulses',
    },
    {
      term: 'ddia',
      label: 'Air Duct Diameter',
    },
    {
      term: 'ctmp',
      label: 'Current Temperature',
    },
    {
      term: 'irms',
      label: 'RMS Current',
    },
    {
      term: 'dmac',
      label: 'Mac Address',
    },
    {
      term: 'vcct',
      label: 'Control Target Flag',
    },
    {
      term: 'vcdt',
      label: 'Exhaust Fan Delay Time',
    },
    {
      term: 'mvcr',
      label: 'Minutes per Hour Exhaust Run',
    },
    {
      term: 'cdst',
      label: 'Device State',
    },
    {
      term: 'frte',
      label: 'Fan Runtime Elapsed',
    },
    {
      term: 'vcfr',
      label: 'Exhaust Fan Flow Rate',
    },
    {
      term: 'cail',
      label: 'Device Alias',
    },
    {
      term: 'slst',
      label: 'Switch Status Light State',
    },
    {
      term: 'cdid',
      label: 'Device ID',
    },
    {
      term: 'pcbv',
      label: 'PCB Revision',
    },
    {
      term: 'cidx',
      label: 'Device Index Position',
    },
    {
      term: 'vcwm',
      label: 'Exhaust Worker Mode',
    },
    {
      term: 'fmwv',
      label: 'Firmware Version',
    },
    {
      term: 'cdss',
      label: 'Device RSSI',
    },
    {
      term: 'cvoc',
      label: 'Current voc',
    },
    {
      term: 'cco2',
      label: 'Current co2',
    },
    {
      term: 'cp25',
      label: 'Current pm2.5',
    },
    {
      term: 'slbr',
      label: '. . .',
    },
    {
      term: 'mnap',
      label: 'Mean Active Power',
    },
    {
      term: 'phas',
      label: 'Phase Angle',
    },
  ],
  objectLabels: {
    frep: 'Forward Reactive Energy Pulses',
    faep: 'Forward Active Energy Pulses',
    cpri: 'Priority',
    phas: 'Phase Angle',
    cflw: 'Current Flow Reading',
    estt: 'Energy Read Status',
    pfct: 'Power Factor',
    vrms: 'RMS Voltage',
    crhr: 'Relative Humidity',
    vfrq: 'Voltage Frequency',
    rrep: 'Reverse Reactive Energy Pulses',
    pcst: 'Probe Connection Status',
    prob: 'Probe',
    mnap: 'Mean Active Power',
    aaep: 'Absolute Active Energy Pulses',
    mapp: 'Mean AP Power',
    mnrp: 'Mean Reactive Power',
    raep: 'Reverse Active Energy Pulses',
    ddia: 'Air Duct Diameter',
    arep: 'Absolute Reactive Energy Pulses',
    ctmp: 'Current Temperature',
    irms: 'RMS Current',
    dmac: 'Mac Address',
    vcct: 'Control Target Flag',
    vcdt: 'Exhaust Fan Delay Time',
    mvcr: 'Minutes per Hour Exhaust Run',
    cdst: 'Device State',
    frte: 'Fan Runtime Elapsed',
    vcfr: 'Exhaust Fan Flow Rate',
    cail: 'Device Alias',
    slst: 'Switch Status Light State',
    cdid: 'Device ID',
    pcbv: 'PCB Revision',
    cidx: 'Device Index Position',
    vcwm: 'Exhaust Worker Mode',
    fmwv: 'Firmware Version',
    cdss: 'Device RSSI',
    cvoc: 'Current voc',
    cco2: 'Current co2',
    cp25: 'Current pm2.5',
    slbr: '- - - - -',
  },
  loading: [
    {
      item: (
        <div className="spinner-grow" key={0}>
          <span className="sr-only"> </span>
        </div>
      ),
    },

    {
      item: (
        <div className="spinner-grow" key={1}>
          <span className="sr-only"> </span>
        </div>
      ),
    },

    {
      item: (
        <div className="spinner-grow" key={2}>
          <span className="sr-only"> </span>
        </div>
      ),
    },
  ],
};
