import globalConfigs from '../../../../config/config';

export default {
  strings: {
    title: 'Awair Connection',
    confirm: 'Connect',
  },
  setup: {
    auth: globalConfigs?.apis?.awair?.auth,
    redirectURI: globalConfigs?.apis?.awair?.redirectURI,
    clientId: globalConfigs?.apis?.awair?.clientId,
    responseType: 'code',
  },
  alerts: {
    support: 'Not yet supported.'
  },
  notifications: {
    success: 'Successfully connected to Awair.',
  },
  fieldsState: {
    username: '',
    apikey: '',
  },
  fieldsFocus: {},
  fields: {
  },
  constraints: {
  },
};
