export default {
    strings: {
      title: 'Edit Peripheral',
      confirm: 'Update Peripheral'
    },
    alerts: {
      update: {
        question: 'Are you sure you want to update peripheral?',
        btn: {
          confirm: 'Yes, update it!',
        },
      },
    },
    notifications: {
      update: {
        success: 'Peripheral updated successfully!',
        error: ''
      },
    },
    fieldsState: {
      cail: ''
    },
    fields: {
      cail: {
        label: 'Peripheral Name',
        type: 'text',
        placeholder: 'Peripheral Name',
      },
    },
    constraints: {
      cail: {
        presence: true,
        length: {
          minimum: 3,
          maximum: 10,
          message: (value: any, label: any, config: any) => {
            return value !== null && value !== undefined
              ? value.length < 3 ? '^Peripheral Name must have at least 3 charaters.' : '^Peripheral Name should have less than 10 charaters.'
              : '';
          },
        },
      },
    },
}
