import React from 'react';

const Footer: React.FC = () => (
  <footer className="footer text-center text-muted bg-white ">
    All Rights Reserved {'  '}
    <button> RWI</button>.
  </footer>
);

export default Footer;
