import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Profile, ProfilePassword } from './pages';
import { useAuth } from '../sessions/hooks/auth';

const profile_path = 'profile';

const MyProfile: React.FC = ({ ...props }) => {
  const { accountType }: any = useAuth();

  const pathController = (account: any) => {
    switch (account) {
      case 'installer':
        return 'installer';
      case 'customer':
      default:
        return 'dashboard';
    }
  };

  return (
    <>
      <BreadcrumbsItem to={`/${pathController(accountType)}/${profile_path}`}>
        Profile
      </BreadcrumbsItem>
      <Switch>
        <Route
          exact
          path={`/${pathController(accountType)}/${profile_path}`}
          component={Profile}
        />
        <Route
          exact
          path={`/${pathController(accountType)}/${profile_path}/update-password`}
          component={ProfilePassword}
        />
      </Switch>
    </>
  );
};

export default MyProfile;
