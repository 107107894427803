// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import Autosuggest from 'react-autosuggest';
import cn from 'classnames';
import './styles.scss';
import styles from './index.module.scss';

interface DataType {
  value: string;
  label?: string;
}
interface AutosuggestInputProps {
  id?: any;
  label?: string;
  onBlur?: any;
  setFieldsState?: any;
  placeholder?: string;
  handleChange: Function;
  value: boolean;
  type?: string;
  name?: string;
  data?: DataType[];
  cols?: {
    col?: number;
    sm?: number;
    md?: number;
    lg?: number;
    lx?: number;
  };
  error?: string;
  disabled?: boolean;
}

const AutosuggestInput: React.FC<AutosuggestInputProps> = ({
  label,
  handleChange,
  value,
  cols,
  error,
  data,
  type,
  setFieldsState,
  placeholder,
  disabled,
  id,
  onBlur=()=>{},
}) => {
  const [suggestions, setSuggestions]: any = useState([...data]);
  const [filteredSuggestions, setFilteredSuggestions]: any = useState([]);
  const [currentSuggestion, setCurrentSuggestion]: any = useState('');

  const renderSuggestion = (suggest: any) => {
    return <div className="air-suggest--item">{suggest?.label}</div>;
  };

  const onSuggestionsClearRequested = () => {
    setFilteredSuggestions([...suggestions]);
  };

  const getSuggestionValue = (suggest: any) => {
    return suggest?.label;
  };

  const onSuggestionSelected = (
    event: any,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method },
  ) => {
    handleChange({ ...suggestion });
  };

  const onChangeOption = (event: any, { newValue, method }) => {
    if (type === 'numeric') {
      const numericValue = newValue?.replace(/[^0-9]/g, '');
      setCurrentSuggestion(numericValue);
    } else {
      setCurrentSuggestion(newValue);
    }
  };

  const handleOnBlur = (event: any, { highlightedSuggestion }) => {
    let tempSuggestion: any = highlightedSuggestion;
    if (!highlightedSuggestion) {
      tempSuggestion = suggestions.find((sg: any) =>
        sg.label.toLowerCase().includes(currentSuggestion.toLowerCase()),
      );
    }
    if (!tempSuggestion) {
      handleChange({});
      onBlur({});
    } else {
      handleChange(tempSuggestion);
      onBlur(tempSuggestion);
    }
  };

  const onSuggestionsFetchRequested = ({ value, reason }) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    const tempSeggestions: any = suggestions.filter((item: any) =>
      item.label.toLowerCase().includes(inputValue),
    );
    setFilteredSuggestions([...tempSeggestions]);
  };

  useEffect(() => {
    setFilteredSuggestions([...data]);
    return () => {};
  }, []);

  useEffect(() => {
    if (value) {
      setCurrentSuggestion(value);
    }
  }, [value]);

  useEffect(() => {
    setSuggestions([...data]);
  }, [data]);

  return (
    <Col
      sm={cols?.sm || cols?.col}
      md={cols?.md || cols?.col}
      lg={cols?.lg || cols?.col}
      className={cn('form-group')}
    >
      {label && <label className="inputLabel">{label}</label>}
      <div id="air-custom--autosug">
        <Autosuggest
          id={id}
          suggestions={filteredSuggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionSelected={onSuggestionSelected}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={{
            placeholder,
            value: currentSuggestion || '',
            onChange: onChangeOption,
            onBlur: handleOnBlur,
          }}
        />
        {error && (
          <span
            className={cn(styles.input, {
              [styles[`input__span--isError`]]: error,
            })}
          >
            {error}
          </span>
        )}
      </div>
    </Col>
  );
};

export default AutosuggestInput;
