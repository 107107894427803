import React from 'react';
import Aircycler from './Aircycler';


interface DeviceSwitchProps {
  device: any;
}

const DeviceSwitch: React.FC<DeviceSwitchProps> = ({ device, ...props }) => {
  switch (device?.device_type) {
    case 'aircycler':
    default:
      return <Aircycler device={device} {...props} />;
  }
};

export default DeviceSwitch;
