export default {
  strings: {
    title: 'Outside Air',
    stLabel: '@outside-air::',
    noRequests:
      'Add device location (city/zip code) to get local outdoor air quality data and manage incoming air.',
    labels: {
      temp: 'ºF',
      rhum: '% RH',
      cfm: 'CFM',
      airq: 'AQI',
    },
    btnLabel: ` SAVE SETUP`,
  },
  alerts: {
    save: {
      question:
        'Are you sure you want to save the new Outside Air configuration?',
      btn: {
        confirm: 'Yes, save it!',
      },
    },
  },
  notifications: {
    update: {
      success: 'Outside Air setup saved successfully!',
    },
  },
  formats: {
    temp: '0,0',
    hum: '0',
    cfm: '0,0',
    airq: '0,0',
  },
  model: {
    max: -101,
    min: -101,
    current: 0,
    init: false,
  },
  sliderValues: {
    temp: {
      max: 120,
      min: -20,
    },
    hum: {
      max: 100,
      min: 0,
    },
    aqi: {
      max: 500,
      min: 0,
    },
    cfm: {
      max: 200,
      min: 0,
    },
    defaults: {
      temp: { max: 75, min: 10, current: -20 },
      hum: { max: 80, current: 0 },
      aqi: { max: 100, current: 0 },
      cfm: { max: 50, current: 0 },
    },
  },
};
