import React from 'react';
import { NativeSelect } from '@material-ui/core';
import { SelectProps } from './interface';
import config from '../../config';
import './styles.scss';


const CONFIG_FIELDS: any = { ...config.fields };
const VENTILATIONSELECT = [...config.ventilationSelect];

const Select: React.FC<SelectProps> = ({ value, onChange }) => {

  return (
    <div className="form-group" id="calculated-flow-st-select_2">
      <label className="inputLabel">
        {CONFIG_FIELDS?.ventilation_setup?.label}
      </label>
      <div className="configuration--select" id="configuration--select--id">
        <NativeSelect className="select" value={value} onChange={onChange}>
          {VENTILATIONSELECT.map((ventilation: any) => (
            <option key={ventilation?.name} value={ventilation?.name}>
              {ventilation?.name}
            </option>
          ))}
        </NativeSelect>
      </div>
    </div>
  );
};

export default Select;
