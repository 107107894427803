import React from 'react';
import './styles.scss';


interface CustomTabButtonProps {
  id: string;
  title: string;
  onTabClick: any;
}

const CustomTabButton: React.FC<CustomTabButtonProps> = ({
  id,
  title,
  onTabClick,
  ...props
}) => {

  return (
    <button
      id={`${id}-tab`}
      className="nav-link"
      data-bs-toggle="tab"
      data-bs-target={`#${id}`}
      aria-controls={id}
      aria-selected="false"
      role="tab"
      type="button"
      onClick={onTabClick}
    >
      {title}
    </button>
  );
};

export default CustomTabButton;
