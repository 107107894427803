import React from 'react';


interface RefreshTooltipProps {}


const RefreshTooltip: React.FC<RefreshTooltipProps> = (props: any) => {
  return (
    <>
      <span style={{ fontWeight: 'bold' }}>Warning</span>
      <p style={{ marginTop: 5 }}>
        If you have a new Foobot sensor and it still does not appear in the list
        below, please click on the "Refresh" button to update the list,
        otherwise the list will be updated by the system which may not be
        immediate.
      </p>
    </>
  );
};

export default RefreshTooltip;
