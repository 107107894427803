import React from 'react';
import './styles.scss';

interface LabelProps {
  children: any;
}

const Label: React.FC<LabelProps> = ({ children }) => {
  return (
    <>
      <span id="spec-label-breadcrumb-001">{children}</span>
    </>
  );
};

export default Label;
