//@ts-nocheck
import { config } from './config';

const toggleActive = (current: string) => {
  if (config.tabsObject && config.tabsObject.hasOwnProperty(current)) {
    return config.tabsObject[current as keyof typeof config.tabsObject];
  }
};

const connSysReady = (sysWithConnectionInfo: any, systemsQuantity: number) => {
  if (
    sysWithConnectionInfo &&
    systemsQuantity &&
    systemsQuantity === sysWithConnectionInfo.length
  ) {
    return true;
  } else {
    return false;
  }
};

const returnTitle = (section: string) => {
  if (config.sections.hasOwnProperty(section)) {
    return config.sections[section as keyof typeof config.sections];
  }
};

const returnLead = (section: string) => {
  if (config.leads.hasOwnProperty(section)) {
    return config.leads[section as keyof typeof config.leads];
  }
};

const handleAllSensorsList = (
  tempDevices: any,
  foobot: any,
  awair: any,
  airthings: any,
) => {
  let allSensors: any = [];
  if (foobot && foobot.length > 0) {
    allSensors = allSensors.concat(foobot);
  }

  if (airthings && airthings.length > 0) {
    allSensors = allSensors.concat(airthings);
  }

  if (awair && awair.length > 0) {
    allSensors = allSensors.concat(awair);
  }

  if (tempDevices['awair-element'] && tempDevices['awair-element'].length > 0) {
    allSensors = allSensors.concat(tempDevices['awair-element']);
  }

  if (tempDevices['awair-omni'] && tempDevices['awair-omni'].length > 0) {
    allSensors = allSensors.concat(tempDevices['awair-omni']);
  }

  if (
    tempDevices['aircycler-sensor'] &&
    tempDevices['aircycler-sensor'].length > 0
  ) {
    allSensors = allSensors.concat(tempDevices['aircycler-sensor']);
  }

  return allSensors;
};

const isOKResponse = (resp: any) => {
  if (resp && resp.statusCode === 200 && resp?.body?.data) {
    return true;
  } else return false;
};

const handleTimeMessage = (time: any) => {
  let newMsm: any;

  if (time < 60) {
    newMsm = `${time} ${time > 1 ? 'seconds' : 'second'}`;
  } else if (time >= 60) {
    let minCalcs: number = time / 60;
    newMsm = `${parseInt(minCalcs)} ${
      parseInt(minCalcs) > 1 ? 'minutes' : 'minute'
    }`;
  } else if (time >= 60 * 60) {
    let hourCalcs: number = time / 60 / 60;
    newMsm = `${parseInt(minCalcs)} ${
      parseInt(hourCalcs) > 1 ? 'hours' : 'hour'
    }`;
  } else if (time >= 60 * 60 * 24) {
    let daysCalcs: number = time / 60 / 60 / 24;
    newMsm = `${parseInt(daysCalcs)} ${
      parseInt(daysCalcs) > 1 ? 'days' : 'day'
    }`;
  }

  return newMsm;
};

const fillIndoorAirDetails = (sensorsList: any) => {
  let indoorAirDetails: any = [];
  sensorsList.forEach((element: any) => {
    indoorAirDetails.push({
      deviceId: element.device_id, //sensor Id
      pm: element.details.data.datapoints[0][1],
      co2: element.details.data.datapoints[0][4],
      tVoc: element.details.data.datapoints[0][5],
    });
  });

  return indoorAirDetails;
};

const handleSettings = (
  devSett: any,
  sensorsList: any,
  sensorsAreLoaded: boolean,
) => {
  let data = {};
  let checkIfSensorExistsInList: any = [];
  //some additional validation to confront to existing sensors list,
  // just to make sure that we do not pass deleted sensors, and prevent errors

  if (devSett?.body?.data.hasOwnProperty('message')) {
    return;
  } else if (Object.keys(devSett?.body?.data?.settings).length === 0) {
    data = { ...data, sensorName: 'MachineShop' };
    return data;
  } else {
    let _settings: any = Object.entries(devSett?.body?.data?.settings)[0][1];

    if (_settings && _settings.hasOwnProperty('add_sensor_id')) {
      //confront this info with the sensors list because sometimes there are information about already deleted sensors

      if (sensorsAreLoaded) {
        checkIfSensorExistsInList = sensorsList.filter(
          (item: any) => item.device_id === _settings['add_sensor_id'],
        );

        if (checkIfSensorExistsInList.length === 0) {
          data = { ...data, sensorId: '', sensorName: '' };
          return data;
        }
      }
    }

    let ob: any = Object.entries(devSett?.body?.data?.settings)[0][1];

    if (ob.hasOwnProperty('add_sensor')) {
      data = { ...data, sensorName: ob['add_sensor'] };
    }
    if (ob.hasOwnProperty('add_sensor_id')) {
      data = { ...data, sensorId: ob['add_sensor_id'] };
    }

    return data;
  }
};

export const helpers = {
  connSysReady: connSysReady,
  fillIndoorAirDetails: fillIndoorAirDetails,
  isOKResponse: isOKResponse,
  returnTitle: returnTitle,
  returnLead: returnLead,
  toggleActive: toggleActive,
  handleAllSensorsList: handleAllSensorsList,
  handleTimeMessage: handleTimeMessage,
  handleSettings: handleSettings,
};
