import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Breadcrumbs } from 'react-breadcrumbs-dynamic';
import { Breadcrumb as BootstrapBreadcrumb } from 'react-bootstrap';
import { FiChevronLeft } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { CrumbItem } from './components';
import CommandsButtonSection from './components/CommandsButtonSection';
import Label from './components/Label';
import './styles.scss';

interface BreadcrumbProps {
  variant?: string;
  title?: string;
  breadcrumb?: boolean;
  titleBackButton?: boolean;
  titleBackOnClick?: any;
  location?: any;
  id?: string;
  reloadCommandsList?: any;
  reloadSetupLogs?: any;
  addCompany?: Function;
  defineCompanyToEdit: Function;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({
  children,
  title,
  breadcrumb = false,
  titleBackButton = false,
  titleBackOnClick,
  reloadCommandsList,
  reloadSetupLogs,
  id,
  addCompany,
  defineCompanyToEdit,
  ...props
}) => {
  const history = useHistory();

  useEffect(() => {
    return () => {
      //defineCompanyToEdit({});
    };
  }, []);

  return (
    <div className="page-breadcrumb p-0" id="air-breadcrumb">
      <div className="row">
        <div className="col-7 align-self-center">
          <h4 className="page-title text-truncate text-dark font-weight-medium mb-1">
            {titleBackButton && (
              <button
                onClick={
                  !!titleBackOnClick ? titleBackOnClick : () => history.go(-1)
                }
                className="page-title text-truncate text-dark font-weight-medium mb-1"
              >
                <FiChevronLeft />
              </button>
            )}
            {title}
          </h4>

          {id && id === `device-commands` && <Label>Device commands </Label>}
          {id && id === `setup-logs-list` && <Label>Setup logs </Label>}
          {id && id === `companies-list` && <Label>Companies List </Label>}

          <Breadcrumbs
            item={CrumbItem}
            container={BootstrapBreadcrumb}
            finalProps={{ active: true }}
            duplicateProps={{ to: 'href' }}
          />
        </div>

        {id && id === `device-commands` ? (
          <CommandsButtonSection onClick={reloadCommandsList} />
        ) : id && id === `setup-logs-list` ? (
          <CommandsButtonSection onClick={reloadSetupLogs} />
        ) : id && id === `companies-list` ? (
          <CommandsButtonSection typeOfBtn={`add-comp`} onClick={addCompany} />
        ) : (
          <div className="col-5 align-self-center"></div>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  const { adminDevicesState, adminCompaniesState } = state;

  return {};
}

function mapDispatchToProps(dispatch: any) {
  const { adminCompaniesState } = dispatch;

  return {
    defineCompanyToEdit: adminCompaniesState.defineCompanyToEdit,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumb);
