import React from 'react';
import { relevantKeys, icons } from './config';

const returnKey = (key: string) => {
  if (relevantKeys.includes(key)) {
    let edited: string = key[0].toUpperCase().concat(key.substring(1));

    return edited;
  }
};

const returnValue = (val: any, k: string) => {
  if (relevantKeys.includes(k)) {
    return isContact(k) ? { phone: val?.phone, email: val?.email } : val;
  }
};

const isContact = (key: any) => {
  return key === `contact` ? true : false;
};

const returnIcon = (key: any) => {
  if (icons.hasOwnProperty(key)) {
    return icons[key];
  }
  return <></>;
};

export const functions = {
  returnKey: returnKey,
  returnValue: returnValue,
  returnIcon: returnIcon,
  isContact: isContact,
};
