import React from 'react'
import { AuthProvider } from '../modules/sessions/hooks/auth'
import { ToastProvider } from './toast'


const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <ToastProvider>{children}</ToastProvider>
  </AuthProvider>
)

export default AppProvider
