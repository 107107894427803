import React from 'react';
import { FaFan, FaRegLightbulb } from 'react-icons/fa';
import './styles.scss';

interface VentilationStateProps {
  index: number;
  title: string;
  subTitle?: string;
  light?: any;
  fan?: any;
  stoped?: any;
}

const VentilationState: React.FC<VentilationStateProps> = ({
  index,
  title,
  subTitle,
  light,
  fan,
  stoped,
  ...props
}) => {

  return (
    <div key={'conn-' + index} className="vent-conn-wrapper">
      <div className="widgets--title-wrapper">
        <h4 className="widgets--title">{title}</h4>
        <h6 className="widgets--subtitle">{subTitle}</h6>
      </div>
      <div className="icons-container">
        <FaFan className={fan && !stoped ? 'rotate' : ''} size="2.3rem" />
        <div className="light-on-wrapper">
          <FaRegLightbulb className="" size="2.3rem" />
          {(light && !stoped) && <div className="light-on"></div>}
        </div>
      </div>
    </div>
  );
};

export default VentilationState;
