export default {
  strings: {
    add: {
      title: `Add installer`,
      breadcrumbTitle: `Add installer`,
      mainTitle: `Installer details`,
      pText: `Add the installer information.`,
      dataTarget: `#save-changes-to-new-installer`,
    },
  },
  emailRegex: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  zipRegex: '[0-9][0-9\\- ]{0,10}[0-9]$',
  errorMsg: {
    name: 'Name should not be blank.',
    email: 'Invalid e-mail.',
    phone: 'Invalid phone number.',
    state: 'State is too short.',
    zip: 'Invalid zipcode.',
    city: 'City is too short',
  },
  fields: [
    {
      outerClass: 'form-group',
      label: 'Name',
      htmlFor: 'exampleInputEmail1',
      type: 'text',
      id: 'installer-name1',
      ariaDescribedby: 'inputHelp',
      placeholder: 'Enter name',
      className: 'form-control mb-2',
      labelClass: 'lead label-create-user',
      name: 'name',
      disabled: '',
    },
    {
      outerClass: 'form-group',
      label: 'E-mail address',
      htmlFor: 'exampleInputEmail1',
      type: 'email',
      id: 'installer-email1',
      ariaDescribedby: 'emailHelp',
      placeholder: 'Enter e-mail address',
      className: 'form-control mb-2',
      labelClass: 'lead label-create-user',
      name: 'email',
      disabled: '',
    },
    {
      outerClass: 'form-group',
      label: 'Phone number',
      htmlFor: 'exampleInputPassword1',
      type: 'text',
      id: 'telephone-input-spec1',
      ariaDescribedby: ' ',
      placeholder: 'Installer phone number',
      className: 'form-control mb-2',
      labelClass: 'lead label-create-user',
      name: 'phone',
      disabled: '',
    },
    {
      outerClass: 'form-group',
      label: 'State',
      htmlFor: 'exampleInputState',
      type: 'text',
      id: 'state0112',
      ariaDescribedby: 'inputHelp',
      placeholder: 'Enter installer State',
      className: 'form-control mb-2',
      labelClass: 'lead label-create-user',
      name: 'state',
      disabled: '',
    },
    {
      outerClass: 'form-group',
      label: 'City',
      htmlFor: 'exampleInputState',
      type: 'text',
      id: 'city0112',
      ariaDescribedby: 'inputHelp',
      placeholder: 'Enter installer City',
      className: 'form-control mb-2',
      labelClass: 'lead label-create-user',
      name: 'city',
      disabled: '',
    },
    {
      outerClass: 'form-group',
      label: 'Zip Code',
      htmlFor: 'exampleInputZip',
      type: 'text',
      id: 'zip0112',
      ariaDescribedby: 'inputHelp',
      placeholder: 'Enter zip code',
      className: 'form-control mb-2',
      labelClass: 'lead label-create-user',
      name: 'zip',
      disabled: '',
    },
  ],
  constraints: {
    name: {
      presence: true,
      length: {
        minimum: 1,
      },
    },
    email: {
      email: true,
      presence: true,
    },
    state: {
      presence: true,
      length: {
        minimum: 2,
      },
    },
    city: {
      presence: true,
      length: {
        minimum: 2,
      },
    },
    zip: {
      presence: true,
      length: {
        minimum: 5,
        maximum: 10,
      },
      format: {
        pattern: '[0-9][0-9\\- ]{0,10}[0-9]$',
        message: 'is invalid',
      },
    },
    phone: {
      presence: true,
      length: {
        minimum: 11,
        maximum: 11,
      },
    },
  },
  modalStrings: {
    edit: `Save installer`,
    confirm: ``,
    warning: `Information`,
    alertStr: ``,
    alertStr2: ``,
    confirmation: ``,
    sureToProceed: `Are you sure you wish to proceed?`,
    noUndo: ``,
    aboutToUpdate: `You are about to save this new installer.`,
    labelOK: ` OK`,
  },
};
