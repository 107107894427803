// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { WidgetSwitch } from '../../../common';
import './styles.scss';

interface SwitchGroupProps {
  id?: string;
  data?: any;
  ckData?: any;
  device: any;
  onChange: any;
}

const SwitchGroupV2: React.FC<SwitchGroupProps> = ({
  id,
  data,
  ckData,
  ...props
}) => {
  const [orderedEl, setOrderedEl]: any = useState([]);


  const retriveValue = (_type: any, _ckData: any, _id: any) => {
    return _type ? _type === 'damper' || _type === 'fanConnect' || _type === 'centralFan' ? _ckData[_id] : (_ckData && _ckData[_type] && _ckData[_type][_id] ? _ckData[_type][_id] : 0) : 0;
  };

  useEffect(() => {
    if(data && Object.values(data).length > 0){
      let tempEl: any = [];
      if(data?.ventConnect){
        data.ventConnect.forEach((periph: any, i: number) => {
          const { cdid, cail } = periph;
          tempEl.push({
            // name: `VentConnect ${i + 1}`,
            name: `VentConnect`,
            type: 'ventConnect',
            alias: cail,
            id: cdid,
          });
        });
      }

      if(data?.damper){
        tempEl.push({
          name: `Damper Open`,
          type: 'damper',
          alias: null,
          id: 'dmst',
        });
      }

      if(data?.fanConnect){
        tempEl.push({
          name: `FanConnect`,
          type: 'fanConnect',
          alias: null,
          id: 'fanConnect',
        });
      }

      if(data?.pressureConnect){
        data.pressureConnect.forEach((periph: any, i: number) => {
          const { cdid } = periph;
          tempEl.push({
            name: `PressureConnect`,
            type: 'pressureConnect',
            alias: null,
            id: cdid,
          });
        });
      }

      if(data?.freshConnect){
        data.freshConnect.forEach((periph: any, i: number) => {
          const { cdid } = periph;
          tempEl.push({
            name: cdid && cdid.startsWith('AirCyclerFT') ? `FreshConnect` : `FreshConnect`,
            type: 'freshConnect',
            alias: null,
            id: cdid,
          });
        });
      }

      if(data?.centralFan){
        tempEl.push({
          name: `Central Fan`,
          type: 'centralFan',
          alias: null,
          id: 'cfcm',
        });
      }

      setOrderedEl(tempEl);
    }
  }, [data]);

  return (
    <>
      <div id={id} className="switchs--wrapper">
        {
          orderedEl.length > 0 && orderedEl.map((element: any, index: number) => {
            const { id, name, alias, type } = element;
            return (
              <div
                key={`${name}+${index}`}
                className="switchs--wrapper--container"
              >
                <WidgetSwitch
                  id={`${id}-${type}`}
                  label={name ? name : id}
                  rootLabel={id}
                  alias={alias}
                  value={retriveValue(type, ckData, id)}
                  onChange={props.onChange}
                />
              </div>
            );
          })
        }
      </div>
    </>
  );
};

export default SwitchGroupV2;
