import React, { useState, useEffect } from 'react';
import config from './config';
import { connect } from 'react-redux';
import { functions } from './helpers';
import { EditInstallerProps } from './interface';
import { useHistory } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { isValidPhoneNumber } from 'react-phone-number-input';
import {
  Template,
  Button,
  Input,
  Phone,
  Label,
  Modal,
} from 'business/modules/admin/common';
import { HiOutlineXCircle } from 'react-icons/hi';
import { RiSave3Fill, RiEdit2Line } from 'react-icons/ri';
import './styles.scss';

const EditInstaller: React.FC<EditInstallerProps> = ({
  updatedInstaller,
  saveUpdatingInstaller,
  compItem,
  saveCompItemGlobally,
  currInstallerToEdit,
  setToEdit,
}) => {
  const history = useHistory();
  const [disabled, setDisabled] = useState<boolean>(true);

  const [errors, setErrors]: any = useState({
    name: '',
    email: '',
    phone: '',
    state: '',
    zipcode: '',
    city: '',
  });

  const [fieldsUpd, setFieldsUpd] = useState({
    name: '',
    email: '',
    phone: '',
    state: '',
    zipcode: '',
    city: '',
  });

  const updateInstaller = async () => {};

  const capitalizeFirstChar = (str: string) => {
    let first = str[0].toUpperCase();
    let rest = str.slice(1);
    return str === 'zip' ? 'Zip Code' : first.concat(rest);
  };

  const getUserInput = (event: any) => {
    let name: string = event.target.name;
    let value: any = event.target.value;

    let validate = functions.handleErrors(name, value);

    setErrors({ ...errors, [name]: validate });

    setFieldsUpd({ ...fieldsUpd, [name]: value });

    saveUpdatingInstaller({
      ...updatedInstaller,
      [name]: value,
    });
  };

  const handlePhoneChange = (value: any, country: any, formattedValue: any) => {
    setErrors({ ...errors, phone: '' });

    setFieldsUpd({ ...fieldsUpd, phone: '+' + value });

    saveUpdatingInstaller({ ...updatedInstaller, phone: '+' + value });

    let validate = functions.handleErrors('phone', value);

    if (!isValidPhoneNumber('+' + value)) {
      setErrors({ ...errors, phone: validate });
    } else {
      setErrors({ ...errors, phone: '' });
    }
  };

  const returnVal = (elem: any) => {
    return fieldsUpd[elem.name as keyof typeof fieldsUpd] || '';
  };

  const cancelUpdate = () => {
    if (currInstallerToEdit && Object.keys(currInstallerToEdit).length > 0) {
      setFieldsUpd({
        name: currInstallerToEdit.name,
        email: currInstallerToEdit?.contact?.email,
        phone: currInstallerToEdit?.contact?.phone,
        state: currInstallerToEdit?.state,
        zipcode: currInstallerToEdit?.zipcode,
        city: currInstallerToEdit?.city,
      });
    }

    setErrors({
      name: '',
      email: '',
      phone: '',
      state: '',
      zipcode: '',
      city: '',
    });
  };

  useEffect(() => {
    const currentCompany = localStorage.getItem('@companyItem');
    let parsed: any;
    if (currentCompany) {
      parsed = JSON.parse(currentCompany);

      saveCompItemGlobally(parsed);
    }
  }, []);

  useEffect(() => {
    let editing: any = localStorage.getItem('currUpdatingInstaller');

    if (editing) {
      let parsed: any = JSON.parse(editing);

      setToEdit(parsed);
    }
  }, []);

  useEffect(() => {
    if (currInstallerToEdit && Object.keys(currInstallerToEdit).length > 0) {
      setFieldsUpd({
        name: currInstallerToEdit.name,
        email: currInstallerToEdit?.contact?.email,
        phone: currInstallerToEdit?.contact?.phone,
        state: currInstallerToEdit?.state,
        zipcode: currInstallerToEdit?.zipcode,
        city: currInstallerToEdit?.city,
      });
    }
  }, [currInstallerToEdit]);

  useEffect(() => {
    let errs: any = Object.values(errors).filter(
      (item: any) => item.length > 0,
    );

    let fields: any = Object.values(fieldsUpd).filter(
      (item: any) => item.length > 0,
    );

    if (errs.length === 0 && fields.length === 6) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [errors, fieldsUpd, currInstallerToEdit]);

  return (
    <Template
      id="installers-ed"
      title={config.strings.edit.title}
      goBack={() => {
        history.push('/admin/dashboard/companies-list/company/installers');
      }}
    >
      <BreadcrumbsItem to={`/admin/dashboard/company/installers/add-edit`}>
        {config.strings.edit.breadcrumbTitle}
      </BreadcrumbsItem>

      <div className="jumbotron edit--installer-container">
        <h4 className="centered">{config.strings.edit.mainTitle} </h4>
        <p className="lead centered card-top-ft">{config.strings.edit.pText}</p>
        <hr className="my-4" />

        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 offset-md-3">
              {config.fields.map((elem: any, index: number) => {
                if (elem.id === `telephone-input-spec1-ed`) {
                  return (
                    <div className="form-group form-group-edit-ins" key={index}>
                      <Label>{capitalizeFirstChar(elem.name)}</Label>
                      <Phone
                        className="form-group lighter  form-group-edit-ins1 btm-space-mb1 plpr0"
                        label={elem}
                        disabled={false}
                        key={index}
                        value={fieldsUpd?.phone}
                        onChange={(
                          value: any,
                          country: any,
                          e: any,
                          formattedValue: any,
                        ) => handlePhoneChange(value, country, formattedValue)}
                        name={elem.name}
                      />
                      <div className="render-error">
                        {errors && errors[elem.name] ? errors[elem.name] : ''}
                      </div>
                    </div>
                  );
                }

                return (
                  <div className="form-group form-group-edit-ins" key={index}>
                    <Label>{capitalizeFirstChar(elem.name)}</Label>
                    <div className="input-group mbottom-0">
                      <Input
                        type={elem.type}
                        className={elem.className}
                        id={elem.id}
                        name={elem.name}
                        aria-describedby={elem.ariaDescribedBy}
                        placeholder={elem.placeholder}
                        children={elem.label}
                        value={returnVal(elem)}
                        onChange={getUserInput}
                      />
                    </div>
                    <div className="render-error">
                      {errors && errors[elem.name] ? errors[elem.name] : ''}
                    </div>
                  </div>
                );
              })}

              <div className="form-group form-group-edit-ins">
                <Label>Company Code</Label>
                <div className="input-group mbottom-0">
                  <Input
                    type={'text'}
                    className={'form-control mb-2'}
                    id={'installer-id2'}
                    name={'installer-id'}
                    aria-describedby={''}
                    placeholder={'Generated ID'}
                    value={compItem?.code || ''}
                    disabled={true}
                  />
                  <div className="input-group-append"></div>
                </div>
              </div>

              <div className="container" id="edit-installer-form-section">
                <div className="row d-flex justify-content-center">
                  <div className="col-12 form-col1">
                    <Button
                      onClick={cancelUpdate}
                      className="small-button dismiss-btn float-right-btn mr--2"
                      dataDismiss={`modal`}
                      dataToggle={config.strings.edit.dataTarget}
                    >
                      <span className="span--icon btn-child-ft ">
                        <HiOutlineXCircle className="cancel" /> CANCEL{' '}
                      </span>
                    </Button>

                    <Button
                      className="small-button ok float-right-btn"
                      dataTarget={config.strings.edit.dataTarget}
                      dataToggle="modal"
                      type="button"
                      disabled={disabled}
                    >
                      <span className="span--icon btn-child-ft ">
                        <RiSave3Fill className="edit" /> SAVE{' '}
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        config={config}
        id={`save-changes-to-current-installer`}
        title={config.modalStrings.edit}
        icon={<RiEdit2Line className="edit" />}
        body={config.modalStrings.aboutToUpdate}
        label={config.modalStrings.labelOK}
        className={`small-button ok`}
        bodyClass={`alert alert-info`}
        sureToProceed={config.modalStrings.sureToProceed}
        hasConfirmBtn={true}
        warning={config.modalStrings.noUndo}
        onClickCancel={cancelUpdate}
        onClick={updateInstaller}
        //handle modal automatic closing when
        //there is a real update going on:
        /* modalMustClose={modalMustClose}
        handleModalState={handleModalState}*/
        modalMustClose={false}
        handleModalState={() => {}}
      />
    </Template>
  );
};

function mapStateToProps(state: any) {
  const { adminInstallersState, adminCompaniesState } = state;
  return {
    installerItem: adminInstallersState.installerItem,
    updatedInstaller: adminInstallersState.updatedInstaller,
    compItem: adminCompaniesState.compItem,
    currInstallerToEdit: adminInstallersState.currInstallerToEdit,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { adminInstallersState, adminCompaniesState } = dispatch;
  return {
    saveCompItemGlobally: adminCompaniesState.saveCompItemGlobally,
    saveUpdatingInstaller: adminInstallersState.saveUpdatingInstaller,
    setToEdit: adminInstallersState.setToEdit,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditInstaller);
