// @ts-nocheck
import React from 'react';
import { Switch, FormControlLabel } from '@material-ui/core';
import './styles.scss';

interface SwitchButtonProps {
  id?: string;
  onChange: any;
  value: any;
  origin?: string;
  status?: string;
  onClick?: Function;
}

const SwitchButton: React.FC<SwitchButtonProps> = ({
  id,
  value,
  origin,
  status,
  ...props
}) => {
  return (
    <>
      <div id={id}>
        <FormControlLabel
          style={{ marginRight: origin && origin === `on-off` ? '0px' : '', marginBottom: 2 }}
          control={
            <Switch
              style={{ marginRight: '2px' }}
              id={origin && origin === `on-off` ? `on-off` : id}
              checked={!!value}
              onChange={props.onChange}
              color="primary"
              onClick={props.onClick}
            />
          }
        />
        <span
          style={{
            fontSize: '1.3rem',
            color: status === 'On' ? 'rgb(145, 145, 145)' : 'rgb(190, 190, 190)',
            fontWeight: '500',
          }}
        >
          {' '}
          {status}
        </span>
      </div>
    </>
  );
};

export default SwitchButton;
