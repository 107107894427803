import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { DeviceDetails, Peripherals } from './pages';

const devices_path = '/details';

const DeviceDetailsRoute: React.FC = props => {

  return (
    <>
      <BreadcrumbsItem to={`/dashboard/devices${devices_path}`}>
        Device Details
      </BreadcrumbsItem>
      <Switch>
        <Route exact path={`/dashboard/devices${devices_path}`} component={DeviceDetails} />
        <Route path={`/dashboard/devices${devices_path}/peripheral`} component={Peripherals} />
        <Route path={`/dashboard/devices${devices_path}/:device_id/peripheral`} component={Peripherals} />
      </Switch>
    </>
  );
};

export default DeviceDetailsRoute;
