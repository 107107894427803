import React from 'react';
import config from './config';
import moment from 'moment-timezone';
import { ConnectedIcon, DisconnectedIcon } from '../../../../../../assets';

const returnKey = (item: any) => {
  let label: any;
  if (config.dictionary.hasOwnProperty(item)) {
    label = config.dictionary[item as keyof typeof config.dictionary];
  }
  return label;
};

const returnKeyIndexValue = (data: any, item: any) => {
  return data && data.hasOwnProperty(item) ? data[item] : '';
};

const formatDate = (date: any, timeZone: any) => {
  if (
    timeZone !== null &&
    timeZone !== undefined &&
    timeZone !== '' &&
    timeZone != 'Select intended timezone'
  ) {
    return date
      ? moment(new Date(date))
          .tz(config.timezones[timeZone].timezone)
          .format('MM-DD-YYYY, hh:mm A')
      : '';
  }

  return date ? moment(new Date(date)).format('MM-DD-YYYY, hh:mm A') : '';
};

const filterConnDevStatus = (values: any) => {
  let isConnected: boolean = false;

  values.forEach((val: any) => {
    if (typeof val === 'object' && val.hasOwnProperty('cdst')) {
      isConnected = val.cdst > 0;
    }
  });

  return !isConnected;
};

const returnValue = (item: any) => {
  const disconnected: boolean =
    item && Array.isArray(item) ? filterConnDevStatus(item) : item === 0;

  return disconnected ? (
    <DisconnectedIcon className="status-icon" />
  ) : (
    <ConnectedIcon className="status-icon" />
  );
};

export const functions = {
  returnKey: returnKey,
  returnKeyIndexValue: returnKeyIndexValue,
  formatDate: formatDate,
  filterConnDevStatus: filterConnDevStatus,
  returnValue: returnValue,
};
