// @ts-nocheck
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import Skeleton from '@material-ui/lab/Skeleton';
import SweetAlert from 'react-bootstrap-sweetalert';
import numeral from 'numeral';
import _ from 'lodash';
import { Notification } from '../../../../../../../react-components';
import { WidgetSingleSlider, NoDevicesAlert, WidgetActions } from '../common';
import {
  SwitchGroup,
  SwitchGroupV2,
  FloatButton,
  SwitchButton,
} from './components';
import { DevicesUtils } from '../../utils';
import { useStatesReducer, statesReducer, ckStatesReducer } from './hooks';
import config from './config';
import './styles.scss';




const COMPONENT_STRINGS: any = { ...config?.strings };
const ALERTS_STRINGS: any = { ...config?.alerts };
const FORMATS: any = { ...config.formats };
const MODEL_FORMAT: any = { ...config.model };
const CK_MODEL_FORMAT: any = { ...config.ckModel };
const DEFAULT_EXTREMS: any = { ...config.sliderValues };
const ST_LABEL: string = config.strings.stLabel;
const SLIDERS_DEFAULTS: any = { ...config.defaults };

interface PressureConnectProps {
  device: any;
  setup: any;
  loading: any;
  updateDevice: any;
  getSysSetup: Function;
  updateSysSetup: Function;
  status: any;
}

const PressureConnect: React.FC<PressureConnectProps> = ({
  device,
  ...props
}) => {
  const [expandedCtrl, setIsExpandedCtrl]: any = useState(false);
  const [
    pressureConnectSectionIsEnabled,
    setPressureConnectSectionIsEnabled,
  ]: boolean = useState(false);
  const [showSaveBtn, setShowSaveBtn]: any = useState(false);
  const [sysSetup, setSysSetup]: any = useState({});
  const [showAlert, setShowAlert]: boolean = useState(false);
  const [peripherals, setPeripherals]: any = useState({});
  const [widgetCkStates, setWidgetCkStates]: any = useState({});
  const [isInit, setIsInit]: boolean = useState(false);
  const [onEditMode, setToEditMode]: boolean = useState(false);
  const [currentDeviceId, setCurrentDeviceId]: any = useState();
  const [showView, setShowView] = useState({
    pa: true,
  });
  const [widgetStates, setWidgetStates]: any = useStatesReducer(statesReducer, {
    pa: { ...MODEL_FORMAT },
  });
  const [isTurnedOnInit, setIsTurnedOnInit]: boolean = useState(false);




  const toggleSection = (status: any) => {
    if(
      props &&
      props.hasOwnProperty('status') &&
      props?.status?.stoped
    ){
      return;
    }

    if(!isTurnedOnInit) setIsTurnedOnInit(true);
    setPressureConnectSectionIsEnabled(!status);

    if (!status) setShowSaveBtn(true);
  };

  const handleChange = (panel: string) => (event: any, isExpanded: any) => {
    setIsExpandedCtrl(isExpanded);
    localStorage.setItem(`${ST_LABEL}${currentDeviceId}`, isExpanded);
  };

  const requestSystemSetup = async (_device: any) => {
    const { device_id } = _device || {};
    const result: any = await props.getSysSetup({ id: device_id });
    if (result) {
      setSysSetup({ ...result });
    }
  };

  const isValueInit = (value: number, prop: string) => {
    return (
      value[prop] && value[prop].hasOwnProperty('current') && value[prop].init
    );
  };

  const transformSingleSlider = useCallback((values: any) => {
    const { max, min, current } = values;
    return [current, max === -101 ? 150 : max];
  });

  const onChangePressure = (newValues: any) => {
    const tempValues: any = DevicesUtils.arrayPackageByProp(newValues, 'pa');
    setWidgetStates(tempValues);
    if (Object.values(peripherals).length > 0) setShowSaveBtn(true);
    setToEditMode(true);
  };

  const onChangeWgtCk = (label: string, event: any, value: any) => {
    const { id } = event?.target;
    const props: any = id.split('-');

    if (props && props[0] && props[1]) {
      if (
        props[1] === 'damper' ||
        props[1] === 'fanConnect' ||
        props[1] === 'centralFan'
      ) {
        setWidgetCkStates((prevVals: any) => {
          return {
            ...prevVals,
            [props[0]]: value,
          };
        });
      } else {
        setWidgetCkStates((prevVals: any) => {
          return {
            ...prevVals,
            [props[1]]: {
              ...prevVals[props[1]],
              [props[0]]: value,
            },
          };
        });
      }
      setToEditMode(true);
      setShowSaveBtn(true);
    }
  };

  const prepareValuesToSave = (
    sliderVals: any,
    ckValues: any,
    _device: any,
  ) => {
    const { device_id } = _device;
    let tempSetup: any = {};
    tempSetup.slider =
      sliderVals.pa.max && sliderVals.pa.max !== -101
        ? sliderVals.pa.max
        : SLIDERS_DEFAULTS.pa.max;
    tempSetup = {
      ...tempSetup,
      checkbox: { ...ckValues },
      turnedOn: pressureConnectSectionIsEnabled,
    };

    return {
      system_id: device_id,
      setup: { pressureConnect: tempSetup },
    };
  };

  const onSavePaData = async (event: any) => {
    setShowAlert(false);
    props.loading.start('Updating device settings...');
    setShowSaveBtn(false);
    const devSetup: any = prepareValuesToSave(
      widgetStates,
      widgetCkStates,
      device,
    );
    let result: any = await props.updateSysSetup(devSetup);

    if (result) {
      setSysSetup({ ...result });
      Notification({
        title: 'Successfully',
        message: config?.notifications?.update?.success,
        type: 'success',
      });
    }

    props.loading.stop();
  };

  const handleSavePreConnData = async (event: any) => {
    setShowAlert(true);
    setToEditMode(false);
  };

  const onCancelAlert = (event: any) => {
    setShowAlert(false);
    setToEditMode(false);
    setShowSaveBtn(false);
    requestSystemSetup(device);
  };

  const expandDetails = (value: any) => {
    switch (value) {
      case 'pa':
        setShowView((prevState: any) => {
          return {
            ...prevState,
            pa: !showView.pa,
          };
        });
        break;
      default:
        break;
    }
  };

  const extractPeripherals = (_details: any) => {
    const { conf } = _details?.settings || {};
    const cdvsClone: any = conf?.cdvs ? _.cloneDeep(conf.cdvs) : [];
    return {
      ventConnect: cdvsClone.filter((item: any) =>
        item.cdid.startsWith('AirCyclerVC'),
      ),
      fanConnect: conf?.fcsp === 1 || conf?.fcsp === '1',
      damper: true,
      pressureConnect: cdvsClone.filter((item: any) =>
        item.cdid.startsWith('AirCyclerPC'),
      ),
      freshConnect: cdvsClone.filter((item: any) =>
        item.cdid.startsWith('AirCyclerFH') || item.cdid.startsWith('AirCyclerFT'),
      ),
      centralFan: true,
    };
  };

  const prepareCkboxStates = (connectedDevs: any) => {
    let tempStates: any = {};
    if (Object.values(connectedDevs).length > 0) {
      Object.keys(connectedDevs).forEach((key: string) => {
        if (key === 'fanConnect') {
          tempStates.fanConnect = false;
        } else if (key === 'damper') {
          tempStates.dmst = false;
        } else if (key === 'centralFan') {
          tempStates.cfcm = false;
        } else {
          connectedDevs[key].forEach((cDev: any) => {
            const { cdid } = cDev;
            if (!tempStates[key]) tempStates[key] = {};
            tempStates[key][cdid] = false;
          });
        }
      });
    }
    return tempStates;
  };




  useEffect(() => {
    if (device) {
      const { cdvs } = device.details?.settings?.conf || {};
      let tempPeriphs: any = extractPeripherals(device.details);
      setPeripherals(tempPeriphs);
      setCurrentDeviceId(device.device_id);

      if (!isInit) {
        let tempStates: any = prepareCkboxStates(tempPeriphs);
        setWidgetCkStates(tempStates);
        setIsInit(true);
      }
    }
  }, [device]);


  useEffect(() => {
    if (!pressureConnectSectionIsEnabled && isInit) {
      const devSetup: any = prepareValuesToSave(
        widgetStates,
        widgetCkStates,
        device,
      );

      props.updateSysSetup(devSetup);
    }

    if (pressureConnectSectionIsEnabled) {
      let storedState: any = localStorage.getItem(
        `${ST_LABEL}${currentDeviceId}`,
      );

      if (storedState !== null && storedState !== undefined) {
        setIsExpandedCtrl(storedState === 'true');
      }
    }
  }, [pressureConnectSectionIsEnabled]);


  useEffect(() => {
    if (props.setup) {
      setSysSetup({ ...props.setup });
    }
  }, [props.setup]);


  useEffect(() => {
    if (!onEditMode) {
      let modelClone: any = _.cloneDeep({ ...MODEL_FORMAT });

      if (Object.values(peripherals).length > 0) {
        const dfpsResult: any = DevicesUtils.getPressureValue({ ...device });
        const { pressureConnect } = sysSetup || {};

        modelClone.init = true;
        modelClone.max =
          pressureConnect && pressureConnect.hasOwnProperty('slider')
            ? pressureConnect.slider
            : -101;
        modelClone.current = dfpsResult;

        if (pressureConnect) {
          const { checkbox, turnedOn } = pressureConnect;

          if (checkbox !== null && checkbox !== undefined){
            setWidgetCkStates({ ...checkbox });
          }

          if (turnedOn !== null && turnedOn !== undefined){
            if(!isTurnedOnInit) setPressureConnectSectionIsEnabled(turnedOn);
          }



        }
      } else {
        modelClone.init = true;
      }

      setWidgetStates({
        type: 'updateOnce',
        prop: 'pa',
        data: modelClone,
      });
    }
  }, [peripherals, sysSetup]);


  return (
    <>
      <Accordion
        id="u_widget--pressure-conn"
        square
        expanded={pressureConnectSectionIsEnabled && expandedCtrl}
        onChange={handleChange('pressure-conn')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="widget--pc"
          id="widget--pc"
        >
          <div className="widget--title-container">
            <div className="widget--row">
              <div className="widget--title-label">
                {COMPONENT_STRINGS.title}
              </div>
              <div className="widget--title-actions">
                <SwitchButton
                  id="pressure-connect"
                  onChange={() =>
                    toggleSection(pressureConnectSectionIsEnabled)
                  }
                  value={pressureConnectSectionIsEnabled}
                />
              </div>
            </div>
            <div className="widget--row" style={{ marginTop: 5 }}>
              <div className="widget--col ac-col-3 col-display col-display--left">
                {!isValueInit(widgetStates, 'pa') ? (
                  <Skeleton
                    variant="text"
                    height={60}
                    width={165}
                    style={{ float: 'left' }}
                  />
                ) : (
                  <div
                    className={
                      !pressureConnectSectionIsEnabled
                        ? `widget--value--disabled`
                        : `widget--value`
                    }
                  >
                    <span>
                      {numeral(widgetStates.pa.current).format(FORMATS.pa)} Pa
                    </span>
                  </div>
                )}
              </div>
              <div className="widget--col">
                <div className="widget--value"></div>
              </div>
              <div className="widget--col">
                <div className="widget--value"></div>
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="widget--body-container">
            <div className="widget--row">
              <div className="widget--row-content">
                <WidgetSingleSlider
                  id="w-p-conn"
                  values={transformSingleSlider(widgetStates.pa)}
                  max={DEFAULT_EXTREMS.pa.max}
                  min={DEFAULT_EXTREMS.pa.min}
                  step={1}
                  col={2}
                  showLabel={true}
                  symbol=""
                  onChange={onChangePressure}
                  defaultMark={true}
                  defaultMarkValues={{
                    top: 20,
                    left: '73.5',
                    value: SLIDERS_DEFAULTS.pa.max,
                  }}
                />
              </div>
              {Object.values(peripherals).length > 0 && (
                <div
                  className="widget--row-expand"
                  onClick={() => expandDetails('pa')}
                  style={{}}
                >
                  <div className="sub-dp-title">
                    {showView.pa ? <></> : 'Show Setup'}
                  </div>
                  <div>{showView.pa ? <ExpandLess /> : <ExpandMoreIcon />}</div>
                </div>
              )}
              {showView.pa && (
                <div className="widget--row-content">
                  {Object.values(peripherals).length > 0 ? (
                    <SwitchGroupV2
                      id={`switch-group-pressureConnect`}
                      device={device}
                      data={peripherals}
                      ckData={widgetCkStates}
                      onChange={onChangeWgtCk.bind(null, 'pa')}
                    />
                  ) : (
                    <NoDevicesAlert />
                  )}
                </div>
              )}
            </div>
            {showSaveBtn && <WidgetActions onSave={handleSavePreConnData} />}
            {showSaveBtn && <FloatButton onSave={handleSavePreConnData} />}
          </div>
        </AccordionDetails>
      </Accordion>
      <SweetAlert
        info
        showCancel
        confirmBtnText={ALERTS_STRINGS.save?.btn?.confirm}
        title={ALERTS_STRINGS?.save?.question}
        confirmBtnBsStyle="info"
        show={showAlert}
        onConfirm={onSavePaData}
        onCancel={onCancelAlert}
        focusCancelBtn
      />
    </>
  );
};

function mapStateToProps(state: any) {
  const { devices } = state;
  return {};
}

function mapDispatchToProps(dispatch: any) {
  const { foobot, devices, systemSettings, loader } = dispatch;
  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    updateDevice: devices.editDevice,
    getSysSetup: systemSettings.getSystemSetupBySysId,
    updateSysSetup: systemSettings.editSystemSetup,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PressureConnect);
