export default {
  strings: {
    genericStrs: {
      nodevs: `This account does not have devices.`,
      defineSetts: `You may define`,
      cont: `settings after the account has at least a device.`,
    },
    dev: {
      goDevSection: 'Ok, take me to add devices section!',
      devices: 'DEVICES',
      start: `START`,
    },
    mc: {
      goDevSection: 'Ok, take me to add devices section!',
      devices: 'DEVICES',
      ddLabel: `Select device type`,
      btnBack: `Back`,
      noDevices: `This account does not have devices.`,
      content: `You may define Manual Control settings after the account has at least a device.`,
    },
  },
};
