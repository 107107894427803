import _ from 'lodash';
import { ConnectionBroker } from '../../core';
import configs from '../../business/config/config';

const SOCKET_URL: string = configs?.apis?.g3iot?.baseSocketURLs[1];


export const alerts = {
  state: {
    isInit: false,
    isConnected: false,
    connectionId: null,
    sockets: [],
    currentNotifications: [],
    originalAlerts: [],
  },
  reducers: {
    updateState(state: any, payload: any, name: string) {
      return { ...state, [name]: payload };
    },
    setStart(state: any, payload: any) {
      return { ...state, isInit: payload };
    },
    setConnectionState(state: any, payload: any) {
      return { ...state, isConnected: payload };
    },
    addSocket(state: any, payload: any){
      const { sockets } = state;
      let socketsClone: any = _.cloneDeep(sockets);
      socketsClone.push(payload);

      return { ...state, sockets: socketsClone };
    },
    updateNotifications(state: any, payload: any){
      return { ...state, currentNotifications: payload };
    },
  },
  effects: (dispatch: any) => ({
    async setAlertsConnection(data: any, rootState: any){
      const self: any = this;
      const { type, apiKey, userId } = data;
      const skUrl = `${SOCKET_URL}?x-iot-key=${apiKey}&type=${type}`;
      let socket: any = new WebSocket(skUrl);

      socket.addEventListener('message', (event: any) => {
        if(event?.data){
          const receivedData: any = JSON.parse(event.data);
          const { type, data }: any = receivedData || {};

          if(type && type === 'connection'){
            const { alerts_id } = data;

            if(alerts_id){
              console.log('      [2]         Socket Connected...     ', alerts_id);
              self.updateState(true, 'isConnected');
              self.updateState(alerts_id, 'connectionId');
            }
          }else{
            const { content } = receivedData;
            if(content) self.updateNotifications(receivedData);
          }
        }
      });

      socket.addEventListener('open', (event: any) => {
        console.log('    [1]       Socket Opening...');
        socket.send(
          JSON.stringify({
            action: 'subscribeAlerts',
            eventType: 'SUBSCIBE-ALERTS',
            account: userId,
          })
        );
      });

      self.addSocket(socket);

      socket.addEventListener('close', (event: any) => {
        if (event?.reason === 'Going away') {
          console.log('      [3]  [Socket Closed] -> Reconnecting socket...');
          self.setAlertsConnection(data);
        }
      });
    },
    async resetSockets(data: any){

    },
    async showActiveAlertsNotifications(data: any, rootState: any){
      const self: any = this;
      const { key } = data;
      const { connectionId, currentNotifications } = rootState.alerts;
      let alertsClone: any = _.cloneDeep(currentNotifications);

      let response = await ConnectionBroker.fetchG3IotAPI(
        'jitG3Iot',
        'alerts.show',
        key,
      );

      self.setStart(true);
      self.updateState(response, 'originalAlerts');
      self.updateState(response, 'currentNotifications');

      return response;
    },
    async showAllAlertsNotifications(data: any, rootState: any){
      let self = this;
      const { key } = data;
      let alertsClone: any = _.cloneDeep(rootState.alerts.currentNotifications);
      let response = await ConnectionBroker.fetchG3IotAPI(
        'jitG3Iot',
        'alerts.list',
        key
      );

      if(response?.statusCode === 200){
        const { alerts } = response?.body;
      }

      return response;
    },
    async markNotificationsAsRead(data: any, rootState: any){
      const self: any = this;
      const { id, key } = data;
      const { currentNotifications } = rootState.alerts;
      let alertsClone: any = _.cloneDeep(currentNotifications);
      let payload: any = { message_id: id };

      let response = await ConnectionBroker.fetchG3IotAPI(
        'jitG3Iot',
        'alerts.updateStatus',
        key,
        payload,
      );

      return response;
    },

  })
};
