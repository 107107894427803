import config from './config';

const handleOpts = (actionToPerformOnRequest: string, section: string) => {
  if (section === `title`) {
    if (config.titles.hasOwnProperty(actionToPerformOnRequest)) {
      return config.titles[
        actionToPerformOnRequest as keyof typeof config.titles
      ];
    }
  }
  if (section === `icon`) {
    if (config.icons.hasOwnProperty(actionToPerformOnRequest)) {
      return config.icons[
        actionToPerformOnRequest as keyof typeof config.icons
      ];
    }
  }
  if (section === `bodies`) {
    if (config.bodies.hasOwnProperty(actionToPerformOnRequest)) {
      return config.bodies[
        actionToPerformOnRequest as keyof typeof config.bodies
      ];
    }
  }

  if (section === `bodyClass`) {
    if (config.bodyClass.hasOwnProperty(actionToPerformOnRequest)) {
      return config.bodyClass[
        actionToPerformOnRequest as keyof typeof config.bodyClass
      ];
    }
  }
};

export const helpers = {
  handleOpts: handleOpts,
};
