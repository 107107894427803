import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { DeleteRequests } from './pages';

const base_path = '/admin/dashboard';

const DeleteRequestsRoutes: React.FC = ({ ...props }: any) => {
  return (
    <Switch>
      <Route
        exact
        path={`${base_path}/delete-requests`}
        component={DeleteRequests}
      />
    </Switch>
  );
};

export default DeleteRequestsRoutes;
