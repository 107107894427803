import React from 'react';
import './styles.scss';



const CustomTabBody: React.FC<any> = ({
  id,
  children,
  ...props
}) => {

  return (
    <div
      id={id}
      className="tab-pane fade"
      role="tabpanel"
      aria-labelledby={`${id}-tab`}
    >
      {children}
    </div>
  );
};

export default CustomTabBody;
