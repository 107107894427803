export default {
  strings: {},
  colunms: [
    { title: 'OutsideAir Thresholds', field: '' },
    { title: 'IndoorAir Thresholds', field: '' },
    { title: 'Max Temperature', field: 'out_temp_max' },
    { title: 'Min Temperature', field: 'out_temp_min' },
    { title: 'Humidity', field: 'out_hum_value' },
    { title: 'Air Quality', field: 'out_aq_value' },
    { title: 'PM2.5', field: 'in_pm25_value' },
    { title: 'CO2', field: 'in_co2_value' },
    { title: 'VOC', field: 'in_voc_value' },
    { title: 'Current Mode', field: 'curr_mode' },
  ],
  threshTags: ['out_temp_max', 'out_temp_min', 'out_hum_value', 'out_aq_value', 'in_pm25_value', 'in_co2_value', 'in_voc_value', 'curr_mode'],
};
