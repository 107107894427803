import React from 'react';
import { IoCheckmark } from 'react-icons/io5';
import { MdOutlineCancelPresentation } from 'react-icons/md';

export default {
  strings: {
    breadcrumbCreate: `Add a new record`,
    tooltipTitle: `Get a new ID`,
    tooltipTxt: `Generate a different ID number by clicking on the button GENERATE ID`,
    tooltipTitle1: `The company ID`,
    tooltipTxt1: `This is the company ID that has been generated when the company was created. This field is immutable.`,
    warning: `Please make sure all the required fields are filled to proceed!`,
    emptyFields: `All the fields must be filled.`,
  },
  modalStrings: {
    confirm: ``,
    warning: `Information`,
    alertStr: ``,
    alertStr2: ``,
    sureToProceed: `Are you sure you wish to proceed?`,
    confirmation: `Are you sure you wish to proceed?`,
  },
  notifications: {
    create: {
      success: 'Company created successfully!',
      error: 'Something went wrong!',
    },
  },
  titles: {
    create__comp: `Create company`,
  },
  icons: {
    create__comp: <IoCheckmark className="edit" />,
    cancel__op: <MdOutlineCancelPresentation className="edit" />,
  },
  bodies: {
    create__comp: `You are about to create a company.`,
  },
  labels: {
    create__comp: ` OK`,
  },
  bodyClass: {
    create__comp: `alert alert-info`,
    edit__comp: `alert alert-danger`,
  },
  genLabel: {
    create: `GENERATE ID`,
  },
  companyCreate: {
    mainTitle: `New company`,
    pText: `Add the company information below.`,
    title: `Create company`,
    breadcrumbItem: `/admin/dashboard/company/add-edit`,
    class: `jumbotron container-jumbo jmb-min-h--1`,
  },
  fields: [
    {
      outerClass: 'form-group',
      label: 'Company Name',
      htmlFor: 'exampleInputEmail1',
      type: 'text',
      id: 'company-name',
      ariaDescribedby: 'inputHelp',
      placeholder: 'Enter name',
      className: 'form-control mb-2',
      labelClass: 'lead label-create-user',
      name: 'name',
      disabled: '',
      isPhone: false,
      isCode: false,
    },
    {
      outerClass: 'form-group',
      label: 'E-mail address',
      htmlFor: 'exampleInputEmail1',
      type: 'email',
      id: 'company-email',
      ariaDescribedby: 'emailHelp',
      placeholder: 'Enter e-mail address',
      className: 'form-control mb-2',
      labelClass: 'lead label-create-user',
      name: 'email',
      disabled: '',
      isPhone: false,
      isCode: false,
    },
    {
      outerClass: 'form-group',
      label: 'Phone number',
      htmlFor: 'exampleInputPassword1',
      type: 'text',
      id: 'telephone-input-spec',
      ariaDescribedby: ' ',
      placeholder: 'Phone number',
      className: 'form-control mb-2',
      labelClass: 'lead label-create-user',
      name: 'phone',
      disabled: '',
      isPhone: true,
      isCode: false,
    },
    {
      outerClass: 'form-group',
      label: 'State',
      htmlFor: 'exampleInputState',
      type: 'text',
      id: 'state011',
      ariaDescribedby: 'inputHelp',
      placeholder: 'Enter State',
      className: 'form-control mb-2',
      labelClass: 'lead label-create-user',
      name: 'state',
      disabled: '',
      isPhone: false,
      isCode: false,
    },
    {
      outerClass: 'form-group',
      label: 'City',
      htmlFor: 'exampleInputState',
      type: 'text',
      id: 'city011',
      ariaDescribedby: 'inputHelp',
      placeholder: 'Enter City',
      className: 'form-control mb-2',
      labelClass: 'lead label-create-user',
      name: 'city',
      disabled: '',
      isPhone: false,
      isCode: false,
    },
    {
      outerClass: 'form-group',
      label: 'Zip Code',
      htmlFor: 'exampleInputZip',
      type: 'text',
      id: 'zip011',
      ariaDescribedby: 'inputHelp',
      placeholder: 'Enter zip code',
      className: 'form-control mb-2',
      labelClass: 'lead label-create-user',
      name: 'zipcode',
      disabled: '',
      isPhone: false,
      isCode: false,
    },
    {
      outerClass: 'form-group',
      label: 'Company ID',
      htmlFor: 'exampleInputCompNumber',
      type: 'text',
      id: 'company-id',
      ariaDescribedby: 'emailHelp',
      placeholder: 'Generated ID',
      className: 'form-control mb-2',
      labelClass: 'lead label-create-user',
      name: 'company-id',
      disabled: true,
      isPhone: false,
      isCode: true,
    },
  ],
  constraints: {
    name: {
      presence: true,
      length: {
        minimum: 1,
      },
    },
    email: {
      email: true,
      presence: true,
    },
    state: {
      presence: true,
      length: {
        minimum: 2,
      },
    },
    city: {
      presence: true,
      length: {
        minimum: 2,
      },
    },
    zipcode: {
      presence: true,
      length: {
        minimum: 5,
        maximum: 10,
      },
      format: {
        pattern: '[0-9][0-9\\- ]{0,10}[0-9]$',
        message: 'is invalid',
      },
    },
    phone: {
      presence: true,
      length: {
        minimum: 11,
        maximum: 11,
      },
    },
  },
  emailRegex: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
};
