import {
  AtomsIcon,
  FanIcon,
  FlowIcon,
  HumidityIcon,
  TemperatureIcon,
} from '../../business/assets/sensors-icons';
import {
  Pm25Icon,
  TVocIcon,
  Co2Icon,
  O3Icon,
} from '../../business/assets';
import {
  FanIcon as FanIconBlue,
  FoobotOrangeLogo,
  AirThingsIcon,
  AwairIcon,
} from '../../business/assets';
import {
  AirCyclerLogoSVG,
} from '../../business/assets/device-icons';



export default {
  sensorTypeToIcon: (type: string) => {
    switch (type) {
      case 'fan':
      case 'vent':
        return FanIcon;
      case 'flow':
      case 'status':
        return FlowIcon;
      case 'humidity':
        return HumidityIcon;
      case 'temp':
        return TemperatureIcon;
      case 'pm25':
        return Pm25Icon;
      case 'voc':
        return TVocIcon;
      case 'co2':
        return Co2Icon;
      case 'o3':
        return O3Icon;
      case 'other':
      default:
        return AtomsIcon;
    }
  },
  deviceIconByType: (type: string) => {
    switch (type) {
      case 'foobot':
        return FoobotOrangeLogo;
      case 'airthings':
        return AirThingsIcon;
      case 'awair':
      case 'awair-element':
      case 'awair-omni':
        return AwairIcon;
      case 'aircycler-local':
        return AirCyclerLogoSVG;
      case 'aircycler':
      default:
        return FanIconBlue;
    }
  },
};
