import React from 'react';
import { MenuProps } from './interface';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './styles.scss';

const RowMenu: React.FC<MenuProps> = ({
  btnId,
  onClick,
  open,
  anchorEl,
  handleClose,
  ariaExpanded,
  item,
  onClick2,
  expandedCtrl,
  id,
  config,
  firstFun,
  secondFun,
  thirdFun,
  fourthFunction,
}) => {
  const menuOptions = {
    firstOpt: () => {
      handleClose();
      firstFun && firstFun();
    },
    secondOpt: () => {
      handleClose();
      secondFun && secondFun(item);
    },
    thirdOpt: () => {
      handleClose();
      thirdFun && thirdFun();
    },
    fourthOpt: () => {
      fourthFunction && fourthFunction(item);
    },
  };

  return (
    <>
      <IconButton
        className="row-menu-btn"
        aria-haspopup="true"
        onClick={onClick2}
        aria-expanded={ariaExpanded}
        id={id}
      >
        <MoreHoriz />
      </IconButton>
      <IconButton
        className={'expand--btn'}
        id={btnId}
        aria-haspopup="false"
        aria-expanded={ariaExpanded}
        onClick={onClick}
      >
        {expandedCtrl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>
      <Menu
        elevation={1}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `more-button-comp`,
        }}
      >
        {config.menu.map((option: any, index: number) => {
          return (
            <MenuItem
              onClick={menuOptions[option.id as keyof typeof menuOptions]}
              key={index}
              data-toggle={option.dataToggle}
              data-target={option.dataTarget}
            >
              {option.label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default RowMenu;
