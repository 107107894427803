import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  DashboardAdmin,
  UserDetails,
  SystemDetails,
  EditNameLocation,
  AddDevLocation,
  EditPeripheral,
  Commands,
  SetupLogs,
  AdminUsers,
  ViewAdmin,
  CreateAdmin,
} from './pages';

const base_path = '/admin/dashboard';

const DashboardAdminRoutes: React.FC = ({ ...props }: any) => {
  return (
    <>
      <Switch>
        <Route exact path={base_path} component={DashboardAdmin} />
        <Route
          exact
          path={`${base_path}/user-details`}
          component={UserDetails}
        />
        <Route
          exact
          path={`${base_path}/user-details/system-settings/:id_system`}
          component={SystemDetails}
        />
        <Route
          exact
          path={`${base_path}/user-details/devices/edit-details/:id`}
          component={EditNameLocation}
        />
        <Route
          exact
          path={`${base_path}/user-details/devices/edit/location/:devId`}
          component={AddDevLocation}
        />
        <Route
          exact
          path={`${base_path}/user-details/:devId/peripheral/edit/:perId`}
          component={EditPeripheral}
        />
        <Route
          exact
          path={`${base_path}/details/devices/commands`}
          component={Commands}
        />
        <Route
          exact
          path={`${base_path}/details/devices/setup-logs`}
          component={SetupLogs}
        />

        <Route exact path={`${base_path}/admin-list`} component={AdminUsers} />
        <Route
          exact
          path={`${base_path}/admin-user-details/:userId`}
          component={ViewAdmin}
        />

        <Route
          exact
          path={`${base_path}/create-user`}
          component={CreateAdmin}
        />
      </Switch>
    </>
  );
};

export default DashboardAdminRoutes;
