import React from 'react';
import config from './config';
import { BreadcrumbProps } from './interface';
import { Breadcrumbs } from 'react-breadcrumbs-dynamic';
import { Breadcrumb as BootstrapBreadcrumb } from 'react-bootstrap';
import { FiChevronLeft } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { CrumbItem, BreadcrumbBtn } from './components';
import './styles.scss';

const Breadcrumb: React.FC<BreadcrumbProps> = ({
  title,
  titleBackButton = false,
  titleBackOnClick,
  id,
  hasRightBtn,
  fun,
  hasLabel,
  icon,
}) => {
  const history = useHistory();
  return (
    <div className="page-breadcrumb p-0 air-breadcrumb-c">
      <div className="row">
        <div className="col-7 align-self-center">
          <h4 className="page-title text-truncate text-dark font-weight-medium mb-1">
            {titleBackButton && (
              <button
                onClick={
                  !!titleBackOnClick ? titleBackOnClick : () => history.go(-1)
                }
                className="page-title text-truncate text-dark font-weight-medium mb-1"
              >
                <FiChevronLeft />
              </button>
            )}
            {title}
          </h4>

          {(hasRightBtn || hasLabel) && (
            <span className="spec-label-breadcrumb-001">
              {config.pageLabels[id as keyof typeof config.pageLabels]}
            </span>
          )}
          <Breadcrumbs
            item={CrumbItem}
            container={BootstrapBreadcrumb}
            finalProps={{ active: true }}
            duplicateProps={{ to: 'href' }}
          />
        </div>

        {hasRightBtn && (
          <BreadcrumbBtn
            label={config.btnLabels[id as keyof typeof config.btnLabels]}
            icon={icon}
            onClick={() => {
              fun && fun();
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Breadcrumb;
