import React from 'react';
import { InputPlus } from 'react-components';
import { parseNumber } from '../../helpers';
import './styles.scss';

interface CalculatedTimeProps {
  config: any;
  errors: any;
  fieldsState: any;
  onChangeState: (name: string, value: any) => void;
}

const CalculatedTime: React.FC<CalculatedTimeProps> = ({
  config,
  errors,
  fieldsState,
  onChangeState,
}) => {
  return (
    <div className="np-paddings" id="calculated-time">
      <div className="row">
        <div className="col-12 np-paddings">
          <InputPlus
            handleChange={(value: any) => {
              onChangeState('mnsf', parseNumber(value));
            }}
            handleChangeError={(err: any) => {}}
            type={config.fields.mnsf.type}
            label={config.fields.mnsf.label}
            value={`${fieldsState?.mnsf}`}
            units={config.fields.mnsf.units}
            error={errors?.mnsf}
            name={'mnsf'}
          />{' '}
        </div>
      </div>

      <>
        {fieldsState?.primary_control === 'fanConnect' && (
          <div className="row">
            <div className="col-12 np-paddings">
              <InputPlus
                handleChange={(value: any) => {
                  onChangeState('mner', parseNumber(value));
                }}
                handleChangeError={(err: any) => {}}
                type={config.fields.mner.type}
                label={config.fields.mner.label}
                value={`${fieldsState?.mner}`}
                units={config.fields.mner.units}
                error={errors?.mner}
                name={'mner'}
              />
            </div>
          </div>
        )}
        {fieldsState?.primary_control !== 'fanConnect' && (
          <div className="row">
            <div className="col-12 np-paddings">
              <InputPlus
                handleChange={(value: any) => {
                  onChangeState('mvcr', parseNumber(value));
                }}
                handleChangeError={(err: any) => {}}
                disabled={false}
                type={config.fields.mvcr.type}
                label={config.fields.mvcr.label}
                value={`${fieldsState?.mvcr}`}
                units={config.fields.mvcr.units}
                error={errors?.mvcr}
                name={'mvcr'}
              />
            </div>
          </div>
        )}
      </>

      <>
        {fieldsState?.primary_control === 'fanConnect' && (
          <div className="row">
            <div className="col-12 np-paddings">
              <InputPlus
                handleChange={(value: any) => {
                  onChangeState('efdt', parseNumber(value));
                }}
                handleChangeError={(err: any) => {}}
                type={config.fields.efdt.type}
                label={config.fields.efdt.label}
                value={`${fieldsState?.efdt}`}
                units={config.fields.efdt.units}
                error={errors?.efdt}
                name={'efdt'}
              />
            </div>
          </div>
        )}
        {fieldsState?.primary_control !== 'fanConnect' && (
          <div className="row">
            <div className="col-12 np-paddings">
              <InputPlus
                handleChange={(value: any) => {
                  onChangeState('vcdt', parseNumber(value));
                }}
                handleChangeError={(err: any) => {}}
                disabled={false}
                type={config.fields.vcdt.type}
                label={config.fields.vcdt.label}
                value={`${fieldsState?.vcdt}`}
                units={config.fields.vcdt.units}
                error={errors?.vcdt}
                name={'vcdt'}
              />
            </div>
          </div>
        )}
      </>

      <div className="row">
        <div className="col-12 np-paddings">
          <InputPlus
            handleChange={(value: any) => {
              onChangeState('mixt', parseNumber(value));
            }}
            handleChangeError={(err: any) => {}}
            type={config.fields.mixt.type}
            label={config.fields.mixt.label}
            value={`${fieldsState?.mixt}`}
            units={config.fields.mixt.units}
            error={errors?.mixt}
            name={'mixt'}
          />{' '}
        </div>
      </div>
    </div>
  );
};

export default CalculatedTime;
