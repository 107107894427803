import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Button, Notification } from '../../../../../react-components';
import { UserTemplate } from 'business/modules/common';
import { AwairLogoHeader } from '../../common';
import { ModalAlert } from './components';
import config from './config';
import './styles.scss';


const configInputs: any = config;
const AW_LABEL: string = '@air-awair';

interface AwairProps {
  awairUserData?: any;
  loading: any;
  disconnect: any;
}

const AwairDisconnect: React.FC<AwairProps> = ({ awairUserData, ...props }) => {
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const handleDisconnect = (event: any) => {
    setShowAlert(true);
  };

  const onDisconnect = async (event: any) => {
    setShowAlert(false);
    props.loading.start();
    await props.disconnect();
    history.replace('/dashboard/awair', {});
    props.loading.stop();
  };

  useEffect(() => {
    if (awairUserData?.details?.thirdParty?.awair){
      const { firstName, lastName } = awairUserData?.details?.thirdParty?.awair;

      if(firstName && lastName){
        setUsername(`${firstName} ${lastName}`);
      }
    }
  }, [awairUserData]);

  return (
      <UserTemplate
        id="awair-disconnection"
        title={configInputs?.strings?.title}
        titleBackButton
      >
        <BreadcrumbsItem to={`/dashboard/awair/disconnect`}>
          Awair Connection
        </BreadcrumbsItem>
        <Grid container direction="column" alignItems="center">
          <Grid item className="section-1">
            <AwairLogoHeader />
          </Grid>
          <Grid item className="section-2">
            <p>Hi, {username}</p>
          </Grid>
          <Grid item className="section-3">
            <p>{configInputs?.strings?.subtitle}</p>
          </Grid>
          <Grid item className="section-4">
            <div className="col d-flex pt-4 pb-2">
              <Button
                className="btn btn-primary btn-block"
                type="button"
                disabled={false}
                onClick={handleDisconnect}
              >
                {configInputs?.strings?.confirm}
              </Button>
            </div>
          </Grid>
        </Grid>
        <ModalAlert
          show={showAlert}
          onConfirm={onDisconnect}
          onCancel={() => {
            setShowAlert(false);
          }}
        />
      </UserTemplate>
  );
};

function mapStateToProps(state: any) {
  return {
    awairUserData: state.awair.userData,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { awair, loader } = dispatch;
  return {
    disconnect: awair.disconnect,
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AwairDisconnect);
