// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { functions } from './helpers';
import { SwitchGroupProps } from './interface';
import { WidgetSwitch } from '../../../common';
import './styles.scss';

const SwitchGroupV2: React.FC<SwitchGroupProps> = ({
  id,
  data,
  ckData,
  comesFrom,
  ...props
}) => {
  const [orderedEl, setOrderedEl]: any = useState([]);

  useEffect(() => {
    if (data && Object.values(data).length > 0) {
      let elements: any = functions.listElements(data);

      setOrderedEl(elements);
    }
  }, [data]);

  return (
    <div id="indoor-air-1">
      <div id={id} className={`switchs--wrapper`}>
        {orderedEl.map((element: any, index: any) => {
          const { id, name, alias, type } = element || {};
          return (
            <div className="col-xs-12 col-sm-12 col-md-6" key={index}>
              <div
                key={`${name}+${index}`}
                className={`switchs--wrapper--container`}
              >
                <WidgetSwitch
                  origin={`indoor-air-config`}
                  id={`${id}-${type}`}
                  label={name ? name : id}
                  rootLabel={id}
                  alias={alias}
                  value={functions.retrieveValue(type, ckData, id)}
                  onChange={props.onChange}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SwitchGroupV2;
