import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { FoobotConnection, FoobotDisconnect } from '../';

const foobot_path = '/foobot';
const FOOBOT_LABEL: string = '@air-foobot';

interface FoobotHomeProps {
  loading: any;
}

const FoobotHome: React.FC<FoobotHomeProps> = ({ loading, ...props }) => {
  const [path, setPath]: any = useState(null);

  useEffect(() => {
    loading.start();
    let foobotData: any = localStorage.getItem(FOOBOT_LABEL);

    setPath(
      foobotData
        ? '/dashboard/foobot/disconnect'
        : '/dashboard/foobot/connection',
    );
    loading.stop();
  }, []);

  return (
    <>
      <Route exact path={`/dashboard/foobot`}>
        {path && <Redirect to={path} />}
      </Route>
    </>
  );
};

function mapStateToProps(state: any) {
  const { foobot } = state;
  return {
    foobotUserData: foobot.userData,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    loading: {
      start: dispatch.loader.startLoader,
      stop: dispatch.loader.stopLoader,
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FoobotHome);
