import React from 'react';

interface FormDataProps {
  emailValue: string;
  handleInput: any;
  config: any;
  errors: any;
}

const FormData: React.FC<FormDataProps> = ({
  emailValue,
  handleInput,
  config,
  errors,
}) => {
  return (
    <div className="form-group form-group-p">
      <label className="label-field">
        {config?.strings?.email}
      </label>

      <input
        type="email"
        className="form-control"
        id="email-address-del-acc"
        placeholder="Enter your email"
        value={emailValue}
        onChange={handleInput}
      />

      {errors && errors?.email?.length > 0 && (
        <span className="input__span--isError">{errors.email}</span>
      )}
    </div>
  );
};

export default FormData;
