import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { ApiRequestPage } from './pages';


const component_path = '/api-request';

const ApiRequestRoutes: React.FC = props => {

  return (
    <>
      <BreadcrumbsItem to={`/admin/dashboard${component_path}`}>
        Requests
      </BreadcrumbsItem>
      <Switch>
        <Route
          exact
          path={`/admin/dashboard${component_path}`}
          component={ApiRequestPage}
        />
        <Route path={`/admin/dashboard${component_path}`} component={ApiRequestPage} />
      </Switch>
    </>
  );
};

export default ApiRequestRoutes;
