export default {
  strings: {
    email: `E-mail address`,
    submit: `Submit`,
    emailError: `Please, provide a valid e-mail address.`,
    breadcrumbItem: `Delete account`,
  },
  alert: {
    btn: {
      confirm: 'Delete account',
    },
    question: 'Are you sure you wish to proceed?',
  },
  notifications: {
    success: 'Deletion request successfully submitted!',
  },
  fieldsState: {
    email: '',
  },
  constraints: {
    email: {
      presence: true,
      email: true,
    },
  },
};
