export default {
  strings: {
    title: 'Airthings Connection',
    confirm: 'Connect',
  },
  alerts: {
    support: 'Not yet supported.'
  },
  notifications: {
    success: 'Successfully connected to Airthings.',
  },
  fieldsState: {
    clientId: '',
    clientSecret: '',
  },
  fieldsFocus: {},
  fields: {
    clientId: {
      label: 'Client Id',
      type: 'text',
      placeholder: 'Client Id',
    },
    clientSecret: {
      label: 'Client Secret',
      type: 'text',
      placeholder: 'Client Secret',
    },
  },
  constraints: {
    clientId: {
      presence: true,
      length: {
        minimum: 36,
      },
    },
    clientSecret: {
      presence: true,
      length: {
        minimum: 36,
      },
    },
  },
};
