import React, { useEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FiLogOut, FiHome, FiSettings, FiHelpCircle } from 'react-icons/fi';
import { MdRssFeed, MdDataExploration, MdSettingsRemote } from 'react-icons/md';
import { IconButton, makeStyles } from '@material-ui/core';
import {
  NavigateBefore,
} from '@material-ui/icons';
import { ZonesIcon as SystemsIcon } from 'business/assets/icons';
import { useAuth } from 'business/modules/sessions/hooks/auth';
import './styles.scss';



interface AsideProps {
  open: any;
  fade: any;
  toggleSidebar: any;
  container: any;
  settingsNodeIds: any;
  setSettingsNodeIds: any;
  setMenuState: Function;
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

const Aside: React.FC<AsideProps> = ({
  open,
  fade,
  toggleSidebar,
  container,
  settingsNodeIds,
  setSettingsNodeIds,
  setMenuState,
}) => {
  let timeoutsStack: any = [];
  const { signOut } = useAuth();
  const history: any = useHistory();
  const classes: any = useStyles();
  const asideElem: any = useRef(null);
  const menuElem: any = useRef(null);


  const resetTimeoutsStack = (stack: any) => {
    stack.forEach((item: any) => {
      clearTimeout(item);
    });
  };

  const onExpandSettings = (event: any, nodeIds: any) => {
    setSettingsNodeIds([...nodeIds]);
  };



  const onMouseEnter = useCallback(
    (event: any) => {
      if (open === 'closed') {
        resetTimeoutsStack(timeoutsStack);
        const asideEl: any = asideElem.current;
        if(asideEl) asideEl.classList.add('absolute-position', 'on-hovering');
        const menuEl: any = menuElem.current;
        if(menuEl) menuEl.classList.add('on-hover');
        const containerEl: any = container.current;
        if(containerEl) containerEl.classList.add('m-left-80');
      }
    },
    [open],
  );

  // to handle with the side menu when closed
  const onMouseLeave = useCallback(
    (event: any) => {
      if (open === 'closed') {
        let timeout = setTimeout(() => {
          const asideEl: any = asideElem.current;
          if(asideEl) asideEl.classList.remove('absolute-position');
          const containerEl: any = container.current;
          if(containerEl) containerEl.classList.remove('m-left-80');
        }, 600);
        timeoutsStack.push(timeout);
        const asideEl: any = asideElem.current;
        if(asideEl) asideEl.classList.remove('on-hovering');
        const menuEl: any = menuElem.current;
        if(menuEl) menuEl.classList.remove('on-hover');
      }
    },
    [open],
  );


  const handleSupport = (event: any) => {
    const supportUrl: string = 'https://www.aircycler.com/pages/support';
    const win: any = window.open(supportUrl, '_blank');
    win.focus();
  };



  useEffect(() => {
    setMenuState(open);
  }, [open]);


  return (
    <div id="left" className={open} ref={asideElem}>
      <div className="icon-menu">
        <IconButton id="expand-btn" onClick={toggleSidebar}>
          <NavigateBefore className={open} />
        </IconButton>
      </div>
      <div
        id="air-sidebar"
        className={`sidebar ${open}`}
        ref={menuElem}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className="header">
          <span
            className={`logo-text ${open}`}
            onClick={() => history.push('/installer')}
          ></span>
        </div>
        <div className="content sidebar-nav">
          <ul id="sidebarnav" className={`menu-${open}`}>
            <li className="sidebar-item">
              <button
                className="sidebar-link selected sidebar-link"
                onClick={() => history.push('/installer')}
                aria-expanded="false"
              >
                <FiHome className="menu-zones-icon" />
                <span className="hide-menu pl-2">Dashboard</span>
              </button>
            </li>
            <li className="list-divider"></li>


            <li className="sidebar-item">
              <button
                className="sidebar-link selected sidebar-link"
                onClick={() => {
                  history.push('/installer/systems');
                }}
                aria-expanded="false"
              >
                <SystemsIcon
                  style={{ transform: 'scale(0.9)' }}
                  className="menu-zones-icon"
                />{' '}
                {'  '}
                <span className="hide-menu pl-2">Systems</span>
              </button>
            </li>
            <li className="list-divider"></li>


            <li className="sidebar-item">
              <button
                className="sidebar-link selected sidebar-link"
                onClick={() => {
                  history.push('/installer/configurations');
                }}
                aria-expanded="false"
              >
                <MdSettingsRemote className="menu-zones-icon" /> {'  '}
                <span className="hide-menu pl-2">Configurations</span>
              </button>
            </li>
            <li className="list-divider"></li>



            <li className="sidebar-item">
              <button
                className="sidebar-link selected sidebar-link"
                onClick={() => {}}
                aria-expanded="false"
              >
                <MdRssFeed className="menu-zones-icon" /> {'  '}
                <span className="hide-menu pl-2">Activity Feed</span>
              </button>
            </li>
            <li className="list-divider"></li>
            <li className="sidebar-item">
              <button
                className="sidebar-link selected sidebar-link"
                onClick={handleSupport}
                aria-expanded="false"
              >
                <FiHelpCircle className="menu-zones-icon" /> {'  '}
                <span className="hide-menu pl-2">Support</span>
              </button>
            </li>
            <li className="list-divider"></li>

            <li className="sidebar-item menu-logout">
              <button
                className="sidebar-link sidebar-link"
                onClick={() => signOut()}
                aria-expanded="false"
              >
                <FiLogOut className="menu-zones-icon" />
                <span className="hide-menu pl-2"> Logout</span>
              </button>
            </li>
            <li className="sidebar-item menu-footer"></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    settingsNodeIds: state.aside.settingsNodeIds,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { aside }: any = dispatch || {};
  return {
    setSettingsNodeIds: aside.setSettingsNodeIds,
    setMenuState: aside.setMenuState,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Aside);
