import { ConnectionBroker } from '../../core';

export const adminSectionsState = {
  state: {
    theIdsAreLoaded: false,
    indoorAirCounter: 0,
    indoorAirChangesMenuIsVisible: false,
    outAirChangesMenuIsVisible: false,
    currentlyActiveTab: 'dev',
    noDataScreenIsVisible: true,
    currentSectionToUpdateByAdmin: '',
    currentSystemId: '',
    currentSystemName: '',
    outsideAirDetailsAdmin: [],
    oaDetailsAdmAreLoaded: false,
    selectedDevice: null,
    devDetailsToShowOnLocationUpdate: null,
    dataWasCleared: false,
    indoorAirDetailsAdmin: [],
    indoorAirDetailsAdmAreLoaded: false,
    devDetails: {},
    devDetailsAreLoaded: false,
    pressureConnectSettingsArr: [],
    pressureConnectSettingsAreLoaded: false,
    indoorAirSettingsArr: [],
    indoorAirSettingsAreLoaded: false,
    indoorAirAdmDetails: [],
    setIAadmDetailsLoaded: false,
    adminWeather: {},
    sensorAndDevData: [],
    sensorAndDevDataLoaded: false,
    mcConfirmation: false,
    indoorAirAdminValuesLoaded: false,
    indoorAirAdminUpdatedValues: {},
    outsideAirValuesUpdated: false,
    indoorAirAdminValuesUpdated: false,
    pressureConnectConfirmation: false,
    mergedSystems: [],
    mergedSystemsAreLoaded: false,
    numberOfDevs: 0,
    numberOfDevsRegistered: false,
    currentUpdatingSection: '',
    modalMustClose: false,
    thereWasMCUpdate: false,
    manualCtrlValues: {},
    oaWidgetStates: {
      temp: { max: -101, min: -101, current: 0, init: false },
      hum: { max: -101, min: -101, current: 0, init: false },
      aqi: { max: -101, min: -101, current: 0, init: false },
      cfm: { max: -101, min: -101, current: 0, init: false },
    },
    oaEnabled: false,
    outSettingsLoaded: false,
    currentDev: {
      system_id: '',
      setup: {
        slider: 0,
        checkbox: {
          fanConnect: false,
          dmst: false,
          cfcm: false,
        },
        turnedOn: false,
      },
    },
  },
  reducers: {
    setCurrentDev(state: any, payload: any) {
      return {
        ...state,
        currentDev: { ...payload },
      };
    },
    updateSwitch(state: any, payload: boolean) {
      return {
        ...state,
        oaEnabled: payload,
      };
    },
    updateOAStates(state: any, payload: any) {
      return {
        ...state,
        oaWidgetStates: { ...payload },
      };
    },
    saveMCUpdating(state: any, payload: any) {
      return {
        ...state,
        manualCtrlValues: payload,
      };
    },
    handleMCUpdate(state: any, payload: boolean) {
      return {
        ...state,
        thereWasMCUpdate: payload,
      };
    },
    handleModalState(state: any, payload: any) {
      return {
        ...state,
        modalMustClose: payload,
      };
    },
    setCurrentUpdatingSection(state: any, payload: string) {
      return { ...state, currentUpdatingSection: payload };
    },
    resetIndoorAirC(state: any) {
      return {
        ...state,
        indoorAirCounter: 0,
      };
    },
    setIndoorAirCounter(state: any, payload: any) {
      return {
        ...state,
        indoorAirCounter: state.indoorAirCounter + 1,
      };
    },
    setPCConfirmation(state: any, payload: boolean) {
      return {
        ...state,
        pressureConnectConfirmation: payload,
      };
    },
    setIAUpdate(state: any, payload: boolean) {
      return {
        ...state,
        indoorAirAdminValuesUpdated: payload,
      };
    },
    setOAUpdate(state: any, payload: boolean) {
      return {
        ...state,
        outsideAirValuesUpdated: payload,
      };
    },
    saveTheUpdatedIAValues(state: any, payload: any) {
      return {
        ...state,
        indoorAirAdminUpdatedValues: payload,
      };
    },
    setMcConfirmation(state: any, payload: boolean) {
      return {
        ...state,
        mcConfirmation: payload,
      };
    },
    toggleUpdateMenuVisibility(state: any, payload: any) {
      let payloadData = { state: payload.state, section: payload.section };
      if (payload.section === 'ia') {
        return {
          ...state,
          indoorAirChangesMenuIsVisible: payloadData.state,
        };
      } else if (payload.section === 'oa') {
        return {
          ...state,
          outAirChangesMenuIsVisible: payloadData.state,
        };
      } else if (payload.section === 'pc') {
        return {
          ...state,
          pressureConnectConfirmation: payloadData.state,
        };
      }
      return {
        ...state,
      };
    },
    changeTab(state: any, payload: any, origin?: string) {
      if (origin && origin === 'edit') {
        return {
          ...state,
          currentlyActiveTab: 'sys',
          noDataScreenIsVisible: true,
        };
      } else {
        return {
          ...state,
          currentlyActiveTab: payload,
          noDataScreenIsVisible: true,
        };
      }
    },
    editDetailsByAdmin(state: any, payload: any) {
      return {
        ...state,
        currentSectionToUpdateByAdmin: payload,
        noDataScreenIsVisible: false,
      };
    },
    saveSystemData(state: any, payload: any) {
      localStorage.setItem('currentSystemNameSysPage', payload.devName);
      return {
        ...state,
        currentSystemId: payload.deviceId,
        currentSystemName: payload.devName,
      };
    },
    setOutsideAirInfoAdmin(state: any, payload: any) {
      return {
        ...state,
        outsideAirDetailsAdmin: payload,
        oaDetailsAdmAreLoaded: true,
      };
    },
    setCurrentDeviceAdmin(state: any, payload: any) {
      return { ...state, selectedDevice: payload };
    },
    setDevDetails(state: any, payload: any) {
      return { ...state, devDetailsToShowOnLocationUpdate: payload };
    },
    eraseClearData(state: any) {
      return {
        ...state,
        dataWasCleared: false,
      };
    },
    eraseLocationDataOutAir(state: any) {
      return {
        ...state,
        dataWasCleared: true,
      };
    },
    setIndoorAirInfoAdmin(state: any, payload: any) {
      return {
        ...state,
        indoorAirDetailsAdmin: payload,
        indoorAirDetailsAdmAreLoaded: true,
      };
    },
    setDevicesDetails(state: any, payload: any) {
      return {
        ...state,
        devDetails: payload,
        devDetailsAreLoaded: true,
      };
    },
    setNumberOfDevs(state: any, payload: any) {
      return {
        ...state,
        numberOfDevs: payload,
        numberOfDevsRegistered: true,
      };
    },
    saveSettings(state: any, payload: any, type: string) {
      switch (type) {
        case 'out-clear':
          return {
            ...state,
            outSettingsLoaded: false,
          };
        case 'out':
          return {
            ...state,
            outSettingsLoaded: true,
          };
        case 'i-settings':
          return {
            ...state,
            indoorAirSettingsArr: payload,
            indoorAirSettingsAreLoaded: true,
          };
        case 'p-settings':
          return {
            ...state,
            pressureConnectSettingsArr: payload,
            pressureConnectSettingsAreLoaded: true,
          };
        case 'ia-details':
          return {
            ...state,
            indoorAirAdmDetails: payload,
            setIAadmDetailsLoaded: true,
          };
        default:
          return {
            ...state,
          };
      }
    },
    updateWeatherAdmin(state: any, payload: any) {
      const { adminWeather } = state;
      return {
        ...state,

        adminWeather: {
          ...adminWeather,
          ...payload,
        },
      };
    },
    saveSensorAndDevData(state: any, payload: any) {
      return {
        ...state,
        sensorAndDevData: state.sensorAndDevData.concat(payload),
        sensorAndDevDataLoaded: true,
      };
    },
    saveMergedSystems(state: any, payload: any) {
      return {
        ...state,
        mergedSystems: payload.body,
        mergedSystemsAreLoaded: true,
      };
    },
  },
  effects: (dispatch: any) => ({
    async getOutsideAirDetailsAdmin(data: any) {
      const that: any = this;
      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        `admin.outsideAir`,
        null,
        data,
      );

      if (response?.statusCode !== 200) {
        that.handleErr('outsideAirDetailsAdmErr');
      }
      if (response?.statusCode === 200) {
        that.setOutsideAirInfoAdmin(response?.body?.data);
      }

      return response;
    },
    async getIndoorAirDetailsAdmin(data: any) {
      const that: any = this;
      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        `admin.indoorAir`,
        null,
        data,
      );
      if (response?.statusCode === 200) {
        that.setIndoorAirInfoAdmin(response?.body.data);
      }
      return response;
    },
    async getManualCtrlDetailsAdmin(data: any) {
      const that: any = this;
      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        `admin.manualControl`,
        null,
        data,
      );

      if (response?.statusCode !== 200) {
        that.handleErr('manualControlDetailsAdmErr');
      }

      if (response?.statusCode === 200) {
      }
      return response;
    },
    //with merge of system data:
    async getAdminSystemSettingsList(data: any) {
      const that: any = this;
      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        'admin.adminSystemSettingsListPerUser',
        null,
        { id: data.id, merge: true },
      );
      if (response?.statusCode === 200) {
        that.saveMergedSystems({ body: response?.body });
      }
      return response;
    },
    //without merge:
    async getNoMergedAdminSystemSettings(data: any) {
      const that: any = this;
      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        'admin.adminSystemSettingsListPerUser',
        null,
        { id: data.id },
      );
      if (response?.statusCode === 200) {
        return response.body;
      }
      return response?.body ? response.body : response;
    },
    async getDeviceSettings(data: any) {
      /***
       *
       * debug helper only:
       */

      const getDevName = (devid: string) => {
        return devid === 'e66a001b-aa33-4521-228d-c07fb5474a30'
          ? 'AC18'
          : 'AirCyclerG3948258';
      };

      /***
       *
        
       */

      const that: any = this;
      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        `admin.adminSystemSettingsShow`,
        null,
        data,
      );

      if (response?.statusCode === 200) {
        that.setDevicesDetails(response?.body?.data);
      }
      return response;
    },
    async editManualControlAdmin(data: any) {
      const userAcc = localStorage.getItem('currentAccount');
      const that: any = this;

      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        `admin.manualControlEdit`,
        data,
        { id: userAcc },
      );
      if (response?.statusCode === 200) {
        that.handleModalState(true);

        //this is used to turn the flag true/ false and warn the component:
        that.handleMCUpdate(true);

        setTimeout(() => {
          that.handleMCUpdate(false);
        }, 1000);
      }
      return response;
    },
    async editOutsideAirAdmin(data: any) {
      const userAcc: any = localStorage.getItem('currentAccount');
      const that: any = this;

      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        `admin.outsideAirAdminEdit`,
        data,
        { id: userAcc },
      );
      if (response?.statusCode === 200) {
        that.setOAUpdate(true);

        await that.getOutsideAirDetailsAdmin({
          device_id: data,
          id: userAcc,
        });

        setTimeout(() => {
          that.setOAUpdate(false);
        }, 5000);

        that.handleModalState(true);
      } else {
        //todo handle error
        that.handleModalState(true);
      }

      return response;
    },
    async getAdminWeather(data: any) {
      const that: any = this;

      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        `admin.adminWeatherFeed`,
        null,
        data,
      );

      return response;
    },
    async editIndoorAirData(data: any) {
      const userAcc = localStorage.getItem('currentAccount');
      const that: any = this;
      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        `admin.indoorAirAdminEdit`,
        data,
        { id: userAcc },
      );

      if (response?.statusCode === 200) {
        that.handleModalState(true);

        that.setIAUpdate(true);
        setTimeout(() => {
          that.setIAUpdate(false);
        }, 5000);
      } else {
        that.handleModalState(true);
      }
      return response;
    },
    async editPressureConn(data: any) {
      const user_account = localStorage.getItem('currentAccount');
      const that: any = this;

      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        'admin.pressConnectEdit',
        data,
        { id: user_account },
      );

      if (response?.statusCode === 200) {
        that.handleModalState(true);
      } else {
        //handle error todo
        that.handleModalState(true);
      }
      return response;
    },
    async showPressureConnect(data: any) {
      const user_account = localStorage.getItem('currentAccount');
      const that: any = this;

      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        'admin.pressConnectShow',
        data,
        { id: user_account },
      );
      if (response?.statusCode === 200) {
      } else {
        //handle error todo
      }
    },
    async getProbeMostRecentValues(data: any) {
      let response: any;

      const tempResponse: any = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        'admin.getProbeLastValues',
        null,
        data,
      );
      if (tempResponse?.statusCode === 200) {
        response = tempResponse?.body ? { ...tempResponse.body } : null;
      }
      return response;
    },
  }),
};
