import React from 'react';
import './styles.scss';

interface LoadingProps {}

const Loading: React.FC<LoadingProps> = () => {
  return (
    <div className="container">
      <div className="row d-flex justify-content-center">
        <div className="col-12 d-flex justify-content-center">
          <div className="spinner-grow text-light bck-blue" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div className="spinner-grow text-light bck-blue" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div className="spinner-grow text-light bck-blue" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
