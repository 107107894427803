import React from 'react';

interface ContainerProps {
  children: any;
  cClass: string;
  rClass: string;
  cCls: string;
}

const Container: React.FC<ContainerProps> = ({
  children,
  cClass,
  rClass,
  cCls,
}) => {
  return (
    <div className={cClass}>
      <div className={rClass}>
        <div className={cCls}>{children} </div>{' '}
      </div>{' '}
    </div>
  );
};

export default Container;
