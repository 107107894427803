import React from 'react';
import { IoCheckmark } from 'react-icons/io5';
import { MdOutlineCancelPresentation } from 'react-icons/md';

export default {
  strings: {
    title: `Device information`,
    subtitle: `Device name`,
    origin: `name-location`,
    editTitle: `Edit Device`,
    breadcrumbItem: `Edit device name and location`,
    path: `/admin/dashboard/user-details/`,
    cancel: `CANCEL`,
    save: `SAVE`,
  },
  labels: {
    device_city: 'Device City',
    device_state: 'Device State',
    device_zipcode: 'Device Zip Code',
  },
  initState: {
    name: '',
    city: '',
    state: '',
    zip: '',
  },
  modalStrings: {
    confirm: ``,
    warning: `Information`,
    alertStr: ``,
    alertStr2: ``,
    confirmation: `Are you sure you wish to proceed?`,
  },
  notifications: {
    create: {
      success: 'System edited successfully!',
      error: 'Something went wrong!',
    },
  },
  titles: {
    edit__sys: `Update System`,
  },
  icons: {
    edit__sys: <IoCheckmark className="edit" />,
    cancel__op: <MdOutlineCancelPresentation className="edit" />,
  },
  bodies: {
    edit__sys: `You are about to edit this System.`,
  },
  _labels: {
    edit__sys: ` OK`,
  },
  bodyClass: {
    edit__sys: `alert alert-info`,
    edit__comp: `alert alert-danger`,
  },
  noLocationEditProps: ['device_city', 'device_state', 'device_zipcode'],
  errors: {
    devname_name1: 'Device name cannot be blank.',
    device_city1: 'Device city cannot be blank.',
    device_state1: 'Device state cannot be blank.',
    device_zipcode1: 'Device zipcode cannot be blank.',
  },
  correspondingValue: {
    devname_name1: 'name',
    device_city1: 'city',
    device_state1: 'state',
    device_zipcode1: 'zip',
  },
};
