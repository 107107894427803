export default {
  wrappVentTitle: (data: any, index: number) => {
    const { cail, cdid } = data || {};
    // return cail && cail !== '' ? cail : `VentConnect ${index + 1}`;
    return cail && cail !== '' ? cail : `VentConnect`;
  },
  wrappFreshTitle: (data: any, index: number, version: any) => {
    const { cail, cdid } = data || {};

    return cail && cail !== '' ? cail : version && version === 1 ? `FreshConnect` : `FreshConnect`;
  },
  createLabel: (option: string, value: any) => {
    switch (option) {
        case 'w-temp':
            return `${value}ºF`;
        case 'w-rhum':
            return `${value}%`;
        default:
            return value;
    }
  },
  subTitleByDeviceType: (type: any) => {
    let subTitle: string = '';

    switch (type) {
      case 1:
        subTitle = 'VentConnect';
        break;
      case 2:
        subTitle = 'FreshConnect';
        break;
      case 3:
        subTitle = 'PressureConnect';
        break;
      default:
        break;
    }

    return subTitle;
  },
};
