import React, { useEffect } from 'react';
import './styles.scss';


interface FormProps {
  page?: any;
}

const Form: React.FC<FormProps> = ({ page, children }) => {

  const handleBodyClassName = (page: any) => {
    switch (page) {
      case 'sign-up--confirm':
        return 'signUp--confirm';
      default:
        return 'session-form-body';
    }
  };


  useEffect(() => {
    const body = document.querySelector('body');
    if(body){
      body.classList.add('login');
    }
    return () => {};
  }, []);

  return (
    <div id="sessions-form" className="main-wrapper">
      <div className="auth-wrapper d-flex no-block justify-content-center align-items-center position-relative">
        <div className="auth-box row">
          <div className="col-lg-12 col-md-12 bg-white">
            <div
              className={handleBodyClassName(page)}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
