import React, { useState, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Button } from 'react-bootstrap';
import validate from 'validate.js';
import { Input } from '../../../../../react-components';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import config from './config';
import Form from '../../components/FormSession';

const configInputs: any = config;
const SignIn: React.FC = () => {
  const history = useHistory();
  const { signIn } = useAuth();
  const [fieldsState, setFieldsState] = useState(configInputs.fieldsState);
  const [errors, setErrors]: any = useState(true);
  const [loading, setLoading]: any = useState(false);
  const handleSubmit = useCallback(
    async () => {
      setErrors(false);
      setLoading(true);
      let isValid = true;
      await validate.async(fieldsState, config.constraints).then(null, err => {
        setErrors(err);
        isValid = false;
      });
      if (!isValid) return;
      await signIn(fieldsState);
      setLoading(false);
    },

    // eslint-disable-next-line
    [fieldsState],
  );

  useEffect(() => {
    const body = document.querySelector('body');
    if(body){
        body.classList.add('login');
    }
    return () => {};
  }, []);

  return (
    <Form>
      <Helmet>
        <title>{config.strings.title} - AirCycler</title>
      </Helmet>
      <h2 className="mt-3 mb-3 text-center">{config.strings.title}</h2>
      <div className="row">
        {Object.keys(configInputs.fields).map((name, index) => (
          <Input
            handleChange={(value: any) =>
              setFieldsState((prevState: any) => ({
                ...prevState,
                [name]: value,
              }))
            }
            handleChangeError={(err: any) =>
              setErrors((prevState: any) => ({
                ...prevState,
                [name]: err,
              }))
            }
            type={configInputs.fields[name].type}
            cols={configInputs.fields[name].cols}
            label={configInputs.fields[name].label}
            name={name}
            maximum={configInputs.fields[name].maximum}
            value={fieldsState[name]}
            error={errors[name]}
            autoFocus={configInputs.fields[name].autofocus}
            constraint={configInputs.constraints[name]}
            key={index}
          />
        ))}
      </div>

      <div className="d-flex  pt-4">
        <Button
          variant="primary"
          className="btn  btn-primary btn-block"
          type="button"
          disabled={loading}
          onClick={() => handleSubmit()}
        >
          {config.strings.login}
        </Button>
      </div>

      <div className="d-flex justify-content-center align-items-center pt-4">
      <Button
        variant="link"
        className="btn btn-sm btn-link"
        type="button"
        disabled={loading}
        onClick={() => history.push('/login')}
      >
        Cancel
      </Button>
      </div>

    </Form>
  );
};

export default SignIn;
