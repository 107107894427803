import { ConnectionBroker } from '../../core';

const STORAGE_PREFIX: string = '@air:brex-slice->';

export const devices = {
  state: {
    devices: [],
    systems: [],
    currentDevice: null,
    currentSystem: null,
    peripheral: null,
    allSensors: [],
  },
  reducers: {
    updateState(state: any, payload: any, name: string) {
      return { ...state, [name]: payload };
    },
    setCurrentDevice(state: any, payload: any) {
      return { ...state, currentDevice: payload };
    },
    setCurrentSystem(state: any, payload: any) {
      return { ...state, currentSystem: payload };
    },
    setPeripheral(state: any, payload: any) {
      return { ...state, peripheral: payload };
    },
    resetSystems(state: any, payload: any){
      return { ...state, systems: [] };
    },
  },
  effects: (dispatch: any) => ({
    async getAllDevices() {
      const that: any = this;
      let response = await ConnectionBroker.fetchAPI('jitBE', 'devices.list');
      if (response?.statusCode === 200) {
        that.updateState(response.body, 'devices');
      } else {
        // TODO: add error handler
      }
      return response;
    },
    async getAllDevicesWithoutUpdateState() {
      const that: any = this;
      let response = await ConnectionBroker.fetchAPI('jitBE', 'devices.list');
      if (response?.statusCode === 200) {
        return response.body;
      } else {
        // TODO: add error handler
      }
      return response;
    },
    async getDeviceDetails(data: object) {
      const that: any = this;
      let response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'devices.show',
        null,
        data,
      );
      if (response?.statusCode === 200) {
        that.setCurrentDevice(response.body);
      } else {
        // TODO: add error handler
      }
      return response;
    },
    async editDevice(payload: any) {
      const that: any = this;
      let response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'devices.update',
        payload,
      );
      if (response?.statusCode === 200) {
        let data: any = { device_id: payload.device_id };
        await that.getDeviceDetails(data);
        await that.getAllDevices();
      } else {
        // TODO: add error handler
      }
      return response;
    },
    async editDeviceAttributes(payload: any) {
      const that: any = this;
      let response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'devices.update_attrr',
        payload,
      );

      if (response?.statusCode === 200) {
        let data: any = { device_id: payload.device_id };
        // await that.getDeviceDetails(data);
      } else {
        // TODO: add error handler
      }
      return response;
    },
    async deleteDevice(data: any) {
      const that: any = this;
      if (data && data.device_id) {
        let response = await ConnectionBroker.fetchAPI(
          'jitBE',
          'devices.delete',
          data,
        );
        if (response?.statusCode === 200) {
          await that.getAllDevices();
        } else {
          // TODO: add error handler
        }
        return response;
      }
    },
    reset(payload: any, rootState: any) {
      const self: any = this;
      const { devices } = rootState;
      if (devices?.devices.length > 0) {
        devices.devices.forEach((_device: any) => {
          const { device_id } = _device;
          const stLabel: string = `${STORAGE_PREFIX}${device_id}`;
          localStorage.removeItem(stLabel);
        });
      }
      self.updateState([], 'devices');
      self.updateState(null, 'currentDevice');
      self.updateState(null, 'peripheral');
    },
    async getSystems() {
      const that: any = this;
      let response = await ConnectionBroker.fetchAPI('jitBE', 'systems.list');
      if (response?.statusCode === 200) {
        that.updateState(response.body, 'systems');
      } else {
        // TODO: add error handler
      }
      return response?.body;
    },
    async getAllSensors() {
      const that: any = this;
      let response = await ConnectionBroker.fetchAPI('jitBE', 'devices.list');
      if (response?.statusCode === 200) {
        const sensors: any = response.body.filter((dev: any) => {
          const { device_type } = dev;

          switch (device_type) {
            case 'foobot':
            case 'airthings':
            case 'awair':
            case 'awair-element':
            case 'awair-omni':
              return true;
            default:
              return false;
          }
        });

        that.updateState(sensors, 'allSensors');
      }

      return [];
    },

    async getSystemStatus(data: object) {
      const that: any = this;
      const { system_id }: any = data || {};
      const response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'systems.status',
        null,
        { device_id: system_id }
      );

      if (response?.statusCode === 200) {
        return response?.body?.data ? response.body.data : null;
      }

      return response;
    },

  }),
};
