import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Notification } from '../../../../../react-components';
import { useHistory, useLocation } from 'react-router-dom';
import config from './config';

import { BsPersonCheck } from 'react-icons/bs';
import Form from '../../components/FormSession';
import { ConnectionBroker } from '../../../../../core';

const SignIn: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading]: any = useState(false);

  const handleResendCode = useCallback(async () => {
    setLoading(true);
    try {
      let response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'session.resendCode',
        { email: location.state.user.email },
      );
      if (response.result) {
        Notification({
          title: 'Successfully',
          message: 'code successfully sent',
          type: 'success',
        });
      } else {
        Notification({
          title: 'Sorry',
          message: 'the code could not be sent',
          type: 'warn',
        });
      }
      setLoading(false);
    } catch (err) {}
  }, [location]);


  useEffect(() => {
    if (!location.state) {
      history.push('/login');
    }
    const body = document.querySelector('body');
    if(body){
        body.classList.add('login');
    }
    return () => {};
  }, [location, history]);


  return (
    <Form page="sign-up--confirm">
      <Helmet>
        <title>{config.strings.title} - AirCycler</title>
      </Helmet>
      <div className="mb-2 text-center">
        <BsPersonCheck size={80} className="text-primary" />
      </div>
      <div className="text-center mb-3">
        <h4>{config.strings.title}</h4>
        <p>{config.strings.subtitle}</p>
      </div>
      <div className="d-flex pb-4 pt-3">
        <Button
          variant="outline-primary"
          className=" btn-block"
          type="button"
          onClick={() => handleResendCode()}
          disabled={loading}
        >
          {config.strings.resend}
        </Button>
      </div>

      <div className="d-flex pt-4">
        <Button
          variant="primary"
          className="btn  btn-primary btn-block"
          type="button"
          onClick={() => history.push('/login')}
        >
          {config.strings.confirm}
        </Button>
      </div>
    </Form>
  );
};

export default SignIn;
