import React, { useState } from 'react';

export function useStatesReducer(reducer: any, initialState: any) {
  const [state, setState]: any = useState(initialState);

  function dispatch(action: any) {
    let tempState: any = reducer(state, action);
    setState(tempState);
  }

  return [state, dispatch];
}

export function statesReducer(state: any, action: any) {
  const { data, type, prop } = action;
  switch (type) {
    case 'init': // TODO: not done
      return state;
    case 'updateOnce':
      return {
        ...state,
        [prop]: data,
      };
    case 'update':
      const { pointer, value, init } = data;
      return {
        ...state,
        [prop]: {
          ...state[prop],
          [pointer]: value,
          init: init,
        },
      };
    case 'updateMulti':
      let stateProp: any = {};
      data.forEach((item: any) => {
        const { pointer, value, init } = item;
        stateProp[pointer] = value;
        stateProp.init = init;
      });
      return {
        ...state,
        [prop]: { ...stateProp },
      };
    case 'updateMultiProps':
      let stateClone: any = { ...state };
      data.forEach((item: any) => {
        const { _prop, pointer, value, init } = item;
        const propClone: any = Object.assign({ ...stateClone[_prop] });
        stateClone[_prop][pointer] = value;
        stateClone[_prop].init = init;
      });
      return { ...stateClone };
    case 'reset': // TODO: not done
      return {
        ...state,
      };
    default:
      return state;
  }
}


export function ckStatesReducer(state: any, action: any) {
  const { data, type, prop } = action;
  switch (type) {
    case 'init':
      return state;
    case 'updateOnce':
      return {
        ...state,
        [prop]: data,
      };
    case 'updateCk':
      const { index } = data;
      let ckData: any = [...state[prop]];
      ckData[index][data.pointer] = data.value;
      return {
        ...state,
        [prop]: [...ckData],
      };
    case 'reset':
    default:
      return state;
  }
}
