import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import validate from 'validate.js';
import _ from 'lodash';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { InstallerTemplate } from 'business/modules/common';
import { Notification } from 'react-components';
import { Form, Text, Card } from './components';
import config from './config';
import './styles.scss';



const DEFAULT_CONSTRAINTS: any = { ...config.constraints };
const DEFAULT_FIELDS: object = {...config.fieldsState};

interface LoadingProps {
  start: any;
  stop: any;
}

interface DeleteAccountProps {
  loading: LoadingProps;
  requestDelete: Function;
}

const DeleteAccount: React.FC<DeleteAccountProps> = ({ ...props }) => {
  const [modalControl, setModalControl]: any = useState(false);
  const [emailValue, setEmailValue]: any = useState({...DEFAULT_FIELDS});
  const [disableSubmit, setDisableSubmit]: any = useState(true);
  const [errors, setErrors]: any = useState();


  const handleInput = async (event: any) => {
    const val: string = event?.target?.value || '';

    setEmailValue((prevValues: any) => {
      return {
        ...prevValues,
        email: val,
      };
    });
  };

  const cancelRequest = () => {
    setModalControl(false);
    setEmailValue((prevValues: any) => {
      return {
        ...prevValues,
        email: '',
      };
    });
  };

  const handleSubmit = (event: any) => {
    setModalControl(true);
  };

  const printError = () => {
    setErrors({
      ...errors,
      email: config?.strings?.emailError,
    });
  };

  const handleRequestResponse = (data: any) => {
    const { code, message }: any = data || {};
    switch (code) {
      case 200:
        Notification({
          title: 'Success',
          message: message,
          type: 'success',
        });
        break;
      default:
        break;
    }

    cancelRequest();
  };


  const sendDeleteRequest = async () => {
    setModalControl(false);
    props.loading.start('Requesting Delete...');
    const { email }: any = emailValue || {};
    const response: any = await props.requestDelete({ email });
    props.loading.stop();

    handleRequestResponse(response);
  };



  useEffect(() => {
    if(emailValue?.email && emailValue.email !== ''){
      let constraintsCopy: any = _.cloneDeep(DEFAULT_CONSTRAINTS);
      const valResult: any = validate(emailValue, constraintsCopy);

      if(valResult){
        setDisableSubmit(true);
        printError();
      }else{
        setErrors({
          ...errors,
          email: ``,
        });
        setDisableSubmit(false);
      }
    }else{
      setDisableSubmit(true);
    }
  }, [emailValue]);


  useEffect(() => {

    return () => {
      setDisableSubmit(true);
      setEmailValue(DEFAULT_FIELDS);
      setErrors();
    };
  }, []);

  return (
    <InstallerTemplate
      id="delete-installer-acc"
      title="Delete account"
      titleBackButton
    >
      <BreadcrumbsItem to={`/installer/delete-account`}>
        {config?.strings?.breadcrumbItem}
      </BreadcrumbsItem>
      <Card>
        <Text />
        <Form
          config={config}
          showModal={modalControl}
          value={emailValue?.email}
          errors={errors}
          btnDisabled={disableSubmit}
          handleInput={handleInput}
          handleCancel={cancelRequest}
          handleSubmit={handleSubmit}
          requestDeleteHandler={sendDeleteRequest}
        />
      </Card>
    </InstallerTemplate>
  );
};


function mapStateToProps(state: any) {
  return {};
}

function mapDispatchToProps(dispatch: any) {
  const { loader, requestDelete }: any = dispatch || {};

  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    requestDelete: requestDelete.requestDeleteAccount,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccount);
