// @ts-nocheck
import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { functions } from './helpers';
import config from './config';
import { connect } from 'react-redux';
import { InstallerOutsideAirProps } from './interface';
import { DoubleSlider, SingleSlider, GradSlider } from '../common';
import { SwitchButton, Save, SwitchBox } from './components';
import './styles.scss';

const COMPONENT_STRINGS: any = { ...config?.strings };
const DEFAULT_EXTREMS: any = { ...config.sliderValues };
const DEFAULT_VALUES: any = { ...config.sliderValues.defaults };

const InstallerOutsideAir: React.FC<InstallerOutsideAirProps> = ({
  loading,
  handleConfigValidation,
  showBtnCtrl,
  showBtn,
  configShowDataLoaded,
  configShowData,
  prepareToSaveSetupStore,
  ...props
}) => {
  const [showAlert, setShowAlert]: boolean = useState(false);
  const [showSaveBtn, setShowSaveBtn]: any = useState(false);
  const [
    outsideAirSectionIsEnabled,
    setOutsideAirSectionIsEnabled,
  ]: boolean = useState(false);
  const [widgetStates, setWidgetStates]: any = useState({
    temp: {
      max: 75,
      min: 10,
      current: -20,
    },
    hum: {
      max: 80,
    },
    aqi: {
      max: 100,
    },
    cfm: {
      max: 50,
    },
  });

  const transformSingleSlider = useCallback((values: any) => {
    const { max, min, current }: any = values || {};
    return [current, max];
  });

  const transformDoubleSlider = useCallback((values: any) => {
    const { max, min, current }: any = values || {};
    return [min, current, max];
  });

  const handleSaveOutsideData = async (event: any) => {
    let errValidation = await handleConfigValidation();

    if (errValidation) {
      setShowAlert(false);
    } else {
      setShowSaveBtn(false);
    }
  };

  const toggleSection = (status: any) => {
    setOutsideAirSectionIsEnabled(!status);
    setShowSaveBtn(true);
    showBtnCtrl(true);
  };

  const onChangeTemps = (newValues: any) => {
    setWidgetStates((prevValues: any) => {
      return {
        ...prevValues,
        temp: {
          ...prevValues.temp,
          min: newValues[0],
          max: newValues[2],
        },
      };
    });
    setShowSaveBtn(true);
  };

  const onChangeHumidity = (newValues: any) => {
    setWidgetStates((prevValues: any) => {
      return {
        ...prevValues,
        hum: {
          ...prevValues.hum,
          min: newValues[0],
          max: newValues[1],
        },
      };
    });
    setShowSaveBtn(true);
  };

  const onChangeAqi = (newValues: any) => {
    setWidgetStates((prevValues: any) => {
      return {
        ...prevValues,
        aqi: {
          ...prevValues.aqi,
          min: newValues[0],
          max: newValues[1],
        },
      };
    });
    setShowSaveBtn(true);
  };

  useEffect(() => {
    if (showSaveBtn) {
      showBtnCtrl(true);
    }
  }, [showSaveBtn]);

  useEffect(() => {
    if (!showBtn) {
      setShowSaveBtn(false);
    }
  }, [showBtn]);

  useEffect(() => {
    if (configShowDataLoaded && configShowData.length > 0) {
      const widgetsValues: any = functions.handleOutsideValues(
        configShowData[0]?.setup,
      );

      setOutsideAirSectionIsEnabled(
        configShowData[0].setup?.outsideAir?.turnedOn,
      );
      setWidgetStates(widgetsValues);
    }
  }, [configShowData, configShowDataLoaded]);

  useEffect(() => {
    const preparedData: any = functions.prepareToSaveSetup(
      widgetStates,
      outsideAirSectionIsEnabled,
    );

    prepareToSaveSetupStore(preparedData);
  }, [widgetStates, outsideAirSectionIsEnabled]);

  return (
    <div className="" id="installer--outside-air-edit">
      <SwitchBox>
        <SwitchButton
          stringInfo={COMPONENT_STRINGS.title}
          id="outside-section"
          onChange={() => {
            toggleSection(outsideAirSectionIsEnabled);
          }}
          value={outsideAirSectionIsEnabled}
        />
      </SwitchBox>

      <div className="installer-edit-outside-wrapper">
        <div className="row">
          <div className="installer-slider-wrapper">
            <DoubleSlider
              id="w-temp-edit-config"
              values={transformDoubleSlider(widgetStates?.temp)}
              max={DEFAULT_EXTREMS.temp.max}
              min={DEFAULT_EXTREMS.temp.min}
              step={1}
              col={2}
              showLabel={true}
              symbol="ºF"
              onChange={onChangeTemps}
              defaultMarks={true}
              defaultMarksValues={{
                left: {
                  top: 39,
                  left: 21.5,
                  value: DEFAULT_VALUES.temp.min,
                },
                right: {
                  top: 40,
                  right: 32,
                  value: DEFAULT_VALUES.temp.max,
                },
              }}
            />
          </div>
          <div className="installer-slider-wrapper">
            <SingleSlider
              id="w-rhum-edit-config"
              values={transformSingleSlider(widgetStates?.hum)}
              max={DEFAULT_EXTREMS.hum.max}
              min={DEFAULT_EXTREMS.hum.min}
              step={1}
              col={2}
              showLabel={true}
              symbol="%"
              onChange={onChangeHumidity}
              defaultMark={true}
              defaultMarkValues={{
                top: 40,
                left: 79.7,
                value: DEFAULT_VALUES.hum.max,
              }}
            />
          </div>
          <div className="installer-slider-wrapper">
            <GradSlider
              id="w-aqi-edit-config"
              values={transformSingleSlider(widgetStates?.aqi)}
              max={DEFAULT_EXTREMS.aqi.max}
              min={DEFAULT_EXTREMS.aqi.min}
              step={1}
              col={2}
              showLabel={true}
              symbol=""
              onChange={onChangeAqi}
            />
          </div>
        </div>
      </div>

      <Save
        showSaveBtn={showSaveBtn}
        onClick={handleSaveOutsideData}
        config={config}
      />
    </div>
  );
};

function mapStateToProps(state: any) {
  const { userConfigs } = state;
  return {
    configShowDataLoaded: userConfigs.configShowDataLoaded,
    configShowData: userConfigs.configShowData,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { loader, userConfigs } = dispatch;
  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    prepareToSaveSetupStore: userConfigs.prepareToSaveSetupStore,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InstallerOutsideAir);
