import React from 'react';
import { GridCardProps } from './interface';
import './styles.scss';

const GridCard: React.FC<GridCardProps> = ({
  title,
  subtitle,
  description,
}) => {
  return (
    <div className="card dash-grid-card">
      <div className="card-body dash-card-body-grid">
        <h5 className="card-title">{title}</h5>
        <h6 className="card-subtitle mb-2 text-muted">{subtitle}</h6>
        <p className="card-text lead descript">{description}</p>
      </div>
    </div>
  );
};

export default GridCard;
