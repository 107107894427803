import React from 'react';
import config from './config';
import { formatInTimeZone } from 'date-fns-tz';
import foobot_dd from '../../../../../../assets/small/foobot_dd.png';
import airthings from '../../../../../../assets/small/airthings.jpg';
import awair from '../../../../../../assets/small/awair.png';

const returnPlaceHolder = (elem: string) => {
  let placeholder = [];
  let plc;
  let lower = elem?.match(/[a-z]+|[0-9]+/g);
  let upper = elem?.match(/[A-Z][a-z]+|[0-9]+/g);

  if (lower) {
    placeholder.push(lower[0]);
  }
  if (upper) {
    placeholder.push(upper.join(' '));
  }
  plc = placeholder.join(' ').toLowerCase();
  return plc.charAt(0).toUpperCase() + plc.slice(1);
};

const returnTypes = (type: string, elem?: any) => {
  switch (type) {
    case 'string':
      return elem && elem === 'systemLocationZipCode' ? 'number' : 'text';
    case 'select':
      return 'select';
    case 'boolean':
      return elem && elem === `hoursOfOperation` ? 'radio' : 'switch';
    case 'number':
      return 'text';
    default:
      return 'text';
  }
};

const checkIfNum = (val: string) => {
  if (config.inputTypes.hasOwnProperty(val)) {
    return config.inputTypes[val as keyof typeof config.inputTypes];
  }
};

const determineIcon = (type: string) => {
  if (type === 'airthings') {
    return (
      <img
        src={airthings}
        style={{ width: '25px', height: '27px', marginRight: '0.2rem' }}
      />
    );
  }
  if (type === 'foobot') {
    return <img src={foobot_dd} />;
  }
  if (type === 'awair-element' || type === 'awair-omni') {
    return (
      <img
        src={awair}
        style={{ width: '24px', height: '26px', marginRight: '0.35rem' }}
      />
    );
  }
};

const returnAdditionalLabel = (element: string) => {
  if (config.fieldsThatRequireAddLabel.includes(element)) {
    return true;
  } else return false;
};

const checkIfInvalidValue = (category: string, val: any) => {
  if (category === 'hour' && (val < 0 || val > 23)) {
    return true;
  }
  if (category === 'hour' && val >= 0 && val <= 23) {
    return false;
  }
  if (category === 'min' && (val < 0 || val > 60)) {
    return true;
  }
  if (category === 'min' && val >= 0 && val <= 60) {
    return false;
  }
  if (category === 'cfm' && (val < 0 || val > 250)) {
    return true;
  }
  if (category === 'cfm' && val > 0 && val < 250) {
    return false;
  }
};

const isCfm = (name: string) => {
  switch (name) {
    case 'flowMeasuredHeatCall':
    case 'flowMeasuredCoolingCall':
    case 'flowMeasuredFanOnlyCall':
    case 'requiredContinuousAirFlow':
    case 'exhaustFanFlowRate':
      return true;
    default:
      return false;
  }
};

const pickControlTarget = (details: any, connDevs: any) => {
  const { conf } = details?.settings || {};
  let controlTarget: any;
  if (conf) {
    const { exct } = conf;
    switch (exct) {
      case 1:
        controlTarget =
          connDevs && connDevs.length > 0
            ? connDevs.find((dev: any) => dev.cdid === 'fanConnect')
            : null;
        break;
      case 2:
        const { cdvs } = conf;
        const tDev: any = cdvs.find((dev: any) => dev.vcct === 1);

        if (tDev) {
          const { cdid } = tDev;
          controlTarget =
            connDevs && connDevs.length > 0
              ? connDevs.find((dev: any) => dev.cdid === cdid)
              : null;
        } else {
        }
        break;
      default:
        break;
    }
  }

  return controlTarget;
};

const mapConnectedLabel = (devId: any) => {
  let label: string = '';

  if (devId !== null && devId !== undefined) {
    if (devId.startsWith('AirCyclerVC')) label = 'VentConnect';
    if (devId.startsWith('AirCyclerPC')) label = 'PressureConnect';
    if (devId.startsWith('AirCyclerFH')) label = 'FreshConnect';
    if (devId.startsWith('AirCyclerFT')) label = 'FreshConnect2';
  }

  return label;
};

const validateTypeOfState = (name: string) => {
  switch (name) {
    case 'systemName':
    case 'systemLocationState':
    case 'systemLocationCity':
    case 'systemLocationZipCode':
      return 'isSystemIdData';

    case 'minutesPerHourSupplyFlow':
    case 'minutesPerHourExhaustRun':
    case 'additionalMixTime':
    case 'flowMeasuredHeatCall':
    case 'flowMeasuredCoolingCall':
    case 'flowMeasuredFanOnlyCall':
    case 'requiredContinuousAirFlow':
    case 'exhaustFanFlowRate':
    case 'exhaustFanDelayTime':
      return 'isVentData';

    default:
      break;
  }
};

const handleOpHours = (disabled: boolean, id: string | undefined) => {};

const mapVentSetupMode = (mode: string) => {
  switch (mode) {
    case 'Calculated Flow':
      return config._config.default.ventSetupTags.calcFlow;
    case 'Calculated Time':
      return config._config.default.ventSetupTags.calcTime;
    case 'Balanced Mode':
      return config._config.default.ventSetupTags.balancedMode;
    default:
      return [];
  }
};

const checkIfThereIsCdid = (filteredDevicesTmp: any) => {
  if (
    filteredDevicesTmp &&
    filteredDevicesTmp.length > 0 &&
    filteredDevicesTmp[0].cdid &&
    filteredDevicesTmp[0].cdid !== ''
  ) {
    return true;
  } else {
    return false;
  }
};

const validateSensor = (device: any) => {
  if (
    device.device_type === 'foobot' ||
    device.device_type === 'airthings' ||
    device.device_type === 'awair' ||
    device.device_type === 'awair-element' ||
    device.device_type === 'awair-omni'
  ) {
    return device;
  }
};

const handleInitialSettings = (
  currSystemDetails: any,
  filteredDevicesTmp: any,
  elem: any,
  cdvs: any,
) => {
  let initialSetting: any;

  if (currSystemDetails?.details) {
    const _controlTarget: any = pickControlTarget(
      currSystemDetails?.details,
      filteredDevicesTmp,
    );

    if (_controlTarget) {
      const { cdid } = _controlTarget;

      if (
        elem?.settings &&
        Object.values(elem?.settings).length > 0 &&
        elem.settings[cdid]
      ) {
        initialSetting = elem.settings[cdid];
      } else {
        if (checkIfThereIsCdid(filteredDevicesTmp)) {
          initialSetting = {
            ...elem.settings[filteredDevicesTmp[0].cdid],
          };
        } else {
          initialSetting = Object.values(elem.settings)[0];
        }
      }
    } else {
      initialSetting =
        elem?.settings && Object.values(elem?.settings).length > 0
          ? Object.values(elem.settings)[0]
          : cdvs && cdvs.length > 0 && cdvs[0].cdid && cdvs[0].cdid !== ''
          ? { ...elem.settings[cdvs[0].cdid] }
          : Object.values(elem.settings)[0];
    }
  } else {
    initialSetting =
      elem?.settings && Object.values(elem?.settings).length > 0
        ? Object.values(elem.settings)[0]
        : filteredDevicesTmp &&
          filteredDevicesTmp.length > 0 &&
          filteredDevicesTmp[0].cdid &&
          filteredDevicesTmp[0].cdid !== ''
        ? { ...elem.settings[filteredDevicesTmp[0].cdid] }
        : Object.values(elem.settings)[0];
  }

  return initialSetting;
};

const handleElemPlaceholder = (
  elem: any,
  hasVentConnect: boolean | undefined,
  primaryCont: any,
  systemDetails: any,
) => {
  if (elem === 'minutesPerHourExhaustRun') {
    if (
      hasVentConnect &&
      primaryCont &&
      primaryCont[0] === 'FanConnect' &&
      systemDetails.hasOwnProperty('minutesPerHourExhaustRunFC')
    ) {
      return systemDetails['minutesPerHourExhaustRunFC'];
    } else if (
      hasVentConnect &&
      primaryCont &&
      primaryCont[0] !== 'FanConnect' &&
      systemDetails.hasOwnProperty('minutesPerHourExhaustRunVC')
    ) {
      return systemDetails['minutesPerHourExhaustRunVC'];
    }
  }

  if (elem === 'exhaustFanDelayTime') {
    if (
      hasVentConnect &&
      primaryCont &&
      primaryCont[0] === 'FanConnect' &&
      systemDetails.hasOwnProperty(elem)
    ) {
      return systemDetails[elem];
    } else if (
      hasVentConnect &&
      primaryCont &&
      primaryCont[0] !== 'FanConnect' &&
      systemDetails.hasOwnProperty(elem)
    ) {
      return systemDetails[elem];
    }
  } else if (systemDetails.hasOwnProperty(elem)) {
    return systemDetails[elem];
  }
};

const validateDiffs = (
  _config: any,
  currSettings: any,
  currDevSettings: any,
  key: any,
  currentModeTags: any,
) => {
  if (_config.default.dependentProps[key]) {
    if (
      _config.default.dependentProps[key].value ==
      currSettings[key][_config.default.dependentProps[key].prop]
    ) {
      if (currDevSettings[key] !== currSettings[key]) {
        return true;
      }
    }
  } else {
    if (
      currentModeTags.findIndex((prop: string) => prop === key) > -1 &&
      currDevSettings[key] !== currSettings[key]
    ) {
      return true;
    }
  }
};

const determineCurrMode = (ventMode: any, currDevSettings: any) => {
  if (ventMode) {
    return ventMode;
  } else if (currDevSettings?.vent_set) {
    return currDevSettings?.vent_set;
  } else {
    return 'Calculated Flow';
  }
};

const handleSensors = (key: any, currDevSettings: any, allSensors: any) => {
  if (
    key === 'add_sensor' &&
    currDevSettings[key] &&
    !currDevSettings.hasOwnProperty('add_sensor_id')
  ) {
    const matchSensor: any =
      allSensors && allSensors.length > 0
        ? allSensors.find(
            (devSensor: any) => devSensor.device_name === currDevSettings[key],
          )
        : null;

    if (matchSensor) {
      return {
        add_sensor: currDevSettings[key],
        add_sensor_id: matchSensor?.device_id,
      };
    } else {
      return { add_sensor: currDevSettings[key] };
    }
  }
  if (key === 'add_sensor_id' && currDevSettings.hasOwnProperty(key)) {
    //devSensor? instead of devSensor because the one that has not device_id (SENSOR-AIRCYCLERAQ123456)
    const foundSensor: any =
      allSensors && allSensors.length > 0
        ? allSensors.find(
            (devSensor: any) => devSensor?.device_id === currDevSettings[key],
          )
        : null;

    if (foundSensor) {
      return {
        add_sensor_id: currDevSettings[key],
        add_sensor: currDevSettings['add_sensor'],
      };
    }
  }
};

const handleSettings = (
  devSett: any,
  sensorsList: any,
  sensorsAreLoaded: boolean,
) => {
  let data = {};
  let checkIfSensorExistsInList: any = [];
  //some additional validation to confront to existing sensors list,
  // just to make sure that we do not pass deleted sensors, and prevent errors

  if (devSett?.body?.data.hasOwnProperty('message')) {
    return;
  } else if (Object.keys(devSett?.body?.data?.settings).length === 0) {
    data = { ...data, sensorName: 'MachineShop' };
    return data;
  } else {
    let _settings: any = Object.entries(devSett?.body?.data?.settings)[0][1];

    if (_settings && _settings.hasOwnProperty('add_sensor_id')) {
      //confront this info with the sensors list because sometimes there are information about already deleted sensors

      if (sensorsAreLoaded) {
        checkIfSensorExistsInList = sensorsList.filter(
          (item: any) => item.device_id === _settings['add_sensor_id'],
        );

        if (checkIfSensorExistsInList.length === 0) {
          data = { ...data, sensorId: '', sensorName: '' };
          return data;
        }
      }
    }

    let ob: any = Object.entries(devSett?.body?.data?.settings)[0][1];

    if (ob.hasOwnProperty('add_sensor')) {
      data = { ...data, sensorName: ob['add_sensor'] };
    }
    if (ob.hasOwnProperty('add_sensor_id')) {
      data = { ...data, sensorId: ob['add_sensor_id'] };
    }

    return data;
  }
};

const handleTz = (currSystemDetails: any) => {
  return `${formatInTimeZone(
    new Date(),
    `${
      config.timezone3[currSystemDetails.details.settings.conf.tmzn].timezone
    }`,
    'HH:mm',
  )}`;
};

const handleInputs = (inputValues: any, currSystemDetails: any) => {
  return {
    ...inputValues,
    systemName: currSystemDetails.device_name,
    systemLocationState: currSystemDetails?.device_state?.name
      ? currSystemDetails.device_state.name
      : config.sysStateStrs?.noState,
    systemLocationCity: currSystemDetails?.device_city?.name
      ? currSystemDetails.device_city.name
      : config.sysStateStrs?.noCity,
    systemLocationZipCode: currSystemDetails?.device_zipcode?.zip
      ? currSystemDetails.device_zipcode.zip
      : config.sysStateStrs?.noZip,
  };
};

const setVSetup = (ventSetupValues: any, currSystemDetails: any) => {
  return {
    ...ventSetupValues,
    exhaustFanDelayTime: currSystemDetails.details.settings.conf.efdt,
    minutesPerHourSupplyFlow: currSystemDetails.details.settings.conf?.mnsf,
    minutesPerHourExhaustRunFC: currSystemDetails.details.settings.conf?.mner,
    minutesPerHourExhaustRunVC:
      currSystemDetails.details.settings.conf?.cdvs.length > 0
        ? currSystemDetails.details.settings.conf?.cdvs[0].mvcr
          ? currSystemDetails.details.settings.conf?.cdvs[0].mvcr
          : 0
        : 0,
    additionalMixTime: currSystemDetails.details.settings.conf.mixt,
    flowMeasuredHeatCall: currSystemDetails.details.settings.conf.fhfr,
    flowMeasuredCoolingCall: currSystemDetails.details.settings.conf.fcfr,
    flowMeasuredFanOnlyCall: currSystemDetails.details.settings.conf.fffr,
    requiredContinuousAirFlow: currSystemDetails.details.settings.conf.carq,
    exhaustFanFlowRate: currSystemDetails.details.settings.conf.effr,
  };
};

const handleTime = (timeToStartStop: any, currSystemDetails: any) => {
  return {
    ...timeToStartStop,
    hourStart: currSystemDetails.details.settings.conf.hrsv,
    hourStop: currSystemDetails.details.settings.conf.hstv,
  };
};

const setSysDetailsState = (
  sensorNm: string,
  sensorId: string,
  currSystemDetails: any,
  ventOptions: any,
  primaryControl: any,
  addSensorLabels: any,
  devStatus: any,
  hoursOfOp: any,
  tz: any,
) => {
  return {
    add_sensor: sensorNm,
    add_sensor_id: sensorId,
    systemName: currSystemDetails.device_name,
    ventilationSetup: ventOptions,
    primaryControl: primaryControl,
    addSensor: addSensorLabels,
    deviceStatus: devStatus,
    systemLocationState: currSystemDetails?.device_state?.name
      ? currSystemDetails.device_state.name
      : 'No state data.',
    systemLocationCity: currSystemDetails?.device_city?.name
      ? currSystemDetails.device_city.name
      : 'No city data.',
    systemLocationZipCode: currSystemDetails?.device_zipcode?.zip
      ? currSystemDetails.device_zipcode.zip
      : 'No zip code data.',
    minutesPerHourSupplyFlow: currSystemDetails.details.settings.conf?.mnsf,
    minutesPerHourExhaustRunFC: currSystemDetails.details.settings.conf?.mner,
    minutesPerHourExhaustRunVC:
      currSystemDetails.details.settings.conf?.cdvs.length > 0
        ? currSystemDetails.details.settings.conf?.cdvs[0].mvcr
        : 0,
    exhaustFanFlowRateVC:
      currSystemDetails.details.settings.conf.cdvs.length > 0
        ? currSystemDetails.details.settings.conf.cdvs[0].vcfr
        : 0,
    exhaustFanDelayTimeVC:
      currSystemDetails.details.settings.conf.cdvs.length > 0
        ? currSystemDetails.details.settings.conf.cdvs[0].vcdt
        : 0,
    additionalMixTime: currSystemDetails.details.settings.conf.mixt,
    hoursOfOperation: hoursOfOp,
    hourStart: currSystemDetails.details.settings.conf.hrsv,
    hourStop: currSystemDetails.details.settings.conf.hstv,
    timezone: tz,
    currentTime: currSystemDetails.details.settings.conf.tmzn,
    flowMeasuredHeatCall: currSystemDetails.details.settings.conf.fhfr,
    flowMeasuredCoolingCall: currSystemDetails.details.settings.conf.fcfr,
    flowMeasuredFanOnlyCall: currSystemDetails.details.settings.conf.fffr,
    requiredContinuousAirFlow: currSystemDetails.details.settings.conf.carq,
    exhaustFanFlowRate: currSystemDetails.details.settings.conf.effr,
    exhaustFanDelayTime: currSystemDetails.details.settings.conf.efdt,
  };
};

const handleTimeError = (value: any, name: string) => {
  if (name === 'hourStart' && (value < 0 || value > 23)) {
    return { type: 'hourStart', hasError: true };
  } else if (name === 'hourStart' && value >= 0 && value <= 23) {
    return { type: 'hourStart', hasError: false };
  } else if (name === 'hourStop' && (value < 0 || value > 23)) {
    return { type: 'hourStop', hasError: true };
  } else if (name === 'hourStop' && value >= 0 && value <= 23) {
    return { type: 'hourStop', hasError: false };
  }
};

const setPrimCtrlCList = (filteredResult: any) => {
  let primaryControlList = filteredResult
    ? filteredResult[0].devsWithLabel
    : [];

  return primaryControlList || [];
};

const handleSensList = (sensorsList: any) => {
  let data: any = [];

  sensorsList.map((item: any) => {
    data.push({
      id: item.device_id,
      name: item.device_name,
      icon: determineIcon(item.device_type),
      device_type: item.device_type,
    });
  });

  return data;
};

const isAC = (primaryControl: any) => {
  if (primaryControl[0].startsWith('AirCycler')) {
    return true;
  }
};

const getCurrSets = (devSettings: any) => {
  return devSettings?.settings &&
    Object.values(devSettings?.settings).length > 0
    ? Object.values(devSettings?.settings)[0]
    : null;
};

const handleErrs = (val: any, name: string) => {
  if (name === 'additionalMixTime') {
    if (checkIfInvalidValue('min', val)) {
      return { type: 'adMxError', hasError: true };
    }
    if (!checkIfInvalidValue('min', val)) {
      return { type: 'adMxError', hasError: false };
    }
  }
  if (isCfm(name)) {
    if (checkIfInvalidValue('cfm', val)) {
      return { type: name, hasError: true };
    } else if (!functions.checkIfInvalidValue('cfm', val)) {
      return { type: name, hasError: false };
    }
  } else {
    if (checkIfInvalidValue('min', val)) {
      return { type: name, hasError: true };
    } else if (!functions.checkIfInvalidValue('min', val)) {
      return { type: name, hasError: false };
    }
  }
};

const filterCdvs = (details: any, fanConnect: any) => {
  let data: any = {};
  const { fcsp } = details?.settings?.conf || {};
  let filteredDevicesTmp: any = details?.settings?.conf?.cdvs || [];

  if (filteredDevicesTmp.length > 0) {
    filteredDevicesTmp.forEach((device: any) => {
      const { cdid } = device;
      device.vent = mapConnectedLabel(cdid);
    });
  }

  if (fcsp) filteredDevicesTmp.push(fanConnect);

  const controlTarget: any = pickControlTarget(details, filteredDevicesTmp);

  if (controlTarget) {
    data.primaryDefault = controlTarget;
  } else if (filteredDevicesTmp[0]?.cdid) {
    data.primaryDefault = filteredDevicesTmp[0];
  }

  data.cdvs = filteredDevicesTmp;

  return data;
};

const setConnDevs = (saved: any, currSystemDetails: any) => {
  let parsedData = JSON.parse(saved);

  let filteredResult = parsedData.filter(
    (item: any) => item.device_id === currSystemDetails.device_id,
  );

  if (filteredResult && filteredResult.length > 0) {
    let primaryControlList = filteredResult[0].devsWithLabel || [];

    return primaryControlList;
  } else return [];
};

const storeSens = (sensorsData: any) => {
  return sensorsData?.body?.data?.length > 0
    ? sensorsData?.body?.data.filter((elem: any) => !elem.isSystem)
    : [];
};

const setSensInf = (sensorsInformation: any) => {
  let data: any = { id: '', name: '' };

  if (sensorsInformation?.sensorName) {
    data.name = sensorsInformation?.sensorName;
  }
  if (sensorsInformation?.sensorId) {
    data.id = sensorsInformation?.sensorId;
  }

  return data;
};

const setCurrent = (response: any, currSystemDetails: any) => {
  return response?.body?.data.filter(
    (item: any) => item.device_id === currSystemDetails.device_id,
  );
};

const setHours = (id: string | undefined) => {
  if (id && id === `No`) {
    return false;
  } else if (id && id === `Yes`) {
    return true;
  } else return false;
};

const setVS = (ventSetupValues: any, primaryCtrlIsFC: boolean, value: any) => {
  if (primaryCtrlIsFC) {
    return {
      ...ventSetupValues,
      minutesPerHourExhaustRunFC: value,
    };
  } else {
    return {
      ...ventSetupValues,
      minutesPerHourExhaustRunVC: value,
    };
  }
};

const selectTypeOfState = (name: string, value: any, stateOb: any) => {
  return {
    ...stateOb,
    [name]: value,
  };
};

const getFiltered = (
  connectedDevsDesignations: any,
  currSystemDetails: any,
) => {
  return connectedDevsDesignations.filter(
    (item: any) => item.device_id === currSystemDetails.device_id,
  );
};

const getDevSets = (result: any, deviceId: any) => {
  return result.find((dSet: any) => dSet.system_id === deviceId);
};

const checkTypeOfAircycler = (airc: any) => {
  let typeOfAirC: string = '';

  if (typeof airc === 'string') {
    if (airc[9] === 'V') {
      typeOfAirC = 'VentConnect';
    } else if (airc[9] === 'F') {
      typeOfAirC = 'FreshConnect2';
    }
  } else {
    let char = airc?.cdid[9];

    if (char === 'V') {
      typeOfAirC = 'VentConnect';
    } else if (char === 'F') {
      typeOfAirC = 'FreshConnect2';
    }
  }

  return typeOfAirC;
};

const handleEmptyFields = (inputValues: any) => {
  let checkEmptyFields = Object.values(inputValues).filter(
    (item: any) => item.trim().length === 0,
  );

  return checkEmptyFields;
};

export const functions = {
  returnPlaceHolder: returnPlaceHolder,
  returnTypes: returnTypes,
  checkIfNum: checkIfNum,
  determineIcon: determineIcon,
  returnAdditionalLabel: returnAdditionalLabel,
  checkIfInvalidValue: checkIfInvalidValue,
  pickControlTarget: pickControlTarget,
  mapConnectedLabel: mapConnectedLabel,
  validateTypeOfState: validateTypeOfState,
  handleOpHours: handleOpHours,
  mapVentSetupMode: mapVentSetupMode,
  checkIfThereIsCdid: checkIfThereIsCdid,
  validateSensor: validateSensor,
  handleInitialSettings: handleInitialSettings,
  handleElemPlaceholder: handleElemPlaceholder,
  validateDiffs: validateDiffs,
  handleSensors: handleSensors,
  determineCurrMode: determineCurrMode,
  handleSettings: handleSettings,
  isCfm: isCfm,
  handleInputs: handleInputs,
  setVSetup: setVSetup,
  setSysDetailsState: setSysDetailsState,
  handleTime: handleTime,
  handleTz: handleTz,
  handleTimeError: handleTimeError,
  setPrimCtrlCList: setPrimCtrlCList,
  handleSensList: handleSensList,
  isAC: isAC,
  getCurrSets: getCurrSets,
  handleErrs: handleErrs,
  filterCdvs: filterCdvs,
  setConnDevs: setConnDevs,
  storeSens: storeSens,
  setSensInf: setSensInf,
  setCurrent: setCurrent,
  setHours: setHours,
  setVS: setVS,
  selectTypeOfState: selectTypeOfState,
  getFiltered: getFiltered,
  getDevSets: getDevSets,
  checkTypeOfAircycler: checkTypeOfAircycler,
  handleEmptyFields: handleEmptyFields,
};
