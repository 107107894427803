// import { ConnectionBroker } from '../../core';

export const navigationalState = {
  state: {
    chartHoveringState: false,
    chartHoveringCfState: null,
    chartHoveringExState: null,
    chartHoverCfItems: null,
    chartHoverExItems: null,
  },
  reducers: {
    storeHoveringState(state: any, payload: any) {
      return { ...state, chartHoveringState: payload };
    },
    setHoveringState(state: any, payload: any, name: string) {
      const { id, value }: any = payload || {};

      return {
        ...state,
        [name === 'ex' ? 'chartHoveringExState' : 'chartHoveringCfState']: {
          [id]: value,
        },
      };
    },
    setHoverCfItem(state: any, payload: any) {
      const { chartHoverCfItems }: any = state;
      const { id, data }: any = payload || {};

      return {
        ...state,
        chartHoverCfItems: { ...chartHoverCfItems, [id]: data },
      };
    },
    setHoverExItem(state: any, payload: any) {
      const { chartHoverExItems }: any = state;
      const { id, data }: any = payload || {};

      return {
        ...state,
        chartHoverExItems: { ...chartHoverExItems, [id]: data },
      };
    },
  },
  effects: (dispatch: any) => ({}),
};
