import React from 'react';
import { SelectProps } from './interface';
import './styles.scss';

const Select: React.FC<SelectProps> = ({
  inputClass,
  selectClass,
  selectId,
  onChange,
  title,
  array,
  optionValue,
  resetMode,
}) => {
  return (
    <div className={`${inputClass} sens-devs-details`}>
      <select className={selectClass} id={selectId} onChange={onChange}>
        <option value={optionValue}>{title}</option>
        {array &&
          array.length > 0 &&
          array.map((id: any, index: number) => (
            <option
              key={id.device_id}
              value={id.device_id}
              onChange={onChange}
              className="select-style lead"
            >
              {id.device_name} - {id.device_id}
            </option>
          ))}
      </select>
    </div>
  );
};

export default Select;
