import React from 'react';
import config from './config';
import { ContactsProps } from './interface';

const Contacts: React.FC<ContactsProps> = ({ filteredObj }) => {
  return (
    <div className="row">
      {config?.info.map((elem: any, index: number) => {
        return (
          <div className={elem.col} key={index}>
            <span className={elem.cardValuesCls}>
              <span className={elem.spanCls}> {elem.spanLabel}</span>
            </span>
            <span className={elem.spanCls2}>
              {elem.spanLabel === `Phone`
                ? filteredObj?.contact?.phone
                : filteredObj?.contact?.email}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default Contacts;
