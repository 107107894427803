import React from 'react';
import { ApiProps } from './interface';
import { Button } from 'business/modules/admin/common';
import './styles.scss';

const ApiComponent: React.FC<ApiProps> = ({
  onClick,
  apiInfo,
  txt,
  className,
  className2,
  className3,
  id,
}) => {
  return (
    <div className={className} id={id}>
      <div className="row api-box">
        <div className={className2}>
          <Button
            className={`btn btn-light btn-lg api-style`}
            onClick={onClick}
          >
            API {apiInfo}
          </Button>
        </div>
        <div className={className3}>
          <span className="lead data-devs-lead-fs">{txt}</span>
        </div>
      </div>
    </div>
  );
};

export default ApiComponent;
