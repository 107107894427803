import React from 'react';
import config from './config';
import { TemplateUserProps } from './interface';
import { MdArrowBackIosNew } from 'react-icons/md';
import { Breadcrumb } from './components';
import './styles.scss';

const TemplateUser: React.FC<TemplateUserProps> = ({
  children,
  id,
  title,
  style,
  location,
  titleBackButton,
  titleBackOnClick,
  goBack,
  classn,
  classn2,
  hasRightBtn,
  fun,
  hasLabel,
  icon,
  ...props
}) => {
  return (
    <div id={id ? id : ''} className={`main-content ${classn ? classn : ``}`}>
      <div className={classn ? classn : ``}>
        <div className="row back-row">
          <div className={`col-12 ${classn2 ? classn2 : ``}`}>
            {goBack && (
              <button onClick={goBack}>
                <div className="container back-btn-inside-cnt">
                  <div className="row back-btn-inside-cnt">
                    <div className="col-1 back-btn-inside-cnt">
                      <MdArrowBackIosNew className="arrow-back" />
                    </div>
                    <div className="col back-btn-inside-cnt">
                      <span className="lead back-span">
                        {config.strings[id as keyof typeof config.strings]}{' '}
                      </span>
                    </div>
                  </div>
                </div>
              </button>
            )}
          </div>
        </div>
      </div>

      <Breadcrumb
        icon={icon}
        title={title}
        id={id}
        titleBackButton={titleBackButton}
        titleBackOnClick={titleBackOnClick}
        location={location}
        hasRightBtn={hasRightBtn}
        fun={fun}
        hasLabel={hasLabel}
        {...props}
      />
      <div className="row d-flex">
        <div className="custom-card">{children}</div>
      </div>
    </div>
  );
};

export default TemplateUser;
