import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { BsPersonCheck } from 'react-icons/bs';
import { MdCancel } from 'react-icons/md';
import { ConnectionBroker } from 'core';
import { LoaderBubble, Button } from 'react-components';
import Form from '../../components/FormSession';



interface DeleteRequestConfirmedProps {
  confirmRequest: Function;
}

const DeleteRequestConfirmed: React.FC<DeleteRequestConfirmedProps> = ({ ...props }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loading, setLoading]: any = useState(true);
  const [success, setSuccess] = useState<boolean>(false);


  // eslint-disable-next-line react-hooks/exhaustive-deps
  const verification = async () => {
    const { token }: any = match?.params || {};

    if(token){
      const response: any = await props.confirmRequest({ token });

      setLoading(false);
      if (response?.code === 200) {
        setSuccess(true);
      }
    }
  };

  useEffect(() => {
    if (loading) verification();
  }, [loading]);


  const ResultComponent = () => {

    if (success) {
      return (
        <div className="pt-5 mt-5 text-center">
          <BsPersonCheck size={80} className="text-primary" />
          <h2 className="pt-5">Success</h2>
          <p>Your account and personal data have been deleted.</p>
          <div className="d-flex justify-content-center align-items-center pt-4"></div>
        </div>
      );
    }

    return (
      <div className="pt-5 mt-5 text-center">
        <MdCancel size={80} className="text-primary" />
        <h2 className="pt-5">Something went wrong!</h2>
        <p>
          Your request for account deletion failed. Please, contact our support
          for further details.
        </p>
        <div className="d-flex justify-content-center align-items-center pt-4">
        </div>
      </div>
    );
  };

  return (
    <Form>
      {loading ? <LoaderBubble /> : <ResultComponent />}
    </Form>
  );
};


function mapStateToProps(state: any) {
  return {};
}

function mapDispatchToProps(dispatch: any) {
  const { requestDelete }: any = dispatch || {};

  return {
    confirmRequest: requestDelete.confirmDeleteRequest,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteRequestConfirmed);
