import React from 'react';

interface ButtonProps {
  config: any;
  fireConfirmation: any;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  config,
  disabled,
  fireConfirmation,
}) => {

  return (
    <div className="to-the-right-btn">
      <button
        className="btn btn-primary"
        disabled={disabled}
        onClick={fireConfirmation}
      >
        {config?.strings?.submit}
      </button>
    </div>
  );
};

export default Button;
