import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { FoobotHome, FoobotConnection, FoobotDisconnect } from './pages';

const foobot_path = '/foobot';

const Foobot: React.FC = ({ ...props }) => {

  return (
    <>
      <BreadcrumbsItem to={`/dashboard${foobot_path}`}>
        Foobot
      </BreadcrumbsItem>
      <Switch>
        <Route exact path={`/dashboard${foobot_path}`} component={FoobotHome} />
        <Route exact path={`/dashboard${foobot_path}/connection`} component={FoobotConnection} />
        <Route exact path={`/dashboard${foobot_path}/disconnect`} component={FoobotDisconnect} />
      </Switch>
    </>
  );
};

export default Foobot;
