import React from 'react';

const OutsideAirTooltip: React.FC = () => {
  return (
    <>
      <span style={{ fontWeight: 'bold' }}>Outside Air (OA)</span>
      <p style={{ marginTop: 3 }}>
        Temperature (°F) and humidity (RH) data sourced from your local weather
        station or FlowProbe™ (if installed). Select high and low outdoor air
        temperature limits for your AirCycler® Connect System.
      </p>
      <span>Air Quality Index (AQI)</span>
      <p>sourced by airnow.gov/ using location data provided.</p>
      <span>Cubic Feet per Minute (CFM)</span>
      <p>airflow data sourced from FlowProbe™ (if installed).</p>
    </>
  );
};

export default OutsideAirTooltip;
