import React, { Fragment } from 'react';
import { FormProps } from './interface';
import { PhoneInput } from '..';
import { NoPhoneNorCode, IdInput, GenerateID } from './components';
import { Label } from 'business/modules/admin/common';
import './styles.scss';

const Form: React.FC<FormProps> = ({
  origin,
  fields,
  handlePhone,
  generatedCode,
  generateNewCode,
  data,
  config,
  getUserInput,
  disabledGenerate,
  genLabel,
  tooltip,
  phone,
  error,
}) => {
  return (
    <div className="container id-create-company--form--011">
      <div className="row d-flex justify-content-center">
        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 the-form-col1">
          {fields.map((field: any, index: number) => {
            if (field.isPhone) {
              return (
                <Fragment key={index}>
                  <Label> {field?.label}</Label>
                  <PhoneInput
                    key={index}
                    value={phone}
                    index={index}
                    handleChange={(value: any) => handlePhone(value)}
                    handleChangeError={() => {}}
                    name={field.name}
                    configInputs={config.constraints[field.name]}
                    errors={error ? error[field.name] : ''}
                    editMode={``}
                  />
                </Fragment>
              );
            }

            if (field.isCode) {
              return (
                <Fragment key={index}>
                  <IdInput
                    index={index}
                    elem={field}
                    generatedCode={generatedCode}
                    newCodeGenerator={
                      <GenerateID
                        onClick={generateNewCode}
                        disabledGenerate={disabledGenerate}
                        label={genLabel[origin]}
                      />
                    }
                    tooltip={tooltip}
                  />
                </Fragment>
              );
            } else {
              return (
                <Fragment key={field.id}>
                  <NoPhoneNorCode
                    field={field}
                    index={index}
                    elem={field}
                    data={data}
                    getUserInput={getUserInput}
                    err={error ? error[field.name] : ''}
                  />
                </Fragment>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default Form;
