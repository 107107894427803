import { ConnectionBroker } from '../../core';


export const airCyclerLocalSensor = {
  state: {
    allSensors: [],
  },
  reducers: {
    updateState(state: any, payload: any, name: string) {
      return { ...state, [name]: payload };
    },
  },
  effects: (dispatch: any) => ({
    async getAirCyclerLocalSensors(data: any) {
      const that: any = this;
      const params: any = { id: data.id };
      let response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'airCyclerLocal.list',
        null,
        { ...params },
      );

      if (response?.statusCode === 200) {
        return response;
      }

      return;
    },
    async getAirCyclerLocalSensorsDataPoint(data: any) {
      const that: any = this;
      const params: any = { id: data.id };
      let response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'airCyclerLocal.show',
        null,
        { ...params },
      );

      if (response?.statusCode === 200) {
        return response;
      }

      return;
    },
  }),
};
