import React from 'react';
import config from './config';
import moment from 'moment-timezone';

const returnKey = (str: string) => {
  if (config.data.hasOwnProperty(str)) {
    return config.data[str as keyof typeof config.data];
  }
};

const isRelevantKey = (key: string) => {
  return key !== '_id' &&
    key !== 'installer_id' &&
    key !== 'contact' &&
    key !== '__v'
    ? true
    : false;
};

const returnIcon = (str: string) => {
  if (config.icons && config.icons.hasOwnProperty(str)) {
    return config.icons[str as keyof typeof config.icons];
  }
};

const formatDate = (date: any) => {
  return date ? moment(new Date(date)).format('MM-DD-YYYY, hh:mm A') : '';
};

const isContact = (key: string) => {
  return key === `contact` ? true : false;
};

const isADate = (key: string) => {
  if (key === `createdAt` || key === `updatedAt`) {
    return true;
  } else return false;
};

const returnLabel = (key: string) => {
  return (
    <span className="badge badge-info spec-info-bdg">
      {transformKeyString(key)}
    </span>
  );
};

const transformKeyString = (key: string) => {
  if (config?.keys && config?.keys.hasOwnProperty(key)) {
    return config.keys[key as keyof typeof config.keys];
  }
};

export const functions = {
  returnKey: returnKey,
  returnIcon: returnIcon,
  formatDate: formatDate,
  isContact: isContact,
  isRelevantKey: isRelevantKey,
  isADate: isADate,
  returnLabel: returnLabel,
};
