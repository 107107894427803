import React from 'react';
import moment from 'moment';
import { IoCheckmark } from 'react-icons/io5';
import { MdOutlineCancelPresentation } from 'react-icons/md';

export default {
  title: `Device summary`,
  strings: [
    {
      id: 'commands-option-dev',
      label: `Commands`,
      dataToggle: ``,
      dataTarget: ``,
    },
    {
      id: 'setup-logs-option-dev',
      label: `Setup Logs`,
      dataToggle: ``,
      dataTarget: ``,
    },
    {
      id: 'datalogg-option-dev',
      label: `Data Logging download`,
      dataToggle: `modal`,
      dataTarget: `#data-logging`,
    },
  ],
  accordionDetails: {
    title: 'Details',
    card1: {
      cardTitle: `Device details`,
      cardSubTitle: `Information`,
      type: `info`,
    },
    card2: {
      subTitle: `Location`,
      data: `location`,
    },
    card3: {
      subTitle: `Settings`,
      data: `settings`,
    },
    card4: {
      subTitle: `G3 data`,
      data: `g3data`,
    },
  },
  devLabels: [
    { id: 'device_id', label: 'Device id' },
    { id: 'device_type', label: 'Device type' },
    { id: 'id', label: 'Id' },
    { id: 'label', label: 'Label' },
    { id: 'prod_code', label: ' Product code' },
  ],
  locationDevLabels: [
    { id: 'countryCode', label: 'Country code' },
    { id: 'isoCode', label: 'State ISO Code' },
    { id: 'name', label: 'State' },
    { id: 'city', label: 'City' },
    { id: 'zip', label: 'ZIP code' },
    { id: 'label', label: 'Country' },
    { id: 'latitude', label: 'Latitude' },
    { id: 'longitude', label: 'Longitude' },
  ],
  dictionary: {
    prob: 'Probe',
    cdss: 'Device RSSI',
    hrss: 'Device RSSI',
    vpct: 'Ventilation Percentage Complete',
    g3id: 'AircyclerG3 device id',
    dmac: 'G3 Device Mac Address',
    uptm: 'Uptime',
    fmwv: 'Firmware version',
    pcbv: 'PCB Revision',
    usul: 'User Setting Update Local',
    thsa: 'Thermostat Call Active',
    thre: 'Thermostat Runtime Elapsed',
    oaqe: 'Outdoor Air Quality Event Control Flag',
    aqse: 'Air Quality Sensor Control Flag',
    uctr: 'User Control Flag',
    vcta: 'Ventilation Control Cycle Active',
    vpro: 'Ventilation Period Rollover',
    evrt: 'Exhaust Vent Required On Time',
    evrl: 'Exhaust Vent Required On Time Left',
    mhsv: 'Minute in Hour Start Exhaust Vent',
    cfrt: 'Central Fan Required On Time',
    cfrl: 'Central Fan Required On Time Left',
    mhsf: 'Minute in Hour Start Central Fan',
    mplf: 'Minutes in Period Left',
    errc: 'Error count',
    conf: 'Configuration',
    fmwu: 'Firmware url',
    fmwm: 'Firmware md5',
    lfdt: 'Last foreground time',
    crdt: 'Current_date_time',
    ccmd: 'Connected Device Command',
    resl: 'Result',
    smth: 'Supply Method',
    fffr: 'Fan Only Flow Rate CFM',
    fhfr: 'Fan Heat Flow Rate CFM',
    fcfr: 'Fan Cool Flow Rate CFM',
    exct: 'Exhaust Control Target',
    fcsp: 'Fan Connect Switch Present',
    fcst: 'Fan Connect Switch State',
    fcds: 'Fan Connect Delay State',
    fcdr: 'Fan Connect Delay Minutes Remaining',
    effr: 'Exhaust Fan Flow Rate (fan connect)',
    efdt: 'Exhaust Fan Delay Time (fan connect)',
    mner: 'Mins. Per Hour Exhaust Run (fan connect)',
    exwm: 'Exhaust Worker Mode (fan connect)',
    carq: 'Constant Air Required CFM',
    mixt: 'Mix Time',
    hroa: 'Hours of Operation Active',
    hrsv: 'Hour Start Ventilation',
    hstv: 'Hour Stop Ventilation',
    dmst: 'Damper Status',
    cfst: 'Central Fan Relay Status',
    tpmn: 'Temperature Probe Minimum',
    tpmx: 'Temperature Probe Maximum',
    ctmp: 'Current Temperature Reading',
    crhr: 'Current Relative Humidity Reading',
    ddia: 'Air Duct Diameter',
    cflw: 'Current Flow Reading',
    mnsf: 'Minutes per Hour Supply Flow',
    lcap: 'Last Conn. Access Point',
    tmzn: 'Current Set Timezone',
    prmd: 'Pair Mode',
    pmto: 'Pair Mode Timeout',
    blto: 'Bluetooth LE Timeout',
    gail: 'G3 Device Alias',
    excm: 'Exhaust Relay Set Command',
    cfcm: 'Central Fan Relay Set Command',
    oacm: 'Outside Air Damper Relay Set Command',
    fdrs: 'Factory Default Reset',
    cdvc: 'Connected Device Count',
    cdid: 'Connected Device ID',
    cail: 'Connected Device Alias',
    cidx: 'Connected Device Index Pos On MQTT Broker',
    cpri: 'Connected Device Display Priority',
    cdst: 'Connected Device State',
    vcct: 'Vent Connect Control Target Flag',
    vcfr: 'Exhaust Fan Flow Rate (vent connect)',
    vcdt: 'Exhaust Fan Delay Time (vent connect)',
    mvcr: 'Mins. per Hour Exhaust Run (vent connect)',
    vcwm: 'Exhaust Worker Mode (vent connect)',
    slst: 'Switch Status Light State',
    fcon: 'Force On',
    frte: 'Fan runtime elapsed',
    pcst: 'Probe Connection Status Probe Status',
    dfps: 'Differential Pressure',
    stmp: 'Sensor Temperature',
    faep: 'Forward Active Energy Pulses',
    raep: 'Reverse Active Energy Pulses',
    aaep: 'Absolute Active Energy Pulses',
    frep: 'Forward Reactive Energy Pulses',
    rrep: 'Reverse Reactive Energy Pulses',
    arep: 'Absolute Reactive Energy Pulses',
    estt: 'Energy Read Status',
    irms: 'RMS Current',
    vrms: 'RMS Voltage',
    mnap: 'Mean Active Power',
    mnrp: 'Mean Reactive Power',
    vfrq: 'Voltage Frequency',
    pfct: 'Power Factor',
    phas: 'Phase Angle',
    mapp: 'Mean AP Power',
    prun: 'Probe run',
    evot: 'Fan Connect (wired exhaust) - Time in Mins',
    mxrh: 'Probe Maximum Relative Humidity',
    man_ctrl: 'Manual Control',
    cdvs: 'Connected Devices',
  },
  settingsDevLabels: [
    { id: 'aqse', label: 'aqse' },
    { id: 'cfrl', label: 'cfrl' },
    { id: 'cfrt', label: 'cfrt' },
    { id: 'conf', label: 'conf' },
    { id: 'errc', label: 'errc' },
    { id: 'aqse', label: 'aqse' },
    { id: 'evot', label: 'evot' },
    { id: 'evrt', label: 'evrt' },
    { id: 'fmwv', label: 'fmwv' },
    { id: 'hrss', label: 'hrss' },
    { id: 'mhsf', label: 'mhsf' },
    { id: 'mplf', label: 'mplf' },
    { id: 'oaqe', label: 'oaqe' },
    { id: 'pcbv', label: 'pcbv' },
    { id: 'thsa', label: 'thsa' },
    { id: 'uctr', label: 'uctr' },
    { id: 'vcta', label: 'vcta' },
    { id: 'vpct', label: 'vpct' },
    { id: 'evrl', label: 'evrl' },
    { id: 'pcst', label: 'pcst' },
    { id: 'prob', label: 'prob' },
    { id: 'uptm', label: 'uptm' },
  ],
  cdvsSettings: [
    'dmac',
    'vcct',
    'cpri',
    'vcdt',
    'mvcr',
    'cdst',
    'frte',
    'vcfr',
    'cail',
    'cdss',
    'slst',
    'cdid',
    'pcbv',
    'cidx',
    'vcwm',
    'fmwv',
  ],
  datalogging: {
    strings: {
      titleLabel: 'System',
      loading: 'Loading Data...',
      reTryLoading: 'Trying reload Data...',
      tmzLabel: 'Current Timezone',
      defaultTimezone: 'Greenwich Mean Time',
    },
    threshTags: [
      'out_temp_max',
      'out_temp_min',
      'out_hum_value',
      'out_aq_value',
      'in_pm25_value',
      'in_co2_value',
      'in_voc_value',
      'curr_mode',
    ],
    table: {
      pageOptions: [
        { value: 1, label: '1 Period' },
        { value: 2, label: '2 Periods' },
        { value: 3, label: '3 Periods' },
        { value: 4, label: '4 Periods' },
        { value: 5, label: '5 Periods' },
        { value: 6, label: '6 Periods' },
      ],
      columns: [
        {
          title: 'Date',
          field: 'date',
          defaultSort: 'desc',
          customSort: (itemA: any, itemB: any) => {
            return (
              moment(itemA.sortDate).valueOf() -
              moment(itemB.sortDate).valueOf()
            );
          },
        },
        { title: 'Current', field: 'time' },
        { title: 'Minutes Left', field: 'min_hour' },
        { title: 'Central Fan', field: 'cfn_state' },
        { title: 'Damper', field: 'dmp_state' },
        { title: 'Exhaust', field: 'ext_state' },
        { title: 'Light', field: 'lgt_state' },
        { title: 'Fresh Air Flow', field: 'air_flow' },
        { title: 'Thermostat', field: 'ths_state' },
        { title: 'Temperature', field: 'out_temp' },
        { title: 'Temp Min', field: 'out_temp_min' },
        { title: 'Temp Max', field: 'out_temp_max' },
        { title: 'Humidity', field: 'out_hum' },
        { title: 'Hum Threshold', field: 'out_hum_value' },
        { title: 'Air Quality', field: 'out_aq' },
        { title: 'Air Quality Threshold', field: 'out_aq_value' },
        { title: 'PM2.5', field: 'in_pm25' },
        { title: 'PM2.5 Threshold', field: 'in_pm25_value' },
        { title: 'CO2', field: 'in_co2' },
        { title: 'CO2 Threshold', field: 'in_co2_value' },
        { title: 'VOC', field: 'in_voc' },
        { title: 'VOC Threshold', field: 'in_voc_value' },
        { title: 'Current Mode', field: 'curr_mode' },
        { title: 'Events', field: 'system_events' },
      ],
      options: {
        exportButton: true,
        search: true,
        sorting: true,
        paginationType: 'normal',
      },
      totalCount: 24 * 70,
      defaultOptions: {
        pageSize: 60,
        pageSizeOptions: () => {
          const totalRows: number = 80 * 24;
          let totalOptions: any = [];

          for (let index = 0; index < totalRows; index++) {
            totalOptions.push(index);
          }

          return totalOptions;
        },
      },
    },
    timezones: [
      {
        label: 'Newfoundland Time w/ Daylight Savings Time',
        timezone: 'Canada/Newfoundland',
      },
      {
        label: 'Atlantic Time w/ Daylight Savings Time',
        timezone: 'Canada/Atlantic',
      },
      {
        label: 'Eastern Standard w/ Daylight Savings Time',
        timezone: 'Canada/Eastern',
      },
      {
        label: 'Central Standard w/ Daylight Savings Time',
        timezone: 'US/Central',
      },
      {
        label: 'Mountain Standard w/ Daylight Savings Time',
        timezone: 'US/Mountain',
      },
      {
        label: 'Mountain Standard Time',
        timezone: 'US/Mountain',
      },
      {
        label: 'Pacific Standard w/ Daylight Savings Time',
        timezone: 'US/Pacific',
      },
      {
        label: 'Alaska Standard w/ Daylight Savings Time',
        timezone: 'US/Alaska',
      },
      {
        label: 'Hawaii Standard w/ Daylight Savings Time',
        timezone: 'US/Hawaii',
      },
    ],
  },
  titles: {
    download_dl: `Data Logging information`,
  },
  icons: {
    download_dl: <IoCheckmark className="edit" />,
    cancel__op: <MdOutlineCancelPresentation className="edit" />,
  },
  bodies: {
    download_dl: `You are about to download the data logging repport`,
  },
  labels: {
    download_dl: ` OK`,
  },
  bodyClass: {
    download_dl: `container`,
  },
};
