export default {
  data: [
    {
      li: `list-group-item break-w`,
      key: `phone`,
      span1: `svg-i`,
      arg: `phone`,
      span2: `lead span-key-039`,
      arg2: `phone`,
      label: `Phone`,
      span3: `lead span-key-040`,
    },

    {
      li: `list-group-item break-w`,
      key: `email`,
      span1: `svg-i`,
      arg: `email`,
      span2: `lead span-key-039`,
      arg2: `email`,
      label: `E-mail`,
      span3: `lead span-key-040`,
    },
  ],
  tooltip: {
    title: `Grab the code`,
    txt: `Copy the code to clipboard`,
  },
};
