import React, { Fragment } from 'react';
import { NoPhoneNorCodeProps } from './interface';
import { Input, Label } from 'business/modules/admin/common';
import './styles.scss';

const NoPhoneNorCode: React.FC<NoPhoneNorCodeProps> = ({
  index,
  elem,
  field,
  data,
  getUserInput,
  err,
}) => {
  const returnValue = (elem: any) => {
    return data[elem.name] || '';
  };

  return (
    <Fragment>
      <div className="form-group diff-input" key={index}>
        <Label>{field.label}</Label>
        <div className="input-group mbottom-5">
          <Input
            type={elem.type}
            className={elem.className}
            id={elem.id}
            name={elem.name}
            ariaDescribedBy={elem.ariaDescribedBy}
            placeholder={elem.placeholder}
            children={elem.label}
            value={returnValue(elem)}
            onChange={getUserInput}
          />
        </div>
        <span className="error-alert">{err}</span>
      </div>
    </Fragment>
  );
};

export default NoPhoneNorCode;
