import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import validate from 'validate.js';
import * as _ from 'lodash';
import { Input, Button } from '../../../../../react-components';
import { UserTemplate } from 'business/modules/common';
import { AtLogoHeader } from '../../common';
import config from './config';
import './styles.scss';

const configInputs: any = config;
// const AT_LABEL: string = '@air-airthings';
// const NOT_YET_SUPPORTED: string = configInputs?.alerts?.support || '';
// const testClientId: string = 'eb82b9c9-55e8-4a19-adf2-f4c3fd1b0305';

interface LoadingProps {
  start: Function;
  stop: Function;
}

interface AirThingsProps {
  loading: LoadingProps;
  profile: any;
  airthingsConnect: Function;
}

const AirThingsConnection: React.FC<AirThingsProps> = ({
  profile,
  ...props
}) => {
  const history = useHistory();
  const [isInit, setInit]: any = useState(false);
  const [btnDisabled, setBtnDisabled]: any = useState(true);
  const [fieldsState, setFieldsState]: any = useState({
    ...configInputs.fieldsState,
  });
  const [errors, setErrors]: any = useState(null);

  const onChangeState = async (name: any, value: any) => {
    let currentFields: any = { [name]: value };
    let currentConstraints: any = {
      [name]: { ...configInputs.constraints[name] },
    };

    await validate
      .async(currentFields, currentConstraints)
      .then((res: any) => {})
      .catch(err => {
        setErrors((prevErrors: any) => {
          return { ...prevErrors, [name]: err[name] };
        });
      });

    setFieldsState((prevState: any) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const validateFields = async (fields: any) => {
    const fieldsCopy: any = _.cloneDeep(fields);
    let valid: boolean = true;

    if (fieldsCopy && Object.keys(fieldsCopy).length > 0) {
      for (const prop in fieldsCopy) {
        let currentFields: any = { [prop]: fieldsCopy[prop] };
        let currentConstraints: any = {
          [prop]: { ...configInputs.constraints[prop] },
        };

        await validate
          .async(currentFields, currentConstraints)
          .then((res: any) => {})
          .catch(err => {
            valid = false;
          });
      }
    }

    setBtnDisabled(!valid);
  };

  const resetFields = () => {
    setFieldsState((prevStates: any) => {
      let statesClone: any = _.cloneDeep(prevStates);
      if (statesClone && Object.keys(statesClone).length > 0) {
        for (const prop in statesClone) {
          statesClone[prop] = '';
        }
      } else {
        statesClone = {};
      }
      return statesClone;
    });
    setBtnDisabled(true);
  };

  const handleSubmit = async (event: any) => {
    props.loading.start('Connecting to Airthings');
    const { clientId, clientSecret }: any = fieldsState;
    const payload: any = { clientId, clientSecret };
    const response: any = await props.airthingsConnect(payload);

    setTimeout(() => {
      props.loading.stop();
      resetFields();
      history.push('/dashboard/airthings');
    }, 1000 * 2);
  };

  useEffect(() => {
    if (fieldsState) {
      validateFields(fieldsState);
    }
  }, [fieldsState]);

  useEffect(() => {
    setInit(true);
  }, []);

  return (
    <UserTemplate
      id="at-connection"
      title={config?.strings?.title}
      titleBackButton
    >
      <BreadcrumbsItem to={`/dashboard/airthings/connection`}>
        Airthings Connection
      </BreadcrumbsItem>
      <Grid container direction="column" alignItems="center">
        <Grid item className="section-1">
          <AtLogoHeader />
        </Grid>
        <Grid item className="section-2">
          <p>
            Connect to your <span className="section-2--bl">AIRTHINGS</span>{' '}
            account in order to import your devices to the{' '}
            <span className="section-2--bl">AirCyclerApp</span>
          </p>
        </Grid>
        <Grid item className="section-3">
          <ul>
            <li>
              1 - Go to your Airthings account and click on "Integrations" on
              the left side menu.
            </li>
            <li>2 - Select the "API" tab.</li>
            <li>
              3 - After click on "+ Request API Client" will open a new sceen,
              fill all the required fields and save.
            </li>
            <li>
              4 - Fill the required fields below with you client id and client
              secret.
            </li>
            <li>5 - Click the "Connect" button.</li>
          </ul>
        </Grid>
        <Grid item className="section-4">
          <div className="">
            {configInputs?.fields &&
              Object.keys(configInputs.fields).map(
                (name: any, index: number) => {
                  return (
                    <Input
                      key={`foo-input--${index}`}
                      handleChange={onChangeState.bind(null, name)}
                      handleChangeError={(err: any) => {
                        setErrors((prevState: any) => ({
                          ...prevState,
                          [name]: err,
                        }));
                      }}
                      type={configInputs.fields[name].type}
                      label={configInputs.fields[name].label}
                      value={fieldsState[name]}
                      error={errors ? errors[name] : ''}
                      name={name}
                    />
                  );
                },
              )}
          </div>
        </Grid>
        <Grid item className="section-5">
          <div className="col d-flex pt-4 pb-2">
            <Button
              className="btn btn-primary btn-block"
              type="button"
              disabled={btnDisabled}
              onClick={handleSubmit}
            >
              {config.strings.confirm}
            </Button>
          </div>
        </Grid>
      </Grid>
    </UserTemplate>
  );
};

function mapStateToProps(state: any) {
  const { profile } = state;
  return {
    profile: profile.user,
  };
}

function mapDispatchToProps(dispatch: any) {
  let { loader, profile, airthings } = dispatch;
  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    airthingsConnect: airthings.connect,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AirThingsConnection);
