import React, { Fragment } from 'react';
import { SettingsProps } from './interface';
import { RiSettings4Line } from 'react-icons/ri';
import './styles.scss';

const Settings: React.FC<SettingsProps> = ({
  config,
  device,
  returnLabel,
  returnValue,
}) => {
  const notToRender = (label: any) => {
    if (
      label?.id === `fmwv` ||
      label === `fmwv` ||
      label?.id === `hrss` ||
      label === `cdss` ||
      label?.id === 'conf' ||
      label?.id === `uptm`
    ) {
      return true;
    } else return false;
  };

  return (
    <div className="settings-inside-devs-screen">
      <h6 className="card-subtitle mb-2 text-muted">
        <RiSettings4Line className="icon-card-title" />

        {config.accordionDetails.card3.subTitle}
      </h6>
      <div className={`card-text pd-zero settings-section`}>
        <div className={`container pd-zero`}>
          <div className="row pd-zero">
            <div className="col-xs-12 col-sm-12 col-md-6 pd-zero1">
              {config.settingsDevLabels
                .slice(0, config.settingsDevLabels.length / 2 + 1)
                .map((label: any, index: number) => {
                  if (notToRender(label)) return null;
                  return (
                    <div className="container pd-zero" key={index}>
                      <div
                        className="row pd-zero item-color"
                        title={returnLabel(label.label)}
                      >
                        <div className="col pd-zero overflow-style">
                          <span className="lead stronger font-size12">
                            {returnLabel(label.label)}
                          </span>
                          <span className={`pill-legend pill-style`}>
                            [{label.label}]
                          </span>
                        </div>

                        <div
                          className={`${
                            returnLabel(label.label) ===
                            'Last Conn. Access Point'
                              ? `col-auto spaced-0`
                              : `col-3 spaced-0`
                          }`}
                        >
                          <span className="font-09 lead float-content-right pt2">
                            {label.id !== 'conf' && returnValue(label.label)}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 pd-zero1">
              {config.settingsDevLabels
                .slice(config.settingsDevLabels.length / 2 + 1)
                .map((label: any, index: number) => {
                  if (notToRender(label)) return null;
                  return (
                    <div className="container pd-zero" key={index}>
                      <div
                        className="row pd-zero item-color"
                        title={returnLabel(label.label)}
                      >
                        <div className="col pd-zero overflow-style">
                          <span className="lead stronger font-size12">
                            {returnLabel(label.label)}
                          </span>
                          <span className={`pill-style`}>[{label.label}]</span>
                        </div>

                        <div
                          className={`${
                            returnLabel(label.label) ===
                            'Last Conn. Access Point'
                              ? `col-auto spaced-0`
                              : `col-3 spaced-0`
                          }`}
                        >
                          <span className="font-09 lead float-content-right pt2">
                            {label.id === 'conf' ? (
                              <Fragment key={label.id}>
                                {Object.entries(
                                  device?.details?.settings?.conf,
                                ).map(([key, value]: [any, any], i: number) => {
                                  return key === 'cdvs' ? (
                                    <Fragment key={i}>
                                      {value.map((element: any, i: number) => (
                                        <div key={i}>
                                          {config.cdvsSettings.map(
                                            (elem: any, index: number) => {
                                              return (
                                                <>
                                                  <div className="container pd-zero">
                                                    <div
                                                      className="row pd-zero item-color"
                                                      title={returnLabel(elem)}
                                                    >
                                                      <div className="col pd-zero overflow-style">
                                                        <span className="lead stronger font-size12">
                                                          {returnLabel(elem)}
                                                        </span>
                                                        <span
                                                          className={`pill-style`}
                                                        >
                                                          [{elem}]{' '}
                                                        </span>
                                                      </div>

                                                      <div
                                                        className={`${
                                                          returnLabel(elem) ===
                                                          'Last Conn. Access Point'
                                                            ? `col-auto spaced-0`
                                                            : `col-3 spaced-0`
                                                        }`}
                                                      >
                                                        <span className="font-09 lead float-content-right pt2">
                                                          {returnLabel(
                                                            element[elem],
                                                          )}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              );
                                            },
                                          )}
                                        </div>
                                      ))}
                                    </Fragment>
                                  ) : (
                                    <>
                                      <div className="container pd-zero">
                                        <div
                                          className="row pd-zero item-color"
                                          title={returnLabel(key)}
                                        >
                                          <div className="col pd-zero overflow-style">
                                            <span className="lead stronger font-size12">
                                              {returnLabel(key)}
                                            </span>
                                            <span className={`pill-style`}>
                                              [{key}]
                                            </span>
                                          </div>

                                          <div
                                            className={`${
                                              returnLabel(key) ===
                                              'Last Conn. Access Point'
                                                ? `col-auto spaced-0`
                                                : `col-3 spaced-0`
                                            }`}
                                          >
                                            <span className="font-09 lead float-content-right pt2">
                                              {value}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              </Fragment>
                            ) : (
                              <span className="font-09 lead float-content-right">
                                {' '}
                                {returnValue(label.label)}
                              </span>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>

      <div className="row pd-zero settings-section">
        <div className="col-xs-12 col-sm-12 col-md-6 pd-zero1">
          {Object.entries(device?.details?.settings?.conf)
            .slice(
              0,
              Object.entries(device?.details?.settings?.conf).length / 2,
            )
            .map(([key, value]: [any, any], i: number) => {
              return key === 'cdvs' ? (
                <></>
              ) : (
                <div className="container pd-zero" key={i}>
                  <div
                    className="row pd-zero item-color"
                    title={returnLabel(key)}
                  >
                    <div className="col pd-zero overflow-style">
                      <span className="lead stronger font-size12">
                        {returnLabel(key)}
                      </span>
                      <span className={`pill-style`}>[{key}]</span>
                    </div>

                    <div
                      className={`${
                        returnLabel(key) === 'Last Conn. Access Point'
                          ? `col-auto spaced-0`
                          : `col-3 spaced-0`
                      }`}
                    >
                      <span className="font-09 lead float-content-right pt2">
                        {value}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 pd-zero1">
          {Object.entries(device?.details?.settings?.conf)
            .slice(Object.entries(device?.details?.settings?.conf).length / 2)
            .map(([key, value]: [any, any], i: number) => {
              return key === 'cdvs' ? null : (
                <div className="container pd-zero" key={i}>
                  <div
                    className="row pd-zero item-color"
                    title={returnLabel(key)}
                  >
                    <div className="col pd-zero overflow-style">
                      <span className="lead stronger font-size12">
                        {returnLabel(key)}
                      </span>
                      <span className={`pill-style`}>[{key}]</span>
                    </div>

                    <div
                      className={`${
                        returnLabel(key) === 'Last Conn. Access Point'
                          ? `col-auto spaced-0`
                          : `col-3 spaced-0`
                      }`}
                    >
                      <span className="font-09 lead float-content-right pt2">
                        {value}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Settings;
