import React, { Fragment } from 'react';
import config from './config';
import { MenuProps } from './interface';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import './styles.scss';

const RowMenu: React.FC<MenuProps> = ({
  btnId,
  open,
  anchorEl,
  handleClose,
  ariaExpanded,
  onClick2,
  handleMenuAction,
  option1,
  option2,
  actionToPerformOnRequest,
  isConfirmed,
}) => {
  return (
    <Fragment>
      <IconButton
        className="btn menu--btn"
        style={{ float: 'right', boxShadow: 'none' }}
        aria-haspopup="true"
        onClick={onClick2}
        aria-expanded={ariaExpanded}
        id={btnId}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        elevation={1}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `more-button-comp`,
        }}
      >
        {isConfirmed && (
          <MenuItem
            id={`view-del-rqst`}
            onClick={() => {
              handleClose();
              handleMenuAction(option1);
            }}
            data-toggle="modal"
            data-target={`#${actionToPerformOnRequest}`}
          >
            {config?.strings?.delete}
          </MenuItem>
        )}
        
        <MenuItem
          id={`cancel-del-rqst`}
          onClick={() => {
            handleClose();
            handleMenuAction(option2);
          }}
          data-toggle="modal"
          data-target={`#${actionToPerformOnRequest}`}
        >
          {config?.strings?.cancel}
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default RowMenu;
