import React from 'react';
import config from './config';
import { NoResultsProps } from './interface';
import './styles.scss';

const NoResults: React.FC<NoResultsProps> = ({ noResultsTitle }) => {
  return (
    <div className="jumbotron no-results-screen">
      <h3 className="display-6">{config?.strings?.title}</h3>

      <p></p>
      <hr className="my-4" />

      <div className="alert alert-light message" role="alert">
        <p className="lead">{noResultsTitle}</p>
      </div>
    </div>
  );
};

export default NoResults;
