// @ts-nocheck
import config from './config';

const DEFAULT_VALUES: any = { ...config.sliderValues.defaults };

const prepareValuesToSave = (values: any, theDevice: any) => {
  let devSettings: any = _.cloneDeep({ ...theDevice.details.settings });
  const { temp, aqi, hum } = values;
  devSettings.conf.tpmn = temp.min;
  devSettings.conf.tpmx = temp.max;
  devSettings.conf.mxrh = hum.max;
  devSettings.conf.aqvi = aqi.max;

  return devSettings;
};

const preparePayload = (fieldsData: any) => {
  let payload: any = {
    name: 'Default Config Name',
    details: {},
    settings: {},
    setup: {},
  };

  if (fieldsData && Object.keys(fieldsData).length > 0) {
    const { config_name } = fieldsData;

    payload.name = config_name;

    Object.keys(fieldsData).forEach((field: string) => {
      if (field !== 'config_name') {
        payload.settings[field] = fieldsData[field];
      }
    });
  }

  return payload;
};

const handleOutsideValues = (storedValues: any) => {
  const { temp, hum, aqi }: any = DEFAULT_VALUES;
  const { outsideAir }: any = storedValues || {};

  return {
    temp: outsideAir?.temp
      ? { ...outsideAir.temp, current: temp.current }
      : { ...temp },
    hum: outsideAir?.hasOwnProperty('hum')
      ? { max: outsideAir.hum, current: hum.current }
      : { ...hum },
    aqi: outsideAir?.hasOwnProperty('aqi')
      ? { max: outsideAir.aqi, current: aqi.current }
      : { ...aqi },
  };
};

const prepareToSaveSetup = (
  values: any,
  outsideAirSectionIsEnabled: boolean,
) => {
  const { temp, aqi, hum, cfm }: any = values || {};
  let tempSetup: object = {
    temp: {
      max: temp?.max,
      min: temp?.min,
    },
    hum: hum?.max,
    aqi: aqi?.max,
    cfm: cfm?.max,
    turnedOn: outsideAirSectionIsEnabled,
  };

  return {
    setup: {
      outsideAir: { ...tempSetup },
    },
  };
};

export const functions = {
  prepareValuesToSave: prepareValuesToSave,
  preparePayload: preparePayload,
  handleOutsideValues: handleOutsideValues,
  prepareToSaveSetup: prepareToSaveSetup,
};
