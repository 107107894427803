import React from 'react';
import { ListContainerProps } from './interface';
import './styles.scss';

const ListContainer: React.FC<ListContainerProps> = ({
  children,
  config,
  id,
}) => {
  return (
    <div className="card" id={id}>
      <div className="card-body spec-card-body--">
        <h5 className="card-title">{config.modal.view.sectionTitle}</h5>
        <h6 className="card-subtitle mb-2 text-muted">
          {config.modal.view.sectionSubtitle}
        </h6>
        <div className="card-text">{children}</div>
      </div>
    </div>
  );
};

export default ListContainer;
