const orderListByCriteria = (
  orderBy: string,
  dataToOrder: any,
  helperOrderData?: any,
  id?: string,
) => {
  let returnResult;
  let willBeOrdered = dataToOrder;

  if (dataToOrder.length > 0 && dataToOrder[0].hasOwnProperty('first_name')) {
    if (orderBy === 'name') {
      returnResult = willBeOrdered.sort((a: any, b: any) => {
        let aString = a.first_name.toUpperCase();
        let bString = b.first_name.toUpperCase();
        return aString < bString ? -1 : aString > bString ? 1 : 0;
      });
      return returnResult;
    } else if (orderBy === 'location') {
      returnResult = willBeOrdered.sort((a: any, b: any) => {
        let aString = a.addresses[0].state.toUpperCase();
        let bString = b.addresses[0].state.toUpperCase();
        return aString < bString ? -1 : aString > bString ? 1 : 0;
      });
      return returnResult;
    }
  }

  //TODO: this will be implemented when we have real installers data
  if (orderBy === 'instCount') {
    return dataToOrder;
  } else if (orderBy === 'count' && helperOrderData) {
    let temp = dataToOrder;
    let returnResult;
    for (let i = 0; i < temp.length; i++) {
      for (let k = 0; k < helperOrderData.length; k++) {
        if (temp[i].account_id === helperOrderData[k].id) {
          temp[i].devicesQuantity = helperOrderData[k].list.length;
        }
      }
    }
    returnResult = temp.sort((a: any, b: any) => {
      let aValue = a.devicesQuantity;
      let bValue = b.devicesQuantity;
      return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
    });

    return returnResult;
  } else if (orderBy === 'date') {
    if (id && id === 'the--commands') {
      returnResult = willBeOrdered.sort((a: any, b: any) => {
        return a.createdAt - b.createdAt;
      });
    } else if (id && id === 'the--logs') {
      returnResult = willBeOrdered.sort((a: any, b: any) => {
        return a.timestamp - b.timestamp;
      });
    }
    returnResult = willBeOrdered.sort((a: any, b: any) => {
      return a.createdAt - b.createdAt;
    });

    return returnResult;
  } else if (orderBy === 'status') {
    //this must be refactored later

    if (id && id === 'users-list-data02') {
      returnResult = willBeOrdered.sort((a: any, b: any) => {
        let aString = a[orderBy].toUpperCase();
        let bString = b[orderBy].toUpperCase();

        return aString < bString ? -1 : aString > bString ? 1 : 0;
      });
    } else if (id && id === 'companies-list-l001-2') {
      returnResult = willBeOrdered.sort((a: any, b: any) => {
        return a.status < b.status ? -1 : a.status > b.status ? 1 : 0;
      });
    }

    return returnResult;
  } else if (orderBy === 'devs') {
    returnResult = willBeOrdered.sort((a: any, b: any) => {
      let aElem = a.installedDevices.length;
      let bElem = b.installedDevices.length;

      return aElem < bElem ? -1 : aElem > bElem ? 1 : 0;
    });
    return returnResult;
  } else if (id && id === 'companies-list-l001-2') {
    if (orderBy === 'name') {
      returnResult = willBeOrdered.sort((a: any, b: any) => {
        let aString = a['device_name'].toUpperCase();
        let bString = b['device_name'].toUpperCase();
        return aString < bString ? -1 : aString > bString ? 1 : 0;
      });
    } else if (orderBy === 'location') {
      returnResult = willBeOrdered.sort((a: any, b: any) => {
        let aString = a['state'].toUpperCase();
        let bString = b['state'].toUpperCase();
        return aString < bString ? -1 : aString > bString ? 1 : 0;
      });
    }
    return returnResult;
  } else {
    returnResult = willBeOrdered.sort((a: any, b: any) => {
      let aString = a[orderBy === `location` ? `state` : orderBy].toUpperCase();
      let bString = b[orderBy === `location` ? `state` : orderBy].toUpperCase();
      return aString < bString ? -1 : aString > bString ? 1 : 0;
    });
  }

  return returnResult;
};

const handleDiffPropName = (prop: string) => {
  switch (prop) {
    case 'location':
      return 'state';
    case 'devs':
      return 'installedDevices';
    default:
      return '';
  }
};

const diffTheDatesOrder = (id: string) => {
  switch (id) {
    case 'the--commands':
      return 'createdAt';
    case 'the--logs':
      return 'timestamp';
    default:
      return '';
  }
};

const counterIsEven = (counter: any) => {
  if (counter % 2 === 0 && counter > 0) {
    return true;
  } else if (counter % 2 === 1) {
    return false;
  }
};

//todo: refactor this function to not repeat code
const returnInit = (position: string, item: any) => {
  if (item.hasOwnProperty('username')) {
    return position === `f` ? item?.username[0]?.toUpperCase() : ``;
  }

  if (item.hasOwnProperty('first_name')) {
    let first: string = item?.first_name?.split(' ')[0];
    let second: string | undefined = item?.last_name?.split(' ')[0];
    return position === `f`
      ? first[0]?.toUpperCase()
      : position === `s` && second
      ? second[0]?.toUpperCase()
      : ``;
  }

  if (item.hasOwnProperty('device_name')) {
    let first: string = item?.device_name?.split(' ')[0];
    let second: string | undefined = item?.device_name?.split(' ')[0];
    return position === `f`
      ? first[0]?.toUpperCase()
      : position === `s` && second
      ? second[0]?.toUpperCase()
      : ``;
  }
  if (item.hasOwnProperty('deletedBy')) {
    let first: string = item?.details?.name?.first_name.split(' ')[0];
    let second: string | undefined = item?.details?.name?.last_name.split(
      ' ',
    )[1];
    return position === `f`
      ? first[0]?.toUpperCase()
      : position === `s` && second
      ? second[0]?.toUpperCase()
      : ``;
  } else {
    let first: string = item?.name?.split(' ')[0];
    let second: string | undefined = item?.name.split(' ')[1];
    return position === `f`
      ? first[0]?.toUpperCase()
      : position === `s` && second
      ? second[0]?.toUpperCase()
      : ``;
  }
};

export const functions = {
  orderListByCriteria: orderListByCriteria,
  counterIsEven: counterIsEven,
  returnInit: returnInit,
};
