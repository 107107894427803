import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';
import LoginAdmin from '../pages/sessionsAdmin/pages/signInAdmin';
import DashboardRoutes from '../pages/routes';
import { useAuth } from '../../sessions/hooks/auth';


const AdminRoutes: React.FC = ({ ...props }) => {
  const { adminUser }: any = useAuth();

  return (
    <Switch>
      <Route path="/admin" exact component={LoginAdmin} isAdmin />
      <Route path="/admin/login" exact component={LoginAdmin} isAdmin />
      <Route path="/admin/dashboard" component={DashboardRoutes} isPrivateAdmin />
    </Switch>
  );
};

export default AdminRoutes;
