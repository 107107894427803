import React, { Fragment, useCallback, useEffect } from 'react';
import config from './config';
import { connect } from 'react-redux';
import { SectionProps } from './interface';
import { helpers } from './helpers';
import { NoData } from '..';
import { VscDebugDisconnect } from 'react-icons/vsc';
import { DeviceInformation, SensorInformation } from './components';
import { BsFillInfoSquareFill } from 'react-icons/bs';
import {
  DetailsHeader,
  Modal,
  Alert,
  Button,
  Spinner,
} from 'business/modules/admin/common';
import {
  Devices,
  ManualControl,
  Sensors,
  Systems,
  IndoorAir,
  OutsideAir,
  PressureConnect,
} from '..';
import './styles.scss';

const Section: React.FC<SectionProps> = ({
  functions,
  currentlyActive,
  enteredUserPage,
  loadedList,
  list,
  title,
  target,
  sectionData,
  setItem,
  sysSetups,
  currentUser,
  changeTab,
  editDetailsByAdmin,
  indoorAirSettingsArr,
  indoorAirSettingsAreLoaded,
  sensors,
  sensorsLoaded,
  connection,
  devicesPerUser,
  loading,
  loadingStr,
  devicesPerUserAreLoaded,
  modalMustClose,
  handleModalState,
  currentPeripheral,
  dName,
  setEnteredInUserPage,
}) => {
  const systemNameHandler = useCallback((_device: any) => {
    const { details, device_name } = _device || {};
    if (device_name && device_name !== '') {
      return device_name;
    } else if (details?.settings?.g3id && details?.settings?.g3id !== '') {
      return details.settings.g3id;
    } else {
      return 'Aircycler G3';
    }
  }, []);

  const returnValue = (val: any) => {
    if (currentPeripheral.hasOwnProperty(val)) {
      return currentPeripheral[val];
    } else {
      return null;
    }
  };

  const disconnectPeriph = () => {};

  const handleIsLocal = (elem: any) => {
    if (
      (elem?.details?.name && elem?.details?.name === 'LOCAL') ||
      (elem?.details?.status && elem?.details?.status?.includes('local'))
    ) {
      return '(LOCAL)';
    }
  };

  useEffect(() => {
    return () => {
      setEnteredInUserPage(false);
    };
  }, []);

  useEffect(() => {
    if (!loadedList || !enteredUserPage) {
      loading.start(loadingStr);
    } else {
      loading.stop();
    }
  }, [list, loadedList, enteredUserPage]);

  return (
    <div className="row section-details-ct">
      <div className="col">
        <div className="jumbotron mt-1 back-cls">
          <h4>{functions.returnTitle(currentlyActive)}</h4>
          <p className="lead">{functions.returnLead(currentlyActive)}</p>
          <hr className="my-4" />

          {!enteredUserPage ? (
            <div className="container loading-cnt">
              <div className="row">
                <div className="col-12">
                  {config.loading.map((el: any, index: number) => (
                    <Fragment key={index}>{Object.values(el)} </Fragment>
                  ))}
                </div>
              </div>
            </div>
          ) : enteredUserPage && loadedList && list.length > 0 ? (
            <>
              {(currentlyActive === 'dev' || currentlyActive === 'sens') && (
                <Alert alertClass={`dev-sensor-alert`}>
                  <span className="lead alert-device">
                    {title}
                    <Button
                      type="button"
                      className="lead btn more-info-dev"
                      dataToggle={`modal`}
                      dataTarget={target}
                    >
                      Click here
                    </Button>
                  </span>
                </Alert>
              )}

              {list.map((element: any, index: number) => {
                return (
                  <Fragment key={index}>
                    {!sectionData[currentlyActive].hasInternalHeader && (
                      <DetailsHeader
                        designation={systemNameHandler(element)}
                        sensorProp={
                          currentlyActive === 'sens'
                            ? handleIsLocal(element)
                            : ''
                        }
                      />
                    )}

                    {currentlyActive === 'dev' ? (
                      <Devices
                        sectionData={sectionData[currentlyActive]}
                        element={element}
                        setItem={setItem}
                        designation={systemNameHandler(element)}
                      />
                    ) : currentlyActive === 'sens' ? (
                      <Sensors
                        sectionData={sectionData[currentlyActive]}
                        setItem={setItem}
                        element={element}
                        sysSetups={sysSetups}
                      />
                    ) : currentlyActive === 'sys' ? (
                      <Systems
                        sectionData={sectionData[currentlyActive]}
                        setItem={setItem}
                        element={element}
                        designation={systemNameHandler(element)}
                      />
                    ) : currentlyActive === 'ia' ? (
                      <IndoorAir
                        settings={devicesPerUser.find(
                          (item: any) => item.device_id === element.device_id,
                        )}
                        connection={connection}
                        sensorsLoaded={sensorsLoaded}
                        orderedSensors={sensors}
                        sectionData={sectionData[currentlyActive]}
                        device={element}
                        setup={
                          sysSetups[element.device_id]
                            ? sysSetups[element.device_id]
                            : {}
                        }
                        sensorName={helpers.getSensorDataForDevice(
                          element.device_id,
                          indoorAirSettingsArr,
                          indoorAirSettingsAreLoaded,
                        )}
                        designation={systemNameHandler(element)}
                      />
                    ) : currentlyActive === 'oa' ? (
                      <OutsideAir
                        sectionData={sectionData[currentlyActive]}
                        element={element}
                        setup={
                          sysSetups[element.device_id]
                            ? sysSetups[element.device_id]
                            : {}
                        }
                        completeDev={helpers.getCurrentDev(
                          element?.device_id,
                          devicesPerUser,
                          devicesPerUserAreLoaded,
                        )}
                        designation={systemNameHandler(element)}
                      />
                    ) : currentlyActive === 'pc' ? (
                      <PressureConnect
                        sectionData={sectionData[currentlyActive]}
                        element={element}
                        device={element}
                        setup={
                          sysSetups[element.device_id]
                            ? sysSetups[element.device_id]
                            : {}
                        }
                        designation={systemNameHandler(element)}
                      />
                    ) : (
                      <ManualControl
                        sectionData={sectionData[currentlyActive]}
                        element={element}
                        currentConfs={sectionData[currentlyActive].configs.find(
                          (item: any) => item.system_id === element.device_id,
                        )}
                        designation={systemNameHandler(element)}
                      />
                    )}
                  </Fragment>
                );
              })}
            </>
          ) : enteredUserPage && loadedList && list.length === 0 ? (
            <NoData
              sectionName={currentlyActive}
              currentUser={currentUser}
              changeTab={changeTab}
              editDetailsByAdmin={editDetailsByAdmin}
            />
          ) : (
            <Spinner />
          )}
        </div>
      </div>

      <DeviceInformation />
      <SensorInformation />

      <Modal
        config={config}
        bodyClass={helpers.handleOpts(`sys`, 'bodyClass')}
        className={`small-button ok`}
        onClick={() => {}}
        title={helpers.handleOpts(`sys`, `title`)}
        id={`delete-device-modal-id01`}
        icon={helpers.handleOpts(`sys`, `icon`)}
        body={helpers.handleOpts(`sys`, 'bodies')}
        /**call handleModalState and pass it true value
         * inside store after receive the server
         * response when an endpoint is available */
        modalMustClose={modalMustClose}
        handleModalState={handleModalState}
        label={config.labels[`sys` as keyof typeof config.labels]}
        hasConfirmBtn={true}
        sureToProceed={`Are you sure you wish to proceed?`}
        warning={config.strings.warning}
      />

      {/**Modal: show settings details */}
      <Modal
        config={config}
        bodyClass={helpers.handleOpts(`settings`, 'bodyClass')}
        className={`small-button ok`}
        onClick={() => {}}
        title={`${helpers.handleOpts(`settings`, `title`)} - ${
          currentPeripheral?.cdid
        }`}
        id={`system-settings-modal-details`}
        icon={helpers.handleOpts(`settings`, `icon`)}
        body={
          <>
            {currentPeripheral?.cdid === 'FanConnect' ? (
              <div className="container pdg">
                <div className="row">
                  <div className="col-12 pdg icon-col">
                    <BsFillInfoSquareFill className="svg-lg-icon" />
                  </div>

                  <div className="col-12 pdg lead str-col">
                    No details to show.
                  </div>
                </div>
              </div>
            ) : (
              config.settingsLabels.map((element: any, index: number) => {
                if (currentPeripheral.hasOwnProperty(element.term)) {
                  return (
                    <div className="container labels--terms2" key={index}>
                      <div className="row labels--terms label-row">
                        <div className={`col labels--terms2`}>
                          <div className="container col-p-0">
                            <div className="row col-p-0">
                              <div className="col-auto col-p-0 data-name">
                                <span className={`information- lead`}>
                                  {
                                    config.objectLabels[
                                      element.term as keyof typeof config.objectLabels
                                    ]
                                  }
                                </span>{' '}
                              </div>
                              <div className="col-2 col-p-0">
                                <span className={`badge term--pill sm-left-mg`}>
                                  {element.term}
                                </span>{' '}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={`col-4 labels--terms2 align--r`}>
                          <span className={`information- lead`}>
                            {returnValue(element.term)}
                          </span>{' '}
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return null;
                }
              })
            )}
          </>
        }
        label={config.labels[`settings` as keyof typeof config.labels]}
        hasConfirmBtn={false}
        /**call handleModalState and pass it
         * true value after receive the server response
         * when an endpoint is available */
        modalMustClose={modalMustClose}
        handleModalState={handleModalState}
      />

      <Modal
        config={config}
        bodyClass={helpers.handleOpts(`disconnect`, 'bodyClass')}
        className={`small-button ok`}
        onClick={disconnectPeriph}
        title={`${currentPeripheral.cdid}`}
        id={`system-disconnect`}
        icon={helpers.handleOpts(`disconnect`, `icon`)}
        body={
          <>
            <div className="row col-p-0">
              <h5 className="card-title centered-content"></h5>
              <div className="col-12 centered-content">
                <VscDebugDisconnect className="larger-icon " />
              </div>

              <div className={`col-12 lead confirmation centered-content`}>
                <div className={`container col-p-0`}>
                  <div className={`row col-p-0`}>
                    <div className={`col-12 col-p-0 just-left`}>
                      {config.strings.confirmation}
                      <span id="bold-grey">
                        {currentPeripheral && currentPeripheral?.cdid
                          ? currentPeripheral?.cdid
                          : currentPeripheral?.cail}
                      </span>
                      {config.strings.part} {dName}?
                      <p className="warning--font">{config.strings.warning}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
        modalMustClose={modalMustClose}
        /**call handleModalState and pass it true value after
         * receive the server response when an endpoint is available */
        handleModalState={handleModalState}
        label={config.labels[`disconnect` as keyof typeof config.labels]}
        hasConfirmBtn={true}
      />
    </div>
  );
};

function mapStateToProps(state: any) {
  const { adminSectionsState, adminUsersState, adminDevicesState } = state;
  return {
    dName: adminDevicesState.dName,
    indoorAirSettingsAreLoaded: adminSectionsState.indoorAirSettingsAreLoaded,
    indoorAirSettingsArr: adminSectionsState.indoorAirSettingsArr,
    devicesPerUser: adminUsersState.devicesPerUser,
    devicesPerUserAreLoaded: adminUsersState.devicesPerUserAreLoaded,
    modalMustClose: adminSectionsState.modalMustClose,
    currentPeripheral: adminDevicesState.currentPeripheral,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { adminSectionsState, loader, adminUsersState } = dispatch;
  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    editDetailsByAdmin: adminSectionsState.editDetailsByAdmin,
    handleModalState: adminSectionsState.handleModalState,
    setEnteredInUserPage: adminUsersState.setEnteredInUserPage,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Section);
