import React from 'react';
import { DevicesProps } from './interface';

const Devices: React.FC<DevicesProps> = ({
  index,
  elm,
  config,
  handleSystemKey,
  handleItemVal,
}) => {
  return (
    <div className="container system-information" key={index}>
      <div className="row si-row">
        <div className="col-12 d-flex justify-content-center">
          <h5 className="card-title lead card-title-sys">{elm.device_name}</h5>
        </div>

        <div className="col-12">
          {Object.entries(elm)
            .filter(([key, value]) => config.relevantKeys.indexOf(key) > -1)
            .map(([key, value], idx) => {
              return (
                <div className="row" key={idx}>
                  <div
                    className={`col ${
                      key === 'details' ? '-12' : '-5'
                    } lead system-k`}
                  >
                    <span className="lead-sm-bold">{handleSystemKey(key)}</span>
                  </div>
                  {key === 'details' && <div className="row"></div>}
                  <div className="col">
                    <span className="lead-sm">{handleItemVal(value, key)}</span>
                  </div>
                  {key === 'details' && <div className="row grey-ln"></div>}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Devices;
