import React from 'react';
import { SaveProps } from './interface';
import { RiSave3Fill } from 'react-icons/ri';

const Save: React.FC<SaveProps> = ({ showSaveBtn, onClick, config }) => {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-1 offset-md-11 col-save-install-indoor-values">
        {showSaveBtn && (
          <button className="btn save-configs-btn-purple" onClick={onClick}>
            <RiSave3Fill />
            <span className="save-span">{config?.strings?.btnLabel}</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default Save;
