import React, { Fragment } from 'react';
import { GenTooltipProps } from './interface';
import './styles.scss';

const GenTooltip: React.FC<GenTooltipProps> = ({ title, textOne, textTwo }) => {
  return (
    <Fragment>
      <span className="tooltip-bold">{title}</span>
      <p style={{ marginTop: 3 }}>{textOne}</p>
      <p>{textTwo}</p>
    </Fragment>
  );
};

export default GenTooltip;
