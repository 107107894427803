import React from 'react';
import { Route, Switch } from 'react-router-dom';
// import SupportRoutes from '../modules/support';
import PrivacyPolicy from '../modules/privacy-policy';


const PolicyRoutes: React.FC = ({ ...props }) => {

  return (
    <Switch>
      <Route path="/privacy-policy" exact component={PrivacyPolicy} />
    </Switch>
  );
};

export default PolicyRoutes;

// <Route path="/support" component={SupportRoutes} />
