import React from 'react';
import { ViewCompanyDetailsProps } from './interface';
import { ListGroup, ListContainer } from './components';
import { relevantKeys } from './config';
import { functions } from './helpers';

const ViewCompanyDetails: React.FC<ViewCompanyDetailsProps> = ({
  currentCompanyItem,
  config,
  copyContent,
  id,
}) => {
  const classes: any = {
    class1: `container-info pdg`,
    class2: `row`,
    role: ``,
    class3: `modal-content pdg`,
  };

  return (
    <ListContainer config={config} id={id}>
      <ListGroup
        currentCompanyItem={currentCompanyItem}
        relevantKeys={relevantKeys}
        returnIcon={functions.returnIcon}
        returnKey={functions.returnKey}
        isContact={functions.isContact}
        returnValue={functions.returnValue}
        copyContent={copyContent}
        classes={classes}
      />
    </ListContainer>
  );
};

export default ViewCompanyDetails;
