import React from 'react';
import './styles.scss';

interface TempDisplayProps {
  item: any;
}

const TempDisplay: React.FC<TempDisplayProps> = ({ item }) => {
  return (
    <div className="container loading-main-cnt">
      <div className="row d-flex justify-content-center">
        <div className="col left-loading">{item}</div>
        <div className="col center-loading">{item}</div>
        <div className="col right-loading">{item}</div>
      </div>
    </div>
  );
};

export default TempDisplay;
