import React, { useEffect, useState } from 'react';
import config from './config';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { functions } from './helpers';
import { EditDetailsDevProps } from './interface';
import {
  Input,
  SwitchBtn,
  Button,
  Modal,
  Template,
} from 'business/modules/admin/common';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { HiOutlineXCircle } from 'react-icons/hi';
import { RiSave3Fill } from 'react-icons/ri';
import './styles.scss';

const EditNameLocation: React.FC<EditDetailsDevProps> = ({
  updateDeviceLocationNameData,
  setUpdateFormKey,
  userCancelledUpdate,
  setCancellation,
  changeTab,
}) => {
  const history = useHistory();
  const [dev, setDev] = useState<any>();
  const [status, setStatus] = useState<boolean>(false);
  const [inputFields, setInputFields] = useState<any>(config.initState);

  const handleError = (field: string) => {
    let _prop: string =
      config.correspondingValue[
        field as keyof typeof config.correspondingValue
      ];
    if (inputFields[_prop].trim().length === 0) {
      return config.errors[field as keyof typeof config.errors];
    } else {
      return '';
    }
  };

  const preventSubmitIfEmptyValues = () => {
    if (
      inputFields.name.trim().length === 0 ||
      inputFields.city.trim().length === 0 ||
      inputFields.state.trim().length === 0 ||
      inputFields.zip.trim().length === 0
    ) {
      return true;
    } else return false;
  };

  const resetState = (reason?: string) => {
    setInputFields({
      ...inputFields,
      name: dev?.device_name || '',
      state: dev?.device_state?.name || '',
      city: dev?.device_city?.name || '',
      zip: dev?.device_zipcode?.zip || '',
    });

    setStatus(dev?.status);

    if (reason && reason === 'cancellation') {
      let newKey = Date.now();

      setUpdateFormKey(newKey);
    } else {
      setUpdateFormKey('dev-update-modal-key-ii');
    }
  };

  const stateProps = {
    'devname-name': 'name',
    device_city: 'city',
    device_state: 'state',
    device_zipcode: 'zip',
  };

  const updateField = (event: any) => {
    setCancellation(false);
    let name = event.target.name;

    setInputFields({
      ...inputFields,
      [stateProps[name as keyof typeof stateProps]]: event.target.value,
    });
  };

  const returnPlaceholderInput = (str: string) => {
    switch (str) {
      case 'name':
        return inputFields.name || '';
      case 'device_city':
        return inputFields.city;
      case 'device_state':
        return inputFields.state;
      case 'device_zipcode':
        return inputFields.zip;
      default:
        break;
    }
  };

  const getPlcNoLocation = (str: string) => {
    switch (str) {
      case 'name':
        return inputFields.name || '';
      case 'device_city':
      case 'device_state':
      case 'device_zipcode':
        return 'No data';
      default:
        break;
    }
  };

  const updateStatusState = () => {
    setCancellation(false);
    setStatus(!status);
  };

  const checkIfLocationPropsAreMissing = (dev: any) => {
    if (
      !dev.hasOwnProperty('device_zipcode') ||
      !dev.hasOwnProperty('device_state') ||
      !dev.hasOwnProperty('device_city')
    ) {
      return true;
    } else return false;
  };

  useEffect(() => {
    if (userCancelledUpdate) {
      resetState('cancellation');
    }
  }, [userCancelledUpdate]);

  useEffect(() => {
    const currentSystem: any = localStorage.getItem('currSystemDetailsData');

    if (currentSystem) {
      let parsed = JSON.parse(currentSystem);
      setDev(parsed);
    }
  }, []);

  useEffect(() => {
    if (dev) {
      setStatus(dev?.status === `active` ? true : false);

      setInputFields({
        name: dev?.device_name || '',
        city: dev?.device_city?.name || '',
        state: dev?.device_state?.name || '',
        zip: dev?.device_zipcode?.zip || '',
      });
    }
  }, [dev]);

  useEffect(() => {
    updateDeviceLocationNameData({ status, inputFields });
  }, [inputFields, status]);

  return (
    <div className="edit-details-dev-screen">
      <Template
        id="details-dev1"
        title={config?.strings?.editTitle}
        goBack={() => {
          history.push(`/admin/dashboard/user-details`);
          changeTab(`sys`, 'edit');
        }}
      >
        <BreadcrumbsItem to={config?.strings?.path}>
          {config?.strings?.breadcrumbItem}
        </BreadcrumbsItem>

        <div className={`container whitecnt mh-col-min-h`}>
          <div className={`row spaced-spec--1`}>
            <div className={`col spaced-spec--1`}>
              <div className="jumbotron system-jumbo2">
                <h4 className="centered">{`Edit details`} </h4>
                <p className="lead centered card-top-font">{`Update device basic information.`}</p>
                <hr className="my-4" />

                <div className="container whitecnt">
                  <div className={`row d-flex justify-content-center`}>
                    <div className={`col-xs-12 col-sm-12 col-md-12 col-lg-6`}>
                      <div
                        className="card xpec-card"
                        id="form--edit--location--and--name"
                      >
                        <div className="card-body">
                          <h5 className="card-title">{config.strings.title}</h5>

                          <h6 className="mb-2 text-muted lead bolder--label">
                            {config.strings.subtitle}
                          </h6>
                          <div className="card-text mb-mb-6">
                            <Input
                              type="text"
                              className="form-control mt-1"
                              placeholder={inputFields?.name}
                              value={inputFields?.name}
                              onChange={updateField}
                              name={`devname-name`}
                              id="devname_name1"
                            />

                            <div className="render-error">
                              {handleError('devname_name1')}
                            </div>

                            <>
                              {dev && checkIfLocationPropsAreMissing(dev) ? (
                                config.noLocationEditProps.map((item: any) => {
                                  return (
                                    <div className={`container pdg`} key={item}>
                                      <div className={`row pdg`}>
                                        <div
                                          className={`col-xs-12 col-sm-12 pdg`}
                                        ></div>

                                        <h6 className="mb-2 text-muted lead bolder--label">
                                          {functions.returnLabel(item)}
                                        </h6>

                                        <Input
                                          type="text"
                                          className="form-control mt-1"
                                          placeholder={getPlcNoLocation(item)}
                                          value={getPlcNoLocation(item)}
                                          onChange={updateField}
                                          name={item}
                                          id={`${item}1`}
                                        />
                                        <div className="render-error">
                                          {handleError(`${item}1`)}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <></>
                              )}
                              {dev &&
                                Object.entries(dev).map(
                                  (key: any, value: any) => {
                                    if (functions.validateInputFields(key[0])) {
                                      return (
                                        <div
                                          className={`container pdg`}
                                          key={key[0]}
                                        >
                                          <div className={`row pdg`}>
                                            <div
                                              className={`col-xs-12 col-sm-12 pdg`}
                                            >
                                              <h6 className="mb-2 text-muted lead bolder--label">
                                                {functions.returnLabel(key[0])}
                                              </h6>
                                              <Input
                                                type="text"
                                                className="form-control mt-1"
                                                placeholder={returnPlaceholderInput(
                                                  key[0],
                                                )}
                                                value={returnPlaceholderInput(
                                                  key[0],
                                                )}
                                                onChange={updateField}
                                                name={key[0]}
                                                id={`${key[0]}1`}
                                              />
                                              <div className="render-error">
                                                {handleError(`${key[0]}1`)}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }

                                    return null;
                                  },
                                )}

                              {dev && (
                                <div className="container pdg">
                                  <div className="row pdg">
                                    <div className="col-auto pdg">
                                      <h6 className="mb-2 text-muted lead bolder--label pdg mrg-btm">
                                        {`Device status`}
                                      </h6>
                                    </div>
                                    <div className="col pdg">
                                      <SwitchBtn
                                        id={`status-edit-location-name-switch`}
                                        value={status}
                                        onChange={updateStatusState}
                                        className={`switch-status-btn-edit`}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>

                            <div className="container pdg">
                              <div className="row">
                                <div className="col pdg">
                                  <Button
                                    className="small-button dismiss-btn btn--ml-1 float-right"
                                    onClick={resetState}
                                  >
                                    <span className="centered-content btn-child-ft">
                                      {config.strings.cancel}
                                      <HiOutlineXCircle className="cancel--icon--style--03" />
                                    </span>
                                  </Button>
                                  <Button
                                    className="small-button ok btn--spec--mr1"
                                    dataToggle="modal"
                                    dataTarget="#save-changes-edit-modal-dev"
                                    disabled={
                                      preventSubmitIfEmptyValues()
                                        ? true
                                        : false
                                    }
                                  >
                                    <span className="centered-content btn-child-ft">
                                      {config.strings.save}
                                      <RiSave3Fill className="edit-spec-style-1" />
                                    </span>
                                  </Button>
                                </div>
                              </div>{' '}
                            </div>

                            <Modal
                              config={config}
                              bodyClass={functions.handleOpts(
                                `edit__sys`,
                                'bodyClass',
                              )}
                              className={`small-button ok`}
                              //todo save
                              onClick={() => {}}
                              onClickCancel={resetState}
                              title={functions.handleOpts(`edit__sys`, `title`)}
                              id={`save-changes-edit-modal-dev`}
                              icon={functions.handleOpts(`edit__sys`, `icon`)}
                              body={functions.handleOpts(`edit__sys`, 'bodies')}
                              /*
                              modalMustClose and handleModalState will be used 
                              when we have an endpoint to save changes
                              */
                              // modalMustClose={modalMustClose}
                              // handleModalState={handleModalState}
                              modalMustClose={false}
                              handleModalState={() => {}}
                              sureToProceed={config.modalStrings.confirmation}
                              label={
                                config._labels[
                                  `edit__sys` as keyof typeof config._labels
                                ]
                              }
                              hasConfirmBtn={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Template>
    </div>
  );
};

function mapStateToProps(state: any) {
  const { adminDevicesState } = state;
  return {
    editDataKey: adminDevicesState.editDataKey,
    userCancelledUpdate: adminDevicesState.userCancelledUpdate,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { adminDevicesState, adminSectionsState } = dispatch;
  return {
    changeTab: adminSectionsState.changeTab,
    setUpdateFormKey: adminDevicesState.setUpdateFormKey,
    updateDeviceLocationNameData:
      adminDevicesState.updateDeviceLocationNameData,
    setCancellation: adminDevicesState.setCancellation,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditNameLocation);
