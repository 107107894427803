import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { SystemDetails, Peripherals } from './pages';

const system_path = '/details';

const SystemDetailsRoute: React.FC = props => {

  return (
    <>
      <BreadcrumbsItem to={`/dashboard/systems${system_path}`}>
        System Details
      </BreadcrumbsItem>
      <Switch>
        <Route exact path={`/dashboard/systems${system_path}`} component={SystemDetails} />
        <Route path={`/dashboard/systems${system_path}/peripheral`} component={Peripherals} />
        <Route path={`/dashboard/systems${system_path}/:device_id/peripheral`} component={Peripherals} />
      </Switch>
    </>
  );
};

export default SystemDetailsRoute;
