import React from 'react';
import TooltipView from 'react-components/components/Tooltip';
import DamperOpen from '../../../../../../assets/damper/airflow_bkg.gif';
import DamperClosed from '../../../../../../assets/damper/noairflow_bkg.gif';
import './styles.scss';


const isSafari: any = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent,
);

interface DamperComponentProps {
  status: any;
  stoped: any;
}

const DamperComponent: React.FC<DamperComponentProps> = ({
  status,
  stoped,
  ...props
}) => {

  const toolTipMessage = (status: any) => {
    return (
      <>
        <span style={{ fontWeight: 'bold' }}>Damper</span>
        <p style={{ marginTop: 3, marginBottom: -3 }}>
          Damper {status ? 'Open' : 'Closed'}.
        </p>
      </>
    );
  };

  return (
    <div className={`damper-wrapper ${isSafari ? 'safary_only' : ''}`}>
      <div className="damp-state--container">
        <div className={`damper--center ${isSafari ? 'safary_only' : ''}`}>
          <TooltipView title={toolTipMessage(status)}>
            <img
              src={status ? DamperOpen : DamperClosed}
              className={`damper-gif ${stoped ? 'ping-stoped' : ''}`}
            />
          </TooltipView>
        </div>
      </div>
    </div>
  );
};

export default DamperComponent;
