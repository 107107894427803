export default {
  resettedCounter: {
    name: 0,
    date: 0,
    status: 0,
  },
  strings: {
    noData: `No data`,
    noReqStr: `No requests to display.`,
    title: `Order list`,
    txt: `Return to default order by clicking here`,
  },
  dataHeader: [
    {
      colClass: `col-3 center-contents-header-0`,
      spanClass: `lead header-label name-span`,
      spanClass2: `lead header-label name-span1`,
      headerDesignationCol: `Requester`,
    },
    {
      colClass: `col-3 center-contents-header-1`,
      spanClass: `lead header-label date--span`,
      spanClass2: `lead header-label mb4-1`,
      headerDesignationCol: `Request date`,
    },
    {
      colClass: `col-3 center-contents-header-1`,
      spanClass: `lead header-label status-req-span`,
      spanClass2: `lead header-label mb4-1`,
      headerDesignationCol: `Status`,
    },
    {
      colClass: `col-2 center-contents-header-2`,
      spanClass: `lead header-label actions--span`,
      spanClass2: `lead header-label mb4-1 spec-mrg`,
      headerDesignationCol: `Actions`,
    },
  ],
};
