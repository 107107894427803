import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Grid } from '@material-ui/core';
import SweetAlert from 'react-bootstrap-sweetalert';
import validate from 'validate.js';
import { InstallerTemplate } from 'business/modules/common';
import {
  Input,
  Button,
  Notification,
} from 'react-components';
import config from './config';
import './styles.scss';

interface LoadingProps {
  start: any;
  stop: any;
}

interface PeripheralEditProps {
  loading: LoadingProps;
  isLoading: boolean;
  peripheral: any;
  device: any;
  setPeripheral: any;
  reqDeviceDetails: any;
  updateDevice: any;
}

const STORAGE_PERIPHERAL_LABEL = '@air-peripheral:';
const PeripheralEdit: React.FC<PeripheralEditProps> = ({
  peripheral,
  device,
  loading,
  isLoading,
  ...props
}) => {
  const history = useHistory();
  const [fieldsState, setFieldsState] = useState({ ...config?.fieldsState });
  const [errors, setErrors]: any = useState(null);
  const [showUpdateAlert, setShowUpdateAlert] = useState(false);

  const saveDeviceData = () => {
    let data = { ...history.location.state };
    if (data) {
      localStorage.setItem(STORAGE_PERIPHERAL_LABEL, JSON.stringify(data));
    }
  };

  const recoverDeviceDetails = async () => {
    let storedDeviceData: any = localStorage.getItem(STORAGE_PERIPHERAL_LABEL);
    if (storedDeviceData) storedDeviceData = JSON.parse(storedDeviceData);

    if (storedDeviceData?.device_id) {
      loading.start('Loading Peripheral Details');
      props
        .reqDeviceDetails({ device_id: storedDeviceData?.device_id })
        .then((resp: any) => {
          // TODO: add notification if response has errors
          if (resp.statusCode === 200) {
            let cdvs = [...resp?.body?.details?.settings?.conf?.cdvs];
            let currentPeripheral =
              cdvs && cdvs.length > 0
                ? cdvs.find(
                    (periph: any) =>
                      periph?.dmac === storedDeviceData?.peripheral_id,
                  )
                : null;
            if (currentPeripheral) {
              props.setPeripheral({ ...currentPeripheral });
            }
          }
          loading.stop();
        });
    }
  };


  const onChangeState = (prop: string, value: any) => {
    setFieldsState((prevState: any) => {
      return {
        ...prevState,
        [prop]: value,
      };
    });
  };


  const handleUpdatePeripheral = () => {
    let vlResult: any = validate(fieldsState, config.constraints);
    if (vlResult) {
      setErrors(vlResult);
      let msm: string = '';
      Object.keys(vlResult).forEach((key: string) => {
        msm += vlResult[key].map((err: any) => `${err}, `);
      });
      msm = msm.substring(0, msm.length - 2);
      Notification({
        title: 'Error',
        message: msm,
        type: 'error',
      });
    } else {
      setShowUpdateAlert(true);
    }
  };

  const updatePeripheral = async () => {
    setShowUpdateAlert(false);
    let deviceClone = { ...device };
    let cdvsIndex = deviceClone.details.settings.conf.cdvs.findIndex(
      (periph: any) => periph.dmac === peripheral.dmac
    );

    if(cdvsIndex > -1) {
      deviceClone.details.settings.conf.cdvs[cdvsIndex].cail = fieldsState.cail;
      loading.start('Updating Peripheral');
      props.updateDevice({...deviceClone}).then((resp: any) => {
        loading.stop();
        if (resp?.statusCode === 200) {
          Notification({
            title: 'Successfully',
            message: config?.notifications?.update?.success,
            type: 'success',
          });
        }
      });
    }
  };


  useEffect(() => {
    if(fieldsState !== null && fieldsState !== undefined){
      let vlResult: any = validate(fieldsState, config.constraints);

      if (vlResult) {
        setErrors(vlResult);
        let msm: string = '';

        Object.keys(vlResult).forEach((key: string) => {
          msm += vlResult[key].map((err: any) => `${err}, `);
        });
        msm = msm.substring(0, msm.length - 2);
      } else {
        setErrors(null);
      }
    }
  }, [fieldsState]);


  useEffect(() => {
    if(peripheral){
      setFieldsState((prevState: any) => {
        return {
          ...prevState,
          cail: peripheral?.cail
        };
      });
    }
  }, [peripheral]);

  useEffect(() => {
    window.addEventListener('beforeunload', saveDeviceData);
    if (!peripheral) {
      recoverDeviceDetails();
    }
    return () => {
      window.removeEventListener('beforeunload', saveDeviceData);
      localStorage.removeItem(STORAGE_PERIPHERAL_LABEL);
    };
  }, []);

  return (
    <InstallerTemplate
      id="edit-peripheral"
      title={config?.strings?.title}
      titleBackButton
    >
      <BreadcrumbsItem to="/dashboard/devices/details/peripheral/setup">
        Peripheral Edit
      </BreadcrumbsItem>
      <Grid container direction="column">
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item className="section-1">
            <div>
              <Input
                handleChange={onChangeState.bind(null, 'cail')}
                handleChangeError={(err: any) => {
                  setErrors((prevState: any) => ({
                    ...prevState,
                    cail: err,
                  }));
                }}
                type={config.fields.cail.type}
                label={config.fields.cail.label}
                value={fieldsState?.cail}
                error={errors?.cail}
                name={'cail'}
              />
            </div>
          </Grid>
          <Grid item className="section-2">
            <div className="col d-flex pt-4 pb-2">
              <Button
                variant="primary"
                className="btn  btn-primary btn-block"
                type="button"
                disabled={isLoading}
                onClick={handleUpdatePeripheral}
              >
                {config?.strings?.confirm ? config.strings.confirm : 'Save'}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <SweetAlert
        info
        show={showUpdateAlert}
        showCancel
        confirmBtnText={config?.alerts?.update?.btn?.confirm}
        confirmBtnBsStyle="info"
        title={`${config?.alerts?.update?.question}`}
        onConfirm={updatePeripheral}
        onCancel={() => setShowUpdateAlert(false)}
        focusCancelBtn
      />
    </InstallerTemplate>
  );
};

function mapStateToProps(state: any) {
  return {
    isLoading: state.loader.loading,
    peripheral: state.devices.peripheral,
    device: state.devices.currentDevice,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    loading: {
      start: dispatch.loader.startLoader,
      stop: dispatch.loader.stopLoader,
    },
    setPeripheral: dispatch.devices.setPeripheral,
    reqDeviceDetails: dispatch.devices.getDeviceDetails,
    updateDevice: dispatch.devices.editDevice
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PeripheralEdit);
