import React from 'react';
import { SwitchBtnProps } from './interface';
import { Switch } from '@material-ui/core';

const SwitchBtn: React.FC<SwitchBtnProps> = ({
  id,
  value,
  onChange,
  onClick,
  className,
  style,
  disabled,
  name,
}) => {
  return (
    <Switch
      id={id}
      checked={!!value}
      onChange={onChange}
      color="primary"
      onClick={onClick}
      className={className}
      style={style}
      disabled={disabled}
      name={name}
    />
  );
};

export default SwitchBtn;
