import React from 'react';
import { UpSectionProps } from './interface';
import './styles.scss';

const UpSection: React.FC<UpSectionProps> = ({ config, item }) => {
  return (
    <div id="top--section--1">
      <h6 className="display-5">{config?.strings?.main}</h6>
      <p className="lead smlead">
        {config?.strings?.lead}
        <span className="stronger-ft">{item?.name}</span>.
      </p>
    </div>
  );
};

export default UpSection;
