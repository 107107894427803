import { ConnectionBroker } from '../../core';

export const adminApiRequests = {
  state: {
    logsData: {},
  },
  reducers: {
    updateState(state: any, payload: any, name: string) {
      return { ...state, [name]: payload };
    },
  },
  effects: (dispatch: any) => ({
    async getSystemLogs(payload: any) {
      const self: any = this;
      const { system_id, user_id, page, items }: any = payload || {};
      let response: any = {
        ok: false,
        data: null,
      };

      const tempLogs: any = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        'admin.requestLogsBySystem',
        null,
        { device_id: system_id, user_id, page, items },
      );

      if (tempLogs?.statusCode === 200) {
        response = {
          ok: true,
          data: tempLogs?.body ? tempLogs.body : [],
        };
      }

      return response;
    },
    async getSystemsByUserId(payload: any) {
      const self: any = this;
      const { user_id }: any = payload || {};
      let response: any = {
        ok: false,
        data: null,
      };

      const tempData: any = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        'admin.requestSystemsByUser',
        null,
        { user_id },
      );

      if (tempData?.statusCode === 200) {
        response = {
          ok: true,
          data: tempData?.body ? tempData.body : [],
        };
      }

      return response;
    },
    async getSensorsByUserId(payload: any) {
      const self: any = this;
      const { user_id }: any = payload || {};
      let response: any = {
        ok: false,
        data: null,
      };

      const tempData: any = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        'admin.requestSensorsByUser',
        null,
        { user_id },
      );

      if (tempData?.statusCode === 200) {
        response = {
          ok: true,
          data: tempData?.body ? tempData.body : [],
        };
      }

      return response;
    },
  }),
};
