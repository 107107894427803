import React from 'react';
import { MdOutlineAdminPanelSettings } from 'react-icons/md';
import { AiOutlineUser } from 'react-icons/ai';
import './styles.scss';

interface CircleProps {
  firstLetter: string;
  secondLetter?: string;
  isAdmin?: boolean;
}

const Circle: React.FC<CircleProps> = ({
  firstLetter,
  secondLetter,
  isAdmin,
}) => {
  return (
    <div id="icon-avt-img">
      <div className="circle modal-circle">
        <span className="circle-content">{firstLetter}</span>
        <span className="circle-content">{secondLetter}</span>
      </div>

      <div id="icon-c">
        <span id="inner-icon">
          {isAdmin ? (
            <MdOutlineAdminPanelSettings className="user-icon" />
          ) : (
            <AiOutlineUser className="user-icon" />
          )}
        </span>
      </div>
    </div>
  );
};

export default Circle;
