import config from '../../../../config/config';

export default {
  strings: {
    ...config.strings,
    title: 'Two-Factor Authentication',
    login: 'Confirm',
  },
  fieldsState: {
    code: '111111',
  },
  fieldsFocus: {
    username: false,
  },
  fields: {
    email: {
      label:'E-mail',
      type: 'email',
      autofocus: true,
      placeholder:"E-mail",
      cols: {
        col: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      },
    },
    code: {
      label:'Code',
      type: 'password',
      autofocus: true,
      placeholder:"Code",
      cols: {
        col: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      },
    },

  },
  constraints: {
    email: {
      email: true
    },
    code: {
      length: {
        minimum: 6,
      },
    },
  },
};
