import config from '../../config/config';

export const jitG3Iot = {
  basesURL: {
    baseURLs: config.apis.g3iot.baseURLs,
    baseSocketURLs: config.apis.jitBE.baseSocketURLs,
  },
  logs: {
    list: {
      path: '/logs/list?items=5',
      description: 'Device logs list',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    }
  },
  alerts: {
    list: {
      path: '/alerts/list',
      description: 'list all alerts',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    show: {
      path: '/alerts/show',
      description: 'show unviewed alerts',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    updateStatus: {
      path: '/alerts/update-status',
      description: 'to mark alert as viewed.',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'put',
    },
  },
  
};
