// @ts-nocheck
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import _ from 'lodash';
import Skeleton from '@material-ui/lab/Skeleton';
import { ExpandLess, ExpandMore } from '@material-ui/icons/';
import { BsQuestionCircle } from 'react-icons/bs';
import { CiWarning } from 'react-icons/ci';
import numeral from 'numeral';
import TooltipView from 'react-components/components/Tooltip';
import SweetAlert from 'react-bootstrap-sweetalert';
import { io } from 'socket.io-client';
import { useAuth } from '../../../../../sessions/hooks/auth';
import { Notification } from '../../../../../../../react-components';
import { WidgetSingleSlider, NoDevicesAlert, WidgetActions } from '../common';
import {
  SwitchGroup,
  SwitchGroupV2,
  FloatButton,
  SwitchButton,
  WarnTooltip,
} from './components';
import IndoorAirTooltip from './components/IndoorAirTooltip';
import { DevicesUtils } from '../../utils';
import config from './config';
import * as globalConfigs from '../../../../../../config/config';
import './styles.scss';




const COMPONENT_STRINGS: any = { ...config?.strings };
const ALERTS_STRINGS: any = { ...config?.alerts };
const FORMATS: any = { ...config.formats };
const DEFAULTS_MAX: any = { ...config.defaults.maxs };
const MODEL_FORMAT: any = { ...config.model };
const CK_MODEL_FORMAT: any = { ...config.ckModel };
const DEFAULT_EXTREMS: any = { ...config.sliderValues };
const REQ_COUNTER: any = 7;
const MAIN_PROPS: any = ['pm25', 'co2', 'tVoc'];
const ST_LABEL: string = config.strings.stLabel;
const SLIDERS_DEFAULTS: any = { ...config.sliders_defaults };
let INTERVALS: any = [];
let WIDGETSTATES_COPY: any = {};
let SOCKET: any;
let WEB_SOCKETS: any = {};
let REQUEST_STATES: any = {};

interface IndoorAirProps {
  device: any;
  devices: any;
  setup: any;
  connection: any;
  sensors: any;
  requestData: any;
  requestAirthingsData: Function;
  requestAwairData: Function;
  updateLocalSensorState: Function;
  loading: any;
  updateDevice: any;
  getSysSettings: Function;
  getSysSetup: Function;
  updateSysSetup: Function;
  isMounted?: any;
  setCurrentDevice: Function;
  status: any;
}

const IndoorAir: React.FC<IndoorAirProps> = ({
  device,
  connection,
  sensors,
  isMounted,
  ...props
}) => {
  const history = useHistory();
  const { token } = useAuth();
  const [
    insideAirSectionIsEnabled,
    setInsideAirSectionIsEnabled,
  ]: boolean = useState(false);
  const [expandedCtrl, setIsExpandedCtrl]: any = useState(false);
  const [peripherals, setPeripherals]: any = useState({});
  const [widgetCkStates, setWidgetCkStates]: any = useState({});
  const [showSaveBtn, setShowSaveBtn]: any = useState(false);
  const [currentFoDev, setCurrentFoDev]: object = useState(null);
  const [isOwnSetup, setIsOwnSetup]: boolean = useState(false);
  const [sysSettings, setSysSettings]: object = useState({});
  const [sysSetup, setSysSetup]: object = useState({});
  const [showAlert, setShowAlert]: boolean = useState(false);
  const [showNoSensorAlert, setShowNoSensorAlert]: boolean = useState(false);
  // const [isFooDeviceSet, setIsFooDeviceSet]: boolean = useState(false);
  const [isInit, setIsInit]: boolean = useState(false);
  const [onEditMode, setToEditMode]: boolean = useState(false);
  const [currentDeviceId, setCurrentDeviceId]: any = useState();
  const [isSensorSet, setIsSensorSet]: any = useState(false); //   HERE ....
  const [showView, setShowView] = useState({
    co2: true,
    tVoc: true,
    pm25: true,
  });
  const [widgetStates, setWidgetStates]: any = useState({
    pm25: { ...MODEL_FORMAT },
    co2: { ...MODEL_FORMAT },
    tVoc: { ...MODEL_FORMAT },
  });






  const handleAddFoobot = (event: any) => {
    history.push('/dashboard/foobot');
  };

  const onClickWarningIcon = (event: any) => {
    const { device_id }: any = device || {};
    const { tagName, id }: any = event?.target || {};

    if (
      tagName !== null &&
      tagName !== undefined
    ) {
      if (
        (tagName === 'svg' || tagName === 'path') &&
        id === 'warn-sys-settings'
      ) {
        props.setCurrentDevice({ ...device });
        history.push({
          pathname: `dashboard/system-settings/${device_id}`,
          state: { sensorAlert: true },
        });
      }
    }
  };


  const startSocket = async (
    _device: any,
    deviceId: string,
    data: any,
    channel: string
  ) => {
    const { device_id, device_name }: any = _device || {};
    const _token: string = token?.token;
    const { path, process } = data;
    let channelSTR: string = `${channel}-${deviceId}`;
    const socket: any =
      globalConfigs?.default?.apis?.jitBE?.baseSocketURLs &&
      globalConfigs?.default?.apis?.jitBE?.baseSocketURLs[0]
        ? io(globalConfigs?.default?.apis?.jitBE?.baseSocketURLs[0], {
            path: '/socket.io',
            transports: ['websocket'],
            autoConnect: false,
            withCredentials: true,
            auth: {
              token: _token,
              path: path,
              process: process,
              channel: channel,
              name: deviceId,
              channelName: channelSTR,
            },
          })
        : null;


    if(socket){
      WEB_SOCKETS[device_id] = {
        socket: socket.connect(),
        device: _device,
        channel: channelSTR,
        connected: false,
      };


      WEB_SOCKETS[device_id].socket.on('connect', () => {
        WEB_SOCKETS[device_id].connected = true;
      });

      WEB_SOCKETS[device_id].socket.on('disconnect', (reason: any) => {
        console.log(
          '\n - - [INDOOR.AIR]  SOCKET DISCONNECT  ->   ',
          WEB_SOCKETS[device_id].channel,
          reason,
        );
      });

      WEB_SOCKETS[device_id].socket.on('connect_error', (error: any) => {
        console.log(
          '\n - - [INDOOR.AIR]  SOCKET ERROR  ->   ',
          WEB_SOCKETS[device_id].channel,
          error,
        );
      });

      WEB_SOCKETS[device_id].socket.on(WEB_SOCKETS[device_id].channel, (data: any) => {
        const { datapoint } = data || {};

        if (datapoint) {
          let tempData: any = updateCurrentStates(device_id, datapoint);

          if (tempData && Object.keys(tempData).length > 0) {
            let tempStatesClone: any = _.cloneDeep(WIDGETSTATES_COPY[device_id]);

            for (const prop in tempData) {
              tempStatesClone = {
                ...tempStatesClone,
                [prop]: tempData[prop],
              };
            }

            setWidgetStates(
              _.cloneDeep(tempStatesClone)
            );
          }
        }
      });
    }

  };




  const destroySocket = (_device: any) => {
    const { device_id }: any = _device || {};

    if (
      WEB_SOCKETS[device_id] !== null &&
      WEB_SOCKETS[device_id] !== undefined
    ) {
      const { details, device_type } = currentFoDev || {};

      WEB_SOCKETS[device_id].socket.off('connect');
      WEB_SOCKETS[device_id].socket.off('disconnect');
      WEB_SOCKETS[device_id].socket.off('connect_error');

      if(WEB_SOCKETS[device_id].channel){
        WEB_SOCKETS[device_id].socket.off(
          WEB_SOCKETS[device_id].channel
        );
      }

      WEB_SOCKETS[device_id].socket.disconnect();
      WEB_SOCKETS[device_id] = null;
      delete WEB_SOCKETS[device_id];
    }
  };


  const handleChange = (panel: string) => (event: any, isExpanded: any) => {
    if (
      connection &&
      currentFoDev !== null &&
      currentFoDev !== undefined
    ) {
      setIsExpandedCtrl(isExpanded);
      localStorage.setItem(`${ST_LABEL}${currentDeviceId}`, isExpanded);
    }
  };


  const toggleSection = (_status: any) => {
    if(
      props &&
      props.hasOwnProperty('status') &&
      props?.status?.stoped
    ){
      return;
    }

    if(
      currentFoDev !== null &&
      currentFoDev !== undefined
    ){
      setInsideAirSectionIsEnabled(!_status);

      if (!_status) setShowSaveBtn(true);
    }
  };



  const isValueInit = (value: number, prop: string) => {
    return (
      value[prop] && value[prop].hasOwnProperty('current') && value[prop].init
    );
  };

  const populateWidgetFields = (data: any, indoorAir: any, target: any) => {
    MAIN_PROPS.forEach((prop: string) => {
      if (data) {
        let tempCurrent: any = data.find(
          (item: any) => item._prop === prop && item.pointer === 'current',
        );

        if (tempCurrent && Object.values(tempCurrent).length > 0) {
          target[prop].current = tempCurrent.value;
        }
      }
      target[prop].max = indoorAir?.slider[prop] || -101;
      target[prop].init = true;
    });

    return target;
  };


  const startIndoorAirValues = async (_device: any, connectedDevices: any) => {
    const { device_id, device_name }: any = _device || {};
    let deviceType: any;
    const sysSettings: any = await props.getSysSettings({ id: device_id });

    const { statusCode, body } = sysSettings || {};
    let widgetStatesClone: any = _.cloneDeep({ ...widgetStates });
    let currentSensor: any;
    let currentSlidersStates: any;

    if (statusCode === 200 && body?.settings) {
      const { settings, setup } = body;
      setSysSettings({ ...settings });

      if (settings && Object.keys(settings).length > 0) {
        currentSensor = handleSystemSettings(settings, sensors);

        if (currentSensor) {
          const { details, device_type } = currentSensor;
          deviceType = device_type;

          if(details?.name === 'LOCAL' || details?.status?.includes('local')){
            props.updateLocalSensorState(
              true,
              'hasLocal'
            );
          }

          if (device_type === 'foobot') {
            currentSlidersStates = await processDeviceV2(
              details.uuid,
              widgetStatesClone,
            );
          } else {
            await processDevice(details.uuid, device_type, widgetStatesClone);
          }
        }
      }

      if (setup) {
        const { indoorAir } = setup || {};
        setSysSetup({ ...setup });

        if (Object.keys(connectedDevices).length > 0) {
          const { data } = currentSlidersStates || {};

          populateWidgetFields(data, indoorAir, widgetStatesClone);

          if (indoorAir?.checkbox) setWidgetCkStates({ ...indoorAir.checkbox });

          if (deviceType && deviceType === 'foobot') {
            setWidgetStates((prevStates: any) => {
              return {
                ...prevStates,
                ...widgetStatesClone,
              };
            });
          }
        }
      }
    } else {
      let tempSensor: any = handleNoSystemSettings(sensors);

      if (statusCode === 200 && tempSensor) {
        const { setup } = body;
        const { details, device_type } = tempSensor;
        deviceType = device_type;

        if (device_type === 'foobot') {
          currentSlidersStates = await processDeviceV2(
            details.uuid,
            widgetStatesClone,
          );
        } else {
          await processDevice(details.uuid, device_type, widgetStatesClone);
        }

        if (setup) {
          const { indoorAir } = setup || {};
          setSysSetup({ ...setup });

          if (Object.keys(connectedDevices).length > 0) {
            const { data } = currentSlidersStates || {};

            populateWidgetFields(data, indoorAir, widgetStatesClone);

            if (indoorAir?.checkbox)
              setWidgetCkStates({ ...indoorAir.checkbox });

            if (deviceType && deviceType === 'foobot') {
              setWidgetStates((prevStates: any) => {
                return {
                  ...prevStates,
                  ...widgetStatesClone,
                };
              });
            }
          }
        }
      }
    }

    setIsInit(true);
  };


  const mapAirthingsDatapointProps = (prop: string) => {
    switch (prop) {
      case 'pm25':
        return 'pm';
      case 'tVoc':
        return 'voc';
      default:
        return prop;
    }
  };


  const updateCurrentStates = (devId: string, data: any) => {
    const { datapoints, sensors } = data;
    const currentProps: any = ['pm25', 'co2', 'tVoc'];
    let prevValuesClone: any = _.cloneDeep(WIDGETSTATES_COPY[devId]);

    currentProps.forEach((prop: string) => {
      let tempIndex: number = sensors.findIndex(
        (sens: any) => sens === mapAirthingsDatapointProps(prop),
      );

      if (
        tempIndex > -1 &&
        datapoints &&
        datapoints.length > 0 &&
        datapoints[0][tempIndex] !== null &&
        datapoints[0][tempIndex] !== undefined
      ) {
        prevValuesClone[prop].current = datapoints[0][tempIndex];
      }
    });

    return _.cloneDeep(prevValuesClone);
  };


  const processDeviceData = (data: any, states: any, devType: any) => {
    const { datapoints, sensors } = data;
    const statesClone: any = _.cloneDeep({ ...widgetStates });

    if (sensors) {
      Object.keys(statesClone).forEach((prop: string) => {
        let tempVal: any = DevicesUtils.extractFoobotProps(
          datapoints,
          sensors,
          prop,
        );
        statesClone[prop].current = tempVal.value;
      });
      let packResult: any = DevicesUtils.packageFields(statesClone);

      packResult.data = packResult.data.map((item: any) => {
        const { _prop, pointer } = item || {};

        if (_prop === 'pm25' && pointer === 'max')
          item.value = states.pm25.max;
        else if (_prop === 'co2' && pointer === 'max')
          item.value = states.co2.max;
        else if (_prop === 'tVoc' && pointer === 'max')
          item.value = states.tVoc.max;

        return item;
      });

      if (packResult && Object.keys(packResult).length > 0) {
        if (packResult.data && packResult.data.length > 0) {
          let tempStates: any = _.cloneDeep(widgetStates);

          packResult.data.forEach((item: any) => {
            const { _prop, pointer, value }: any = item;

            tempStates[_prop][pointer] = value;
            tempStates[_prop].init = true;
          });

          setWidgetStates(_.cloneDeep(tempStates));
        }
      }
      // setIsFooDeviceSet(true);
    }
  };


  const getFuncByType = (type: any, value: any) => {
    switch (type) {
      case 'airthings':
        return {
          func: 'requestAirthingsData',
          payload: { id: value },
        };
      case 'awair':
      case 'awair-element':
      case 'awair-omni':
        return {
          func: 'requestAwairData',
          payload: { id: value },
        };
      case 'aircycler-local':
      case 'aircycler-sensor':
        return {
          func: 'requestLocalAirCycler',
          payload: { id: value },
        };
      case 'foobot':
      default:
        return {
          func: 'requestData',
          payload: { device_id: value },
        };
    }
  };


  const filterParams = (type: any, data: any) => {
    switch (type) {
      case 'airthings':
      case 'awair':
      case 'awair-element':
      case 'awair-omni':
      case 'aircycler-local':
      case 'aircycler-sensor':
        const { datapoint }: any = data || {};

        return { ...datapoint };
      case 'foobot':
      default:
        return { ...data };
    }
  };


  const processDevice = async (uuid: string, devType: string, states: any) => {
    const funcData: any = getFuncByType(devType, uuid);
    const response: any = await props[funcData.func](funcData.payload);

    if (response?.statusCode === 200) {
      const { body } = response;
      let preParam: any = filterParams(devType, body);

      devType && devType === 'foobot'
        ? processDeviceDataV3(body, states)
        : processDeviceData(preParam, states, devType);
    } else {
      if (REQUEST_STATES[device.device_id]) {
        REQUEST_STATES[device.device_id] = false;
      }
    }
  };


  const processDeviceDataV2 = (data: any, states: any) => {
    const { datapoints, sensors } = data;
    const statesClone: any = _.cloneDeep({ ...widgetStates });
    let packResult: any;

    if (sensors) {
      Object.keys(statesClone).forEach((prop: string) => {
        let tempVal: any = DevicesUtils.extractFoobotProps(
          datapoints,
          sensors,
          prop,
        );
        statesClone[prop].current = tempVal.value;
      });
      packResult = DevicesUtils.packageFields(statesClone);
      packResult.data = packResult.data.map((item: any) => {
        const { _prop, pointer } = item;
        if (_prop === 'pm25' && pointer === 'max') item.value = states.pm25.max;
        else if (_prop === 'co2' && pointer === 'max')
          item.value = states.co2.max;
        else if (_prop === 'tVoc' && pointer === 'max')
          item.value = states.tVoc.max;
        return item;
      });
    }

    return packResult;
  };


  const processDeviceDataV3 = (data: any, states: any) => {
    const { datapoints, sensors } = data;
    const statesClone: any = _.cloneDeep(
      states !== null && states !== undefined
        ? { ...states }
        : { ...widgetStates },
    );
    let packResult: any;

    if (sensors) {
      Object.keys(statesClone).forEach((prop: string) => {
        let tempVal: any = DevicesUtils.extractFoobotProps(
          datapoints,
          sensors,
          prop,
        );
        statesClone[prop].current = tempVal.value;
      });
      packResult = DevicesUtils.packageFields(statesClone);
      packResult.data = packResult.data.map((item: any) => {
        const { _prop, pointer } = item;
        if (_prop === 'pm25' && pointer === 'max') item.value = states.pm25.max;
        else if (_prop === 'co2' && pointer === 'max')
          item.value = states.co2.max;
        else if (_prop === 'tVoc' && pointer === 'max')
          item.value = states.tVoc.max;
        return item;
      });

      if (
        packResult &&
        Object.keys(packResult).length > 0
      ) {
        if (
          packResult.data &&
          packResult.data.length > 0
        ) {
          MAIN_PROPS.forEach((prop: string) => {
            let tempCurrent: any = packResult.data.find(
              (item: any) => item._prop === prop && item.pointer === 'current',
            );

            if (
              tempCurrent &&
              Object.values(tempCurrent).length > 0
            ) {
              statesClone[prop].current = tempCurrent.value;
              statesClone[prop].init = true;
            }
          });

          setWidgetStates((prevStates: any) => {
            return {
              ...prevStates,
              ...statesClone,
            };
          });
        }
      }
      // setIsFooDeviceSet(true);
    }
  };


  const processDeviceV2 = async (uuid: string, states: any) => {
    const response: any = await props.requestData({ device_id: uuid });
    let tempStates: any = _.cloneDeep(states);

    if (response?.statusCode === 200) {
      const { body } = response;
      tempStates = processDeviceDataV2({ ...body }, tempStates);
    }
    return tempStates;
  };


  const transformSingleSlider = (values: any, id: string) => {
    const { max, min, current } = values;
    return [current, max === -101 ? SLIDERS_DEFAULTS[id] : max];
  };


  const onChangePm25 = (newValues: any) => {
    const tempValues: any = DevicesUtils.arrayPackageByProp(newValues, 'pm25');

    if (tempValues) {
      const { prop, data }: any = tempValues;

      setWidgetStates((prevStates: any) => {
        return {
          ...prevStates,
          [prop]: {
            ...prevStates[prop],
            [data[0].pointer]: data[0].value,
            [data[1].pointer]: data[1].value,
          },
        };
      });
    }

    if (Object.values(peripherals).length > 0) setShowSaveBtn(true);
    setToEditMode(true);
  };


  const onChangeCO2 = (newValues: any) => {
    const tempValues: any = DevicesUtils.arrayPackageByProp(newValues, 'co2');

    if (tempValues) {
      const { prop, data }: any = tempValues;

      setWidgetStates((prevStates: any) => {
        return {
          ...prevStates,
          [prop]: {
            ...prevStates[prop],
            [data[0].pointer]: data[0].value,
            [data[1].pointer]: data[1].value,
          },
        };
      });
    }

    if (Object.values(peripherals).length > 0) setShowSaveBtn(true);
    setToEditMode(true);
  };


  const onChangeTvoc = (newValues: any) => {
    const tempValues: any = DevicesUtils.arrayPackageByProp(newValues, 'tVoc');

    if (tempValues) {
      const { prop, data }: any = tempValues;

      setWidgetStates((prevStates: any) => {
        return {
          ...prevStates,
          [prop]: {
            ...prevStates[prop],
            [data[0].pointer]: data[0].value,
            [data[1].pointer]: data[1].value,
          },
        };
      });
    }

    if (Object.values(peripherals).length > 0) setShowSaveBtn(true);
    setToEditMode(true);
  };


  const normalizeMaxValues = (
    prop: string,
    maxValue: any,
    currentValues: any,
  ) => {
    let normalizedValue: any = maxValue;
    const { current, init }: any = currentValues || {};
    let gainA: number = 100;

    switch (prop) {
      case 'co2':
      case 'tVoc':
        gainA = 200;
        break;
      case 'pm25':
      default:
        break;
    }

    if (
      init &&
      current &&
      current > maxValue
    ) {
      const factor: number = parseInt(current).toString().length;
      let gain: number = Math.pow(10, factor - 1);
      normalizedValue = current + 100;
      if (gain > 0) normalizedValue = parseInt(normalizedValue / gain) * gain;
    }

    return normalizedValue;
  };


  const onChangeWgtCk = (section: string, event: any, value: any) => {
    const { id }: any = event?.target || {};
    const props: any = id.split('-');

    if (props && props[0] && props[1]) {
      if (
        props[1] === 'damper' ||
        props[1] === 'fanConnect' ||
        props[1] === 'centralFan'
      ) {
        setWidgetCkStates((prevVals: any) => {
          return {
            ...prevVals,
            [section]: {
              ...prevVals[section],
              [props[0]]: value,
            },
          };
        });
      } else {
        setWidgetCkStates((prevVals: any) => {
          return {
            ...prevVals,
            [section]: {
              ...prevVals[section],
              [props[1]]: {
                ...prevVals[section][props[1]],
                [props[0]]: value,
              },
            },
          };
        });
      }
      setShowSaveBtn(true);
      setToEditMode(true);
    }
  };


  const requestSysSettings = async (id: any) => {
    const sysSettings: any = await props.getSysSettings(id);
    const { statusCode, body } = sysSettings || {};

    if (statusCode === 200 && body?.settings) {
      const { settings, setup } = body;
      setSysSettings({ ...settings });
      if (setup) setSysSetup({ ...setup });
    } else {
      handleNoSystemSettings(sensors);
    }
  };


  const requestSysSetup = async (dev: object) => {
    const { device_id }: any = dev || {};
    const result: any = await props.getSysSetup({ id: device_id });

    if (result) {
      setSysSetup({ ...result });
    }
  };



  const prepareValuesToSave = (
    values: any,
    ckValues: any,
    _device: any,
    hasLocal?: boolean,
  ) => {
    const { device_id } = _device;
    let tempSettings: any = {
      slider: {},
      checkbox: {},
      turnedOn: insideAirSectionIsEnabled,
      hasLocal: !!hasLocal,
    };

    MAIN_PROPS.forEach((prop: string) => {
      tempSettings.slider[prop] =
        values.hasOwnProperty(prop) &&
        values[prop].max !== null &&
        values[prop].max !== undefined &&
        values[prop].max !== -101
          ? values[prop].max
          : SLIDERS_DEFAULTS[prop];

      tempSettings.checkbox[prop] = { ...ckValues[prop] };
    });

    return {
      system_id: device_id,
      setup: { indoorAir: tempSettings },
    };
  };



  const hasLocalSensor = (_device: any) => {
    return (
      _device?.details?.settings?.conf?.lsnm &&
      _device?.details?.settings?.conf?.lsnm !== '' &&
      (
        _device.details.settings.conf.lsnm.includes('awair') ||
        _device.details.settings.conf.lsnm.includes('AirCyclerAQ')
      )
    );
  };


  const onSaveIndoorData = async (event: any) => {
    setShowAlert(false);
    props.loading.start('Updating device settings...');
    setShowSaveBtn(false);
    const devSetup: any = prepareValuesToSave(
      widgetStates,
      widgetCkStates,
      device,
      hasLocalSensor(device),
    );

    let result: any = await props.updateSysSetup(devSetup);

    if (result) {
      setSysSetup({ ...result });
      Notification({
        title: 'Successfully',
        message: config?.notifications?.update?.success,
        type: 'success',
      });
    }

    props.loading.stop();
  };

  const onClickSetupSensor = (event: any) => {
    const { device_id }: any = device || {};

    if (device && device_id) {
      props.setCurrentDevice({ ...device });

      history.push({
        pathname: `dashboard/system-settings/${device_id}`,
        state: { sensorAlert: true },
      });
    }
  };

  const handleSaveIndoorData = async (event: any) => {
    if (isSensorSet) {
      setShowAlert(true);
      setToEditMode(false);
    } else {
      setShowNoSensorAlert(true);
    }
  };

  const onCancelAlert = (event: any) => {
    setShowAlert(false);
    setToEditMode(false);
    setShowSaveBtn(false);
    requestSysSetup(device);
  };

  const onCancelSetupSensor = (event: any) => {
    setShowNoSensorAlert(false);
  };

  const retriveSensorName = useCallback(() => {
    const { device_name }: any = currentFoDev || {};
    return device_name && device_name !== '' ? device_name : '_ _ _ _ _ _ _ _';
  }, [currentFoDev]);


  const isLocalSensor = useCallback(() => {
    const { conf }: any = device?.details?.settings || {};
    const {
      device_type,
      details,
    } = currentFoDev || {};

    switch (device_type) {
      case 'awair-omni':
      case 'aircycler-local':
        const localLabel: string = conf && conf.lsnm !== '' ? `${conf.lsnm}-local` : '';
        const isLocal: boolean = details?.name === 'LOCAL' || details?.status?.includes('local');
        const isRunning: boolean = localLabel !== '' && localLabel === details?.uuid;

        return isLocal ? (isRunning ? '(Local)' : '(Disconnected)') : '';
      default:
        return '';
    }
  }, [currentFoDev, widgetStates]);


  const allowedSensor = (devType: any) => {
    switch (devType) {
      case 'awair':
      case 'awair-element':
      case 'awair-omni':
      case 'airthings':
      case 'foobot':
      case 'aircycler-local':
        return true;
      default:
        return false;
    }
  };


  const handleNoSystemSettings = (devSensors: any) => {
    let fooDevData: any = devSensors.find((sensor: any) => {
      const { device_type }: any = sensor || {};

      return allowedSensor(device_type);
    });

    setIsOwnSetup(false);
    return fooDevData;
  };


  const handleSystemSettings = (settings: object, devSensors: any) => {
    const hasSettings: boolean = Object.keys(settings).length > 0;

    if (hasSettings) {
      let sensorName: string = settings[Object.keys(settings)[0]]?.add_sensor;
      let sensorId: string = settings[Object.keys(settings)[0]]?.add_sensor_id;

      if (sensorName && sensorName !== '' && sensorName !== 'Foobot') {
        let sensor: any = devSensors.find(
          (sens: object) =>
            sens.device_name === sensorName && sens.device_id === sensorId,
        );

        if (!sensor && devSensors.length > 0) {
          // sensor = devSensors[0];
          setIsSensorSet(false);
        }else{
          setIsOwnSetup(true);
        }

        setCurrentFoDev(sensor);
        return sensor;
      }
    }

    // return handleNoSystemSettings(devSensors);
    return;
  };


  const expandDetails = (value: any) => {
    switch (value) {
      case 'pm25':
        setShowView((prevState: any) => {
          return {
            ...prevState,
            pm25: !showView.pm25,
          };
        });
        break;
      case 'co2':
        setShowView((prevState: any) => {
          return {
            ...prevState,
            co2: !showView.co2,
          };
        });
        break;

      case 'tVoc':
        setShowView((prevState: any) => {
          return {
            ...prevState,
            tVoc: !showView.tVoc,
          };
        });
        break;
      default:
        break;
    }
  };


  const prepareCkboxStates = (connectedDevs: any) => {
    let tempStates: any = {};

    if (Object.values(connectedDevs).length > 0) {
      Object.keys(connectedDevs).forEach((key: string) => {
        if (key === 'fanConnect') {
          tempStates.fanConnect = false;
        } else if (key === 'damper') {
          tempStates.dmst = false;
        } else if (key === 'centralFan') {
          tempStates.cfcm = false;
        } else {
          connectedDevs[key].forEach((cDev: any) => {
            const { cdid } = cDev;
            if (!tempStates[key]) tempStates[key] = {};
            tempStates[key][cdid] = false;
          });
        }
      });
    }
    return tempStates;
  };


  const extractPeripherals = (_details: any) => {
    const { conf } = _details?.settings || {};
    const cdvsClone: any = conf?.cdvs ? _.cloneDeep(conf.cdvs) : [];

    return {
      ventConnect: cdvsClone.filter((item: any) =>
        item.cdid.startsWith('AirCyclerVC'),
      ),
      fanConnect: conf?.fcsp === 1 || conf?.fcsp === '1',
      damper: true,
      pressureConnect: cdvsClone.filter((item: any) =>
        item.cdid.startsWith('AirCyclerPC'),
      ),
      freshConnect: cdvsClone.filter(
        (item: any) =>
          item.cdid.startsWith('AirCyclerFH') ||
          item.cdid.startsWith('AirCyclerFT'),
      ),
      centralFan: true,
    };
  };


  const prepareCkboxStatesBySection = (connectedDevs: any) => {
    const defaultSections = Object.keys(DEFAULT_EXTREMS).map(
      (section: string) => section,
    );
    let cksDefaults: any = {};
    defaultSections.forEach((prop: string) => {
      cksDefaults[prop] = prepareCkboxStates(connectedDevs);
    });

    return cksDefaults;
  };






  useEffect(() => {
    if (
      sysSettings !== null &&
      sysSettings !== undefined &&
      Object.values(sysSettings).length > 0
    ) {
      const {
        add_sensor,
        add_sensor_id,
      }: any = Object.values(sysSettings)[0] || {};

      if(
        sensors &&
        sensors.length > 0 &&
        add_sensor !== '' &&
        add_sensor_id !== ''
      ){
        const sysSensor: any = sensors.find(
          (sensor: any) => sensor?.device_name === add_sensor && sensor.device_id === add_sensor_id
        );

        setIsSensorSet(
          sysSensor !== null && sysSensor !== undefined
        );
      }else{
        setIsSensorSet(
          add_sensor !== '' && add_sensor_id !== ''
        );
      }
    }
  }, [sysSettings]);


  useEffect(() => {
    if (
      insideAirSectionIsEnabled &&
      currentDeviceId !== null &&
      currentDeviceId !== undefined
    ) {
      let storedState: any = localStorage.getItem(
        `${ST_LABEL}${currentDeviceId}`,
      );

      if (storedState !== null && storedState !== undefined) {
        setIsExpandedCtrl(storedState === 'true');
      }
    }
  }, [insideAirSectionIsEnabled, currentDeviceId]);


  useEffect(() => {
    if (!insideAirSectionIsEnabled && isInit) {
      const devSetup: any = prepareValuesToSave(
        widgetStates,
        widgetCkStates,
        device,
        hasLocalSensor(device),
      );

      props.updateSysSetup(devSetup);
    }
  }, [insideAirSectionIsEnabled]);


  useEffect(() => {
    if (device) {
      const { cdvs } = device.details?.settings?.conf || {};
      let tempPeriphs: any = extractPeripherals(device.details);
      setPeripherals(tempPeriphs);
      setCurrentDeviceId(device.device_id);

      if (!isInit) {
        let tempStates: any = prepareCkboxStatesBySection(tempPeriphs);
        setWidgetCkStates(tempStates);
        startIndoorAirValues(device, tempPeriphs);
      }
    }
  }, [device]);


  useEffect(() => {
    if (props.setup) {
      setSysSetup({ ...props.setup });
    }
  }, [props.setup]);


  useEffect(() => {
    if (isInit) {
      (async () => {
        let widgetStatesClone: any = _.cloneDeep({ ...widgetStates });

        if (Object.keys(peripherals).length > 0 && !onEditMode) {
          const { indoorAir } = sysSetup || {};

          MAIN_PROPS.forEach((prop: string) => {
            widgetStatesClone[prop].max =
              indoorAir?.slider.hasOwnProperty(prop) &&
              indoorAir?.slider[prop] !== null &&
              indoorAir?.slider[prop] !== undefined
                ? indoorAir?.slider[prop]
                : -101;

            widgetStatesClone[prop].init = true;
          });

          if (indoorAir) {
            const { checkbox, turnedOn }: any = indoorAir || {};

            if (checkbox !== null && checkbox !== undefined)
              setWidgetCkStates({ ...checkbox });
            if (turnedOn !== null && turnedOn !== undefined)
              setInsideAirSectionIsEnabled(turnedOn);
          }

          setWidgetStates((prevStates: any) => {
            return {
              ...prevStates,
              ...widgetStatesClone,
            };
          });

          if (currentFoDev) {
            const { details, device_type }: any = currentFoDev || {};
            const { device_id, device_name }: any = device || {};

            if (!REQUEST_STATES[device.device_id]) {
              REQUEST_STATES[device.device_id] = true;
              await processDevice(
                details.uuid,
                device_type,
                widgetStatesClone
              );
            }


            if(isOwnSetup){
              if (
                device_type === 'airthings' &&
                !WEB_SOCKETS.hasOwnProperty(device_id)
              ) {
                startSocket(
                  device,
                  details.uuid,
                  { path: 'notifications', process: 'airthings' },
                  'AirThings',
                );
              } else if (
                (device_type === 'awair' ||
                  device_type === 'awair-element' ||
                  device_type === 'awair-omni') &&
                  !WEB_SOCKETS.hasOwnProperty(device_id)
              ) {
                startSocket(
                  device,
                  details.uuid,
                  { path: 'notifications', process: 'awair' },
                  'Awair',
                );
              } else if (
                device_type === 'aircycler-local' &&
                !WEB_SOCKETS.hasOwnProperty(device_id)
              ) {
                startSocket(
                  device,
                  details.uuid,
                  { path: 'notifications', process: 'Local.AirCycler' },
                  'Local.AirCycler',
                );
              }
            }

          }
        }
      })();
    }
  }, [sysSetup, isInit]);


  useEffect(() => {
    const { device_id }: any = device || {};

    if (
      widgetStates !== null &&
      widgetStates !== undefined &&
      device_id !== null &&
      device_id !== undefined
    ) {
      WIDGETSTATES_COPY[device_id] = _.cloneDeep({ ...widgetStates });
    }
  }, [widgetStates]);


  useEffect(() => {
    return () => {
      if (
        INTERVALS &&
        INTERVALS.length > 0
      ) {
        for (const interval of INTERVALS) {
          clearInterval(interval);
        }
        INTERVALS = [];
      }

      if (
        REQUEST_STATES &&
        Object.keys(REQUEST_STATES).length > 0
      ) {
        for (const key in REQUEST_STATES) {
          REQUEST_STATES[key] = false;
        }
      }

      destroySocket(device);
      setCurrentFoDev(null);
      setIsInit(false);
    };
  }, []);



  return (
    <>
      <Accordion
        id="user-widget--indoor-air"
        square
        expanded={insideAirSectionIsEnabled && expandedCtrl}
        onChange={handleChange('indoor-air')}
      >
        <AccordionSummary
          expandIcon={connection ? <ExpandMore /> : <></>}
          aria-controls="widget--ia"
          id="widget--ia"
          className={!connection ? 'no-cursor' : ''}
        >
          <div className="widget--title-container">
            <div className="widget--row">
              <div
                className={`widget--title-wrapp widget--title-label ${
                  !connection ? 'no-connection' : ''
                }`}
              >
                {COMPONENT_STRINGS.title}
                <TooltipView title={<IndoorAirTooltip />}>
                  <span className="helper--wrapper">
                    <BsQuestionCircle
                      className="widget--title-helper-icon"
                      size="1rem"
                    />
                  </span>
                </TooltipView>
                {connection ? (
                  <span className="widget--title-sname current-system">
                    {retriveSensorName()}
                    <span className="widget--title-local-sensor">
                      {isLocalSensor()}
                    </span>
                  </span>
                ) : (
                  <></>
                )}
                {!isSensorSet && (
                  <div className="widget--title-warn-icon">
                    <TooltipView title={<WarnTooltip />}>
                      <span className="warn--container">
                        <CiWarning
                          id="warn-sys-settings"
                          onClick={onClickWarningIcon}
                        />
                      </span>
                    </TooltipView>
                  </div>
                )}
              </div>
              <div className="widget--title-actions">
                {!connection && (
                  <span className="btn-add" onClick={handleAddFoobot}>
                    ADD
                  </span>
                )}

                {connection && (
                  <SwitchButton
                    id="inside-air-section"
                    onChange={() => toggleSection(insideAirSectionIsEnabled)}
                    value={insideAirSectionIsEnabled}
                  />
                )}
              </div>
            </div>
            {connection ? (
              <div className="widget--row" style={{ marginTop: 5 }}>
                <div className="widget--col">
                  {!isValueInit(widgetStates, 'pm25') && connection ? (
                    <Skeleton
                      variant="text"
                      height={60}
                      width={165}
                      style={{ float: 'left' }}
                    />
                  ) : (
                    <div
                      className={
                        !insideAirSectionIsEnabled
                          ? `widget--value--disabled -j-left`
                          : `widget--value -j-left`
                      }
                    >
                      <div className="widget--value-container">
                        <span>
                          {isSensorSet ? numeral(widgetStates.pm25.current).format(
                            FORMATS.ugm3,
                          ) : '_ _'}{' '}
                          ug/m<sup>3</sup>
                        </span>
                        <label className="widget--label">PM 2.5</label>
                      </div>
                    </div>
                  )}
                </div>
                <div className="widget--col">
                  {!isValueInit(widgetStates, 'co2') && connection ? (
                    <Skeleton
                      variant="text"
                      height={60}
                      width={165}
                      style={{ margin: 'auto' }}
                    />
                  ) : (
                    <div
                      className={
                        !insideAirSectionIsEnabled
                          ? `widget--value--disabled j-center`
                          : `widget--value j-center`
                      }
                    >
                      <div className="widget--value-container">
                        <span>
                          {isSensorSet ? numeral(widgetStates.co2.current).format(
                            FORMATS.ppm,
                          ) : '_ _'}{' '}
                          ppm
                        </span>
                        <label className="widget--label">
                          CO<sub>2</sub>
                        </label>
                      </div>
                    </div>
                  )}
                </div>
                <div className="widget--col">
                  {!isValueInit(widgetStates, 'tVoc') && connection ? (
                    <Skeleton
                      variant="text"
                      height={60}
                      width={165}
                      style={{ float: 'right' }}
                    />
                  ) : (
                    <div
                      className={
                        !insideAirSectionIsEnabled
                          ? `widget--value--disabled j-right`
                          : `widget--value j-right`
                      }
                    >
                      <div className="widget--value-container">
                        <span>
                          {isSensorSet ? numeral(widgetStates.tVoc.current).format(
                            FORMATS.ppb,
                          ) : '_ _'}{' '}
                          ppb
                        </span>
                        <label className="widget--label">tVOC</label>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="widget--row">
                <div className="widget--no-foobots">
                  {COMPONENT_STRINGS.noFoobots}
                </div>
              </div>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="widget--body-container">
            <div className="widget--row">
              <div className="widget--row-content">
                <WidgetSingleSlider
                  id="w-pm25"
                  values={transformSingleSlider(widgetStates.pm25, 'pm25')}
                  max={normalizeMaxValues(
                    'pm25',
                    DEFAULT_EXTREMS.pm25.max,
                    widgetStates.pm25,
                  )}
                  min={DEFAULT_EXTREMS.pm25.min}
                  step={1}
                  showLabel={true}
                  symbol=""
                  onChange={onChangePm25}
                  defaultMark={true}
                  defaultMarkValues={{
                    top: 20,
                    left: '29.4',
                    value: SLIDERS_DEFAULTS.pm25,
                  }}
                />
              </div>
              {Object.values(peripherals).length > 0 && (
                <div
                  className="widget--row-expand"
                  onClick={() => expandDetails('pm25')}
                  style={{}}
                >
                  <div className="sub-dp-title">
                    {showView.pm25 ? <></> : 'Show Setup'}
                  </div>
                  <div>{showView.pm25 ? <ExpandLess /> : <ExpandMore />}</div>
                </div>
              )}
              {showView.pm25 && (
                <div className="widget--row-content p--form">
                  {Object.values(peripherals).length > 0 ? (
                    <SwitchGroupV2
                      id={`switch-group-pm25`}
                      device={device}
                      data={peripherals}
                      ckData={widgetCkStates?.pm25}
                      onChange={onChangeWgtCk.bind(null, 'pm25')}
                    />
                  ) : (
                    <NoDevicesAlert />
                  )}
                </div>
              )}
            </div>

            <div className="widget--row ext-row">
              <div className="widget--row-content">
                <WidgetSingleSlider
                  id="w-co2"
                  values={transformSingleSlider(widgetStates.co2, 'co2')}
                  max={normalizeMaxValues(
                    'co2',
                    DEFAULT_EXTREMS.co2.max,
                    widgetStates.co2,
                  )}
                  min={DEFAULT_EXTREMS.co2.min}
                  step={10}
                  showLabel={true}
                  symbol=""
                  onChange={onChangeCO2}
                  defaultMark={true}
                  defaultMarkValues={{
                    top: 20,
                    left: '17.5',
                    value: SLIDERS_DEFAULTS.co2,
                  }}
                />
              </div>
              {Object.values(peripherals).length > 0 && (
                <div
                  className="widget--row-expand"
                  onClick={() => expandDetails('co2')}
                  style={{}}
                >
                  <div className="sub-dp-title">
                    {showView.co2 ? <></> : 'Show Setup'}
                  </div>
                  <div>{showView.co2 ? <ExpandLess /> : <ExpandMore />}</div>
                </div>
              )}
              {showView.co2 && (
                <div className="widget--row-content p--form">
                  {Object.values(peripherals).length > 0 ? (
                    <SwitchGroupV2
                      id={`switch-group-co2`}
                      device={device}
                      data={peripherals}
                      ckData={widgetCkStates?.co2}
                      onChange={onChangeWgtCk.bind(null, 'co2')}
                    />
                  ) : (
                    <NoDevicesAlert />
                  )}
                </div>
              )}
            </div>
            <div className="widget--row ext-row">
              <div className="widget--row-content">
                <WidgetSingleSlider
                  id="w-tvoc"
                  values={transformSingleSlider(widgetStates.tVoc, 'tVoc')}
                  max={normalizeMaxValues(
                    'tVoc',
                    DEFAULT_EXTREMS.tVoc.max,
                    widgetStates.tVoc,
                  )}
                  min={DEFAULT_EXTREMS.tVoc.min}
                  step={10}
                  showLabel={true}
                  symbol=""
                  onChange={onChangeTvoc}
                  defaultMark={true}
                  defaultMarkValues={{
                    top: 20,
                    left: '23.6',
                    value: SLIDERS_DEFAULTS.tVoc,
                  }}
                />
              </div>
              {Object.values(peripherals).length > 0 && (
                <div
                  className="widget--row-expand"
                  onClick={() => expandDetails('tVoc')}
                  style={{}}
                >
                  <div className="sub-dp-title">
                    {showView.tVoc ? <></> : 'Show Setup'}
                  </div>
                  <div>{showView.tVoc ? <ExpandLess /> : <ExpandMore />}</div>
                </div>
              )}
              {showView.tVoc && (
                <div className="widget--row-content p--form">
                  {Object.values(peripherals).length > 0 ? (
                    <SwitchGroupV2
                      id={`switch-group-tVoc`}
                      device={device}
                      data={peripherals}
                      ckData={widgetCkStates?.tVoc}
                      onChange={onChangeWgtCk.bind(null, 'tVoc')}
                    />
                  ) : (
                    <NoDevicesAlert />
                  )}
                </div>
              )}
            </div>
            {showSaveBtn && <WidgetActions onSave={handleSaveIndoorData} />}
            {showSaveBtn && <FloatButton onSave={handleSaveIndoorData} />}
          </div>
        </AccordionDetails>
      </Accordion>
      <SweetAlert
        info
        showCancel
        confirmBtnText={ALERTS_STRINGS.save?.btn?.confirm}
        title={ALERTS_STRINGS?.save?.question}
        confirmBtnBsStyle="info"
        show={showAlert}
        onConfirm={onSaveIndoorData}
        onCancel={onCancelAlert}
        focusCancelBtn
      />
      <SweetAlert
        info
        showCancel
        confirmBtnText={ALERTS_STRINGS.warning?.btn?.setup}
        title={ALERTS_STRINGS?.warning?.noSensor}
        confirmBtnBsStyle="info"
        show={showNoSensorAlert}
        onConfirm={onClickSetupSensor}
        onCancel={onCancelSetupSensor}
        focusCancelBtn
      />
    </>
  );
};

function mapStateToProps(state: any) {
  const { devices } = state;
  return {
    devices: devices.devices,
  };
}

function mapDispatchToProps(dispatch: any) {
  const {
    foobot,
    airthings,
    awair,
    devices,
    systemSettings,
    loader,
    airCyclerLocalSensor,
  } = dispatch || {};

  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    requestData: foobot.getLastDataPoint,
    requestAirthingsData: airthings.getLastestValues,
    requestAwairData: awair.getLastestValues,
    updateLocalSensorState: awair.updateState,
    updateDevice: devices.editDevice,
    getSysSettings: systemSettings.getSystemSettingsBySysId,
    getSysSetup: systemSettings.getSystemSetupBySysId,
    updateSysSetup: systemSettings.editSystemSetup,
    setCurrentDevice: devices.setCurrentDevice,
    requestLocalAirCycler: airCyclerLocalSensor.getAirCyclerLocalSensorsDataPoint,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IndoorAir);
