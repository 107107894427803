import React, { useState, useEffect, useRef } from 'react';
import config from './config';
import { connect } from 'react-redux';
import { SensorInformationProps } from './interface';
import { helpers } from './helpers';
import {
  Select,
  Button,
  Spinner,
  ApiComponent,
} from 'business/modules/admin/common';
import { HiOutlineXCircle } from 'react-icons/hi';
import { TbListDetails } from 'react-icons/tb';
import { GrLinkTop } from 'react-icons/gr';
import { HiCheck } from 'react-icons/hi';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import './styles.scss';

const SensorInformation: React.FC<SensorInformationProps> = ({
  sensorsList,
  showSensorDetailsById,
  sensorDetails,
  modalKey,
  setModalKey,
  saveSensorDetailsToShow,
  sensorsAreLoaded,
}) => {
  const searchDevsModalRef = useRef<any>(null);
  const [device_id, setDeviceId] = useState('');
  const [id, setAccountId] = useState('');
  const [devInfoIsLoaded, setDevInfoIsLoaded] = useState(false);
  const [requestWasMade, setRequestWasMade] = useState(false);
  const [resetMode, setResetMode] = useState(true);
  const modalTop = useRef<any>(null);
  const [apiIsHidden, setApiIsHidden] = useState<boolean>(true);

  const resetKeys: any = {
    button: 'otherkey',
    close: 'otherkey2',
    default: 'other-dev-key3',
  };

  const pushToTheTop = () =>
    modalTop &&
    modalTop.current &&
    modalTop.current.scrollIntoView({ behavior: 'smooth' });

  const toggleApiVisibility = () => {
    apiIsHidden ? setApiIsHidden(false) : setApiIsHidden(true);
  };

  const resetKey = (comesFrom: string) => {
    setDeviceId('');
    switch (comesFrom) {
      case 'button':
      case 'close':
        setModalKey(resetKeys[comesFrom]);
        setResetMode(true);
        break;

      default:
        setModalKey('other-dev-key3');
        setResetMode(true);
        break;
    }

    saveSensorDetailsToShow({});
  };
  const selectDevId = (event: any) => {
    if (event.target.value === `Select a sensor`) {
      return;
    }
    setDeviceId(event.target.value);
  };

  const searchDevById = async () => {
    setResetMode(false);
    setRequestWasMade(true);

    if (id && device_id) {
      let response = await showSensorDetailsById({ id, device_id });

      if (response && response?.statusCode === 200) {
        setDevInfoIsLoaded(true);
        setRequestWasMade(false);
      }
    } else {
      return;
    }
  };

  //filter locals except OMNI
  const removeLocalSensors = (sensorsList: any) => {
    let filteredSensors: any =
      sensorsAreLoaded &&
      sensorsList.filter((sensor: any) => {
        return (
          sensor?.device_type !== 'AirCyclerAQ123456' &&
          (sensor.device_type === 'awair-omni' ||
            sensor?.details?.name !== 'LOCAL' ||
            !sensor?.details?.status.includes('local'))
        );
      });
    return filteredSensors;
  };

  useEffect(() => {
    let account: any = localStorage.getItem('currentAccount');

    if (account) {
      setAccountId(account);
    }
  }, []);

  useEffect(() => {
    if (device_id) {
      searchDevById();
    }
  }, [device_id]);

  useEffect(() => {
    const handleClick = (event: any) => {
      if (searchDevsModalRef.current?.contains(event.target)) {
        if (event.target === searchDevsModalRef.current) {
          setDeviceId('');
          resetKey('backdrop');
        }
      }
    };
    window.addEventListener('click', handleClick);
    return () => window.removeEventListener('click', handleClick);
  }, [searchDevsModalRef]);

  return (
    <div
      ref={searchDevsModalRef}
      key={modalKey}
      className="modal fade"
      id="sens-show"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id={`sens-show1`}>
              {config.strings.sensorDetails}
            </h5>
            <Button
              type="button"
              className="close"
              dataDismiss="modal"
              onClick={() => resetKey('button')}
              id="close-modal5"
            >
              <span aria-hidden="true">&times;</span>
            </Button>
          </div>

          <div className="modal-body sens-select1">
            {!sensorsAreLoaded ? (
              <Spinner />
            ) : (
              <Select
                optionValue={config.strings.selectSensor}
                inputClass={`input-group mb-3`}
                selectClass={`custom-select`}
                selectId={`sensors_id`}
                onChange={selectDevId}
                title={config.strings.selectSensor}
                array={removeLocalSensors(sensorsList)}
                resetMode={resetMode}
              />
            )}

            {!devInfoIsLoaded && requestWasMade ? (
              <Spinner />
            ) : resetMode ? (
              <div className="card reset-mode-1">
                <div className="card-body pdg-tb">
                  <h5 className="card-title centered">{`Sensor details`}</h5>
                  <h6
                    className="card-subtitle mb-2 text-muted centered"
                    style={{ paddingBottom: '6px' }}
                  >
                    <TbListDetails className="tb--icon" />
                  </h6>
                  <p className="card-text lead smlead centered">{`Sensor details will show here.`}</p>
                </div>
              </div>
            ) : (
              sensorDetails &&
              Object.keys(sensorDetails).length > 0 && (
                <div className="card" ref={modalTop}>
                  <div className="card-body">
                    <h5 className="card-title">{`Sensor details`}</h5>
                    <h6 className="card-subtitle mb-2 text-muted">
                      {sensorDetails?.device_name}
                    </h6>

                    <div className="card-text lead dev-details--info">
                      {Object.entries(sensorDetails).map(([key, value], i) => {
                        if (key === `api_key`) {
                          return (
                            <ApiComponent
                              className={`container api-box`}
                              className2={`col-auto api-box`}
                              className3={`col api-box`}
                              id="api-sensor-modal"
                              key={i}
                              onClick={toggleApiVisibility}
                              apiInfo={
                                !apiIsHidden ? (
                                  <AiOutlineEye className="api-icon" />
                                ) : (
                                  <AiOutlineEyeInvisible className="api-icon" />
                                )
                              }
                              txt={
                                !apiIsHidden &&
                                !apiIsHidden && (
                                  <div className="col-auto pdg mgtp-8">
                                    <span className="data--devs pt--2">
                                      {helpers.renderValue(value)}
                                    </span>
                                  </div>
                                )
                              }
                            />
                          );
                        }
                      })}

                      {Object.entries(
                        helpers.filterRelevantProps(sensorDetails),
                      ).map(([key, value]: [any, any], i: number) => {
                        return key === 'details' ? (
                          <div className="container pdg" key={`${key}--${i}`}>
                            <div className="row">
                              <div className="col-3 pdg">
                                <span className="data--devs-12 lead stronger">
                                  {helpers.returnLabel(key)}
                                </span>
                              </div>
                              <div className="row"></div>
                              <div className="col-12 pdg">
                                {helpers.renderVal(value)}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="container pdg" key={`${key}--${i}`}>
                            <div className="row">
                              <div className="col-3 pdg">
                                <span className="data--devs-12 lead stronger">
                                  {key === 'isSystem'
                                    ? null
                                    : helpers.returnLabel(key)}
                                </span>
                              </div>
                              <div className="col-9 pdg to-the-right">
                                {key === 'isSystem' ? (
                                  <>
                                    {' '}
                                    <HiCheck className="check--icon--sensors" />{' '}
                                    {config.strings.system}
                                  </>
                                ) : (
                                  value
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <div className="row center-content">
                      <button className="" onClick={pushToTheTop}>
                  
                        <span className="lead btn-label">
                        <GrLinkTop className="back-to-top" />{config.strings.backToTop}
                        </span>
                      </button>{' '}
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
          <div className="modal-footer" id="check-sensors-modal-footer">
            <Button
              onClick={() => {
                resetKey('close');
              }}
              className="small-button dismiss-btn"
              dataDismiss={`modal`}
            >
              <HiOutlineXCircle className="cancel-v" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  const { adminDevicesState, adminState } = state;
  return {
    sensorDetails: adminDevicesState.sensorDetails,
    modalKey: adminState.modalKey,
    sensorsList: adminDevicesState.sensorsList,
    sensorsAreLoaded: adminDevicesState.sensorsAreLoaded,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { adminDevicesState, adminState } = dispatch;
  return {
    showSensorDetailsById: adminDevicesState.showSensorDetailsById,
    setModalKey: adminState.setModalKey,
    saveSensorDetailsToShow: adminDevicesState.saveSensorDetailsToShow,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SensorInformation);
