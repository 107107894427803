import React from 'react';
import config from './config';
import { CompanyDetailsProps } from './interface';
import { functions } from './helpers';
import { JumboCard, UpSection } from './components';
import './styles.scss';

const CompanyDetails: React.FC<CompanyDetailsProps> = ({ item }) => {
  return (
    <div className="jumbotron" id="jumbo-container--1">
      <UpSection config={config} item={item} />

      <JumboCard
        config={config}
        returnLabel={functions.returnLabel}
        formatDate={functions.formatDate}
        item={item}
        filteredObj={functions.filterRelevantProps(item)}
        functions={functions}
      />
    </div>
  );
};
export default CompanyDetails;
