// @ts-nocheck
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { InstallerIndoorAirProps } from './interface';
import Container from './components/Container';
import { RiSave3Fill } from 'react-icons/ri';
import { SingleSlider } from '../common';
import { SwitchButton, SwitchGroupV2 } from './components';
import { ConfigsUtils } from '../../utils';
import config from './config';
import './styles.scss';

const COMPONENT_STRINGS: any = { ...config?.strings };
const MODEL_FORMAT: any = { ...config.model };
const DEFAULT_EXTREMS: any = { ...config.sliderValues };
const MAIN_PROPS: any = ['pm25', 'co2', 'tVoc'];
const SLIDERS_DEFAULTS: any = { ...config.sliders_defaults };

const InstallerIndoorAir: React.FC<InstallerIndoorAirProps> = ({
  configShowData,
  configShowDataLoaded,
  handleConfigValidation,
  prepareToSaveIASetupStore,
  showBtnCtrl,
  showBtn,
  ...props
}) => {
  const [
    insideAirSectionIsEnabled,
    setInsideAirSectionIsEnabled,
  ]: boolean = useState(false);
  const [showSaveBtn, setShowSaveBtn]: any = useState(false);
  const [showAlert, setShowAlert]: boolean = useState(false);
  const [widgetStates, setWidgetStates]: any = useState({
    pm25: { ...MODEL_FORMAT },
    co2: { ...MODEL_FORMAT },
    tVoc: { ...MODEL_FORMAT },
  });
  const [widgetCkStates, setWidgetCkStates]: any = useState({
    co2: { dmst: false, cfcm: false },
    pm25: { dmst: false, cfcm: false },
    tVoc: { dmst: false, cfcm: false },
  });
  const [peripherals]: any = useState(config.peripherals);



  const transformSingleSlider = (values: any, id: string) => {
    const { max, min, current } = values;
    return [current, max === -101 ? SLIDERS_DEFAULTS[id] : max];
  };

  const onChangePm25 = (newValues: any) => {
    const tempValues: any = ConfigsUtils.arrayPackageByProp(newValues, 'pm25');

    if (tempValues) {
      const { prop, data }: any = tempValues;

      setWidgetStates((prevStates: any) => {
        return {
          ...prevStates,
          [prop]: {
            ...prevStates[prop],
            [data[0].pointer]: data[0].value,
            [data[1].pointer]: data[1].value,
          },
        };
      });
    }

    setShowSaveBtn(true);
    showBtnCtrl(true);
  };

  const onChangeCO2 = (newValues: any) => {
    const tempValues: any = ConfigsUtils.arrayPackageByProp(newValues, 'co2');

    if (tempValues) {
      const { prop, data }: any = tempValues;

      setWidgetStates((prevStates: any) => {
        return {
          ...prevStates,
          [prop]: {
            ...prevStates[prop],
            [data[0].pointer]: data[0].value,
            [data[1].pointer]: data[1].value,
          },
        };
      });
    }
    setShowSaveBtn(true);
    showBtnCtrl(true);
  };

  const onChangeTvoc = (newValues: any) => {
    const tempValues: any = ConfigsUtils.arrayPackageByProp(newValues, 'tVoc');

    if (tempValues) {
      const { prop, data }: any = tempValues;

      setWidgetStates((prevStates: any) => {
        return {
          ...prevStates,
          [prop]: {
            ...prevStates[prop],
            [data[0].pointer]: data[0].value,
            [data[1].pointer]: data[1].value,
          },
        };
      });
    }
    setShowSaveBtn(true);
    showBtnCtrl(true);
  };

  const normalizeMaxValues = (
    prop: string,
    maxValue: any,
    currentValues: any,
  ) => {
    let normalizedValue: any = maxValue;
    const { current, init }: any = currentValues || {};
    let gainA: number = 100;

    switch (prop) {
      case 'co2':
      case 'tVoc':
        gainA = 200;
        break;
      case 'pm25':
      default:
        break;
    }

    if (init && current && current > maxValue) {
      const factor: number = parseInt(current).toString().length;
      let gain: number = Math.pow(10, factor - 1);
      normalizedValue = current + 100;
      if (gain > 0) normalizedValue = parseInt(normalizedValue / gain) * gain;
    }

    return normalizedValue;
  };

  const prepareValuesToSave = (values: any, ckValues: any) => {
    let tempSettings: any = {
      slider: {},
      checkbox: {},
      turnedOn: insideAirSectionIsEnabled,
    };

    MAIN_PROPS.forEach((prop: string) => {
      tempSettings.slider[prop] = values[prop].max;
      tempSettings.checkbox[prop] = { ...ckValues[prop] };
    });

    return {
      setup: { indoorAir: tempSettings },
    };
  };

  const onSaveIndoorData = (event: any) => {
    setShowAlert(false);
    const setupValues: any = prepareValuesToSave(
      widgetStates, widgetCkStates
    );
    return setupValues ? {...setupValues} : {};
  };

  const handleSaveIndoorData = async (event: any) => {
    let errValidation = await handleConfigValidation();

    if (errValidation) {
      setShowAlert(false);
    }
  };

  const toggleSection = (status: any) => {
    setInsideAirSectionIsEnabled(!status);
    setShowSaveBtn(true);
    showBtnCtrl(true);
  };

  const handleIndoorAirValues = (storedValues: any) => {
    const { pm25, co2, tVoc }: any = SLIDERS_DEFAULTS;
    const { slider }: any = storedValues || {};

    return {
      pm25: { max: slider?.pm25 ? slider.pm25 : pm25 },
      co2: { max: slider?.co2 ? slider.co2 : co2 },
      tVoc: { max: slider?.tVoc ? slider.tVoc : tVoc },
    };
  };

  const onChangeWgtCk = (section: string, event: any, value: any) => {
    const { id } = event?.target;
    const props: any = id.split('-');

    if (props && props[0] && props[1]) {
      if (
        props[1] === 'damper' ||
        props[1] === 'fanConnect' ||
        props[1] === 'centralFan'
      ) {
        setWidgetCkStates((prevVals: any) => {
          return {
            ...prevVals,
            [section]: {
              ...prevVals[section],
              [props[0]]: value,
            },
          };
        });
      } else {
        setWidgetCkStates((prevVals: any) => {
          return {
            ...prevVals,
            [section]: {
              ...prevVals[section],
              [props[1]]: {
                ...prevVals[section][props[1]],
                [props[0]]: value,
              },
            },
          };
        });
      }
    }

    setShowSaveBtn(true);
    showBtnCtrl(true);
  };



  useEffect(() => {
    const setupResult: any = onSaveIndoorData();

    prepareToSaveIASetupStore(setupResult);
  }, [widgetStates, widgetCkStates, insideAirSectionIsEnabled]);

  useEffect(() => {
    if(!showBtn){
      setShowSaveBtn(false);
    }
  }, [showBtn]);

  useEffect(() => {
    if (configShowDataLoaded && configShowData.length > 0) {
      const indoorAir: any = configShowData[0]?.setup?.indoorAir;
      const tempWidgetsValues: any = handleIndoorAirValues(
        indoorAir,
      );
      setInsideAirSectionIsEnabled(
        indoorAir?.turnedOn,
      );

      setWidgetStates(tempWidgetsValues);

      if (indoorAir) {
        const { checkbox } = indoorAir;
        if (checkbox !== null && checkbox !== undefined) {
          setWidgetCkStates({ ...checkbox });
        }
      }
    }
  }, [configShowData, configShowDataLoaded]);



  return (
    <div className="" id="installer-slider-section-edit">
      <Container cClass="switch-box" colClass={`col`}>
        <SwitchButton
          stringInfo={COMPONENT_STRINGS.title}
          id="indoor-section"
          onChange={() => {
            toggleSection(insideAirSectionIsEnabled);
          }}
          value={insideAirSectionIsEnabled}
        />
      </Container>

      <div className="installer-edit-indoor-wrapper">
        <div className="row">
          <div className="installer-slider-wrapper">
            <SingleSlider
              id="w-pm25-inst"
              values={transformSingleSlider(widgetStates?.pm25, 'pm25')}
              max={normalizeMaxValues(
                'pm25',
                DEFAULT_EXTREMS.pm25.max,
                widgetStates.pm25,
              )}
              min={DEFAULT_EXTREMS.pm25.min}
              step={1}
              col={2}
              showLabel={true}
              symbol=""
              onChange={onChangePm25}
              defaultMark={true}
              defaultMarkValues={{
                top: 40,
                left: 29.7,
                value: SLIDERS_DEFAULTS.pm25,
              }}
            />

            <SwitchGroupV2
              data={peripherals}
              onChange={onChangeWgtCk.bind(null, 'pm25')}
              ckData={widgetCkStates?.pm25}
            />
          </div>
          <div className="installer-slider-wrapper">
            <SingleSlider
              id="w-co2-inst"
              values={transformSingleSlider(widgetStates?.co2, 'co2')}
              max={normalizeMaxValues(
                'co2',
                DEFAULT_EXTREMS.co2.max,
                widgetStates.co2,
              )}
              min={DEFAULT_EXTREMS.co2.min}
              step={10}
              col={2}
              showLabel={true}
              symbol=""
              onChange={onChangeCO2}
              defaultMark={true}
              defaultMarkValues={{
                top: 40,
                left: 18.4,
                value: SLIDERS_DEFAULTS.co2,
              }}
            />

            <SwitchGroupV2
              data={peripherals}
              ckData={widgetCkStates?.co2}
              onChange={onChangeWgtCk.bind(null, 'co2')}
            />
          </div>
          <div className="installer-slider-wrapper">
            <SingleSlider
              id="w-tvoc-inst"
              values={transformSingleSlider(widgetStates?.tVoc, 'tVoc')}
              max={normalizeMaxValues(
                'tVoc',
                DEFAULT_EXTREMS.tVoc.max,
                widgetStates.tVoc,
              )}
              min={DEFAULT_EXTREMS.tVoc.min}
              step={10}
              col={2}
              showLabel={true}
              symbol=""
              onChange={onChangeTvoc}
              defaultMark={true}
              defaultMarkValues={{
                top: 40,
                left: 24.6,
                value: SLIDERS_DEFAULTS.tVoc,
              }}
            />

            <SwitchGroupV2
              data={peripherals}
              ckData={widgetCkStates?.tVoc}
              onChange={onChangeWgtCk.bind(null, 'tVoc')}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-1 offset-md-11 col-save-install-indoor-values">
          {showSaveBtn && (
            <button
              className="btn save-configs-btn-purple"
              onClick={handleSaveIndoorData}
            >
              <RiSave3Fill />{' '}
              <span className="installer-save-span-edit">SAVE SETUP</span>
            </button>
          )}
        </div>
      </div>

      <div className="lead"></div>
    </div>
  );
};

function mapStateToProps(state: any) {
  const { installerConfigs } = state;
  return {
    configShowData: installerConfigs.configShowData,
    configShowDataLoaded: installerConfigs.configShowDataLoaded,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { adminLoaderState, installerConfigs } = dispatch;
  return {
    loading: {
      startLoader: adminLoaderState.startLoader,
      stopLoader: adminLoaderState.stopLoader,
    },
    prepareToSaveIASetupStore: installerConfigs.prepareToSaveIASetupStore,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallerIndoorAir);
