export default {
  strings: {
    title: 'Systems'
  },
  alerts: {
    delete: {
        msm: 'You wont be able to revert the operation!'
    }
  },
  notifications: {
    delete: {
        msm: 'System deleted successfully!'
    }
  },
  fieldsState: {},
  fieldsFocus: {},
  fields: {},
  constraints: {}
};
