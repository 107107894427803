import React from 'react';
import { EditConfigPageProps } from './interface';
import './styles.scss';

const EditConfigPage: React.FC<EditConfigPageProps> = ({
  editConfigData,
  config,
  children,
}) => {
  return (
    <>
      <div className="row">
        <h2 className="header-edit-conf1">
          {editConfigData?.config_name || config?.strings?.configStrings}
        </h2>
      </div>
      <div className="m-container installer-edit-body-wrapper">
        <div className="row">{children}</div>
      </div>
    </>
  );
};

export default EditConfigPage;
