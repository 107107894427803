export default {
  inputTypes: {
    systemName: 'text',
    systemLocationState: 'text',
    systemLocationCity: 'text',
    systemLocationZipCode: 'number',
    flowMeasuredHeatCall: 'number',
    flowMeasuredCoolingCall: 'number',
    flowMeasuredFanOnlyCall: 'number',
    flowContinuousAirFlow: 'number',
    exhaustFanFlowRate: 'number',
    additionalMixTime: 'number',
    hoursOfOperation: 'number',
    hourStart: 'number',
    hourStop: 'number',
    currentTime: 'number',
    exhaustFanDelayTime: 'number',
    minutesPerHourExhaustRun: 'number',
    minutesPerHourSupplyFlow: 'number',
    requiredContinuousAirFlow: 'number',
  },
};
