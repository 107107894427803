import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from '@material-ui/core';
import moment from 'moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ActionsMenu, DetailsChip, HeaderMenu } from '../../';
import { Convertions } from 'core/utils';
import { Notification } from 'react-components';
import {
  FanIcon,
  ConnectedIcon,
  DisconnectedIcon,
} from 'business/assets/device-icons';
import config from './config';
import './styles.scss';


const WIFI_CONN_DIFF = 30; // in seconds


interface AircyclerProps {
  device: any;
  setPeripheral: any;
  getDeviceLogs: any;
}

const Aircycler: React.FC<AircyclerProps> = ({ device, ...props }) => {
  const history = useHistory();
  let intervals: any = [];
  const [deviceSettings, setDeviceSettings]: any = useState(null);
  const [connDevices, setConnDevices]: any = useState([]);
  const [menuOptions, setMenuOptions]: any = useState(
    config?.menu?.options || [],
  );
  const [expanded, setExpanded]: any = useState(false);
  const [upDevice, setUpDevice]: any = useState({...device});


  const onClickConnDevice = async (rowDevice: any, event: any) => {
    props.setPeripheral({ ...rowDevice });
    history.push(
      `/dashboard/devices/details/${device.device_id}/peripheral/setup`,
      {
        device_id: device?.device_id,
        peripheral_id: rowDevice?.dmac,
      },
    );
  };

  const onClickFanConnect = (fanConnect: any) => {
    Notification({
      title: 'FanConnect',
      message: 'Not yet supported.',
      type: 'info',
    });
  };

  const handleExpandPanel = (panel: any) => (event: any, isExpanded: any) => {
    setExpanded(isExpanded ? panel : false);
  };

  const hasValidDate = (item: any) => {
    const { createdAt, createdDate } = item;
    const nowDate: any = moment();
    const devDate: any = moment(new Date(createdDate));
    return nowDate.diff(devDate, 'seconds') < WIFI_CONN_DIFF;
  };

  const isDeviceConnected = async (_device: any) => {
    const { api_key, device_name } = _device;
    const resp: any = await props.getDeviceLogs({ deviceKey: api_key });
    return resp && resp?.Items?.length > 0
      ? hasValidDate(resp.Items[0])
      : false;
  };

  const updateDeviceState = async (_device: any) => {
    let isConn: boolean = await isDeviceConnected(_device);
    _device.isWifiConn = isConn ? 'active' : 'inactive';
    setUpDevice({..._device});
    return _device;
  };

  const startStateListener = async (_device: any) => {
    let updatedDevice: any = { ..._device };
    let tempInterval: any = setInterval(async () => {
      await updateDeviceState({...updatedDevice});
    }, 15 * 1000);  // 30secs
    intervals.push(tempInterval);
  };

  const resetIntervals = () => {
    if (intervals && intervals.length > 0) {
      intervals.forEach((ival: any) => {
        clearInterval(ival);
      });
    }
  };

  const prepareConnectVents = (_system: any) => {
    const { settings } = _system?.details || {};
    let hasFanConnect: boolean
        = settings?.conf?.fcsp === 1
        || settings?.conf?.fcsp === '1';
    let ventConnects: any
        = settings?.conf?.cdvs?.length > 0
        ? settings.conf.cdvs.map((ventConn: any, index: number) => {
          return {
            ...ventConn,
            type: 'vent',
            index: index + 1,
          };
        }) : [];

    if(hasFanConnect){
      ventConnects.unshift({
        type: 'fan',
        isWifiConn: 'active',
        cdid: 'FanConnect',
        cail: '',
      });
    }

    return ventConnects;
  };

  useEffect(() => {
    if (device?.details?.settings) {
      setDeviceSettings({ ...device.details.settings });
      if (device.details.settings?.conf?.cdvs) {
        setConnDevices(prepareConnectVents(device));
      }
    }
    startStateListener(device);
    updateDeviceState({...device});
    return () => {
      resetIntervals();
    };
  }, [device]);

  useEffect(() => {
    return () => {
      setDeviceSettings(null);
    };
  }, []);

  return (
    <div id="device-details-aircycler">
      <Grid container>
        <Grid item xs={12} sm={12} className="device-details--name">
          <div className="device-details--header">
            <h4>{device?.device_name}</h4>
            <HeaderMenu data={device} options={menuOptions} />
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        className="device-container-grid"
        style={{ marginTop: 0 }}
      >
        <Grid item xs={12} sm={12} className="device-details--section-title">
          <div>
            <h5>{config?.strings?.titles?.peripherals}</h5>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} className="device-details--values">
          <Grid sm={12} item className="device-details--values-row">
            <DetailsChip
              id="fscp"
              type="fan"
              label={config?.strings?.labels?.fanCon}
              value={deviceSettings?.conf?.fcsp}
            />
          </Grid>
          <Grid sm={12} item className="device-details--values-row">
            <DetailsChip
              id="dmst"
              type="status"
              label={config?.strings?.labels?.damper}
              value={deviceSettings?.conf?.dmst}
            />
          </Grid>
          <Grid sm={12} item className="device-details--values-row">
            <DetailsChip
              id="crhr"
              type="humidity"
              label={config?.strings?.labels?.relativeHumid}
              value={deviceSettings?.conf?.crhr}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} className="device-details--values">
          <Grid sm={12} item className="device-details--values-row">
            <DetailsChip
              id="ctmp"
              type="temp"
              label={config?.strings?.labels?.currentTemp}
              value={deviceSettings?.conf?.ctmp}
            />
          </Grid>
          <Grid sm={12} item className="device-details--values-row">
            <DetailsChip
              id="cflw"
              type="flow"
              label={config?.strings?.labels?.currentFlow}
              value={deviceSettings?.conf?.cflw}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container className="device-container-grid">
        <Grid item xs={12} sm={12} className="device-details--section-title">
          <div>
            <h5>{config?.strings?.titles?.connDevices}</h5>
          </div>
        </Grid>
        <Grid item md={12} className="device-details--values">
          {connDevices.length > 0 ? (
            <List id="conn-devices-list">
              {connDevices.map((dev: any, _index: number) => {
                const { type, index } = dev || {};

                if(type && type === 'fan'){
                  return (
                      <ListItem
                        key={`fan-conn${_index}`}
                        alignItems="flex-start"
                        onClick={onClickFanConnect}
                      >
                        <ListItemAvatar className="conn-devices-avatar">
                          <FanIcon />
                        </ListItemAvatar>
                        <ListItemText>
                          <div className="vent-connect-wrapper">
                            <div className="vent-connect-l-display">
                              {'FanConnect'}
                            </div>
                            <div className="vent-connect-r-display">
                              ( <span className="vent-connect-id">
                                {'Wired'}
                              </span> )
                            </div>
                          </div>
                        </ListItemText>
                        <ListItemAvatar className="conn-devices-avatar">
                          <Tooltip title={upDevice.isWifiConn === 'active' ? 'online' : 'offline'} placement="top">
                            {upDevice ? <ConnectedIcon /> : <DisconnectedIcon />}
                          </Tooltip>
                        </ListItemAvatar>
                        <ListItemSecondaryAction>
                          <ActionsMenu data={dev} parent={device} />
                        </ListItemSecondaryAction>
                      </ListItem>
                  );
                }

                return (
                  <ListItem
                    key={dev?.dmac}
                    alignItems="flex-start"
                    onClick={onClickConnDevice.bind(null, dev)}
                  >
                    <ListItemAvatar className="conn-devices-avatar">
                      <FanIcon />
                    </ListItemAvatar>
                    <ListItemText>
                      <div className="vent-connect-wrapper">
                        <div className="vent-connect-l-display">
                          {dev?.cail ? dev.cail : dev?.cdid}
                        </div>
                        <div className="vent-connect-r-display">
                          ( <span className="vent-connect-id">
                            {'VentConnect ' + index}
                          </span> )
                        </div>
                      </div>
                    </ListItemText>
                    <ListItemAvatar className="conn-devices-avatar">
                      <Tooltip title={upDevice.isWifiConn === 'active' ? 'online' : 'offline'} placement="top">
                        {upDevice ? <ConnectedIcon /> : <DisconnectedIcon />}
                      </Tooltip>
                    </ListItemAvatar>
                    <ListItemSecondaryAction>
                      <ActionsMenu data={dev} parent={device} />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <div className="no-conn-devices">
              <h6>{config?.strings?.warns?.noDevices}</h6>
            </div>
          )}
        </Grid>
      </Grid>
      <Grid container className="device-container-grid">
        <Accordion
          expanded={expanded === 'about-device'}
          onChange={handleExpandPanel('about-device')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="about-device-content"
            id="about-device-header"
          >
            <Typography
              style={{
                width: '40%',
                flexShrink: 0,
                fontSize: '18px',
                fontWeight: 500,
              }}
            >
              About
            </Typography>
            <Typography style={{ color: '#70707099' }}>
              Click to {expanded === 'about-device' ? 'hide' : 'show'} details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              className="device-details--values"
            >
              <Grid sm={12} item className="device-details--values-row">
                <label>{config?.strings?.labels?.firmware} </label>
                <div id="fmwv">{deviceSettings?.fmwv}</div>
              </Grid>
              <Grid sm={12} item className="device-details--values-row">
                <label>{config?.strings?.labels?.macAddrr} </label>
                <div id="dmac">{deviceSettings?.dmac || device?.details?.id}</div>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              className="device-details--values"
            >
              <Grid sm={12} item className="device-details--values-row">
                <label>{config?.strings?.labels?.upTime} </label>
                <div id="uptm">
                  {Convertions.secToHours(deviceSettings?.uptm)}
                </div>
              </Grid>
              <Grid sm={12} item className="device-details--values-row">
                <label>{config?.strings?.labels?.conDevices} </label>
                <div id="cdvc">{deviceSettings?.conf?.cdvc}</div>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {};
}

function mapDispatchToProps(dispatch: any) {
  const { devices, logs } = dispatch;
  return {
    setPeripheral: devices.setPeripheral,
    getDeviceLogs: logs.getDeviceLogs,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Aircycler);
