const sortAlphabetically = (array: any) => {
  let ordered = array.sort((a: any, b: any) => {
    let aString = a.first_name.toUpperCase();
    let bString = b.first_name.toUpperCase();

    return aString < bString ? -1 : aString > bString ? 1 : 0;
  });
};

const dataIsLoaded = (users: any, loaded: boolean, devs: any) => {
  if (users && loaded && devs && users.length === devs.length) {
    return true;
  } else return false;
};

export const helpers = {
  sortAlphabetically: sortAlphabetically,
  dataIsLoaded: dataIsLoaded,
};
