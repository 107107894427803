import React, { useState } from 'react';
import { ListRowProps } from './interface';
import { RowContent } from './components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import './styles.scss';

const ListRow: React.FC<ListRowProps> = ({
  item,
  handleView,
  functions,
  config,
  secondFun,
  fourthFunction,
  setItem,
  retrieveColValue,
  returnAccordionDetails,
  firstFun,
  thirdFun,
  id,
  rowClasses,
  noMenu,
  circle,
  onlyCollapse,
  ...handles
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [expandedCtrl, setIsExpandedCtrl]: any = useState(false);

  const handleClick = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setItem(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = () => {
    setIsExpandedCtrl(!expandedCtrl);
  };

  return (
    <div className={`${id}--around-accordion`}>
      <Accordion
        id="widget--comp-list1"
        square
        expanded={expandedCtrl}
        onChange={handleChange}
      >
        <AccordionSummary
          aria-controls="widget--comp-list1"
          id="widget--comp--list1"
        >
          <RowContent
            id={`${id}-rcont`}
            item={item}
            handleChange={handleChange}
            expandedCtrl={expandedCtrl}
            handleClick={handleClick}
            open={open}
            handleView={handleView}
            handleClose={handleClose}
            anchorEl={anchorEl}
            firstCol={retrieveColValue(item, '1')}
            secondCol={retrieveColValue(item, '2')}
            thirdCol={retrieveColValue(item, '3')}
            fourthCol={retrieveColValue(item, '4')}
            functions={functions}
            config={config}
            firstFun={firstFun}
            secondFun={secondFun}
            thirdFun={thirdFun}
            fourthFunction={fourthFunction}
            rowClasses={rowClasses}
            noMenu={noMenu}
            circle={circle}
            onlyCollapse={onlyCollapse}
          />
        </AccordionSummary>

        <AccordionDetails>
          <div className="widget--body-container">
            <div className="widget--row">{returnAccordionDetails(item)}</div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ListRow;
