import React from 'react';
import { HeaderProps } from './interface';

const Header: React.FC<HeaderProps> = ({ title, id, onClick }) => {
  return (
    <div className="modal-header">
      <h5 className="modal-title" id="exampleModalLongTitle2">
        {title}
      </h5>
      <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
        id={id}
        onClick={onClick}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};
export default Header;
