import React from 'react';
import { CommonSectionProps } from './interface';
import { formatInTimeZone } from 'date-fns-tz';
import { Input } from 'react-components';
import { OperationTime, Timezone } from './components';
import './styles.scss';

const CommonSection: React.FC<CommonSectionProps> = ({
  config,
  errors,
  handleSetTimezone,
  fieldsState,
  onChangeState,
}) => {
  const TIMEZONE: any = [...config.timezones];

  return (
    <div
      id="time--section--user-add"
      className="no-side--time-section"
    >
      <OperationTime
        hOpClass={`hours-operation  spec-op-1 ct--${
          !fieldsState?.hroa ? 'close' : 'open'
        }`}
        fieldsState={fieldsState}
        onChangeState={onChangeState}
        errors={errors}
        config={config}
      />

      <Timezone
        value={TIMEZONE[fieldsState.tmzn]?.label}
        onChange={handleSetTimezone}
      >
        {TIMEZONE.map((tm: any) => (
          <option key={tm.label} value={tm.label}>
            {tm.label}
          </option>
        ))}
      </Timezone>

      <div className="row">
        <div className="col-12 no-side--time-section">
          <div
            className={`tmzn-add-display--wrapper--${
              fieldsState?.hroa ? 'open' : 'closed'
            }`}
          >
            <Input
              disabled
              handleChangeError={(err: any) => {}}
              handleChange={() => {}}
              type="text"
              label={config.fields.tmzn.label}
              value={`${formatInTimeZone(
                new Date(),
                `${TIMEZONE[fieldsState.tmzn]?.timezone}`,
                'HH:mm',
              )}`}
              error={errors?.tmzn}
              name={'tmzn'}
            />
          </div>
        </div>{' '}
      </div>
    </div>
  );
};

export default CommonSection;
