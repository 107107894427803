export default {
  strings: {
    title: 'Admin user details',
    breadcrumbItem: `/admin/dashboard/admin-list`,
  },
  states: {
    userInitState: {
      account_id: '',
      email: '',
      status: '',
      username: '',
      __v: 0,
      _id: '',
    },
    addressInitState: {
      address_line_one: '',
      address_line_two: '',
      zip_code: '',
      city: '',
      state: '',
    },
    errors: {
      emailError: '',
      usernameError: '',
    },
  },
  regex: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  gridData: [
    {
      title: `Details`,
      subtitle: `Admin since`,
    },
    {
      title: `Status`,
      subtitle: `Account status`,
    },
    {
      title: `Account`,
      subtitle: `Information`,
    },
    {
      title: `Useful`,
      subtitle: `Data`,
    },
  ],
  lead: 'Admin list',
  leadList: 'Other admin users',
  alertStr: `Toggle the list order (alphabetical or default)`,
  formDataAdmin: [
    {
      type: 'text',
      label: 'ID',
      name: '_id',
      disabled: true,
      classname: 'form-control mb-1',
    },
    {
      type: 'email',
      label: 'Email',
      name: 'email',
      disabled: true,
      classname: 'form-control mb-1',
    },
    {
      type: 'text',
      label: 'Username',
      name: 'username',
      disabled: true,
      classname: 'form-control mb-1',
    },
    {
      type: 'text',
      label: 'Account ID',
      name: 'account_id',
      disabled: true,
      classname: 'form-control mb-1',
    },
    {
      type: 'switch',
      label: 'Status',
      name: 'status',
      disabled: true,
      classname: 'form-control mb-1',
    },
  ],
  modalStrings: {
    edit: `Update user data`,
    confirm: ``,
    warning: `Information`,
    alertStr: ``,
    alertStr2: ``,
    confirmation: ``,
    sureToProceed: `Are you sure you wish to proceed?`,
    noUndo: ``,
    aboutToUpdate: `You are about to edit the user information.`,
    labelOK: ` OK`,
  },
};
