import React, { useEffect, useState } from 'react';
import config from './config';
import { connect } from 'react-redux';
import { ManualControlProps } from './interface';
import { helpers } from './helpers';
import _ from 'lodash';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import {
  SwitchBtn,
  TooltipView,
  Modal,
  Notification,
  DetailsHeader,
} from 'business/modules/admin/common';
import { io } from 'socket.io-client';
import { useAuth } from '../../../../../../../sessions/hooks/auth';
import * as globalConfigs from '../../../../../../../../config/config';
import { AiOutlineControl } from 'react-icons/ai';
import { RiSave3Fill } from 'react-icons/ri';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FaExclamation } from 'react-icons/fa';
import './styles.scss';

const ST_LABEL: string = config.strings.stLabel;
let WEB_SOCKETS: any = {};
let INTERVALS: any = [];
let REQUEST_STATES: any = {};

const ManualControl: React.FC<ManualControlProps> = ({
  sectionData,
  element,
  mcConfirmation,
  setMcConfirmation,
  setCurrentUpdatingSection,
  modalMustClose,
  handleModalState,
  editManualControlAdmin,
  getManualCtrlDetailsAdmin,
  currentUpdatingSection,
  loading,
  saveMCUpdating,
  manualCtrlValues,
  designation,
  systemsCurrentStatus,
}) => {
  const { adminToken } = useAuth();
  const [orderedEl, setOrderedEl]: any = useState([]);
  const [expandedCtrl, setIsExpandedCtrl] = useState<boolean>(false);
  const [widgetCkStates, setWidgetCkStates] = useState<any>({});
  const [manualCtrlStatus, setManualCtrlStatus] = useState<boolean>(false);
  const [currentDeviceId, setCurrentDeviceId] = useState<any>();
  const [isInit, setIsInit] = useState<boolean>(false);
  const [peripherals, setPeripherals] = useState<any>({});

  const handleChange = (panel: string) => (event: any, isExpanded: any) => {
    setIsExpandedCtrl(isExpanded);
    localStorage.setItem(`${ST_LABEL}${currentDeviceId}`, isExpanded);
  };

  const performAction = (id: string) => {
    switch (id) {
      case 'update-man-ctrl':
        return saveManCtrlChanges();
      default:
        break;
    }
  };

  const saveManCtrlChanges = async () => {
    let response: any = await editManualControlAdmin(manualCtrlValues);

    if (response?.statusCode === 200) {
      Notification({
        title: 'Success!',
        message: config?.notifications?.update?.success,
        type: 'success',
      });
      let callMc: any = await getManualCtrlDetailsAdmin({
        device_id: element.device_id,
        id: localStorage.getItem('currentAccount'),
      });
      if (callMc?.statusCode === 200) {
        let _theDevSetup = callMc.body.data;
        if (_theDevSetup && Object.keys(_theDevSetup).length > 0) {
          const setupData: any = helpers.handleSetup(
            _theDevSetup,
            localStorage.getItem('currentAccount'),
          );
          setManualCtrlStatus(_theDevSetup?.status);
          mergeStoredData(setupData);
        }
      }
    }
  };

  const toggleOnOff = (event: any, devId: string) => {
    event.stopPropagation();

    setCurrentUpdatingSection(devId);

    if (!expandedCtrl) setIsExpandedCtrl(true);
    setManualCtrlStatus((prevState: any) => {
      return !prevState;
    });

    setMcConfirmation(true);
  };

  const mergeStoredData = (mcData: any) => {
    const { setup }: any = mcData || {};

    if (setup && Object.keys(setup).length > 0) {
      setWidgetCkStates((prevWidgetStates: any) => {
        let prevWidgetStatesCopy: any = { ...prevWidgetStates };

        for (const prop in setup) {
          if (prop === 'cdvs') {
            if (prevWidgetStatesCopy?.setup?.cdvs && setup && setup[prop]) {
              for (const vc of setup[prop]) {
                const { cidx, cdst } = vc;
                const prevIndex: number = prevWidgetStatesCopy.setup.cdvs.findIndex(
                  (item: any) => item.cidx === cidx,
                );
                if (prevIndex > -1) {
                  prevWidgetStatesCopy.setup.cdvs[prevIndex].cdst = cdst;
                }
              }
            }
          } else {
            prevWidgetStatesCopy.setup[prop] = setup[prop];
          }
        }

        return prevWidgetStatesCopy;
      });
    }
  };

  const onChangeCheckBoxes = (event: any, value: any) => {
    setMcConfirmation(true);
    setCurrentUpdatingSection(element?.device_id);

    const devData: any = event.target.id;
    const devDataIds: any = devData.split('-');
    const _type: any = devDataIds[1];

    setWidgetCkStates(
      helpers.fillTheStates(widgetCkStates, devDataIds, _type, value),
    );
  };

  const startSocket = async (
    _device: any,
    deviceId: string,
    data: any,
    channel: string,
  ) => {
    const { device_id }: any = _device || {};
    const _token: string = adminToken?.token;
    const { path, process } = data;
    let channelSTR: string = `${channel}-${deviceId}`;

    const socket: any =
      globalConfigs?.default?.apis?.jitBE?.baseSocketURLs &&
      globalConfigs?.default?.apis?.jitBE?.baseSocketURLs[0]
        ? io(globalConfigs?.default?.apis?.jitBE?.baseSocketURLs[0], {
            path: '/socket.io/',
            transports: ['websocket'],
            autoConnect: false,
            withCredentials: true,
            auth: {
              token: _token,
              path: path,
              process: process,
              channel: channel,
              name: deviceId,
              channelName: channelSTR,
            },
          })
        : null;

    if (socket) {
      WEB_SOCKETS[device_id] = {
        socket: socket.connect(),
        device: _device,
        channel: channelSTR,
        connected: false,
      };

      WEB_SOCKETS[device_id].socket.on('connect', () => {
        WEB_SOCKETS[device_id].connected = true;
      });

      WEB_SOCKETS[device_id].socket.on('disconnect', (reason: any) => {});

      WEB_SOCKETS[device_id].socket.on('connect_error', (error: any) => {});

      WEB_SOCKETS[device_id].socket.on(
        WEB_SOCKETS[device_id].channel,
        (data: any) => {
          //todo: add socket update states
        },
      );
    }
  };

  const destroySocket = (_device: any) => {
    const { device_id }: any = _device || {};

    if (
      WEB_SOCKETS[device_id] !== null &&
      WEB_SOCKETS[device_id] !== undefined
    ) {
      WEB_SOCKETS[device_id].socket.off('connect');
      WEB_SOCKETS[device_id].socket.off('disconnect');
      WEB_SOCKETS[device_id].socket.off('connect_error');

      if (WEB_SOCKETS[device_id].channel) {
        WEB_SOCKETS[device_id].socket.off(WEB_SOCKETS[device_id].channel);
      }

      WEB_SOCKETS[device_id].socket.disconnect();
      WEB_SOCKETS[device_id] = null;

      delete WEB_SOCKETS[device_id];
    }
  };

  useEffect(() => {
    if (!sectionData.loadedConfigs) {
      loading.start('Loading Manual Control information...');
    } else {
      loading.stop();
    }
  }, [sectionData.loadedConfigs]);

  useEffect(() => {
    if (element && !isInit) {
      const { details } = element;
      const _peripherals: any = helpers.extractPeripherals(details);
      const preCkData: any = helpers.prepareManualControlData(
        element,
        _peripherals,
      );
      setCurrentDeviceId(element.device_id);
      setPeripherals(_peripherals);
      setWidgetCkStates(preCkData);
      setIsInit(true);
    }
    return () => {};
  }, [element]);

  useEffect(() => {
    if (peripherals && Object.values(peripherals).length > 0) {
      setOrderedEl(helpers.fillElementsArray(peripherals));
    }
  }, [peripherals]);

  useEffect(() => {
    let userId: any = localStorage.getItem('currentAccount');

    if (sectionData.loadedConfigs && element?.device_id) {
      const devSetup: any = sectionData.configs.find((item: any) => {
        return item.system_id === element.device_id;
      });

      if (devSetup && Object.keys(devSetup).length > 0) {
        const setupData: any = helpers.handleSetup(devSetup, userId);

        setManualCtrlStatus(devSetup?.status);

        mergeStoredData(setupData);
      }
    }
  }, [sectionData.loadedConfigs]);

  useEffect(() => {
    if (helpers.isDefinedValue(currentDeviceId)) {
      const storedExpanded: any = localStorage.getItem(
        `${ST_LABEL}${currentDeviceId}`,
      );

      if (helpers.isDefinedValue(storedExpanded)) {
        setIsExpandedCtrl(storedExpanded === 'true');
      }
    }
    return () => {};
  }, [currentDeviceId]);

  useEffect(() => {
    return () => {
      setCurrentUpdatingSection('');
    };
  }, []);

  useEffect(() => {
    if (widgetCkStates?.setup) {
      const {
        account_id,
        system_id,
        status,
        ...setupRest
      } = widgetCkStates?.setup;

      const mcDataToUpdate: any = {
        device_id: widgetCkStates?.system_id,
        setup: setupRest,
        status: manualCtrlStatus,
      };

      saveMCUpdating(mcDataToUpdate);
    }
    return () => {};
  }, [widgetCkStates, manualCtrlStatus]);

  useEffect(() => {
    return () => {
      if (INTERVALS && INTERVALS.length > 0) {
        for (const interval of INTERVALS) {
          clearInterval(interval);
        }
        INTERVALS = [];
      }

      if (REQUEST_STATES && Object.keys(REQUEST_STATES).length > 0) {
        for (const key in REQUEST_STATES) {
          REQUEST_STATES[key] = false;
        }
      }

      destroySocket(element);
      setIsInit(false);
    };
  }, []);

  useEffect(() => {
    if (isInit) {
      (async () => {
        if (sectionData.loadedConfigs && element?.device_id) {
          const devSetup: any = sectionData.configs.find((item: any) => {
            return item.system_id === element.device_id;
          });

          if (devSetup && Object.keys(devSetup).length > 0) {
            const setupData: any = helpers.handleSetup(
              devSetup,
              localStorage.getItem('currentAccount'),
            );

            if (!WEB_SOCKETS.hasOwnProperty(element?.device_id)) {
              startSocket(
                element,
                element?.device_id,
                { path: 'notifications', process: 'manualControl' },
                `Admin-Setup-Update-manualControl`,
              );
            }
          }
        }
      })();
    }
  }, [isInit, sectionData.loadedConfigs]);

  useEffect(() => {
    if (isInit && !manualCtrlStatus) {
      const {
        account_id,
        system_id,
        status,
        ...setupRest
      } = widgetCkStates?.setup;

      const mcDataToUpdate: any = {
        device_id: widgetCkStates?.system_id,
        setup: setupRest,
        status: manualCtrlStatus,
      };

      editManualControlAdmin(mcDataToUpdate);
    }
  }, [manualCtrlStatus]);

  return (
    <div style={{ marginBottom: '16px', marginTop: '16px' }}>
      <DetailsHeader
        designation={designation}
        sensorProp={''}
        icon={
          systemsCurrentStatus &&
          Object.keys(systemsCurrentStatus).length > 0 &&
          systemsCurrentStatus.hasOwnProperty(element?.device_id) &&
          systemsCurrentStatus[element?.device_id].statusIsLoaded &&
          systemsCurrentStatus[element?.device_id].stopped ? (
            <TooltipView title="Disconnected">
              <span>
                <div className="circle-excl">
                  <FaExclamation
                    size="0.8rem"
                    color="red"
                    className="system-status-icon"
                  />
                </div>
              </span>
            </TooltipView>
          ) : null
        }
      />

      <div style={{ marginTop: '16px' }}>
        <Accordion
          className={sectionData.className}
          square
          expanded={expandedCtrl && manualCtrlStatus}
          onChange={handleChange('mc-admin')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={''}
            id={''}
          >
            <div
              className={`container admin-section-container manual-ctrl-acc`}
            >
              <div className="row">
                <div
                  className={`col-xs-12 col-sm-12 col-md-5 col-lg-8 col-xl-8 title-label`}
                >
                  <div className={`card crd-details`}>
                    <div className={`card-body spec-crd-body`}>
                      <h5 className={`card-title`}>
                        {config.strings.cardTitle}
                      </h5>

                      <p className={`card-subtitle mb-2 text-muted lead-sm`}>
                        {' '}
                        <AiOutlineControl className="settings-ic" />{' '}
                        {config.strings.settings}
                      </p>
                      <div className={`card-text lead parag`}></div>
                    </div>
                  </div>
                </div>
                <div
                  className={`col-xs-12 col-sm-12 col-md-7 col-lg-4 col-xl-4 switch--side`}
                >
                  <div
                    className="container"
                    style={{
                      paddingLeft: '0px',
                      paddingRight: '0px',
                    }}
                  >
                    <div className="row cont-sw" id="switch-box-mc">
                      <div className="col-xs-6 col-sm-6 col-md-8 col-lg-3 pdg-r the--switch">
                        <TooltipView
                          title={
                            <>
                              <span className="acc-tooltp-font">
                                {config?.strings?.title}
                              </span>
                              <p className="tooltip-cls">
                                {config?.strings?.conf}
                              </p>
                            </>
                          }
                        >
                          <span>
                            <SwitchBtn
                              id={``}
                              value={manualCtrlStatus}
                              onClick={(event: any) =>
                                toggleOnOff(event, element.device_id)
                              }
                              className={`enable-disable-admin`}
                              disabled={
                                systemsCurrentStatus &&
                                Object.keys(systemsCurrentStatus).length > 0 &&
                                systemsCurrentStatus.hasOwnProperty(
                                  element?.device_id,
                                ) &&
                                systemsCurrentStatus[element?.device_id]
                                  .statusIsLoaded &&
                                systemsCurrentStatus[element?.device_id].stopped
                                  ? true
                                  : false
                              }
                            />
                          </span>
                        </TooltipView>
                      </div>

                      <div className="row display-sm"></div>

                      <div className="col-xs-6 col-sm-6 col-md-4 col-lg-3 centered">
                        <span
                          className={`lead status-mc ${
                            manualCtrlStatus ? `on-clr` : `off-clr`
                          }`}
                        >
                          {manualCtrlStatus ? `ON` : `OFF`}
                        </span>{' '}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {!sectionData.loadedConfigs ? (
              <></>
            ) : (
              <div className="container mc-details">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 left-mrg-lg">
                    {orderedEl &&
                      orderedEl.length > 0 &&
                      orderedEl
                        .slice(0, helpers.getTheIndex(Object.keys(orderedEl)))
                        .map((item: any, index: number) => {
                          return (
                            <div className="row" key={index}>
                              <div className="col align-label">
                                <span className="label-per"> {item.name}</span>
                              </div>
                              <div className="col-auto centered-switch">
                                <SwitchBtn
                                  className=""
                                  id={`${item.id}-${item.type}-${
                                    item.cidx !== undefined &&
                                    item.cidx !== null
                                      ? item.cidx
                                      : 'na'
                                  }`}
                                  value={helpers.retrieveValue(
                                    item.type,
                                    widgetCkStates,
                                    item.id,
                                  )}
                                  onChange={onChangeCheckBoxes}
                                  onClick={onChangeCheckBoxes}
                                />
                              </div>
                            </div>
                          );
                        })}
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 left-mrg-lg-2">
                    {orderedEl &&
                      orderedEl.length > 0 &&
                      orderedEl
                        .slice(helpers.getTheIndex(Object.keys(orderedEl)))
                        .map((item: any, index: number) => {
                          return (
                            <div className="row" key={index}>
                              <div className="col align-label">
                                <span className="label-per"> {item.name}</span>
                              </div>
                              <div className="col-auto centered-switch">
                                <SwitchBtn
                                  className=""
                                  id={`${item.id}-${item.type}-${
                                    item.cidx !== undefined &&
                                    item.cidx !== null
                                      ? item.cidx
                                      : 'na'
                                  }`}
                                  value={helpers.retrieveValue(
                                    item.type,
                                    widgetCkStates,
                                    item.id,
                                  )}
                                  onChange={onChangeCheckBoxes}
                                  onClick={onChangeCheckBoxes}
                                />
                              </div>
                            </div>
                          );
                        })}
                  </div>

                  {currentUpdatingSection === element?.device_id &&
                    mcConfirmation && (
                      <div
                        id="floating--div--1"
                        className="float-btn--wrapper2"
                      >
                        <div className="float-btn--content2 save--dv">
                          <div className="container pdg mg-b-15">
                            <div className="row pdg">
                              <div className="col-10 pdg"></div>
                              <div className="col-2 pdg c-2">
                                <button
                                  className={`btn small-button ok`}
                                  type="button"
                                  data-toggle="modal"
                                  data-target="#manual-ctrl-changes"
                                  onClick={() => {
                                    setMcConfirmation(false);
                                  }}
                                >
                                  <span className="btn-label btn-child-ft">
                                    <RiSave3Fill className="svg-ii" /> SAVE{' '}
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            )}
          </AccordionDetails>
        </Accordion>
      </div>
      <Modal
        config={config}
        id={`manual-ctrl-changes`}
        title={config.titles.mc}
        icon={<RiSave3Fill className="edit" />}
        body={config.strings.confirmation}
        modalMustClose={modalMustClose}
        handleModalState={handleModalState}
        onClick={() => performAction(`update-man-ctrl`)}
        label={` OK`}
        className={`small-button ok`}
        bodyClass={`alert alert-danger`}
        sureToProceed={`Are you sure you wish to proceed?`}
        hasConfirmBtn={true}
      />
    </div>
  );
};

function mapStateToProps(state: any) {
  const { adminSectionsState, adminDevicesState } = state;
  return {
    mcConfirmation: adminSectionsState.mcConfirmation,
    currentUpdatingSection: adminSectionsState.currentUpdatingSection,
    modalMustClose: adminSectionsState.modalMustClose,
    manualCtrlValues: adminSectionsState.manualCtrlValues,
    systemsCurrentStatus: adminDevicesState.systemsCurrentStatus,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { adminSectionsState, loader } = dispatch;
  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    getManualCtrlDetailsAdmin: adminSectionsState.getManualCtrlDetailsAdmin,
    editManualControlAdmin: adminSectionsState.editManualControlAdmin,
    setMcConfirmation: adminSectionsState.setMcConfirmation,
    setCurrentUpdatingSection: adminSectionsState.setCurrentUpdatingSection,
    handleModalState: adminSectionsState.handleModalState,
    saveMCUpdating: adminSectionsState.saveMCUpdating,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManualControl);
