import states from './states';

export default {
  strings: {
    confirm: 'Update Profile',
    cancel: 'Back',
  },
  fieldsState: {
    first_name:'',
    last_name:'',
    company: '',
    installerKey: '',
    email: '',
    phone: '',
    address_line_one: '',
    address_line_two: '',
    zip_code: '',
    city: '',
    state: '',
  },
  fieldsFocus: {
    username: false,
  },
  fields: {
    first_name: {
      label: 'First Name',
      type: 'text',
      placeholder: 'First Name',
      cols: {
        col: 6,
        sm: 12,
        md: 12,
        lg: 6,
        xl: 6,
      },
      classname: 'input--first-name',
    },
    last_name: {
      label: 'Last Name',
      type: 'text',
      placeholder: 'Last Name',
      cols: {
        col: 6,
        sm: 12,
        md: 12,
        lg: 6,
        xl: 6,
      },
      classname: 'input--last-name',
    },
    company: {
      label: 'Company',
      type: 'text-option',
      placeholder: 'Company',
      cols: {
        col: 6,
        sm: 12,
        md: 12,
        lg: 6,
        xl: 6,
      },
      classname: 'input--company',
    },
    installerKey: {
      label: 'Installer Key',
      type: 'text-option',
      placeholder: 'Installer Key',
      cols: {
        col: 6,
        sm: 12,
        md: 12,
        lg: 6,
        xl: 6,
      },
      classname: 'input--key',
    },
    email: {
      label: 'E-mail',
      type: 'email',
      placeholder: 'E-mail',
      cols: {
        col: 6,
        sm: 12,
        md: 12,
        lg: 6,
        xl: 6,
      },
      classname: 'input--email',
    },
    phone: {
      label: 'Mobile number',
      type: 'phone',
      placeholder: 'Mobile number',
      cols: {
        col: 6,
        sm: 12,
        md: 12,
        lg: 6,
        xl: 6,
      },
      classname: 'input--phone',
    },
    address_line_one: {
      label: 'Address line 1',
      type: 'text',
      placeholder: 'Address line 1',
      cols: {
        col: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      },
      classname: 'input--address-1',
    },
    address_line_two: {
      label: 'Address line 2',
      type: 'text',
      placeholder: 'Address line 2',
      cols: {
        col: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      },
      classname: 'input--address-2',
    },
    zip_code: {
      label: 'Zip Code',
      type: 'text',
      placeholder: 'Zip Code',
      cols: {
        col: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      },
      classname: 'input--zip-code',
    },
    city: {
      label: 'City',
      type: 'text',
      placeholder: 'City',
      cols: {
        col: 6,
        sm: 12,
        md: 12,
        lg: 6,
        xl: 6,
      },
      classname: 'input--city',
    },
    state: {
      label: 'State',
      type: 'dropdown',
      title:'Select state',
      placeholder: 'State',
      cols: {
        col: 6,
        sm: 12,
        md: 12,
        lg: 6,
        xl: 6,
      },
      classname: 'select--states',
      data: states,
    },
  },
  constraints: {
    first_name: {
      presence: true,
      format: {
        pattern: "^[\\w'\\-,.][^0-9_!¡?÷?¿/\\\\+=@#$%ˆ&*(){}|~<>;:[\\]]{2,}$",
      },
    },
    last_name: {
      presence: true,
      format: {
        pattern: "^[\\w'\\-,.][^0-9_!¡?÷?¿/\\\\+=@#$%ˆ&*(){}|~<>;:[\\]]{2,}$",
      },
    },
    company: {
      presence: true,
    },
    installerKey: {
      presence: true,
    },
    email: {
      presence: true,
      email: true,
    },
    phone: {
      presence: true,
    },

    address_line_one: {
      presence: true,
      length: {
        minimum: 3,
      },
    },
    zip_code: {
      presence: true,
      format: {
        pattern: '[a-z0-9][a-z0-9\\- ]{0,10}[a-z0-9]$',
        message: 'is invalid',
      },
    },
    city: {
      presence: true,
      length: {
        minimum: 3,
      },
    },
    state: {
      presence: true,
      length: {
        minimum: 2,
      },
    },
  },
};
