import { ConnectionBroker } from 'core';

export const userConfigs = {
  state: {
    userListConfigs: [],
    userConfigsLoaded: false,
    configShowData: [],
    configShowDataLoaded: false,
    outsideAirPreparedSetup: {},
    indoorAirPreparedSetup: {},
    currentUserConfigId: undefined,
    currentIDConfig: '',
  },
  reducers: {
    saveCreatedConfigID(state: any, payload: any) {
      return {
        ...state,
        currentIDConfig: payload,
      };
    },
    saveUserConfigs(state: any, payload: any) {
      return {
        ...state,
        userListConfigs: payload,
        userConfigsLoaded: true,
      };
    },
    prepareToSaveSetupStore(state: any, payload: any) {
      return {
        ...state,
        outsideAirPreparedSetup: payload,
      };
    },
    prepareToSaveIASetupStore(state: any, payload: any) {
      return {
        ...state,
        indoorAirPreparedSetup: payload,
      };
    },
    storeId(state: any, payload: any) {
      localStorage.setItem('@currentUserSelectedConfigId', payload);

      let savedId = localStorage.getItem('@currentUserSelectedConfigId');

      return {
        ...state,
        currentUserConfigId: savedId,
      };
    },
    resetCurrentSaved(state: any) {
      return {
        ...state,
        configShowData: [],
        configShowDataLoaded: false,
      };
    },
    saveCurrConfig(state: any, payload: any) {
      return {
        ...state,
        configShowData: payload,
        configShowDataLoaded: true,
      };
    },
  },
  effects: (dispatch: any) => ({
    async listUserConfigs(data: any, rootState: any) {
      const that: any = this;
      const response: any = await ConnectionBroker.fetchAPI(
        'jitBE',
        'userConfigs.listConfigs',
      );

      if (response?.statusCode === 200) {
        that.saveUserConfigs(response?.body || []);
      }

      return response?.body ? response.body : [];
    },
    async createConfig(payload: any, rootState: any) {
      const that: any = this;
      const response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'userConfigs.createConfig',
        payload,
      );
      if (response.statusCode === 200) {
        await that.listUserConfigs();
      }

      return response?.body ? response.body : null;
    },
    async showConfig(data: any) {
      const that: any = this;
      const response: any = await ConnectionBroker.fetchAPI(
        'jitBE',
        'userConfigs.showConfig',
        null,
        {
          id: data,
        },
      );

      if (response?.statusCode === 200) {
        if (response?.body) {
          that.saveCurrConfig(response?.body);
        }
      } else {
        //TODO handle errors
      }

      return response?.body ? response.body : [];
    },
    async editConfiguration(payload: any) {
      const that: any = this;
      const { data, id }: any = payload;

      const response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'userConfigs.updateConfig',
        { ...data },
        { id: id },
      );

      if (response?.statusCode === 200) {
        await that.listUserConfigs();
      }

      return response?.statusCode;
    },
    async deleteUserConfig(data: any) {
      const that: any = this;
      const response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'userConfigs.deleteConfig',
        null,
        { id: data.id },
      );

      if (response?.statusCode === 200) {
        await that.listUserConfigs();
      }

      return response?.body ? response.body : null;
    },
  }),
};
