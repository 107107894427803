import React from 'react';
import { TempMessageProps } from './interface';
import './styles.scss';

const TempMessage: React.FC<TempMessageProps> = ({
  id,
  title,
  text,
  closeId,
  classnames,
  closeBtn,
}) => {
  return (
    <div
      className={classnames.class1}
      id={id}
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className={classnames.class2} role={classnames.role}>
        <div className={classnames.class3}>
          <div className="modal-header tm--spacing">
            <div className="container pdg">
              <div className="row top-msg">
                <div className="col-12 pdg">
                  <span className="span-title">{title}</span>
                  <button
                    type="button"
                    className={closeBtn}
                    data-dismiss="modal"
                    aria-label="Close"
                    id={closeId}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-body tm--spacing text-msg">{text}</div>
        </div>
      </div>
    </div>
  );
};

export default TempMessage;
