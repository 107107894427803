import React from 'react';


interface HumiditySymbolProps {
  value?: any;
}

const HumiditySymbol: React.FC<HumiditySymbolProps> = ({ value }) => {
  return (
    <>
      {`${value} %`}
    </>
  );
};

export default HumiditySymbol;
