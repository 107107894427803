import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { FormData, Button } from './components';


interface FormProps {
  config: any;
  showModal: boolean;
  value: any;
  errors: any;
  btnDisabled: boolean;
  handleInput: any;
  handleCancel: any;
  handleSubmit: any;
  requestDeleteHandler: any;
}

const Form: React.FC<FormProps> = ({
  config,
  showModal,
  value,
  errors,
  btnDisabled,
  handleInput,
  handleCancel,
  handleSubmit,
  requestDeleteHandler,
}) => {

  return (
    <>
      <div className="">
        <FormData
          config={config}
          emailValue={value}
          errors={errors}
          handleInput={handleInput}
        />
      </div>
      <Button
        config={config}
        fireConfirmation={handleSubmit}
        disabled={btnDisabled}
      />
      <SweetAlert
        danger
        show={showModal}
        showCancel
        confirmBtnText={config?.alert?.btn?.confirm}
        confirmBtnBsStyle="danger"
        title={config?.alert?.question}
        onConfirm={requestDeleteHandler}
        onCancel={handleCancel}
        focusCancelBtn
      />
    </>
  );
};

export default Form;
