import React from 'react';
import { SwitchboxProps } from './interface';

const SwitchBox: React.FC<SwitchboxProps> = ({ children }) => {
  return (
    <div className="switch-box">
      <div className="row">
        <div className="col">{children} </div>
      </div>
    </div>
  );
};

export default SwitchBox;
