import React from 'react';
import foobot_dd from '../../../../../../../../assets/small/foobot_dd.png';
import airthings from '../../../../../../../../assets/small/airthings.jpg';
import awair from '../../../../../../../../assets/small/awair.png';

const determineIconFn = (type: any) => {
  if (type === 'airthings') {
    return (
      <img
        src={airthings}
        style={{ width: '22px', height: '24px', marginRight: '0.2rem' }}
      />
    );
  }
  if (type === 'foobot') {
    return <img src={foobot_dd} />;
  }
  if (type === 'awair-element' || type === 'awair-omni') {
    return (
      <img
        src={awair}
        style={{ width: '24px', height: '26px', marginRight: '0.35rem' }}
      />
    );
  }
};

const checkTypeOfAircycler = (airc: any) => {
  let typeOfAirC: string = '';

  if (typeof airc === 'string') {
    if (airc[9] === 'V') {
      typeOfAirC = 'VentConnect';
    } else if (airc[9] === 'F') {
      typeOfAirC = 'FreshConnect2';
    }
  } else {
    let char = airc?.cdid[9];

    if (char === 'V') {
      typeOfAirC = 'VentConnect';
    } else if (char === 'F') {
      typeOfAirC = 'FreshConnect2';
    }
  }

  return typeOfAirC;
};

const renderLabel = (id: string) => {
  switch (id) {
    case 'minutesPerHourSupplyFlow':
    case 'minutesPerHourExhaustRun':
    case 'exhaustFanDelayTime':
    case 'additionalMixTime':
    case 'exhaustFanFlowRate':
      return '| Min per hr';

    case 'flowMeasuredHeatCall':
    case 'flowMeasuredCoolingCall':
    case 'flowMeasuredFanOnlyCall':
    case 'requiredContinuousAirFlow':
    case 'exhaustFanDelayTime':
      return '| Enter CFM';

    default:
      return '';
  }
};

export const functions = {
  determineIconFn: determineIconFn,
  checkTypeOfAircycler: checkTypeOfAircycler,
  renderLabel: renderLabel,
};
