export default {
  strings: {
    title: 'Device Details',
    labels: {
      firmwareType: 'Firmware Type: ',
      firmware: 'Firmware Version: ',
      macAddrr: 'Mac Address: ',
      upTime: 'Average Mqtt Session: ',
      co2: 'CO2 - eq',
      humidity: 'Humidity',
      temp: 'Temperature',
      voc: 'tVOC',
      pm25: 'PM 2.5',
      o3: 'O3',
    },
    titles: {
      sensorsStats: 'Sensors Stats',
      airQuality: 'Air Quality',
    },
    warns: {
      noDevices: 'Controller does not have any connected device.',
    },
  },
  fieldsState: {},
  fieldsFocus: {},
  fields: {},
  constraints: {},
};
