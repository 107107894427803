// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { WidgetDoubleSliderProps } from './interface';
import { Grid } from '@material-ui/core';
import ReactSlider from 'react-slider';
import TooltipView from 'react-components/components/Tooltip';
import {
  renderCustomThumb,
  LeftSideComponent,
  RightSideComponent,
} from './hooks';
import * as Utils from './utils';
import './styles.scss';

const UTILS = { ...Utils.default };

const WidgetDoubleSlider: React.FC<WidgetDoubleSliderProps> = ({
  id,
  values,
  onChange,
  max,
  min,
  step,
  col,
  showLabel,
  symbol,
  defaultMarks,
  defaultMarksValues,
  ...props
}) => {
  const [compData, setCompData]: any = useState(null);
  const [cValues, setCValues]: any = useState([]);

  useEffect(() => {
    if (id && id !== '') {
      const result: any = UTILS.selectData(id);
      if (result) {
        setCompData({ ...result });
      }
    }

    return () => {
      setCompData(null);
    };
  }, [id]);

  useEffect(() => {
    if (values) {
      const tempValues: any = UTILS.mapNewValues(values, { max, min });
      setCValues([...tempValues]);
    }
  }, [values]);

  return (
    <Grid className="double--widget-slider">
      <div id={id} className="double--widget-slider--container">
        <Grid container>
          <Grid item className={`slider--left-container--${col ? col : 1}`}>
            {compData && <LeftSideComponent data={compData} />}
          </Grid>
          <Grid item className={`slider--center-container--${col ? col : 1}`}>
            <ReactSlider
              className="db-horizontal-slider"
              thumbClassName="db-slider-thumb"
              trackClassName="db-slider-track"
              value={cValues}
              step={step}
              max={max}
              min={min}
              ariaLabel={['Left thumb', 'Middle thumb', 'Right thumb']}
              renderThumb={renderCustomThumb.bind(null, symbol)}
              minDistance={-max}
              snapDragDisabled={true}
              onSliderClick={(value: number, tIndex: number) => {}}
              onBeforeChange={(value: number, tIndex: number) => {}}
              onChange={onChange}
            />
            {defaultMarks && defaultMarksValues && (
              <div className="default-value--wrapper">
                <TooltipView
                  title={`Default value: ${defaultMarksValues.left.value}`}
                >
                  <svg
                    width="10"
                    height="8"
                    style={{
                      position: 'absolute',
                      zIndex: 9,
                      top: defaultMarksValues.left.top
                        ? `${defaultMarksValues.left.top}%`
                        : defaultMarksValues.left.top,
                      left: defaultMarksValues.left.left
                        ? `${defaultMarksValues.left.left}%`
                        : defaultMarksValues.left.left,
                    }}
                  >
                    <rect
                      width="10"
                      height="8"
                      style={{ fill: '#90c095', strokeWidth: 0 }}
                    />
                    Sorry, your browser does not support inline SVG.
                  </svg>
                </TooltipView>
                <TooltipView
                  title={`Default value: ${defaultMarksValues.right.value}`}
                >
                  <svg
                    width="10"
                    height="8"
                    style={{
                      position: 'absolute',
                      zIndex: 9,
                      top: defaultMarksValues.right.top
                        ? `${defaultMarksValues.right.top}%`
                        : defaultMarksValues.right.top,
                      right: defaultMarksValues.right.right
                        ? `${defaultMarksValues.right.right}%`
                        : defaultMarksValues.right.right,
                    }}
                  >
                    <rect
                      width="10"
                      height="8"
                      style={{ fill: '#90c095', strokeWidth: 0 }}
                    />
                    Sorry, your browser does not support inline SVG.
                  </svg>
                </TooltipView>
              </div>
            )}
          </Grid>
          <Grid item className={`slider--right-container--${col ? col : 1}`}>
            {compData && <RightSideComponent data={compData} />}
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default WidgetDoubleSlider;
