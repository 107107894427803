import React from 'react';
import { Breadcrumb } from '../../../../react-components';
import './styles.scss';

interface DataLoggingTemplateProps {
  id?: string;
  title?: string;
  style?: any;
  location?: any;
  titleBackButton?: any;
  titleBackOnClick?: any;
  elRef?: any;
}

const DataLoggingTemplate: React.FC<DataLoggingTemplateProps> = ({
  children,
  id,
  title,
  style,
  location,
  titleBackButton,
  titleBackOnClick,
  elRef,
  ...props
}) => {

  return (
    <div
      id={id ? id : ''}
      className="dtl-template--main-content"
    >
      <Breadcrumb
        title={title}
        titleBackButton={titleBackButton}
        titleBackOnClick={titleBackOnClick}
        location={location}
        {...props}
      />
      <div className="row d-flex">
        <div className="custom-card">
          {children}
        </div>
      </div>
    </div>
  );
};

export default DataLoggingTemplate;
