import React from 'react';
import { Grid } from '@material-ui/core';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Button } from '../../../../../../../react-components';
import './styles.scss';

interface ModalAlertProps {
  show: boolean;
  onConfirm: any;
  onCancel: any;
}

const ModalAlert: React.FC<ModalAlertProps> = ({
  show,
  onConfirm,
  onCancel,
  ...props
}) => {
  const MessageTitle = () => {
    return (
      <Grid item className="foobot-alert--title">
        <p>You are about to disconnect your FOOBOT account.</p>
      </Grid>
    );
  };

  const CustomButtons = () => {
    return (
      <span className="foobot-alert--buttons">
        <span>
          <Button type="button" onClick={onConfirm} className="foobot-alert--buttons-confirm">
            I Am Sure
          </Button>
          <Button type="button" onClick={onCancel} className="foobot-alert--buttons-cancel">
            Cancel
          </Button>
        </span>
      </span>
    );
  };

  return (
    <div id="foobot-connection-alert">
      <SweetAlert
        show={show}
        showCancel
        title={<MessageTitle />}
        onConfirm={onConfirm}
        onCancel={onCancel}
        customButtons={<CustomButtons />}
        focusCancelBtn
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          className="foobot-alert--body"
        >
          <Grid item className="foobot-alert--body-section-1">
            <p>
              If you decide to disconnect your FOOBOT account, your FOOBOT
              devices will be removed from the AirCycler App.
            </p>
          </Grid>
          <Grid item className="foobot-alert--body-section-2">
            <p>ARE YOU SURE?</p>
          </Grid>
        </Grid>
      </SweetAlert>
    </div>
  );
};

export default ModalAlert;
