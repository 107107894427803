import React from 'react';

export function renderCustomThumb(symbol: string, props: any, state: any) {
  const { index, valueNow } = state;
  let newPos: any = parseFloat(props.style.left);
  newPos -= 8;
  props.style.left = `${newPos}px`;
  return (
    <div id="home-outside-air" {...props}>
      <div className="ac--slider-grad-mark">
        {valueNow}
        {symbol || ' '}
      </div>
    </div>
  );
}

function labelsFormater(label: string) {
  switch (label) {
    case 'CO2':
      return (
        <>
          CO<sup>2</sup>
        </>
      );
    case 'ug/m3':
      return (
        <>
          ug/m<sup>3</sup>
        </>
      );
    default:
      return label;
  }
}

export function LeftSideComponent({ data }: any) {
  const { _class, left } = data;
  const { isIcon, item } = left;
  const Icon: any = item;

  return (
    <div className="slider-grad--left">
      {isIcon ? (
        <Icon className={`slider-grad--icon ${_class}`} />
      ) : (
        <label className={`slider-grad-label ${_class}`}>
          {labelsFormater(item)}
        </label>
      )}
    </div>
  );
}

export function RightSideComponent({ data }: any) {
  const { _class, right } = data;
  return (
    <div className="slider-grad--right">
      <label className={`slider-grad-label ${_class}`}>
        {labelsFormater(right)}
      </label>
    </div>
  );
}
